import { Spinner, Text } from '@elements'
import { getCoordString, validCoords } from '@helpers/coordinate'
import {
  AlgoliaGeoDistro,
  AlgoliaGeoDoc,
  AlgoliaGeoFarm,
  AlgoliaGeoProduct,
  isGeoDistro,
  isGeoFarm,
  isGeoProduct,
} from '@models/Algolia'
import { GeoSearchCardMulti, MultilLocFarmData } from '@screens/Explore/GeoSearchCard'
import { ExploreContext } from '@screens/Explore/components/ExploreContext'
import { FloatingButton } from '@screens/Explore/components/HelperComponents'
import { memo, useContext, useMemo } from 'react'
import { Hit, StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults, connectStats } from 'react-instantsearch-native'
import { FlatList, View } from 'react-native'
import { useDispatch } from 'react-redux'

import { globalStyles } from '../../constants/Styles'
import { useKeyboard } from '../../hooks/useKeyboard'
import { setCurrentHover } from '../../redux/actions/appState'

import ConnectedPagination from '@/admin/components/AdminTable/ConnectedPagination'
import { isWeb } from '@/constants/Layout'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'

function ConnectedResults({ searchResults, isSearchStalled, searching }: StateResultsProvided<AlgoliaGeoDoc>) {
  const isKeyboardVisible = useKeyboard()
  const { setCondensedView } = useContext(ExploreContext)
  const hits = useDeepCompareMemo(() => searchResults?.hits || [], [searchResults?.hits])
  const processedHits = useMemo(() => processHits(hits), [hits])
  const loading: boolean = (searching || isSearchStalled) ?? true
  const dispatch = useDispatch()

  /** Reset hover on hits change */
  useFocusFx(() => {
    if (!isWeb) return
    if (validCoords(hits?.[0]?._geoloc)) {
      dispatch(setCurrentHover(getCoordString(hits[0]._geoloc)))
    } else dispatch(setCurrentHover(null))
  }, [hits, dispatch])

  const styles = useStyles()

  return (
    <View style={globalStyles.flex1}>
      <FlatList
        ListHeaderComponent={<ConnectedStats loading={loading} />}
        contentContainerStyle={styles.list}
        data={processedHits}
        keyExtractor={(itm, idx) => itm?.objectID ?? idx.toString()}
        renderItem={({ item }) => <GeoSearchCardMulti data={item} />}
        ListEmptyComponent={() =>
          loading ? (
            <Spinner size="large" style={styles.spinner} />
          ) : (
            <View style={styles.noResultsContainer}>
              <Text style={styles.headerText} size={18}>
                Tips for improving your search:
              </Text>
              <Text style={styles.bullet}>• Try a larger search area (zoom out on the map)</Text>
              <Text style={styles.bullet}>• Try a different location</Text>
              <Text style={styles.bullet}>• Check your spelling</Text>
            </View>
          )
        }
        /** Should never prevent the ConnectedPagination from showing. (Should show even if there's no results) because if you're at some page other than the first, and you change the filters, there might be less results, so your current page would be empty. Showing this regardless would allow you to return to the first page, where you would find results most likely */
        ListFooterComponent={<ConnectedPagination />}
        /** Without this `initialNumToRender`, we observed a problem where only the first 10 items were rendered, and the rest were never rendered even after scrolling down.
         * This discussion doesn't have any better alternatives, other than to use a scrollview around the flatlist which makes no sense. If we used scrollview we might as well just do [].map(<></>) instead of flatlist.  https://stackoverflow.com/questions/54302266/react-native-flatlist-only-renders-10-items
         */
        initialNumToRender={processedHits.length}
      />
      {!isKeyboardVisible && (
        <View style={styles.bottomContainer}>
          <FloatingButton view="list" onPress={setCondensedView} />
        </View>
      )}
    </View>
  )
}
export default connectStateResults(memo(ConnectedResults))

interface StatsProvided {
  nbHits: number
  processingTimeMS: number
}

const ConnectedStats = connectStats<StatsProvided & { loading?: boolean }>(({ nbHits, loading }) => {
  const styles = useStyles()
  return loading ? (
    <Spinner size="large" style={styles.spinner} />
  ) : (
    <Text size={18} style={styles.nbrResultsContainer}>
      {nbHits} results
    </Text>
  )
})

/** Aggregates results into data format expected by GeoSearchCards with multiple distros */
const processHits = (hits: Hit<AlgoliaGeoDoc<AlgoliaGeoFarm | AlgoliaGeoDistro | AlgoliaGeoProduct>>[]) => {
  const multiLocs: { [farmId: string]: MultilLocFarmData } = {}
  const prods: AlgoliaGeoDoc<AlgoliaGeoProduct>[] = []

  hits.forEach((doc) => {
    if (isGeoProduct(doc)) prods.push(doc)
    else if (isGeoFarm(doc)) {
      if (!multiLocs[doc.farm.id]) multiLocs[doc.farm.id] = { farm: { ...doc }, distros: [], objectID: doc.objectID }
    } else if (isGeoDistro(doc)) {
      if (!multiLocs[doc.farm.id]) multiLocs[doc.farm.id] = { distros: [doc], objectID: doc.objectID }
      else multiLocs[doc.farm.id].distros.push(doc)
    }
  })

  return [...prods, ...Object.values(multiLocs)]
}

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isLargeDevice }) => ({
    bottomContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    bullet: {
      marginVertical: 5,
      fontSize: 13,
    },
    noResultsContainer: {
      flex: 1,
      paddingHorizontal: 25,
    },
    headerText: {
      marginBottom: 15,
    },
    list: {
      paddingHorizontal: isSmallDevice ? 0 : 15,
      paddingBottom: isLargeDevice ? 30 : 100,
    },
    spinner: {
      marginVertical: 10,
    },
    nbrResultsContainer: {
      paddingHorizontal: 25,
      marginVertical: 10,
    },
  }))
