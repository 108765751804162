import InputLabel from '@/admin/components/InputLabel'
import { StateZipInput } from '@/admin/screens/LocationsAndZones/StateZipsInput'
import { ShortState, getShortState } from '@/assets/data/states'
import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { adminFarmIdSelector } from '@/redux/selectors'
import { addProductFee, updateProductFee } from '@api/ProductFees'
import {
  ErrorText,
  FormButton,
  FormDisplayRow,
  FormInput,
  FormMoneyInput,
  FormPickerInput,
  FormUndefinedInitialValue,
  Icon,
  KeyboardAvoidingScrollView,
  Text,
  Toast,
  hideModal,
} from '@elements'
import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import DecimalCalc from '@helpers/decimal'
import { errorToString, nonEmptyString } from '@helpers/helpers'
import { ShortZip } from '@models/Address'
import { RegionType } from '@models/Location'
import { Money, isMoney } from '@models/Money'
import { FeeType, FeeValueType, NoneValue, ProductFee, isPercentProductFee } from '@models/ProductFee'
import { Formik, FormikProps } from 'formik'
import { memo, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

const feeTypesPickerItems = [
  { label: 'Tax', value: FeeType.Tax },
  { label: 'Fee', value: FeeType.Fee },
]

const locationTypePickerItems = [
  { label: 'State', value: RegionType.State },
  { label: 'Zipcode', value: RegionType.Zipcode },
]

type ProductFeeForm = Pick<ProductFee, 'name' | 'type' | 'value' | 'valueType' | 'regionType' | 'regions'>

/** Schema for add/edit productFee form */
export const validationSchema = Yup.object<ProductFeeForm>().shape({
  name: Yup.string().trim().label('Name').required(),
  type: Yup.mixed<FeeType>().label('Fee Type').required().oneOf([FeeType.Tax, FeeType.Fee]),
  /** The value should be strict with valueType */
  value: Yup.mixed<number | Money>().when('valueType', {
    is: FeeValueType.Percent,
    then: (_) => YUP_WHOLE_NUMBER_REAL('Value', { allowDecimal: true }).max(100, 'Cannot be greater than 100%'),
    otherwise: (_) => YUP_MONEY_REQUIRED('Value'),
  }),
  valueType: Yup.mixed<FeeValueType>().label('Value Type').oneOf([FeeValueType.Fixed, FeeValueType.Percent]),
  regionType: Yup.mixed<ProductFeeForm['regionType']>()
    .label('Region Type')
    .oneOf([RegionType.State, RegionType.Zipcode, NoneValue]),
  regions: Yup.array<ShortState[] | ShortZip[]>().when('regionType', {
    is: (regionType: RegionType) => regionType === RegionType.State,
    then: (schema) =>
      schema.test('Valid state', 'State must be a valid state code', (v?: ShortState[]) => {
        // If it is undefined or empty we should allow that as valid (because it is optional)
        if (!Array.isArray(v) || !v.length) {
          return true
        }
        // If it has elements then check each element
        return v.every((s) => nonEmptyString(s) && !!getShortState(s))
      }),
    otherwise: (schema) =>
      schema.when('regionType', {
        is: (regionType: RegionType) => regionType === RegionType.Zipcode,
        then: (schema) =>
          schema.test('5 digits', 'Zip code must be 5 digits', (v?: ShortZip[]) => {
            // If it is undefined or empty we should allow that as valid (because it is optional)
            if (!Array.isArray(v) || !v.length) {
              return true
            }
            // If it has elements then check each element
            return v.every((zip: string) => !!zip && zip.length === 5)
          }),
        // Default validation schema when 'regionType' is not State or Zipcode
        otherwise: (schema) => schema.default([]),
      }),
  }),
})

/** Props for AddEditProductFee component */
type AddEditProductFeeProps = {
  productFee?: ProductFee
}

export const AddEditProductFee = memo(function AddEditProductFee({ productFee }: AddEditProductFeeProps) {
  const [error, setError] = useState('')
  const styles = useStyles()
  const farmId = useSelector(adminFarmIdSelector)
  // If the productFee exists then we are editing, otherwise we are adding
  const isEdit = !!productFee?.id
  const initialValues: ProductFeeForm = {
    name: productFee?.name ?? '',
    type: productFee?.type ?? FeeType.Tax,
    value: initialProductFeeValue(productFee),
    valueType: productFee?.valueType ?? FeeValueType.Percent,
    regionType: productFee?.regionType ?? NoneValue,
    regions: productFee?.regions ?? [],
  }

  /** submit handler to add and update product fee.*/
  const onSubmitHandler = async (values: ProductFeeForm) => {
    if (error) setError('')
    if (!farmId) return setError('Could not load your farm, please reload and try again.')

    /**  We use FormInput to allow unlimited decimal, so the value could be string and we have to cast it as number. */
    const localValues = { ...values, value: typeof values.value === 'string' ? parseFloat(values.value) : values.value }

    try {
      if (isEdit) {
        // Updating a product fee
        const updatedProductFee: ProductFee = {
          ...productFee,
          ...localValues,
          value: isPercentProductFee(localValues) ? DecimalCalc.divide(localValues.value, 100) : localValues.value,
        }
        await updateProductFee(updatedProductFee)
        Toast(`This ${localValues.type} has been updated successfully`)
      } else {
        const newProductFee: Omit<ProductFee, 'id'> = {
          ...localValues,
          farm: { id: farmId },
          archived: false,
          value: isPercentProductFee(localValues) ? DecimalCalc.divide(localValues.value, 100) : localValues.value,
        }
        // Add new product fee to firestore
        await addProductFee(newProductFee)
        Toast(`This ${localValues.type} has been added successfully`)
      }

      hideModal()
    } catch (e) {
      Logger.error(errorToString(e))
      setError(`Unable to save ${localValues.type}: Please try again later. If the problem persists, contact support.`)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={validationSchema}>
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }: FormikProps<ProductFeeForm>) => (
        <KeyboardAvoidingScrollView>
          <FormInput
            label={<InputLabel required label="Name" />}
            placeholder="County tax or flat fee"
            errorMessage={touched.name ? errors.name : ''}
            value={values.name}
            onChangeText={(val) => setFieldValue('name', val)}
            onBlur={handleBlur('name')}
          />
          <FormDisplayRow>
            <FormPickerInput
              disabled={isEdit}
              placeholder={null}
              items={feeTypesPickerItems}
              value={values.type}
              onValueChange={(val) => {
                setFieldValue('type', val)
                if (val === FeeType.Tax) {
                  setFieldValue('valueType', FeeValueType.Percent)
                } else {
                  setFieldValue('valueType', FeeValueType.Fixed)
                }
              }}
              useWebNativePicker
              label={<InputLabel required label="Type" />}
            />
            {values.type === FeeType.Tax && (
              <FormInput
                row
                label={<InputLabel required label="Rate" />}
                value={
                  typeof values.value === 'number' || typeof values.value === 'string'
                    ? values.value.toString()
                    : undefined
                }
                placeholder="20"
                keyboardType="decimal-pad"
                onChangeText={(val) => setFieldValue('value', val)}
                onBlur={handleBlur('value')}
                rightIcon={<Icon name="percent" />}
                rightIconContainerStyle={styles.iconContainer}
                errorMessage={touched.value ? errors.value : ''}
              />
            )}
            {values.type === FeeType.Fee && (
              <FormMoneyInput
                row
                label={<InputLabel required label="Rate" />}
                maxLength={11}
                value={isMoney(values.value) ? values.value : undefined}
                onChangeText={(val) => setFieldValue('value', val)}
                onBlur={handleBlur('value')}
                errorMessage={touched.value ? errors.value : ''}
              />
            )}
          </FormDisplayRow>
          <View style={globalStyles.marginHorizontal10}>
            <Text size={14} type="medium">
              Limit to certain locations
            </Text>
            <Text>You can specify states or zip codes for this tax or flat fee. Leave blank to apply to all.</Text>
          </View>
          <FormPickerInput
            placeholder={{ label: 'Not selected', value: NoneValue }}
            containerStyle={styles.locationPicker}
            items={locationTypePickerItems}
            value={values.regionType}
            onValueChange={(val) => {
              setFieldValue('regionType', val)
              // Reset regions when regionType is changed
              setFieldValue('regions', [])
            }}
            useWebNativePicker
            label={<InputLabel label="Location Type" />}
          />
          {values.regionType !== NoneValue && (
            <StateZipInput
              values={values.regions}
              containerStyle={globalStyles.marginHorizontal10}
              inputContainerStyle={styles.stateZipInputContainer}
              label="Location"
              onUpdate={(val) => setFieldValue('regions', val)}
              onBlur={handleBlur('regions')}
              type={values.regionType}
              errorMessage={touched.regions ? (errors.regions as string) : ''}
            />
          )}
          <FormButton
            title="Save"
            onPress={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
            style={styles.formButton}
          />
          {!!error && <ErrorText>{error}</ErrorText>}
        </KeyboardAvoidingScrollView>
      )}
    </Formik>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice }) => ({
    iconContainer: {
      marginVertical: 0,
    },
    stateZipInputContainer: {
      marginRight: 20,
    },
    locationPicker: {
      width: isExtraSmallDevice ? '100%' : '40%',
    },
    formButton: {
      marginTop: 15,
      alignSelf: 'flex-end',
    },
  }))

/** local helper to get correct and formatted initial productFee value. */
const initialProductFeeValue = (productFee?: ProductFee) => {
  // This allows the default to be blank instead of 0
  if (!productFee) return FormUndefinedInitialValue
  else if (isPercentProductFee(productFee)) return DecimalCalc.multiply(productFee.value, 100)
  else return productFee.value
}
