import { TypedUseSelectorHook, useSelector } from 'react-redux'

import { RootState } from './reducers/types'

import { emptyFarmCachedData, FarmCachedData } from '@/constants/types/navParams'
import { matchesIdOrSlug } from '@helpers/urlSafeSlug'
import { useMemo } from 'react'

/** A typed use selector which expects a function of the root state. The fn must return the selected slice */
export const useSelectorRoot: TypedUseSelectorHook<RootState> = useSelector

/** Selects cart info based on the admin mode */
export const useCartInfo = (isAdmin = false) =>
  useSelectorRoot((rs) =>
    isAdmin ? { ...rs.adminPersist.cartInfo, cartFarmId: rs.adminPersist.adminFarmId } : rs.appPersist.cartInfo,
  )

// Admin Selectors
export const adminFarmSelector = (state: RootState) => state.adminPersist.farm
export const adminLocsSelector = (state: RootState) => state.adminPersist.locations
export const adminSchedulesSelector = (state: RootState) => state.adminPersist.distributions
export const isAdminSelector = (state: RootState) => state.adminPersist.isAdmin
export const adminCsasSelector = (state: RootState) => state.adminState.CSAs
export const adminParamsSelector = (state: RootState) => state.adminState.navParams
export const adminFarmIdSelector = (state: RootState) => state.adminPersist.adminFarmId
export const adminPermissionsSelector = (state: RootState) => state.adminPersist.permissions
export const certificationSelector = (state: RootState) => state.adminPersist.certifications
export const categorySelector = (state: RootState) => state.adminPersist.categories
export const adminProductsSelector = (state: RootState) => state.adminPersist.products
export const queueAlgoliaProducts = (state: RootState) => state.adminState.queueAlgoliaProducts
export const adminFeaturesAvailableSelector = (state: RootState) => state.adminState.featuresAvailable
/** Returns the selected user in the order creator */
export const orderCreatorUserSelector = (state: RootState) => state.adminState.orderCreatorCustomer
export const adminCartInfoSelector = (state: RootState) => state.adminPersist.cartInfo
export const adminCartServiceSelector = (state: RootState) => state.adminState.cartService

// User Selectors
/** User selector returns the logged in user */
export const userSelector = (state: RootState) => state.user
export const notificationsSelector = (state: RootState) => state.user.notifications
export const promotionsSelector = (state: RootState) => state.user.promotions
export const unsubscribersSelector = (state: RootState) => state.appState.unsubscribers

// AppPersist Selectors
export const farmsSelector = (state: RootState) => state.appPersist.farmsAssoc
export const userLocationSelector = (state: RootState) => state.appPersist.userLocation
export const consumerCartInfoSelector = (state: RootState) => state.appPersist.cartInfo
export const addonsPurchasesSelector = (state: RootState) => state.appPersist.availAddonsPurchases

// AppState Selectors
export const searchLocationSelector = (state: RootState) => state.appState.searchLocation
export const currentHoverSelector = (state: RootState) => state.appState.currentHover
export const searchFiltersSelector = (state: RootState) => state.appState.searchFilters
export const isAdminOpenSelector = (state: RootState) => state.appState.isAdminOpen
export const layoutSelector = (state: RootState) => state.appState.layout
export const layoutSizeSelector = (state: RootState) => state.appState.layout.size
/** paramsSelector is meant for storing data to be used by screens, which is not appropriate (too large, or complex) for a simple react-navigation parameter. For example, objects or arrays */
export const paramsSelector = (state: RootState) => state.appState.navParams
/** Selects the screen name currently in focus. This value will change whenever the user navigates between screens */
export const navRouteSelector = (state: RootState) => state.appState.navRoute
export const hasListenersSelector = (state: RootState) => state.appState.hasListeners
export const unreadCountSelector = (state: RootState) => state.appPersist.unreadCount
export const notificationsListSelector = (state: RootState) => state.appPersist.notifications
export const cartServiceSelector = (state: RootState) => state.appState.cartService
export const featuresAvailableSelector = (state: RootState) => state.appState.navParams.featuresAvailable

/** Gets the farm data from cache for the farm currently in the navigation prop state */
export const currentFarmCacheSelector = ({
  appState: {
    navParams: { farm, farmCache },
  },
}: RootState): FarmCachedData => {
  // if farm exists, return the farmCache[farm.urlSafeSlug] or farmCache[farm.id] or emptyFarmCachedData
  return farm ? farmCache[farm.urlSafeSlug] || farmCache[farm.id] || emptyFarmCachedData() : emptyFarmCachedData()
}

/** Gets any cached farm data available for the specified farmSlug or id */
export const useFarmDataFromCache = (farmSlug: string): FarmCachedData => {
  const { farmCache } = useSelector(paramsSelector)
  const currentFarmData = useSelector(currentFarmCacheSelector)

  return useMemo(() => {
    // First try getting the cached data for the slug provided (slug or id)
    const cachedDataForSlug = farmCache[farmSlug]

    // If it is in the cache, done
    if (cachedDataForSlug) return cachedDataForSlug

    // If it's not found under that slug, perhaps it is under the other ref as key (id or slug)
    if (currentFarmData.farm && matchesIdOrSlug(currentFarmData.farm, farmSlug)) return currentFarmData

    return emptyFarmCachedData()
  }, [farmCache, farmSlug, currentFarmData])
}
