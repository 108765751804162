import { CachedCompute, encodeArgsBasic } from '@helpers/cachedCompute'
import { getPickups } from '@helpers/order'

import { removeDuplicates } from '@helpers/helpers'
import { Distribution, isDistroNonPickup } from '@models/Distribution'
import { CartItem, isCartPhysical } from '@models/Order'
import { Product, isAddon, isPhysical } from '@models/Product'
import { SetLocationFlowOpts } from './useSetLocationFlow'

/** This should be used instead of 'getPickups' anywhere inside the addToCart flow, for efficient caching of results limited to this area. */

export const { cachedFn: getPickupsCacheAddtoCartFlow, clearCache: clearGetPickupsCacheAddtoCartFlow } = CachedCompute(
  getPickups,
  (sch, prod, opts) => sch.id + (!!prod && 'id' in prod ? prod.id : '') + encodeArgsBasic(opts),
  { seconds: 120 },
)

/** Will try to find cart distros that match with a distribution id from the distro array of a product.
 * - Used for determining whether a distro should be selected automatically when adding a new product to cart
 * - Not related to the calculation of addon availability
 */
export const getMatchingCartSchedules = (cart: CartItem[], product: Product, isAdmin = false) => {
  if (!isPhysical(product)) return []

  //Get the unique distros of cart items
  const cartSchedulesIds = removeDuplicates(cart.filter(isCartPhysical).map((ci) => ci.distribution.id))

  //Get the product distros that match with cart distros
  const matchingSchedules = product.distributions.filter(
    (pSch) => !pSch.isHidden && (isAdmin ? true : !pSch.closed) && cartSchedulesIds.includes(pSch.id),
  )

  return matchingSchedules.filter((sch) => {
    return (
      getPickupsCacheAddtoCartFlow(sch, product, {
        excludeHiddenDistros: true,
        excludeClosedDistros: !isAdmin,
        ignoreDisableBuyInFuture: isAdmin,
        ignoreOrderCutoffWindow: isAdmin,
      }).length > 0
    )
  })
}

/** Basic eligibility for choosing a distro for adding to cart, without considering pickups calculation */
export const getCanAddScheduleBasic = (dist: Distribution | undefined, isAdmin = false) => {
  if (!dist || dist.isHidden) return false
  if (!isAdmin && dist.closed) return false
  if (isDistroNonPickup(dist) && !dist.location.address) return false
  return true
}

/** Auto-selects a distribution based on the SetPickup opts */
export function getAutoSelectedSchedule({
  distribution: preSelectedDistro,
  matchingSchedulesAvailAddon,
  matchingSchedulesCart,
  prod,
  isAdmin,
}: Pick<SetLocationFlowOpts, 'distribution' | 'matchingSchedulesAvailAddon' | 'matchingSchedulesCart' | 'prod'> & {
  isAdmin: boolean
}): Distribution | undefined {
  // if defined by the end, means the distro will be auto-selected
  let autoSelectedDistro: Distribution | undefined = preSelectedDistro

  if (!autoSelectedDistro) {
    // Try the other scenarios that would lead to a single option to auto-select

    if (isAddon(prod) && !isAdmin && matchingSchedulesAvailAddon) {
      // check if there's only one valid distro option, and assign it
      if (matchingSchedulesAvailAddon.length === 1 && getCanAddScheduleBasic(matchingSchedulesAvailAddon[0], isAdmin)) {
        autoSelectedDistro = matchingSchedulesAvailAddon[0]
      }
    } else if (matchingSchedulesCart.length === 1 && getCanAddScheduleBasic(matchingSchedulesCart[0], isAdmin)) {
      // if there's only one valid distro option, assign it
      autoSelectedDistro = matchingSchedulesCart[0]
    } else if (prod.distributions.length === 1 && getCanAddScheduleBasic(prod.distributions[0], isAdmin)) {
      // if there's only one valid distro option, assign it
      autoSelectedDistro = prod.distributions[0]
    }
  }

  if (!!autoSelectedDistro && isDistroNonPickup(autoSelectedDistro) && !autoSelectedDistro.location.address) {
    const autoSelectedId = autoSelectedDistro.id
    // get the address from any cart matching distro of the same id
    const matchingAddress = matchingSchedulesCart.find((d) => d.id === autoSelectedId)?.location.address

    // assign the cart address to the autoSelected distro
    autoSelectedDistro.location.address = matchingAddress
  }

  return autoSelectedDistro
}
