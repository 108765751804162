import { CheckBox, Icon, Text } from '@elements'
import { useState } from 'react'
import { LayoutRectangle, Platform, ScrollView, StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'
import { Overlay } from 'react-native-elements'

import Colors from '../../constants/Colors'

export type SubFilterButtons = { label: string; state?: boolean; onPress?: () => void; disabled?: boolean }[]

type FilterButtonProps = {
  label: string
  onPress?: () => void
  buttonStyle?: ViewStyle
  selected: boolean
  subFilters?: SubFilterButtons
  dropdownStyle?: ViewStyle
  filterCount?: number
  /** If not undefined, controls the chevron icon (up or down). If undefined, chevron icon will only be shown if there's subFilters */
  chevron?: boolean
  disabled?: boolean
}

//On web, the onLayout event.nativeEvent.layout returns an obj with 'top' and 'left' as the x and y position of the element.
type LayoutRectableWeb = LayoutRectangle & { top?: number; left?: number }

function FilterButton({
  label,
  onPress,
  buttonStyle = {},
  selected,
  subFilters,
  dropdownStyle,
  filterCount = 0,
  chevron,
  disabled,
}: FilterButtonProps) {
  const [showDropdown, setShowDropdown] = useState(false)
  const [btnPos, setBtnPos] = useState<LayoutRectableWeb>()

  const overlayPosition = () => {
    if (btnPos) {
      const { x, y, height, top, left } = btnPos
      const topPos = Platform.select({ web: top, default: y })
      const leftPos = Platform.select({ web: left, default: x })
      return { left: leftPos, top: topPos + height + 5 }
    }
  }

  const computedButtonStyle: StyleProp<ViewStyle> = [
    styles.filterButtonStyle,
    {
      backgroundColor: selected ? Colors.secondaryGreen3 : Colors.white,
      borderColor: selected ? Colors.green : Colors.semiTransparent,
      paddingVertical: filterCount ? 7 : 10,
    },
    buttonStyle,
  ]

  let chevronIcon: 'chevron-up' | 'chevron-down'
  if (chevron !== undefined) chevronIcon = `chevron-${chevron ? 'up' : 'down'}`
  else chevronIcon = `chevron-${showDropdown ? 'up' : 'down'}`

  return (
    <>
      <TouchableOpacity
        style={computedButtonStyle}
        onPress={() => {
          if (!disabled) onPress?.()
          if (subFilters) setShowDropdown(true)
        }}
        onLayout={(e) => setBtnPos(e.nativeEvent.layout)}
        disabled={disabled}
      >
        <Text color={disabled ? Colors.shades[200] : selected ? Colors.green : undefined}>{label}</Text>
        {filterCount > 0 && (
          <Text color="white" style={styles.filterIndicator}>
            {filterCount}
          </Text>
        )}
        {(subFilters || chevron !== undefined) && (
          <Icon
            name={chevronIcon}
            size={14}
            color={selected ? Colors.green : Colors.shades[300]}
            style={{ marginLeft: 9 }}
          />
        )}
      </TouchableOpacity>
      {subFilters && !!subFilters.length && (
        <Overlay
          isVisible={showDropdown}
          onBackdropPress={() => setShowDropdown(false)}
          backdropStyle={{ backgroundColor: Colors.transparent }}
          overlayStyle={[styles.overlay, overlayPosition(), dropdownStyle]}
        >
          <ScrollView contentContainerStyle={{ maxHeight: 250 }}>
            {subFilters.map((filter) => {
              return (
                <CheckBox
                  checked={filter.state ?? false}
                  title={filter.label}
                  onChange={filter.onPress}
                  disabled={filter.disabled}
                  key={filter.label}
                />
              )
            })}
          </ScrollView>
        </Overlay>
      )}
    </>
  )
}
const styles = StyleSheet.create({
  filterButtonStyle: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.semiTransparent,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    paddingHorizontal: 16,
    borderRadius: 10,
    margin: 10,
  },
  overlay: {
    position: 'absolute',
    borderRadius: 10,
  },
  filterIndicator: {
    backgroundColor: Colors.green,
    borderRadius: 4,
    marginLeft: 9,
    padding: 3,
    paddingHorizontal: 8,
  },
})

export default FilterButton
