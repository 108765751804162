import { Distribution } from '@models/Distribution'
import {
  AddonShare,
  BaseProduct,
  DigitalStandard,
  EbtEligibility,
  FarmBalance,
  GlobalStandard,
  isGlobalUnit,
  isPayInFull,
  isPhysical,
  isShare,
  isStockUnit,
  PhysicalBase,
  PrimaryShare,
  Product,
  ProductType,
  Share,
  ShareCommon,
  Standard,
  StandardCommon,
  StockDependent,
  StockType,
  UnitBase,
  UnitProductCommon,
  UnitStandard,
} from '@models/Product'
import { Frequency, isWiderFreq } from '@models/Schedule'
import { isProdErr, ProductError } from '@shared/errors/product'

import { deepClone, isNonNullish, isObject } from '@helpers/helpers'
import { isBefore } from '@helpers/time'
import { isValidSlug } from '@helpers/urlSafeSlug'
import { ErrorWithCode } from '@shared/Errors'
import { MoneyCalc } from '../money'
import { getDistributionPickups } from '../order'
import { validateScheduleNDateConstr } from '../products'
import { entries, intersect, PartialPick, Undefine } from '../typescript'

/** ProductOptions includes all the properties of all subtypes of Product */
export type ProductFields = Partial<
  BaseProduct & PhysicalBase & StandardCommon & UnitProductCommon & StockDependent & ShareCommon
>

export function buildProduct<T extends Product>(obj: T): T
export function buildProduct(obj: Omit<ProductFields, 'type'> & { type: ProductType.PrimaryShare }): PrimaryShare
export function buildProduct(obj: Omit<ProductFields, 'type'> & { type: ProductType.AddonShare }): AddonShare
export function buildProduct(obj: Omit<ProductFields, 'type'> & { type: ProductType.Digital }): DigitalStandard
export function buildProduct(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Standard; unitStock: true },
): UnitStandard
export function buildProduct(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Standard; unitStock?: false | undefined },
): GlobalStandard
/** buildProduct receives the properties of a Product and returns a subtype of Product if data adheres to a valid subtype.
 * If data doesn't pass validations, will throw human readable errors.
 */
export function buildProduct(obj: ProductFields): Product {
  const { type, unitStock } = obj
  if (!type) throw new Error('missing type')

  let result: Product

  if (type === ProductType.PrimaryShare || type === ProductType.AddonShare) {
    const baseProd = getBaseProd(obj)
    const basePhysical = getBasePhysical(obj)
    const shareCommon = getShareCommon(obj)
    const share: Share = {
      ...baseProd,
      ...basePhysical,
      ...shareCommon,
      type,
    }

    result = share
  } else if (type === ProductType.Standard) {
    const baseProd = getBaseProd(obj)
    const basePhysical = getBasePhysical(obj)

    if (unitStock) {
      const unitProdCommon = getUnitCommon(obj)
      const standardCommon = getStandardCommon(obj)
      const stockDependent = getStockDep({ ...obj, type: ProductType.Standard, unitStock: true })
      const standardProps = intersect(unitProdCommon, stockDependent)
      const unitStandard: UnitStandard = {
        ...baseProd,
        ...basePhysical,
        ...standardCommon,
        ...standardProps,
        type,
      }
      result = unitStandard
    } else {
      const unitProdCommon = getUnitCommon(obj)
      const standardCommon = getStandardCommon(obj)
      const stockDependent = getStockDep({ ...obj, type: ProductType.Standard, unitStock: false })
      const standardProps = intersect(unitProdCommon, stockDependent)
      const globalStandard: GlobalStandard = {
        ...baseProd,
        ...basePhysical,
        ...standardCommon,
        ...standardProps,
        type,
      }
      result = globalStandard
    }
  } else if (type === ProductType.Digital || type === ProductType.FarmBalance) {
    const baseProd = getBaseProd(obj)
    const digitalCommon = getDigitalCommon(obj)
    const stockDependent = getStockDep({ ...obj, type })
    const digitalProps = intersect(digitalCommon, stockDependent)
    const digitalStandard: DigitalStandard = {
      ...baseProd,
      ...digitalProps,
      type: ProductType.Digital,
    }
    const farmBalance: FarmBalance = {
      ...baseProd,
      ...digitalProps,
      type: ProductType.FarmBalance,
    }
    result = type === ProductType.Digital ? digitalStandard : farmBalance
  } else {
    throw new Error('Not implemented')
  }
  return result
}

const getBaseProd = ({
  category,
  description,
  farm,
  images,
  longDescription,
  name,
  type,
  id,
  isHidden,
  isPrivate,
  urlSafeSlug,
  ...rest
}: ProductFields): BaseProduct => {
  if (!category) throw new Error('Missing category')
  if (!description) throw new Error('Missing description')
  if (!farm) throw new Error('Missing farm')
  if (!images?.length) throw new Error('Missing images')
  if (!longDescription || !longDescription.length) throw new Error('Missing long description')
  if (!name || !name.length) throw new Error('Missing name')
  if (!type) throw new Error('Missing type')
  if (typeof isHidden !== 'boolean') throw new Error('isHidden should not be undefined')
  if (typeof isPrivate !== 'boolean') throw new Error('isPrivate should be a boolean')
  if (!urlSafeSlug || !isValidSlug(urlSafeSlug))
    throw new ErrorWithCode({
      code: 'validation-error',
      devMsg: 'urlSafeSlug does not comply with the basic slug validation requirements in isValidSlug()',
      uiMsg: 'Name is invalid, name must contain at least 3 consecutive letters',
    })
  const taxesAndFees = validateTaxesAndFees(rest.taxesAndFees)

  return {
    category,
    description,
    farm,
    images,
    longDescription,
    name,
    type,
    isHidden,
    isPrivate,
    urlSafeSlug,
    taxesAndFees,
    id: id as string,
    csa: rest.csa,
    hashtags: rest.hashtags,
    isDraft: rest.isDraft,
    isFeatured: rest.isFeatured,
    purchasedQuantity: rest.purchasedQuantity,
  }
}

/** This function is used to validate taxesAndFees object for all products */
const validateTaxesAndFees = (taxesAndFees: ProductFields['taxesAndFees']): BaseProduct['taxesAndFees'] => {
  const localTaxesAndFees = deepClone(taxesAndFees)

  if (!localTaxesAndFees || !isObject(localTaxesAndFees)) throw new Error('Missing taxes and fees')
  if (typeof localTaxesAndFees.isTaxExempt !== 'boolean') throw new Error('isTaxExempt should be a boolean')

  if (localTaxesAndFees.fees) {
    localTaxesAndFees.fees.forEach((fee) => {
      entries(fee).forEach(([key, value]) => {
        if (!isNonNullish(value)) throw new Error(`One of taxes and fees is missing a value for field ${key}`)
      })
    })
  }

  return localTaxesAndFees
}

const getBasePhysical = (product: ProductFields): PhysicalBase => {
  const { distributions, producer, ...rest } = product
  if (!distributions) throw new Error('Missing distributions')

  distributions.forEach((dist) => {
    validateProdDistro(product as Product, dist)
  })

  return {
    distributions,
    distributionConstraints: rest.distributionConstraints,
    productionMethod: rest.productionMethod,
    certification: rest.certification,
    producer,
  }
}

/** Checks whether the distro is compatible with the product.
 * - Considers any distro constraint inside the product, for the distro being checked
 * - Checks that both the dateRange and frequency constraints are valid
 * - Checks the number of pickups fits with the distro and constraint
 * - Throws human readable error messages */
export const validateProdDistro = (prod: Product, dist: Distribution): void => {
  if (isPhysical(prod)) {
    const constraint = prod.distributionConstraints?.find((constraint) => dist.id === constraint.id)

    //Validate date constraint
    if (constraint?.dateRange) {
      try {
        validateScheduleNDateConstr(constraint?.dateRange, dist.schedule)
      } catch (err) {
        // Here we're simply re-throwing the error, but with the full data, for the message to include more details
        if (isProdErr(err)) {
          switch (err.data.code) {
            case 'NeedsEndDate':
              throw new ProductError({ code: 'NeedsEndDate', dist, prod })
            case 'InvalidDateRangeConstraint':
              throw new ProductError({ code: 'InvalidDateRangeConstraint', constraint, dist, prod })
            case 'InvalidSeason':
              throw new ProductError({ code: 'InvalidSeason', dist, prod })
            case 'InvalidConstraintStartDate':
              throw new ProductError({ code: 'InvalidConstraintStartDate', dist, constraint, prod })
            case 'InvalidConstraintEndDate':
              throw new ProductError({ code: 'InvalidConstraintEndDate', dist, constraint, prod })
          }
        } else throw err
      }
    }

    //Validate frequency constraint
    if (constraint?.frequency)
      try {
        validateFreqConstraint(
          constraint.frequency,
          dist.schedule.frequency,
          /** Should use strict true here because this validation is for db */ true,
        )
      } catch (err) {
        // re-throw with the complete data on the error constructor
        throw new ProductError({ code: 'InvalidFrequency', prod, dist, constraint })
      }
  }

  validateConstraintNumberPickups(dist, prod)
}

const getShareCommon = ({
  csa,
  numberPickups,
  paymentSchedules,
  quantity,
  shareInfo,
  sku,
  vacationWeeks,
  ...rest
}: ProductFields): ShareCommon => {
  if (!csa) throw new Error('Missing csa')

  if (!numberPickups) throw new Error('Missing numberPickups')
  if (typeof numberPickups !== 'number') throw new Error('numberPickups must be a number')
  if (!Number.isInteger(numberPickups)) throw new Error('Number of pickups must be a whole number')
  if (numberPickups === 0) throw new Error('You cannot have a share with zero pickups')

  if (!paymentSchedules || !paymentSchedules.length) throw new Error('Missing payment schedules')
  validatePaymentSchedules(paymentSchedules, { ...rest, numberPickups } as PartialPick<
    Share,
    'type' | 'numberPickups' | 'distributions'
  >)

  if (quantity === undefined) throw new Error('Share must have quantity')
  if (!shareInfo) throw new Error('Missing shareInfo')
  if (vacationWeeks === undefined) throw new Error('Missing vacationWeeks')
  return {
    csa,
    numberPickups,
    paymentSchedules,
    quantity,
    shareInfo,
    sku,
    vacationWeeks,
    cancellationPolicy: rest.cancellationPolicy,
    isChild: rest.isChild,
    templateProduct: rest.templateProduct,
    waitlist: rest.waitlist,
  }
}

type validatePaymentSchedulesOpts = {
  /** If true, the payment schedule's last payment date will be allowed even if it's before the first pickup of any schedule */
  allowLastPymtDateEarly: boolean
}

/** Checks that each paymentSchedule has compatible payment-end-dates for each distribution-schedule. */
export const validatePaymentSchedules = (
  paySchedules: Share['paymentSchedules'],
  rest: PartialPick<Share, 'type' | 'numberPickups' | 'distributions'>,
  opts: validatePaymentSchedulesOpts = { allowLastPymtDateEarly: true },
) => {
  paySchedules.forEach((ps) => {
    if (isPayInFull(ps)) return

    //Check deposit and total price. Deposit should be less thant Total Price. Otherwise, throw error here.
    if (MoneyCalc.isGreaterThan(ps.deposit, ps.amount))
      throw new Error(`Deposit in payment schedule "${ps.frequency}" must be less than the Total Price.`)

    if (!ps.paymentDates.endDate)
      throw new Error(`Payment schedule "${ps.frequency}" must have a final installment date.`)

    // This section is validating the first and last payment dates in relation to the pickups for each schedule.
    rest.distributions?.forEach((sch) => {
      // The pickups should be calculated from the start of the schedule (pre-season date), so we can compare the pymt dates against the original pickup dates, in the case the product is old data from past seasons
      const pickups = getDistributionPickups(
        sch,
        { ...rest, distributions: rest.distributions ?? [] },
        { excludeHiddenDistros: true, excludeClosedDistros: true },
      )
      if (
        pickups.length &&
        !opts.allowLastPymtDateEarly &&
        isBefore(ps.paymentDates.endDate, pickups[0], { granularity: 'day', zone: sch.location.timezone })
      )
        throw new ProductError({ code: 'LastPaymentDateTooEarly', dist: sch, frequency: ps.frequency })

      // Scenario "last payment date too late": We don't need to make sure the endDate is before the last pickup. If the endDate happened after the last pickup, invoices will be unaffected. I.e. the last payment date would be ignored because it is not constraining the invoices to end earlier than the last pickup, which is the sole purpose of the last payment date.
    })
  })
}

/** Extracts the properties common to standard products */
const getStandardCommon = ({
  ebtEligibility,
  commodity,
  costOfProduction,
  disableBuyInFuture,
  variety,
  minPickups,
}: ProductFields): StandardCommon => {
  if (!isValidEbtEligibility(ebtEligibility)) throw new Error('Invalid SNAP/EBT Eligibility')

  return {
    ebtEligibility,
    commodity,
    costOfProduction,
    disableBuyInFuture,
    variety,
    minPickups,
  }
}

/** Fields common to product types that use units, for example standard and digital types with units */
const getUnitCommon = ({ baseUnit, unitStock, hideFromShop, ...rest }: ProductFields): UnitProductCommon => {
  if (!baseUnit) throw new Error('Missing baseUnit')
  if (unitStock === undefined) throw new Error('Missing unitStock field')
  if (hideFromShop && !rest.csa?.length) {
    throw new Error('If "Only show on CSA" is active, you must select at least one CSA')
  }

  return {
    baseUnit,
    unitStock,
    quantity: rest.quantity,
    unitSkuPrefix: rest.unitSkuPrefix,
    pricePerUnit: rest.pricePerUnit,
    hideFromShop,
  }
}

function getStockDep(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Digital | ProductType.FarmBalance },
): StockDependent<StockType.Global>
function getStockDep(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Standard; unitStock: true },
): StockDependent<StockType.Unit>
function getStockDep(
  obj: Omit<ProductFields, 'type' | 'unitStock'> & { type: ProductType.Standard; unitStock?: false | undefined },
): StockDependent<StockType.Global>
function getStockDep({ unitStock, units, pricePerUnit, quantity }: ProductFields): StockDependent {
  if (!units) throw new Error('Missing units')

  if (unitStock) {
    const unitStockUnits: UnitBase<StockType.Unit>[] = units
      .map((u) => {
        if (isGlobalUnit(u)) throw new Error('UnitStock unit should have unit quantity')
        const unitQty = u.quantity
        if (typeof unitQty !== 'number' || unitQty < 0) throw new Error('Quantity must be a positive number')
        if (typeof u.multiplier !== 'number' || u.multiplier < 0)
          throw new Error('Multiplier must be a positive number')
        return u
      })
      .filter(isStockUnit)
    if (!pricePerUnit) throw new Error('UnitStandard products should have pricePerUnit')
    if (quantity !== undefined) throw new Error("UnitStandard products shouldn't have global quantity")

    const stockDepUnit: StockDependent<StockType.Unit> = {
      units: unitStockUnits,
      pricePerUnit,
      unitStock: true,
    }
    return stockDepUnit
  } else {
    const globStockUnits: UnitBase<StockType.Global>[] = units
      .map((u) => {
        if (isStockUnit(u)) throw new Error("GlobalStandard products unit shouldn't have unit quantity")
        if (typeof u.multiplier !== 'number' || u.multiplier < 0)
          throw new Error('Multiplier must be a positive number')
        return u
      })
      .filter(isGlobalUnit)
    if (pricePerUnit !== undefined) throw new Error("GlobalStandard products shouldn't have pricePerUnit")
    if (quantity === undefined) throw new Error('GlobalStandard products should have global quantity')
    if (typeof quantity !== 'number' || quantity < 0) throw new Error('Quantity must be a positive number')

    const stockDepUnit: StockDependent<StockType.Global> = {
      units: globStockUnits,
      pricePerUnit,
      unitStock: false,
      quantity,
    }
    return stockDepUnit
  }
}

const getDigitalCommon = ({
  baseUnit,
  unitStock,
  certification,
  commodity,
  costOfProduction,
  distributionConstraints,
  distributions,
  producer,
  productionMethod,
  variety,
  pricePerUnit,
  hideFromShop,
  disableBuyInFuture,
  ...rest
}: ProductFields): Undefine<PhysicalBase> & Undefine<UnitProductCommon, 'pricePerUnit'> => {
  if (!baseUnit) throw new Error('Missing baseUnit')
  if (unitStock) throw new Error('Digital products should be unitStock false')
  if (certification) throw new Error("Digital products shouldn't have field 'certification'")
  if (commodity) throw new Error("Digital products shouldn't have field 'commodity'")
  if (costOfProduction) throw new Error("Digital products shouldn't have field 'costOfProduction'")
  if (distributionConstraints) throw new Error("Digital products shouldn't have distributionConstraints")
  if (distributions) throw new Error("Digital products shouldn't have field 'distributions'")
  if (typeof producer === 'string') throw new Error("Digital products shouldn't have field 'producer'")

  if (typeof productionMethod === 'string') throw new Error("Digital products shouldn't have field 'productionMethod'")
  if (typeof variety === 'string') throw new Error("Digital products shouldn't have field 'variety'")
  if (pricePerUnit !== undefined) throw new Error("Digital products shouldn't have field 'pricePerUnit'")
  if (hideFromShop && !rest.csa?.length) {
    throw new Error('If "Only show on CSA" is active, you must select at least one CSA')
  }
  if (disableBuyInFuture !== undefined) throw new Error("Digital products shouldn't have a 'disableBuyInFuture' field")
  return {
    baseUnit,
    unitStock: false,
    certification,
    distributionConstraints,
    distributions,
    pricePerUnit,
    producer,
    productionMethod,
    quantity: rest.quantity,
    unitSkuPrefix: rest.unitSkuPrefix,
    hideFromShop,
  }
}

/** Converts a product type and unitStock property into a product subtype */
export type ProductSubType<
  T extends ProductFields['type'],
  U extends ProductFields['unitStock'] = undefined,
> = T extends ProductType.PrimaryShare
  ? PrimaryShare
  : T extends ProductType.AddonShare
  ? AddonShare
  : T extends ProductType.Digital
  ? DigitalStandard
  : { type: T; unitStock: U } extends { type: ProductType.Standard; unitStock: true }
  ? UnitStandard
  : { type: T; unitStock: U } extends { type: ProductType.Standard; unitStock: false | undefined }
  ? GlobalStandard
  : T extends ProductType.Standard
  ? Standard
  : never

/** Will verify whether a share's number of pickups can fit within the effective pickup window of a product + distro combo,
 * considering any distro constraints.
 */
export const validateConstraintNumberPickups = (dist: Distribution, product: Product): void => {
  // For all non-shares we don't need to validate number of pickups
  if (!isShare(product)) return

  /** Get all pickups from before the start of the distribution, considering distro constraints
  This method of obtaining the maximum possible pickups ensures the calculation remains the same even if the share is in the past */
  const maxPossiblePickups = getDistributionPickups(dist, product).length

  // Make sure that we have at least as many pickups as we are expecting available in the date range
  if (maxPossiblePickups < product.numberPickups) {
    throw new ProductError({
      code: 'MissingPickups',
      dist,
      distPickups: maxPossiblePickups,
      numberPickups: product.numberPickups,
      prod: product,
    })
  }
}

/**
 * Checks whether a frequency constraint can be applied to a distribution frequency.
 * @param freqConstrain the frequency constraint to be applied. It must be narrower (or equal if non strict) than the distribution frequency.
 * @param distroFreq the normal frequency of the distribution
 * @param strict if true, constraint must be narrower than distro freq. Else, constraint can be equal.
 * @returns void. Throws an error if the constraint is invalid.
 */
export const validateFreqConstraint = (freqConstrain: Frequency, distroFreq: Frequency, strict = true): void => {
  if (!isWiderFreq(freqConstrain, distroFreq, strict)) throw new ProductError({ code: 'InvalidFrequency' })
}

/** EBT ELIGIBILITY */
/**
 * Checks if the provided EbtEligibility value is valid.
 * @param ebtEligibility - The value to check.
 * @returns A boolean indicating whether the provided value is a valid EbtEligibility.
 */
export const isValidEbtEligibility = (ebtEligibility: any): ebtEligibility is EbtEligibility => {
  return Object.values(EbtEligibility).some((v) => v === ebtEligibility)
}
