import { INITIAL_LIMIT_N_ORDERS, snapshotOrdersByUserAndDueDate } from '@api/Orders'
import { LoaderWithMessage } from '@components'
import { Spinner, Text } from '@elements'
import { getOrderItem } from '@helpers/display'
import { Order } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import { memo, useCallback, useContext, useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { OrdersParamList } from '../../navigation/types'
import { addNavProp } from '../../redux/actions/appState'
import { userSelector } from '../../redux/selectors'
import { OrderCard } from './OrderCard'

import { Logger } from '@/config/logger'
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll'
import { OrdersScreenContext } from './OrdersScreen'

export const OrderTab = memo(function OrderTab() {
  const [{ orders, pickups, tab, showEmptyOrders }, set] = useContext(OrdersScreenContext)
  const visible = tab === 'orders'
  const user = useSelector(userSelector)
  const navigation = useNavigation<StackNavigationProp<OrdersParamList, 'Orders'>>()
  const dispatch = useDispatch()

  const { increaseLimit, limit, loading, setLoading, forceIncreaseLimit } = useInfiniteScroll(
    INITIAL_LIMIT_N_ORDERS,
    orders.length,
  )

  useEffect(() => {
    setLoading(true)
    return snapshotOrdersByUserAndDueDate(
      (newOrders: Order[]) => {
        set('orders', newOrders)
        disableLoading()
      },
      undefined,
      user.id,
      limit > MaxLimit ? null : limit,
    )
    // No other more dependencies should be passed, in order to avoid unintented snapshot calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, user.id])

  /** If a pickup item cannot be found on the orders, we should fetch more orders*/
  useEffect(() => {
    const notFound = pickups.flatMap((p) => p.items).filter((pItm) => !getOrderItem(orders, pItm))

    // This will increase the snapshot limit if some order items weren't found, up to a max limit
    if (notFound.length > 0 && limit < MaxLimit) {
      forceIncreaseLimit(notFound.length * 4)
    } else if (notFound.length > 0) {
      Logger.error(new Error('Some pickup items were never found in the orders.'), { notFound })
    }
  }, [pickups, orders, limit])

  const emptyOrderFilter = useCallback(
    (order: Order) => {
      if (showEmptyOrders) return true
      // Show only if the order has at least 1 uncanceled item
      return order.items.filter((itm) => !itm.cancelled).length !== 0
    },
    [showEmptyOrders],
  )
  /** This delay ensures that the dispatch has finished */
  const disableLoading = () =>
    setTimeout(() => {
      setLoading(false)
    }, 150)

  const goToOrderDetails = useCallback((order: Order) => {
    dispatch(addNavProp({ order }))
    navigation.navigate('OrderSummary', { orderId: order.id })
  }, [])

  const ListEndLoader = useCallback(() => {
    if (loading) {
      return <Spinner />
    }
    return <View />
  }, [loading])

  const renderItem = useCallback(
    (info: ListRenderItemInfo<Order>) => {
      return <OrderCard order={info.item} goToOrderDetails={goToOrderDetails} />
    },
    [goToOrderDetails],
  )
  if (!visible) return <View />

  return (
    <FlashList
      onEndReached={increaseLimit}
      onEndReachedThreshold={0.9}
      estimatedItemSize={400}
      ListFooterComponent={ListEndLoader}
      renderItem={renderItem}
      data={orders.filter(emptyOrderFilter)}
      ListEmptyComponent={
        <LoaderWithMessage loading={loading} icon="shopping-bag" title="No Orders!">
          <Text>After you make a purchase on GrownBy, you can find your orders here.</Text>
        </LoaderWithMessage>
      }
    />
  )
})
export const MaxLimit = 300
