import { dequal } from 'dequal'
import { DependencyList, EffectCallback, useCallback, useEffect, useMemo, useRef } from 'react'

/** Function that compares 2 values of the same type and returns true if they're the same, false if they're different */
type IsEqualCheck<T> = (v1: T, v2: T) => boolean

/**
 * Return a memoized value only when it has had a deep inequality
 * - A custom inequality comparison fn can be provided
 * - Adapted and simplified from https://github.com/kentcdodds/use-deep-compare-effect/blob/main/src/index.ts */
export function useDeepCompareMemoize<T>(value: T, isEqual: IsEqualCheck<T> = dequal) {
  const ref = useRef<T>(value)
  const signalRef = useRef<number>(0)

  if (!isEqual(value, ref.current)) {
    ref.current = value
    signalRef.current += 1
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current])
}

/** useMemo that changes only if the dependencies have a deep inequality. This should only be used when there are complex
 * dependencies that cannot be simplified. Ex. If it is a date or something that can be represented as a primitive that is ideal */
export function useDeepCompareMemo<T>(factory: () => T, deps: DependencyList): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(factory, useDeepCompareMemoize(deps))
}

/** useCallback that changes only if the dependencies have a deep inequality. This should only be used when there are complex
 * dependencies that cannot be simplified. Ex. If it is a date or something that can be represented as a primitive that is ideal */
export function useDeepCompareCallback<T extends Function>(
  callback: T,
  deps: DependencyList,
  isEqualDeps?: IsEqualCheck<typeof deps>,
): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, useDeepCompareMemoize(deps, isEqualDeps))
}

/** useEffect that runs only if the dependencies have a deep inequality */
export default function useDeepCompareEffect(callback: EffectCallback, dependencies: DependencyList) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, useDeepCompareMemoize(dependencies))
}
