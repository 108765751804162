import { HeaderText } from '@elements'
import { Ionicons } from '@expo/vector-icons'
import { memo } from 'react'
import { Pressable, View } from 'react-native'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { ShoppingStackParamList } from '@/navigation/types'
import { paramsSelector } from '@/redux/selectors'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import ShoppingCart from '../../components/ShoppingCart'

export const MobileCsaHeader = memo(function MobileCsaHeader() {
  const { navigate } = useNavigation<StackNavigationProp<ShoppingStackParamList, 'CSADetails'>>()
  const {
    params: { farmSlug, goBack },
  } = useRoute<RouteProp<ShoppingStackParamList, 'CSADetails'>>()
  const { csa } = useSelector(paramsSelector)

  const styles = useLayoutFnStyles(({ top }) => ({
    main: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: top,
      paddingBottom: 10,
      paddingHorizontal: 10,
      justifyContent: 'space-between',
    },
  }))

  return (
    <View style={styles.main}>
      <Pressable
        onPress={() => {
          if (goBack === 'home') navigate('Home')
          else navigate('FarmShop', { farmSlug })
        }}
      >
        <Ionicons name="arrow-back" size={27} color="black" />
      </Pressable>
      <HeaderText size={18} children={csa?.name ?? 'CSA Details'} />
      <ShoppingCart />
    </View>
  )
})
