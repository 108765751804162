import { getOrderNum, getPrice, pickupPriceRangeString, unmarshalPhoneNumber } from '@helpers/display'
import { removeObjDuplicates } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { orderItemTotal } from '@helpers/order'
import { omit } from '@helpers/typescript'

import {
  getCardPrice,
  getLastAvailTimestamp,
  getProductAvailability,
  getStock,
  isInStock,
  isPrivate,
  isProductEbtEligible,
  isProductEbtOnly,
  shouldShow,
} from '@helpers/products'
import {
  AlgoliaAdminCustomer,
  AlgoliaAdminInvoice,
  AlgoliaAdminMeta,
  AlgoliaAdminMetaArr,
  AlgoliaAdminOrder,
  AlgoliaAdminOrderMeta,
  AlgoliaAdminProduct,
  AlgoliaDocType,
  AlgoliaGeoDistro,
  AlgoliaGeoDoc,
  AlgoliaGeoFarm,
  AlgoliaGeoProduct,
  AlgoliaIndexType,
  AlgoliaShareCategory,
} from '@models/Algolia'
import { CSA } from '@models/CSA'
import { Distribution, isDistroLocalPickup } from '@models/Distribution'
import { Farm } from '@models/Farm'
import { Invoice } from '@models/Invoice'
import { Zero } from '@models/Money'
import { Order, orderStatusText } from '@models/Order'
import { FarmBalance } from '@models/Payment'

import log from '@helpers/log'
import { chainPaymentsMethod } from '@helpers/paymentMethodDisplay'
import { formatScheduleText } from '@helpers/schedule'
import { displayShortDateRange } from '@helpers/time'
import { hasUnits, hasUnitStock, isDigital, isPhysical, isShare, Product } from '@models/Product'
import { FarmAssociation, User, userName } from '@models/User'
import { DateTime } from 'luxon'

/**
 * CONSUMER DOCS
 */
export const buildAlgoliaFarm = (farm: Farm): AlgoliaGeoDoc['farm'] => ({
  id: farm.id,
  name: farm.name,
  logo: farm.logo,
  practices: farm.practices,
  tags: Object.keys(farm.properties || {}),
  reviews: farm.reviews,
  hasProducts: farm.productCount > 0,
  setupCount: Object.keys(farm.onboardSteps || {}).length,
  productCount: farm.productCount || 0,
  status: farm.status,
  urlSafeSlug: farm.urlSafeSlug,
})

type AlgoliaGeoDocType = AlgoliaDocType.FARM | AlgoliaDocType.DISTRO | AlgoliaDocType.PRODUCT

//Generic type that maps AlgoliaDocType.XXX enums into `AlgoliaGeo${XXX}` SubDoc types
export type SubDoc<T extends AlgoliaGeoDocType> = T extends AlgoliaDocType.FARM
  ? AlgoliaGeoFarm
  : T extends AlgoliaDocType.DISTRO
  ? AlgoliaGeoDistro
  : T extends AlgoliaDocType.PRODUCT
  ? AlgoliaGeoProduct
  : unknown

type CreateGeoDocInputBase = {
  docType: AlgoliaGeoDocType
  farm: Farm
  distro?: Distribution
  prod?: Product
  /** csas are necessary to create a product doc */
  csas?: CSA[]
}

type CreateGeoDocInput<T extends AlgoliaGeoDocType> = {
  docType: T
  farm: Farm
  /** distro is expected for product and distro docType creation */
  distro?: Distribution
  prod?: T extends AlgoliaDocType.PRODUCT ? Product : undefined
  /** csas is expected for product creation */
  csas?: T extends AlgoliaDocType.PRODUCT ? CSA[] : undefined
}

export function createGeoDoc(input: CreateGeoDocInput<AlgoliaDocType.FARM>): AlgoliaGeoDoc<AlgoliaGeoFarm> | undefined
export function createGeoDoc(
  input: CreateGeoDocInput<AlgoliaDocType.DISTRO>,
): AlgoliaGeoDoc<AlgoliaGeoDistro> | undefined
export function createGeoDoc(
  input: CreateGeoDocInput<AlgoliaDocType.PRODUCT>,
): AlgoliaGeoDoc<AlgoliaGeoProduct> | undefined
/** Assembles a geodoc, based on the arguments */
export function createGeoDoc({ docType, farm, distro, prod, csas }: CreateGeoDocInputBase): AlgoliaGeoDoc | undefined {
  try {
    switch (docType) {
      case AlgoliaDocType.FARM: {
        const images = farm.media.map((obj) => obj.storageUrl)
        const farmDoc: AlgoliaGeoDoc<AlgoliaGeoFarm> = {
          index: AlgoliaIndexType.geosearch,
          objectID: farm.id,
          id: farm.id,
          images,
          imageSort: images.length ? 1 : 0,
          name: farm.name,
          address: omit(farm.address, 'coordinate'),
          locationCount: farm.locationCount,
          description: farm.about.substring(0, 500),
          _geoloc: {
            lat: farm.address.coordinate.latitude,
            lng: farm.address.coordinate.longitude,
          },
          isEbt: farm.paymentTypes.ebt,
          docType,
          farm: buildAlgoliaFarm(farm),
        }

        return farmDoc
      }
      case AlgoliaDocType.DISTRO: {
        if (!distro) return undefined
        const images = farm.media.map((obj) => obj.storageUrl)
        const distroDoc: AlgoliaGeoDoc<AlgoliaGeoDistro> = {
          index: AlgoliaIndexType.geosearch,
          objectID: distro.id,
          id: distro.id,
          name: distro.name,
          images,
          imageSort: images.length ? 1 : 0,
          isHidden: distro.isHidden,
          address:
            isDistroLocalPickup(distro) && distro.location.address
              ? omit(distro.location.address, 'coordinate')
              : omit(farm.address, 'coordinate'),
          scheduleText: formatScheduleText(distro),
          distroNickname: distro.location.nickname || distro.location.name,
          _geoloc: {
            lat:
              isDistroLocalPickup(distro) && distro.location.address
                ? distro.location.address.coordinate.latitude
                : farm.address.coordinate.latitude,
            lng:
              isDistroLocalPickup(distro) && distro.location.address
                ? distro.location.address.coordinate.longitude
                : farm.address.coordinate.longitude,
          },
          locationId: distro.location.id,
          isEbt: farm.paymentTypes.ebt,
          docType,
          farm: buildAlgoliaFarm(farm),
          description: farm.about.substring(0, 500),
        }

        return distroDoc
      }
      case AlgoliaDocType.PRODUCT: {
        if (!prod || !csas) return undefined

        // Check the csas array has all the csas for the product
        if (prod.csa?.length && prod.csa.some((id) => !csas.find((csa) => csa.id === id))) {
          return undefined
        }

        const categories = [prod.category]
        if (isShare(prod) || prod.csa?.length) categories.push(AlgoliaShareCategory)

        const lastAvailStamp: number = getLastAvailStampNumericFilter(prod)

        const allProdLocations = prod.distributions?.map((d) => ({ id: d.location.id, name: d.location.name })) ?? []

        // Make sure the locations are unique
        const uniqueProdLocations = removeObjDuplicates(allProdLocations, (obj) => obj.id)

        // We should hide if either the distribution or the product is hidden, or if the share has only hidden csas
        const prodCsas = csas.filter((csa) => prod.csa?.includes(csa.id))
        const isHidden =
          distro?.isHidden ||
          prod.isHidden ||
          (isShare(prod) && (!prodCsas.length || prodCsas.every((csa) => csa.isHidden))) ||
          !shouldShow(prod)

        const prodDoc: AlgoliaGeoDoc<AlgoliaGeoProduct> = {
          index: AlgoliaIndexType.geosearch,
          objectID: getProductObjectID(prod, distro),
          id: prod.id,
          isHidden,
          urlSafeSlug: prod.urlSafeSlug,
          // Set the distribution name as nickname here
          distroNickname: distro?.name ?? '',
          name: prod.name,
          images: prod.images,
          imageSort: prod.images.length ? 1 : 0,
          description: prod.longDescription,
          category: categories,
          price: isShare(prod) ? pickupPriceRangeString(prod) : getPrice(prod),
          priceInCard: getCardPrice(prod),
          type: prod.type,
          shortDescription: prod.description,
          _geoloc: {
            lat:
              distro && isDistroLocalPickup(distro) && distro.location.address
                ? distro.location.address.coordinate.latitude
                : farm.address.coordinate.latitude,
            lng:
              distro && isDistroLocalPickup(distro) && distro.location.address
                ? distro.location.address.coordinate.longitude
                : farm.address.coordinate.longitude,
          },
          isEbt: isProductEbtEligible(prod),
          isEbtOnly: isProductEbtOnly(prod),
          scheduleText: formatScheduleText(distro),
          docType,
          farm: buildAlgoliaFarm(farm),
          address:
            distro && isDistroLocalPickup(distro) && distro.location.address
              ? omit(distro.location.address, 'coordinate')
              : omit(farm.address, 'coordinate'),
          lastAvailStamp,
          isInStock: isInStock(prod),
          isPrivate: isPrivate(prod, prodCsas),
          hideFromShop: hasUnits(prod) && !!prod.hideFromShop,
          csa: prod.csa ?? [],
          locations: uniqueProdLocations,
          isFeatured: prod.isFeatured ?? false,
        }

        return prodDoc
      }
      default: {
        return undefined
      }
    }
  } catch (e) {
    log(e)
    return undefined
  }
}

/**
 * ADMIN ALGOLIA INDICES
 */

function createOrderMeta(order: Order): AlgoliaAdminOrderMeta {
  const date = order.date.toMillis()
  const csas = order.items.filter((itm) => itm.csa?.id).map((itm) => ({ id: itm.csa!.id, name: itm.csa!.name, date }))
  const products = order.items.map((itm) => ({ id: itm.product.id, name: itm.product.name, date }))
  const locations = order.items
    .filter((itm) => !!itm.distribution)
    .map((itm) => ({ id: itm.distribution!.location.id, name: itm.distribution!.location.name, date }))
  const distributions = order.items
    .filter((itm) => !!itm.distribution)
    .map((itm) => ({ id: itm.distribution!.id, name: itm.distribution!.name, date }))
  return {
    csas: removeObjDuplicates(csas),
    products: removeObjDuplicates(products),
    locations: removeObjDuplicates(locations),
    distributions: removeObjDuplicates(distributions),
  }
}

/**
 *
 * @param product db product
 * @param csasArg csas array. Could be all of the farm csas, or only the product csas. This fn will only select those csas whose ids are in the product.csa ids list.
 * @returns
 */
function createProductMeta(product: Product, csasArg?: CSA[]): AlgoliaAdminMeta {
  const date = 0
  let csas: AlgoliaAdminMetaArr = []
  if (csasArg) {
    csas = csasArg.filter((csa) => product.csa?.includes(csa.id)).map((csa) => ({ id: csa.id, name: csa.name, date }))
  }
  const locations = (product.distributions ?? [])
    .filter((itm) => !!itm.location?.name)
    .map((itm) => ({ id: itm.location.id, name: itm.location.name, date }))
  const distributions = (product.distributions ?? [])
    .filter((itm) => !!itm.name)
    .map((itm) => ({ id: itm.id, name: itm.name, date }))
  return {
    csas: removeObjDuplicates(csas),
    locations: removeObjDuplicates(locations),
    distributions: removeObjDuplicates(distributions),
  }
}

export const createAlgoliaAdminOrder = (order: Order): AlgoliaAdminOrder => {
  const orderSubtotal = order.items.map((itm) => orderItemTotal(itm)).reduce((a, b) => MoneyCalc.add(a, b), Zero)
  return {
    index: AlgoliaIndexType.admindata,
    id: order.id,
    objectID: order.id,
    docType: AlgoliaDocType.ORDER,
    farmId: order.farm.id,
    orderNum: order.orderNum,
    alternateOrderNums: [getOrderNum(order.orderNum).replace('#', ''), (order.orderNum || '').toString()],
    date: order.date.toMillis(),
    total: orderSubtotal.value,
    user: {
      id: order.user.id,
      name: userName(order.user),
      email: order.user.email,
    },
    status: orderStatusText(order),
    ...createOrderMeta(order),
  }
}

export const createAlgoliaAdminInvoice = (invoice: Invoice): AlgoliaAdminInvoice => {
  return {
    index: AlgoliaIndexType.admindata,
    id: invoice.id,
    objectID: invoice.id,
    docType: AlgoliaDocType.INVOICE,
    status: invoice.status,
    farmId: invoice.farm.id,
    date: invoice.dueDate.toMillis(),
    amountDue: invoice.amountTotal.value,
    invoiceNum: invoice.invoiceNum,
    payUrl: invoice.payUrl,
    pdf: invoice.pdf,
    order: {
      id: invoice.order.id,
      orderNum: invoice.order.orderNum,
    },
    user: {
      id: invoice.user.id,
      name: userName(invoice.user),
      email: invoice.user.email,
    },
    paymentMethods: chainPaymentsMethod(invoice),
    paymentMethodsDisplay: chainPaymentsMethod(invoice, true),
  }
}

/** Gets a string that will represent the pre-calculated product availability for display purposes */
export const getAvailabilityDisplay = (prod: Product) => {
  if (isDigital(prod)) return 'Digital'

  const availDates = getProductAvailability(prod, undefined, {
    excludeHiddenDistros: true,
    excludeClosedDistros: true,
    zone: prod.farm.timezone,
  })

  if (!availDates) return ''

  return displayShortDateRange(availDates)
}

/** Assembles an admin product document */
export function createAlgoliaAdminProduct(prod: Product, csas?: CSA[]): AlgoliaAdminProduct {
  // Generate categories
  const categories = [prod.category]
  if (isShare(prod) || prod.csa?.length) categories.push(AlgoliaShareCategory)

  return {
    index: AlgoliaIndexType.admindata,
    id: prod.id,
    objectID: prod.id,
    farmId: prod.farm.id,
    docType: AlgoliaDocType.PRODUCT,
    type: prod.type,
    unitStock: hasUnits(prod) && prod.unitStock,
    name: prod.name,
    description: prod.description,
    longDescription: prod.longDescription,
    skuDisplay: isShare(prod) ? prod.sku || '' : prod.unitSkuPrefix || '',
    productionMethod: prod.productionMethod || '',
    category: categories,
    isHidden: prod.isHidden,
    lastAvailStamp: getLastAvailStampNumericFilter(prod),
    isEbt: isProductEbtEligible(prod),
    isEbtOnly: isProductEbtOnly(prod),
    isFeatured: !!prod.isFeatured,
    image: prod.images[0],
    price: getPrice(prod),
    pricePerUnit: hasUnitStock(prod) ? prod.pricePerUnit : undefined,
    stock: getStock(prod),
    urlSafeSlug: prod.urlSafeSlug,
    availabilityDisplay: getAvailabilityDisplay(prod),
    ...createProductMeta(prod, csas),
  }
}

/** This should be the single source of logic for how to create an algolia customer.
 * - It should be used in the server and gb-cli scripts alike. For that, it needs to be agnostic to how data is loaded or saved.
 * So it needs to receive all the necessarydata and only execute the creation logic synchronously.
 * @param association the FarmAssociation between the user and the farm
 * @param user the firestore user
 * @param orders aray of orders between the `user` and the farm in `association`.
 * if not provided, it will produce empty arrays for metadata attributes.
 * can be used with a single order, when updating the metadata only.
 * @param farmBalance the farm balance between the user and the farm in `association`. May be undefined if not yet exists.
 * @returns Will always return an AlgoliaAdminCustomer, because no checks or async requests are involved.
 *  */
export function createAlgoliaAdminCustomer(
  association: FarmAssociation,
  user: User,
  orders?: Order[],
  farmBalance?: FarmBalance,
): AlgoliaAdminCustomer {
  const farmId = association.farmId || association.id
  const phoneNumber = user.phoneNumber || ''

  return {
    index: AlgoliaIndexType.admindata,
    id: user.id,
    objectID: user.id + '_' + farmId,
    docType: AlgoliaDocType.CUSTOMER,
    farmId,
    name: userName(user),
    email: user.email,
    phone: phoneNumber,
    alternatePhoneNums: [unmarshalPhoneNumber(phoneNumber), phoneNumber, phoneNumber.replace('+1 ', '')],
    farmCredit: farmBalance?.amount || 0,
    monthlyReloadAmt: farmBalance?.reload?.enabled ? farmBalance.reload.amount : 0,
    ...createCustomerMeta(orders),
  }
}

/** Takes in the orders between a customer and a farm, and creates the metadata for those orders.
 *  Can be used for a single order, when updating the metadata only */
function createCustomerMeta(orders?: Order[]): AlgoliaAdminMeta {
  const csas: AlgoliaAdminMetaArr = []
  const locations: AlgoliaAdminMetaArr = []
  const distributions: AlgoliaAdminMetaArr = []

  orders?.forEach((order) => {
    const date = order.date.toMillis()
    csas.push(
      ...order.items.filter((itm) => itm.csa?.id).map((itm) => ({ id: itm.csa!.id, name: itm.csa!.name, date })),
    )
    locations.push(
      ...order.items
        .filter((itm) => !!itm.distribution?.location?.name)
        .map((itm) => ({ id: itm.distribution!.location.id, name: itm.distribution!.location.name, date })),
    )
    distributions.push(
      ...order.items
        .filter((itm) => !!itm.distribution?.name)
        .map((itm) => ({ id: itm.distribution!.id, name: itm.distribution!.name, date })),
    )
  })
  return {
    csas: removeObjDuplicates(csas),
    locations: removeObjDuplicates(locations),
    distributions: removeObjDuplicates(distributions),
  }
}

/**This helper ensures all products in algolia have a lastAvailStamp numeric value.
 * - In some cases the value is coerced (as in digital products).
 */
function getLastAvailStampNumericFilter(prod: Product): number {
  const lastAvailStamp = getLastAvailTimestamp(prod)
  if (lastAvailStamp !== null) return lastAvailStamp.toMillis()

  if (isPhysical(prod)) {
    // If the lastAvailStamp is not available then return 0 because this means that the product is either invalid or does not have availability so we should not have a lastAvailStamp
    return 0
  } else {
    return DateTime.now().plus({ years: 100 }).toMillis()
  }
}

/** Creates the Algolia Object ID for a product.
 * - If product is physical, it requires a schedule.
 * - If product is digital, no schedule.
 */
export const getProductObjectID = (
  product: Product,
  distro?: Distribution,
): AlgoliaGeoDoc<AlgoliaGeoProduct>['objectID'] => {
  // If no distro then it means that is a digital product
  if (!distro) {
    if (!isDigital(product)) throw new Error('A physical product requires a schedule to create a geosearch object id')
    return `digital_${product.id}`
  }
  return `${distro.id}_${product.id}`
}
