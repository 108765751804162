import { Button, SearchInputBar } from '@elements'
import * as React from 'react'
import { useState } from 'react'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { connectSearchBox } from 'react-instantsearch-native'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import ConnectedRefinementDropdown, { RefinementDropdownExternalProps } from './ConnectedRefinementDropdown'

import { useFocusFx } from '@/hooks/useFocusFx'
import { useLayout } from '@/hooks/useLayout'

type Props = SearchBoxProvided & {
  placeholder?: string
  refinements?: RefinementDropdownExternalProps[]
  expandRefinementsInitial?: boolean
  style?: StyleProp<ViewStyle>
}

function SearchBox({ refine, refinements, expandRefinementsInitial, placeholder, style }: Props) {
  const { isSmallDevice } = useLayout()
  const [curText, setCurText] = useState<string>('')
  /** If false, the refinements buttons will be collapsed. Otherwise all will be shown */
  const [isExpandedRefinements, setIsExpandedRefinements] = useState(expandRefinementsInitial ?? !isSmallDevice)

  /** If there is a change in the initial value desired, apply it */
  useFocusFx(() => {
    if (expandRefinementsInitial) setIsExpandedRefinements(expandRefinementsInitial)
  }, [expandRefinementsInitial])

  //refine Helper -> work for current requirements
  const refineFunc = (value: string) => {
    let formattedCurrentRefinement: string = value
    setCurText(formattedCurrentRefinement)
    /** We want to remove the zeros before refinement because it helps match data in algolia */
    formattedCurrentRefinement = value.replace(/^0+/, '')
    refine(formattedCurrentRefinement)
  }

  return (
    <View style={[styles.container, style]}>
      {!!refinements && (
        <>
          {(isSmallDevice || !isExpandedRefinements) && refinements?.length > 0 && (
            <Button
              style={styles.buttonStyle}
              outline
              small={isSmallDevice}
              title={`${isExpandedRefinements ? 'Hide' : 'Show'} Filters`}
              icon="filter"
              onPress={() => setIsExpandedRefinements((prev) => !prev)}
            />
          )}
          {isExpandedRefinements && (
            <>
              {refinements.map((props, idx) => (
                <ConnectedRefinementDropdown key={`${props.placeholder}_${idx}`} {...props} />
              ))}
            </>
          )}
        </>
      )}
      <SearchInputBar
        onChangeText={refineFunc}
        value={curText}
        placeholder={placeholder}
        contStyle={styles.searchBarStyle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  searchBarStyle: {
    flex: 1,
    minWidth: 260,
    margin: 2,
    borderRadius: 8,
    height: 40,
    borderWidth: 1,
  },
  buttonStyle: {
    flex: 1,
    justifyContent: 'center',
    minWidth: 150,
    minHeight: 40,
    marginHorizontal: 2,
    marginVertical: 2,
    borderRadius: 8,
    paddingHorizontal: 0,
  },
})

export default connectSearchBox<Props>(SearchBox)
