import { combineReducers, Reducer } from 'redux'
import { PersistPartial } from 'redux-persist/es/persistReducer'

import { SIGN_OUT } from '../actions/actionTypes'
import adminPersist, { initialState as adminPersistDefault } from './adminPersist'
import adminState, { initialState as adminStateDefault } from './adminState'
import appPersist, { initialState as appPersistDefault } from './appPersist'
import appState, { initialState as appStateDefault } from './appState'
import { RootState } from './types'
import user, { initialState as userDefault } from './user'

export const defaultState: RootState & PersistPartial = {
  adminState: adminStateDefault,
  adminPersist: adminPersistDefault,
  appPersist: appPersistDefault,
  appState: appStateDefault,
  user: userDefault,
  _persist: { rehydrated: false, version: 0 },
}

/** Will unsubscribe from any global listeners and clear the app state */
export const resetState = (state: RootState): RootState => {
  //unsubscribe from any listeners
  state.adminState.farmUnListener?.()
  state.appState.unsubscribers.forEach((fn) => fn?.())

  const appState: RootState['appState'] = {
    ...state.appState,
    hasListeners: appStateDefault.hasListeners,
    unsubscribers: appStateDefault.unsubscribers,
    isAdminOpen: appStateDefault.isAdminOpen,
    currentHover: appStateDefault.currentHover,
    navParams: appStateDefault.navParams,
    region: appStateDefault.region,
    searchFilters: appStateDefault.searchFilters,
    searchLocation: appStateDefault.searchLocation,
  }

  const adminState: RootState['adminState'] = {
    ...state.adminState,
    farmUnListener: adminStateDefault.farmUnListener,
    CSAs: adminStateDefault.CSAs,
    featuresAvailable: adminStateDefault.featuresAvailable,
    navParams: adminStateDefault.navParams,
    orderCreatorCustomer: adminStateDefault.orderCreatorCustomer,
    queueAlgoliaProducts: adminStateDefault.queueAlgoliaProducts,
  }

  const newState: RootState = {
    appState,
    adminState,
    adminPersist: adminPersistDefault,
    appPersist: appPersistDefault,
    user: userDefault,
  }
  return newState
}

const rootReducer: Reducer<RootState> = (state, action) => {
  // Will reset all reducers except appState and admin
  if (action.type === SIGN_OUT && !!state) {
    state = resetState(state)
  }
  const allReducers = combineReducers({
    appState,
    appPersist,
    adminState,
    adminPersist,
    user,
  })
  return allReducers(state, action)
}

export default rootReducer

/** The slices of the state which get persisted */
export const persistWhiteList: (keyof RootState)[] = ['user', 'adminPersist', 'appPersist']
