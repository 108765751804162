import { Button, TextH3 } from '@elements'
import { FieldArray, FieldArrayRenderProps, FormikContextType, getIn, useFormikContext } from 'formik'
import { ReactNode } from 'react'
import { View } from 'react-native'

/**
 * This function will convert formik top level helpers into array item specific helpers
 * @param formik The formik instance to take the helpers from
 * @param arrayKey The array-key on the formik type
 * @param idx The index of the current element
 */

function getArrayContext<FormType extends Record<TKey, any[]>, TKey extends string>(
  formik: FormikContextType<FormType>,
  arrayKey: TKey,
  idx: number,
): FormikContextType<FormType[TKey][0]> & { showError(key: string, checkedTouch?: boolean): string } {
  return {
    ...formik,
    values: getIn(formik.values, `${arrayKey}[${idx}]`),
    errors: getIn(formik.errors, `${arrayKey}[${idx}]`),
    touched: getIn(formik.touched, `${arrayKey}[${idx}]`),
    handleChange: (key: any) => formik.handleChange(`${arrayKey}[${idx}].${key}`),
    handleBlur: (key: any) => formik.handleBlur(`${arrayKey}[${idx}].${key}`),
    setFieldValue: (key: string, value: any) => formik.setFieldValue(`${arrayKey}[${idx}].${key}`, value),
    showError: (key: string, checkedTouch = true) => {
      // Most of the time we don't want to show errors until the user has touched the field
      if (checkedTouch && !getIn(formik.touched, `${arrayKey}[${idx}].${key}`)) return

      return getIn(formik.errors, `${arrayKey}[${idx}].${key}`)
    },
  }
}

export type RenderType<FormType extends Record<TKey, any[]>, TKey extends keyof FormType> = {
  formik: FormikContextType<FormType[TKey][0]> & { showError(key: string, checkedTouch?: boolean): string }
  arrayHelpers: FieldArrayRenderProps
  index: number
}

type Props<FormType extends Record<TKey, any[]>, TKey extends keyof FormType> = {
  name: TKey
  renderItem(params: RenderType<FormType, TKey>): ReactNode
  emptyItem?: FormType[TKey][0]
}

export default function FormikArray<FormType extends Record<TKey, any[]>, TKey extends keyof FormType & string>({
  name,
  renderItem,
  emptyItem,
}: Props<FormType, TKey>) {
  const arrayFormik = useFormikContext<FormType>()

  const _renderItem = (arrayHelpers: FieldArrayRenderProps) => {
    const arrValue: FormType[TKey] = getIn(arrayFormik.values, name) ?? []
    return arrValue.length > 0 ? (
      arrValue.map((_, index) => {
        // Will get array item level helpers from the form helpers
        const formik = getArrayContext(arrayFormik, name, index)
        return renderItem({ formik, arrayHelpers, index })
      })
    ) : (
      <View>
        <TextH3>No {name} found. Click the button below to add one.</TextH3>
        <Button
          // when a user presses the button it will add a new FormType item to the first position in the array
          onPress={() => arrayHelpers.push(emptyItem)}
          title="Add New"
          icon="plus"
          outline
          style={{ width: '100%', flex: 1 }}
        />
      </View>
    )
  }

  return <FieldArray name={name} render={_renderItem} />
}
