import { MoneyCalc } from '@helpers/money'
import { getPaymentSchedules } from '@helpers/order'
import { Money, Zero } from '@models/Money'
import { CartItem } from '@models/Order'
import { UserAddress } from '@models/UserAddress'

import { Total } from '@/constants/types'

/** Touched object represents the touched state of a given cartitem id */
export type Touched = { [id: string]: boolean }

export type BillingAddressFormType = Omit<UserAddress, 'id' | 'coordinate' | 'state'> & {
  phoneNumber?: string
  state: string
  updateError?: string
}

export const hasInstallments = (cart: CartItem[]): boolean => {
  return cart.some((ci) => ci.paymentSchedule.frequency !== 'ONCE')
}

export const initialTotal: Total = {
  subtotal: Zero,
  tax: Zero,
  total: Zero,
  discounts: Zero,
  ebtEligibleAmount: Zero,
}

export const hasSinglePaySchedule = (item: CartItem) => getPaymentSchedules(item).length === 1

/**
 * @description Makes a Touched obj from a list of CartItems.
 * Pre-touches items with single pay option.
 * Reuses touch state for each item from the current Touched obj.
 */
export function makeTouched(items: CartItem[], currTouched: Touched): Touched {
  return items.reduce<Touched>(
    (prev, curr) => ({
      ...prev,
      [curr.id]: hasSinglePaySchedule(curr) ? true : currTouched[curr.id] ?? false,
    }),
    {},
  )
}

export const getTotalAfterFeesNCredit = (total: Money, coverFeeValue: Money, farmBalanceAmt: Money): Money => {
  let amount = MoneyCalc.add(total, coverFeeValue || Zero)

  // Subtract available farm credit from total
  amount = MoneyCalc.subtract(amount, farmBalanceAmt)

  // If the total amount minus the farm credit is greater than 0, return total
  if (MoneyCalc.isGTE(amount, Zero)) return amount

  // If the farm credit covers all the amount, return 0 (instead of negative value)
  return Zero
}
