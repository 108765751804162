import { Button, ButtonGroup, Divider, ErrorText, Spinner, Text } from '@elements'
import { formatAddress, plural } from '@helpers/display'
import { Distribution } from '@models/Distribution'
import { Standard, Unit } from '@models/Product'
import { Frequency } from '@models/Schedule'
import { DateTime } from 'luxon'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '../../constants/Colors'
import { CalendarSelector } from './CalendarSelector'
import { DatesMapDisplay, getDateKey, INITIAL_DISPLAYDATE, SelectableDate } from './DateSelector-helper'
import { ListSelector } from './ListSelector'

import { useCartService } from '@/hooks/useCart'
import { getPickupsCacheAddtoCartFlow } from '@/hooks/useCart/addToCartFlow/helpers'
import useDeepCompareEffect from '@/hooks/useDeepEqualEffect'
import { useDeviceSize } from '@/hooks/useLayout'
import { canUpdateQuantity, findItemInCart, getRemaining, isCartUnique } from '@helpers/canUpdateQuantity'

/**
 * Date selection UI for Standard products. Only shown if distro selection is already made. Allows going back to distro selection. When date selection is complete will call onSelect, which is part of the addToCart flow.
 * - It should retain both 'multi' and 'single' pickup functionality as a configurable parameter, in case the requirements change suddenly.
 */
export const DateSelector = memo(function DateSelector({
  onSelect: onSelectProp,
  onBackPress,
  product: prod,
  distro,
  preselectedDates,
  initialMode = 'multi',
  allowChangeMode = false,
  unit,
  isAdmin = false,
}: {
  /** Handler for the dates selected */
  onSelect: (dates: DateTime[], autoAddedDates?: boolean) => any | Promise<any>
  /** If no onBackPress provided, won't show "Back" button */
  onBackPress?: () => void
  /** The product being added to the cart */
  product: Standard
  distro: Distribution
  /** Dates from a CartItem, to be shown as currently selected in the date selector as the initial state. Useful for modifying dates because you want to show the previously selected dates instead of show a blank selection */
  preselectedDates?: DateTime[]
  /** Whether the selector allows multiple dates or single dates selected */
  initialMode?: 'single' | 'multi'
  /** Whether the mode can be changed by the user */
  allowChangeMode?: boolean
  /** If the product being added to the cart is a unit product, the unit selection step should've taken place before date selection step. So this must include the buying option selected there. This is necessary here to correctly calculate stock is sufficient when selecting add multiple dates, and prevent adding more dates that would exceed the stock. */
  unit: Unit
  /** "admin" mode allows closed distros and ignores cutoff window when getting pickup dates. If false, closed distros are excluded and available pickups will begin on the cutoff date. Should be true in the order creator. */
  isAdmin?: boolean
}) {
  const [mode, setMode] = useState(initialMode)
  const [availableDates, setAvailableDates] = useState<SelectableDate[]>()
  const [datesMap, setDatesMap] = useState<DatesMapDisplay>({})
  const [loading, setLoading] = useState(true)
  const { isSmallDevice } = useDeviceSize()
  const styles = useStyles()
  const isWeekly = distro.schedule.frequency === Frequency.WEEKLY

  /** On change to the prod, distro or pre-selected dates, build the available dates, which is the main data object */
  useDeepCompareEffect(() => {
    const dates = getPickupsCacheAddtoCartFlow(distro, prod, {
      excludeHiddenDistros: true,
      excludeClosedDistros: !isAdmin,
      ignoreOrderCutoffWindow: isAdmin,
      ignoreDisableBuyInFuture: isAdmin,
    })
    const selectableDates: SelectableDate[] = dates.map((pickupDate) => {
      return {
        date: pickupDate,
        selected: false,
      }
    })
    setAvailableDates(selectableDates)
  }, [prod, distro, preselectedDates, isAdmin])

  /** When the available dates are set, updates the map data for display purposes */
  useEffect(() => {
    if (!availableDates) return

    const newDatesMap: DatesMapDisplay = {}

    availableDates.forEach((date) => {
      newDatesMap[getDateKey(date.date)] = { ...INITIAL_DISPLAYDATE, selected: date.selected }
    })

    setDatesMap(newDatesMap)
    setLoading(false)
  }, [availableDates])

  /** When switching from multi to single, reset the dates to a single one, the first one */
  useEffect(() => {
    if (mode === 'multi') return
    let count = 0
    setAvailableDates((prev) => {
      if (!prev) return
      return prev.map((d) => {
        if (d.selected) count++
        return { ...d, selected: count > 1 ? false : d.selected }
      })
    })
  }, [mode])

  /** When a new date is selected, sets the new available dates based on the current mode. I.e: either it adds it to the existing array, or it simply replaces the array with the new date. (multi vs single-date mode) */
  const onDayPress = useCallback(
    (value: DateTime) => {
      const idx = availableDates!.findIndex((date) => date.date === value)
      if (idx === -1) return
      const newArr = availableDates!.map((e, index) => {
        // On single select mode, just one day to be selected. Else, keep the previous selections
        return { ...e, selected: mode === 'single' ? index === idx : index === idx ? !e.selected : e.selected }
      })
      setAvailableDates([...newArr])
    },
    [availableDates, mode],
  )

  /** The final step, called when the user confirms their entire selection */
  const onNextPress = useCallback(async () => {
    if (!availableDates || availableDates.every((d) => !d.selected)) return
    setLoading(true)
    await onSelectProp(
      availableDates.filter((el) => el.selected).map((e) => e.date),
      false, // at the moment, the date selector isn't auto-selecting dates
    )
    return setLoading(false)
  }, [availableDates, onSelectProp])

  const minimumDates = useMemo(() => prod.minPickups ?? 1, [prod.minPickups])

  const hasSelectedMinDates = useMemo(
    () => (availableDates ? availableDates.filter((date) => date.selected).length >= minimumDates : false),
    [availableDates, minimumDates],
  )

  /** controls the disabled state of the Next button */
  const disableNextBtn = useMemo((): boolean => {
    if (!availableDates) return true
    if (!hasSelectedMinDates) return true
    return false
  }, [availableDates, hasSelectedMinDates])

  const { cart } = useCartService(isAdmin)

  /** This should be total number of dates that can be selected based on the stock available
   * - It should take into account the amounts from other items (and the same item) in the cart which may count toward the same stock.
   * - It should correctly handle the stock for the buying option if it is a UnitProduct, handling either global or unit stock, as well as unit multiplier for globalstock units. */
  const nDatesRemaining = useMemo(() => {
    if (!prod) return null

    // pass the quantity from the existing item in the cart if any
    const quantity = findItemInCart({ product: prod, unit }, cart)?.quantity ?? 1

    // we filter this item from the cart because it's necessary for this to work correctly when modifying dates. We are framing the calculation as if it were the first time the item is being added
    const cartWithoutItem = cart.filter((ci) => !isCartUnique({ product: prod, unit }, ci))

    // We add one because the calculation adds one date by default, and we want the total number of dates we can add
    const compensation = 1

    return (
      getRemaining({
        cart: cartWithoutItem,
        cartItem: { product: prod, unit, quantity },
        attribute: 'pickups',
      }) + compensation
    )
  }, [cart, prod, unit])

  /** This will be true if additional dates can still be selected. When this is false, no more dates can be selected */
  const canSelectMore = useMemo(() => {
    if (!availableDates || !prod) return false
    const selected = availableDates.filter((d) => d.selected).map((d) => d.date)

    // this should find any non-selected date left in the available dates array
    const additionalDate = availableDates.find((d) => !d.selected)?.date

    // We want to add an extra date to the current selection because we want to know if we can add more than the current selection
    const tentativePickups = [...selected]
    if (additionalDate) tentativePickups.push(additionalDate)

    const quantity = findItemInCart({ product: prod, unit }, cart)?.quantity ?? 1

    return canUpdateQuantity({ cart, cartItem: { product: prod, unit, quantity, pickups: tentativePickups } })
  }, [availableDates, cart, prod, unit])

  // tracks whether the auto-selecting step has happened
  const doneAutoSelect = useRef(false)

  /** auto-selects dates based on initial data and stock */
  useEffect(() => {
    if (nDatesRemaining === null) return

    if (nDatesRemaining === 1 && !preselectedDates) {
      // When there is only one date in stock, and there's no preselected dates, we don't want to auto-select anything because the rest will become disabled and could be confusing since the user hasn't made any selection
    } else if (!doneAutoSelect.current) {
      doneAutoSelect.current = true // set this to "done", so we never run this again
      setAvailableDates((prevState) =>
        prevState?.map((selectableDate, ix) => ({
          ...selectableDate,
          // this should auto-select any pickup dates that are preselected dates
          selected: preselectedDates
            ? preselectedDates.some((pd) => getDateKey(pd) === getDateKey(selectableDate.date))
            : // in the absence of preselected dates, we auto-select the first date on normal circumstances, to make addToCart faster and easier
              ix === 0,
        })),
      )
    }
  }, [nDatesRemaining, preselectedDates])

  const stockRemainingUI = useMemo(() => {
    if (nDatesRemaining === null) return null

    if (!canSelectMore && availableDates)
      return (
        <ErrorText style={styles.stockRemainingText}>{`Maximum number of dates selected (${
          availableDates.filter((d) => d.selected).length
        })`}</ErrorText>
      )

    return null
  }, [availableDates, canSelectMore, nDatesRemaining, styles.stockRemainingText])

  return (
    <View style={styles.mainContainer}>
      <View style={styles.direction}>
        <View>
          <Text type="medium" size={14} color={loading ? Colors.shades[200] : undefined}>
            {distro.location.name}
          </Text>
          {distro.location.address && (
            <Text type="medium" size={13} color={loading ? Colors.shades[200] : undefined}>
              {formatAddress(distro.location.address)}
            </Text>
          )}
        </View>
        {allowChangeMode ? (
          <View style={styles.marginV}>
            <ButtonGroup
              buttons={[
                { label: 'Single Pickup', onPress: () => setMode('single') },
                { label: 'Multi Pickup', onPress: () => setMode('multi') },
              ]}
              selectedIndex={mode === 'single' ? 0 : 1}
            />
          </View>
        ) : null}
      </View>
      {!hasSelectedMinDates && (
        <View style={styles.flexEnd}>
          <ErrorText>{`Select at least ${minimumDates} ${plural(minimumDates, 'date')}`}</ErrorText>
        </View>
      )}
      <View style={styles.flexEnd}>{stockRemainingUI}</View>
      <Divider />
      <View style={styles.selectorWrapper}>
        {!isWeekly || mode === 'multi' ? (
          <ListSelector
            selectableDates={availableDates}
            onDayPress={onDayPress}
            schedule={distro}
            loading={loading}
            canSelectMore={canSelectMore}
          />
        ) : (
          // TODO: If we ever use the CalendarSelector again, it should be updated to disable adding more dates based on stock remaining in the same way we do for list selector
          <CalendarSelector datesMap={datesMap} availableDates={availableDates} onDayPress={onDayPress} />
        )}
        <View style={styles.buttonsContainer}>
          {onBackPress && (
            <>
              <Button
                style={styles.btn}
                outline
                small={isSmallDevice}
                title="Back"
                onPress={onBackPress}
                testID="dateSelector-back"
                loading={loading}
              />
              <View style={styles.spacing} />
            </>
          )}
          <Button
            style={styles.btn}
            disabled={disableNextBtn}
            title="Next"
            onPress={onNextPress}
            small={isSmallDevice}
            testID="dateSelector-next"
            loading={loading}
          />
        </View>
        {loading ? <Spinner style={styles.spinner} /> : null}
      </View>
    </View>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    mainContainer: {
      margin: 10,
      padding: 20,
      borderWidth: 1,
      borderColor: Colors.shades['100'],
      borderRadius: 10,
      flex: 1,
    },
    selectorWrapper: {
      flex: 1,
      paddingBottom: 20,
    },
    spinner: {
      zIndex: 1,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    btn: {
      flex: 1,
      marginHorizontal: 0,
    },

    buttonsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 20,
      width: '100%',
    },
    spacing: {
      width: 20,
      height: 20,
    },
    direction: {
      flexDirection: 'row',
    },
    marginV: {
      marginVertical: 5,
    },
    flexEnd: {
      alignItems: 'flex-end',
    },
    stockRemainingText: {
      marginHorizontal: 15,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      mainContainer: {
        margin: 5,
        padding: 5,
      },
      direction: {
        flexDirection: 'column',
      },
      marginV: {
        marginVertical: 5,
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      spacing: {
        height: 5,
      },
      mainContainer: {
        margin: 0,
      },
      buttonsContainer: {
        marginTop: 5,
      },
    },
  },
)
