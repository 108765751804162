import { loadCSA } from '@api/CSAs'
import { loadProduct, loadProductsByCSA } from '@api/Products'
import { AvailAddonResult, getAvailAddons } from '@helpers/addons'
import { isNonNullish, loadAllDocs, removeDuplicates, removeObjDuplicates } from '@helpers/helpers'
import { CartItemBase, Order } from '@models/Order'
import { AddonShare, PrimaryShare, ProductType } from '@models/Product'

/** Determines which are the available addons based on past purchases */
export async function getAvailAddons_orders(orders: Order[]): Promise<{ data: AvailAddonResult[]; hash: number }> {
  const csaIds = getCsaIdsFromOrders(orders)
  const addons = await loadAddonsFromCsaIds(csaIds)

  // List all order items that are primary shares and are not cancelled
  const orderItemsWithPrimary = orders
    .map((ord) => ord.items)
    .flat()
    .filter(
      (itm) => !itm.cancelled && itm.csa?.id && itm.product.type === ProductType.PrimaryShare && !!itm.distribution,
    )

  const cartItems: Pick<CartItemBase<PrimaryShare>, 'product' | 'distribution' | 'csa'>[] = (
    await Promise.all(
      orderItemsWithPrimary.map(async (oi) => {
        const product = await loadProduct<PrimaryShare>(oi.product.id)
        if (!product) return null
        const distribution = product.distributions.find((dist) => dist.id === oi.distribution!.id)
        if (!distribution) return null
        const csa = await loadCSA(oi.csa!.id)
        if (!csa) return null

        return { product, distribution, csa }
      }),
    )
  ).filter(isNonNullish)

  const availAddons: AvailAddonResult[] = getAvailAddons(cartItems, addons)

  return {
    data: availAddons,
    hash: generateOrderHash(orders),
  }
}

/** load all addons from these CSAs */
async function loadAddonsFromCsaIds(csaIds: string[]): Promise<AddonShare[]> {
  const addons: AddonShare[] = []
  const products = await loadAllDocs(csaIds, loadProductsByCSA)
  products.flat().forEach((prod) => {
    if (prod.type === ProductType.AddonShare) addons.push(prod)
  })
  return removeObjDuplicates(addons)
}

/** Loads all CSAs and distributions from primary share orders */
function getCsaIdsFromOrders(orders: Order[]) {
  const csas: string[] = []
  orders.forEach((ord) => {
    const items = ord.items.filter(
      (itm) => !itm.cancelled && itm.csa?.id && itm.product.type === ProductType.PrimaryShare,
    )
    const csaIds = items.map((itm) => itm.csa!.id)
    csas.push(...csaIds)
  })

  return removeDuplicates(csas)
}

/** Will generate a hash from all purchases with primary shares */
export function generateOrderHash(orders: Order[]) {
  const ordStr = orders
    .filter(hasPrimaryShare)
    .map((ord) => ord.id)
    .reduce((a, b) => a + b, '')
  return hashCode(ordStr)
}

/** Generates a simple hashcode from the string */
function hashCode(str: string) {
  let h = 0
  for (let i = 0; i < str.length; i++) h = (Math.imul(31, h) + str.charCodeAt(i)) | 0
  return h
}

const hasPrimaryShare = (order: Order) =>
  order.items.filter((itm) => !itm.cancelled && itm.csa?.id && itm.product.type === ProductType.PrimaryShare).length > 0
