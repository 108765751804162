import { getFocusedRouteNameFromRoute, useNavigationState, useRoute } from '@react-navigation/native'

// Will return the current screen name
function useRouteName() {
  const route = useRoute()
  const nav = useNavigationState((state) => state)
  const routeName = getFocusedRouteNameFromRoute(route)

  // If the focused route is not found, we need to assume it's the initial screen
  // This can happen during if there hasn't been any navigation inside the screen
  // We can get the initial screen from navState using the index
  if (routeName) return routeName
  return nav.routeNames[nav.index]
}

export default useRouteName
