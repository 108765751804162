import { ButtonClear, ErrorText, Icon, Text } from '@elements'
import { formatAddress } from '@helpers/display'
import { LocationTypes } from '@models/Location'
import { UserAddress } from '@models/UserAddress'
import { memo, useCallback } from 'react'
import { View } from 'react-native'
import { GoogleLocationDetailResult } from 'react-native-google-autocomplete/dist/services/Google.service'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { GooglePlacesSearch, makeEstablishment } from '../../../components/GooglePlacesSearch'
import Colors from '../../../constants/Colors'
import { GoogleAddressParser } from '../../../constants/GoogleAddressParser'
import { globalStyles } from '../../../constants/Styles'

import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import useKeyedState from '@/hooks/useKeyedState'
import { errorToString } from '@helpers/helpers'
import { createAddressString } from '@helpers/location'

export const testIdChooseAddress = 'chooseAddress'

type ChooseAddressState = { isEdit: boolean; errorText: string }

type Props = {
  /** callback for when an address is selected from google places search */
  onSelect(address: UserAddress): void
  /** This is the current address state for the UI, managed outside this component */
  address?: UserAddress
  /** location type. Only nonPickup location types involve a manually entered address */
  type: LocationTypes.Shipping | LocationTypes.Delivery
  onStateSet?: (state: ChooseAddressState) => void
  testID?: string
}

/** Component with an GooglePlaceAutocomplete Input that enables users to set the delivery address for a cartItem */
export const ChooseAddress = memo(function ChooseAddress({ onSelect, type, address, onStateSet }: Props) {
  const styles = useStyles()
  const [{ isEdit, errorText }, set, setState] = useKeyedState<ChooseAddressState>(
    { isEdit: false, errorText: '' },
    { onStateSet },
  )

  const setGooglePlace = useCallback(
    (item: AutoCompleteItem<GoogleLocationDetailResult>) => {
      try {
        const details = makeEstablishment(item)
        if (!details.address_components)
          return set('errorText', 'Something went wrong while getting the data for this address. ')
        const address = new GoogleAddressParser(details.address_components).getAddress({ allowPO: false })
        setState((prev) => ({ ...prev, errorText: '', isEdit: false }))
        onSelect({ ...address, coordinate: details.coordinate, id: createAddressString(address) })
      } catch (error) {
        set('errorText', errorToString(error))
      }
    },
    [onSelect, set, setState],
  )

  return (
    <View style={styles.container}>
      {address && !isEdit ? (
        <View style={styles.noEditCont}>
          <Text size={14}>
            {`${type === LocationTypes.Shipping ? 'Ship' : 'Deliver'} to `} <Icon name="map-marker-alt" size={14} />
            {` ${formatAddress(address)}`}
          </Text>
          <ButtonClear title="Edit Address" onPress={() => set('isEdit', true)} style={styles.editAddress} />
        </View>
      ) : (
        <View style={styles.editContainer}>
          <Text style={globalStyles.margin10}>Enter your {type.toLowerCase()} address</Text>
          <View>
            <GooglePlacesSearch
              contStyle={styles.input}
              types="geocode"
              selectTextOnFocus
              onSelect={setGooglePlace}
              placeholder={address ? formatAddress(address) : ''}
              inline
              noInitialValue
              hasClearBtn
            />
          </View>
        </View>
      )}
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </View>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginBottom: 10,
    },
    noEditCont: {
      borderRadius: 10,
      padding: 10,
    },
    editContainer: {
      width: '50%',
      minWidth: 300,
    },

    input: {
      borderWidth: 1,
      backgroundColor: Colors.white,
      borderColor: Colors.shades['300'],
    },
    inputCont: {
      marginHorizontal: 10,
    },
    editAddress: { maxWidth: 150 },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      input: {
        marginHorizontal: 5,
      },
    },
  },
)
