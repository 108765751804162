import { Alert, ButtonClear, Icon, Spinner, Text, Toast } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { errorToString, isNonNullish } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { Money, Zero } from '@models/Money'
import { memo, useCallback, useEffect, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Input } from 'react-native-elements'

import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { CartService } from '../../../constants/types/cartService'
import { useDeepCompareCallback } from '../../../hooks/useDeepEqualEffect'

import { useControlledState } from '@/hooks/useControlledState'

export const EnterDiscount = memo(function EnterDiscount({
  style,
  alwaysShowInput = false,
  addDiscount,
  removeDiscount,
  discount,
  loadingCart,
  ebtAmount = Zero,
}: Pick<CartService, 'discount' | 'addDiscount' | 'removeDiscount' | 'loadingCart'> & {
  ebtAmount?: Money
  style?: StyleProp<ViewStyle>
  alwaysShowInput?: boolean
}) {
  const [promoCode, setPromoCode] = useState<string>('')
  const [showingInput, [showInput]] = useControlledState(alwaysShowInput, [true, false])

  useEffect(() => {
    if (MoneyCalc.isZero(ebtAmount) && discount?.coupon.ebtOnly) {
      // If the coupon is EBT only and we no longer have an ebtAmount then we should remove the coupon and notify the user
      removeDiscount()
      setPromoCode('')
      Alert(
        'Discount Removed',
        'Your discount has been removed because you are no longer paying with an EBT payment method.',
      )
    }
  }, [ebtAmount, discount?.coupon.ebtOnly, removeDiscount])

  const onSubmitPromo = useDeepCompareCallback(async () => {
    if (!promoCode) return Toast('Promo code missing')
    try {
      // If there is a previous discount then remove it before adding a new one
      if (discount) {
        await removeDiscount()
      }
      await addDiscount(promoCode.toUpperCase(), MoneyCalc.isGTZero(ebtAmount))
    } catch (err) {
      // Clear the promo code if it fails
      setPromoCode('')
      const msg = errorToString(err) || 'Unable to add this discount'
      Logger.warn('Failed applying discount' + msg)
      Alert('Adding discount failed', msg)
    }
  }, [addDiscount, promoCode, ebtAmount, discount, removeDiscount])

  const capitalizeText = useCallback(
    (text: string) => {
      setPromoCode(text.trim().toUpperCase())
    },
    [setPromoCode],
  )

  return (
    <View style={style}>
      {showingInput ? (
        <View style={styles.inputContainer}>
          <Input
            autoCorrect={false}
            autoCapitalize="characters"
            inputStyle={styles.inputStyle}
            inputContainerStyle={styles.borderBottom}
            containerStyle={globalStyles.flex1}
            placeholder="Apply promo code"
            onChangeText={capitalizeText}
            renderErrorMessage={false}
            value={promoCode}
            disabled={loadingCart}
          />
          <ButtonClear title="Apply" onPress={onSubmitPromo} disabled={loadingCart} loading={loadingCart} />
        </View>
      ) : (
        <ButtonClear
          title="+ Add Promo Code"
          size={12}
          style={styles.paddingLeft}
          onPress={showInput}
          disabled={loadingCart}
        />
      )}
      {isNonNullish(discount) && (
        <View style={styles.appliedCoupon}>
          <Text style={styles.marginRight}>Applied Discount {discount?.coupon.name}</Text>
          {!loadingCart ? <Icon color={Colors.red} name="times" onPress={removeDiscount} /> : <Spinner size="small" />}
        </View>
      )}
    </View>
  )
}, propsAreDeepEqual)

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderColor: Colors.shades['100'],
    borderWidth: 1,
    borderRadius: 10,
    maxWidth: 300,
    minWidth: 280,
  },
  inputStyle: {
    marginTop: 2,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  borderBottom: {
    borderBottomWidth: 0,
  },
  paddingLeft: {
    paddingLeft: 0,
  },
  marginRight: {
    marginRight: 5,
  },
  appliedCoupon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
