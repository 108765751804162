import { ProductType } from '@models/Product'
import { DateRange, SummaryFilter } from '@models/Schedule'
import { DateTime } from 'luxon'

import { FeeType } from '@models/ProductFee'
import { getRunConfiguration, isEmul } from '../../config/Environment'
import { openUrl } from './client'

interface SalesFilters {
  startDate: string
  endDate: string
}

/** The function to generate sorted and unsorted sales CSV */
export function openSalesCSV(farmId: string, filters: SalesFilters, type: 'sorted' | 'unsorted') {
  const url = cloudURLBase('salesCSV')

  url.searchParams.append('farmId', farmId)
  url.searchParams.append('startDate', filters.startDate)
  url.searchParams.append('endDate', filters.endDate)
  url.searchParams.append('type', type)

  openUrl(url.href)
}

interface DateRangeFilters {
  dateRange: {
    startDate: DateTime
    endDate: DateTime
  }
}

/** The function to generate coupon redemption csv  */
export function openCouponRedemptionCSV(farmId: string, filters: DateRangeFilters) {
  const url = cloudURLBase('couponRedemptionCSV')

  url.searchParams.append('farmId', farmId)
  url.searchParams.append('startDate', filters.dateRange.startDate.toISO())

  url.searchParams.append('endDate', filters.dateRange.endDate.toISO())

  openUrl(url.href)
}

/** The functions to generate payouts csv  */
export function openPayoutsCSV(farmId: string, filters: DateRangeFilters, withInvoices: boolean) {
  const url = cloudURLBase(withInvoices ? 'payoutInvoicesSummaryCSV' : 'payoutSummaryCSV')

  url.searchParams.append('farmId', farmId)
  url.searchParams.append('startDate', filters.dateRange.startDate.toUTC().toISO())

  url.searchParams.append('endDate', filters.dateRange.endDate.toUTC().toISO())

  openUrl(url.href)
}

export interface CustomerFilters {
  csaName?: string
  productName?: string
  locationName?: string
  distributionName?: string
}

/** customersCSV opens the customers CSV. */
export function openCustomersCSV(farmId: string, filters: CustomerFilters, searchQuery?: string) {
  const url = cloudURLBase('customersCSV')
  url.searchParams.append('farmId', farmId)

  if (filters.csaName) {
    url.searchParams.append('csaName', filters.csaName)
  }
  if (filters.productName) {
    url.searchParams.append('productName', filters.productName)
  }
  if (filters.locationName) {
    url.searchParams.append('locationName', filters.locationName)
  }
  if (filters.distributionName) {
    url.searchParams.append('distributionName', filters.distributionName)
  }

  if (searchQuery) {
    url.searchParams.append('query', searchQuery)
  }

  openUrl(url.href)
}

/**  openOrdersCSV opens the orders CSV. */
export interface OrderFilters {
  csaName?: string
  productName?: string
  locationName?: string
  distributionName?: string
  dateRange: {
    min: number
    max: number
  }
}

/** openOrdersCSV opens the orders CSV */
export function openOrdersCSV(farmId: string, filters: OrderFilters, searchQuery?: string) {
  const url = cloudURLBase('ordersCSV')
  url.searchParams.append('farmId', farmId)

  url.searchParams.append('startDate', filters.dateRange.min.toString())
  url.searchParams.append('endDate', filters.dateRange.max.toString())

  if (filters.csaName) {
    url.searchParams.append('csaName', filters.csaName)
  }
  if (filters.productName) {
    url.searchParams.append('productName', filters.productName)
  }
  if (filters.locationName) {
    url.searchParams.append('locationName', filters.locationName)
  }
  if (filters.distributionName) {
    url.searchParams.append('distributionName', filters.distributionName)
  }
  if (searchQuery) {
    url.searchParams.append('query', searchQuery)
  }

  openUrl(url.href)
}

/** openProductsCSV opens the products CSV. */
export function openProductsCSV(farmId: string, productType: ProductType | 'share') {
  const url = cloudURLBase('productsCSV')
  url.searchParams.append('farmId', farmId)

  if (productType) {
    url.searchParams.append('productType', productType)
  }

  openUrl(url.href)
}

/** openInvoicesCSV opens the invoices CSV. */
export interface InvoiceFilters {
  paymentMethod?: string
  status?: string
  dateRange: {
    min: number
    max: number
  }
}

/** openSummaryInvoicesCSV opens the invoices CSV with summary*/
export function openSummaryInvoicesCSV(farmId: string, filters: InvoiceFilters, searchQuery?: string) {
  const url = cloudURLBase('summaryInvoicesCSV')

  url.searchParams.append('farmId', farmId)

  url.searchParams.append('startDate', filters.dateRange.min.toString())

  url.searchParams.append('endDate', filters.dateRange.max.toString())

  if (filters.paymentMethod) {
    url.searchParams.append('paymentMethod', filters.paymentMethod)
  }

  if (filters.status) {
    url.searchParams.append('status', filters.status)
  }

  if (searchQuery) {
    url.searchParams.append('query', searchQuery)
  }

  openUrl(url.href)
}

/** openDetailInvoicesCSV opens the invoices CSV with items based details */
export function openDetailInvoicesCSV(farmId: string, filters: InvoiceFilters, searchQuery?: string) {
  const url = cloudURLBase('detailInvoicesCSV')

  url.searchParams.append('farmId', farmId)

  url.searchParams.append('startDate', filters.dateRange.min.toString())

  url.searchParams.append('endDate', filters.dateRange.max.toString())

  if (filters.paymentMethod) {
    url.searchParams.append('paymentMethod', filters.paymentMethod)
  }

  if (filters.status) {
    url.searchParams.append('status', filters.status)
  }

  if (searchQuery) {
    url.searchParams.append('query', searchQuery)
  }

  openUrl(url.href)
}

export interface ProductFeeFilters {
  feeType?: FeeType
  dateRange: {
    startDate: DateTime
    endDate: DateTime
  }
}

/** openProductFeesCSV opens the taxes and fees CSV*/
export function openProductFeesCSV(farmId: string, filters: ProductFeeFilters) {
  const url = cloudURLBase('summaryProductFeesCSV')

  url.searchParams.append('farmId', farmId)

  url.searchParams.append('startDate', filters.dateRange.startDate.toISODate())

  url.searchParams.append('endDate', filters.dateRange.endDate.toISODate())

  if (filters.feeType) {
    url.searchParams.append('feeType', filters.feeType)
  }

  openUrl(url.href)
}

/** openDailySummaryCSV opens the daily summary CSV file. */
export function openDailySummaryCSV(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  openSummaryLink('dailySummaryCSV', undefined, farmId, dateRange, filter)
}

/** openLocationSummaryCSV opens the location summary CSV file. */
export function openLocationSummaryCSV(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  openSummaryLink('locationSummaryCSV', undefined, farmId, dateRange, filter)
}

/** openSignInCSV opens the sign in CSV file. */
export function openSignInCSV(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  openSummaryLink('signInCSV', undefined, farmId, dateRange, filter)
}

/** openPackListPDF opens the pack list PDF in a browser or new tab when on the web platform. */
export function openSignInPDF(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  openSummaryLink('signInPDF', '_blank', farmId, dateRange, filter)
}

/** This function format an invoice into PDF format.  */
export function openInvoiceReceiptPDF(invoiceId: string) {
  const url = cloudURLBase('invoiceReceiptPDF')
  url.searchParams.append('invoiceId', invoiceId)

  openUrl(url.href, { target: '_blank' })
}

/** openPackListCSV opens the packList CSV file. */
export function openPackListCSV(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  openSummaryLink('packListCSV', undefined, farmId, dateRange, filter)
}

/** openPackListPDF opens the pack list PDF in a browser or new tab when on the web platform.*/
export function openPackListPDF(farmId: string, dateRange: DateRange, filter: SummaryFilter) {
  openSummaryLink('packListPDF', '_blank', farmId, dateRange, filter)
}

/** Only used for the HyperLink component */
export const hyperlinkOpenUrl = (url: string, text?: string) => openUrl(url, { target: text })

/** openSummaryLink creates the URL for a summary report. */
function openSummaryLink(
  action: string,
  target: string | undefined,
  farmId: string,
  dateRange?: DateRange,
  filter?: SummaryFilter,
) {
  const url = cloudURLBase(action)
  url.searchParams.append('farmId', farmId)

  if (dateRange) {
    url.searchParams.append('start', dateRange.startDate.toISODate())
    url.searchParams.append('end', dateRange.endDate.toISODate())
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      url.searchParams.append(key, value)
    })
  }

  openUrl(url.href, { target })
}

/** cloudURLBase returns a base URL for assembling URL routes. */
export function cloudURLBase(action: string): URL {
  const config = getRunConfiguration()

  // For testing local changes to reports
  if (isEmul) {
    return new global.URL(`http://localhost:5001/${config.projectId}/us-central1/${action}`)
  }
  return new global.URL(`https://us-central1-${config.projectId}.cloudfunctions.net/${action}`)
}
