import { Alert, Modal } from '@elements'
import { ProdErrorsMap, ValidationErrObj } from '@helpers/distributionEditing'
import { Distribution, DistributionConstraint } from '@models/Distribution'
import { Product } from '@models/Product'

import ScheduleEditBlock from '../ScheduleEditBlock'
import ScheduleEditConfirmation from '../ScheduleEditConfirmation'
import { ReturnStates, ReturnTypes } from './types'

/** Will display validation errors that should block the schedule edit */
export function blockEdit(prodErrorMap: ProdErrorsMap): Promise<ReturnTypes> {
  return new Promise((resolve) => {
    Modal(
      <ScheduleEditBlock prodErrorMap={prodErrorMap} onDismiss={() => resolve({ status: ReturnStates.DO_NOTHING })} />,
      { title: 'Cannot Save Schedule', header: false, onDismiss: () => resolve({ status: ReturnStates.DO_NOTHING }) },
    )
  })
}

/** Ask the farmer if they want to proceed with destructive schedule change, which will update the constraints or unassign the schedule from related products */
export function confirmationPrompt(
  fixableErrors: ProdErrorsMap<ValidationErrObj>,
  updatedConstraints: Map<string, DistributionConstraint>,
  linkedProducts: Product[],
): Promise<ReturnStates.DO_NOTHING | ReturnStates.PROCEED> {
  return new Promise((resolve) => {
    Modal(
      <ScheduleEditConfirmation
        fixableErrors={fixableErrors}
        updatedConstraints={updatedConstraints}
        linkedProducts={linkedProducts}
        resolve={resolve}
      />,
      {
        title: 'Please confirm product changes',
        onDismiss: () => resolve(ReturnStates.DO_NOTHING),
      },
    )
  })
}

export type ConfirmationPrompt = typeof confirmationPrompt

// Will prompt the farmer with a warning asking if they want to proceed or cancel the update

export function locationChangePrompt(change: {
  before: Distribution['location']
  after: Distribution['location']
}): Promise<ReturnStates.DO_NOTHING | ReturnStates.PROCEED> {
  return new Promise((resolve) => {
    Alert(
      'Warning! Important Change',
      'This location change will edit the location of all future pickups associated with this schedule. Please be sure to clearly communicate this change to your customers.',
      [
        {
          text: 'Proceed',
          style: 'destructive',
          onPress: () => resolve(ReturnStates.PROCEED),
        },
        {
          text: 'Cancel',
          style: 'cancel',
          onPress: () => resolve(ReturnStates.DO_NOTHING),
        },
      ],
    )
  })
}

/**
 * Prompt the user when an invalid pickup is skipped.
 * @param day - The position of the skipped pickup ('first' or 'last').
 */
export function invalidPickupSkippedPrompt(day: 'first' | 'last'): void {
  const date = day === 'first' ? 'start' : 'end'

  return Alert(
    'Invalid Date',
    `You cannot skip the ${day} day of a distribution, consider moving the ${date} date a week earlier/later.`,
  )
}
