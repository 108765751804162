import { Button, Spinner, Text, Touchable } from '@elements'
import { memo, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { ProductCardProps, prodSharedStyles, useProductCardData } from '.'
import Colors from '../../constants/Colors'
import { AddCartBtnIcon } from '../AddCartBtn'
import { EbtIcon } from '../EbtIcon'
import { Image, ResizedSuffix } from '../Image'

import { SHADOW_2, SHADOW_5 } from '@/constants/Styles'
import { useFlatStyle } from '@/hooks/useMergeStyle'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { omit } from '@helpers/typescript'
import { getCardTags } from './ProductCardSquare-helpers'

/**
 * This component represents the UI for a (Squared) Product Card used across the app.
 * Its necessary data is provided from useProductData hook, and it is calling specific home components like AddCartBtnHome
 */
function ProductCardSquareComp(props: ProductCardProps) {
  const {
    urlPushAction,
    onPressCard,
    testId,
    url,
    touchableProps,
    style,
    cardAction,
    product,
    loading,
    errorLoadingProd,
    onActionPress,
    onCSAPress,
    csa,
    unit,
    small = false,
    cardText,
  } = useProductCardData(props)

  const actionEl = useMemo(
    () =>
      loading ? (
        <Spinner />
      ) : errorLoadingProd || !product ? null : cardAction === 'addcart' ? (
        <AddCartBtnIcon
          outline
          style={styles.addCartBtn}
          product={product}
          onPress={onActionPress}
          testID={testId}
          csa={csa}
          unit={unit}
        />
      ) : cardAction === 'csa' ? (
        /** In this Button we don't use url because the main product card already has a url, so this would become a nested url, which is invalid html. Instead, this navigates via the onPress */
        <Button
          outline
          small
          style={styles.csaBtn}
          onPress={onCSAPress}
          title={csa?.name ?? 'View CSA'}
          size={10}
          textProps={{ numberOfLines: 1 }}
        />
      ) : null,
    [cardAction, onActionPress, onCSAPress, csa, product, unit, testId, loading, errorLoadingProd],
  )

  const wrapperStyle = useFlatStyle([small ? prodSharedStyles.smallWrapper : prodSharedStyles.wrapper, style])

  return (
    <Touchable style={wrapperStyle} url={url} onPress={onPressCard} navAction={urlPushAction} {...touchableProps}>
      <View style={styles.imageWrapper}>
        <View style={styles.imageCont}>
          {!!getCardTags(product).length && (
            <View style={styles.tagsContainer}>
              {getCardTags(product).map((tag, i) => (
                <View key={i} style={styles.tag}>
                  <Text>{tag}</Text>
                </View>
              ))}
            </View>
          )}
          <Image resizeSuffix={ResizedSuffix.THUMB} style={styles.image} source={{ uri: product?.images?.[0] }} />
        </View>
        <View style={styles.actionElCont}>{actionEl}</View>
      </View>
      <View style={styles.textContainer}>
        <View style={styles.fill}>
          <Text size={small ? 12 : 14} style={styles.nameText} numberOfLines={2}>
            <EbtIcon product={product} />
            {product?.name}
          </Text>
          <Text numberOfLines={2} size={small ? 10 : 12}>
            {cardText}
          </Text>
        </View>
      </View>
    </Touchable>
  )
}

export const ProductCardSquare = memo(
  ProductCardSquareComp,
  /**This function will compare props except for those that should not update this component */ (prev, next) => {
    const prevCompare = omit(prev, 'onPressCsaNavigate', 'onPressMobileNavigate', 'onActionPress')
    const nextCompare = omit(next, 'onPressCsaNavigate', 'onPressMobileNavigate', 'onActionPress')
    return propsAreDeepEqual(prevCompare, nextCompare)
  },
)

const styles = StyleSheet.create({
  csaBtn: {
    backgroundColor: Colors.white,
    paddingHorizontal: 10,
    paddingVertical: 0,
    marginRight: 0,
    borderWidth: 0,
    marginBottom: 0,
    minHeight: 35,
    borderRadius: 5,
    maxWidth: '85%',
    ...SHADOW_2,
  },
  actionElCont: {
    position: 'absolute',
    right: 0,
    paddingRight: 5,
    bottom: 0,
    paddingBottom: 5,
    width: '100%',
    zIndex: 1,
    alignItems: 'flex-end',
  },

  imageWrapper: {
    width: '100%',
    aspectRatio: 1,
  },
  image: {
    height: '100%',
    width: '100%',
    resizeMode: 'cover',
  },
  imageCont: {
    flex: 1,
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
  },
  tagsContainer: {
    position: 'absolute',
    zIndex: 5,
    left: 5,
    top: 5,
    gap: 5,
  },
  tag: {
    paddingVertical: 5,
    paddingHorizontal: 5,
    backgroundColor: Colors.white,
    borderRadius: 5,
    ...SHADOW_2,
  },
  fill: {
    flex: 1,
  },
  textContainer: { flex: 1, padding: 5 },
  addCartBtn: {
    backgroundColor: Colors.white,
    borderWidth: 0,
    ...SHADOW_5,
  },
  nameText: {
    marginTop: 10,
  },
})
