import { loadCSAsByFarm } from '@api/CSAs'
import { snapshotDistributionsByFarm } from '@api/Distributions'
import { addOrder } from '@api/Orders'
import { loadFuturePickupsByUserAndFarm } from '@api/Pickups'
import { loadUser } from '@api/Users'
import {
  Alert,
  Button,
  ButtonGroup,
  ErrorText,
  KeyboardAvoidingScrollView,
  Loader,
  LoadingView,
  Picker,
  Text,
  TextH1,
  TextH2,
  Toast,
} from '@elements'
import { formatAddress } from '@helpers/display'
import { errorToString, extendErr, groupBy } from '@helpers/helpers'
import { createAddressString, getDeliveryFee, getUniqueDates } from '@helpers/location'
import { objToStr } from '@helpers/log'
import { MoneyCalc } from '@helpers/money'
import { calculatePayments, getPickups } from '@helpers/order'
import { sortByName } from '@helpers/sorting'
import { isAfter } from '@helpers/time'
import { AlgoliaAdminCustomer, AlgoliaAdminProduct } from '@models/Algolia'
import { isNonPickup } from '@models/Location'
import { Zero } from '@models/Money'
import { SplitTenderPayment, isCartPhysical } from '@models/Order'
import { isEbtPayment, pmt_CashMethod } from '@models/PaymentMethod'
import { DigitalStandard, Product, ProductType, Share, Standard } from '@models/Product'
import { userName } from '@models/User'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { validateEbtPin } from '@screens/PaymentMethods/validateEbtPin'
import { CartItemGroupType } from '@screens/Shopping/Checkout/CartItemsCheckout'
import { hasInstallments, initialTotal } from '@screens/Shopping/Checkout/helpers'
import { DateTime } from 'luxon'
import { createContext, useCallback, useEffect, useMemo, useRef } from 'react'
import { useConfigure, useHits, useSearchBox } from 'react-instantsearch'
import { Hit } from 'react-instantsearch-core'
import { StyleSheet, TextInput, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { snapshotProductsOrderCreator } from '@api/Products'
import { AdminUserAutoComplete } from '../../components/AdminUserAutocomplete'
import {
  CreateOrderScreenContextType,
  buildProdFilters,
  createOrderScreenInitialState,
  dbProdsAreUpdated,
  getPickupsForSelectedDistro,
  goBackString,
  goBackUrl,
  hitsAreUpdated,
  makeTableData,
} from './CreateOrderScreen.helper'
import { ShareRow, StandardRow } from './components/OrderCreatorRows'
import { OrderSummary } from './components/OrderCreatorSummary'

import { BackTo } from '@/admin/components/BackTo'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { AdminOrdersParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { useSetCartService } from '@/constants/appInitialization/useSetCartService'
import { useAlgoliaState } from '@/hooks/useAlgoliaState'
import { useApiFx, useSnapshot } from '@/hooks/useApiFx'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useDeepCompareCallback } from '@/hooks/useDeepEqualEffect'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState, { KeyedState } from '@/hooks/useKeyedState'
import { useDeviceSize, useLayout } from '@/hooks/useLayout'
import { useValidateCartAlert } from '@/hooks/useValidateCart'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { withAdminIndexHooks } from '@/hooks/withAlgoliaIndex'
import { setAdminSchedules } from '@/redux/actions/adminPersist'
import { setOrderCreatorCustomer } from '@/redux/actions/adminState'
import { adminCartInfoSelector, adminFarmSelector, adminSchedulesSelector } from '@/redux/selectors'
import { cartsCollection } from '@api/framework/ClientCollections'
import { AccessRight, Permission } from '@helpers/Permission'
import { pick } from '@helpers/typescript'
import { isFeeProductFee, isTaxProductFee } from '@models/ProductFee'

export const CreateOrderScreenContext = createContext<KeyedState<CreateOrderScreenContextType>>(
  {} as KeyedState<CreateOrderScreenContextType>,
)

function CreateOrderScreenComp() {
  /** If not provided, the `params.orderType` defaults to 'standard', so it will never really be undefined. But the param type should remain optional because it shouldn't be required for other screens to navigate here. */
  const { params } = useRoute<RouteProp<AdminOrdersParamList, 'CreateOrder'>>()
  const navigation = useNavigation<StackNavigationProp<AdminOrdersParamList, 'CreateOrder'>>()
  const farm = useSelector(adminFarmSelector)
  const [loading, setLoading] = useKeyedState<Loaders>({
    customer: true,
    products: true,
    order: false,
    distros: true,
  })
  const dispatch = useDispatch()
  const keyedState = useKeyedState<CreateOrderScreenContextType>(createOrderScreenInitialState, {
    onStateSet: (s) => {
      /** When state changes, this will run in order for the cart service to assign the customer to the db cart */
      dispatch(setOrderCreatorCustomer(s.customer))
    },
  })
  const [
    {
      prodSearchTerm,
      distro,
      customer,
      pickupDates,
      pickupDate,
      csa,
      splitTender,
      markAsPaidInfo,
      total,
      tableData,
      deliveryFeesData: { hasDeliveryFees },
    },
    set,
    setState,
    setters,
  ] = keyedState
  const distros = useSelector(adminSchedulesSelector)

  useConfigure({
    facetFilters: buildProdFilters(farm.id, params?.orderType, distro?.id, csa?.id),
    hitsPerPage: 400,
  })
  const { hits } = useHits<Hit<AlgoliaAdminProduct>>()
  const { loadingSearch, noResults } = useAlgoliaState()
  const { refine: refineSearch, clear: clearSearch } = useSearchBox()

  const { cart, discount } = useSetCartService(true)
  const validateCartAlert = useValidateCartAlert({ isAdmin: true })
  const { cartId } = useSelector(adminCartInfoSelector)
  const { isLargeDevice, height } = useLayout()

  /** This is a condition for the following useApiFx to determine whether it should fetch the customer's pickups or not, it should not fetch them if there's no delivery fees in the cart, since that would be pointless */
  const shouldLoadCustPickups = useMemo(() => {
    /** Here we need to determine whether the cart has delivery fees, without considering future pickups. However, we can't use the state 'hasDeliveryFees' because that one is calculated based on future pickups (which is the result of the next useApiFx effect). So if we used it, there would be a race condition where both states depend on each other's value */
    const { itemsDeliveryFees } = getDeliveryFee(cart) // dont include pickups in this call to getDeliveryFee()
    return itemsDeliveryFees && MoneyCalc.isGTZero(itemsDeliveryFees)
    // this only needs to update when product ids in cart change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objToStr(cart.map((i) => i.product.id).sort())])

  /** Fetches future pickups for the customer. These future pickups are used for determining combined delivery dates */
  const custPickupsFx = useApiFx(
    loadFuturePickupsByUserAndFarm,
    [customer?.id ?? '', farm.id],
    !!farm.id && shouldLoadCustPickups,
    {
      onError: () =>
        Toast("There was an error while loading the customer's pickups. Can't determine correct delivery fees."),
    },
  )

  /** Sets the total delivery fee data into main context state */
  useDeepCompareFocusFx(() => {
    if (custPickupsFx.loading) {
      return set('deliveryFeesData', (prev) => ({ ...prev, loading: true }))
    }
    if (custPickupsFx.err) {
      return set('deliveryFeesData', (prev) => ({ ...prev, err: custPickupsFx.err }))
    }
    const deliveryFeeData = getDeliveryFee(cart, { pickups: custPickupsFx.data })
    const hasDeliveryFees = deliveryFeeData?.itemsDeliveryFees && MoneyCalc.isGTZero(deliveryFeeData?.itemsDeliveryFees)
    set('deliveryFeesData', {
      ...deliveryFeeData,
      hasDeliveryFees,
      err: undefined,
      loading: false,
    })
  }, [cart, set, custPickupsFx])

  /** Sets into context state a grouping of items by schedule location, allows managing delivery fee totals per location */
  useFocusFx(() => {
    // groups of cart items which have the same address
    const deliveryGroups = groupBy(cart, (itm) =>
      isCartPhysical(itm)
        ? //group items by distro id and address.
          itm.distribution.location.id + createAddressString(itm.distribution.location.address!)
        : 'digital',
    ).map((group): CartItemGroupType => {
      const locId = group[0].distribution?.location.id
      const { itemsDeliveryFees, combinedDates, combinedPickups } = getDeliveryFee(group, {
        pickups: custPickupsFx.data,
        locId,
      })

      return {
        items: group,
        address: isCartPhysical(group[0]) ? formatAddress(group[0].distribution.location.address!) : undefined,
        locationFee:
          isCartPhysical(group[0]) && isNonPickup(group[0].distribution.location)
            ? group[0].distribution.location.cost
            : undefined,
        groupDeliveryTotal: itemsDeliveryFees,
        groupDeliveryTotalDueNow: undefined, //hard-coded because we invoice delivery fees always in the future
        combinedDeliveryDates: combinedDates,
        combinedDeliveryPickups: combinedPickups,
        uniqueDates: getUniqueDates(group),
        locType: isCartPhysical(group[0]) ? group[0].distribution.location.type : undefined,
      }
    })
    set('itemGroups', deliveryGroups)
  }, [cart, custPickupsFx.data, set])

  /** Gets the cart total amounts due now */
  useFocusFx(() => {
    if (!cart.length || !farm?.timezone) return
    const payments = calculatePayments({ items: cart, isAdmin: true, discount }, splitTender)
    if (!payments) return

    // If there is no payment due today don't list it in the checkout page
    if (isAfter(payments[0].date, DateTime.now(), { granularity: 'day', zone: farm.timezone })) {
      return set('total', initialTotal)
    }

    set('additionalFees', payments[0].taxesAndFees?.filter((itm) => isFeeProductFee(itm.productFee)) ?? [])

    const totalTaxesAndFees =
      payments[0].taxesAndFees?.reduce((acc, itm) => MoneyCalc.add(acc, itm.amount), Zero) ?? Zero

    const tax =
      payments[0].taxesAndFees
        ?.filter((itm) => isTaxProductFee(itm.productFee))
        .reduce((acc, itm) => MoneyCalc.add(acc, itm.amount), Zero) ?? Zero

    set('total', {
      subtotal: payments[0].subtotal,
      total: MoneyCalc.add(payments[0].total, totalTaxesAndFees),
      discounts: payments[0].discounts,
      ebtEligibleAmount: payments[0].ebtEligibleAmount,
      tax,
    })

    // splitTender is intentionally left out because we only need to update it when discounts change. Otherwise, there will
    // be an infinite loop of discount making total less which makes splitTender less which makes discount less and so on.
  }, [cart, discount, farm?.timezone, set])

  /** If there's a single distro, auto-select it */
  useFocusFx(() => {
    if (!loading.products && params?.orderType === 'standard' && !distro && distros.length === 1)
      set('distro', distros[0])
  }, [params?.orderType, distro, distros, loading.products, set])

  /** This input ref will control the customer selection text input */
  const customerInputRef = useRef<TextInput>(null)

  /** Called when a customer is selected in the autocomplete input. This should only set the nav param. Then a different fx should react to the navParam change, and handle fetching the customer for the current custId param **/
  const onUserSelected = useCallback(
    (user: AlgoliaAdminCustomer) => {
      navigation.setParams({ custId: user.id })
    },
    [navigation],
  )

  /** Gets the customer data for the current custId navigation parameter. */
  useCancelableFocusFx(
    async (isActive) => {
      if (!params?.custId) {
        set('customer', undefined)
        return setLoading('customer', false)
      }

      setLoading('customer', true)
      try {
        const customer = await loadUser(params.custId)
        if (!isActive) return
        if (customer) {
          set('customer', customer)
        } else {
          throw new Error('Could not load customer in create order screen. userId: ' + params.custId)
        }
      } catch (e) {
        Logger.error(e)
        Toast('Could not load the selected customer')
      }
      setLoading('customer', false)
    },
    [params?.custId, set, setLoading],
  )

  const notSelectedText = 'No customer selected'
  /** Element that shows info on the currently selected customer */
  const customerTextEl = useMemo(() => {
    const text =
      customer && params?.custId === customer.id
        ? `${userName(customer)} | ${customer.email}`
        : customer && params?.custId !== customer.id
        ? 'Updating customer...'
        : notSelectedText

    return (
      <LoadingView loading={loading.customer}>
        {cart.length > 0 && text === notSelectedText ? (
          <ErrorText>{text}</ErrorText>
        ) : (
          <Text style={styles.marginV15}>{text}</Text>
        )}
      </LoadingView>
    )
  }, [customer, params?.custId, cart, loading.customer])

  /** Syncs the farm distros into admin redux */
  useFocusFx(() => {
    setLoading('distros', true)
    const unsubscribe = snapshotDistributionsByFarm(farm.id, (distros) => {
      dispatch(setAdminSchedules(distros))
      setLoading('distros', false)
    })
    return unsubscribe
  }, [farm.id, dispatch, setLoading])

  /** Fetch farm CSAs into state
   * - Hidden CSAs will not be available as options in the order creator csa selector
   */
  const { data: csas, loading: loadingCsas } = useApiFx(loadCSAsByFarm, [farm.id], !!farm.id, {
    transform: (csas) => csas.filter((csa) => !csa.isHidden),
  })

  /** If there's a single csa, auto-select it */
  useFocusFx(() => {
    if (!loading.products && params?.orderType === 'share' && !csa && csas?.length === 1) set('csa', csas[0])
    // We don't want this to run on csa change because it should be possible to de-select the auto-selected csa
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.orderType, csas, loading.products])

  /** Fetches active farm products from database in response to changes in farm id & order type */
  const {
    data: dbProds,
    loading: loadingDbProds,
    error: dbProdsError,
  } = useSnapshot(snapshotProductsOrderCreator, [farm?.id, params?.orderType], !!farm?.id && !!params?.orderType)

  /**
   * - Calculates the pickup dates for the selected distro
   * - Resets pickup date if distro was reset */
  useFocusFx(() => {
    if (distro) {
      return set('pickupDates', getPickupsForSelectedDistro(distro, dbProds))
    } else {
      //If the distro selector was reset, also reset the pickup date selected and available dates
      return setState((p) => ({ ...p, pickupDate: undefined, pickupDates: [] }))
    }
  }, [distro, set, setState, dbProds])

  /** Updates pickup date when the available dates change */
  useFocusFx(() => {
    if (pickupDates.length) {
      let newDate = pickupDate
      /** When new pickup dates are computed, updates or resets pickup date previously selected */
      if (pickupDate && pickupDates.every((date) => date.toISODate() !== pickupDate.toISODate())) newDate = undefined
      if (pickupDates.length === 1) newDate = pickupDates[0]
      set('pickupDate', newDate)
    }
  }, [pickupDates, pickupDate, set])

  /** Display toast on product load error */
  useFocusFx(() => {
    if (dbProdsError) Toast('Something went wrong while loading products')
  }, [dbProdsError])

  /** `placeOrderProceed` will call the addOrder api. This is only meant to be called by `placeOrderChecks`, once preliminary checks are satisfactory */
  const placeOrderProceed = useCallback(
    (payments: SplitTenderPayment, startNew?: boolean) => {
      if (!cartId) return Alert('Cart Id not found')
      Loader(true)
      addOrder({ cartId, tender: payments, markAsPaidInfo })
        .catch((err) => {
          Loader(false)
          setLoading('order', false)
          Alert('Error Placing Order', errorToString(err))
          Logger.error(extendErr(err, `Error placing an admin order. cartid: ${cartId}`))
        })
        .then(async (result) => {
          Loader(false)
          setLoading('order', false)
          if (result?.id) {
            navigation.setParams({ custId: undefined }) // Clear the customer id at the navigation level will trickle down fx and clear the customer state as well
            setState(createOrderScreenInitialState) // reset the screen state
            if (!startNew) {
              // If the admin is only placing one order then we should navigate to the orderDetails screen to see the order
              navigation.navigate('AdminOrderDetails', { orderId: result.id })
            }
          }
        })
    },
    [cartId, markAsPaidInfo, navigation, setState, setLoading],
  )

  /** `placeOrderCheck` is the first part of the order placing process. It will validate all the conditions are met for placing an order. If all checks pass, it will call placeOrderProceed() */
  const placeOrderCheck = useDeepCompareCallback(
    async (startNew?: boolean) => {
      if (!cartId) return Toast('Cart is not initialized')
      if (!cart.length) return Toast('Cart is empty')

      if (!customer || !params?.custId || customer.id !== params.custId)
        return Alert(
          'No customer selected',
          'Please select the customer for this order under the "Add Customer" header',
          [{ text: 'Select customer', onPress: () => customerInputRef.current?.focus() }],
        )

      // start loading before any async code
      setLoading('order', true)

      try {
        /** Check whether the customer has been assigned to the cart
         * This is a safety measure against the scenario where the cartInit failed to update the cart when we dispatched the selected customer. */
        const cartDoc = await cartsCollection.fetch(cartId)
        if (cartDoc.user?.id !== customer.id) {
          setLoading('order', false)
          set('customer', undefined)
          return Alert(
            'Cart not updated',
            'Something went wrong while assigning the customer to the cart. Please select the customer again',
            [{ text: 'Select customer', onPress: () => customerInputRef.current?.focus() }],
          )
        }

        // Validate the cart and show an alert if there's any issues
        const res = await validateCartAlert()

        /** We will only proceed to adding the order if the user didn't cancel on any alert prompts notifying them of cart changes AND the remaining cart isn't empty */
        if (res.cancelCheckout || res.newCartLength === 0) {
          return setLoading('order', false)
        }

        // If the order is marked as already paid then we should not check split tender and treat the payment as Offline
        if (markAsPaidInfo.markAsPaid) {
          return placeOrderProceed([{ paymentMethod: pmt_CashMethod }], startNew)
        }

        // Make sure payments are defined, this should never be false after the payment selector confirms it
        // it sets the loading state to false if there's an error
        if (!splitTender || splitTender.length === 0) {
          setLoading('order', false)
          return Alert('Order Error', 'No payment methods specified for this order')
        }

        // If there is an ebt payment then check the pin and request it if necessary
        const ebtPayment = splitTender?.find((pmt) => isEbtPayment(pmt.paymentMethod))
        if (ebtPayment && isEbtPayment(ebtPayment.paymentMethod)) {
          const { pin } = await validateEbtPin(ebtPayment.paymentMethod, customer.id, true)
          ebtPayment.paymentMethod.pin = pin
        }

        placeOrderProceed(splitTender, startNew)
      } catch (error) {
        Logger.error(error)
        setLoading('order', false)
        return Toast('Something went wrong. Please try again.')
      }
    },
    [
      customer,
      markAsPaidInfo,
      params?.custId,
      placeOrderProceed,
      setLoading,
      splitTender,
      cartId,
      cart.length,
      set,
      validateCartAlert,
    ],
  )

  /** Update the payment selector options */
  useEffect(() => {
    setters.paymentSelectorOptions({
      farm: pick(farm, 'id', 'name', 'offlinePayments', 'paymentTypes'),
      amountTotal: total.total,
      amountEbt: total.ebtEligibleAmount ?? Zero,
      hasInstallments: hasInstallments(cart),
      hasDelivery: hasDeliveryFees,
      hasFarmBalanceItem: cart.some((ci) => ci.product.type === ProductType.FarmBalance),
      // If there is a total then it means the invoice is due now
      isInvoiceDueToday: MoneyCalc.isGTZero(total.total),
      allowOfflinePayments: true,
    })
  }, [cart, farm, hasDeliveryFees, setters, total])

  /** Prepares the data for the table. dbProds will be filtered by orderType and the ids of the algolia search */
  useFocusFx(() => {
    setLoading('products', true)
    set('tableData', undefined)
    if (!loadingSearch && hits && !loadingDbProds && dbProds) {
      set('tableData', makeTableData(dbProds, params?.orderType, hits, distro, csa, pickupDate))
      setLoading('products', false)
    }
  }, [loadingSearch, loadingDbProds, dbProds, params?.orderType, hits, distro, csa, pickupDate, set, setLoading])

  /** Product search: In response to changes in search string, calls algolia refineSearch and sets loading products to true */
  useFocusFx(() => {
    if (!prodSearchTerm) return clearSearch()
    setLoading('products', true)
    refineSearch(prodSearchTerm)
  }, [prodSearchTerm, setLoading, clearSearch, refineSearch])

  /** Disables products loading state when results reflect search params */
  useFocusFx(() => {
    if (!hits || !dbProds || !tableData || loadingSearch || loadingDbProds) return //The hits can come as undefined, even if the type doesn't say so
    if (hitsAreUpdated(hits, params?.orderType, distro?.id, csa?.id) && dbProdsAreUpdated(dbProds, params?.orderType)) {
      setLoading('products', false)
    }
    if (noResults && !tableData.length) return setLoading('products', false)
  }, [
    hits,
    params?.orderType,
    loadingSearch,
    noResults,
    csa?.id,
    distro?.id,
    dbProds,
    loadingDbProds,
    tableData,
    setLoading,
  ])

  const styleMemo = useLayoutFnStyles(({ isLargeDevice, isMedDevice, isSmallDevice, width }) => {
    return {
      orderOptions: { flex: isLargeDevice ? 1 : undefined, margin: isLargeDevice ? 20 : 10 },
      screenContStyle: { flexDirection: isLargeDevice ? 'row' : 'column' },
      orderSummaryWrapper: {
        marginVertical: 20,
        marginHorizontal: isLargeDevice ? 20 : 10,
        flexDirection: isLargeDevice ? 'column' : 'column-reverse',
        flex: isLargeDevice ? 0.5 : undefined,
      },
      wrapItem: {
        flex: 1,
        minWidth: isSmallDevice ? width * 0.9 : 300,
        marginRight: 10,
        marginVertical: isLargeDevice ? 12 : isMedDevice ? 8 : 3,
      },
      marginV: { marginVertical: isLargeDevice ? 12 : isMedDevice ? 8 : 3 },
      largePlaceOrderBtn: { alignSelf: 'center', paddingHorizontal: isLargeDevice ? 50 : isMedDevice ? 200 : 75 },
    }
  })

  const renderItem = useCallback<NonNullable<OfflineTableProps<Product>['renderItem']>>(
    ({ item, index }) => {
      if (params?.orderType === 'standard') {
        return <StandardRow prod={item as Standard} index={index} />
      } else if (params?.orderType === 'share') {
        return <ShareRow title="Add" prod={item as Share} index={index} />
      } else if (params?.orderType === 'digital') {
        return <StandardRow prod={item as DigitalStandard} index={index} />
      } else if (params?.orderType) {
        Logger.error('Unexpected order type param:' + params?.orderType)
      }
      return null
    },
    [params?.orderType],
  )

  /** Props common to the different types of the offline table */
  const commonProps: Pick<
    OfflineTableProps<Product>,
    | 'searchTerm'
    | 'search'
    | 'placeholder'
    | 'filters'
    | 'isLoading'
    | 'extraData'
    | 'containerStyle'
    | 'maxHeight'
    | 'renderItem'
    | 'scrollEnabled'
  > = useMemo(
    () => ({
      /** maxHeight is necessary on non-large size because the order summary will go below the table. a maxHeight less than the screenHeight should ensure the screen can be scrollable without relying on the narrow borders. For example, to prevent the iOS scroll being stuck in nested scroll. */
      maxHeight: isLargeDevice ? undefined : height * 0.8,
      searchTerm: prodSearchTerm,
      search: setters.prodSearchTerm,
      placeholder: 'Search for products',
      isLoading: loading.products || loadingSearch || loadingDbProds,
      extraData: [loading.products], //variables that make the flatlist re-render. Similar to an effect trigger
      containerStyle: { marginVertical: 15 },
      renderItem,
      scrollEnabled: true,
    }),
    [prodSearchTerm, loading.products, loadingSearch, loadingDbProds, isLargeDevice, setters, renderItem, height],
  )

  const table = useMemo(
    () =>
      params?.orderType === 'standard' ? (
        <OfflineTable
          data={tableData}
          headerColumns={[{ title: 'Product Name', widthFlex: 2 }, { title: 'Price' }, { title: 'Base Unit' }, {}]}
          minWidth={600}
          needsHeaderSpacer /** Needs spacer on standard row because row component has subRows */
          {...(commonProps as Partial<OfflineTableProps<Product>>)}
        />
      ) : params?.orderType === 'share' ? (
        <OfflineTable
          data={tableData}
          headerColumns={[
            { title: 'Share Name', widthFlex: 2 },
            { title: 'Payment' },
            { title: 'Type' },
            { title: 'Start-End Dates' },
            { title: 'Price' },
            { widthFlex: 2 },
          ]}
          minWidth={750}
          needsHeaderSpacer={false} /** No spacer on share row because row component has NO subRows */
          {...(commonProps as Partial<OfflineTableProps<Product>>)}
        />
      ) : params?.orderType === 'digital' ? (
        <OfflineTable
          data={tableData}
          headerColumns={[{ title: 'Product Name', widthFlex: 2 }, { title: 'Price' }, { title: 'Base Unit' }, {}]}
          minWidth={600}
          needsHeaderSpacer /** Needs spacer on digital row because row component has subRows */
          {...(commonProps as Partial<OfflineTableProps<Product>>)}
        />
      ) : null,
    [params?.orderType, tableData, commonProps],
  )

  /** Options for the product type being filtered by the button group */
  const typeButtons = useMemo(
    () => [
      {
        label: 'Standard',
        onPress: () => {
          return (
            set('tableData', undefined), setLoading('products', true), navigation.setParams({ orderType: 'standard' })
          )
        },
      },
      {
        label: 'Subscription',
        onPress: () => {
          return set('tableData', undefined), setLoading('products', true), navigation.setParams({ orderType: 'share' })
        },
      },
      {
        label: 'Digital',
        onPress: () => {
          return (
            set('tableData', undefined), setLoading('products', true), navigation.setParams({ orderType: 'digital' })
          )
        },
      },
    ],
    [navigation, set, setLoading],
  )

  /** Distros should be sorted in groups of same location name, and within each location group, schedules sorted by schedule name */
  const distroDropdownOpts = useMemo(
    () =>
      distros
        .filter(
          (d) =>
            !d.isHidden &&
            (dbProds && dbProds.length ? dbProds.find((p) => p.distributions?.find((pd) => pd.id === d.id)) : true) &&
            getPickups(d, undefined, {
              ignoreOrderCutoffWindow: true,
              excludeHiddenDistros: true,
              ignoreDisableBuyInFuture: true,
            }).length,
        )
        .map((d) => ({
          label: `${d.closed ? '[closed] ' : ''}${d.name} @ ${d.location.name}`,
          value: d.id,
          key: `${d.name} @ ${d.location.name} - ${d.id}`,
        }))
        /**
         * - SortBy name after combination distName and locationName
         * - closed distro should be also sorted by name of combined distName and locationName, not by [closed] prefix
         */
        .sort((a, b) => sortByName(a, b, (dist) => dist.key.toLocaleLowerCase())),
    [distros, dbProds],
  )
  /** Sets the distro from the dropdown picker interaction */
  const setDistro = useCallback(
    (id: string) =>
      set(
        'distro',
        distros.find((d) => d.id === id),
      ),
    [distros, set],
  )
  /** Options for date selector dropdown */
  const dateDropdownOpts = useMemo(
    () =>
      pickupDates.map((d) => ({
        label: d.toLocaleString(),
        value: d.toISODate(),
        key: d.toISODate(),
      })),
    [pickupDates],
  )
  /** Sets date from the date selector dropdown */
  const setDate = useCallback(
    (isoDate: string) =>
      set(
        'pickupDate',
        pickupDates.find((d) => d.toISODate() === isoDate),
      ),
    [set, pickupDates],
  )
  /** Sets the csa from the csa selector dropdown */
  const setCsa = useCallback(
    (csaId: string) =>
      set(
        'csa',
        csas?.find((csa) => csa.id === csaId),
      ),
    [set, csas],
  )
  /** Options for the csa dropdown selector */
  const csaDropdownOpts = useMemo(
    () =>
      (csas ?? []).map((csa) => ({
        label: csa.name,
        value: csa.id,
        key: csa.id,
      })),
    [csas],
  )

  const { isSmallDevice } = useDeviceSize()

  return (
    <CreateOrderScreenContext.Provider value={keyedState}>
      <KeyboardAvoidingScrollView extraHeight={150} contentContainerStyle={styleMemo.screenContStyle}>
        <View style={styleMemo.orderOptions}>
          <BackTo
            title={`Back to ${goBackString(params)}`}
            url={goBackUrl(
              params /**This touchable should use url because using onPress might not work if the Customers navigator isn't loaded due to lazy loading (Happens when you reload the screen) */,
            )}
          />
          <TextH1>New Order</TextH1>
          <View style={styleMemo.marginV}>
            <TextH2 style={styles.marginTop15}>Select Product Type</TextH2>
            <ButtonGroup
              appearance="fill"
              buttons={typeButtons}
              selectedIndex={
                params?.orderType && ['standard', 'share', 'digital'].includes(params.orderType)
                  ? ['standard', 'share', 'digital'].indexOf(params.orderType)
                  : undefined
              }
              useIcon
              small={isSmallDevice}
            />
          </View>
          {params?.orderType !== 'digital' ? (
            <View style={styles.wrap}>
              <View style={styleMemo.wrapItem}>
                <TextH2>Select Schedule</TextH2>
                <Picker
                  items={distroDropdownOpts}
                  placeholder="Schedule @ Location"
                  value={distro?.id}
                  onValueChange={setDistro}
                  style={styles.marginV15}
                  loading={loading.distros}
                />
              </View>
              {params?.orderType === 'standard' ? (
                <View style={styleMemo.wrapItem}>
                  <TextH2>Select Date</TextH2>
                  <Picker
                    items={dateDropdownOpts}
                    placeholder="Choose date"
                    value={pickupDate?.toISODate()}
                    onValueChange={setDate}
                    style={styles.marginV15}
                    loading={loading.distros}
                    disabled={!distro}
                  />
                </View>
              ) : (
                <View style={styleMemo.wrapItem}>
                  <TextH2>CSA Group</TextH2>
                  <Picker
                    items={csaDropdownOpts}
                    placeholder="Choose CSA"
                    value={csa?.id}
                    onValueChange={setCsa}
                    style={styles.marginV15}
                    loading={loadingCsas}
                  />
                </View>
              )}
            </View>
          ) : null}
          <TextH2 style={styles.marginTop15}>Choose Products</TextH2>
          {table}
        </View>
        {cart.length > 0 && (
          <View style={styleMemo.orderSummaryWrapper}>
            <View style={styles.btnsContainer}>
              <Button
                loading={loading.order || loading.customer}
                outline
                title="Place and start new"
                onPress={() => placeOrderCheck(true)}
              />
              <Button
                loading={loading.order || loading.customer}
                title="Place order"
                onPress={() => placeOrderCheck()}
              />
            </View>
            <OrderSummary>
              <TextH2 style={styles.marginTop15}>Add Customer</TextH2>
              <AdminUserAutoComplete inputRef={customerInputRef} onSelect={onUserSelected} />
              {customerTextEl}
            </OrderSummary>
            <Button
              style={styleMemo.largePlaceOrderBtn}
              loading={loading.order || loading.customer}
              title="Place order"
              onPress={() => placeOrderCheck()}
            />
          </View>
        )}
      </KeyboardAvoidingScrollView>
    </CreateOrderScreenContext.Provider>
  )
}

export const CreateOrderScreen = withAdminAuth(
  withAdminIndexHooks(CreateOrderScreenComp),
  Permission.Orders,
  AccessRight.Edit,
)

type Loaders = {
  customer: boolean
  products: boolean
  order: boolean
  distros: boolean
}

const styles = StyleSheet.create({
  flex1: { flex: 1 },
  wrap: { flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' },
  btnsContainer: {
    marginTop: 20,
    marginBottom: 10,
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    alignItems: 'center',
    alignSelf: 'flex-end',
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  marginTop15: { marginTop: 15 },
  marginV15: { marginVertical: 15 },
})
