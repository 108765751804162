import { ListRenderItemInfo } from '@shopify/flash-list'
import React, { useMemo } from 'react'
import { View } from 'react-native'

import { ListEmpty, ListFooter, ShopListHeader } from './FarmShop/components'

import {
  paddedProdWidth,
  paddedProdWidthSM,
  prodHeight,
  prodSharedStyles,
  ProductCardSquare,
  ResponsiveGrid,
} from '@components'
import { StackScreenProps } from '@react-navigation/stack'

import { ShoppingStackParamList } from '../../navigation/types'

import { AlgoliaFarmDataContext, useAlgoliaFarmData } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData'
import { ALL_CATEGORIES } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData-helpers'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCartService } from '@/hooks/useCart'
import { FarmDataContext, useFarmData } from '@/hooks/useFarmData'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useLayout } from '@/hooks/useLayout'
import { withConsumerIndexHooks } from '@/hooks/withAlgoliaIndex'
import { KeyboardAvoidingScrollView, ScreenView, Toast } from '@elements'
import { ArrElement } from '@helpers/typescript'
import { getIdFromSlug, matchesIdOrSlug } from '@helpers/urlSafeSlug'
import { FarmStatus } from '@models/Farm'
import { FarmShopHeader } from './FarmShop/FarmShopHeader'
import { getCardActionShop, MAX_SHOP_WIDTH, shouldShowSidebar } from './FarmShop/helpers'
import { ShopSideBar } from './FarmShop/ShopSideBar'

type Props = StackScreenProps<ShoppingStackParamList, 'FarmShop'>

/** Main shopping screen for the farm. Lists product categories in sections */
function FarmShopComp({ route, navigation }: Props) {
  const { cart } = useCartService()
  const {
    params: { farmSlug, goBack, locationId, categoryId, type, searchTerm, ebtOnly },
  } = route

  const farmData = useFarmData(farmSlug, {
    fetchCsas: true,
    fetchLocs: true,
    fetchProds: false,
  })

  const {
    farm: { data: farm, loading: farmLoading, err: farmError },
    csas: { data: csas },
  } = farmData

  const algoliaData = useAlgoliaFarmData({
    farmSlug,
    locationId,
    categoryId,
    type,
    ebtOnly,
    searchTerm,
  })
  const { products, isLoadingProducts, categoryFilter, errorMsg, activeFiltersNo } = algoliaData

  const layout = useLayout()

  const styles = useStyles()

  /** If farm not registered, exit the shop. */
  useFocusFx(() => {
    if (!farmSlug) return
    if (farm && matchesIdOrSlug(farm, farmSlug) && farm?.status !== FarmStatus.Registered)
      navigation.navigate('FarmDetailScreen', { farmSlug })
  }, [farmSlug, farm, navigation])

  const onBackPress = () => {
    if (goBack === 'explore') {
      return navigation.navigate('Home', { screen: 'ExploreScreen' })
    }

    if (goBack === 'home') {
      return navigation.navigate('Home', { screen: 'HomeScreen' })
    }
    navigation.navigate('FarmDetailScreen', { farmSlug })
  }

  const farmId = useMemo(() => getIdFromSlug(farmSlug, farm), [farmSlug, farm])
  const { availAddons } = useAvailAddons(farmId)

  const renderItem = ({ item: prod }: ListRenderItemInfo<ArrElement<typeof products>>) => {
    const prodCsa = prod.csa?.length ? csas?.find((csa) => prod.csa.includes(csa.id)) : undefined
    return (
      <ProductCardSquare
        small={layout.isExtraSmallDevice}
        product={prod}
        cardAction={getCardActionShop(
          prod,
          availAddons.map((adn) => adn.id),
          cart.map((itm) => itm.product.id),
        )}
        onPressMobileNavigate={(p) => {
          navigation.navigate('ProductDetails', {
            farmSlug,
            productId: p.id,
            csaId: prodCsa?.id,
          })
        }}
        style={prodSharedStyles.responsiveWrapper}
        csa={prodCsa}
        onPressCsaNavigate={(p, csa) => {
          if (!csa) return Toast("This CSA isn't active")
          navigation.navigate('CSADetails', { farmSlug, csaId: csa.id })
        }}
      />
    )
  }

  const categoryLabel = categoryFilter.currentFilter?.label ?? products.length ? ALL_CATEGORIES : ''

  return (
    <FarmDataContext.Provider value={farmData}>
      <AlgoliaFarmDataContext.Provider value={algoliaData}>
        {/* ScreenView is necessary here to make the main app Header stick to the top, while being outside of the screen component */}
        <ScreenView>
          <KeyboardAvoidingScrollView>
            <FarmShopHeader farm={matchesIdOrSlug(farm, farmSlug) ? farm : undefined} onBackPress={onBackPress} />
            <View style={styles.main}>
              {shouldShowSidebar(layout.isLargeDevice) && <ShopSideBar />}
              <View style={styles.prodListContainer}>
                <ResponsiveGrid
                  estimatedItemSize={prodHeight}
                  itemBaseWidth={layout.isExtraSmallDevice ? paddedProdWidthSM : paddedProdWidth}
                  showsVerticalScrollIndicator={false}
                  renderItem={renderItem}
                  ListHeaderComponent={<ShopListHeader title={categoryLabel} />}
                  /** Return empty list when hits are loading or if there is any error, in order to show the `ListEmpty` component in the UI
                   * - This will help when filtering is applied for example, and the connection is slow. So the user will see that something is loading
                   */
                  data={!!errorMsg || isLoadingProducts ? [] : products}
                  ListEmptyComponent={
                    <ListEmpty
                      hasFiltering={!!activeFiltersNo}
                      prodsLoading={isLoadingProducts}
                      hasError={!!farmError || !!errorMsg}
                    />
                  }
                  extraData={csas}
                />
              </View>
            </View>
            <ListFooter loading={farmLoading} />
          </KeyboardAvoidingScrollView>
        </ScreenView>
      </AlgoliaFarmDataContext.Provider>
    </FarmDataContext.Provider>
  )
}

export const FarmShop = withConsumerIndexHooks(FarmShopComp)

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isSmallDevice }) => ({
    main: {
      maxWidth: MAX_SHOP_WIDTH,
      alignSelf: 'center',
      width: '100%',
      flex: 1,
      /** Direction must be `row` if Sidebar is visible */
      flexDirection: shouldShowSidebar(isLargeDevice) ? 'row' : 'column',
    },
    prodListContainer: {
      padding: isSmallDevice ? 10 : 15,
      flex: 1,
    },
  }))
