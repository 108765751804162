import { FarmSetupTab } from '@/admin/navigation/types'
import { useDeviceSize } from '@/hooks/useLayout'
import { ButtonGroup, Divider, Picker, PickerProps } from '@elements'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'

type Props = {
  selectedTab?: FarmSetupTab
  visibleTabs: FarmSetupTab[]
  onPress: (tab: FarmSetupTab) => void
}

const mappedTabNames: Record<FarmSetupTab, string> = {
  payments: 'Payments',
  memberships: 'Membership & Plan',
  payouts: 'Payouts',
  productFees: 'Taxes & Fees',
  members: 'Team members',
}

const tabsToPickerItems = (tabs: FarmSetupTab[]): PickerProps['items'] => {
  return tabs.map((tab) => {
    return { value: tab, label: mappedTabNames[tab] }
  })
}

export const TabSelector = memo(function TabSelector({ selectedTab, visibleTabs, onPress }: Props) {
  const { isSmallDevice } = useDeviceSize()

  if (isSmallDevice) {
    return (
      <View>
        <Picker
          style={styles.picker}
          value={selectedTab}
          placeholder={null}
          items={tabsToPickerItems(visibleTabs)}
          onValueChange={(val) => onPress(val as FarmSetupTab)}
        />
        <Divider clear />
      </View>
    )
  }

  return (
    <>
      <Divider bottom={0} top={0} />
      <ButtonGroup
        onSelect={(idx) => onPress(visibleTabs[idx])}
        selectedIndex={!selectedTab ? undefined : visibleTabs.indexOf(selectedTab)}
        buttons={visibleTabs.map((tab) => mappedTabNames[tab])}
      />
    </>
  )
})
const styles = StyleSheet.create({
  picker: {
    width: '95%', // needed in order to align with the rest of the content,
    alignSelf: 'center',
  },
})
