import { getNearbyProducts } from '@api/Products'
import { validCoords } from '@helpers/coordinate'
import { removeDuplicates } from '@helpers/helpers'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { Coordinate } from '@models/Coordinate'

import { Logger } from '@/config/logger'
import { isSmallDevice } from '@/constants/Layout'
import { useProgressiveLoading } from './useProgressiveLoading'
import { useState } from 'react'
import { useFocusFx } from './useFocusFx'

/** Returns the number of farms expected of the results, for the current device */
export const getRequiredNFarms = () => (isSmallDevice() ? 2 : 3)

/** This is the number of products that will be fetched for each search to be done. If the number of farms is not met after reaching this number, it will continue loading more */
const getTargetLength = () => (isSmallDevice() ? 35 : 50)

const getPageLength = () => Math.ceil(getTargetLength() / (isSmallDevice() ? 2 : 3))

/**
 * Hook that fetches the most nearby products based on a specified location
 * @param coords - coordinates of the desired location
 */
export const useGetNearbyProds = (coords?: Coordinate) => {
  const [lastCoords, setLastCoords] = useState(coords)
  const [updateSignal, setUpdateSignal] = useState(0)

  /** Whenever the screen re-focuses, if the new coords are different and it has successfully run before, this should force a re-run */
  useFocusFx(() => {
    if (
      !!coords &&
      !!lastCoords &&
      (coords.latitude !== lastCoords.latitude || coords.longitude !== lastCoords.longitude)
    ) {
      setUpdateSignal((prev) => prev + 1)
    }
  }, [coords, lastCoords])

  return useProgressiveLoading<AlgoliaGeoDoc<AlgoliaGeoProduct>, number>({
    initialState: { loading: true },
    getPageData: async (cursor, pageLength) => {
      const hits = await getNearbyProducts(coords!, {
        length: pageLength,
        offset: cursor === null ? 0 : cursor, // offset is the starting point for the next set of results
      })

      return {
        data: hits,
        lastCursor: (cursor ?? 0) + hits.length,
      }
    },
    runCondition: !!coords && validCoords(coords),
    targetLength: getTargetLength(),
    pageLength: getPageLength(), // There will be at least 3 iterations before reaching the target length
    shouldFetchMore: (results) => {
      /** If the number of unique farms is less than this number, should continue fetching more products.
       * If the target length was reached, but there are still less than the required N farms, it should continue
       * fetching more produts.
       */
      return removeDuplicates(results.map((res) => res.farm.id)).length < getRequiredNFarms()
    },
    maxCalls: 6, // If the required N farms isn't reached by the 6th call, it'll end regardless
    failedConditionMode: 'stop-loading',
    // When loading is done, keep track of the last coords used
    onDone: ({ data, loading }) => {
      if (data.length && !loading) {
        setLastCoords(coords)
      }
    },
    onError(err) {
      Logger.error(err)
    },
    noRefocus: true,
    deps: [coords, updateSignal],
  })
}
