import { AlgoliaGeoDoc, AlgoliaGeoProduct, isAlgoliaDoc } from '@models/Algolia'
import { isNonPickupDistLocation } from '@models/Location'
import { CartItem, isCartPhysical } from '@models/Order'
import { Product, hasUnits, isPhysical } from '@models/Product'
import { getPaymentSchedules } from './order'
import { getUnits } from './products'

/** given a cartItem and its most recent product, will return a copy of the cartItem with updated data from the most recent product
 * @param prod the fresh product data for the cart item
 */
export function refreshCartItemData(cartItem: CartItem, prod: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>) {
  // The product ids must match
  if (cartItem.product.id !== prod.id) return cartItem

  const updatedCartItem = { ...cartItem } as typeof cartItem
  if (!isAlgoliaDoc(prod)) {
    updatedCartItem.product = prod

    if (hasUnits(prod)) {
      // refresh unit and price in cart item
      const updatedUnit = getUnits(prod).find((u) => u.id === updatedCartItem.unit?.id)
      if (updatedUnit) {
        updatedCartItem.unit = updatedUnit

        const updatedPrice = updatedUnit.prices.find((pr) => pr.id === cartItem.price?.id)
        if (updatedPrice) updatedCartItem.price = updatedPrice
      }
    }

    if (isPhysical(prod)) {
      // refresh the schedule in the cart item
      const updatedSchedule = prod.distributions.find((sch) => sch.id === cartItem.distribution?.id)
      if (updatedSchedule) {
        // If the item was using a delivery address, the address must be put back in the item location after the refresh
        const deliveryAddr =
          isCartPhysical(cartItem) && isNonPickupDistLocation(cartItem.distribution?.location)
            ? cartItem.distribution.location.address
            : undefined

        updatedCartItem.distribution = updatedSchedule
        if (deliveryAddr) {
          updatedCartItem.distribution.location.address = deliveryAddr
        }
      }
    }

    // refresh the payment schedule in the item

    // first get the payment schedules available to the cart item based on its relevant options
    const paySchedules = getPaymentSchedules({ product: prod, pickups: cartItem.pickups, price: cartItem.price })
    const updatedPs = paySchedules.find((ps) => ps.frequency === cartItem.paymentSchedule.frequency)
    if (updatedPs) updatedCartItem.paymentSchedule = updatedPs
  }
  return updatedCartItem
}
