import { EbtIcon, Image } from '@components'
import { CheckBox, Spinner, Text, Toast, Touchable } from '@elements'
import { capitalize, formatMoney } from '@helpers/display'
import { ArrElement } from '@helpers/typescript'
import { Money } from '@models/Money'
import { GlobalStandard, Product, UnitStandard, hasGlobalStock, hasUnitStock, hasUnits, isShare } from '@models/Product'
import { dateTimeInZone } from '@models/Timezone'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback, useContext, useMemo } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { saveGlobalQuantity } from '../helpers/saveGlobalQuantity'
import { saveGlobalUnitPrice } from '../helpers/saveGlobalUnitPrice'
import { savePricePerUnit } from '../helpers/savePricePerUnit'
import { saveUnitStockQuantity } from '../helpers/saveUnitStockQuantity'
import { NumOrMoneyInput } from './NumOrMoneyInput'
import { RowActions } from './RowActions'

import { Column, ExpandableRow, ExpandableRowProps } from '@/admin/components/OfflineTable/ExpandableRow'
import { ProductTag } from '@/admin/components/ProductTag'
import { AdminProductsParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { newSaleStat } from '@/constants/types'
import { useFnStyles } from '@/hooks/useFnStyles'
import useKeyedState from '@/hooks/useKeyedState'
import { addQueueAlgoliaProduct } from '@/redux/actions/adminState'
import { adminFarmSelector, queueAlgoliaProducts } from '@/redux/selectors'
import { productsCollection } from '@api/framework/ClientCollections'
import { sortUnits } from '@helpers/sorting'
import { AlgoliaAdminProduct, isAlgoliaAdminProduct } from '@models/Algolia'
import { useHits } from 'react-instantsearch'
import { AdminProductListContext } from '../AdminProductListScreen'

type RowItem = Product['id']

type Props = ExpandableRowProps<RowItem>

/** This component is used for the Product List screen when in spreadSheet mode */
export const ProductRow = memo(function ProductRow({ index, item: prodId }: Props) {
  const { timezone } = useSelector(adminFarmSelector)
  const [{ selection, saleStats }, setCtx] = useContext(AdminProductListContext)
  const isSelected = selection[prodId]
  const [{ prodDb }, , setState] = useKeyedState({
    /** The product from the db will only be fetched when opening the sub rows */
    prodDb: undefined as Product | undefined,
  })
  const navigation = useNavigation<StackNavigationProp<AdminProductsParamList, 'ProductList'>>()
  const productQueue = useSelector(queueAlgoliaProducts) //Any products here should take precedence over algolia results

  // if the product was just added, it'll be in the queue, which means it's not in algolia yet
  const isInAddQueue = isAlgoliaAdminProduct(productQueue[prodId])

  const { hits } = useHits<AlgoliaAdminProduct>()

  /** This will obtain the algolia admin product to display. The data might come from either the search hits, or from the product queue */
  const prod = useMemo<AlgoliaAdminProduct | undefined>(() => {
    // If the data is in the queue, use that. This ensures edited data will have precedence over algolia data
    const dataInQueue = productQueue[prodId]
    if (dataInQueue?.data) return dataInQueue.data

    // If the data is in the hits, use that
    const hit = hits.find((h) => h.id === prodId)
    if (hit) return hit

    return undefined
  }, [hits, prodId, productQueue])

  // TODO: (Later) this lastAvailStamp should be obtained from the product model and not algolia hit, once it is added to the firestore model
  const lastAvailStamp = prod?.lastAvailStamp

  const isAvailable =
    lastAvailStamp === undefined && isInAddQueue
      ? true
      : lastAvailStamp
      ? dateTimeInZone(timezone).plus({ minutes: 5 }).toMillis() <= lastAvailStamp
      : false

  const styles = useStyles(isAvailable, !!prod?.isHidden)

  const sales = saleStats.get(prodId) || newSaleStat()

  const onExpand = useCallback(() => {
    setState((prev) => ({ ...prev, prodDb: undefined }))
    productsCollection.fetch(prodId).then((prod) => setState((prev) => ({ ...prev, prodDb: prod })))
  }, [prodId, setState])

  const dispatch = useDispatch()

  /** Product main row columns */
  const mainRowColumns = useMemo<Column<NonNullable<typeof prod>>[]>(
    () => [
      {
        process: () => (
          <CheckBox
            checked={!!isSelected}
            onChange={() => {
              setCtx('selection', (p) => ({ ...p, [prodId]: !isSelected }))
            }}
          />
        ),
        widthFlex: 0.5,
      },
      {
        process: (product) => <Image source={{ uri: product.image }} style={styles.mainImage} />,
        widthFlex: 0.7,
      },
      {
        process: (product) => (
          <Touchable onPress={() => navigation.navigate('EditProduct', { prodId })}>
            <Text numberOfLines={2}>
              <EbtIcon product={product} /> {product.name}
            </Text>
          </Touchable>
        ),
        widthFlex: 2,
      },
      {
        process: (product) => product.skuDisplay ?? '',
        widthFlex: 1.5,
      },
      {
        process: (product) => (
          <View style={styles.productTag}>
            <ProductTag type={product.type} />
            {product.isFeatured && <ProductTag type="featured" />}
          </View>
        ),
        widthFlex: 1.5,
      },
      {
        process: (product) => (hasUnits(product) ? product.baseUnit : 'Share'),
      },
      {
        /** Price: Only allow changing UnitStandard's pricePerUnit at the top-level row */
        process: (product) => {
          if (hasUnitStock(product))
            return (
              <NumOrMoneyInput<Money>
                value={product.pricePerUnit!}
                onSave={async (newPrice) => {
                  try {
                    const updatedProd = await savePricePerUnit(newPrice, prodId)
                    dispatch(addQueueAlgoliaProduct({ prodId: updatedProd.id, data: updatedProd, action: 'edited' }))
                    Toast(`The price per unit was updated`)
                    if (prodDb) onExpand() // This will refresh the subRows if they've been expanded
                  } catch (error) {
                    Toast('Update failed')
                    Logger.error(error)
                    throw error // This must throw the error, to so the input component can catch and prevent setting the new value in state
                  }
                }}
              />
            )
          return (
            <View>
              <Text numberOfLines={2} style={styles.textStyle}>
                {product.price}
              </Text>
            </View>
          )
        },
        widthFlex: 1.5,
      },
      {
        /** Only allow updating the quantity of product types with global stock at the first row level*/
        process: (product) =>
          hasGlobalStock(product) ? (
            <NumOrMoneyInput
              value={product.stock}
              onSave={async (newQty) => {
                try {
                  const updatedProd = await saveGlobalQuantity(newQty, prodId)
                  dispatch(addQueueAlgoliaProduct({ prodId: updatedProd.id, data: updatedProd, action: 'edited' }))
                  Toast(`The stock was updated`)
                  if (prodDb) onExpand() // This will refresh the subRows if they've been expanded
                } catch (error) {
                  Toast('Update failed')
                  Logger.error(error)
                  throw error // This must throw the error, to so the component can catch and prevent setting the new value in state
                }
              }}
            />
          ) : (
            <View>
              <Text numberOfLines={2} style={styles.textStyle}>
                -
              </Text>
            </View>
          ),
      },
      {
        process: () => (
          <View style={styles.availabilityBox}>
            <Text>{prod?.availabilityDisplay ?? ''}</Text>
            <View style={styles.availDot} />
          </View>
        ),
        widthFlex: 1.5,
      },
      {
        process: (product) => (
          <View style={globalStyles.padding10}>
            <RowActions algoliaProd={product} sales={sales} />
          </View>
        ),
        widthFlex: 0.5,
      },
    ],
    [
      navigation,
      prod?.availabilityDisplay,
      sales,
      styles.availDot,
      styles.availabilityBox,
      styles.mainImage,
      styles.productTag,
      styles.textStyle,
      isSelected,
      prodId,
      setCtx,
      prodDb,
      onExpand,
      dispatch,
    ],
  )

  /** callback that generates the subRows for the main product row */
  const genSubRows = useCallback(() => {
    if (!prodDb) return [<Spinner key="spinner" style={styles.spinnerSubrow} />]

    const rows: JSX.Element[] = []
    let subRowCount = 0

    if (hasUnits(prodDb)) {
      prodDb.units.sort(sortUnits).map((unit) => {
        subRowCount++
        rows.push(
          <ExpandableRow
            item={unit}
            rowContainerStyle={styles.rowContainer}
            columns={[
              { widthFlex: 0.5 },
              { widthFlex: 0.7 },
              {
                process: () => unit.name,
                widthFlex: 2,
              },
              {
                process: () => (unit.sku ? unit.sku.replace(`${prodDb.unitSkuPrefix || ''}-`, '') : ''),
                widthFlex: 1.5,
              },
              { widthFlex: 1.5 },
              {},
              {
                /** This is exclusively for updating the stock global stock products */
                process: () =>
                  !isShare(prodDb) && hasGlobalStock(prodDb) ? (
                    <NumOrMoneyInput<Money>
                      value={unit.prices[0].amount}
                      onSave={async (newPrice) => {
                        try {
                          const updatedProd = await saveGlobalUnitPrice(
                            newPrice,
                            prodDb,
                            unit as ArrElement<GlobalStandard['units']>,
                          )
                          Toast(`The stock was updated`)
                          dispatch(
                            addQueueAlgoliaProduct({ prodId: updatedProd.id, data: updatedProd, action: 'edited' }),
                          )
                          if (prodDb) onExpand() // This will refresh the subRows if they've been expanded
                        } catch (error) {
                          Logger.error(error)
                          Toast('Update failed')
                          throw error // This must throw the error, to so the component can catch and prevent setting the new value in state
                        }
                      }}
                    />
                  ) : (
                    <View>
                      <Text numberOfLines={2} style={styles.textStyle}>
                        {formatMoney(unit.prices[0].amount)}
                      </Text>
                    </View>
                  ),
                widthFlex: 1.5,
              },
              {
                /** This is exclusively for updating the stock of a specific unitStock buying option */
                process: (unit) =>
                  hasUnitStock(prodDb) ? (
                    <NumOrMoneyInput
                      value={unit.quantity ?? 0}
                      onSave={async (newQuantity) => {
                        try {
                          const updatedProd = await saveUnitStockQuantity(
                            newQuantity,
                            prodDb,
                            unit as ArrElement<UnitStandard['units']>,
                          )
                          dispatch(
                            addQueueAlgoliaProduct({ prodId: updatedProd.id, data: updatedProd, action: 'edited' }),
                          )
                          Toast(`The stock was updated`)
                          if (prodDb) onExpand() // This will refresh the subRows if they've been expanded
                        } catch (error) {
                          Logger.error(error)
                          Toast('Update failed')
                          throw error // This must throw the error, to so the component can catch and prevent setting the new value in state
                        }
                      }}
                    />
                  ) : (
                    <View>
                      <Text numberOfLines={2} style={styles.textStyle}>
                        -
                      </Text>
                    </View>
                  ),
              },
              { widthFlex: 1.5 },
              {
                widthFlex: 0.5,
              },
            ]}
            index={subRowCount}
            key={`prodList-unitRow_${prodId}_${subRowCount}`}
          />,
        )
      })
    } else if (isShare(prodDb)) {
      rows.push(
        <ExpandableRow
          item={{}}
          columns={[
            { widthFlex: 0.5 },
            { widthFlex: 0.7 },
            {
              process: () => <Text type="bold">Pay Schedule</Text>,
              widthFlex: 2,
            },
            { widthFlex: 1.5 },
            { process: () => <Text type="bold">Total</Text>, widthFlex: 1.5 },
            {},
            { process: () => <Text type="bold">Deposit</Text>, widthFlex: 1.5 },
            {},
            { widthFlex: 1.5 },
            { widthFlex: 0.5 },
          ]}
          index={subRowCount}
          key={`prodList-unitRow_${prodId}_${subRowCount}`}
        />,
      )
      prodDb.paymentSchedules.map((ps, index) => {
        subRowCount++
        rows.push(
          <ExpandableRow
            item={ps}
            columns={[
              { widthFlex: 0.5 },
              { widthFlex: 0.7 },
              {
                process: (ps) => capitalize(ps.frequency.toLocaleLowerCase()),
                widthFlex: 2,
              },
              { widthFlex: 1.5 },
              { process: (ps) => formatMoney(ps.amount), widthFlex: 1.5 },
              {},
              { process: (ps) => formatMoney(ps.deposit), widthFlex: 1.5 },
              {},
              { widthFlex: 1.5 },
              { widthFlex: 0.5 },
            ]}
            index={index}
            key={`prodList-payScheduleRow_${prodId}_${index}`}
          />,
        )
      })
    }
    return rows
  }, [styles.rowContainer, styles.textStyle, prodId, prodDb, styles.spinnerSubrow, onExpand, dispatch])

  if (!prod) return null

  return (
    <ExpandableRow<NonNullable<typeof prod>>
      item={prod}
      onExpand={onExpand}
      index={index}
      key={`prodList_${prodId}`}
      rowContainerStyle={styles.rowContainer}
      columns={mainRowColumns}
      generateSubRows={genSubRows}
    />
  )
})

const useStyles = (avail: boolean, prodHidden: boolean) =>
  useFnStyles(
    (avail: boolean, prodHidden: boolean) => ({
      rowContainer: {
        backgroundColor: prodHidden ? Colors.lightGray : Colors.white,
        alignItems: 'center',
      },
      productTag: { alignItems: 'flex-start', marginLeft: -10, flexDirection: 'row' },
      availabilityBox: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      availDot: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: avail ? Colors.green : Colors.lightGray,
        maxWidth: 10,
        maxHeight: 10,
        minWidth: 10,
        minHeight: 10,
        marginLeft: 5,
      },
      textStyle: { fontSize: 15, textAlign: 'left' },
      mainImage: {
        width: 50,
        height: 50,
        maxHeight: 50,
        minHeight: 50,
        maxWidth: 50,
        minWidth: 50,
        borderRadius: 5,
      },
      spinnerSubrow: {
        margin: 10,
      },
    }),
    avail,
    prodHidden,
  )
