import { isProductEbtOnly } from '@helpers/products'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, isGeoProduct } from '@models/Algolia'
import { Product } from '@models/Product'
import { ReactNode } from 'react'
import { StyleSheet } from 'react-native'
import { EbtIcon } from '../EbtIcon'

/** Default product card width on small devices */
export const prodWidthSM = 130

/** Default product card width */
export const prodWidth = 180

/** Spacing width between product cards */

/** Spacing width between product cards */
const spacing = 20

/** Card width (small device) that includes margin (used in columns calculation, SeeMoreList, etc)
 *
 * Value: 150 = prodWidthSM (130) + 20
 */
export const paddedProdWidthSM = prodWidthSM + spacing

/** Card width that includes margin (used in columns calculation, SeeMoreList, etc)
 *
 * Value: 200 = prodWidthMD (180) + 20
 */
export const paddedProdWidth = prodWidth + spacing

/** Height of the card (small), used in FlashList's `estimatedItemSize` */
export const prodHeightSM = paddedProdWidthSM / 0.6

/** Height of the card, used in FlashList's `estimatedItemSize` */
export const prodHeight = paddedProdWidth / 0.65

/** Shared styles used for ProductCardSquare component */
export const prodSharedStyles = StyleSheet.create({
  /** Default card wrapper. Has fixed sizes, as those values are used to calculate number of columns, etc. */
  wrapper: {
    width: prodWidth,
    aspectRatio: 0.65,
  },
  /** Small version of the wrapper, used in smaller screens */
  smallWrapper: {
    width: prodWidthSM,
    aspectRatio: 0.6,
  },
  /** Styles that will make the card to be flexible, used especially with the ResponsiveGrid. */
  responsiveWrapper: {
    flex: 1,
    width: undefined, // Will override default wrapper width

    // As the card will take the entire space, it will also be responsible for how the spacing between itself and other cards is managed. This will create consistent spacing between items.
    marginHorizontal: spacing / 2,
    marginBottom: spacing,
  },
})

/** Gets the strings for the tags that appear in the product card */
export function getCardTags(product?: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>): ReactNode[] {
  if (!product) return []

  const tags = []

  if (product.isFeatured) {
    tags.push('Featured')
  }

  const isEbtOnly = isGeoProduct(product) ? product.isEbtOnly : isProductEbtOnly(product)
  if (isEbtOnly) {
    tags.push(
      <>
        <EbtIcon product={product} />
        SNAP Only
      </>,
    )
  }

  return tags
}
