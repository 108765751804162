import { getCoordString } from '@helpers/coordinate'
import { Coordinate, ShortCoord } from '@models/Coordinate'
import { RouteProp, useRoute } from '@react-navigation/native'
import { getDefaultCoords } from '@screens/Explore/helpers'
import { useSelector } from 'react-redux'

import { useDeepCompareCallback } from '@/hooks/useDeepEqualEffect'
import { HomeParamList } from '@/navigation/types'
import { searchLocationSelector, userLocationSelector } from '@/redux/selectors'

/** In react navigation, a screen header will exist multiple times. One for each screen that uses the same header component, including focused and unfocused screens. Therefore this is a solution to help cypress select a specific screen header */
export const makeHeaderTestId = (screenKey: string) => `header-${screenKey}`

/**
 *  Helps create a set of search params for navigation in or to the explore screen
 * - Reminder: This should allow an empty string as a valid refinement because that's the only way to clear the search from a text input */
export const useCreateSearchParams = () => {
  /** This hook is intended to be used anywhere in the navigation structure, especially the Header, which is used in all navigators.
   * It's technically wrong but useful to annotate the current route's params as the explore screen params type.
   * As long as all the code in this hook double-checks the params are of this type, everything should be fine.
   * For that, simply check `params?.near` and `params?.q` before using them.
   */
  const { params } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'>>()
  const searchLoc = useSelector(searchLocationSelector)
  const userLoc = useSelector(userLocationSelector)

  return useDeepCompareCallback(
    ({
      coords,
      refinement,
      reset,
    }: {
      /** New coords to set */
      coords?: Coordinate | ShortCoord
      /** New refinement to set */
      refinement?: string
      /** if true, reset will replace the current params with the provided coords and refinement, or remove them if new values not specified; by default the current params are otherwise retained */
      reset?: boolean
    }): HomeParamList['ExploreScreen'] => {
      const newParams: HomeParamList['ExploreScreen'] = {
        // This should reset the facet refinement parameters (with undefined) because otherwise it's not possible to refine at a new location after city selection
        category: undefined,
        certifications: undefined,
        tags: undefined,
      }
      if (coords || reset) newParams.near = coords ? getCoordString(coords) : undefined
      else if (params?.near) {
        //retain current params if new ones not specified
        newParams.near = getCoordString(getDefaultCoords({ near: params?.near, searchLoc, userLoc }))
      }

      //ALLOW EMPTY STRING AS REFINEMENT
      if (refinement !== undefined || reset) {
        newParams.q = refinement?.trim() || undefined //Must be undefined to reset search term
      } else if (params?.q) {
        //retain current params if new ones not specified
        newParams.q = params?.q
      }
      return newParams
    },
    [params, searchLoc, userLoc],
  )
}
