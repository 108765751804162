import { capitalize, plural } from '@helpers/display'
import { PickExcept, Undefine } from '@helpers/typescript'

import { ShortState } from '../assets/data/states'
import { Address, ShortZip } from './Address'
import { Distribution, DistroLocation } from './Distribution'
import { Farm } from './Farm'
import { Money } from './Money'
import { Timezone } from './Timezone'
import { UserAddress } from './UserAddress'

export enum LocationTypes {
  STAND = 'farmstand',
  MARKET = 'market',
  FARM = 'farm',
  COMMUNITY = 'community',
  Delivery = 'delivery',
  Shipping = 'shipping',
}

/** Specify the region is state or zipcode */
export enum RegionType {
  State = 'state',
  Zipcode = 'zipcode',
}

export type DeliveryLocationTypes = LocationTypes.Shipping | LocationTypes.Delivery

export type PickupLocationTypes = Exclude<LocationTypes, DeliveryLocationTypes>

export enum FeeWaiveOptionType {
  None = 'None',
  /** If partial EBT is set and the cart contains at least one item that is EBT eligible and EBT payment is used, then remove delivery/shipping fees */
  PartialEBT = 'Partial EBT',
  /** If full EBT is set and the cart contains only EBT items and EBT payment is used, remove the delivery/shipping fee */
  FullEBT = 'Full EBT',
}

export type BaseLocation = {
  id: string

  // The farm that is associated with the location.
  farm: Pick<Farm, 'id' | 'urlSafeSlug'>

  name: string

  nickname?: string

  type: LocationTypes

  /**
   * In LocalPickup, it's the address where the customer will pick up the item; and its always defined.
   * In NonPickup, it's the address where the item will be shipped or delivered. Will be undefined in the DB. When added to the cart will hold the destination address inside the CartItem.Distribution.location. */
  address?: Address | UserAddress

  // The name of the timezone from the tz database that the location is found. (e.g. America/New_York)
  timezone: Timezone

  // The cost per shipment or delivery
  cost?: Money

  // fee waive eligibility (EBT). For Payment Due / Pay offline setup, the same thing should also be possible on Payments page by the customer
  feeWaiveOption?: FeeWaiveOptionType

  /** list of states that can be shipped to or zipcodes that can be delivered to. Shipping types will have states; Delivery types will have zip codes */
  regions?: string[]
}

/** A location dedicated to either shipping or delivery */
export type NonPickup<T extends DeliveryLocationTypes = DeliveryLocationTypes> = PickExcept<
  BaseLocation,
  'type' | 'address' | 'cost' | 'feeWaiveOption' | 'regions'
> & {
  type: T

  /** In non-pickup schedules, the document is saved with no address in the db. When adding to cart, the user will select this address. The address should be defined on the cartItem schedule once in the cart */
  address?: UserAddress

  /** The cost per shipment or delivery */
  cost: Money

  /** fee waive eligibility (EBT). For Payment Due / Pay offline setup, the same thing should also be possible on Payments page by the customer */
  feeWaiveOption: FeeWaiveOptionType

  /** list of states that can be shipped to or zipcodes that can be delivered to. Shipping types will have states; Delivery types will have zip codes */
  regions: T extends LocationTypes.Delivery
    ? ShortZip[]
    : T extends LocationTypes.Shipping
    ? ShortState[]
    : ShortZip[] | ShortState[]
}

/** A location dedicated to pickups */
export type LocalPickup<T extends PickupLocationTypes = PickupLocationTypes> = PickExcept<
  BaseLocation,
  'type' | 'address' | 'cost' | 'feeWaiveOption' | 'regions'
> & {
  type: T
  address: Address
} & Undefine<Pick<BaseLocation, 'cost' | 'feeWaiveOption' | 'regions'>>

export type Location = LocalPickup | NonPickup

export function isLocalPickup(type: Location['type']): type is PickupLocationTypes
export function isLocalPickup(location: Pick<Location, 'type'>): location is LocalPickup
/** Determines if a location is local (pickup) type */
export function isLocalPickup(
  location: Pick<Location, 'type'> | Location['type'],
): location is LocalPickup | PickupLocationTypes {
  const type = typeof location === 'object' ? location.type : location

  return type !== LocationTypes.Shipping && type !== LocationTypes.Delivery
}

/** Similar to isLocalPickup but specific to the Distribution['location'] type. if this is integrated as an overload of the main isLocalPickup, it will not correctly narrow the type which is why it was separated. */
export function isLocalPickupDistLocation(location: Distribution['location']): location is DistroLocation<LocalPickup> {
  return isLocalPickup(location)
}

export function isNonPickup(type: LocationTypes): type is DeliveryLocationTypes
export function isNonPickup(location: Pick<Location, 'type'>): location is NonPickup
/** Determines if a location is either delivery or shipping type */
export function isNonPickup(
  location: Pick<Location, 'type'> | LocationTypes,
): location is NonPickup | DeliveryLocationTypes {
  const type = typeof location === 'object' ? location.type : location

  return type === LocationTypes.Shipping || type === LocationTypes.Delivery
}

/** Identifies a distro location which is non pickup */
export function isNonPickupDistLocation(location: Distribution['location']): location is DistroLocation<NonPickup> {
  return isNonPickup(location)
}

/** Identifies a shipping location */
export function isShipping(location: Pick<Location, 'type'>): location is NonPickup<LocationTypes.Shipping> {
  return isNonPickup(location) && (typeof location === 'string' ? location : location.type) === LocationTypes.Shipping
}

/** Identifies a distro location for shiipping */
export function isShippingDistLocation(
  location: Distribution['location'],
): location is DistroLocation<NonPickup<LocationTypes.Shipping>> {
  return isShipping(location)
}

/** Identifies a delivery location */
export function isDelivery(location: Parameters<typeof isNonPickup>[0]): location is NonPickup<LocationTypes.Delivery> {
  return isNonPickup(location) && (typeof location === 'string' ? location : location.type) === LocationTypes.Delivery
}

/** Identifies a distro location for delivery */
export function isDeliveryDistLocation(
  location: Distribution['location'],
): location is DistroLocation<NonPickup<LocationTypes.Delivery>> {
  return isDelivery(location)
}

/** Will format a distribution display text from a given location type.
 * For example the distribution of a LocalPickup is a pickup. The distribution of a Delivery location is a delivery. The distribution of a Shipping location is a shipment, etc.
 */
export function formatDistributionType(
  location?: Pick<Location, 'type'>,
  // action can be passed to specify you want Shipping instead of shipment
  opts?: { capitalize?: boolean; plural?: boolean; action?: boolean },
) {
  const simpleText =
    !location || isLocalPickup(location)
      ? 'pickup'
      : isDelivery(location)
      ? 'delivery'
      : opts?.action
      ? 'shipping'
      : 'shipment'

  const withPlural = plural(
    opts?.plural ? 2 : 1,
    simpleText,
    location && isDelivery(location) ? 'deliveries' : undefined,
  )

  if (opts?.capitalize) {
    return capitalize(withPlural)
  }
  return withPlural
}
