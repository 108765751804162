import { memo } from 'react'
import { ImageBackground, View } from 'react-native'
import { useSelector } from 'react-redux'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { LogoResponsive } from '../../admin/components/LogoResponsive'
import HamburgerMenu from '../../components/HamburgerMenu'
import { HeaderText, Text } from '../../components/elements/Text'
import WebLink from '../../components/elements/WebLink'
import Colors from '../../constants/Colors'
import { isWeb } from '../../constants/Layout'
import { RootState } from '../../redux/reducers/types'
import { isAdminSelector } from '../../redux/selectors'
import { HomeSearchBar } from './HomeSearchBar'

export const HomeHeader = memo(function HomeHeader() {
  const isAdmin = useSelector<RootState, boolean | undefined>(isAdminSelector)
  const styles = useStyles()
  return (
    <View>
      <ImageBackground source={require('../../assets/images/home/home-header-large.jpg')} style={styles.fill}>
        <View style={styles.overImageLayer}>
          {isWeb ? (
            <View style={styles.header}>
              <HamburgerMenu clear style={styles.hamburger} />
            </View>
          ) : (
            <View style={styles.logoCont}>
              <LogoResponsive white type="logo" />
            </View>
          )}
          <View style={styles.spacing100} />
          <View style={styles.wrapper}>
            <HeaderText style={styles.title} size={35}>
              Shop your local farms
            </HeaderText>

            <HomeSearchBar />
            <View style={styles.spacing10} />
            <Text color={Colors.white}>At least 98% of your purchase goes back to the farm</Text>

            {!isAdmin && (
              <WebLink url="/farm-onboard/walkthrough" style={styles.webLink}>
                Are you a farmer? Start selling on GrownBy
              </WebLink>
            )}
          </View>
        </View>
      </ImageBackground>
    </View>
  )
})

const useStyles = () =>
  useLayoutFnStyles(({ top }) => ({
    logoCont: {
      paddingTop: top + 10,
      alignItems: 'center',
    },
    hamburger: {
      marginLeft: -30,
    },
    spacing100: {
      height: 100,
    },
    spacing10: {
      height: 10,
    },
    webLink: {
      color: Colors.white,
      fontSize: 14,
      marginVertical: 10,
    },
    wrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      margin: 20,
    },
    title: {
      color: Colors.white,
      marginBottom: 10,
    },
    header: {
      flexDirection: 'row',
      padding: 10,
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: 30,
      marginTop: top,
    },
    link: {
      color: 'black',
      padding: 10,
      backgroundColor: 'transparent',
    },
    fill: {
      flex: 1,
    },
    overImageLayer: {
      flex: 1,
      backgroundColor: Colors.black + '66',
    },
  }))
