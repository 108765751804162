import { useFocusFx } from '@/hooks/useFocusFx'
import { useMemo } from 'react'
import { useClearRefinements, useMenu } from 'react-instantsearch'

/** Algolia refinement helper to filter data by a specific property */
export const usePropRefinement = (attribute: string, value: string | undefined) => {
  /** Creates algolia filters. On refine, the value passed as argument will be selected*/
  const { items, refine } = useMenu({ attribute, limit: 50, sortBy: ['name'] })

  /** This will clear the current refinement */
  const { refine: clearRefinement } = useClearRefinements({ includedAttributes: [attribute] })

  /** This keeps the current algolia filter refinement in sync with the refinement value from params */
  useFocusFx(
    () => {
      if (value) {
        refine(value)
      } else {
        clearRefinement()
      }
    },
    [clearRefinement, refine, value],
    { noRefocus: true },
  )

  /** Current selected filter. Undefined if no selected  */
  const currentFilter = useMemo(() => {
    const selectedCategory = items.find((el) => el.isRefined)
    return selectedCategory
  }, [items])

  return { currentFilter, items, clearRefinement }
}
