import { ButtonClear, Divider, Hoverable, Icon, Modal, Touchable } from '@elements'
import { Feather } from '@expo/vector-icons'
import { UserRole, hasGrownByPrivilege } from '@models/User'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { Overlay } from 'react-native-elements'
import { useSelector } from 'react-redux'

import Colors from '../constants/Colors'
import { WithAuthCallback } from '../hooks/WithAuthCallback'
import useMeasure from '../hooks/useMeasure'
import { AppStackParamList } from '../navigation/types'
import { isAdminSelector, userSelector } from '../redux/selectors'
import { Image, ResizedSuffix } from './Image'
import { AccountHelp } from './modals/AccountHelp'

import { useCartService } from '@/hooks/useCart'
import { useDeviceSize } from '@/hooks/useLayout'
import signOut from '@/redux/actions/signout'
import { RootState } from '@/redux/reducers/types'

const overlayWidth = 214 //This should be a good guess of the initial width of the overlay container

const accountHelpModal = () => Modal(<AccountHelp />, { title: 'Account Help' })

export default function UserDropdown(props: { admin?: boolean }) {
  const { isSmallDevice } = useDeviceSize()
  const user = useSelector(userSelector)
  const isAdmin = useSelector<RootState, boolean | undefined>(isAdminSelector)
  const [showLogin, setShowLogin] = useState(false)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const [btnPos, btnRef, measure] = useMeasure()
  const [showMenu, setShowMenu] = useState(false)
  const { loadingCart } = useCartService()

  useEffect(() => {
    if (loadingCart) return
    /** By measuring the element after the cart is loaded, we ensure the correct position is in state, because the cart will grow wider after the items are fetched when there's items in cart. */
    measure()
  }, [loadingCart, measure])

  function handleSignout() {
    setShowMenu(false)
    signOut()
  }

  const profilePressed = () => {
    /** Measure needs to run on btn press because the user button first renders further right, and then the shopping cart component becomes wider after the cart items are fetched, which causes this button to be pushed to the left,and the measure state remains at the position further right when it initially rendered. onLayout doesn't re-run when it gets shifted left so that doesn't update the measure */
    measure()
    if (!isSmallDevice) {
      return setShowMenu((a) => !a)
    }
    if (user.id) {
      /** In the AdminDrawerNavigator Tree, there is no Screen User, so we need to navigate to Root tree to access Screen User/Profile  */
      navigation.navigate('Consumer', { screen: 'User', params: { screen: 'Profile' } })
    } else {
      setShowLogin(true)
      setShowMenu(false)
    }
  }

  const getOverlayPosition = (): ViewStyle => {
    if (btnPos) {
      const { pageX: x, width: w, pageY: y, height: h } = btnPos
      return {
        position: 'absolute',
        left: x + w - overlayWidth,
        top: y + h + 5,
      }
    }
    return {}
  }

  return (
    <View
      onLayout={measure}
      ref={btnRef}
      style={{ marginHorizontal: 20 }}
      testID="user_dropdown_view"
      collapsable={false}
    >
      {showLogin && (
        <WithAuthCallback
          onDismiss={() => {
            setShowLogin(false)
          }}
          callback={() => {
            setShowLogin(false)
          }}
        />
      )}
      <Hoverable>
        {(isHovered) => (
          <Touchable style={[styles.headerIcon, isHovered && styles.onHover]} onPress={profilePressed}>
            {user.avatar ? (
              <Image
                type="profile"
                style={styles.avatar}
                source={{ uri: user.avatar }}
                resizeSuffix={ResizedSuffix.NONE}
              />
            ) : (
              <Icon name="user-circle" solid size={25} style={{ margin: 5 }} />
            )}
            {!isSmallDevice && (
              <Feather
                name={showMenu ? 'chevron-up' : 'chevron-down'}
                size={20}
                color={Colors.green}
                style={{ paddingHorizontal: 5 }}
              />
            )}
          </Touchable>
        )}
      </Hoverable>
      <Overlay
        isVisible={showMenu}
        onBackdropPress={() => setShowMenu(false)}
        backdropStyle={{ backgroundColor: Colors.transparent }}
        overlayStyle={[styles.overlay, getOverlayPosition()]}
      >
        <View style={{ padding: 10, borderRadius: 10 }}>
          {props.admin ? (
            <ButtonClear
              title="GrownBy Marketplace"
              url="/"
              onPress={() => {
                setShowMenu(false)
              }}
            />
          ) : (
            <>
              <ButtonClear title="Orders" icon="box" url="/orders" onPress={() => setShowMenu(false)} />
              <ButtonClear title="Notifications" icon="bell" url="/notifications" onPress={() => setShowMenu(false)} />
              <ButtonClear title="Favorite Farms" icon="heart" url="/farms" onPress={() => setShowMenu(false)} />
              <Divider />
              {isAdmin === true && (
                <ButtonClear
                  title="Farmer Dashboard"
                  url="/admin"
                  onPress={() => {
                    setShowMenu(false)
                  }}
                />
              )}
              {isAdmin === false && (
                <ButtonClear title="Start Selling" url="/farm-onboard/walkthrough" onPress={() => setShowMenu(false)} />
              )}
              {hasGrownByPrivilege(user, UserRole.Admin) && (
                <ButtonClear
                  title="Analytics Dashboard"
                  url="/farm-generations/analytics"
                  onPress={() => setShowMenu(false)}
                />
              )}
            </>
          )}
          <ButtonClear
            title="Account"
            url="/profile"
            onPress={() => {
              setShowMenu(false)
            }}
          />
          <ButtonClear
            title="Help"
            onPress={() => {
              setShowMenu(false)
              accountHelpModal()
            }}
          />
          {user.id ? (
            <ButtonClear title="Sign out" onPress={handleSignout} />
          ) : (
            <ButtonClear
              title="Sign in"
              onPress={() => {
                setShowLogin(true)
                setShowMenu(false)
              }}
              testID="sign_in_button"
            />
          )}
        </View>
      </Overlay>
    </View>
  )
}

const styles = StyleSheet.create({
  headerIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 2,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
  },
  avatar: {
    width: 40,
    height: 40,
    borderWidth: 2,
    borderRadius: 20,
    borderColor: Colors.transparent,
  },
  onHover: {
    borderColor: Colors.green,
    borderWidth: 2,
  },
  link: {
    color: Colors.black,
    padding: 10,
    backgroundColor: Colors.transparent,
  },
  activeLink: {
    color: Colors.white,
    padding: 10,
    backgroundColor: Colors.green,
    overflow: 'visible',
  },
  overlay: {
    width: overlayWidth,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
    padding: 0, //it adds 10 padding automatically, but we need the total size to be calculated from the inner View
    borderRadius: 10, //it uses radius 4 by default
  },
})
