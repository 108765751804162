import { Divider, Text } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { extractPaymentInfo, formatMoney, getReadablePayment } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import {
  Invoice,
  InvoiceStatus,
  getInvoiceDiscounts,
  getInvoiceSourceDiscounts,
  getProcessorItemSubtotal,
  getProcessorItemTotal,
  invoiceItemTotal,
  invoiceSubtotal,
  invoiceTotal,
  isProductFee,
  isTipOrServiceFee,
} from '@models/Invoice'
import { Zero, moneyIsZero } from '@models/Money'
import React, { memo } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { getProcessorInvoiceAmount, getTotalRefunds, groupByProcessor } from '../invoiceUtils'
import { ItemRow } from './ItemRow'

import Colors from '@/constants/Colors'
import { getInvItemDescription } from '@helpers/invoice'
import { getProductFeesFromInvoice } from '@helpers/productFee'
import { getServiceFeeNameForInvoice } from '@helpers/serviceFee'
import { FeeType } from '@models/ProductFee'

export const SplitInvoiceSummary = memo(function SplitInvoiceSummary({ invoice }: { invoice: Invoice }) {
  const styles = useStyles()
  const invoicePayments = groupByProcessor(invoice.items)
  const totalRefunds = getTotalRefunds(invoice)
  const totalDiscounts = getInvoiceDiscounts(invoice)
  const tip = invoice.items.find((itm) => isTipOrServiceFee(itm.id))

  return (
    <View style={styles.borderedCont}>
      {invoicePayments?.map((payment, idx) => (
        <View key={payment.source} style={{ marginVertical: 10 }}>
          <View style={styles.paymentHeaderRow}>
            <Text style={styles.headerText} type="medium">
              {getReadablePayment(payment.source)}
            </Text>
            <Text style={styles.headerText} type="medium">
              {`Payment ${idx + 1}/${invoicePayments.length}`}
            </Text>
          </View>
          {payment.items.length > 0 && (
            <ItemRow isHeader leftSideValues={['Item']} rightSideValues={['Quantity', 'Price', 'Amount']} />
          )}
          {payment.items
            .filter((itm) => !isTipOrServiceFee(itm.id) && !isProductFee(itm.id))
            .map((itm) => (
              <ItemRow
                key={itm.id}
                leftSideValues={[`${itm.cancelled ? '(CANCELLED) ' : ''}${getInvItemDescription(itm)}`]}
                rightSideValues={[
                  itm.quantity.toString(),
                  formatMoney(
                    MoneyCalc.add(MoneyCalc.divide(getProcessorItemSubtotal(itm, payment.source), itm.quantity)),
                  ),
                  formatMoney(itm.cancelled ? Zero : getProcessorItemSubtotal(itm, payment.source)),
                ]}
              />
            ))}
          <>
            <View style={styles.subtotalsContainer}>
              <View style={styles.leftColumn}>
                <Text type="medium" style={styles.subtotal}>
                  SUBTOTAL
                </Text>
                {MoneyCalc.isGTZero(totalDiscounts) &&
                  MoneyCalc.isGTZero(getInvoiceSourceDiscounts(invoice, payment.source)) && (
                    <Text style={styles.subtotal} size={14} type="medium" color={Colors.green}>
                      Discounts
                    </Text>
                  )}
                {!!tip && MoneyCalc.isGTZero(getProcessorItemTotal(tip, payment.source)) && (
                  <Text type="medium">{getServiceFeeNameForInvoice(invoice)}</Text>
                )}
                {getProductFeesFromInvoice({ items: payment.items }, FeeType.Tax).length > 0 && (
                  <Text type="medium">Taxes</Text>
                )}
                {getProductFeesFromInvoice({ items: payment.items }, FeeType.Fee).length > 0 &&
                  getProductFeesFromInvoice({ items: payment.items }, FeeType.Fee).map((fee) => (
                    <Text key={`${fee.id}_${fee.description}`} type="medium">
                      {fee.description}
                    </Text>
                  ))}
              </View>
              <View style={styles.rightColumn}>
                <Text type="medium" style={styles.subtotal}>
                  {formatMoney(
                    getProcessorInvoiceAmount(
                      payment.source,
                      payment.items.filter(
                        (itm) => !itm.cancelled && !isTipOrServiceFee(itm.id) && !isProductFee(itm.id),
                      ),
                    ),
                  )}
                </Text>
                {MoneyCalc.isGTZero(totalDiscounts) &&
                  MoneyCalc.isGTZero(getInvoiceSourceDiscounts(invoice, payment.source)) && (
                    <Text style={styles.subtotal} color={Colors.green} size={14} type="medium">
                      -{formatMoney(getInvoiceSourceDiscounts(invoice, payment.source))}
                    </Text>
                  )}
                {!!tip && MoneyCalc.isGTZero(getProcessorItemTotal(tip, payment.source)) && (
                  <Text type="medium">{formatMoney(getProcessorItemTotal(tip, payment.source))}</Text>
                )}
                {getProductFeesFromInvoice({ items: payment.items }, FeeType.Tax).length > 0 && (
                  <Text type="medium">
                    {formatMoney(
                      invoiceTotal({ items: getProductFeesFromInvoice({ items: payment.items }, FeeType.Tax) }),
                    )}
                  </Text>
                )}
                {getProductFeesFromInvoice({ items: payment.items }, FeeType.Fee).length > 0 &&
                  getProductFeesFromInvoice({ items: payment.items }, FeeType.Fee).map((fee) => (
                    <Text key={fee.id} type="medium">
                      {formatMoney(invoiceItemTotal(fee))}
                    </Text>
                  ))}
              </View>
            </View>
            <Divider />
          </>
        </View>
      ))}
      <View style={styles.totalsContainer}>
        <View style={styles.leftColumn}>
          <Text type="medium" style={styles.text} size={14}>
            ORDER TOTAL
          </Text>
          {!moneyIsZero(totalRefunds) && (
            <Text type="medium" style={styles.text} color={Colors.red} size={13}>
              TOTAL REFUNDS
            </Text>
          )}
          {invoicePayments?.map((payment) => (
            <>
              {invoice.payments[payment.source]?.totalPaid && (
                <Text style={styles.text}>Paid {extractPaymentInfo(invoice.payments[payment.source]!)}</Text>
              )}
              {invoice.payments[payment.source]?.refundedAmount &&
                invoice.payments[payment.source]!.refundedAmount!.value > 0 && (
                  <Text style={styles.text} color={Colors.red}>
                    Refunded {extractPaymentInfo(invoice.payments[payment.source]!)}
                  </Text>
                )}
            </>
          ))}
        </View>
        <View style={styles.rightColumn}>
          <Text type="medium" style={styles.text} size={15}>
            {formatMoney(invoice.status === InvoiceStatus.Void ? invoiceSubtotal(invoice) : invoice.amountTotal)}
          </Text>
          {!moneyIsZero(totalRefunds) && (
            <Text type="medium" style={styles.text} color={Colors.red} size={14}>
              {formatMoney(totalRefunds)}
            </Text>
          )}
          {invoicePayments?.map((payment) => (
            <>
              {invoice.payments[payment.source]?.totalPaid && (
                <Text style={styles.text}>{formatMoney(invoice.payments[payment.source]!.totalPaid!)}</Text>
              )}
              {invoice.payments[payment.source]?.refundedAmount &&
                invoice.payments[payment.source]!.refundedAmount!.value > 0 && (
                  <Text style={styles.text} color={Colors.red}>
                    {formatMoney(invoice.payments[payment.source]!.refundedAmount!.value!)}
                  </Text>
                )}
            </>
          ))}
        </View>
      </View>
    </View>
  )
}, propsAreDeepEqual)

const useStyles = CreateResponsiveStyle(
  {
    borderedCont: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
      borderRadius: 10,
      padding: 20,
      marginVertical: 10,
    },
    text: {
      marginVertical: 5,
    },
    headerText: {
      fontSize: 14,
      marginTop: 15,
    },
    subtotalsContainer: {
      alignSelf: 'flex-end',
      flexDirection: 'row',
    },
    totalsContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginVertical: 10,
    },
    paymentHeaderRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    leftColumn: {
      marginRight: 30,
      alignItems: 'flex-end',
    },

    rightColumn: {
      alignItems: 'flex-end',
    },
    subtotal: {
      marginVertical: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      borderedCont: {
        padding: 5,
      },
    },
  },
)
