import { MoneyCalc } from '@helpers/money'
import { Invoice, InvoiceItem, PaymentSources, getProcessorItemSubtotal } from '@models/Invoice'
import { Money, Zero, addMoney, moneyIsZero } from '@models/Money'

type InvoiceProcessor = {
  source: PaymentSources
  items: Invoice['items']
}
export const groupByProcessor = (items: InvoiceItem[]): InvoiceProcessor[] => {
  let invProcessors: InvoiceProcessor[] = []
  items.forEach((itm) =>
    itm.payments.forEach((payment) => {
      const sourceIdx = invProcessors.findIndex((p) => p.source === payment.source)
      if (sourceIdx === -1) {
        invProcessors = [...invProcessors, { source: payment.source, items: [itm] }]
      } else {
        const updatedArr = [...invProcessors]
        updatedArr[sourceIdx].items = [...updatedArr[sourceIdx].items, itm]
      }
    }),
  )
  return invProcessors
}
export const getTotalRefunds = (invoice: Invoice): Money => {
  let total = Zero
  Object.values(invoice.payments).forEach((payment) => {
    if (payment.refundedAmount && !moneyIsZero(payment.refundedAmount)) {
      total = addMoney(total, payment.refundedAmount)
    }
  })
  return total
}
export const getProcessorInvoiceAmount = (source: PaymentSources, items: Invoice['items']) => {
  let total = Zero
  for (const item of items) {
    const payment = MoneyCalc.add(getProcessorItemSubtotal(item, source))
    total = addMoney(total, payment)
  }
  return total
}
