import { FormBuilder } from '@components'
import { ViewStyle } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

/**
 * @deprecated because it is an extra wrapper for the form builder which is deprecated
 */
export default function FormWrapper({
  children,
  row,
  style,
}: {
  children: (JSX.Element | null | false)[] | JSX.Element | false
  row?: boolean
  style?: ViewStyle
}) {
  const styles = responsiveStyle()
  return (
    <FormBuilder row={row} style={[styles.formWrapper, style]}>
      {children}
    </FormBuilder>
  )
}

const responsiveStyle = CreateResponsiveStyle(
  {
    formWrapper: {
      paddingHorizontal: 25,
      paddingBottom: 40,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      formWrapper: {
        paddingHorizontal: 0,
      },
    },
  },
)
