import { geocode } from '@api/Addresses'
import { addLocation, snapshotLocation } from '@api/Locations'
import { Establishment, FormBuilder, GooglePlacesSearch, ToolTips, makeEstablishment } from '@components'
import { CustomInput, FormPicker, PickerProps, Text, Toast, typography } from '@elements'
import { formatZipcode } from '@helpers/display'
import getTimeZone from '@helpers/getTimeZone'
import { LocalPickup, Location, LocationTypes } from '@models/Location'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import LoaderWithMessage from '../../../components/LoaderWithMessage'
import { useDeviceSize } from '../../../hooks/useLayout'
import { AdminView } from '../../components/AdminView'
import { LocationsAndZonesParamList } from '../../navigation/types'
import { EditHeader } from '../Schedules/components/EditHeader'
import { ReturnStates, validateLocationEdit } from './editValidation'

import InputLabel from '@/admin/components/InputLabel'
import Colors from '@/constants/Colors'
import { GoogleAddressParser } from '@/constants/GoogleAddressParser'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { setAdminNav } from '@/redux/actions/adminState'
import { adminFarmSelector, adminParamsSelector } from '@/redux/selectors'
import { locationsCollection } from '@api/framework/ClientCollections'
import { AccessRight, Permission } from '@helpers/Permission'

type FormType = {
  zipcode: string
  locationName: string
  city: string
  locationNickname: string
  streetAddress1: string
  streetAddress2?: string
  state: string
  type: LocalPickup['type']
}

enum EditState {
  LOADING = 0,
  FAILED = 1,
  SUCCESS = 2,
}

const typeDropdownItems: PickerProps['items'] = [
  {
    label: 'Farm location',
    value: LocationTypes.FARM,
  },
  {
    label: 'Farm stand or store',
    value: LocationTypes.STAND,
  },
  {
    label: 'Farmers market',
    value: LocationTypes.MARKET,
  },
  {
    label: 'Community Location',
    value: LocationTypes.COMMUNITY,
  },
]

const setGooglePlace = (
  details: Establishment,
  setFieldValue: (key: keyof FormType, value: string) => void,
  locationName: string,
) => {
  if (!locationName?.length && details.name) setFieldValue('locationName', details.name)
  if (!details.address_components) return
  const address = new GoogleAddressParser(details.address_components).result()
  setFieldValue('streetAddress1', `${address.street_number || ''} ${address.street_name || ''}`)
  setFieldValue('city', address.city || '')
  setFieldValue('state', address.state || '')
  setFieldValue('zipcode', address.postal_code || '')
}

const locationValidationSchema = Yup.object<FormType>().shape({
  locationName: Yup.string().trim().label('Location Name').required(),
  locationNickname: Yup.string().trim().label('Location Nickname').required(),
  type: Yup.mixed<LocalPickup['type']>()
    .oneOf([LocationTypes.COMMUNITY, LocationTypes.FARM, LocationTypes.MARKET, LocationTypes.STAND])
    .required(),
  streetAddress1: Yup.string().trim().label('Street Address 1').required(),
  streetAddress2: Yup.string().trim().label('Street Address 2'),
  city: Yup.string().trim().label('City').required(),
  state: Yup.string().trim().label('State').required(),
  zipcode: Yup.string()
    .trim()
    .label('Zip code')
    .required()
    .matches(/[0-9]{5}/, 'Zip code must be numeric')
    .min(5, 'Zip code must be 5 digits')
    .max(5, 'Zip code must be 5 digits'),
})

/** This screen will only be used for LocalPickup type locations */
function AddLocationScreen() {
  const navigation = useNavigation<StackNavigationProp<LocationsAndZonesParamList, 'AddLocation'>>()
  const dispatch = useDispatch()
  const { params } = useRoute<RouteProp<LocationsAndZonesParamList, 'AddLocation' | 'EditLocation'>>()
  const farm = useSelector(adminFarmSelector)
  const editLocation = useSelector(adminParamsSelector).location as LocalPickup
  const { isLargeDevice } = useDeviceSize()

  const [initialValues, setInitialValues] = useState<FormType>({
    locationName: '',
    locationNickname: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipcode: '',
    type: LocationTypes.FARM,
  })
  const [editState, setEditState] = useState<EditState>(EditState.LOADING)

  const isEdit = !!params?.id

  useEffect(() => {
    if (!(params?.id && (!editLocation || editLocation.id !== params.id))) {
      setEditState(EditState.SUCCESS)
      return
    }

    return snapshotLocation(
      params.id,
      farm.id,
      (location: Location) => {
        dispatch(setAdminNav({ location }))
        setEditState(EditState.SUCCESS)
      },
      () => setEditState(EditState.FAILED),
    )
  }, [params?.id, dispatch, editLocation, farm?.id])

  useEffect(() => {
    if (isEdit && editLocation && editLocation)
      setInitialValues({
        locationName: editLocation.name,
        locationNickname: editLocation.nickname || '',
        streetAddress1: editLocation.address.street1,
        streetAddress2: editLocation.address.street2 || '',
        city: editLocation.address.city,
        state: editLocation.address.state,
        zipcode: editLocation.address.zipcode,
        type: editLocation.type,
      })
  }, [editLocation, isEdit])

  async function handleSubmitLocation(values: FormType, { resetForm }: FormikHelpers<FormType>) {
    const type: LocationTypes = values.type

    // Set values from the form
    const data: LocalPickup = {
      id: '',
      farm: { id: farm?.id, urlSafeSlug: farm?.urlSafeSlug },
      address: {
        city: values?.city,
        state: values?.state,
        street1: values?.streetAddress1,
        street2: values?.streetAddress2,
        zipcode: formatZipcode(values?.zipcode),
        coordinate: editLocation?.address.coordinate || { latitude: 0, longitude: 0 },
      },
      name: values?.locationName,
      nickname: values.locationNickname,
      type,
      timezone: editLocation?.timezone || '',
    }

    if (isEdit && editLocation) {
      // Check that we are actually updating anything and warn the farmer of any important changes
      const result = await validateLocationEdit(editLocation, data)

      // return here so we don't go back if something requires more attention or a popup was cancelled
      if (result.status === ReturnStates.DO_NOTHING) {
        return Toast('Nothing to update, going back')
      }

      // If the farmer agrees and there are changes then proceed to update
      if (result.status === ReturnStates.SAVE) {
        data.address = await geocode(data.address)
        data.timezone = await getTimeZone(data.address)
        await locationsCollection.update(result.data)
      }
    } else {
      data.address = await geocode(data.address)
      data.timezone = await getTimeZone(data.address)
      await addLocation(data, farm)
    }
    resetForm()
    navigation.navigate('Locations')
  }

  if (isEdit && editState !== EditState.SUCCESS)
    return (
      <AdminView
        customHeader={
          <EditHeader
            goBack={() => navigation.navigate('Locations')}
            title={isEdit && !!editLocation?.name ? `Edit Location - ${editLocation.name}` : 'Add Location'}
            actionTitle="Back"
            onPress={() => navigation.navigate('Locations')}
          />
        }
      >
        <LoaderWithMessage loading={editState === EditState.LOADING} icon="map-marked" title="Location not found">
          <Text>
            This location could not be loaded, please click the "X" in the upper right corner and go back and select a
            location from the list.
          </Text>
        </LoaderWithMessage>
      </AdminView>
    )

  return (
    <Formik validationSchema={locationValidationSchema} initialValues={initialValues} onSubmit={handleSubmitLocation}>
      {({
        touched,
        errors,
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
      }: FormikProps<FormType>) => {
        return (
          <AdminView
            customHeader={
              <EditHeader
                goBack={() => navigation.navigate('Locations')}
                title={isEdit && !!editLocation?.name ? `Edit Location - ${editLocation.name}` : 'Add Location'}
                actionTitle="Save"
                isLoading={isSubmitting}
                onPress={handleSubmit}
              />
            }
          >
            <View style={styles.formWrapper}>
              <CustomInput
                onChangeText={handleChange('locationName')}
                onBlur={handleBlur('locationName')}
                value={values.locationName}
                label={
                  <InputLabel label="Location Name" tooltipTitle="Location Name" tooltipId={ToolTips.LOCATION_NAME} />
                }
                errorMessage={touched.locationName ? errors.locationName : ''}
              />
              <CustomInput
                onChangeText={handleChange('locationNickname')}
                onBlur={handleBlur('locationNickname')}
                value={values.locationNickname}
                errorMessage={touched.locationNickname ? errors.locationNickname : ''}
                label={
                  <InputLabel
                    label="Location Nickname"
                    tooltipTitle="Location Nickname"
                    tooltipId={ToolTips.LOCATION_NICKNAME}
                  />
                }
                helperText="Neighborhood or common name to help customers identify it"
              />
              <FormBuilder style={styles.locationDropdownCont}>
                <FormPicker
                  value={values.type}
                  placeholder={null}
                  minimal={isLargeDevice}
                  onValueChange={handleChange('type')}
                  items={typeDropdownItems}
                  label={<InputLabel label="Location Type" />}
                  errorMessage={touched.type ? errors.type : ''}
                />
              </FormBuilder>
              <Text style={styles.label}>Address</Text>
              <GooglePlacesSearch
                placeholder="Search for your location"
                types="establishment"
                onSelect={(item) => setGooglePlace(makeEstablishment(item), setFieldValue, values.locationName)}
                style={styles.placeSearch}
                contStyle={styles.placeSearchCont}
                inline
              />
              <View style={{ height: 20 }} />

              <CustomInput
                onChangeText={handleChange('streetAddress1')}
                onBlur={handleBlur('streetAddress1')}
                value={values.streetAddress1}
                label="Street Address"
                errorMessage={touched.streetAddress1 ? errors.streetAddress1 : ''}
              />
              <CustomInput
                onChangeText={handleChange('streetAddress2')}
                onBlur={handleBlur('streetAddress2')}
                value={values.streetAddress2}
                label="Street Address 2"
                errorMessage={touched.streetAddress2 ? errors.streetAddress2 : ''}
              />
              <CustomInput
                onChangeText={handleChange('city')}
                onBlur={handleBlur('city')}
                value={values.city}
                label="City"
                errorMessage={touched.city ? errors.city : ''}
              />
              <CustomInput
                onChangeText={handleChange('state')}
                onBlur={handleBlur('state')}
                value={values.state}
                label="State"
                errorMessage={touched.state ? errors.state : ''}
              />
              <CustomInput
                label="Zip Code"
                value={values.zipcode}
                keyboardType="number-pad"
                maxLength={5}
                onChangeText={handleChange('zipcode')}
                onBlur={handleBlur('zipcode')}
                errorMessage={touched.zipcode ? errors.zipcode : ''}
              />
            </View>
          </AdminView>
        )
      }}
    </Formik>
  )
}

export default withAdminAuth(AddLocationScreen, Permission.ProductSetup, AccessRight.Edit)

const styles = StyleSheet.create({
  placeSearch: {
    fontSize: 14,
    height: 30,
  },
  placeSearchCont: {
    color: Colors.shades[500],
    borderWidth: 1,
    borderColor: Colors.shades[100],
    borderRadius: 10,
    padding: 10,
  },
  formWrapper: {
    marginVertical: 20,
    width: '90%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  label: {
    fontSize: 14,
    fontFamily: typography.body.medium,
    color: Colors.black,
    marginBottom: 4,
  },
  locationDropdownCont: {
    marginHorizontal: -10,
  },
})
