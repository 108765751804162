import { Header } from '@components'
import { useMemo, useState } from 'react'
import { View } from 'react-native'

import ConnectedResults from '../../components/Search/ConnectedResults'
import { isMobile, isWeb } from '../../constants/Layout'
import { withConsumerIndex } from '../../hooks/withAlgoliaIndex'
import withLocation from '../../hooks/withLocation'
import ConnectedGeoSearch from './ConnectedGeoSearch'
import AllFiltersModal from './Filters'
import { MobileExploreInput } from './MobileExploreInput'
import { SearchConfigure } from './SearchConfigure'
import { VirtualParamRefinement } from './VirtualParamRefinement'
import { ExploreContext } from './components/ExploreContext'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { SafeAreaView } from '@elements'

function ExploreScreen() {
  const { isLargeDevice, isSmallDevice } = useDeviceSize()
  const [condensedView, setCondensedView] = useState<'list' | 'map'>('list')
  const [showModal, setShowModal] = useState(false)
  const [showCarousel, setShowCarousel] = useState(false)

  const exploreContext = useMemo(() => {
    return { showModal, setShowModal, condensedView, setCondensedView, showCarousel, setShowCarousel }
  }, [showModal, setShowModal, condensedView, setCondensedView, showCarousel, setShowCarousel])
  const styles = useStyles()
  return (
    <ExploreContext.Provider value={exploreContext}>
      <SafeAreaView style={styles.screen}>
        <SearchConfigure />
        <VirtualParamRefinement attribute="category" paramKey="category" />
        <VirtualParamRefinement attribute="farm.practices" paramKey="certifications" />
        <VirtualParamRefinement attribute="farm.tags" paramKey="tags" />
        <AllFiltersModal />
        {/* On all web devices, we want to show this header since there is no bottom navigation */}
        {isWeb && <Header noSafeareaMargin />}
        {/* On all mobile and on small screen web, we want to show this input since there will be no header input */}
        {(isMobile || isSmallDevice) && <MobileExploreInput />}
        <View style={styles.container}>
          {(isLargeDevice || condensedView === 'list') && (
            <View style={styles.left}>
              <ConnectedResults />
            </View>
          )}
          {(isLargeDevice || condensedView === 'map') && (
            <View style={styles.right}>
              <ConnectedGeoSearch />
            </View>
          )}
        </View>
      </SafeAreaView>
    </ExploreContext.Provider>
  )
}

export default withLocation(withConsumerIndex(ExploreScreen, false))

const useStyles = () =>
  useLayoutFnStyles(({ height }) => ({
    screen: {
      flex: 1,
      // This prevents the map component from exceeding the screen height, which makes the entire screen scrollable
      maxHeight: height,
    },
    container: {
      flex: 1,
      flexDirection: 'row',
    },
    left: {
      flex: 3,
      maxWidth: 600,
    },
    right: {
      flex: 2,
    },
  }))
