import { voidInvoice } from '@api/Invoices'
import { Alert, Button, Divider, hideModal, Toast } from '@elements'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

import { Logger } from '../../../../../config/logger'
import Colors from '../../../../../constants/Colors'

type FormType = {
  note: string
}

export default function VoidInvoice({ invoiceId, voidInv }: { invoiceId: string; voidInv: boolean }) {
  const [loading, setLoading] = useState(false)

  const validationSchema = Yup.object<FormType>().shape({
    note: Yup.string(),
  })

  const onSubmitHandler = async (values: FormType) => {
    setLoading(true)
    try {
      const { success, message } = await voidInvoice(invoiceId, voidInv, values.note)
      setLoading(false)
      hideModal()
      Alert(
        success ? `Success!` : 'Failed!',
        success
          ? `Invoice successfully ${voidInv ? 'voided' : 'un-voided'}. The changes will show up shortly.`
          : message || 'Something went wrong, please try again later or contact support if this issue persists.',
        [
          {
            text: 'OK',
            onPress: () => {},
            style: 'default',
          },
        ],
      )
    } catch (e) {
      Logger.error(e)
      setLoading(false)
      hideModal()
      Toast(
        `Could not ${voidInv ? 'void' : 'un-void'}, please try again later or contact support if this issue persists.`,
      )
    }
  }
  return (
    <Formik
      initialValues={{
        note: '',
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, handleSubmit, handleBlur }: FormikProps<FormType>) => (
        <View style={styles.container}>
          <Text>{`Please include reason for ${voidInv ? 'voiding' : 'un-voiding'} invoice here`}</Text>
          <Divider clear />
          <Input
            multiline
            numberOfLines={2}
            placeholder="Internal Note"
            value={values.note}
            onChangeText={(val) => setFieldValue('note', val)}
            onBlur={handleBlur('note')}
          />
          <Button loading={loading} title="Submit" onPress={() => handleSubmit()} />
          <Text>
            {`Are you sure you want to ${
              voidInv ? 'void' : 'un-void'
            } this invoice? You can undo this action later if you change your mind.`}
          </Text>
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  amountInput: {
    fontSize: 20,
    fontWeight: '600',
    margin: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['300'],
  },
  reloadCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
})
