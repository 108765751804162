import { SplitTenderPayment } from '@models/Order'
import { PaymentSelectorOptions } from './types'
import {
  EbtCardTypes,
  isAchPayment,
  isCreditPayment,
  isEbtPayment,
  isFarmCreditPayment,
  isInfinitePayment,
} from '@models/PaymentMethod'
import { MoneyCalc } from '@helpers/money'
import { Money, Zero } from '@models/Money'
import { capitalize } from '@helpers/display'

/** For installments, we must have either infinite or EBT to cover the installment amount */
export const needsInstallmentPayment = (splitTender: SplitTenderPayment, options: PaymentSelectorOptions) =>
  options.hasInstallments && !splitTender.some(({ paymentMethod }) => isEbtPayment(paymentMethod))

/** For delivery fees, we must have infinite or EBT to cover the installment amount */
export const needsDeliveryPayment = (options: PaymentSelectorOptions) => options.hasDelivery

/**
 * This helper computes whether infinite payments should be shown and if so what they should be shown for
 * @param splitTender the split tender to check for payment configuration
 * @param options the options to compare with
 */
export function showInfinitePaymentsHelper(splitTender: SplitTenderPayment, options: PaymentSelectorOptions) {
  // This will tell us if we have split tender that contains an infinite payment
  const hasInfinitePayment =
    splitTender.length > 0 && splitTender.some((tender) => isInfinitePayment(tender.paymentMethod.source))

  const totalAmountSelected = splitTender.reduce((total, tender) => MoneyCalc.add(total, tender.amount ?? Zero), Zero)

  // Return the reason for needing an infinite payment
  //We should show the default infinite payment if we don't have enough to cover the total
  if (!MoneyCalc.isGTE(totalAmountSelected, options.amountTotal)) return 'default'
  if (needsInstallmentPayment(splitTender, options)) return 'installment'
  if (needsDeliveryPayment(options)) return 'delivery'
  // If we have an infinite payment selected, and it is not for delivery or installments, then we should show the default
  if (hasInfinitePayment) return 'default'
  return undefined
}

/**
 * This helper will format the split tender for display and add the amounts next to the type
 */
export function formatSplitTender(tender: SplitTenderPayment, totalAmount: Money) {
  const tenderTotal = tender.reduce((total, tender) => MoneyCalc.add(total, tender.amount ?? Zero), Zero)
  // Farm credit is displayed in a separate box, so we should not show it here
  const filteredTender = tender.filter((tender) => !isFarmCreditPayment(tender.paymentMethod))

  return filteredTender.map(({ paymentMethod, amount }): [string, Money] => {
    // Get the amount for the certain tender type
    const amt = amount ?? MoneyCalc.subtract(totalAmount, tenderTotal)

    if (isCreditPayment(paymentMethod)) {
      return [`${capitalize(paymentMethod.card_type)} *${paymentMethod.last4}`, amt]
    }
    if (isAchPayment(paymentMethod)) {
      return [`${capitalize(paymentMethod.bank_name)} *${paymentMethod.last4}`, amt]
    }
    if (isEbtPayment(paymentMethod)) {
      const typeString = paymentMethod.card_type === EbtCardTypes.SNAP ? 'SNAP' : 'EBT Cash'
      return [`${typeString} *${paymentMethod.last4}`, amt]
    }
    return ['Offline', amt]
  })
}
