import { formatShortPickupDate } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { getProratedAmount } from '@helpers/order'
import { getProductFeesForCartProduct, getProductFeesFromCart } from '@helpers/productFee'
import { isProductEbtEligible } from '@helpers/products'
import { sortByEarliest } from '@helpers/sorting'
import { eachMonthOfInterval, eachWeekOfInterval, fromJSDate, isValidDateRange, toJSDate } from '@helpers/time'
import { ArrElement, PartialPick, pick } from '@helpers/typescript'
import { SimpleCart } from '@models/Cart'
import { Distribution } from '@models/Distribution'
import {
  Invoice,
  InvoiceItem,
  InvoiceItemTypes,
  InvoicePaymentMethod,
  InvoiceTemp,
  PaymentSources,
  invoiceItemSubtotal,
} from '@models/Invoice'
import { formatDistributionType } from '@models/Location'
import { Money, Zero } from '@models/Money'
import { CartItem, CartPhysical, LocationFeeMapping, isCartShare, isCartStandardMulti } from '@models/Order'
import { PaymentType } from '@models/Payment'
import {
  PaymentMethod,
  isAchPayment,
  isCashPayment,
  isCreditPayment,
  isEbtPayment,
  isFarmCreditPayment,
} from '@models/PaymentMethod'
import { isShare } from '@models/Product'
import { isPercentProductFee } from '@models/ProductFee'
import { Timezone, dateTimeInZone } from '@models/Timezone'
import { DateTime } from 'luxon'

/** The arguments for this helper should all refer to the properties of a valid cart item. */
export function calculateInterval(
  paymentSchedule: CartItem['paymentSchedule'],
  pickups: CartPhysical['pickups'],
  timezone: Timezone,
  prorated: boolean,
): DateTime[] {
  const now = dateTimeInZone(timezone).startOf('day')

  // If the timezone for paymentDates is different from the timezone specified then convert the paymentDate timezone to current
  // this will make it so that the payment date is treated as a date and not a time. So that no matter where you are you will
  // have the same installment cutoff date. Also fixes an issue where paymentDates in utc are not being included
  if (paymentSchedule.paymentDates.endDate.zoneName !== timezone) {
    paymentSchedule.paymentDates.endDate = paymentSchedule.paymentDates.endDate.setZone(timezone, {
      keepLocalTime: true,
    })
  }

  // For Multi standard pickups we want each pickup date to be a payment date
  if (paymentSchedule.frequency === 'PER-PICKUP') {
    // Pay-per-pickup first invoice should be moved to today so that there is always an upfront one due,
    // This means that the first pickup will not have a payment due
    const invoiceDates = [...pickups]

    invoiceDates.splice(0, 1, now)
    return invoiceDates
  }

  // If the payment endDate has passed then only allow upfront payments
  if (paymentSchedule.paymentDates.endDate < now) {
    return [now]
  }

  // Will get the last day payments can be made, whichever is the earlier between the paymentDate.endDate or the last pickup
  let end = DateTime.min(paymentSchedule.paymentDates.endDate, pickups[pickups.length - 1])

  if (paymentSchedule.frequency === 'WEEKLY') {
    const start = prorated ? now : pickups[0].startOf('week')
    // If the payment endDay is before the start, use the latest possible, effectively neutralizing the last payment end date
    if (!isValidDateRange({ startDate: start, endDate: end })) end = DateTime.max(...pickups)
    const invIntervals = eachWeekOfInterval(
      { start, end },
      {
        zone: timezone,
        fromStartDate: true,
      },
    )
    return invIntervals.length ? invIntervals : [now]
  }
  if (paymentSchedule.frequency === 'MONTHLY') {
    const start = prorated ? now : pickups[0].startOf('month')
    // If the payment endDay is before the start, use the latest possible, effectively neutralizing the last payment end date
    if (!isValidDateRange({ startDate: start, endDate: end })) end = DateTime.max(...pickups)
    const invIntervals = eachMonthOfInterval({ start, end }, { zone: timezone, fromStartDate: true })
    return invIntervals.length ? invIntervals : [now]
  }
  return [now]
}

function groupInvoices(invoices: InvoiceTemp[], timezone: Timezone): Pick<Invoice, 'dueDate' | 'items'>[] {
  const tempObj: { [key: number]: InvoiceItem[] } = {}
  // Group array of invoices into object
  invoices.forEach((invoice) => {
    const dateNum = toJSDate(invoice.dueDate).getTime()
    if (tempObj.hasOwnProperty(dateNum)) {
      // Check that this item isn't already on invoice
      const sameObj = tempObj[dateNum].findIndex(
        (item) => item.id === invoice.item.id && item.description === invoice.item.description,
      )
      // If it is just update quantity
      if (sameObj !== -1) tempObj[dateNum][sameObj].quantity += invoice.item.quantity
      // If not add it to the invoice
      else tempObj[dateNum].push(invoice.item)

      // If the invoice is new set the first item
    } else tempObj[dateNum] = [invoice.item]
  })
  // Convert object into array of grouped invoices
  const invoiceArr: Pick<Invoice, 'dueDate' | 'items'>[] = Object.keys(tempObj).map((keyStr: string) => {
    const key = Number(keyStr)
    const items = tempObj[key]

    return {
      dueDate: fromJSDate(new Date(key), timezone),
      items,
    }
  })
  return invoiceArr.sort(sortByEarliest('dueDate'))
}

type FeeOptions = Pick<InvoiceItem, 'description'> & Pick<ArrElement<InvoiceItem['payments']>, 'amount' | 'source'>

/** Will add a fee line item to the invoice for service fees */
export function addFeeToInvoice<InvType extends Pick<Invoice, 'amountTotal' | 'items'>>(
  invoice: InvType,
  feeOpts: FeeOptions,
): InvType {
  const feeItem: InvoiceItem = {
    id: InvoiceItemTypes.TIP,
    description: feeOpts.description,
    quantity: 1,
    payments: [
      {
        source: feeOpts.source,
        amount: feeOpts.amount,
      },
    ],
  }
  return addItemToInvoice(invoice, feeItem)
}

/** Will create a delivery fee InvoiceItem */
export function createDeliveryInvoiceItem(
  fee: Omit<LocationFeeMapping, 'invoice'>,
  dueDate: DateTime,
  source: PaymentSources,
  distId: Distribution['id'],
): InvoiceItem {
  const deliveryFeeItem: InvoiceItem = {
    id: fee.type,
    description: `Fee for your ${
      fee.type === InvoiceItemTypes.DELIVERY_FEE ? 'delivery' : 'shipment'
    } on ${formatShortPickupDate(dueDate)}`,
    quantity: 1,
    distId,
    payments: [
      {
        source,
        amount: fee.amount,
      },
    ],
  }
  return deliveryFeeItem
}

/** Will create an adjustment InvoiceItem and add it to the invoice */
export function addAdjustmentToInvoice(invoice: Invoice, amount: Money, reason: string): Invoice {
  const adjustment: InvoiceItem = {
    id: InvoiceItemTypes.ADJUSTMENT,
    description: `[ADJUSTMENT]: ${reason}`,
    quantity: 1,
    payments: [
      {
        source: PaymentSources.OFFLINE,
        // We add the adjustment as a negative amount to the invoice
        amount: MoneyCalc.multiply(amount, -1),
      },
    ],
  }
  return addItemToInvoice(invoice, adjustment)
}

/** Will add an item to an invoice and update the amount total
 * - Useful for fees or coupons */
export function addItemToInvoice<InvType extends Pick<Invoice, 'items' | 'amountTotal'>>(
  invoice: InvType,
  itm: InvoiceItem,
): InvType {
  return {
    ...invoice,
    amountTotal: invoice.amountTotal ? MoneyCalc.add(invoice.amountTotal, invoiceItemSubtotal(itm)) : undefined,
    items: [...invoice.items, itm],
  }
}

/** Calculates the base invoices data for a cart. */
export function createInvoiceItems(
  { items, isAdmin }: PartialPick<SimpleCart, 'items'>,
  timezone?: Timezone,
  paymentSource?: PaymentSources,
): Pick<Invoice, 'dueDate' | 'items'>[] {
  const zone = timezone ?? items[0]?.product.farm.timezone
  const invoices: InvoiceTemp[] = []
  const productFees = getProductFeesFromCart({ items })

  items.forEach((item: CartItem) => {
    const { paymentSchedule, product } = item
    const now = dateTimeInZone(zone).startOf('day')
    const isEbtEligible = isProductEbtEligible(product)
    const ignoreOrderCutoffWindow = isAdmin ?? false
    const productFeesForProduct = getProductFeesForCartProduct(productFees, product.id)

    // totalprice should already be prorated if necessary
    const { itemAmount, upfrontPmt, isProrated } = getProratedAmount(item, {
      excludeHiddenDistros: true,
      excludeClosedDistros: !isAdmin,
      ignoreOrderCutoffWindow,
    })

    // Add invoice items for pay in total amounts and up front amounts
    if (paymentSchedule.paymentType === PaymentType.PAY_FULL && itemAmount !== null) {
      // Will get the total price
      const invPrice = MoneyCalc.fromCents(itemAmount ?? 0)
      const unitName = !isCartShare(item) ? item.unit.name : ''

      // For MPSP we want to multiply quantity by the number of pickups if they are paying all upfront
      const quantityMultiplier = isCartStandardMulti(item) ? item.pickups!.length : 1
      invoices.push({
        dueDate: now,
        item: {
          id: item.id,
          isEbtEligible,
          distId: item.distribution?.id,
          payments: [
            {
              source: paymentSource ?? PaymentSources.STRIPE,
              amount: invPrice,
            },
          ],
          quantity: item.quantity * quantityMultiplier,
          description: `${product.name}, ${unitName ? `(${unitName})` : ''}`,
          product: {
            id: product.id,
            name: product.name,
            category: product.category,
            producer: product.producer,
            description: product.description,
          },
          appliedProductFees: productFeesForProduct,
          location: item.distribution?.location ? pick(item.distribution.location, 'id', 'type', 'address') : undefined,
        },
      })
    } else if (upfrontPmt) {
      invoices.push({
        dueDate: now,
        item: {
          id: item.id,
          isEbtEligible,
          distId: item.distribution?.id,
          payments: [
            {
              source: PaymentSources.STRIPE,
              amount: MoneyCalc.fromCents(upfrontPmt ?? 0),
            },
          ],
          quantity: item.quantity,
          description: `[UPFRONT] ${product.name}`,
          product: {
            id: product.id,
            name: product.name,
            category: product.category,
            producer: product.producer,
            description: product.description,
          },
          appliedProductFees: productFeesForProduct,
          location: item.distribution?.location ? pick(item.distribution.location, 'id', 'type', 'address') : undefined,
        },
      })
    }

    // Return if it is an upfront payment skip interval calculation
    // There are times we want to get cost before the item is created, so we should not rely on item.pickups to be defined. Also pickups won't exist for digital products in cart
    // Will make sure we don't try to calculate payments if there are no pickups
    if (paymentSchedule.paymentType === PaymentType.PAY_FULL || !item.pickups || item.pickups.length === 0) {
      return
    }
    // Our number of payments is used to divide up the cost,
    const paymentDates = calculateInterval(paymentSchedule, item.pickups, zone, isProrated)

    // Will get the total price + any additional shipping required for the share
    const seasonalTotal = MoneyCalc.fromCents((itemAmount ?? 0) - (upfrontPmt ?? 0))

    // For MPSP we use the total as each invoice amount. For seasonal we split the amount between payments minus deposit
    const eachPayAmt = isCartStandardMulti(item)
      ? seasonalTotal
      : MoneyCalc.round(MoneyCalc.divide(seasonalTotal, paymentDates.length))

    // This amount is the remaining cents left over in the season total due to rounding. It will be at most +- 1 cent per invoice and will be added to the final invoice
    const remainingAmt = isCartStandardMulti(item)
      ? Zero
      : MoneyCalc.math(
          Math.round,
          MoneyCalc.subtract(seasonalTotal, MoneyCalc.multiply(eachPayAmt, paymentDates.length)),
        )

    // Calculates the total of each payment
    paymentDates.forEach((date, idx) => {
      const isLastPmt = idx === paymentDates.length - 1
      // Make installments show what number installment it is and MPSP shows PICKUP (1 of 5)
      const pickupDescriptor = formatDistributionType(item.distribution.location).toUpperCase()
      const invoiceText = `[${isCartStandardMulti(item) ? pickupDescriptor : 'INSTALLMENT'} (${idx + 1} of ${
        paymentDates.length
      })]`

      // The only time we want to charge fees for a share is if it is the first payment, because all installments are part of
      // the same quantity as the upfront and should not have additional fees charged on them
      const shouldChargeFees = !isShare(product) || (!upfrontPmt && idx === 0)

      return invoices.push({
        // If there is a payment scheduled in the past move as a current payment
        dueDate: date < now ? now : date,
        item: {
          id: item.id,
          isEbtEligible,
          distId: item.distribution.id,
          payments: [
            {
              source: PaymentSources.STRIPE,
              // If this is the last payment then we want to add the remaining cents on to it to bring the totals into sync
              amount: MoneyCalc.add(eachPayAmt, isLastPmt ? remainingAmt : Zero),
            },
          ],
          quantity: item.quantity,
          description: `${invoiceText} ${product.name}`,
          product: {
            id: product.id,
            name: product.name,
            category: product.category,
            producer: product.producer,
            description: product.description,
          },
          // We do not apply fixed fees to installments for shares, as they are part of the upfront purchased quantity
          appliedProductFees: shouldChargeFees
            ? productFeesForProduct
            : productFeesForProduct.filter((fee) => isPercentProductFee(fee)),
          location: pick(item.distribution.location, 'id', 'type', 'address'),
        },
      })
    })
  })

  return groupInvoices(invoices, zone)
}

/** Will get the required payment method details for the specified payment method to be stored on the invoice */
export function getInvoicePaymentMethod(paymentMethod: PaymentMethod): InvoicePaymentMethod<PaymentSources> {
  if (isEbtPayment(paymentMethod)) {
    return pick(paymentMethod, 'id', 'token', 'last4', 'card_type')
  } else if (isCreditPayment(paymentMethod)) {
    return pick(paymentMethod, 'id', 'token', 'last4', 'card_type')
  } else if (isAchPayment(paymentMethod)) {
    return pick(paymentMethod, 'id', 'token', 'last4', 'bank_name')
  } else if (isFarmCreditPayment(paymentMethod)) {
    return pick(paymentMethod, 'id', 'token')
  } else if (isCashPayment(paymentMethod)) {
    return pick(paymentMethod, 'id', 'token')
  } else {
    // This will help us when adding new payment methods to the system to make sure we don't forget to add them here
    throw new Error('attempted to convert unsupported payment type to invoice payment')
  }
}
