import { ErrorText, Picker, PickerProps, Text } from '@elements'
import { ReactNode, isValidElement } from 'react'
import { View } from 'react-native'
import { isWeb } from '../../../constants/Layout'
import { isLabelAccessible } from './helpers/helpers'
import { formStyles, useDynamicFormStyles } from './helpers/styles'
import { FormSharedProps } from './helpers/types'

type FormPickerInputType = FormSharedProps &
  PickerProps & {
    // Props specific to form inputs that will be rendered alongside the inputs
    errorMessage?: string
    label?: string | ReactNode
  }

/** This input component can be used to produce consistently styled picker inputs
 *
 * - If there's no placeholder, or it's loading, it will have a default placeholder with empty string as value, to prevent interference with Yup validation schemas */
export function FormPickerInput({
  label,
  errorMessage,
  placeholder,
  loading,
  row,
  containerStyle,
  ...props
}: FormPickerInputType) {
  const styles = useDynamicFormStyles(!!errorMessage, !!row)

  return (
    <View style={[isWeb ? formStyles.elementWrapper : formStyles.elementWrapperMobile, containerStyle]}>
      {isLabelAccessible(label) ? (
        <Text style={formStyles.inputLabel}>{label || ''}</Text>
      ) : isValidElement(label) ? (
        label
      ) : null}
      <Picker
        {...props}
        minimal
        style={styles.elementInputContainer}
        placeholder={
          loading
            ? { label: 'Loading...', value: '' } // value must be empty to not upset validation schema
            : placeholder === null
            ? null
            : !placeholder
            ? { label: 'Select an Option', value: '' } // value must be empty to not upset validation schema
            : placeholder
        }
      />
      {!!errorMessage && <ErrorText style={formStyles.elementErrorStyle}>{errorMessage}</ErrorText>}
    </View>
  )
}
