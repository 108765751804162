import { FontAwesome5 } from '@expo/vector-icons'
import { getOrderNum } from '@helpers/display'
import { PartialExcept } from '@helpers/typescript'
import { CSA } from '@models/CSA'
import { Farm } from '@models/Farm'
import { Invoice } from '@models/Invoice'
import { Order } from '@models/Order'
import { Product } from '@models/Product'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { useComponentRoute } from '@/hooks/useComponentRoute'
import { useDeviceSize } from '@/hooks/useLayout'
import { useSlugNavigation } from '@/hooks/useSlugNavigation'
import { OrdersParamList, ShoppingStackParamList, UserStackParamList } from '@/navigation/types'
import Colors from '../../constants/Colors'
import { NavParams } from '../../constants/types/navParams'
import { paramsSelector } from '../../redux/selectors'
import { ButtonClear } from './ButtonClear'
import { Divider } from './Divider'
import { HeaderText, Text } from './Text'
import WebLink from './WebLink'

type RoutePiece = {
  name: string
  url: string
  /** Will display a screen header with this title below the breadcrumbs. If there's an empty string, will display the route name. If undefined, will not show a screen header */
  showTitle?: string
}

/** Partial Farm but with required id, name and urlSafeSlug */
type PartialFarmWithRef = PartialExcept<Farm, 'name' | 'id' | 'urlSafeSlug'>
/** Partial CSA but with required id and name */
type PartialCSAWithRef = PartialExcept<CSA, 'name' | 'id'>
/** Partial Order but with required id and orderNum */
type PartialOrderWithRef = PartialExcept<Order, 'orderNum' | 'id'>
/** Partial Invoice but with required id */
type PartialInvoiceWithRef = PartialExcept<Invoice, 'id'>
/** Partial product but with required name and id */
type PartialProductWithRef = PartialExcept<Product, 'name' | 'id'>

type BuilderType = {
  FarmDetailsScreen: (farm?: PartialFarmWithRef) => RoutePiece[]
  FarmShop: (farm?: PartialFarmWithRef) => RoutePiece[]
  ProductDetails: (
    farm?: PartialFarmWithRef,
    product?: PartialExcept<Product, 'name' | 'id' | 'urlSafeSlug'>,
    csa?: PartialCSAWithRef,
  ) => RoutePiece[]
  CSADetails: (farm?: PartialFarmWithRef, csa?: PartialCSAWithRef) => RoutePiece[]
  Orders: () => RoutePiece[]
  Invoices: () => RoutePiece[]
  OrderSummary: (order?: PartialOrderWithRef) => RoutePiece[]
  InvoiceConsumer: (invoice?: PartialInvoiceWithRef) => RoutePiece[]
  MyCart: (farm?: PartialFarmWithRef) => RoutePiece[]
  Checkout: (farm?: PartialFarmWithRef) => RoutePiece[]
}
type UserBuilderType = {
  Profile: () => RoutePiece[]
  PersonalInformation: () => RoutePiece[]
  BalanceScreen: () => RoutePiece[]
  AddressScreen: () => RoutePiece[]
  PaymentsScreen: () => RoutePiece[]
  Notifications: () => RoutePiece[]
  Security: () => RoutePiece[]
}

const CrumbBuilder: BuilderType = {
  FarmDetailsScreen: (farm?: PartialFarmWithRef) => {
    if (!farm) return []
    return [
      { name: 'Explore', url: `/explore` },
      { name: farm.name, url: `/farms/${farm.urlSafeSlug}` },
    ]
  },
  FarmShop: (farm?: PartialFarmWithRef) => {
    if (!farm) return []
    return [...CrumbBuilder.FarmDetailsScreen(farm), { name: 'Shop Products', url: `/farms/${farm.urlSafeSlug}/shop` }]
  },
  ProductDetails: (farm?: PartialFarmWithRef, product?: PartialProductWithRef, csa?: PartialCSAWithRef) => {
    if (!farm || !product) return []
    if (csa)
      return [
        ...CrumbBuilder.CSADetails(farm, csa),
        {
          name: product.name,
          url: `/farms/${farm.urlSafeSlug}/shop/product/${product.id}`,
        },
      ]
    return [
      ...CrumbBuilder.FarmShop(farm),
      {
        name: product.name,
        url: `/farms/${farm.urlSafeSlug}/shop/product/${product.id}`,
      },
    ]
  },
  CSADetails: (farm?: PartialFarmWithRef, csa?: PartialExcept<CSA, 'name' | 'id'>) => {
    if (!farm || !csa) return []
    return [...CrumbBuilder.FarmShop(farm), { name: csa.name, url: `/farms/${farm.urlSafeSlug}/shop/csa/${csa.id}` }]
  },
  Orders: () => {
    return [{ name: 'Orders', url: `/orders` }]
  },
  OrderSummary: (order?: PartialOrderWithRef) => {
    if (!order) return []
    return [...CrumbBuilder.Orders(), { name: `Order ${getOrderNum(order.orderNum)}`, url: `/orders/${order.id}` }]
  },
  Invoices: () => {
    return [{ name: 'Invoices', url: '/orders' }]
  },
  InvoiceConsumer: (invoice?: PartialInvoiceWithRef) => {
    if (!invoice) return []
    return [
      ...CrumbBuilder.Invoices(),
      { name: `Invoice ${getOrderNum(invoice.invoiceNum)}`, url: `/orders/invoice/${invoice.id}` },
    ]
  },
  MyCart: (farm?: PartialFarmWithRef) => {
    if (!farm) return []
    return [...CrumbBuilder.FarmShop(farm), { name: 'My Cart', url: `/farms/shop/my-cart` }]
  },
  Checkout: (farm?: PartialFarmWithRef) => {
    if (!farm) return []
    return [...CrumbBuilder.MyCart(farm), { name: 'Checkout', url: `/farms/shop/checkout` }]
  },
}

const UserCrumbs: UserBuilderType = {
  Profile: () => {
    return [{ name: 'Profile', url: `/profile` }]
  },
  PersonalInformation: () => {
    return [...UserCrumbs.Profile(), { name: `Personal Information`, url: `/profile/view`, showTitle: '' }]
  },
  BalanceScreen: () => {
    return [...UserCrumbs.Profile(), { name: `Your Farm Credits`, url: `/profile/balances`, showTitle: '' }]
  },
  AddressScreen: () => {
    return [...UserCrumbs.Profile(), { name: `Your Addresses`, url: `/profile/addresses`, showTitle: '' }]
  },
  PaymentsScreen: () => {
    return [...UserCrumbs.Profile(), { name: `Your Payment Methods`, url: `/profile/payments`, showTitle: '' }]
  },
  Notifications: () => {
    return [...UserCrumbs.Profile(), { name: `Notification Preferences`, url: `/profile/notifications`, showTitle: '' }]
  },
  Security: () => {
    return [...UserCrumbs.Profile(), { name: `Password & Security`, url: `/profile/security`, showTitle: '' }]
  },
}

/** Tcreen routes supported by the crumbs. This type is helpful because if a route name ever changed, there would be errors if this file letting us know we also need to update the reference to the route name */
type CrumbRoutes = keyof ShoppingStackParamList | keyof OrdersParamList | keyof UserStackParamList

const getCrumb = (routeName: CrumbRoutes, navProps: NavParams) => {
  if (routeName === 'FarmDetailScreen') return CrumbBuilder.FarmDetailsScreen(navProps.farm)
  if (routeName === 'FarmShop') return CrumbBuilder.FarmShop(navProps.farm)
  if (routeName === 'ProductDetails') return CrumbBuilder.ProductDetails(navProps.farm, navProps.product, navProps.csa)
  if (routeName === 'CSADetails') return CrumbBuilder.CSADetails(navProps.farm, navProps.csa)
  if (routeName === 'MyCart') return CrumbBuilder.MyCart(navProps.farm)
  if (routeName === 'Checkout') return CrumbBuilder.Checkout(navProps.farm)

  if (routeName === 'OrderSummary') return CrumbBuilder.OrderSummary(navProps.order)
  if (routeName === 'InvoiceConsumer') return CrumbBuilder.InvoiceConsumer(navProps.invoice)

  if (routeName === 'PersonalInformation') return UserCrumbs.PersonalInformation()
  if (routeName === 'BalanceScreen') return UserCrumbs.BalanceScreen()
  if (routeName === 'AddressScreen') return UserCrumbs.AddressScreen()
  if (routeName === 'PaymentsScreen') return UserCrumbs.PaymentsScreen()
  if (routeName === 'Notifications') return UserCrumbs.Notifications()
  if (routeName === 'Security') return UserCrumbs.Security()
  return []
}

export const BreadCrumbs = memo(function BreadCrumbs({ goBack }: { goBack?: () => void }) {
  const { isSmallDevice } = useDeviceSize()
  const navProps = useSelector(paramsSelector)
  const routeName = useComponentRoute()?.name ?? ''
  const navigation = useNavigation<StackNavigationProp<any>>()
  const [crumbs, setCrumbs] = useState<RoutePiece[]>([])
  const [bigHeader, setBigHeader] = useState<string>()
  useSlugNavigation() // forces navigation to use slug as parameter instead of id

  /** On change to route and props, will set the crumb data for the route, and set the header content */
  useEffect(() => {
    if (!routeName) return

    const crumbs = getCrumb(routeName as CrumbRoutes, navProps)

    if (!crumbs) return

    setCrumbs(crumbs)

    const idx = crumbs.length - 1
    const title = crumbs[idx]?.showTitle

    if (title !== undefined) {
      // If title is empty then use the route name, else use the value in show title
      if (title?.length === 0) setBigHeader(crumbs[idx].name)
      else setBigHeader(title)
    } else {
      setBigHeader(undefined)
    }
  }, [routeName, navProps])

  if (!crumbs.length) return <Divider clear top={20} />

  return (
    <View>
      <View style={styles.crumbContainer}>
        {!isSmallDevice && (
          <ButtonClear
            title="Back"
            icon="arrow-left"
            size={14}
            color={Colors.shades[500]}
            onPress={() => (goBack ? goBack() : navigation.canGoBack() ? navigation.goBack() : undefined)}
          />
        )}
        {crumbs.map((route, idx) => (
          <View style={styles.item} key={route.name}>
            {idx !== crumbs.length - 1 ? (
              <>
                <WebLink style={styles.link} url={route.url}>
                  {route.name}
                </WebLink>
                <FontAwesome5 name="chevron-right" />
              </>
            ) : (
              <Text size={12}>{route.name}</Text>
            )}
          </View>
        ))}
      </View>
      {!!bigHeader && <HeaderText size={30}>{bigHeader}</HeaderText>}
    </View>
  )
})

const styles = StyleSheet.create({
  crumbContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 15,
    marginHorizontal: 10,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 5,
  },
  link: { marginRight: 10 },
})
