import { SearchOptions } from '@algolia/client-search'
import { getSortAlgoliaProducts } from '@helpers/sorting'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, FILTERS, FacetFilter, checkFacetFilter } from '@models/Algolia'
import { ProductFilters } from '@models/Filters'
import { Location } from '@models/Location'
import { AddonShare, isAddon } from '@models/Product'
import { useMenu } from 'react-instantsearch'

/** The original menu item type from algolia as a direct reference */
export type MenuItem = ReturnType<typeof useMenu>['items'][0]

export const ALL_CATEGORIES = 'All'
export const ALL_TYPES = 'All'

/** Prepares data for the shop sidebar from type refinement options
 * - For the moment will allow only primary and addons
 */

type BuildFilterOpts = {
  farmId: string | undefined
  filterByEbt: boolean
  csaId?: string
}

/** Creates algolia facets filters, sent as queries to algolia */
export const buildFilters = (props: BuildFilterOpts): SearchOptions['facetFilters'] => {
  const { farmId, filterByEbt, csaId } = props

  // If the farmId is not ready yet, this will build a query that returns no results
  if (!farmId) return [FILTERS.Null]

  /** These filters must be in the correct order, otherwise some might not be applied.
   * - You should not apply the hidden filter before the docType filter, since some non-product docTypes don't possess a isHidden field */
  const facets: FacetFilter = [
    FILTERS.Product,
    checkFacetFilter<'farm.id', string>(`farm.id:${farmId}`),
    FILTERS.NotHidden,
  ]
  if (filterByEbt) {
    facets.push(FILTERS.Ebt)
  }

  if (csaId) {
    // If a csaId is provided, this assumes we are on csa details, so we filter by the csa id and we allow addons and private prods
    facets.push(checkFacetFilter<'csa', string>(`csa:${csaId}`))
  } else {
    /** We're not showing addons in the shop. So algolia should not return addons. We will show avail addons only, but those don't come from algolia */
    facets.push(FILTERS.NotAddon)

    /** If we're in screens other than the CSADetails (homescreen, shop, etc), this must exclude private products.
     * Private products are only meant to be shown in the CSADetails screen. Private products, in this sense, means they are BOTH "Only show in CSA" AND are assigned to only private CSA group/s.
     * INFO: Those two criteria are supposed to already be encoded in the isPrivate field, which allows us to easily filter by the two criteria by using this single filter. */
    facets.push(FILTERS.NotPrivateProd)
  }

  return facets
}

/** Creates the product data for the UI, based on the farm's db locations and algolia refinement location options */
export function getLocations(
  dbLocs: Location[] | undefined,
  algoliaLocItems: Pick<MenuItem, 'value'>[],
): { locations: undefined; loadingLocations: boolean } | { locations: Location[]; loadingLocations: boolean } {
  if (!dbLocs) return { locations: undefined, loadingLocations: true }

  const algoliaLocIds = algoliaLocItems.map((loc) => loc.value)
  return {
    locations: dbLocs.filter((loc) =>
      // This should only allow showing the db locations which are present in the algolia location ids
      algoliaLocIds.includes(loc.id),
    ),
    loadingLocations: false,
  }
}

type GetProductsOptions = ProductFilters & {
  hits: AlgoliaGeoDoc<AlgoliaGeoProduct>[]
  availAddons: AddonShare[]
  isLoading: boolean
}

/** Processes the product data from db and algolia and the scrceen filters, to create the product data for the UI */
export function getProducts(opts: GetProductsOptions): (AlgoliaGeoDoc<AlgoliaGeoProduct> | AddonShare)[] {
  const { hits, csaId, availAddons, isLoading, ...filters } = opts

  /** Sorting will be applied only when the searchTerm is not provided
   * When searching by term, we do not want to apply sorting, as the searched item can be placed at the end of the list.
   */
  const shouldSort = !filters.searchTerm?.length

  if (isLoading) {
    /** This part is intentionally not returning products until the hits appear. Even if the availAddons are present, we won't show those alone. We will only show them in conjunction with the search hits. Otherwise the screen would show first a small set of results, and a larger set later. The addons integrated here are meant to be part of the search hits, not meant to be shown by themselves. Do NOT return addons by themselves. */
    return []
  }

  if (csaId) {
    /** If we're filtering by csaId, it is assumed we're in CSADetail screen.
     * - We ignore the availAddons because it is expected the hits should already include the addons for the csaId, since the csaId should've been used as a search filter.
     * - We allow showing private products because they're allowed in the CSADetails screen
     * - We don't need to apply the filters on the hits because those should have been applied on the search query
     */

    return !shouldSort ? hits : [...hits].sort(getSortAlgoliaProducts(availAddons.map((adn) => adn.id)))
  }

  /** If we're not filtering by csaId, it is assumed we are not in the CSADetail screen.
   * - We are not supposed to show addons. However, we do want to show those that are available for adding to cart. To accomplish that, we will merge the hits with the available addons from the useAvailAddons hook.
   * - We must exclude private products outside of CSADetails screen. Hits should already be filtered by notPrivate in this case. But the addons aren't yet filtered. So we must apply a notPrivate filter to the avail addons array.
   */

  /** Get the available addons allowed to be shown, based on the current filters. Any addons not in this filtered list will be hidden. */
  const filteredAvailAddons = filterShopAddons(availAddons, filters)
  const filteredHits = [...hits.filter((hit) => !isAddon(hit))]

  if (!shouldSort) {
    /** Place addons at the start of the list if no sorting is applied */
    return [...filteredAvailAddons, ...filteredHits]
  }

  return [...filteredHits, ...filteredAvailAddons].sort(
    getSortAlgoliaProducts(filteredAvailAddons.map((adn) => adn.id)),
  )
}

const filterShopAddons = (addons: AddonShare[], filters: ProductFilters) => {
  const { type, locationId, categoryId, searchTerm } = filters

  // Do not show private products outside of the csa details screen. Also applies to avail addons
  // The algolia filters should already have applied this filter for the hits, but these avail addons aren't yet filtered
  const filtered = addons
    .filter((adn) => !adn.isPrivate)
    /** If showing avail addons, should apply any filters used in the screen, so they can have the same refinements as the search hits */
    .filter((adn) => (type ? type === adn.type : true))
    .filter((adn) => (locationId ? adn.distributions.find((dist) => dist.location.id === locationId) : true))
    .filter((adn) => (categoryId ? adn.category === categoryId : true))
    /// Filter addons just by name if search term is provied
    .filter((adn) =>
      searchTerm ? [adn.name].some((value) => value.toLowerCase().includes(searchTerm.toLowerCase().trim())) : true,
    )

  return filtered
}
