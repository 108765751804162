import { CheckBox, Divider, FormInput, TextH2 } from '@elements'

import React from 'react'
import { View } from 'react-native'

import { AdminCard } from '@/admin/components/AdminCard'
import { globalStyles } from '@/constants/Styles'
import { FormikProps } from 'formik'
import { PaymentsFormProps } from './types'

/** Offline payments section */
export function OfflinePayments({ values, setFieldValue, handleChange }: FormikProps<PaymentsFormProps>) {
  return (
    <AdminCard noMargins>
      <TextH2 size={18}>Offline Payments</TextH2>

      <View style={globalStyles.margin10} />
      <CheckBox
        checked={values.offlineEnabled}
        onChange={(val) => setFieldValue('offlineEnabled', val)}
        title="Allow offline payments"
      />
      <Divider clear />
      {values.offlineEnabled && (
        <FormInput
          multiline
          numberOfLines={8}
          label="Offline payment message"
          placeholder="Enter what your users will see when they place an order to pay offline"
          value={values.offlinePaymentsMessage}
          onChangeText={handleChange('offlinePaymentsMessage')}
        />
      )}
    </AdminCard>
  )
}
