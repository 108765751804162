export * from './FormColorPicker'
export * from './FormDateTimeInput'
export * from './FormDisplayRow'
export * from './FormInput'
export * from './FormMaskedInput'
export * from './FormMoneyInput'
export * from './FormNumberInput'
export * from './FormPickerInput'

/** This allows us to specify an initial value as undefined even when the form type and yup schema have the field type as required.
 *
 * Full Explanation: This is necessary in a situation where you want to force the user to "touch" the field before submission. In a case like that, the form type and schema will have the field as required, and therefore you would need to provide a nonNullish initial value to satisfy the schema type; But then there's no way in formik and yup to prevent the form from being submitted if the user didn't touch the field. In other words, there's no functionality in formik/yup where you can say a field must be touched before submission. (2024.03.28_18.35 Miguel verified that in a brief research session) So the only solution is to give it a nullish initial value which doesn't satisfy the nonNullish schema & type. In this case the user will be forced to enter a value before submitting, because the initial form value doesn't satisfy the schema so they will get a validation error unless they change the initial nullish value. That's what we want.*/
export const FormUndefinedInitialValue = undefined as any
