import { User } from '@models/User'
import { DocumentData } from 'firebase/firestore'

export interface ChangeUserRequest {
  userId: string
  deltas: Partial<User>
  oldDeltas: Partial<User>
  test?: boolean
}

export interface EditCustomerRequest {
  farmId: string
  customerId: string
}

export interface MarkEmailVerifiedRequest {
  userId: string
  email: string
  /** ExternalLink doc Id */
  externalId: string
}

export enum VerifyEmailResult {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export interface SendUserMessageType {
  customerId: string
  farmId: string
  subject: string
  farmerId: string
  message: string
}

export interface CreateFirestoreAndAuthUserRequest {
  userData: DocumentData
  farmId: string
}
