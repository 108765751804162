import { SearchOptions } from '@algolia/client-search/dist/client-search'
import { GetDeliveryFeeResult, NoDeliveryFees } from '@helpers/location'
import { getPickups } from '@helpers/order'
import { isActive, isInStock } from '@helpers/products'
import { AlgoliaAdminProduct, FILTERS, FacetFilter, ProductTypeFilter } from '@models/Algolia'
import { CSA } from '@models/CSA'
import { Distribution } from '@models/Distribution'
import { Money, Zero } from '@models/Money'
import { SplitTenderPayment } from '@models/Order'
import { Product, ProductType, isDigital, isPhysical, isShare, isStandard } from '@models/Product'
import { User } from '@models/User'
import { CartItemGroupType } from '@screens/Shopping/Checkout/CartItemsCheckout'
import { initialTotal } from '@screens/Shopping/Checkout/helpers'
import { MarkAsPaidInfo } from '@shared/types/v2/invoice'
import { DateTime } from 'luxon'
import { Hit } from 'react-instantsearch-core'

import { CreateOrderScreenParams, OrderType } from '../../navigation/types'

import { Total } from '@/constants/types'
import { UseApiFxReturn } from '@/hooks/useApiFx'
import { ProductFeesForInvoice } from '@helpers/productFee'
import { isSameDay } from '@helpers/time'
import { PaymentSelectorOptions } from '@screens/PaymentMethods/PaymentSelection/helpers/types'

export const goBackString = (params?: CreateOrderScreenParams): string => {
  switch (params?.goBack) {
    case 'orders':
      return 'All orders'
    case 'customers':
      return 'All Customers'
    case 'customerDetails':
      return 'Customer Details'
    default:
      return 'Previous Screen'
  }
}

/** Returns a url that should navigate back to the screen that came to the order creator */
export const goBackUrl = (params?: CreateOrderScreenParams): string => {
  switch (params?.goBack) {
    case 'orders':
      return '/admin/orders/list'
    case 'customers':
      return '/admin/customers/list'
    case 'customerDetails': {
      if (params?.custId) return `/admin/customers/${params.custId}`
      else return `/admin/customers`
    }
    default:
      return 'admin/orders'
  }
}

const getTypeFilter = (orderType?: OrderType): ProductTypeFilter[] | ProductTypeFilter | undefined => {
  if (!orderType) return
  switch (orderType) {
    case 'standard':
    case 'digital':
      return `type:${orderType}`
    case 'share':
      return ['type:addon', 'type:primary']
  }
}
/** Builds the facetFilters for the product search */
export const buildProdFilters = (
  farmId: string,
  orderType?: OrderType,
  distroId?: string,
  csaId?: string,
): SearchOptions['facetFilters'] => {
  const filters: FacetFilter = [FILTERS.Product, `farmId:${farmId}`, FILTERS.NotHidden]
  const typeFilter = getTypeFilter(orderType)
  if (typeFilter) filters.push(typeFilter)
  if (distroId && orderType !== 'digital') filters.push(`distributions.id:${distroId}`)
  if (csaId && orderType === 'share') filters.push(`csas.id:${csaId}`)
  return filters
}

/** Determines which products will be shown in the create order table.
 * - Filters products in table based on non-algolia selectors (distro and pickup date), as well as some product attributes. */
const showProd = (
  p: Product,
  distro?: Distribution,
  pickupDate?: DateTime,
  csa?: CSA,
  orderType?: OrderType,
): boolean => {
  // Filter by ordertype
  if (orderType === 'standard' && !isStandard(p)) return false
  if (orderType === 'share') {
    if (!isShare(p)) return false
    if (!!csa && !p.csa.includes(csa.id)) return false
  }
  if (orderType === 'digital' && !isDigital(p)) return false

  // Don't show products with no unhiden distros.
  if (isPhysical(p) && !p.distributions.some((d) => !d.isHidden)) return false

  if (!isInStock(p)) return false

  // Filter physical products by distro selected
  if (distro && isPhysical(p)) {
    if (!p.distributions.find((pd) => pd.id === distro.id)) return false

    const pickups = getPickups(distro, p, {
      excludeHiddenDistros: true,
      ignoreOrderCutoffWindow: true,
      ignoreDisableBuyInFuture: true,
    })
    const minPickups = isStandard(p) ? p.minPickups ?? 1 : 1

    if (pickups.length < minPickups) return false

    // Filter standard by date selected
    if (orderType === 'standard' && pickupDate && !pickups.find((date) => date.toISODate() === pickupDate.toISODate()))
      return false
  } else {
    // If there's no distro selected, show products with pickups left
    if (!isActive(p, { excludeClosedDistros: false, excludeHiddenDistros: true, ignoreOrderCutoffWindow: true }))
      return false
  }

  return true
}

export const isSameOrderType = (prodType: ProductType, orderType: OrderType = 'standard') => {
  if (orderType === 'share' && isShare(prodType)) return true
  if (orderType === 'standard' && prodType === ProductType.Standard) return true
  if (orderType === 'digital' && isDigital(prodType)) return true
  return false
}
/** When the orderType changes, algolia's current hits will be outdated. On subsequent re-renders, we can use this to tell if the new hits reflect the new orderType. This is necessary to know when to disable the loader state */
export const hitsAreUpdated = (
  hits: Hit<AlgoliaAdminProduct>[],
  orderType: OrderType = 'standard',
  distroId?: string,
  csaId?: string,
): boolean => {
  return (
    hits.every((h) => isSameOrderType(h.type, orderType)) &&
    (distroId && orderType !== 'digital' ? hits.every((h) => h.distributions.find((d) => d.id === distroId)) : true) &&
    (csaId && orderType === 'share' ? hits.every((h) => h.csas.find((csa) => csa.id === csaId)) : true)
  )
}
/** Indicates whether the dbProds correspond to the current orderType */
export const dbProdsAreUpdated = (dbProds: Product[], orderType: OrderType = 'standard'): boolean => {
  return !dbProds.length || dbProds.every((p) => isSameOrderType(p.type, orderType))
}
/** Gets the results that correspond to the orderType selected and the algolia hit results. The filtering here is only necessary during the first re-renders after changing the orderType. On subsequent re-renders, the new results will all reflect the new orderType */
export const makeTableData = (
  results: Product[],
  orderType: OrderType = 'standard',
  hits: { id: string; type: ProductType }[],
  distro?: Distribution,
  csa?: CSA,
  pickupDate?: DateTime,
): Product[] => {
  return results.filter(
    (res) =>
      isSameOrderType(res.type, orderType) &&
      hits.map((h) => h.id).includes(res.id) &&
      showProd(res, distro, pickupDate, csa, orderType),
  )
}

export const getProdTypes = (orderType?: OrderType): ProductType[] => {
  if (orderType === 'standard') return [ProductType.Standard]
  if (orderType === 'share') return [ProductType.AddonShare, ProductType.PrimaryShare]
  if (orderType === 'digital') return [ProductType.Digital]
  return []
}

export type CreateOrderScreenContextType = {
  prodSearchTerm: string
  distro?: Distribution
  customer?: User
  pickupDates: DateTime[]
  pickupDate?: DateTime
  csa?: CSA
  splitTender?: SplitTenderPayment
  appliedFarmBalance: Money
  /** markAsPaid info will be used to decide if this order will be marked as paid on creation. will be passed into OderSummary for use by components downstream */
  markAsPaidInfo: MarkAsPaidInfo
  total: Total
  /** Additional fees that should be applied on products */
  additionalFees: ProductFeesForInvoice
  tableData?: Product[]
  /** delivery fees total and details (due anytime). This data is supposed to reflect combined delivery dates based on future pickups */
  deliveryFeesData: GetDeliveryFeeResult &
    Pick<UseApiFxReturn<any>, 'err' | 'loading'> & {
      /** Whether the cart includes new delivery fees, regardless of of due date */
      hasDeliveryFees: boolean
    }
  /** grouping of cartitems by delivery fee locations */
  itemGroups: CartItemGroupType[]
  paymentSelectorOptions: PaymentSelectorOptions | undefined
}

export const createOrderScreenInitialState: CreateOrderScreenContextType = {
  prodSearchTerm: '',
  pickupDates: [],
  appliedFarmBalance: Zero,
  markAsPaidInfo: { markAsPaid: false, note: '' },
  total: initialTotal,
  additionalFees: [],
  csa: undefined,
  customer: undefined,
  distro: undefined,
  pickupDate: undefined,
  splitTender: undefined,
  tableData: undefined,
  deliveryFeesData: { ...NoDeliveryFees, hasDeliveryFees: false, loading: false, err: undefined },
  itemGroups: [],
  paymentSelectorOptions: undefined,
}

export const getPickupsForSelectedDistro = (distro: Distribution, dbProds?: Product[]): DateTime[] => {
  let pickups: DateTime[]

  if (dbProds) {
    pickups = dbProds
      .flatMap((p) =>
        //get the pickups for all the products at this schedule
        getPickups(distro, p, {
          ignoreOrderCutoffWindow: true,
          excludeHiddenDistros: true,
          ignoreDisableBuyInFuture: true,
        }),
      )
      .reduce<DateTime[]>(
        //combine the pickups with no duplicates
        (agg, next) => (agg.find((d) => isSameDay(d, next, distro.location.timezone)) ? agg : agg.concat(next)),
        [],
      )
  } else
    pickups = getPickups(distro, undefined, {
      ignoreOrderCutoffWindow: true,
      excludeHiddenDistros: true,
      ignoreDisableBuyInFuture: true,
    })

  return pickups
}
