import { StyleSheet, View } from 'react-native'

import Colors, { withAlpha } from '../../constants/Colors'

export default function (_props: google.maps.LatLngLiteral) {
  return (
    <View style={styles.currLocation}>
      <View style={styles.currLocationInside} />
    </View>
  )
}

const styles = StyleSheet.create({
  currLocation: {
    position: 'absolute',
    top: -20,
    left: -20,
    backgroundColor: withAlpha(Colors.blue, 0.2),
    width: 40,
    height: 40,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: withAlpha(Colors.blue, 0.2),
  },
  currLocationInside: {
    backgroundColor: Colors.blue,
    width: 15,
    height: 15,
    borderRadius: 25,
    borderWidth: 2,
    borderColor: Colors.white,
  },
})
