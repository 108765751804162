import { Text, typography } from '@elements'
import { formatMoney } from '@helpers/display'
import { CategorySales, ProductSales, TemplateProductSales } from '@shared/types/v2/sales'
import { memo, useCallback, useMemo, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle } from 'rn-responsive-styles'

import { DateValue } from '../../Analytics/util'
import { Column, ExpandableRow } from '../../OfflineTable/ExpandableRow'
import { Tag } from '../../ProductTag'
import { Product } from './Product'
import { TemplateProduct } from './TemplateProduct'
import { LoadingRow, createColumns, indent1 } from './helpers'

import Colors from '@/constants/Colors'
import { adminFarmSelector } from '@/redux/selectors'
import { fetchProductSalesForCategory, fetchTemplateProductSalesForCategory } from '@api/Analytics'

interface CategoryProps {
  data: CategorySales
  index: number
  dateFilter: DateValue
  isTotal?: boolean
}

export const Category = memo(function Category({ data, index, dateFilter, isTotal }: CategoryProps) {
  const farm = useSelector(adminFarmSelector)
  const styles = useResponsiveStyle()

  const isOdd = index % 2 === 1

  const [products, setProducts] = useState<ProductSales[] | null>(null)
  const [templateProducts, setTemplateProducts] = useState<TemplateProductSales[] | null>(null)

  const productSubrows = useMemo(() => {
    if (!products)
      return [...new Array(data.productIds.length)].map((_, i) => <LoadingRow key={`${i}_product_loader`} />)
    return products.map((product, i) => (
      <Product data={product} index={i} indent={indent1} dateFilter={dateFilter} key={product.id} />
    ))
  }, [products, data.productIds.length, dateFilter])

  const templateProductSubrows = useMemo(() => {
    if (!templateProducts)
      return [...new Array(data.templateProductIds.length)].map((_, i) => <LoadingRow key={`${i}_template_loader`} />)
    return templateProducts.map((templateProduct, i) => (
      <TemplateProduct
        data={templateProduct}
        indent={indent1}
        index={i}
        dateFilter={dateFilter}
        key={templateProduct.id}
      />
    ))
  }, [templateProducts, data.templateProductIds.length, dateFilter])

  const fetchProducts = useCallback(async () => {
    //don't fetch if there are no products
    if (data.productIds.length === 0) return
    const productsRes = await fetchProductSalesForCategory(dateFilter, farm.id, data.productIds, farm.timezone)
    setProducts(productsRes)
  }, [data.productIds, dateFilter, farm.id, farm.timezone])

  const fetchTemplateProducts = useCallback(async () => {
    //don't fetch if there are no template products
    if (data.templateProductIds.length === 0) return
    const templateProductsRes = await fetchTemplateProductSalesForCategory(
      dateFilter,
      farm.id,
      data.templateProductIds,
      farm.timezone,
    )
    setTemplateProducts(templateProductsRes)
  }, [data.templateProductIds, dateFilter, farm.id, farm.timezone])

  const fetchSubRows = useCallback(async () => {
    await Promise.all([fetchProducts(), fetchTemplateProducts()])
  }, [fetchProducts, fetchTemplateProducts])

  const generateSubRows = useCallback(() => {
    return templateProductSubrows.concat(productSubrows)
  }, [productSubrows, templateProductSubrows])

  const rowStyle: StyleProp<ViewStyle> = useMemo(
    () => [isTotal ? styles.totalRow : isOdd ? styles.oddContainer : undefined],
    [isTotal, isOdd, styles.totalRow, styles.oddContainer],
  )

  const columns = useMemo(
    (): Column<CategorySales>[] =>
      createColumns({
        name(category) {
          return (
            <Text numberOfLines={1} style={[styles.text, isTotal && styles.totalText]}>
              {category.name}
            </Text>
          )
        },
        sku() {
          return ''
        },
        type() {
          return isTotal ? <></> : <Tag color={Colors.gold} title="Category" />
        },
        quantitySold(category) {
          return <Text style={[styles.text, isTotal && styles.totalText]}>{category.quantitySold.toString()}</Text>
        },
        grossAmount(category) {
          return <Text style={[styles.text, isTotal && styles.totalText]}>{formatMoney(category.grossAmount)}</Text>
        },
      }),
    [styles, isTotal],
  )

  return (
    <ExpandableRow<CategorySales>
      item={data}
      index={index}
      onFirstExpand={fetchSubRows}
      rowContainerStyle={rowStyle}
      columns={columns}
      generateSubRows={generateSubRows}
    />
  )
})

const useResponsiveStyle = CreateResponsiveStyle({
  oddContainer: { backgroundColor: Colors.lightGray },
  totalRow: { backgroundColor: Colors.semiGreen },
  text: { fontFamily: typography.body.regular, flex: 1 },
  totalText: { fontSize: 16 },
})
