import { addUserAddress } from '@api/UserAddress'
import { validatePhoneNumber } from '@api/Users'
import { Alert, Toast } from '@elements'
import { errorToString, isEmptyValue } from '@helpers/helpers'
import { Address, validateAddress } from '@models/Address'
import { FormikErrors } from 'formik'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUser } from '../../../redux/actions/user'
import { userSelector } from '../../../redux/selectors'
import { BillingAddressFormType } from './helpers'
import { checkoutInitialState } from './useCheckoutData'

import { Logger } from '@/config/logger'
import { SetHelper } from '@/hooks/useKeyedState'

/** Will do formik address validation in addition to the Yup schema, to have more control over the async flow, and be able to show toasts and alerts, and properly save user data.
 * - We need an async validator to check for duplicate phone numbers.
 * - If errors are found, they will be returned in the formik format. Undefined means no errors.
 * @param shouldRequireForm is meant to be true if the user address is either empty or invalid. A user who has previously placed orders and has a valid address should not be required to fill this address again */
export const useValidateBillingAddress = (set: SetHelper<typeof checkoutInitialState>, shouldRequireForm: boolean) => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)

  return useCallback(
    async (values: BillingAddressFormType): Promise<FormikErrors<BillingAddressFormType> | void> => {
      set('formValidationLoading', true)
      let errors: FormikErrors<BillingAddressFormType> = {}

      if (shouldRequireForm) {
        //Validate the phone number, if one was provided. It is optional
        let newPhoneNumber = values.phoneNumber || ''
        if (newPhoneNumber) {
          try {
            newPhoneNumber = await validatePhoneNumber(newPhoneNumber, user.id, false)
          } catch (err) {
            const msg = errorToString(err)
            Toast(msg)
            errors.phoneNumber = msg
          }
        }

        //Validate address
        const newAddress: Omit<Address, 'coordinate'> = {
          street1: values.street1,
          street2: values.street2,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
        }
        // We allow PO box addresses in the validation of billing address for checkout
        errors = { ...errors, ...validateAddress(newAddress, { allowPO: true }) }

        if (!isEmptyValue(errors)) {
          Alert(
            'Need address',
            `Please add a valid billing address to proceed.\n\nErrors:\n\n${Object.values(errors).join(' | ')}`,
          )
          set('formValidationLoading', false)
          return errors
        } else {
          try {
            //Save the address to user's addresses subCollection as default
            const savedAddress = await addUserAddress(user.id, { ...newAddress, isDefault: true }, { allowPO: true })
            dispatch(
              setUser({
                ...user,
                address: savedAddress,
                phoneNumber: newPhoneNumber,
              }),
            )
          } catch (error) {
            Logger.error(error)
            Alert('Error', `There was a problem while trying to update your address. Please contact support`)
            set('formValidationLoading', false)
            return { ...errors, updateError: errorToString(error) }
          }
        }
      }
      //If form isn't required, no need to validate
      set('formValidationLoading', false)
    },
    [dispatch, set, shouldRequireForm, user],
  )
}
