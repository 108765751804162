import { AlgoliaFarmDataContext } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData'
import { useComponentRoute } from '@/hooks/useComponentRoute'
import { FarmDataContext } from '@/hooks/useFarmData'
import { ShoppingStackParamList } from '@/navigation/types'
import { isNonNullish } from '@helpers/helpers'
import { ArrElement } from '@helpers/typescript'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useContext, useMemo } from 'react'
import {
  ProductFilterGroup,
  getCategoryRefinementGroup,
  getCsaRefinementGroup,
  getTypeRefinementGroup,
} from './Filtering/filterUtils'

/** Provides data for the shop sidebar and the mobile equivalent component */
export const useShopSidebarData = () => {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()
  const { categoryFilter, typeFilter, products } = useContext(AlgoliaFarmDataContext)
  const { params } = useRoute<RouteProp<ShoppingStackParamList, 'FarmShop'>>()

  /** Whether we are on the csa screen or on the shop screen */
  const isCsaScreen = useComponentRoute()?.name === 'CSADetails'

  const {
    farm: { data: farm },
    csas: { data: csas },
  } = useContext(FarmDataContext)

  const onButtonPress = useCallback(
    (item: ArrElement<ProductFilterGroup['items']>) => {
      if (item.filterKey === 'csaId') {
        if (!farm) return
        return navigation.navigate('CSADetails', { farmSlug: farm.urlSafeSlug, csaId: item.value })
      }

      // On toggle or `All` press, reset filter
      const value = item.shouldClear || params[item.filterKey] === item.value ? undefined : item.value

      navigation.setParams({ [item.filterKey]: value })
    },
    [farm, navigation, params],
  )

  /** Data for the UI */
  const filterGroups = useMemo<ProductFilterGroup[]>(() => {
    const catGroup = getCategoryRefinementGroup(categoryFilter.items)

    if (!isCsaScreen) {
      return [catGroup, getCsaRefinementGroup(csas, products)].filter(isNonNullish)
    }
    /** Return both categories and types if on the csa page */
    const typeGroup = getTypeRefinementGroup(typeFilter.items)

    return [catGroup, typeGroup].filter(isNonNullish)
  }, [categoryFilter.items, isCsaScreen, typeFilter.items, csas, products])

  return {
    filterGroups,
    onButtonPress,
  }
}
