import { Optional } from '@helpers/typescript'
import { FormikErrors } from 'formik'

import { isTruthy } from '@helpers/helpers'
import { getState } from '../assets/data/states'
import { Coordinate } from './Coordinate'

export type Address = {
  coordinate: Coordinate
  street1: string
  street2?: string
  city: string
  state: string
  zipcode: string
  country?: string
}

/** Zip code 5-digit literal string type */
export type ShortZip = `${number}${number}${number}${number}${number}`

/** Will indicate whether an address is valid, without considering its coordinate */
export function isValidAddress(
  address?: Optional<Address, 'coordinate'>,
  opts?: ValidateAddressOpts,
): address is Address {
  return !!address && !validateAddress(address, opts)
}

export type ValidateAddressOpts = {
  /** Whether PO box addresses are allowed */
  allowPO?: boolean
}

/** Will validate an address except for its coordinate.
 * - If it returns void, means there were no errors.
 * - This validator does not geocode, so it doesn't validate that the address corresponds to a real place.
 */
export function validateAddress(
  address: Optional<Address, 'coordinate'>,
  { allowPO = false }: ValidateAddressOpts = { allowPO: false },
): FormikErrors<Omit<Address, 'coordinate'>> | void {
  const errors: FormikErrors<Omit<Address, 'coordinate'>> = {}

  if (address.street1.trim().length === 0) errors.street1 = 'Street1 is required'
  if (!allowPO && isPO(address)) errors.street1 = 'Cannot use a PO box'

  if (address.city.trim().length === 0) errors.city = 'City is required'
  if (address.state.trim().length < 2) errors.state = 'State must have at least 2 letters'
  else if (!getState(address.state)) errors.state = 'State is not a valid state'
  if (address.zipcode.trim().length !== 5) errors.zipcode = 'Zip code must have 5 characters'
  if (
    address.zipcode
      .trim()
      .split('')
      .some((char) => isNaN(parseInt(char, 10)))
  )
    errors.zipcode = 'Zip code must have only digits'
  if (Object.values(errors).some(isTruthy)) return errors
}

/** Identifies a po box address */
export const isPO = (address: Pick<Address, 'street1'>): boolean => {
  /** https://stackoverflow.com/a/25271289 */
  const pattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i,
  )

  return pattern.test(address.street1)
}
