import { addCoupon, archiveCoupon, updateCoupon } from '@api/Coupons'
import { FormBuilder } from '@components'
import {
  Alert,
  ButtonClear,
  CheckBox,
  Divider,
  ErrorText,
  FormButton,
  FormInput,
  Icon,
  KeyboardAvoidingScrollView,
  MoneyInput,
  TextH2,
  Toast,
  hideModal,
} from '@elements'
import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { errorToString } from '@helpers/helpers'
import { Coupon, CouponType, isFixedCoupon, isPercentCoupon } from '@models/Coupon'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'

import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import DecimalCalc from '@helpers/decimal'
import { Money } from '@models/Money'
import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { adminFarmIdSelector } from '../../../redux/selectors'
import { RadioButton } from '../Schedules/components/RadioButton'
import { CategoriesSelection } from './components/CategoriesSelection'
import { ProducersSelection } from './components/ProducersSelection'

export type CouponFormType = Omit<Coupon, 'id' | 'farm' | 'timesRedeemed' | 'archived'>

const initialCouponValue = (coupon?: Coupon) => {
  // This allows the default to be blank instead of 0
  if (!coupon) return undefined as any
  if (isPercentCoupon(coupon)) return DecimalCalc.multiply(coupon.value, 100)
  return coupon.value
}

export const couponValidation = Yup.object<CouponFormType>().shape({
  name: Yup.string().trim().label('Name').required(),
  type: Yup.mixed<CouponType>().label('Coupon Type').required().oneOf([CouponType.Fixed, CouponType.Percent]),
  value: Yup.mixed<number | Money>().when('type', {
    is: CouponType.Percent,
    then: () => YUP_WHOLE_NUMBER_REAL('Value', { allowDecimal: true }).max(100, 'Cannot be greater than 100%'),
    otherwise: () => YUP_MONEY_REQUIRED('Value'),
  }),
  ebtOnly: Yup.boolean().label('EBT Only'),
  categories: Yup.array().of(Yup.string()),
  producers: Yup.array().of(Yup.string()),
})

export default function AddEditCoupon({
  coupon,
  onAdded,
  onUpdate,
}: {
  coupon?: Coupon
  onAdded?: (coupon: Coupon) => void
  onUpdate?: (coupon: Coupon) => void
}) {
  const [error, setError] = useState('')
  const farmId = useSelector(adminFarmIdSelector)
  const [isArchiving, setIsArchiving] = useState(false)
  const isEdit = !!coupon?.id

  const initialValues: CouponFormType = {
    name: coupon?.name ?? '',
    type: coupon?.type ?? CouponType.Percent,
    value: initialCouponValue(coupon),
    ebtOnly: coupon?.ebtOnly ?? false,
    categories: coupon?.categories ?? [],
    producers: coupon?.producers ?? [],
  }

  const onSubmitHandler = async (values: CouponFormType) => {
    if (error) setError('')
    if (!farmId) return setError('Could not load your farm, please reload and try again.')

    const newCoupon: Coupon = {
      ...values,
      /** Convert percent to decimal value and we use FormInput to allow unlimited decimal, so the value will be string and we have to cast it as number. */
      value: isPercentCoupon(values) ? DecimalCalc.divide(parseFloat(values.value.toString()), 100) : values.value,
      id: coupon?.id || '',
      farm: {
        id: farmId,
      },
      timesRedeemed: coupon?.timesRedeemed ?? 0,
      archived: coupon?.archived,
    }

    try {
      if (coupon?.id) {
        // updating a coupon
        await updateCoupon(newCoupon)
        Toast('This coupon has been updated successfully')
        onUpdate?.(newCoupon)
      } else {
        // adding a new coupon
        const addedCoupon = await addCoupon(newCoupon)
        Toast('This coupon has been added successfully')
        onAdded?.(addedCoupon)
      }
      hideModal()
    } catch (e) {
      Logger.warn(e)
      setError(`Unable to add coupon: ${errorToString(e)} `)
    }
  }

  const _archiveCoupon = async () => {
    if (!coupon)
      return Alert('Unable to archive coupon', 'This coupon does not exist or failed to load, please try again.')

    try {
      setIsArchiving(true)
      await archiveCoupon(coupon)
      onUpdate?.({ ...coupon, archived: true })
      setIsArchiving(false)
      Toast('This coupon has been archived')
      hideModal()
    } catch (e) {
      Logger.warn(e)
      setError(`Unable to archive coupon: ${errorToString(e)} `)
      setIsArchiving(false)
    }
  }

  // Will change the coupon type, additional closure for passing directly to radio return
  const changeCouponType = useCallback(
    (setFieldValue: FormikProps<CouponFormType>['setFieldValue'], newValue: CouponType) => () => {
      // We cannot change the coupon type after it has been created
      if (isEdit) return

      setFieldValue('type', newValue)
      // On change we should clear the value as it will be a different type
      setFieldValue('value', undefined)
    },
    [isEdit],
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={couponValidation}>
      {({
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }: FormikProps<CouponFormType>) => (
        <KeyboardAvoidingScrollView contentContainerStyle={styles.container}>
          <FormBuilder>
            <Input
              onChangeText={handleChange('name')}
              onBlur={handleBlur('name')}
              value={values.name}
              placeholder="Coupon Name"
              errorMessage={touched.name ? errors.name : ''}
            />
            <TextH2>Coupon Type</TextH2>
            {isEdit && (
              // Extra view to prevent styling from being overwritten from FormBuilder
              <View>
                <ErrorText>Coupon type and amount cannot be changed after it is created.</ErrorText>
              </View>
            )}
            <RadioButton
              value="Percentage discount"
              isSelected={values.type === CouponType.Percent}
              disabled={isEdit}
              onSelect={changeCouponType(setFieldValue, CouponType.Percent)}
            />
            <RadioButton
              value="Fixed amount discount"
              isSelected={values.type === CouponType.Fixed}
              disabled={isEdit}
              onSelect={changeCouponType(setFieldValue, CouponType.Fixed)}
            />
            <Divider clear />
            {isPercentCoupon(values) ? (
              <FormInput
                disabled={isEdit}
                placeholder="20"
                keyboardType="decimal-pad"
                label="Percentage off"
                value={values.value ? values.value.toString() : undefined}
                onChangeText={(val) => setFieldValue('value', val)}
                onBlur={handleBlur('value')}
                rightIcon={<Icon name="percent" />}
                errorMessage={touched.value ? errors.value : ''}
              />
            ) : isFixedCoupon(values) ? (
              <MoneyInput
                disabled={isEdit}
                label="Discount amount"
                maxLength={11}
                value={values.value}
                onChangeText={(val) => setFieldValue('value', val)}
                onBlur={handleBlur('value')}
                errorMessage={touched.value ? errors.value : ''}
              />
            ) : null}
            {isPercentCoupon(values) && (
              <CheckBox
                style={styles.inputPadding}
                checked={!!values.ebtOnly}
                onChange={(val) => setFieldValue('ebtOnly', val)}
                title="Only apply this discount to items purchased with EBT"
              />
            )}
          </FormBuilder>
          <CategoriesSelection />
          <ProducersSelection />
          <View style={globalStyles.flexRowCenter}>
            {isEdit && (
              <ButtonClear
                disabled={isSubmitting}
                loading={isArchiving}
                style={globalStyles.flex1}
                title="Archive Coupon"
                color={Colors.red}
                onPress={_archiveCoupon}
              />
            )}
            <FormButton
              loading={isSubmitting}
              disabled={isArchiving}
              style={globalStyles.flex1}
              title="Save Coupon"
              onPress={handleSubmit}
            />
          </View>

          {!!error && <ErrorText>{error}</ErrorText>}
        </KeyboardAvoidingScrollView>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
  maskedInput: {
    fontSize: 20,
    margin: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['300'],
  },
  error: { color: Colors.red, marginBottom: 5 },
  inputPadding: {
    marginVertical: 10,
  },
})
