import { loadCSAsByFarm } from '@api/CSAs'
import { loadFarmCategories } from '@api/Categories'
import { uploadImageAsync } from '@api/FirebaseStorage'
import { loadTemplateProductById, loadTemplateProductsByFarmId } from '@api/TemplateProduct'
import { FormBuilder, ToolTips } from '@components'
import {
  AddNewDropdownItem,
  Alert,
  Button,
  ButtonClear,
  CheckBox,
  ErrorText,
  FormPicker,
  FormPickerInput,
  LoadingView,
  Spinner,
  Text,
  TextH3,
  ToggleButton,
  Tooltip,
} from '@elements'
import { sortByName } from '@helpers/sorting'
import { PartialPick } from '@helpers/typescript'
import { CSA } from '@models/CSA'
import {
  BaseProduct,
  EbtEligibility,
  Product,
  ProductType,
  StandardBase,
  TemplateProduct,
  hasUnits,
  isDigital,
  isPhysical,
  isShare,
  isStandard,
} from '@models/Product'
import { Media } from '@models/shared/Media'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useFormikContext } from 'formik'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { FlatList, StyleSheet, View, ViewStyle } from 'react-native'
import { Input } from 'react-native-elements'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'
import sha1 from 'sha1'
import * as Yup from 'yup'

import { Logger } from '../../../../config/logger'
import { AdminDrawerParamList, AdminProductsParamList } from '../../../navigation/types'
import FormSectionHeader from '../components/FormSectionHeader'
import { createUnitOrSetValue } from './helpers/baseUnitAdd.helper'

import InputLabel from '@/admin/components/InputLabel'
import { ShortState, getShortState } from '@/assets/data/states'
import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { UseApiFxReturn, useApiFx } from '@/hooks/useApiFx'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useDeviceSize } from '@/hooks/useLayout'
import { adminFarmSelector, adminParamsSelector } from '@/redux/selectors'
import { loadFarmProducers } from '@api/Producers'
import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { nonEmptyString } from '@helpers/helpers'
import { getReadableEbtEligibility } from '@helpers/products-display'
import { ShortZip } from '@models/Address'
import { Farm } from '@models/Farm'
import { RegionType } from '@models/Location'
import { Money } from '@models/Money'
import { FeeType, FeeValueType, NoneValue, ProductFee } from '@models/ProductFee'
import { PickerItemProps } from '@react-native-picker/picker'
import { PhotoSection } from './PhotoSection'
import { ProductFeeSection } from './ProductFeeSection'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'

// Array of picker items for EBT eligibility. This array contains objects with `label` and `value` properties representing different EBT eligibility options.
const productsDisplay: PickerItemProps<EbtEligibility>[] = Object.values(EbtEligibility).map((value) => ({
  label: getReadableEbtEligibility(value),
  value,
}))

export type BasicInfoFormType = {
  name?: string
  sku?: string
  shortDescription?: string
  longDescription?: string
  category?: string
  producer?: string
  media?: Media[]
  csas?: string[]
  ebtEligibility?: EbtEligibility
  isDiscountEligible?: boolean
  shareInfo?: {
    minimumNumberOfItems?: number
    maximumNumberOfItems?: number
    suggestedFamilySize?: number
  }
  isChild?: boolean
  templateProductId?: string
  templateProductName?: string
  hasTemplate?: boolean
  vacationWeeks?: number
  productionMethod?: string
  hideFromShop?: StandardBase['hideFromShop']
  isTaxExempt?: BaseProduct['taxesAndFees']['isTaxExempt']
  fees?: BaseProduct['taxesAndFees']['fees']
}

// Component for displaying not editable input fields
const NonInput = memo(function NonInput({ style, label, text }: { style?: ViewStyle; label: string; text: string }) {
  return (
    <View style={[stylesNonInput.container, style]}>
      <InputLabel label={label} style={stylesNonInput.label} />
      <TextH3 size={14} style={stylesNonInput.text}>
        {text}
      </TextH3>
    </View>
  )
})

const stylesNonInput = StyleSheet.create({
  container: { marginBottom: 5 },
  label: { marginBottom: 10, marginHorizontal: 10 },
  text: { marginBottom: 30, paddingHorizontal: 15 },
})

// Props for the TemplateSection component
type TemplateSectionProps = {
  childCheckbox: () => void
  templateUsed: boolean
  pickTemplate: (value: string) => void
  navTemplateEdit: () => void
  templates: UseApiFxReturn<(farmId: string) => Promise<TemplateProduct[]>>
}

//Component to handle the template checkbox and select templates functionality
const TemplateSection: FC<TemplateSectionProps> = memo(function TemplateSection({
  childCheckbox,
  templateUsed,
  pickTemplate,
  navTemplateEdit,
  templates,
}) {
  const { values, errors, touched } = useFormikContext<BasicInfoFormType>()
  const styles = useStyles()
  return (
    <View style={globalStyles.padding10}>
      <CheckBox
        style={styles.templateChildCheckbox}
        checked={!!values.isChild}
        onChange={childCheckbox}
        title="Use a template"
        toolTipId={ToolTips.TEMPLATE}
        toolTipTitle="The Template"
      />
      {values.isChild && !!touched.isChild && !!errors.isChild && (
        <View style={globalStyles.margin10}>
          <ErrorText>{errors.isChild as string}</ErrorText>
        </View>
      )}
      {!!values.isChild && (
        <FormPicker
          style={globalStyles.margin10}
          value={values.templateProductId}
          placeholder={{
            label: !templateUsed ? 'Select an Option' : "Don't use template",
            value: '',
          }}
          items={
            templates.data
              ?.sort((a, b) => sortByName(a, b, (val) => val.name))
              .map((template) => ({ label: template.name, value: template.id, key: template.id }))
              .concat({ label: 'Add New +', value: 'add new', key: 'Add New' }) ?? [
              { label: 'No template - Add New', value: 'add new' },
            ]
          }
          onValueChange={pickTemplate}
        />
      )}
      {templateUsed && (
        <Button title="Edit Share Template" style={styles.editShareTemButton} outline onPress={navTemplateEdit} />
      )}
    </View>
  )
})

/**
 * true,
 * All products which do not implement the template
 * false,
 * share products which have implemented the template or going to implement template
 */
const isNotTemplateChild = (isChild: boolean, type: ProductType, templateProductId: string) =>
  !(isChild && templateProductId && isShare({ type }))

const basicInfoValidation: Yup.ObjectSchema<BasicInfoFormType> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .label('Name')
    .min(3, 'Name must be at least 3 characters')
    .test('is-valid-name', 'Name must contain at least three consecutive letters', (value) => {
      if (!value) return false
      // Require at least 3 consecutive characters
      return !!value.match(/[a-zA-Z]{3,}/)
    })
    .required(),
  shortDescription: Yup.string()
    .trim()
    .label('Short Description')
    .when(['isChild', 'type', 'templateProductId'], {
      is: isNotTemplateChild,
      then: (schema) => schema.required(),
    }),
  longDescription: Yup.string()
    .trim()
    .label('Long Description')
    .when(['isChild', 'type', 'templateProductId'], {
      is: isNotTemplateChild,
      then: (schema) => schema.required(),
    }),
  category: Yup.string()
    .trim()
    .label('Category')
    .when(['isChild', 'type', 'templateProductId'], {
      is: isNotTemplateChild,
      then: (schema) => schema.required(),
    }),
  producer: Yup.string().trim().label('Producer'),
  media: Yup.array<Media>()
    .label('Images')
    .when(['isChild', 'type', 'templateProductId'], {
      is: isNotTemplateChild,
      then: (schema) => schema.required().min(1, 'Must upload at least one image'),
    }),
  csas: Yup.array()
    .label('CSAs')
    .when('type', {
      is: (type: ProductType) => isShare({ type }),
      then: (schema) =>
        schema.test(
          'is-csa-array-non-empty',
          'Must select at least one CSA that this share is available at.',
          (csas) => !!csas && csas.length > 0,
        ),
    }),
  vacationWeeks: Yup.number()
    .label('Vacation Weeks')
    .when('type', {
      is: (type: ProductType) => isShare({ type }),
      then: () => YUP_WHOLE_NUMBER_REAL('Vacation Weeks', { allowZero: true }),
    }),
  ebtEligibility: Yup.mixed<EbtEligibility>().label('SNAP/EBT Eligibility').oneOf(Object.values(EbtEligibility)),
  isDiscountEligible: Yup.boolean().label('Discount Eligible'),
  shareInfo: Yup.object({
    minimumNumberOfItems: Yup.number().typeError('Must be a number and can not left empty.'),
    maximumNumberOfItems: Yup.number().typeError('Must be a number and can not left empty.'),
    suggestedFamilySize: Yup.number().typeError('Must be a number and can not left empty.'),
  }),
  isChild: Yup.boolean()
    .label('Child Product')
    .when('templateProductId', {
      is: (templateProductId: string) => !templateProductId,
      then: (schema) => schema.equals([false], 'You have to choose a template'),
    }),
  templateProductId: Yup.string().label('Template Product'),
  templateProductName: Yup.string().label('Template Product Name'),
  sku: Yup.string().label('SKU'),
  productionMethod: Yup.string().label('Production Method'),
  hideFromShop: Yup.boolean().test(
    'csa-required-when-hideFromShop',
    'If "Only show on CSA" is active, you must select at least one CSA',
    function (value) {
      const { csas } = this.parent

      // If hideFromShop is true, ensure at least one CSA is selected
      if (value) {
        return csas && csas.length > 0
      }

      // If hideFromShop is false, the validation automatically passes
      return true
    },
  ),
  isTaxExempt: Yup.boolean()
    .label('Tax Exempt')
    .test('Validate Tax Exempt', 'Tax Exempt products cannot have taxes', function (value) {
      const { fees } = this.parent
      if (value && fees) {
        return fees.every((fee: ProductFee) => fee.type === FeeType.Fee)
      }
      return true
    }),
  fees: Yup.array<ProductFee>()
    .label('Product Fees')
    .of(
      Yup.object<ProductFee>().shape({
        id: Yup.string().required(),
        name: Yup.string().trim().required(),
        farm: Yup.object<Pick<Farm, 'id'>>()
          .shape({
            id: Yup.string().required(),
          })
          .required(),
        type: Yup.mixed<FeeType>().required().oneOf([FeeType.Tax, FeeType.Fee]),
        /** The value should be strict with valueType */
        value: Yup.mixed<number | Money>()
          .required()
          .when('valueType', {
            is: FeeValueType.Percent,
            then: (_) => YUP_WHOLE_NUMBER_REAL('Value', { allowDecimal: true }).max(1, 'Cannot be greater than 1'),
            otherwise: (_) => YUP_MONEY_REQUIRED('Value'),
          }),
        valueType: Yup.mixed<FeeValueType>().required().oneOf([FeeValueType.Fixed, FeeValueType.Percent]),
        regionType: Yup.mixed<ProductFee['regionType']>()
          .required()
          .oneOf([RegionType.State, RegionType.Zipcode, NoneValue]),
        regions: Yup.array<ShortState[] | ShortZip[]>()
          .required()
          .when('regionType', {
            is: (regionType: RegionType) => regionType === RegionType.State,
            then: (schema) =>
              schema.test('Valid state', 'State must be a valid state code', (v?: ShortState[]) => {
                if (!Array.isArray(v)) {
                  return false
                }
                // Check each element in the array
                return v.length === 0 || v.every((s) => nonEmptyString(s) && !!getShortState(s))
              }),
            otherwise: (schema) =>
              schema.when('regionType', {
                is: (regionType: RegionType) => regionType === RegionType.Zipcode,
                then: (schema) =>
                  schema.test('5 digits', 'Zip code must be 5 digits', (v?: ShortZip[]) => {
                    if (!Array.isArray(v)) {
                      return false
                    }
                    // Check each element in the array
                    return v.length === 0 || v.every((zip: string) => !!zip && zip.length === 5)
                  }),
                // Default validation schema when 'regionType' is not State or Zipcode
                otherwise: (schema) => schema.default([]),
              }),
          }),
        archived: Yup.boolean().required(),
      }),
    ),
  hasTemplate: Yup.boolean().optional(),
})

const toFormik = (product: PartialPick<Product, 'type'>): BasicInfoFormType => {
  const base: BasicInfoFormType = {
    name: product.name || '',
    shortDescription: product.description || '',
    longDescription: product.longDescription || '',
    category: product.category || '',
    producer: product.producer || '',
    productionMethod: product.productionMethod || '',
    media:
      product.images?.map((img) => ({
        storageUrl: img,
        type: 'image',
      })) || ([] as Media[]),
    csas: product.csa || [],
    hideFromShop: hasUnits(product) ? product.hideFromShop : undefined,
    /** Note: isTaxExempt by default should be true for now, so all products are now isTaxExempts until further changes.  */
    isTaxExempt: product.taxesAndFees?.isTaxExempt ?? true,
    fees: product.taxesAndFees?.fees || [],
  }

  if (isStandard(product)) {
    base.ebtEligibility = product.ebtEligibility
  }

  //sku here is skuPrefix when product is standard and digital
  if (hasUnits(product)) {
    base.sku = product.unitSkuPrefix || ''
  }

  if (isShare(product)) {
    base.shareInfo = {
      minimumNumberOfItems: Number(product.shareInfo?.minimumNumberOfItems) || 0,
      maximumNumberOfItems: Number(product.shareInfo?.maximumNumberOfItems) || 0,
      suggestedFamilySize: Number(product.shareInfo?.suggestedFamilySize) || 0,
    }
    base.isChild = product.isChild || false
    base.templateProductId = product.templateProduct?.id || ''
    base.templateProductName = product.templateProduct?.name || ''
    base.vacationWeeks = product.vacationWeeks
    base.sku = product.sku || ''
  }

  return base
}

async function fromFormik<T extends BasicInfoFormType & { type?: ProductType }>(values: T): Promise<Partial<Product>> {
  const base: PartialPick<Product, 'type'> = {
    type: values.type!,
    name: values.name,
    description: values.shortDescription,
    longDescription: values.longDescription,
    category: values.category,
    csa: values.csas || [],
    taxesAndFees: {
      isTaxExempt: values.isTaxExempt!,
      fees: values.fees,
    },
  }

  if (isPhysical(base)) {
    base.producer = values.producer
    base.productionMethod = values.productionMethod
  }

  if (isStandard(base)) {
    base.ebtEligibility = values.ebtEligibility ?? EbtEligibility.NotEbtEligible
  }

  // When product is standard and digital, sku is skuPrefix and should be saved as unitSkuPrefix
  if (hasUnits(base)) {
    base.unitSkuPrefix = values.sku || ''
    base.hideFromShop = values.hideFromShop
  }

  if (isShare(base)) {
    base.shareInfo = Object.fromEntries(
      Object.entries(values.shareInfo ?? {}).filter(([_, value]) => Number(value) !== 0),
    )
    base.isChild = values.isChild
    /** Cast it as number because Input value is string */
    base.vacationWeeks = Number(values.vacationWeeks!)
    base.sku = values.sku // the sku here is for share
    base.templateProduct = { id: '', name: '' }
  }

  // If product applies a template, get the template id and template name and set them to the product
  if (values.templateProductId && values.isChild && isShare(base)) {
    base.templateProduct = { id: values.templateProductId, name: values.templateProductName || '' }
  }

  if (values.media) {
    try {
      values.media = await Promise.all(
        values.media.map((m: Media) => {
          // Check if image is already hosted
          if (m.storageUrl.includes('http://') || m.storageUrl.includes('https://')) return m
          else return uploadImageAsync(sha1(m.storageUrl), m)
        }),
      )
      base.images = values.media.map((m) => m.storageUrl)
    } catch (err) {
      Alert('Error saving images', 'There was an error saving your images. Please try again.')
      Logger.error('Error saving images', err)
    }
  }

  return base as Partial<Product>
}

export const FormikBasicInformation = new ProductFormikComponent(basicInfoValidation, toFormik, fromFormik)

export default function BasicInformationComponent({ type }: { type: ProductType }) {
  const { isSmallDevice, isLargeDevice } = useDeviceSize()
  const navigation = useNavigation<StackNavigationProp<AdminProductsParamList, 'AddProduct' | 'EditProduct'>>()
  const { handleChange, values, errors, handleBlur, touched, setFieldValue, setTouched } =
    useFormikContext<BasicInfoFormType>()
  const farm = useSelector(adminFarmSelector)

  /** CSAs should be re-fetched on every re-focus to ensure this alwasy has the latest csa data. This should not filter by isHidden, because a product associated with a hidden csa should still show the selected one */
  const csasFx = useApiFx(loadCSAsByFarm, [farm.id])

  const categoriesFx = useApiFx(loadFarmCategories, [farm.localCategories], undefined, { noRefocus: true })
  const templatesFx = useApiFx(loadTemplateProductsByFarmId, [farm.id])
  const producers = useMemo(() => loadFarmProducers(farm.localProducers), [farm.localProducers])
  const { product } = useSelector(adminParamsSelector)
  const [templateLoading, setTemplateLoading] = useState(false)
  const styles = useStyles()

  //When there is no template selected, reset the fields that are controlled by the template
  const resetTemplateFields = useCallback(() => {
    setFieldValue('templateProductId', '')
    setFieldValue('shareInfo.maximumNumberOfItems', 0)
    setFieldValue('shareInfo.minimumNumberOfItems', 0)
    setFieldValue('shareInfo.suggestedFamilySize', 0)
    setFieldValue('templateProductName', '')
    setFieldValue('longDescription', '')
    setFieldValue('shortDescription', '')
    setFieldValue('category', '')
    setFieldValue('media', [])
    setFieldValue('sku', '')
    setFieldValue('producer', '')
    setFieldValue('productionMethod', '')

    //Implementing timeout here is to help better user experience when resetting the fields. It means not selecting a template anymore.
    setTimeout(() => {
      setTemplateLoading(false)
    }, 1500)
  }, [setFieldValue])

  useFocusFx(() => {
    // If templateProductId is not empty, should set belows fields to formik values from a template product
    if (values.templateProductId) {
      setTemplateLoading(true)
      loadTemplateProductById(values.templateProductId).then((t) => {
        if (t) {
          setFieldValue('shareInfo.maximumNumberOfItems', t.shareInfo.maximumNumberOfItems)
          setFieldValue('shareInfo.minimumNumberOfItems', t.shareInfo.minimumNumberOfItems)
          setFieldValue('shareInfo.suggestedFamilySize', t.shareInfo.suggestedFamilySize)
          setFieldValue('templateProductName', t.name)
          setFieldValue('longDescription', t.longDescription)
          setFieldValue('shortDescription', t.description)
          setFieldValue('category', t.category)
          setFieldValue(
            'media',
            t.images.map((img) => ({
              storageUrl: img,
              type: 'image',
            })) || ([] as Media[]),
          )
          setFieldValue('sku', t.sku)
          setFieldValue('producer', t.producer)
          setFieldValue('productionMethod', t.productionMethod)
        }
        //Implementing timeout here is to help better user experience when resetting the fields.
        setTimeout(() => {
          setTemplateLoading(false)
        }, 500)
      })
    }

    // Resetting template product fields group if hasTemplate is true and isChild is false (This case is for when the user doesn't want to use a template on a share anymore.)
    if (!values.isChild && values.hasTemplate) {
      resetTemplateFields()
    }
  }, [values.templateProductId, values.isChild, values.hasTemplate, setFieldValue, resetTemplateFields])

  //CSA checkboxes handler
  const checkboxOnClick = useCallback(
    (item: CSA) => {
      if (values.csas?.includes(item.id)) {
        setFieldValue(
          'csas',
          values.csas?.filter((id) => id !== item.id),
        )
      } else {
        setFieldValue('csas', [...(values.csas || []), item.id])
      }
    },
    [setFieldValue, values.csas],
  )

  //Handle pick a template or create a new template (template if for share products)
  const pickTemplate = useCallback(
    (value: string) => {
      if (!value) {
        setTemplateLoading(true)
        setFieldValue('templateProductId', '')
        resetTemplateFields()
      }
      if (value !== 'add new') {
        setFieldValue('templateProductId', value)
      }
      if (value === 'add new') {
        navigation.replace('AddTemplate')
      }
    },
    [navigation, resetTemplateFields, setFieldValue],
  )

  //This helper handles the checkbox for isChild template checkbox
  const childCheckbox = useCallback(() => {
    // Should reset data when " unSelecting the checkbox and currently has a template selected ""
    if (values.isChild && values.templateProductId) {
      setTemplateLoading(true)
      resetTemplateFields()
    }
    if (values.isChild) setFieldValue('templateProductId', '')
    setFieldValue('isChild', !values.isChild)
    setTouched({ ...touched, isChild: true })
  }, [resetTemplateFields, setFieldValue, setTouched, touched, values.isChild, values.templateProductId])

  //This helper handles navigate to edit template screen
  const navTemplateEdit = useCallback(() => {
    if (values.templateProductId)
      navigation.replace('EditTemplate', { templateId: values.templateProductId, prodId: product?.id })
  }, [navigation, product?.id, values.templateProductId])

  // Category dropdown picker handler
  const categoryOnChange = useCallback(
    (value: string) => {
      setTouched({ ...touched, category: true })
      createUnitOrSetValue(value, 'category', farm.id, (v) => setFieldValue('category', v))
    },
    [farm.id, setFieldValue, setTouched, touched],
  )

  // Producer dropdown picker handler
  const producerOnChange = useCallback(
    (value: string) => {
      if (value !== values.producer) setTouched({ ...touched, producer: true })
      createUnitOrSetValue(value, 'producer', farm.id, (v) => setFieldValue('producer', v))
    },
    [farm.id, setFieldValue, setTouched, touched, values.producer],
  )

  // CSA dropdown picker handler
  const csaOnChange = useCallback(
    async (value: string) => {
      if (!value) {
        return await setFieldValue('csas', [] as string[])
      }
      await setFieldValue('csas', [value] as string[])
      await setTouched({ ...touched, csas: true })
    },
    [setFieldValue, setTouched, touched],
  )

  const ebtEligibilityOnChange = useCallback(
    (value: string) => {
      if (value !== values.ebtEligibility) setTouched({ ...touched, ebtEligibility: true })
      setFieldValue('ebtEligibility', value)
    },
    [setFieldValue, setTouched, touched, values.ebtEligibility],
  )

  /**
   * templateUsed is a value to tell if the template is currently applied or not no matter adding product or editing product
   * This also can be used to decide if only showing plain texts or showing dropdown picker or any Inputs
   */
  const templateUsed = useMemo(() => {
    return !!(values.isChild && values.templateProductId)
  }, [values.isChild, values.templateProductId])

  if (templateLoading) return <Spinner size="small" style={{ marginTop: 20 }} />

  return (
    <View style={styles.container}>
      <View style={styles.basicSection}>
        <FormSectionHeader title="Basic Information" />
        {/* Allow a share product to use or change a template when the share product already exists and not isLargeDevice */}
        {isShare({ type }) && !isLargeDevice && (
          <TemplateSection
            templateUsed={templateUsed}
            templates={templatesFx}
            childCheckbox={childCheckbox}
            navTemplateEdit={navTemplateEdit}
            pickTemplate={pickTemplate}
          />
        )}
        <FormBuilder style={styles.inputs}>
          {templateUsed && (
            <Input
              value={values.name}
              placeholder="What are you selling?"
              label={<InputLabel label="Farm Shop Product Name" tooltipId={ToolTips.BASIC_NAME} required />}
              onChangeText={handleChange('name')}
              onBlur={handleBlur('name')}
              errorMessage={touched.name ? errors.name : ''}
            />
          )}
          <FormBuilder row={!isSmallDevice}>
            {!templateUsed && (
              <Input
                value={values.name}
                placeholder="What are you selling?"
                label={<InputLabel label="Product Name" tooltipId={ToolTips.BASIC_NAME} required />}
                onChangeText={handleChange('name')}
                onBlur={handleBlur('name')}
                errorMessage={touched.name ? errors.name : ''}
              />
            )}
            {!templateUsed && type !== ProductType.FarmBalance && (
              <Input
                value={values.sku}
                label={<InputLabel label={isShare({ type }) ? 'SKU' : 'SKU-Prefix'} tooltipId={ToolTips.OPTION_SKU} />}
                placeholder={isShare({ type }) ? 'SKU' : 'SKU-Prefix'}
                onChangeText={handleChange('sku')}
                onBlur={handleBlur('sku')}
                errorMessage={touched.sku ? errors.sku : ''}
              />
            )}

            {templateUsed && (
              <>
                <NonInput
                  style={globalStyles.flex1}
                  label="Template Product Name"
                  text={values.templateProductName ?? ''}
                />
                <NonInput style={globalStyles.flex1} label="SKU" text={values.sku ?? ''} />
              </>
            )}
          </FormBuilder>
          {!templateUsed && (
            <Input
              value={values.shortDescription}
              label={<InputLabel label="Short Description" tooltipId={ToolTips.SHORTDESCRIPTION} required />}
              placeholder="Short Description"
              onChangeText={handleChange('shortDescription')}
              onBlur={handleBlur('shortDescription')}
              errorMessage={touched.shortDescription ? errors.shortDescription : ''}
            />
          )}
          {!templateUsed && (
            <Input
              value={values.longDescription}
              label={<InputLabel label="Long Description" tooltipId={ToolTips.LONGDESCRIPTION} required />}
              placeholder="A detailed description of the product"
              onChangeText={handleChange('longDescription')}
              onBlur={handleBlur('longDescription')}
              errorMessage={touched.longDescription ? errors.longDescription : ''}
              numberOfLines={isLargeDevice ? 4 : 2}
              multiline
              style={{ height: 100 }}
            />
          )}
          {templateUsed && (
            <>
              <NonInput label="Short Description" text={values.shortDescription ?? ''} />
              <NonInput label="Long Description" text={values.longDescription ?? ''} />
            </>
          )}
          {!templateUsed && isPhysical({ type }) && (
            <Input
              value={values.productionMethod?.toString() || ''}
              label="Production Method"
              placeholder="Organic, Conventional, Grassfed, etc"
              onChangeText={handleChange('productionMethod')}
              onBlur={handleBlur('productionMethod')}
              errorMessage={touched.productionMethod ? errors.productionMethod : ''}
            />
          )}
          {templateUsed && <NonInput label="Production Method" text={values.productionMethod || ''} />}

          {type !== ProductType.FarmBalance ? (
            <>
              <FormBuilder row={!isSmallDevice}>
                {!templateUsed &&
                  (!!categoriesFx.data && !categoriesFx.loading ? (
                    <FormPicker
                      minimal={!isSmallDevice}
                      onValueChange={categoryOnChange}
                      label={<InputLabel label="Category" tooltipId={ToolTips.BASIC_CATEGORY} required />}
                      value={values.category}
                      items={categoriesFx.data
                        .map((category) => ({ label: category.name, value: category.name }))
                        .concat([AddNewDropdownItem])}
                      errorMessage={touched.category ? errors.category : ''}
                    />
                  ) : (
                    <Spinner />
                  ))}
                {!templateUsed && !isDigital({ type }) && (
                  <FormPicker
                    onValueChange={producerOnChange}
                    minimal={!isSmallDevice}
                    label={<InputLabel label="Producer" tooltipId={ToolTips.BASIC_PRODUCER} />}
                    errorMessage={errors.producer}
                    value={values.producer}
                    items={
                      producers.length
                        ? producers
                            .map((producer) => ({ label: producer, value: producer }))
                            .concat([AddNewDropdownItem])
                        : [AddNewDropdownItem]
                    }
                  />
                )}
                {templateUsed && (
                  <>
                    <NonInput style={globalStyles.flex1} label="Category" text={values.category ?? ''} />
                    <NonInput style={globalStyles.flex1} label="Producer" text={values.producer ?? ''} />
                  </>
                )}
              </FormBuilder>

              {/* If it's a standard product and farm is ebt enabled, it can be managed by the user in the form UI. */}
              {isStandard({ type }) && farm.paymentTypes.ebt && (
                <FormPickerInput
                  label={<InputLabel label="SNAP/EBT Eligibility" tooltipId={ToolTips.SNAP_EBT_ELIGIBILITY} required />}
                  value={values.ebtEligibility}
                  items={productsDisplay}
                  onValueChange={ebtEligibilityOnChange}
                  errorMessage={errors.ebtEligibility}
                />
              )}
            </>
          ) : (
            <View style={{ marginLeft: 10 }}>
              <View style={{ marginTop: 10 }} />
              <InputLabel label="Category - Farm Credit" />
              <View style={{ marginTop: 10 }} />
              <InputLabel label="Unit - USD (Local currency is default)" />
            </View>
          )}

          {isShare({ type }) && (
            <FormBuilder row>
              <Input
                value={values.vacationWeeks?.toString() || ''}
                label={<InputLabel label="Vacation Weeks" required />}
                placeholder="ex. 4"
                onChangeText={handleChange('vacationWeeks')}
                onBlur={handleBlur('vacationWeeks')}
                errorMessage={touched.vacationWeeks ? errors.vacationWeeks : ''}
              />
            </FormBuilder>
          )}

          {(hasUnits({ type }) || isShare({ type })) && (
            <LoadingView loading={csasFx.loading} success={csasFx.data} error={csasFx.err}>
              {(csas) =>
                csas.length >= 1 ? (
                  /** TODO: For multiple currently selected CSAs (more than one) in existing product, we still show checkboxes and allow farmer to uncheck them because
                   * we will limit one product only has one CSA for new products. This is a temporary solution and gradual migration for now. Right now, farmers can still keep current multiple CSAs like before or uncheck them, but future products will only have one CSA. This code could be removed in the future when enough products have become single-csa over time.
                   */

                  // If we're editing an existing product, and it has multiple csa assignments, show multiple checkboxes.
                  !!product?.csa && product.csa?.length > 1 ? (
                    <FormBuilder style={globalStyles.padding10}>
                      <View style={hasUnits({ type }) ? styles.standardCSAWrapper : styles.shareCSAWrapper}>
                        <Text style={styles.subSectionTitle}>
                          Select a CSA {hasUnits({ type }) ? '(Optional)' : '*'}
                        </Text>
                        <Tooltip
                          id={hasUnits({ type }) ? ToolTips.SELECT_CSA_STANDARD : ToolTips.SELECT_CSA}
                          title="CSA"
                          style={{ marginLeft: 10 }}
                        />
                        {hasUnits({ type }) && (
                          <>
                            <View style={{ width: 30 }} />
                            <ToggleButton
                              title="Only show on CSA pages"
                              onChange={(checked) => setFieldValue('hideFromShop', checked)}
                              value={!!values.hideFromShop}
                            />
                            {!!errors.hideFromShop && <ErrorText>{errors.hideFromShop}</ErrorText>}
                          </>
                        )}
                      </View>
                      <FlatList
                        columnWrapperStyle={styles.csaContainer}
                        data={csas}
                        renderItem={({ item: csa }) => (
                          <CheckBox
                            style={{ marginLeft: 10 }}
                            title={`${csa.name}${csa.isHidden ? ' (Hidden)' : ''}`}
                            key={csa.id}
                            onChange={() => {
                              checkboxOnClick(csa)
                              setTouched({ ...touched, csas: true })
                            }}
                            checked={!!(values.csas && values.csas.find((csaId) => csaId === csa.id))}
                          />
                        )}
                        keyExtractor={(item) => item.id}
                        numColumns={3}
                      />
                      {!!touched.csas && !!errors.csas && (
                        <ErrorText style={globalStyles.padding10}>{errors.csas}</ErrorText>
                      )}
                      <ErrorText style={globalStyles.padding10}>
                        * Products can only be associated with one CSA going forward. You can remove the additional CSAs
                        now or in the future when your pickups are complete.
                      </ErrorText>
                    </FormBuilder>
                  ) : (
                    // In 'add' mode or 'edit' mode with only one CSA, the form will show single-select dropdown
                    <View style={styles.csaOption}>
                      <FormBuilder style={hasUnits({ type }) ? styles.wrapperItemStyle : globalStyles.flex1}>
                        <FormPicker
                          label={
                            <InputLabel
                              label={`Select a CSA ${hasUnits({ type }) ? '(Optional)' : '*'}`}
                              tooltipId={hasUnits({ type }) ? ToolTips.SELECT_CSA_STANDARD : ToolTips.SELECT_CSA}
                            />
                          }
                          minimal={!isSmallDevice}
                          onValueChange={csaOnChange}
                          value={values.csas?.[0]}
                          items={csas.map((csa) => ({
                            label: `${csa.name}${csa.isHidden ? ' (Hidden)' : ''}`,
                            value: csa.id,
                          }))}
                          errorMessage={touched.csas ? errors.csas : ''}
                        />
                      </FormBuilder>
                      {hasUnits({ type }) && (
                        <View style={styles.onlyShowOnCsaContainer}>
                          <ToggleButton
                            title="Only show on CSA pages"
                            onChange={(checked) => setFieldValue('hideFromShop', checked)}
                            value={!!values.hideFromShop}
                          />
                          {!!errors.hideFromShop && <ErrorText>{errors.hideFromShop}</ErrorText>}
                        </View>
                      )}
                    </View>
                  )
                ) : (
                  // In case the farm has no CSA groups, we show a link to the csa creation page
                  <FormBuilder>
                    <InputLabel
                      label={`Select a CSA ${hasUnits({ type }) ? '(Optional)' : '*'}`}
                      tooltipId={hasUnits({ type }) ? ToolTips.SELECT_CSA_STANDARD : ToolTips.SELECT_CSA}
                      style={globalStyles.padding10}
                    />
                    <ButtonClear
                      textStyle={{ textDecorationLine: 'underline' }}
                      title="Create a CSA Group"
                      onPress={() =>
                        (navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('CSAStackNav', {
                          screen: 'CSADetail',
                          params: {
                            goBack: 'AddProduct',
                          },
                        })
                      }
                    />
                    <ErrorText style={globalStyles.padding10}>
                      Please click this link and go to CSA page to create a CSA Group, then you may add it to this
                      product.
                    </ErrorText>
                  </FormBuilder>
                )
              }
            </LoadingView>
          )}

          {isShare({ type }) &&
            (!templateUsed ? (
              <FormBuilder row={!isSmallDevice}>
                <Input
                  value={values.shareInfo?.suggestedFamilySize?.toString()}
                  label={<InputLabel label="Suggested family size" />}
                  placeholder="ex. 4"
                  onChangeText={handleChange('shareInfo.suggestedFamilySize')}
                  onBlur={handleBlur('shareInfo.suggestedFamilySize')}
                  //errors.shareInfo will expect shareInfo as a string, not an object
                  //@ts-ignore
                  errorMessage={touched.shareInfo?.suggestedFamilySize ? errors.shareInfo?.suggestedFamilySize : ''}
                />
                <Input
                  value={values.shareInfo?.minimumNumberOfItems?.toString()}
                  label={<InputLabel label="Minimum items" />}
                  placeholder="ex. 8"
                  onChangeText={handleChange('shareInfo.minimumNumberOfItems')}
                  onBlur={handleBlur('shareInfo.minimumNumberOfItems')}
                  //@ts-ignore
                  errorMessage={touched.shareInfo?.minimumNumberOfItems ? errors.shareInfo?.minimumNumberOfItems : ''}
                />
                <Input
                  value={values.shareInfo?.maximumNumberOfItems?.toString()}
                  label={<InputLabel label="Maximum items" />}
                  placeholder="ex. 8"
                  onChangeText={handleChange('shareInfo.maximumNumberOfItems')}
                  onBlur={handleBlur('shareInfo.maximumNumberOfItems')}
                  //@ts-ignore
                  errorMessage={touched.shareInfo?.maximumNumberOfItems ? errors.shareInfo?.maximumNumberOfItems : ''}
                />
              </FormBuilder>
            ) : (
              <FormBuilder row={!isSmallDevice}>
                <NonInput
                  style={globalStyles.flex1}
                  label="Suggested family size"
                  text={values.shareInfo?.suggestedFamilySize?.toString() ?? '0'}
                />
                <NonInput
                  style={globalStyles.flex1}
                  label="Minimum items"
                  text={values.shareInfo?.minimumNumberOfItems?.toString() ?? '0'}
                />
                <NonInput
                  style={globalStyles.flex1}
                  label="Maximum items"
                  text={values.shareInfo?.maximumNumberOfItems?.toString() ?? '0'}
                />
              </FormBuilder>
            ))}
        </FormBuilder>
        <ProductFeeSection />
      </View>

      <FormBuilder style={styles.photosWrapper}>
        <PhotoSection
          onMediaChange={(media) => setFieldValue('media', media)}
          value={values.media ?? []}
          disabled={templateUsed}
        />
        {!!touched.media && !!errors.media && <ErrorText>{errors.media as string}</ErrorText>}
        {/* Allow a share product to use or change a template when the share product already exists and isLargeDevice */}
        {isShare({ type }) && isLargeDevice && (
          <TemplateSection
            templateUsed={templateUsed}
            templates={templatesFx}
            childCheckbox={childCheckbox}
            navTemplateEdit={navTemplateEdit}
            pickTemplate={pickTemplate}
          />
        )}
      </FormBuilder>
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      paddingHorizontal: 25,
      paddingBottom: 10,
    },
    templateChildCheckbox: {
      paddingLeft: 15,
    },
    subSectionTitle: {
      color: Colors.black,
      marginLeft: -5,
      fontSize: 16,
    },
    basicSection: {},
    inputs: {},
    photosWrapper: {},
    csaContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    csaOption: { flexDirection: 'row', justifyContent: 'space-between' },
    onlyShowOnCsaContainer: { flexDirection: 'column', justifyContent: 'flex-end' },
    editShareTemButton: {
      maxWidth: 300,
      marginHorizontal: 10,
      marginVertical: 25,
    },
    wrapperItemStyle: {
      flexDirection: 'column',
      marginLeft: 3,
      maxWidth: 299,
    },
    standardCSAWrapper: { flexDirection: 'row', marginVertical: 10, alignItems: 'center', flexWrap: 'wrap' },
    shareCSAWrapper: {
      flexDirection: 'row',
      marginBottom: 5,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      csaOption: { flexDirection: 'column' },
      onlyShowOnCsaContainer: {
        marginLeft: 14,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
      container: {
        paddingHorizontal: 0,
        paddingBottom: 0,
      },
      subSectionTitle: {
        paddingLeft: 10,
      },
      csaContainer: {},
      templateChildCheckbox: {
        paddingLeft: 10,
      },
      wrapperItemStyle: {
        maxWidth: '100%',
      },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      container: { flexDirection: 'column' },
      inputs: {
        width: '100%',
        flexDirection: 'column',
      },
    },

    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      photosWrapper: {
        flex: 0.4,
        paddingLeft: 12.5,
      },
      basicSection: {
        flex: 0.6,
        paddingRight: 12.5,
      },
    },
  },
)
