import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { isLabelAccessible } from './helpers/helpers'
import { formStyles, useDynamicFormStyles } from './helpers/styles'
import { FormSharedProps } from './helpers/types'
import { MoneyInput, MoneyInputProps } from '@elements'

type FormMoneyInputProps = FormSharedProps & MoneyInputProps

/** This input component can be used to produce consistently styled money inputs */
export function FormMoneyInput(props: FormMoneyInputProps) {
  const styles = useDynamicFormStyles(!!props?.errorMessage, !!props.row)

  return (
    <MoneyInput
      placeholderTextColor={Colors.shades['200']}
      errorStyle={formStyles.error}
      label={props.label || props.placeholder}
      accessibilityLabel={isLabelAccessible(props.label) ? props.label : props.placeholder}
      labelStyle={formStyles.inputLabel}
      inputStyle={styles.inputStyle}
      inputContainerStyle={styles.inputContainer}
      containerStyle={props.row ? globalStyles.flex1 : {}}
      keyboardType="decimal-pad"
      {...props}
    />
  )
}
