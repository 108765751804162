import { OrderItem } from '@models/Order'
import { CancellationTypes, ProductType } from '@models/Product'
import * as yup from 'yup'

export const orderItemProductSchema: yup.ObjectSchema<OrderItem['product']> = yup.object().shape({
  id: yup.string().required(),
  type: yup
    .mixed<ProductType>()
    .required()
    //@ts-expect-error
    .oneOf(['standard', 'primary', 'addon', 'digital', 'farm-balance']),
  image: yup.string().defined(),
  name: yup.string().required(),
  description: yup.string().defined(),
  vacationWeeks: yup.number().required(),
  // Only relevant for standard products (default undefined for non-standard products)
  isEbtEligible: yup.boolean(),
  cancellationPolicy: yup
    .mixed<CancellationTypes>()
    .defined()
    .nullable()
    // @ts-expect-error
    .oneOf(['flexible', 'flexible-pre', 'moderate', 'strict']),
})
