import { addProduct, overWriteProduct } from '@api/Products'
import { productsCollection } from '@api/framework/ClientCollections'
import { MessageWithIcon } from '@components'
import { Alert, LoadingView, Toast } from '@elements'
import { buildProduct } from '@helpers/builders/buildProduct'
import { errorToString, isNonNullish } from '@helpers/helpers'
import { getStock, getUnits, isPrivate } from '@helpers/products'
import { pick } from '@helpers/typescript'
import { formatToSafeSlug } from '@helpers/urlSafeSlug'
import { Farm } from '@models/Farm'
import { Product, ProductType, hasUnits, isPhysical, isShare } from '@models/Product'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { dequal } from 'dequal'
import { Formik, FormikConfig, FormikProps, yupToFormErrors } from 'formik'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AdminProductsParamList } from '../../navigation/types'
import { EditHeader } from '../Schedules/components/EditHeader'
import AdvancedPricingComponent from './ProductForm/AdvancedPricing'
import AvailabilityOverview from './ProductForm/AvailabilityOverview'
import BasicInformation from './ProductForm/BasicInformation'
import { SchedulesComponent } from './ProductForm/SchedulesComponent'
import ShareBilling from './ProductForm/ShareBillingOptions'
import SharePricing from './ProductForm/SharePricing'
import { UnitsComponent } from './ProductForm/UnitsComponent'
import {
  ProductDetailsForm,
  buildInitialValues,
  buildProductFromFormik,
  productFormSchema,
} from './ProductForm/helpers/formHelpers'
import { AddEditProductHeader } from './components/ProductHeader'

import { AdminView } from '@/admin/components/AdminView'
import { Logger } from '@/config/logger'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useDeepCompareFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { addQueueAlgoliaProduct, setAdminNav } from '@/redux/actions/adminState'
import { setNavProps } from '@/redux/actions/appState'
import { adminFarmSelector, adminParamsSelector, userSelector } from '@/redux/selectors'
import { loadCSAsByFarm } from '@api/CSAs'
import { AccessRight, Permission } from '@helpers/Permission'
import { bullet, formatShortDate } from '@helpers/display'
import { getPaymentSchedules } from '@helpers/order'
import { isBefore } from '@helpers/time'
import { User } from '@models/User'
import { isErrorWithCode } from '@shared/Errors'
import { DateTime } from 'luxon'

/** Screen component that can be used for viewing, editing or creating products */
function AdminProductDetailsScreenComp() {
  const navigation = useNavigation<StackNavigationProp<AdminProductsParamList, 'AddProduct' | 'EditProduct'>>()
  const farm = useSelector(adminFarmSelector)
  const dispatch = useDispatch()
  const { params, name: routeName } = useRoute<RouteProp<AdminProductsParamList, 'AddProduct' | 'EditProduct'>>()
  const { product } = useSelector(adminParamsSelector) // The 'product' from adminParamsSelector will be the most up-to-date snapshot of the product, if screen is on edit mode
  const user = useSelector(userSelector)
  const [{ loading, type, error }, set, setState] = useKeyedState<{
    loading: boolean
    type?: ProductType
    error?: string
  }>({
    loading: true,
    type: undefined, //this undefined value should not remain so at the time it's passed into Formik's initial values. Otherwise, its value inside formik will not update after first render.
  })

  /** Re-initializes the screen state based on navigation params.
   * - This fx should use deep compare, because we can't know the exact params type in advance so we can't check more specifically for a given property. */
  useDeepCompareFocusFx(
    () => {
      /** When the parameters change, we will be reusing the same screen and its state, because we don't want to lose an in-progress edit when navigating away. Therefore, when changes in screen parameters re-trigger this fx, we must first reset the state with loading: true, then based on the routeName we must set the product type, fetch the product and set it to context, and finally set loading to false */
      setState({ loading: true })
      if (routeName === 'AddProduct') {
        const type = (params as AdminProductsParamList['AddProduct']).type
        set('type', type ?? ProductType.Standard)
        dispatch(setAdminNav({ product: undefined }))
        set('loading', false)
      } else if (routeName === 'EditProduct') {
        const prodId = (params as AdminProductsParamList['EditProduct']).prodId
        if (!prodId) {
          set('error', 'Missing the ID of the product you wish to edit')
          set('loading', false)
          return
        }
        return productsCollection.snapshotDoc(
          prodId,
          (product) => {
            if (product) {
              set('type', product.type)
              dispatch(setAdminNav({ product }))
              const hasPermission = checkHasPermission(farm.managers, user)
              if (!hasPermission) set('error', 'You do not have permission to access this product')
              set('loading', false)
            } else {
              set(
                'error',
                'This product could not be loaded, please click the "X" in the upper right corner and go back and select a product from the list.',
              )
              set('loading', false)
            }
          },
          (err) => {
            dispatch(setAdminNav({ product: undefined }))
            set(
              'error',
              `There was a problem loading this product. Check your internet connection and try again. ${errorToString(
                err,
              )}`,
            )
            set('loading', false)
          },
        )
      } else {
        set('error', 'This path does not exist')
        Logger.error(new Error('Trying to use the product details component in the wrong navigator screen'))
      }
    },
    [params, routeName, user, dispatch, set, setState, farm.managers],
    {
      /** It should not run on re-focus because we only want to run it when the dependencies actually change. */
      noRefocus: true,
    },
  )

  const saveForm = useCallback(
    async function (values: ProductDetailsForm) {
      let newProd: Product
      try {
        newProd = (await buildProductFromFormik(values)) as Product

        // Any fields not controlled by the form must be set to the new product built from the form values
        // This is often going to be the fields controlled by the product row, which are not present inside the edit form UI
        newProd.farm = pick(farm, 'id', 'address', 'name', 'timezone', 'urlSafeSlug')
        newProd.isHidden = routeName === 'AddProduct' ? false : product?.isHidden ?? false
        newProd.isFeatured = routeName === 'AddProduct' ? false : product?.isFeatured ?? false

        // Set any derived product fields not managed by the user
        const csas = await loadCSAsByFarm(farm.id)
        newProd.isPrivate = isPrivate(newProd, csas)
        newProd.urlSafeSlug = formatToSafeSlug(newProd.name)

        /** Validate the product has all required fields and no extraneous ones */
        newProd = buildProduct(newProd)
      } catch (err) {
        const msg = errorToString(err)
        Alert('Error saving product:', msg)
        Logger.error(err)
        return
      }

      /**
       * Prompt below message to the farmer, if all are true :
       * - current routeName === 'EditProduct'
       * - Prod is not undefined
       * - newProd.distributionConstraints is not equal to Prod.distributionConstraints
       */
      let proceed = true
      if (routeName === 'EditProduct' && !!product) {
        if (!dequal(newProd.distributionConstraints, product.distributionConstraints))
          proceed = await new Promise((resolve) =>
            Alert(
              'Important Information',
              "Changes to a product's availability only apply to future orders. To change the schedule of an existing product or share, edit the associated schedule in the Schedules tab.",
              [
                { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
                { text: 'Continue', onPress: () => resolve(true) },
              ],
            ),
          )
      }
      if (!proceed) return

      // Show a warning if a payment end date is in the past
      const paySchedules = isShare(newProd) ? getPaymentSchedules({ product: newProd }) : undefined
      const paymentEndWarnings = paySchedules
        ?.map((ps) =>
          isBefore(ps.paymentDates.endDate, DateTime.now(), { granularity: 'day', zone: farm.timezone })
            ? ` ${bullet} ${ps.frequency}: ${formatShortDate(ps.paymentDates.endDate)}\n`
            : null,
        )
        .filter(isNonNullish)
      if (paymentEndWarnings?.length) {
        proceed = await new Promise((resolve) =>
          Alert(
            'Payment End Date is Past',
            'The following payment end dates are in the past and will not allow paying with installments. \n\n' +
              paymentEndWarnings.join(', '),
            [
              { text: 'OK', onPress: () => resolve(true) },
              { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
            ],
          ),
        )
      }
      /**TODO: When we decide to keep going this feature (Adding product slug to url, then we can return this feature) */
      // if (!proceed) return

      // // Show a warning if the product name has changed
      // if (
      //   routeName === 'EditProduct' &&
      //   !!product &&
      //   formatToSafeSlug(newProd.name) !== formatToSafeSlug(product.name)
      // ) {
      //   proceed = await new Promise((resolve) =>
      //     Alert('Name Change warning', getNameChangeOnUrlSafeSlugWarningText('products'), [
      //       { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
      //       { text: 'Continue', onPress: () => resolve(true) },
      //     ]),
      //   )
      // }
      // if (!proceed) return

      Toast('Saving product...')

      try {
        let dbProd: Product
        if (routeName === 'EditProduct' && product?.id) {
          newProd.id = product.id
          /** This helper will fix any previous invalid data because whenever it saves edited products, it always computed validated product data and overWrite existing data. */
          dbProd = await overWriteProduct(newProd)
          Toast('Product updated successfully')
        } else {
          dbProd = await addProduct(newProd, farm)
          Toast('Product added successfully')
        }
        //put in a temporary "add/edit" queue, while it gets processed into algolia
        dispatch(
          addQueueAlgoliaProduct({
            prodId: dbProd.id,
            data: dbProd,
            action: routeName === 'EditProduct' ? 'edited' : 'added',
          }),
        )
        dispatch(setNavProps()) //Clear cache so the product will show updated data next time the shop is visited
        navigation.navigate('ProductList')
      } catch (error) {
        if (isErrorWithCode(error)) {
          Alert('Error saving product:', errorToString(error.uiMsg))
        } else {
          Alert(
            'Error saving product:',
            'There was a problem while trying to save your product. Please check your internet connection and try again.',
          )
        }
        Logger.error('Error saving Product', error)
      }
    },
    [farm, routeName, navigation, product, dispatch],
  )

  /** Formik validate prop */
  const validateForm: FormikConfig<ProductDetailsForm>['validate'] = useCallback(
    async (values: ProductDetailsForm) => {
      try {
        await productFormSchema().validate(values, {
          abortEarly: false,
          context: { type, unitStock: values.unitStock },
        })
        return {}
      } catch (e) {
        return yupToFormErrors(e)
      }
    },
    [type],
  )

  /** Whenever this data has a deep inequality change, the form initial values will be rebuilt. If not used correctly it may have unintended consequences.
   * - Should only include data about the product which should completely reset the form. Ideally the form should not reset while editing because that might revert unsaved changes.
   * - For areas where rebuilding the form would have bad consequences, each form component should implement its own technique for listening to product data changes, and handling them against their current form values. That would be a more laborious, but would prevent user losing their unsaved changes.
   *
   * - No need to use the entire "farm". Just farm.name and first image url. Todo: determine why we need certain farm fields here. We're currently just going with the existing code, for safety. Either way this farm data isn't expected to change while editing products.

   * - Product['distributions'] Schedules should not be included here because rebuilding form distros would revert some unsaved changes in the schedules constraints, as well as unsaved assignments or unassignments of schedules. For example, if a schedule is unassigned, and it is edited in another tab, it will change the product object and will rebuild the form, reseting the unsaved changes and adding back the unassigned schedule. https://github.com/farmgenerations/grownby/issues/7018 . For dealing correctly with schedule updates, instead of rebuilding the entire form at this higher level, the Schedules form component implements its own fx to handle schedule changes in a way that integrates them with the form values without reverting unsaved changes.
   */
  const formResetData = [
    type,
    farm.name,
    farm.media[0]?.storageUrl,
    !product ? undefined : [getUnits(product), getStock(product)],
  ]

  /** The success values for the main loading view.
   * - The form type holds the initial values for the product edit formik components
   */
  const success = useDeepCompareMemo<[ProductDetailsForm, ProductType] | undefined>(
    () =>
      type
        ? [
            buildInitialValues({
              product: { ...product, type },
              name: routeName,
              params,
              farm,
            }),
            type,
          ]
        : undefined,
    /** formReset data has exactly the data we want to consider for updating the initial values */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formResetData],
  )

  return (
    <LoadingView
      loading={loading}
      success={success}
      error={error}
      ErrorComponent={({ error }) => (
        /** When there is an error like Product Not Found, we return the not found page instead of rendering the Product Edit page */
        <AdminView>
          <EditHeader
            goBack={() => navigation.navigate('ProductList')}
            title="Edit Product"
            actionTitle="Back"
            onPress={() => navigation.navigate('ProductList')}
          />
          <MessageWithIcon icon="carrot" title="Product not found">
            {error}
          </MessageWithIcon>
        </AdminView>
      )}
      switchMode //switchmode is necessary for adminview scroll to work
    >
      {function OnSuccess([initialValues, type]) {
        return (
          <Formik<ProductDetailsForm>
            initialValues={initialValues}
            enableReinitialize //Form should be allowed to re-initialize on product updates
            onSubmit={saveForm}
            validate={validateForm}
          >
            {function Form(formik: FormikProps<ProductDetailsForm>) {
              return (
                <AdminView
                  customHeader={<AddEditProductHeader product={product} routeName={routeName} prodType={type} />}
                >
                  <BasicInformation type={type} />
                  {hasUnits({ type }) && (
                    <>
                      {formik.values.type !== ProductType.FarmBalance && <UnitsComponent />}
                      <AdvancedPricingComponent />
                    </>
                  )}
                  {isShare(type) && (
                    <>
                      <SharePricing />
                      <ShareBilling />
                    </>
                  )}
                  {isPhysical({ type }) && <SchedulesComponent />}
                  {isPhysical({ type }) && <AvailabilityOverview />}
                </AdminView>
              )
            }}
          </Formik>
        )
      }}
    </LoadingView>
  )
}

export const AdminProductDetailsScreen = withAdminAuth(
  AdminProductDetailsScreenComp,
  Permission.ProductSetup,
  AccessRight.Edit,
)

/** Checks whether the user has permission to edit a given product */
const checkHasPermission = (farmManagers?: Farm['managers'], user?: User): boolean => {
  if (!farmManagers || !user) return false
  let canAccess = false
  farmManagers.forEach((manager) => {
    if (manager.user.id === user.id) {
      canAccess = true
    }
  })
  return canAccess
}
