import { extendErr, retry } from '@helpers/helpers'
import { Cart } from '@models/Cart'
import { CartItem } from '@models/Order'
import { PaymentSchedule } from '@models/Product'
import { DataError, ErrorCode } from '@shared/Errors'
import { ConflictingFarmError, InsufficientStockError, OutOfStockError } from '@shared/errors/cart'
import {
  AddToCartApiInput,
  CartAddDiscountRequest,
  CartAddRequest,
  CartInitRequest,
  CartUpdateItemRequest,
  UpdateCartItemFields,
} from '@shared/types/v2/cart'

import { marshalCartItem, unmarshalCartItem } from './encoding/Cart'
import { marshalPaymentSchedule } from './encoding/Product'
import { cartsCollection } from './framework/ClientCollections'
import { callEndpoint, isServerErrorWithCodeLegacy } from './v2'

import { Logger } from '@/config/logger'

/**
 * cartInit initializes a shopping cart used to collect items for order.
 * - Once a cart has been initialized with a session ID subsequent calls to the function with the same ID will return the same cart.
 * - Assigning a user to the cart is optional to allow unauthenticated sessions to start adding items to the cart.
 * - Once a user has been authenticated this function can be called again using the same session ID.
 * - A user is required before beginning the checkout process.
 *
 * There is an issue in server sdk that makes the firestore user not found server side, although it is found client size. It was observed to only happen during the first 3 seconds after the creation of the user document. If cartInit fails in this manner, the cart won't have a user id assigned at the time of checkout. To address that, we retry cartInit in 3 seconds intervals.
 */
export async function cartInit(sessionId: string, userId?: string, isAdmin = false): Promise<string> {
  try {
    // By default, we retry cartInit because we expect it to fail some of the time.
    // Explanation: The firebase issue that when a user creates a new account, the admin firestore sdk won't find the user for a few seconds, even after the user doc has been fully created.
    return retry(
      async () => {
        const request: CartInitRequest = { sessionId, userId, isAdmin }
        const { cartId } = await callEndpoint('v2.Cart.cartInitService', request)
        return cartId
      },
      4,
      3000,
    )
  } catch (e) {
    Logger.error(extendErr(e, 'Error while calling cartInit service:'))
    throw e
  }
}

/** cartAdd calls the cartAdd API service to add the supplied product information as a new item in the cart. */
export async function cartAdd({
  cartId,
  product,
  distribution,
  unit,
  price,
  csa,
  pickups,
  paymentSchedule,
  replace,
  isAdmin,
}: AddToCartApiInput): Promise<CartItem> {
  const item = {
    product,
    distribution,
    unit,
    price,
    csa,
    pickups,
    paymentSchedule,
  } as Partial<CartItem>

  const request: CartAddRequest = {
    cartId,
    ...marshalCartItem(item),
    replace,
    isAdmin,
  }

  try {
    const cartItem = await callEndpoint('v2.Cart.cartAddService', request)
    return unmarshalCartItem(cartItem)
  } catch (err) {
    Logger.error(new DataError('Error while calling cartAdd service.', request))

    if (isServerErrorWithCodeLegacy(err, ErrorCode.CONFLICTING_FARM)) {
      throw new ConflictingFarmError()
    }
    if (isServerErrorWithCodeLegacy(err, ErrorCode.OUT_OF_STOCK)) {
      throw new OutOfStockError()
    }
    if (isServerErrorWithCodeLegacy(err, ErrorCode.INSUFFICIENT_STOCK)) {
      throw new InsufficientStockError()
    }
    throw err
  }
}

/**
 /** cartList returns the results of cartList with each cart item transformed into a CartItem model.
 * @param cartId the cart id to list
 */
export async function cartList(cartId: string): Promise<CartItem[]> {
  try {
    const { items } = await callEndpoint('v2.Cart.cartListService', { cartId })
    return (items as any[]).map((ci) => unmarshalCartItem(ci))
  } catch (err) {
    Logger.error(
      new DataError('Error while calling cartList service.', {
        error: err,
        cartId,
      }),
    )
    throw err
  }
}

/**
 * loadCartDiscount loads the discount from the cartId
 * @param cartId the cart id to load discounts for
 */
export async function loadCartDiscount(cartId: string): Promise<Cart['discount']> {
  const cart = await cartsCollection.fetch(cartId)
  return cart.discount
}

/** cartUpdateQuantity updates the quantity of an item specified by the supplied ID to use the new quantity value. */
export async function cartUpdateQuantity(cartId: string, itemId: string, newQuantity: number): Promise<CartItem> {
  try {
    const update = await callEndpoint('v2.Cart.cartUpdateQuantityService', { cartId, itemId, newQuantity })
    return unmarshalCartItem(update)
  } catch (err) {
    Logger.error(
      new DataError('Error while calling updateQuantity service.', {
        error: err,
        cartId,
        itemId,
        newQuantity,
      }),
    )
    if (isServerErrorWithCodeLegacy(err, ErrorCode.OUT_OF_STOCK)) {
      throw new OutOfStockError()
    }
    if (isServerErrorWithCodeLegacy(err, ErrorCode.INSUFFICIENT_STOCK)) {
      throw new InsufficientStockError()
    }
    throw err
  }
}

/** cartUpdatePaymentSchedule updates the payment schedule associated with a cart item. */
export async function cartUpdatePaymentSchedule(
  cartId: string,
  itemId: string,
  paymentSchedule: PaymentSchedule,
): Promise<CartItem> {
  try {
    const update = await callEndpoint('v2.Cart.cartUpdatePaymentScheduleService', {
      cartId,
      itemId,
      paymentSchedule: marshalPaymentSchedule(paymentSchedule),
    })
    return unmarshalCartItem(update)
  } catch (err) {
    Logger.error(
      new DataError('Error while calling updatePaymentSchedule service.', {
        error: err,
        cartId,
        itemId,
        paymentSchedule: marshalPaymentSchedule(paymentSchedule),
      }),
    )
    throw err
  }
}

/** updates data inside an item in the cart */
export async function cartUpdateItem(
  cartId: string,
  itemId: string,
  update: UpdateCartItemFields,
  isAdmin?: boolean,
): Promise<CartItem> {
  try {
    const req: CartUpdateItemRequest = {
      cartId,
      itemId,
      update: marshalCartItem(update as Partial<CartItem>),
      isAdmin,
    }
    const res = await callEndpoint('v2.Cart.cartUpdateItemService', req)
    return unmarshalCartItem(res)
  } catch (err) {
    Logger.error(
      new DataError('Error while updating a cart item.', {
        error: err,
        cartId,
        itemId,
        update,
      }),
    )

    if (isServerErrorWithCodeLegacy(err, ErrorCode.OUT_OF_STOCK)) {
      throw new OutOfStockError()
    }
    if (isServerErrorWithCodeLegacy(err, ErrorCode.INSUFFICIENT_STOCK)) {
      throw new InsufficientStockError()
    }

    throw err
  }
}

/** Will add a discount to a cart */
export const cartAddDiscount = (req: CartAddDiscountRequest) => {
  try {
    return callEndpoint('v2.Cart.cartAddDiscountService', req)
  } catch (err) {
    Logger.error(extendErr(err, 'Error while adding cart discount.'))
    throw err
  }
}

/** Will remove a discount from the cart*/
export function cartRemoveDiscount(cartId: string): Promise<void> {
  try {
    return callEndpoint('v2.Cart.cartRemoveDiscountService', { cartId })
  } catch (err) {
    Logger.error(
      new DataError('Error while removing discount.', {
        error: err,
        cartId,
      }),
    )
    throw err
  }
}
