import {
  CartButtons,
  CartButtonsHelpers,
  disableAddCartBtn,
  EbtIcon,
  Image,
  makeTestIdPrefix,
  PayScheduleExt,
  Stepper,
} from '@components'
import { ButtonClear, Divider, ErrorText, Text, TextH2, TextH3, Toast } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { createObjectStyles } from '@helpers/client/createObjectStyles'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { formatMoney, formatPickupDate } from '@helpers/display'
import { objToStr } from '@helpers/log'
import { MoneyCalc } from '@helpers/money'
import { getProratedAmount } from '@helpers/order'
import { formatDistributionType, isNonPickup } from '@models/Location'
import { CartItem, isCartStandard, isCartStandardMulti } from '@models/Order'
import { CartItemGroupType, getDeliveryDescr, getDeliveryDetails } from '@screens/Shopping/Checkout/CartItemsCheckout'
import { EnterDiscount } from '@screens/Shopping/components/EnterDiscount'
import { Dispatch, memo, PropsWithChildren, SetStateAction, useContext, useMemo, useState } from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'

import Colors from '@/constants/Colors'
import { CreateOrderScreenContext } from '../CreateOrderScreen'
import { OrderCreatorPaymentOptions } from './PaymentOptions'

import { useCartService } from '@/hooks/useCart'
import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { useControlledState } from '@/hooks/useControlledState'
import { useToolTip } from '@/hooks/useToolTip'

/** Component that displays the data for a group of nonPickup cart items that have the same location */
const DeliveryGroupItem = memo(function DeliveryGroupItem({
  items,
  address,
  groupDeliveryTotal,
  locationFee,
  combinedDeliveryDates,
  combinedDeliveryPickups,
  uniqueDates,
  locType,
}: CartItemGroupType) {
  const [showingDetails, [showDetails, hideDetails]] = useControlledState(false, [true, false])
  const descr = getDeliveryDescr(locationFee!, locType!, combinedDeliveryDates, uniqueDates.length, false)
  const details = getDeliveryDetails({
    nDates: uniqueDates.length,
    combinedDeliveryDates,
    combinedDeliveryPickups,
    deliveryTotal: groupDeliveryTotal,
    locationFee,
    locType,
  })
  const { TipButton, TipOverlay } = useToolTip(details)

  return (
    <View>
      <TipOverlay />
      <View key={objToStr(items.map((itm) => itm.id).sort())} style={styles.deliveryGroupTop}>
        <Text style={styles.itemProdName}>{address}</Text>
        <TextH2>{formatMoney(groupDeliveryTotal)}</TextH2>
      </View>
      <View style={styles.deliveryDetailsCont}>
        <TouchableOpacity onPress={showingDetails ? hideDetails : showDetails}>
          <TextH3 color={Colors.green}>{showingDetails ? 'Hide details' : 'Details'}</TextH3>
          {showingDetails ? (
            <Text style={styles.deliveryDetailsText}>
              {descr} <TipButton />
            </Text>
          ) : null}
        </TouchableOpacity>
      </View>
    </View>
  )
})

export type OrderSummaryProps = {
  children?: PropsWithChildren['children']
}
/** Section in the order creator which holds the current cart items and all relevant details for finishing order placement */
export const OrderSummary = memo(function OrderSummary({ children }: OrderSummaryProps) {
  const [loading, setLoading] = useState(false)
  const cartApi = useCartService(true)
  const [
    {
      total,
      additionalFees,
      itemGroups,
      deliveryFeesData: { itemsDeliveryFees, err: deliveryFeesError },
    },
  ] = useContext(CreateOrderScreenContext)
  const { TipOverlay, TipButton } = useToolTip(
    'Delivery fees will be billed as a separate invoice per delivery, due on the delivery date',
  )
  return (
    <View style={styles.wrapper}>
      <TipOverlay />
      <View style={styles.padded}>
        <TextH2 size={18}>Order Summary</TextH2>
        {children}
      </View>
      {cartApi.cart.length > 0 ? (
        <View>
          <View style={[styles.headerOrderTotal, styles.padded]}>
            <TextH2>
              Order total <Text>(Due now)</Text>
            </TextH2>
            <TextH2>{formatMoney(total.total)}</TextH2>
          </View>
          <View style={styles.padded}>
            {cartApi.cart.map((ci) => (
              <OrderSummaryItem key={ci.id} cartItem={ci} loading={loading} setLoading={setLoading} />
            ))}
            <View style={styles.subtotalContainer}>
              <View style={styles.subtotalRow}>
                <Text>Subtotal</Text>
                <Text style={styles.subtotalAmount}>{formatMoney(total.subtotal)}</Text>
              </View>
            </View>
          </View>
          <EnterDiscount
            style={styles.addCoupon}
            addDiscount={cartApi.addDiscount}
            removeDiscount={cartApi.removeDiscount}
            discount={cartApi.discount}
            loadingCart={cartApi.loadingCart}
          />

          <View style={styles.taxesAndFeesAndDiscountsCont}>
            {!!total.discounts && !MoneyCalc.isZero(total.discounts) && (
              <View style={styles.subtotalRow}>
                <Text color={Colors.green}>Discounts</Text>
                <Text color={Colors.green} style={styles.totalDiscount}>
                  {`-${formatMoney(total.discounts)}`}
                </Text>
              </View>
            )}
            {additionalFees.length > 0 &&
              additionalFees.map((item) => (
                <View style={styles.subtotalRow} key={item.productFee.id}>
                  <Text>{item.productFee.name}</Text>
                  <Text style={styles.subtotalAmount}>{formatMoney(item.amount)}</Text>
                </View>
              ))}
            {MoneyCalc.isGTZero(total.tax) && (
              <View style={styles.subtotalRow}>
                <Text>Tax</Text>
                <Text style={styles.subtotalAmount}>{formatMoney(total.tax)}</Text>
              </View>
            )}
          </View>
          <View style={styles.orderTotalCont}>
            <TextH2 size={16}>
              Order total <Text>(Due now)</Text>
            </TextH2>
            <TextH2 style={styles.orderTotalAmount} size={16}>
              {formatMoney(total.total)}
            </TextH2>
          </View>
          {itemGroups.some((g) => g.locType && isNonPickup(g.locType)) && (
            <>
              <Divider large />
              <View style={styles.padded}>
                <TextH2 style={styles.deliverySectionTitle}>Delivery fees</TextH2>
                <View style={styles.deliveryGroupCont}>
                  {itemGroups
                    .filter((g) => g.locType && isNonPickup(g.locType))
                    .map((group) => (
                      <DeliveryGroupItem key={objToStr(group.items.map((ci) => ci.id).sort())} {...group} />
                    ))}
                </View>
              </View>
              <View style={styles.deliveryTotalCont}>
                <View style={styles.deliveryTotalMain}>
                  <TextH2 size={16}>Delivery total</TextH2>
                  <Text>
                    (Billed separately) <TipButton />
                  </Text>
                </View>
                <TextH2 size={16}>{deliveryFeesError ? '   -  ' : formatMoney(itemsDeliveryFees)}</TextH2>
              </View>
              {deliveryFeesError ? (
                <ErrorText>Couldn't calculate delivery fees due to an unexpected error</ErrorText>
              ) : null}
            </>
          )}
          <OrderCreatorPaymentOptions />
        </View>
      ) : (
        <View style={styles.emptyCartCont}>
          <Text style={styles.emptyCartText}>No items yet</Text>
          <FontAwesome5 color={Colors.shades['300']} name="shopping-cart" size={60} />
        </View>
      )}
    </View>
  )
})

/** UI for each cart item in the order summary */
const OrderSummaryItem = memo(function OrderSummaryItem({
  cartItem,
  setLoading,
  loading,
}: {
  cartItem: CartItem
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
}) {
  const [showingPickups, [showPickups, hidePickups]] = useControlledState(false, [true, false])
  const paySchedules: PayScheduleExt[] = useMemo(
    () => CartButtonsHelpers.processPaySchedules(cartItem, true),
    [cartItem],
  )
  const itemAmount = useMemo(
    () =>
      getProratedAmount(cartItem, {
        excludeHiddenDistros: true,
        excludeClosedDistros: false,
        ignoreOrderCutoffWindow: true,
      }).itemAmount,
    [cartItem],
  )
  const cartSrv = useCartService(true)
  const { modifyDates } = useAddToCartFlow(true)

  const updateQty = async (delta: number) => {
    setLoading(true)
    try {
      await cartSrv.updateQuantity(cartItem.id, cartItem.quantity + delta)
    } catch {
      Toast('Something went wrong while updating the quantity')
    }
    setLoading(false)
  }

  const removeItem = async () => {
    setLoading(true)
    try {
      await cartSrv.updateQuantity(cartItem.id, 0)
    } catch {
      Toast('Something went wrong while removing this item')
    }
    setLoading(false)
  }

  return (
    <View key={cartItem.id} style={styles.cartItemContainer}>
      {cartItem.product.images.length > 0 && (
        <Image resizeMode="cover" style={styles.image} type="product" source={{ uri: cartItem.product.images[0] }} />
      )}
      <View style={styles.itemTop}>
        <TextH2 style={styles.itemProdName}>
          <EbtIcon product={cartItem.product} />
          {cartItem.product.name}
        </TextH2>
        <TextH2>{formatMoney(itemAmount)}</TextH2>
      </View>
      <Text style={styles.itemBuyingOpt}>{cartItem.unit?.name}</Text>
      {cartItem.pickups && (
        <>
          <View style={styles.itemPickupsCont}>
            <TextH3>
              No. {formatDistributionType(cartItem.distribution.location, { plural: true })}: {cartItem.pickups.length}
            </TextH3>
            {cartItem.pickups.length > 1 && (
              <TextH3>
                Qty./ {formatDistributionType(cartItem.distribution.location)}: {cartItem.quantity}
              </TextH3>
            )}
          </View>
          <View style={styles.itemPickupsCont}>
            {cartItem.pickups.length === 1 ? (
              <TextH3 size={11}>{formatPickupDate(cartItem.pickups[0])}</TextH3>
            ) : (
              <ButtonClear
                style={styles.buttonClear}
                size={13}
                title={
                  (!showingPickups ? 'Show ' : 'Hide ') +
                  formatDistributionType(cartItem.distribution.location, { plural: true })
                }
                onPress={showingPickups ? hidePickups : showPickups}
              />
            )}
            {isCartStandard(cartItem) && (
              <ButtonClear
                style={styles.buttonClear}
                size={13}
                title={cartItem.pickups?.length === 1 ? 'Add more dates' : 'Modify Dates'}
                onPress={() => modifyDates(cartItem.id)}
              />
            )}
          </View>
          {showingPickups
            ? cartItem.pickups!.map((pickup, idx) => (
                <View style={styles.pickupsExpanded} key={idx}>
                  <TextH3 size={11}>{formatPickupDate(pickup)}, </TextH3>
                </View>
              ))
            : null}
        </>
      )}
      <View style={styles.stepperContainer}>
        <Stepper
          key={makeTestIdPrefix(cartItem.product, cartItem.unit)}
          style={styles.stepperOverride}
          cartItem={cartItem}
          updateQuantity={updateQty}
          loading={loading}
          disableAdd={disableAddCartBtn(cartItem.product, cartSrv.cart, [], cartItem.unit, 'admin')}
        />
        <TouchableOpacity onPress={removeItem} disabled={loading}>
          <Text>Remove</Text>
        </TouchableOpacity>
      </View>
      {isCartStandardMulti(cartItem) && (
        <CartButtons
          item={cartItem}
          paySchedules={paySchedules}
          showErrors={false}
          updatePaySchedule={(ps) => cartSrv.updatePaySchedule(cartItem.id, ps)}
          touched
        />
      )}
    </View>
  )
},
propsAreDeepEqual)

const styles = createObjectStyles(({ merge }) => ({
  totalDiscount: { marginLeft: 10 },
  orderTotalCont() {
    return merge(this.headerOrderTotal, this.padded, { justifyContent: 'flex-end', paddingTop: 0 })
  },
  orderTotalAmount: { marginLeft: 10 },
  deliverySectionTitle: { marginTop: 15 },
  deliveryGroupCont: { marginVertical: 15 },
  deliveryGroupTop: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
  deliveryTotalMain: { marginHorizontal: 10 },
  deliveryTotalCont(): ViewStyle {
    return merge(this.headerOrderTotal, this.padded, { justifyContent: 'flex-end', marginVertical: 15 })
  },
  deliveryDetailsCont: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    padding: 5,
    marginTop: 5,
  },
  deliveryDetailsText: { marginTop: 5 },
  emptyCartText: { marginVertical: 10 },
  itemTop: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
  itemProdName: { padding: 1 },
  itemBuyingOpt: { marginVertical: 10 },
  itemPickupsCont: { flexDirection: 'row', justifyContent: 'space-between' },
  buttonClear: { padding: 0 },
  pickupsExpanded: { marginHorizontal: 5, marginTop: 5 },
  stepperOverride: { borderWidth: 0 },
  image: {
    borderRadius: 10,
    overflow: 'hidden',
    width: 60,
    aspectRatio: 1,
    marginVertical: 10,
  },
  cartItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
    paddingVertical: 10,
  },
  subtotalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
    alignItems: 'center',
  },
  subtotalContainer: {
    alignSelf: 'flex-end',
  },
  subtotalAmount: { marginLeft: 10 },
  stepperContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  wrapper: {
    borderWidth: 1,
    borderColor: Colors.lightGray,
    borderRadius: 10,
  },
  headerOrderTotal: {
    width: '100%',
    backgroundColor: Colors.lightGreen,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  padded: {
    padding: 15,
  },
  emptyCartCont: {
    paddingTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addCoupon: {
    alignSelf: 'flex-end',
    margin: 10,
  },
  taxesAndFeesAndDiscountsCont: {
    paddingHorizontal: 15,
    alignSelf: 'flex-end',
  },
}))
