import { Alert } from '@elements'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useRef, useState } from 'react'

import { ShoppingStackParamList } from '../../../navigation/types'

import { isWeb } from '@/constants/Layout'

/** Implements various kinds of confirmation listeners to help users complete placing their order. the unsubscriber functions must be run after succesfully placing the order, but before navigating away from checkout
 * @returns an unlistener
 */
export default function useConfirmLeaveCheckout(
  hasItems: boolean,
  navigation: StackNavigationProp<ShoppingStackParamList, 'Checkout'>,
): () => void {
  const [alertsEnabled, setAlertsEnabled] = useState(true)
  const unlisten = useRef(() => {})

  /** This fx sets listeners for the confirmation alerts */
  useEffect(() => {
    if (!hasItems || !alertsEnabled) return

    /** BeforeUnLoad listener checks whether the browser tab was closed */
    let unlistenBeforeunload = () => {}
    if (isWeb) {
      const callbackBeforeunload = (e: BeforeUnloadEvent) => {
        e.preventDefault()
        e.returnValue = ''
        return ''
      }
      addEventListener('beforeunload', callbackBeforeunload)
      unlistenBeforeunload = () => window.removeEventListener('beforeunload', callbackBeforeunload)
    }

    /** onBlur navigation listener runs after the screen navigated away */
    const unlistenBlur = navigation.addListener('blur', () => {
      if (alertsEnabled)
        Alert('Your order was not placed', 'Do you still want to place your order?', [
          {
            text: 'Return to checkout',
            onPress: () => {
              navigation.navigate('Checkout')
            },
          },
          {
            text: 'Continue here',
            onPress: () => {
              unlistenBlur()
              unlistenBeforeunload()
            },
          },
        ])
      else {
        unlistenBlur()
        unlistenBeforeunload()
      }
    })

    /** BeforeRemove listener runs on navigation calls and stops them before they happen */
    const unlistenBeforeRemove = navigation.addListener('beforeRemove', (e) => {
      if (!alertsEnabled) {
        unlistenBeforeRemove()
        unlistenBlur()
        unlistenBeforeunload()
        return
      }

      e.preventDefault()
      Alert("You haven't finished placing your order", 'Are you sure you want to leave this screen?', [
        { text: 'Continue checkout' },
        {
          text: 'Leave',
          onPress: () => {
            unlistenBeforeRemove()
            unlistenBlur()
            unlistenBeforeunload()
            navigation.dispatch(e.data.action) //This will continue navigating away, wherever the destination is,
          },
        },
      ])
    })

    const unlistenAll = () => {
      unlistenBeforeRemove()
      unlistenBlur()
      unlistenBeforeunload()
    }

    // When unlisten is called, will unlisten and disable them to prevent setting them again
    unlisten.current = () => {
      unlistenAll()
      setAlertsEnabled(false)
    }

    // On deps change, cleanup will remove listeners but won't prevent them being set again
    return unlistenAll
  }, [alertsEnabled, hasItems, navigation])

  return unlisten.current
}
