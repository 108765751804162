import { getPickupsCacheAddtoCartFlow } from '@/hooks/useCart/addToCartFlow/helpers'
import { isNonNullish, isTruthy, removeDuplicates } from '@helpers/helpers'
import { objToStr } from '@helpers/log'
import { sortSchedulesByDailyAndDayOfWeek } from '@helpers/sorting'
import { Distribution } from '@models/Distribution'
import { Location, isNonPickupDistLocation } from '@models/Location'
import { PhysicalProduct, isPhysical } from '@models/Product'
import { useEffect, useMemo, useState } from 'react'

export type UseAvailableSchedulesProps = {
  prod: PhysicalProduct
  /** If schedule ids are provided, only schedules included here will be returned */
  scheduleFilter?: Distribution['id'][]
  /** If the location filter is provided, only schedules with this location id will be returned */
  locFilter?: Location['id'][]
  /** Admin mode allows for greater privileges. Should be true when using this component in the order creator. */
  isAdmin?: boolean
}

/** Returns the product schedules that have pickups and the available regions for the nonPickup among them */
export const useAvailableSchedules = ({ prod, scheduleFilter, locFilter, isAdmin }: UseAvailableSchedulesProps) => {
  const [availableSchedules, setAvailableSchedules] = useState<Distribution[]>()

  useEffect(() => {
    if (!isPhysical(prod)) return setAvailableSchedules([])
    const schedules = getAvailableSchedules(prod, isAdmin, scheduleFilter, locFilter)

    return setAvailableSchedules(schedules.sort(sortSchedulesByDailyAndDayOfWeek))

    /** this hook is meant to only run when the ids for product, location filter and schedule filter change.
     * we're using a stringified ids list, which is a simple way to avoid using deep comparison. it's OK for this purpose
     * because the ids are not expected to change or be re-ordered, and even if they do it's not a big deal.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prod.id, isAdmin, objToStr(locFilter), objToStr(scheduleFilter)])

  /** When the locationType is nonPickup, this should get the unique regions of the nonPickup locations of each schedule. This represents the regions where the product is available in a general sense (Without any filters applied yet) */
  const availableRegions = useMemo(() => {
    if (!availableSchedules) return undefined
    return removeDuplicates(
      availableSchedules
        .map((sch) => sch.location)
        .filter(isNonPickupDistLocation)
        .flatMap((loc) => loc.regions)
        .filter(isNonNullish) ?? [],
    )
  }, [availableSchedules])

  return { availableSchedules, availableRegions }
}

/** Filters a set of schedules from a product based on several options related to the schedule selection step of the addto cart flow */
export function getAvailableSchedules(
  prod: PhysicalProduct,
  isAdmin: boolean | undefined,
  scheduleFilter: string[] | undefined,
  locFilter: string[] | undefined,
) {
  return prod.distributions
    .map((sch) => {
      if (sch.isHidden) return false
      if (!!sch.closed && !isAdmin) return false
      if (scheduleFilter && !scheduleFilter.includes(sch.id)) return false
      if (locFilter && !locFilter.includes(sch.location.id)) return false

      const pickups = getPickupsCacheAddtoCartFlow(sch, prod, {
        excludeHiddenDistros: true,
        excludeClosedDistros: !isAdmin,
        ignoreOrderCutoffWindow: isAdmin,
        ignoreDisableBuyInFuture: isAdmin,
      })
      if (!pickups[0]) return false

      return sch
    })
    .filter(isTruthy)
}
