import { getShortState } from '@/assets/data/states'
import { bullet } from '@helpers/display'
import { ValidateAddressOpts, validateAddress } from '@models/Address'
import { UserAddress } from '@models/UserAddress'
import { ErrorWithCode, isErrorWithCode } from '@shared/Errors'

interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

/** A simplified address parsed from google places api */
interface ParsedAddress {
  street_number?: string
  street_name?: string
  city?: string
  state?: string
  country?: string
  postal_code?: string
}

export class GoogleAddressParser {
  private address: ParsedAddress = {}

  constructor(private address_components: AddressComponent[]) {
    if (!Array.isArray(this.address_components)) {
      throw Error('Address Components is not an array')
    }

    if (!this.address_components.length) {
      throw Error('Address Components is empty')
    }

    for (let i = 0; i < this.address_components.length; i++) {
      const component: AddressComponent = this.address_components[i]

      if (GoogleAddressParser.isStreetNumber(component)) {
        this.address.street_number = component.long_name
      }

      if (GoogleAddressParser.isStreetName(component)) {
        this.address.street_name = component.long_name
      }

      if (!this.address.city && GoogleAddressParser.isCity(component)) {
        this.address.city = component.long_name
      }

      if (GoogleAddressParser.isCountry(component)) {
        this.address.country = component.long_name
      }

      if (GoogleAddressParser.isState(component)) {
        this.address.state = component.long_name
      }

      if (GoogleAddressParser.isPostalCode(component)) {
        this.address.postal_code = component.long_name
      }
    }
  }

  private static isStreetNumber(component: AddressComponent): boolean {
    return component.types.includes('street_number')
  }

  private static isStreetName(component: AddressComponent): boolean {
    return component.types.includes('route')
  }

  private static isCity(component: AddressComponent): boolean {
    return (
      component.types.includes('locality') ||
      component.types.includes('administrative_area_level_3') ||
      component.types.includes('neighborhood')
    )
  }

  private static isState(component: AddressComponent): boolean {
    return component.types.includes('administrative_area_level_1')
  }

  private static isCountry(component: AddressComponent): boolean {
    return component.types.includes('country')
  }

  private static isPostalCode(component: AddressComponent): boolean {
    return component.types.includes('postal_code')
  }

  /** returns a simplified parsed address.
   * @deprecated this is not compatible with our main Address model. Use getAddress instead.
   */
  result(): ParsedAddress {
    return this.address
  }

  /** parses the google address into a validated address in our app's basic Address model */
  getAddress(validateOpts: ValidateAddressOpts = { allowPO: true }): Omit<UserAddress, 'coordinate' | 'id'> {
    const parsedAddress = this.address

    try {
      const street1 =
        parsedAddress.street_number && parsedAddress.street_name
          ? `${parsedAddress.street_number} ${parsedAddress.street_name}`
          : ''

      const stateCode = getShortState(parsedAddress.state || '')
      if (!stateCode) throw new ErrorWithCode({ code: 'AddressError', devMsg: 'Invalid state', data: parsedAddress })

      const address: Omit<UserAddress, 'coordinate' | 'id'> = {
        street1,
        city: parsedAddress.city || '',
        state: stateCode,
        zipcode: parsedAddress.postal_code || '',
      }

      const addrErrors = validateAddress(address, validateOpts)
      if (addrErrors) {
        const errStr = Object.entries(addrErrors)
          .map(([k, v]) => ` ${bullet} ${k}: ${v}.\n`)
          .join('')

        throw new ErrorWithCode({ code: 'AddressError', devMsg: errStr, data: parsedAddress })
      }
      return address
    } catch (error) {
      if (isErrorWithCode(error, 'AddressError')) {
        throw error
      }
      throw new ErrorWithCode({
        code: 'ParsingError',
        devMsg: 'The address data could not be parsed.',
        data: parsedAddress,
      })
    }
  }
}
