import { LinkingOptions } from '@react-navigation/native'
import * as Linking from 'expo-linking'

import env from '@/config/Environment'
import { isMobile } from '../constants/Layout'

const envFlag = env.APP_ENV || 'dev'

/**
 * For IOS, the deepLinks works when we set appLink in the app.config.ts
 * For Android, in order to make deepLinks work, The prefixs (domain should match the 'scheme' and 'host' in the intentFilter in app.config.ts).
 * When a deepLink is clicked, it should open the app and look at rest of path to do internal navigation to correct screen.
 */
const mobilePrefixes = [
  //production
  `https://grownby${envFlag === 'dev' || envFlag === 'staging' ? `-${envFlag}.web` : ''}.app`,
  //dev and staging
  `http://grownby${envFlag === 'dev' || envFlag === 'staging' ? `-${envFlag}.web` : ''}.app`,
  Linking.createURL('/'),
]

// Shared with both web and mobile
//@ts-ignore: Should add better types here in the future
const linkingOpts: LinkingOptions = {
  prefixes: isMobile ? mobilePrefixes : undefined,
  config: {
    screens: {
      Consumer: {
        path: '',
        initialRouteName: 'Home',
        screens: {
          Home: {
            path: '',
            initialRouteName: 'HomeScreen',
            screens: {
              HomeScreen: '/',
              FarmerOnboardScreen: '/farm-onboard/walkthrough',
              ExploreScreen: 'explore/:searchType?',
            },
          },
          Shopping: {
            path: 'farms',
            initialRouteName: 'MyFarmsScreen',
            screens: {
              MyFarmsScreen: {
                path: '',
              },
              FarmDetailScreen: {
                path: ':farmSlug',
              },
              FarmShop: {
                path: ':farmSlug/shop',
              },
              CSADetails: {
                path: ':farmSlug/shop/csa/:csaId',
              },
              ProductDetails: {
                path: ':farmSlug/shop/product/:productId',
              },
              MyCart: {
                path: 'shop/my-cart',
              },
              Checkout: {
                path: 'shop/checkout',
              },
            },
          },
          OrdersNavigator: {
            path: 'orders',
            initialRouteName: 'Orders',
            screens: {
              Orders: '',
              OrderSummary: ':orderId',
              InvoiceConsumer: 'invoice/:invoiceId',
            },
          },
          Notifications: {
            screens: {
              NotificationHistoryScreen: 'notifications',
            },
          },
          User: {
            path: 'profile',
            initialRouteName: 'Profile',
            screens: {
              Profile: '',
              PersonalInformation: 'view',
              BalanceScreen: 'balances',
              PaymentsScreen: 'payments',
              AddressScreen: 'addresses/:id?',
              Login: 'login',
              CompleteProfile: 'edit',
              Verification: 'verification',
              InviteFarm: 'InviteFarm',
              Notifications: 'notifications',
              Feedback: 'Feedback',
              Email: {
                path: 'email/login',
                screens: {
                  SignIn: 'sign-in',
                  Register: 'register',
                  Reset: 'reset',
                },
              },
              Security: 'security',
            },
          },
        },
      },
      FarmerWalkthroughNavigator: {
        path: 'farm-onboard',
        initialRouteName: 'RegisterFarm',
        screens: {
          RegisterFarm: 'register',
          ClaimScreen: 'claim/:farmSlug',
          AddFarmScreen: 'add-farm',
        },
      },
      //TODO: Remove, these routes are not in use
      PaymentScreen: {
        screens: {
          PaymentMethods: 'payments',
          StripeAuthCallback: 'payments/3D-auth',
          AddCreditCard: 'payments/add-credit',
          AddBankAccount: 'payments/add-bank',
        },
      },
      AdminDrawerNavigator: {
        path: 'admin',
        initialRouteName: '',
        screens: {
          Register: 'register',
          FarmProfileSetUp: {
            path: 'farm-profile',
          },
          LocationsAndZonesNavigator: {
            path: 'locations',
            initialRouteName: 'Locations',
            screens: {
              Locations: 'list',
              AddLocation: 'add',
              EditLocation: 'edit/:id',
              AddDeliveryShipping: 'add/:type',
              EditDeliveryShipping: 'edit/:type/:id',
            },
          },
          AdminCoupons: {
            path: 'coupons',
            initialRouteName: 'CouponList',
            screens: {
              CouponList: 'list',
              CouponDetails: 'view/:id',
            },
          },
          DistributionSchedulesNavigator: {
            path: 'schedules',
            initialRouteName: 'Schedules',
            screens: {
              Schedules: 'list',
              AddDistribution: 'add',
              EditDistribution: 'edit/:id',
            },
          },
          CSAStackNav: {
            path: 'csa',
            screens: {
              CSAGroup: 'list',
              CSADetail: 'add/:csaId',
            },
          },
          Products: {
            path: 'products',
            screens: {
              ProductList: 'list',
              AddProduct: 'add',
              EditProduct: 'edit/:prodId',
              ViewProduct: 'view/:prodId',
              AddTemplate: 'add-template',
              EditTemplate: 'edit-template/:templateId',
              Categories: 'categories',
              Producers: 'producers',
              ShareTemplates: 'share-templates',
            },
          },
          Customer: {
            path: 'customers',
            screens: {
              CustomerList: 'list',
              CustomerDetails: ':custId',
              CreateOrder: 'createorder',
              AdminOrderDetails: 'view/:orderId',
            },
          },
          Onboard: 'welcome',
          Dashboard: 'dashboard',
          Sales: 'sales',
          Analytics: 'analytics',
          Orders: {
            path: 'orders',
            initialRouteName: 'AdminOrderList',
            screens: {
              AdminOrderList: 'list',
              AdminOrderDetails: 'view/:orderId',
              CreateOrder: 'createorder',
            },
          },
          Invoices: 'invoices',
          DistributionSummaryStack: {
            path: 'summaries',
            initialRouteName: 'distribution-summary',
            screens: {
              DistributionSummary: 'distribution-summary',
              SignInSheetSelector: 'signin-selector',
              LocationSummary: 'location-summary',
            },
          },
          AccountSettings: 'account-settings',
          UserInfo: 'user-info',
          FarmSetup: 'setup/:tab?',
          Messages: 'messages',
        },
      },
      OnboardStripeConnect: 'admin/stripe-onboard',
      ExternalLinking: {
        screens: {
          /** FIXME: The ":collection" param isn't being used by the FarmLanding screen. The unused part should be removed from the url path after all considerations are taken into account */
          FarmLanding: 'external/farm/:collection/:farmId',
          FarmInvitation: 'external/farm-invite/:id',
          ResetPassword: 'external/account-reset/:id',
          SavingPassword: 'external/account-setup/:farmId/:id',
          InvoiceExternal: 'external/invoice/:id',
          StripeAuthorizationCallback: 'external/authorization-callback',
          InAppBrowserReturn: 'external/browser-return',
          VerifyEmail: 'external/verify-email/:id',
          AddPaymentExternal: 'external/add-payment/:userId',
        },
      },
      SignInSheet: {
        path: 'sign-in-sheet',
        screens: {
          ElectronicSignInSheet: 'electronic-signin-sheet/:id',
          EndSignIn: 'end-signin/:id',
          PostDonation: 'post-donation/:id',
          AddNotes: 'add-notes/:id',
          FinalizeSignIn: 'finalize/:id',
          PickupSummary: 'pickup-summary/:id',
          EditSignIn: 'edit/:id',
        },
      },
      FarmGenAdminNavigator: {
        path: 'farm-generations',
        screens: {
          FarmGenAdmin: 'admin',
          FarmGenAdminUserLogin: 'admin/user/:token',
          AnalyticsAdmin: 'analytics',
        },
      },
      NotFound: '*',
    },
  },
}

export default linkingOpts
