import { paramsSelector } from '@/redux/selectors'
import { isSafeSlug, matchesIdOrSlug } from '@helpers/urlSafeSlug'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { useFocusFx } from './useFocusFx'

/**
 * The goal of this hook is to force the use of a slug whenever an id was used as navigation parameter.
 *
 * - It is intended to be called in the root component of a screen.
 */
export function useSlugNavigation() {
  const { product, csa, farm } = useSelector(paramsSelector)
  const route = useRoute<RouteProp<{ SlugParams: { farmSlug?: string; productSlug?: string; csaSlug?: string } }>>()
  const navigation = useNavigation<any>()

  useFocusFx(() => {
    // If the slugOrId is not a safe slug, set the param to urlSafeSlug
    if (route.params?.farmSlug && !isSafeSlug(route.params.farmSlug) && farm?.urlSafeSlug) {
      //check if the redux state matches the route param
      if (matchesIdOrSlug(farm, route.params.farmSlug)) {
        navigation.setParams({
          farmSlug: farm.urlSafeSlug,
        })
      }
    }
    if (route.params?.productSlug && !isSafeSlug(route.params.productSlug) && product?.urlSafeSlug) {
      //check if the redux state matches the route param
      if (matchesIdOrSlug(product, route.params.productSlug)) {
        navigation.setParams({
          productSlug: product.urlSafeSlug,
        })
      }
    }
    if (route.params?.csaSlug && !isSafeSlug(route.params.csaSlug) && csa?.urlSafeSlug) {
      //check if the redux state matches the route param
      if (matchesIdOrSlug(csa, route.params.csaSlug)) {
        navigation.setParams({
          csaSlug: csa.urlSafeSlug,
        })
      }
    }
    // this is needed to avoid comparing the full objects in the dependency array (farm,csa,product)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    csa?.urlSafeSlug,
    farm?.urlSafeSlug,
    product?.urlSafeSlug,
    navigation,
    route.params?.csaSlug,
    route.params?.farmSlug,
    route.params?.productSlug,
  ])
}
