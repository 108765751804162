import { getCoordString } from '@helpers/coordinate'
import { AlgoliaGeoDoc } from '@models/Algolia'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentHover } from '../../../redux/actions/appState'
import { currentHoverSelector } from '../../../redux/selectors'
import { Marker } from './Marker'

/** Array of all markers to be rendered by the map component */
export function GenerateMarkers({
  hits,
  onMarkerPress,
}: {
  hits: AlgoliaGeoDoc[]
  onMarkerPress: ({ x, y, docs }: { x: number | undefined; y: number | undefined; docs: AlgoliaGeoDoc[] }) => void
}) {
  const sameGeoProds = new Map<string, AlgoliaGeoDoc[]>() //keys are coords, values are hits in that location
  const currentHover = useSelector(currentHoverSelector) //Becomes the coordstring of the hovered location
  const dispatch = useDispatch()

  //Group hits by location
  for (const hit of hits) {
    const hitCoord = getCoordString(hit._geoloc)
    if (sameGeoProds.has(hitCoord)) {
      sameGeoProds.get(hitCoord)?.push(hit)
    } else {
      sameGeoProds.set(hitCoord, [hit])
    }
  }

  return Array.from(sameGeoProds.entries()).map(([coordStr, coordHits]) => {
    //Only show the current marker as a gray dot if all the geodocs at this coord are from unregistered farms
    const isGrayDot = coordHits.every((doc: AlgoliaGeoDoc) => doc.farm.status !== 'Registered')

    //Set default styles
    let z = 2
    let isHovered = false

    if (isGrayDot) {
      z--
    }
    if (currentHover === coordStr) {
      z++
      isHovered = true
    }
    const curLabel = sameGeoProds.get(coordStr)?.length //Count of hits at this location
    const onHoverIn = () => {
      dispatch(setCurrentHover(coordStr))
    }

    const onHoverOut = () => {
      // dispatch(setCurrentHover(null))
      // let's keep the hover active for now. until a new item is hovered. it helps users find the hovered item in the scroll list. may be reverted once we implement list auto-scrolling to item that corresponds to hovered marker
    }
    const coords = coordHits[0]._geoloc

    return (
      <Marker
        key={coordStr}
        lat={coords.lat}
        lng={coords.lng}
        label={curLabel ? curLabel.toString() : ''}
        isGrayDot={isGrayDot}
        isHovered={isHovered}
        onPress={(evt) => {
          if (!evt) return
          if ('pageX' in evt.nativeEvent) {
            onMarkerPress({ x: evt.nativeEvent.pageX, y: evt.nativeEvent.pageY, docs: sameGeoProds.get(coordStr)! })
          } else {
            onMarkerPress({
              x: evt.nativeEvent.position?.x,
              y: evt.nativeEvent.position?.y,
              docs: sameGeoProds.get(coordStr)!,
            })
          }
        }}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        zIndex={z}
      />
    )
  })
}
