import { apiVersionSnapshot } from '@api/App'
import { loadFeatures } from '@api/FeatureFlag'
import { VersionBlocker } from '@components'
import { Modal } from '@elements'
import { openUrl } from '@helpers/client'
import { SignedInState } from '@models/User'
import * as Linking from 'expo-linking'
import * as Notifications from 'expo-notifications'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import env, { updatesAppVersion } from '../../config/Environment'
import Analytics from '../../config/FBAnalytics'
import { isWeb, setOrientation } from '../Layout'
import { isValidAPIVersionUsing } from '../Version'
import { useAutomaticUpdates } from './useAutomaticUpdates'
import { useConstantAppData } from './useConstantAppData'
import { useSetAvailAddons, useSetAvailAddonsPurchases } from './useSetAvailAddons'
import { useSetCartService } from './useSetCartService'
import { useSetLayout } from './useSetLayout'

import Sentry from '@/config/Sentry'
import { Logger } from '@/config/logger'
import { MAX_SESSION_DURATION } from '@/constants/CONSTANTS'
import { SET_SIGNEDIN_TIME } from '@/redux/actions/actionTypes'
import { setAdminFeaturesAvailable } from '@/redux/actions/adminState'
import { loadFarmAssociations, loadNotifications } from '@/redux/actions/appPersist'
import { setFeaturesAvailable, setListeners } from '@/redux/actions/appState'
import signout from '@/redux/actions/signout'
import { onUserAuthChange, setUserListener } from '@/redux/actions/user'
import { adminFarmIdSelector, hasListenersSelector, paramsSelector, userSelector } from '@/redux/selectors'
import { getStore } from '@/redux/store'
import loadGoogleMapsApi from 'load-google-maps-api'
import { DateTime } from 'luxon'

/** Sets global snapshot listeners for the signed in user account.
 * Warning: Having too many global listeners not managed properly may produce the error "Excessive number of pending callbacks" */
export const useConsumerListeners = () => {
  const dispatch = useDispatch()
  const hasListeners = useSelector(hasListenersSelector)

  /**
   * - Sets the global listeners for the signed in user account.
   * - if the user has signed in, it will check if the session exceeded the max duration and signout if so.
   */
  useEffect(() => {
    //Warning: Having more instances of `onUSerAuthChange` around the app may produce hard to debug "excessive pending callbacks" errors. This should be the only instance of this listener, and no others should be required
    return onUserAuthChange(({ state, userId }) => {
      const signedInTime = getStore().getState().appPersist.signedInTime

      const hasTimeRemaining = Boolean(
        signedInTime && signedInTime.plus({ milliseconds: MAX_SESSION_DURATION }) > DateTime.now(),
      )

      if (state === SignedInState.SIGNEDIN && !hasListeners && (!signedInTime || hasTimeRemaining)) {
        Logger.debug('Loading User Data')

        if (signedInTime === undefined) {
          //If signedInTime is undefined, we set it. Otherwise, we leave it as-is. We should not overwrite it again every time we reload the user data
          dispatch({ type: SET_SIGNEDIN_TIME, payload: DateTime.now() })
        }
        dispatch(setUserListener(userId))
        dispatch(loadFarmAssociations(userId))
        dispatch(loadNotifications(userId))
        dispatch(setListeners(true))
      } else if (state === SignedInState.SIGNEDIN && !hasTimeRemaining) {
        signout()
      } else if (state === SignedInState.SIGNEDOUT && getStore().getState().user?.id) {
        /** https://github.com/farmgenerations/grownby/issues/8099 */
        /** Firesbase internally will sign the user out, and this cause any page that requires user authorized or permission fails , so when user is signed out internally, we need to sign user out completely, and this could help to solve the cache issue. When you manually clear cookies or cache, you will be signed out. Thus, whenever state is SIGNEDOUT we need to fully sign out user  */
        signout()
      }
    })
  }, [hasListeners, dispatch])
}

/** Initialize error analytics and auth tracking */
export const useMonitoringServices = () => {
  const user = useSelector(userSelector)

  useEffect(() => {
    if (user) {
      Sentry.setUser(user)
      Analytics.setUser(user)
    }
  }, [user])
}

export const useSetFeaturesContext = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const adminFarmId = useSelector(adminFarmIdSelector)
  const { farm } = useSelector(paramsSelector)

  /** Sets the features available for the current admin farm */
  useEffect(() => {
    loadFeatures(adminFarmId, user.role)
      .then((features) => dispatch(setAdminFeaturesAvailable(features)))
      .catch((err) => Logger.error(err))
  }, [adminFarmId, user.role, dispatch])

  /** Sets the features available for the current farm in consumer screens */
  useEffect(() => {
    loadFeatures(farm?.id, user.role)
      .then((features) => dispatch(setFeaturesAvailable(features)))
      .catch((err) => Logger.error(err))
  }, [farm?.id, user.role, dispatch])
}

export const useVersionBlocker = () => {
  /** Enable the version blocker */
  useEffect(() => {
    // We want to get the native version from the device to see if they need to update from the app store.
    // For web the native version is the most recent update(expoConfig.version) as all its changes are pushed OTA
    return apiVersionSnapshot((required) => {
      const isValid = isValidAPIVersionUsing(updatesAppVersion, required)
      // Make sure app version is valid
      if (!isValid) Modal(<VersionBlocker />, { header: false, dismissable: false, webWidth: 400 })
    })
  }, [])
}

/** Listen for and handle push notification events.
 * - NOTE: This hook is not part of the main useAppInitialization because it was originally intended for the App.tsx, whereas the rest of the hooks here are intended for the Navigation container
 */
export const useNotifications = () => {
  useEffect(() => {
    const handleReceivedNotification = async (notification: Notifications.Notification) => {
      const data = notification.request.content.data
      if (data.url) {
        const url = Linking.createURL(data.url as string)
        await openUrl(url)
      }
    }
    const subscription = Notifications.addNotificationReceivedListener(handleReceivedNotification)

    return () => Notifications.removeNotificationSubscription(subscription)
  }, [])
}

export const useSetOrientation = () =>
  useEffect(() => {
    setOrientation()
  }, [])

/** Loads the google api library which is needed by places autocomplete in web */
export const useGoogleMapsApi = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!isWeb) return setLoading(false)

    loadGoogleMapsApi({ libraries: ['places'], key: env.API_KEY }).then(() => setLoading(false))
  }, [])

  return loading
}

/** Includes all the services necessary to initialize the app.
 * - Returns a boolean that specifies the initialization loading state. When loading state is true, the app should wait before first render */
export function useAppInitialization(): boolean {
  useSetOrientation()
  useSetAvailAddonsPurchases()
  useSetAvailAddons()
  useSetCartService()
  useAutomaticUpdates()
  useConstantAppData()
  useConsumerListeners()
  useMonitoringServices()
  useSetFeaturesContext()
  useVersionBlocker()
  const loadingLayout = useSetLayout()
  const loadingGoogleApi = useGoogleMapsApi()

  return loadingLayout || loadingGoogleApi
}
