import { payInvoiceOffline } from '@api/Invoices'
import { Alert, Button, Divider, hideModal, Toast } from '@elements'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

import { Logger } from '../../../../../config/logger'
import Colors from '../../../../../constants/Colors'

type FormType = {
  note: string
}

export function MarkPaid({ invoiceId, onPaid }: { invoiceId: string; onPaid?: () => void }) {
  const [loading, setLoading] = useState(false)

  const validationSchema = Yup.object<FormType>().shape({
    note: Yup.string(),
  })

  const onSubmitHandler = async (values: FormType) => {
    setLoading(true)
    try {
      await payInvoiceOffline(invoiceId, values.note || undefined)
      setLoading(false)
      hideModal()
      Alert('Success!', 'Invoice has been successfully marked as paid. The changes will show up shortly.', [
        {
          text: 'OK',
          onPress: onPaid,
          style: 'default',
        },
      ])
    } catch (e) {
      Logger.error(e)
      setLoading(false)
      hideModal()
      Toast('Could not mark invoice as paid, please try again later or contact support if this issue persists.')
    }
  }
  return (
    <Formik
      initialValues={{
        note: '',
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, handleSubmit, handleBlur }: FormikProps<FormType>) => (
        <View style={styles.container}>
          <Text>
            You can add a note for future reference like a check number or "paid in cash". This note will be shown on
            your customer's receipt.
          </Text>
          <Divider clear />
          <Input
            multiline
            numberOfLines={2}
            placeholder="Note"
            value={values.note}
            onChangeText={(val) => setFieldValue('note', val)}
            onBlur={handleBlur('note')}
          />
          <Button loading={loading} title="Submit" onPress={() => handleSubmit()} />
          <Text>
            Are you sure you want to mark this invoice as paid? Only do this if you accepted the payment outside of
            Stripe.
          </Text>
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  amountInput: {
    fontSize: 20,
    fontWeight: '600',
    margin: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['300'],
  },
  reloadCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
})
