import { getCoordString } from '@helpers/coordinate'
import { AlgoliaGeoDoc } from '@models/Algolia'
import { useContext, useEffect, useRef, useState } from 'react'
import { connectGeoSearch } from 'react-instantsearch-native'
import { Animated, ScrollView, View } from 'react-native'
import { useDispatch } from 'react-redux'

import Colors from '../../../constants/Colors'
import { isWeb } from '../../../constants/Layout'
import { useLayoutFnStyles } from '../../../hooks/useFnStyles'
import { useDeepCompareFocusFx } from '../../../hooks/useFocusFx'
import { useLayout } from '../../../hooks/useLayout'
import { setCurrentHover } from '../../../redux/actions/appState'
import PopUpCard from '../PopUpCard'
import { ExploreContext } from './ExploreContext'

type Props = {
  hits: AlgoliaGeoDoc[]
}

export const Carousel = connectGeoSearch(({ hits }: Props) => {
  const { width } = useLayout()
  const { showCarousel, setShowCarousel } = useContext(ExploreContext)
  const scrollRef = useRef<ScrollView>(null)
  const animation = useRef(new Animated.Value(0)).current
  const [scrollOffset, setScrollOffset] = useState({ x: 0, y: 0 })
  const timeOutId = useRef<NodeJS.Timeout>()
  const dispatch = useDispatch()

  useEffect(() => {
    // reset the scroll offset
    setScrollOffset({ x: 0, y: 0 })
  }, [])

  /** Prevents jumpiness in the carousel caused by the continuous nature of the animation value changes. By waiting some time, we only get the ending value of the x-offset, and thus we only set state once per dragging action. This also helps preserve the position of the carousel after it hides. */
  useDeepCompareFocusFx(() => {
    const listener = animation.addListener(({ value }) => {
      clearTimeout(timeOutId.current)

      timeOutId.current = setTimeout(() => {
        setScrollOffset({ x: value, y: 0 })
        const index = Math.round(value / (width * 0.9 + 10) + 0.3) // animate 30% away from landing on the next item
        const card = hits[index]
        if (card && card._geoloc.lat && card._geoloc.lng) dispatch(setCurrentHover(getCoordString(card._geoloc)))
      }, 100)
    })
    return () => animation.removeListener(listener)
  }, [hits, width])

  const dynamicStyles = useLayoutFnStyles((layout) => ({
    card: {
      borderRadius: 10,
      backgroundColor: Colors.white,
      margin: 5,
      width: layout.width * 0.9,
    },
    animatedContainer: {
      paddingLeft: 0,
      paddingRight: layout.width * 0.1 - 10,
    },
  }))

  return (
    <Animated.ScrollView
      ref={scrollRef}
      horizontal
      scrollEventThrottle={1}
      showsHorizontalScrollIndicator
      snapToInterval={width * 0.9 + 10}
      decelerationRate="fast"
      contentContainerStyle={dynamicStyles.animatedContainer}
      onScroll={Animated.event(
        [
          {
            nativeEvent: {
              contentOffset: {
                x: animation,
              },
            },
          },
        ],
        { useNativeDriver: !isWeb },
      )}
      contentOffset={scrollOffset}
    >
      {hits.map((hit) => (
        <View key={hit.id} style={dynamicStyles.card}>
          <PopUpCard
            data={[hit]}
            close={() => {}}
            showImage={showCarousel}
            onPressChevron={() => setShowCarousel(!showCarousel)}
          />
        </View>
      ))}
    </Animated.ScrollView>
  )
})
