import { PartialPick } from '@helpers/typescript'
import { DateTime } from 'luxon'

import { Farm } from './Farm'
import { Money } from './Money'
import { User } from './User'

export enum CouponType {
  Fixed = 'fixed',
  Percent = 'percent',
}

/** Discount is a combination of promo and coupon */
export type Discount = {
  /** If this is a consumer discount then there must be a promo code as well as a coupon */
  promo?: PromoCode
  /** For admin carts, they can specify only a coupon and do not need a promo code */
  coupon: Coupon
}

/** A coupon represents a collection a grouping of discounts with specific rules */
export type Coupon<Type = CouponType> = {
  /** The document identifier from Firestore */
  id: string

  /** The farm this coupon is for */
  farm: Pick<Farm, 'id'>

  /** The identifier for this coupon, ex. $10 OFF SPRING SALE */
  name: string

  /** The type of the coupon, either fixed or percent */
  type: Type

  /** Will be the value of the coupon, either money amount or percent amount. Percent represented as decimal, ex. 20% = 0.2 */
  value: Type extends CouponType.Fixed ? Money : Type extends CouponType.Percent ? number : never

  /** The number of times the coupon has been redeemed */
  timesRedeemed: number

  /** If the coupon is archived then all promo codes under it are also archived */
  archived?: boolean

  /** The below are options that allow specific requirements to be met for the coupon to work. EBT only means that this coupon can only be used with ebt payment methods. This is only an option for percentage coupons*/
  ebtOnly?: Type extends CouponType.Percent ? boolean : never

  /** A list of categories that this coupon can be applied to */
  categories?: string[]

  /** A list of producers that this coupon can be applied to */
  producers?: string[]
}

/** A promo code is a customer facing code for a coupon that can have additional requirements */
export type PromoCode<Type = CouponType> = {
  /** The document identifier from Firestore */
  id: string

  /** The coupon this promo code is associated with */
  coupon: Pick<Coupon<Type>, 'id' | 'farm'>

  /** The code assigned by the farmer, must be unique for that farm */
  code: string

  /** The below are options that allow specific requirements to be met for the promo code to work **/

  /** The customers for which this coupon is valid for */
  customers?: Pick<User, 'id' | 'email'>[]

  /** The minimum amount the order can be for the promo code */
  orderMinimum?: Money

  /** The maximum number of times this promo code can be redeemed */
  maxRedemptions?: number

  /** The number of times the promo code has been redeemed */
  timesRedeemed: number

  /** The expiration date of this promo code */
  expiration?: DateTime

  /** Will only allow this code to be used once per customer [DEFAULT=true]
   * This will not include any coupons that the admin apply
   */
  oncePerCustomer: boolean
}

export function isFixedCoupon(coupon: PartialPick<Coupon, 'type'>): coupon is Coupon<CouponType.Fixed> {
  return coupon.type === CouponType.Fixed
}

export function isPercentCoupon(coupon: PartialPick<Coupon, 'type'>): coupon is Coupon<CouponType.Percent> {
  return coupon.type === CouponType.Percent
}
