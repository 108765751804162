import * as React from 'react'

import { Picker, PickerProps } from './Picker'

type Props = {
  //Props specific to FormBuilder: They have no effect if this element is rendered outside the form-builder, because the DropDownPicker doesn't use them.
  errorMessage?: string
  label?: string | React.ReactNode
} & PickerProps

/**
 * A variation of DropdownPicker, with special changes for FormBuilder component.
 *
 * Use this if you're adding a picker element to the form builder. If you're using a dropdown picker elsewhere, use the regular DropdownPicker.
 * - If there's no placeholder, or it's loading, it will have a default placeholder with empty string as value, to prevent interference with Yup validation schemas
 */
export function FormPicker({ placeholder, loading, ...props }: Props) {
  return (
    <Picker
      {...props}
      placeholder={
        loading
          ? { label: 'Loading...', value: '' } // value must be empty to not upset validation schema
          : !placeholder
          ? { label: 'Select an Option', value: '' } // value must be empty to not upset validation schema
          : placeholder
      }
    />
  )
}
