import * as Yup from 'yup'

/**
 * Yup validation for address fields
 * If any of the address fields are filled, all of them must be filled
 */
export function isAddressRequired(this: Yup.StringSchema<string | undefined, object>, field: string) {
  return this.test(field, field + ' is required', function (this) {
    // formParent accesses the parent of the form and can use the form's values
    const formParent = this.parent

    // If any of the address fields are filled, all of them must be filled
    if (formParent.street1 || formParent.city || formParent.state || formParent.zipcode) {
      // if the field is filled then it has satisfied the test
      return !!formParent[field]
    }
    return true
  })
}
