import { PartialPick } from '@helpers/typescript'
import { Product } from '@models/Product'
import * as Yup from 'yup'

import { AdminProductsParamList, ProductDetailsRoutes } from '@/admin/navigation/types'
import { RouteProp } from '@react-navigation/native'

/** Route data used when building form values */
export type FormRouteData = Pick<RouteProp<AdminProductsParamList, ProductDetailsRoutes>, 'params' | 'name'>

/** Creates a formik component that can handle a portion of a larger form */
export class ProductFormikComponent<Type extends object> {
  /** Yup validation that will verify the subcomponent is valid */
  validator: Yup.ObjectSchema<Type>

  /** Will convert the product into the formik values.
   * @param routeData provides information about the screen navigation state, which is needed when generating the form values
   */
  toFormik: (prod: PartialPick<Product, 'type'>, routeData?: FormRouteData) => Type

  /** Will transform the formik values into a product */
  fromFormik: (values: Type) => Promise<Partial<Product>> | Partial<Product>

  constructor(
    validation: Yup.ObjectSchema<Type>,
    toFormik: (prod: PartialPick<Product, 'type'>, routeData?: FormRouteData) => Type,
    fromFormik: (values: Type) => Promise<Partial<Product>> | Partial<Product>,
  ) {
    this.validator = validation
    this.toFormik = toFormik
    this.fromFormik = fromFormik
  }
}
