import { Logger } from '@/config/logger'
import { useCreateSearchParams } from '@components'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { memo, useCallback } from 'react'
import { ImageBackground, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { LogoResponsive } from '../../admin/components/LogoResponsive'
import HamburgerMenu from '../../components/HamburgerMenu'
import ShoppingCart from '../../components/ShoppingCart'
import UserDropdown from '../../components/UserDropdown'
import { ButtonClear } from '../../components/elements/ButtonClear'
import { Divider } from '../../components/elements/Divider'
import { HeaderText } from '../../components/elements/Text'
import WebLink from '../../components/elements/WebLink'
import Colors from '../../constants/Colors'
import { DEVICE_SIZES, getDeviceSize, isWeb } from '../../constants/Layout'
import { HomeParamList } from '../../navigation/types'
import { setSearchLocation } from '../../redux/actions/appState'
import { RootState } from '../../redux/reducers/types'
import { isAdminSelector, searchLocationSelector, userLocationSelector } from '../../redux/selectors'
import { HomeSearchBar } from './HomeSearchBar'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useLayout } from '@/hooks/useLayout'

export const SmallHomeHeader = memo(function SmallHomeHeader() {
  const layout = useLayout()
  const isAdmin = useSelector<RootState, boolean | undefined>(isAdminSelector)
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<HomeParamList>>()
  const searchLoc = useSelector(searchLocationSelector)
  const userLoc = useSelector(userLocationSelector)

  const styles = useStyles()

  const showFarmerText = () =>
    (isAdmin === true || isAdmin === false) && getDeviceSize(layout.width) > DEVICE_SIZES.MEDIUM_DEVICE

  const goAdmin = useCallback(() => {
    if (isAdmin === undefined) {
      Logger.debug('Still loading farmer status')
    } else if (isAdmin) {
      navigation.navigate('AdminDrawerNavigator')
    } else navigation.navigate('FarmerWalkthroughNavigator')
  }, [isAdmin, navigation])

  const createSearchParams = useCreateSearchParams()

  const goToMapSearch = useCallback(() => {
    if (searchLoc || userLoc) dispatch(setSearchLocation({ ...(searchLoc ?? userLoc) }))
    navigation.navigate('ExploreScreen', createSearchParams({ coords: (searchLoc ?? userLoc)?.coordinate }))
  }, [createSearchParams, userLoc, dispatch, navigation, searchLoc])

  return (
    <View style={styles.wrapper}>
      <ImageBackground source={require('../../assets/images/home/home-header-small.jpg')} style={styles.fill}>
        <View style={styles.overImageLayer}>
          {isWeb ? (
            <View style={styles.header}>
              <HamburgerMenu clear />
              <LogoResponsive white style={styles.logo} />
              {showFarmerText() && (
                <ButtonClear
                  style={styles.btn}
                  color={Colors.white}
                  title={isAdmin ? 'Farmer Dashboard' : 'Start selling on GrownBy'}
                  onPress={goAdmin}
                />
              )}
              <View style={styles.leftContent}>
                <UserDropdown />
                <ShoppingCart color={Colors.white} />
              </View>
            </View>
          ) : (
            <View style={styles.logoCont}>
              <LogoResponsive white type="logo" />
              <View style={styles.shoppingCartCont}>
                <ShoppingCart color={Colors.white} />
              </View>
            </View>
          )}
          <View style={styles.searchWrapper}>
            <HeaderText style={styles.title} size={24}>
              Shop locally from farms
            </HeaderText>

            <Divider clear />

            <HomeSearchBar />
            {!isAdmin && (
              <WebLink url="/farm-onboard/walkthrough" style={styles.webLink}>
                Are you a farmer? Start selling on GrownBy
              </WebLink>
            )}
            <View style={styles.marginTop}>
              {layout.isSmallDevice && (
                <ButtonClear
                  size={16}
                  color={Colors.white}
                  title="Explore nearby farms"
                  onPress={goToMapSearch}
                  icon="map"
                />
              )}
            </View>
          </View>
        </View>
      </ImageBackground>
    </View>
  )
})

const useStyles = () =>
  useLayoutFnStyles(({ top }) => ({
    header: {
      flexDirection: 'row',
      padding: 10,
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
      paddingTop: top || 10,
    },
    logoCont: {
      alignItems: 'center',
      paddingTop: top,
    },
    webLink: {
      color: Colors.white,
      fontSize: 14,
      marginVertical: 10,
    },
    marginTop: {
      marginTop: 10,
    },
    btn: {
      justifyContent: 'center',
      flex: 0.3,
      flexGrow: 1,
    },
    logo: {
      padding: 10,
    },
    wrapper: {
      zIndex: 1,
    },
    fill: {
      flex: 1,
    },
    overImageLayer: {
      backgroundColor: Colors.black66,
    },
    searchWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      marginHorizontal: 20,
      marginTop: 40,
      marginBottom: 20,
    },
    title: {
      color: Colors.white,
    },

    leftContent: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: 10,
    },
    shoppingCartCont: {
      position: 'absolute',
      right: 8,
      bottom: 8,
    },
  }))
