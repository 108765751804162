import { Farm } from '@models/Farm'
import { arrayRemove, arrayUnion, collection, doc, getDoc, limit, where } from 'firebase/firestore'

import { loadProductsByFarm } from '@api/Products'
import { callEndpoint } from '@api/v2'
import { sortByName } from '@helpers/sorting'
import { Product } from '@models/Product'
import { EditLocalCategoryRequest, EditLocalCategoryResponse } from '@shared/types/v2/product'
import { db } from './db'
import { farmsCollection, productsCollection } from './framework/ClientCollections'

export type Category = {
  name: string
  isDefault: boolean
}

export type CategoryWithCount = Category & {
  count: number
}

/** This returns the default global categories only */
export async function loadDefaultCategories(): Promise<string[]> {
  const buyingOptions = await getDoc(doc(collection(db(), 'buying_options'), 'all_options'))
  return buyingOptions.get('categories')
}

/** This receives the farm-specific categories, and concatenates them with the global ones.
 * - Use this when you already have the farm data */
export async function loadFarmCategories(localCategories: Farm['localCategories']): Promise<Category[]> {
  const categories = await loadDefaultCategories()
  const formattedDefaultCategories = categories.map((category) => {
    return {
      name: category,
      isDefault: true,
    }
  })
  const formattedLocalCategories = localCategories?.map((category) => {
    return {
      name: category,
      isDefault: false,
    }
  })
  return formattedDefaultCategories.concat(...(formattedLocalCategories ?? [])).sort(sortByName)
}

/** fetches the entire farm categories only from the farm id.
 * - Use this when you don't have the farm data.
 */
export async function loadFarmCategoriesFromId(farmId: string) {
  const farm = await farmsCollection.fetch(farmId)
  return loadFarmCategories(farm.localCategories)
}

/** This receives the farm-specific custom category and add it to the farm firestore doc. */
export async function addLocalCategory(cat: string, currFarm: string): Promise<'already-exists' | 'added'> {
  const farm = await farmsCollection.fetch(currFarm)
  const currCatetories = (await loadFarmCategories(farm.localCategories)).map((category) => category.name)

  // block duplicates
  if (currCatetories.includes(cat)) {
    return Promise.resolve('already-exists')
  }

  await farmsCollection.update({
    id: currFarm,
    localCategories: arrayUnion(cat) as any,
  })

  return 'added'
}

/**
 * Count the number of products in each local category for a specific farm.
 * Each object contains a local category name as the key and the number of products in that category as the value.
 */
export async function listLocalCategoriesAndCountAssociatedProducts(
  farmId: string,
  localCategoriesFromFarm: Farm['localCategories'],
): Promise<Record<string, CategoryWithCount>[]> {
  const localCategories = await loadFarmCategories(localCategoriesFromFarm)
  const products = await loadProductsByFarm(farmId)

  const categoriesWithCount = countProductsPerCategory(localCategories, products)

  return Object.keys(categoriesWithCount).map((category) => ({
    [category]: {
      ...categoriesWithCount[category],
    },
  }))
}
/**
 * Delete a local category from a specific farm.
 * This function is useful for removing an existing category from a farm's categories.
 */
export async function deleteLocalCategoryFromFarm(farmId: string, categoryName: string): Promise<boolean> {
  try {
    // If there are no associated products, proceed with deletion
    await farmsCollection.update({
      id: farmId,
      localCategories: arrayRemove(categoryName) as any,
    })
    return true
  } catch (err) {
    return false
  }
}

/**
 * Edit a local category for a specific farm by updating the category name in-place.
 * This also updates the category name for all products and template products associated with the old category.
 */
export async function editLocalCategory(data: EditLocalCategoryRequest): Promise<EditLocalCategoryResponse> {
  return await callEndpoint('v2.Product.editLocalCategoryService', data)
}

/** Checks if at least one product exists with the given categoryName, on that farm */
export async function canDeleteLocalCategory(farmId: string, categoryName: string): Promise<boolean> {
  const prodsWithCat = await productsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where('category', '==', categoryName),
    limit(1),
  )
  return prodsWithCat.length === 0
}

function countProductsPerCategory(localCategories: Category[], products: Product[]): Record<string, CategoryWithCount> {
  const categoryCounts: Record<string, CategoryWithCount> = {}

  localCategories.forEach((localCategory) => {
    // Initialize each local category's count at zero
    categoryCounts[localCategory.name] = {
      ...localCategory,
      count: 0,
    }
  })

  products.forEach((product) => {
    if (product.category && categoryCounts[product.category]) {
      categoryCounts[product.category].count++
    }
  })

  return categoryCounts
}
