import { disableAddCartBtn, getCartItemForAddCartBtn, makeTestIdPrefix, Stepper } from '@components'
import { Button, Toast } from '@elements'
import { CSA } from '@models/CSA'
import { Distribution } from '@models/Distribution'
import { CartItem } from '@models/Order'
import { DigitalProduct, isStandard, PaymentSchedule, Share, Standard, Unit } from '@models/Product'
import { DateTime } from 'luxon'
import { memo, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useCartService } from '../../../../hooks/useCart'

import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'

type AddBtnShareProps = {
  cartItem?: CartItem
  prod: Share
  csa?: CSA
  paySchedule?: PaymentSchedule
  distro?: Distribution
  title: string
}

export const AddBtnShare = memo(function AddBtnShare({
  cartItem,
  prod,
  csa,
  paySchedule,
  distro,
  title,
}: AddBtnShareProps) {
  const key = makeTestIdPrefix(prod)
  const cartSrv = useCartService(true)
  const { addToCartFlow, addingToCart } = useAddToCartFlow(true)

  const updateQuantity = async (delta: number) => {
    if (!cartItem) return
    try {
      await cartSrv.updateQuantity(cartItem.id, cartItem.quantity + delta)
    } catch {
      Toast('Something went wrong while updating the item quantity')
    }
  }

  return cartItem && cartItem.paymentSchedule.frequency === paySchedule?.frequency ? (
    <Stepper
      style={styles.stepper}
      cartItem={cartItem}
      updateQuantity={updateQuantity}
      loading={cartSrv.loadingCart}
      disableAdd={disableAddCartBtn(prod, cartSrv.cart, [], undefined, 'admin')}
      key={key}
    />
  ) : (
    <Button
      disabled={!paySchedule}
      title={title}
      onPress={() => addToCartFlow({ product: prod, csa, paymentSchedule: paySchedule, distribution: distro })}
      loading={cartSrv.loadingCart || addingToCart}
      key={key}
      style={styles.addCartBtn}
    />
  )
},
propsAreDeepEqual)

type AddBtnStandardProps = {
  prod: Standard | DigitalProduct
  /** This 'unit' should come from the StandardRow */
  unit?: Unit
  /** the distro currently selected in the distro selector dropdown */
  distro?: Distribution
  /** the date currently selected in the date selector dropdown */
  pickupDate?: DateTime
}

export const AddBtnStandard = memo(function AddBtnStandard({
  prod,
  unit: unitProp,
  distro,
  pickupDate,
}: AddBtnStandardProps) {
  const cartSrv = useCartService(true)
  const unit = useMemo(() => unitProp ?? (prod.units.length === 1 ? prod.units[0] : undefined), [unitProp, prod])
  const itemInCart = useMemo(() => getCartItemForAddCartBtn(prod, cartSrv.cart, unit), [prod, cartSrv, unit])
  const { addToCartFlow } = useAddToCartFlow(true)

  const updateQuantity = async (delta: number) => {
    if (!itemInCart) return
    try {
      await cartSrv.updateQuantity(itemInCart.id, itemInCart.quantity + delta)
    } catch {
      Toast('Something went wrong while updating the item quantity')
    }
  }

  /** If there is a pickupDate selected as filter, we want to add the product with this date, but only it's a standard whose minPickups are either 1 or undefined */
  const preSelectedPickups = useMemo(() => {
    if (!pickupDate || !isStandard(prod) || (prod.minPickups ?? 1) > 1) return undefined
    else return [pickupDate]
  }, [prod, pickupDate])

  // It will return null for the parent rows, which display no button. The sub-rows will receive each unit
  if (!unit) return null

  return itemInCart ? (
    <Stepper
      style={styles.stepper}
      cartItem={itemInCart}
      updateQuantity={updateQuantity}
      loading={cartSrv.loadingCart}
      disableAdd={disableAddCartBtn(prod, cartSrv.cart, [], itemInCart.unit, 'admin')}
      key={makeTestIdPrefix(prod, unit)}
    />
  ) : (
    <Button
      title="Add"
      onPress={() =>
        addToCartFlow({
          product: prod,
          unit: unitProp,
          distribution: distro,
          pickups: preSelectedPickups,
        })
      }
      loading={cartSrv.loadingCart}
      key={makeTestIdPrefix(prod, unit)}
      style={styles.addCartBtn}
    />
  )
},
propsAreDeepEqual)

const styles = StyleSheet.create({
  stepper: { borderWidth: 0 },
  addCartBtn: {
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },
})
