import { ButtonClear, TextH2 } from '@elements'
import { Fontisto } from '@expo/vector-icons'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { GoogleLocationDetailResult } from 'react-native-google-autocomplete/dist/services/Google.service'
import { useSelector } from 'react-redux'

import { GooglePlacesSearch } from '../../components/GooglePlacesSearch'
import Colors from '../../constants/Colors'

import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { useLayout } from '@/hooks/useLayout'
import { searchLocationSelector } from '@/redux/selectors'

type Props = {
  onSelectCity: (item: AutoCompleteItem<GoogleLocationDetailResult>) => void
  onExplorePress: () => void
}

/** Displays the product search UI where the user can enter a city */
export const NearbyProdsSearch = memo(function NearbyProdsSearch({ onSelectCity, onExplorePress }: Props) {
  const { isSmallDevice } = useLayout()
  const searchLoc = useSelector(searchLocationSelector)

  /* If there's no searchLoc, hasCurrentLocation should be true. If a searchLoc becomes available through user action, hasCurrentLocation should become false, so we can see the searchLoc instead of the userLoc */
  const shouldShowCurrLoc = !searchLoc

  return (
    <View style={styles.screen}>
      <View style={styles.mainContent}>
        <TextH2 size={18}>Farms selling near </TextH2>
        <View style={styles.searchInputWrapper}>
          <View style={styles.iconCont}>
            <Fontisto name="map-marker-alt" size={20} />
          </View>
          <GooglePlacesSearch
            hasClearBtn
            types="geocode"
            style={styles.inputStyle}
            contStyle={styles.googleplacesCont}
            placeholderCurrLoc={shouldShowCurrLoc}
            onSelect={onSelectCity}
          />
        </View>
      </View>
      {!isSmallDevice && (
        <ButtonClear style={styles.exploreBtn} title="Explore on a map" onPress={onExplorePress} icon="map" />
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  exploreBtn: {
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  inputStyle: {
    fontSize: 18,
    height: 35,
  },
  googleplacesCont: {
    borderWidth: 1,
    width: 260,
    paddingLeft: 30,
    backgroundColor: Colors.white,
    borderColor: Colors.shades['100'],
  },
  searchInputWrapper: {
    marginLeft: 30,
    height: 35,
  },
  mainContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
  },
  screen: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconCont: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    width: 35,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
  },
})
