import Colors, { shadeColor } from '@/constants/Colors'
import { isMobile, isWeb } from '@/constants/Layout'
import { TextH2 } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { ShortCoord } from '@models/Coordinate'
import { memo } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, View } from 'react-native'
import { MapMarkerProps, MarkerPressEvent } from 'react-native-maps'

/** @param e in the mobile library it gets a MarkerPressEvent. In web it gets a regular GestureResponderEvent */
type MultiPlatformMarkerOnpress = (e: GestureResponderEvent | MarkerPressEvent) => void

/**
 * ShortCoord is here because it is a requirement for the web library to correctly position the coords
 * https://github.com/google-map-react/google-map-react/blob/HEAD/API.md
 */
export type MarkerProps = {
  label: string
  isGrayDot?: boolean
  isHovered?: boolean
  onHoverIn?: () => void
  onHoverOut?: () => void
  onPress: MultiPlatformMarkerOnpress
} & Pick<MapMarkerProps, 'zIndex' | 'style'> &
  ShortCoord

export const iconSize = 32 // Size of the icon

export const dotSize = 15

type MarkerContentProps = Partial<
  Pick<MarkerProps, 'isGrayDot' | 'isHovered' | 'zIndex' | 'label' | 'style' | 'onPress' | 'onHoverIn' | 'onHoverOut'>
>

export const MarkerContent = memo(function MarkerContent({
  isGrayDot,
  isHovered,
  zIndex,
  label,
  style,
  onPress,
  onHoverIn,
  onHoverOut,
}: MarkerContentProps) {
  const iconColor = isHovered ? shadeColor(Colors.blue, 1.7) : Colors.blue

  return (
    <Pressable
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPress={onPress}
      style={[styles.pressablePosition, { zIndex }]}
    >
      {!isGrayDot ? (
        <View style={[styles.iconContainer, style]}>
          <FontAwesome5 name="map-marker" color={iconColor} size={iconSize} />
          <View style={styles.textOverlay}>
            <TextH2 color={Colors.white} center size={isHovered ? 15 : 14} type={isHovered ? 'bold' : undefined}>
              {label !== '1' ? label : ''}
            </TextH2>
          </View>
        </View>
      ) : (
        <View style={[styles.dotMarker, isHovered && styles.dotIsHovered, style]} />
      )}
    </Pressable>
  )
})

export const styles = StyleSheet.create({
  dotMarker: {
    position: isWeb ? 'absolute' : undefined,
    top: isWeb ? -7 : undefined,
    left: isWeb ? -7 : undefined,
    borderRadius: 10,
    borderColor: Colors.white,
    borderWidth: 2,
    width: dotSize,
    height: dotSize,
    backgroundColor: Colors.shades[300],
  },
  dotIsHovered: {
    backgroundColor: Colors.shades[500],
    width: dotSize + 3,
    height: dotSize + 3,
    top: isMobile ? 0 : undefined, // if mobile set the top to 0 so that the dot is not cutted off (only appearing part off)
    left: isMobile ? 0 : undefined, // if mobile set the left to 0 so that the dot is not cutted off (only appearing part off)
  },
  textOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.transparent,
    alignItems: 'center',
    justifyContent: 'center',
    top: -4,
  },
  iconContainer: {
    position: isWeb ? 'absolute' : undefined,
    top: isWeb ? 0 : -4,
    left: 0,
    height: isWeb ? iconSize + 2 : iconSize + 12,
    width: iconSize,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pressablePosition: {
    position: isWeb ? 'absolute' : undefined,
    top: isWeb ? -20 : undefined,
    left: isWeb ? -24 : undefined,
  },
})
