import {
  Button,
  ButtonClear,
  ButtonGroup,
  ButtonGroupButton,
  Divider,
  ErrorText,
  Hoverable,
  Icon,
  LoadingView,
  Modal,
  Spinner,
  Text,
  hideModal,
} from '@elements'
import { Distribution } from '@models/Distribution'
import {
  LocationTypes,
  formatDistributionType,
  isDelivery,
  isLocalPickup,
  isNonPickup,
  isNonPickupDistLocation,
  isShipping,
} from '@models/Location'
import { PhysicalProduct } from '@models/Product'
import { useCallback, useEffect, useMemo } from 'react'
import { Pressable, View } from 'react-native'

import Colors from '../../constants/Colors'
import { useHitTrack } from '../../hooks/useHitTrack'
import { AppStatus } from '../AppStatus'

import { Logger } from '@/config/logger'
import { GoogleAddressParser } from '@/constants/GoogleAddressParser'
import { globalStyles } from '@/constants/Styles'
import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import useKeyedState from '@/hooks/useKeyedState'
import { useDeviceSizeFromWidth } from '@/hooks/useLayout'
import { useSelectorRoot } from '@/redux/selectors'
import { GooglePlacesSearch, ResponsiveGrid, makeEstablishment } from '@components'
import { formatAddress } from '@helpers/display'
import { errorToString, extendErr, isTruthy } from '@helpers/helpers'
import { isCompatibleLocation } from '@helpers/location'
import uuid from '@helpers/uuid'
import { isValidAddress } from '@models/Address'
import { UserAddress } from '@models/UserAddress'
import { isErrorWithCode } from '@shared/Errors'
import { ListRenderItemInfo } from '@shopify/flash-list'
import { GoogleLocationDetailResult } from 'react-native-google-autocomplete/dist/services/Google.service'
import { useAvailableSchedules } from './useAvailableSchedules'
import { useFilteredLocations } from './useFilteredLocations'

const hasShipping = (distros?: Distribution[]) => distros?.some(({ location }) => isShipping(location))
const hasDelivery = (distros?: Distribution[]) => distros?.some(({ location }) => isDelivery(location))
const hasPickup = (distros?: Distribution[]) => distros?.some(({ location }) => isLocalPickup(location))

/** Will return a location type intended to be used as a default initial filter, based on the available schedules of a product */
const getDefaultLocationType = (schedules: Distribution[]): LocationTypes | undefined =>
  hasPickup(schedules)
    ? LocationTypes.FARM
    : hasDelivery(schedules)
    ? LocationTypes.Delivery
    : hasShipping(schedules)
    ? LocationTypes.Shipping
    : LocationTypes.FARM

/** Props for the set location modal component */
type SetLocationProps = {
  prod: PhysicalProduct
  /** Selection will be limited to locations on these schedules */
  selectableSchedules?: Distribution[]
  /** Handles the result. In local pickup mode, the array will have a single location selected manually by the user. In nonPickup mode, the array will include any product locations compatible with the user address entered. */
  onSelect: (locs: Distribution['location'][]) => void | Promise<void>
  /** 'admin' mode is intended for different behavior in the order creator. Differences are: If admin is true, should ignore order cutoff window, should allow closed distros, and should pass this same flag to the Date Selector component */
  isAdmin?: boolean
}

/** Modal component that helps the user select location/s based on the product schedules.
 * - In the addtoCart flow, this is intended to be used through its wrapper function, not directly
 */
export function SetLocation({ prod, selectableSchedules, onSelect: onSelectProp, isAdmin = false }: SetLocationProps) {
  // Provide analytics data for the add to cart action.
  useHitTrack(prod.farm.id, 'addToCart')

  /** This user should be selected based on the isAdmin mode. For consumer, should select the signed in user. For admin should select the user selected in the order creator, which is stored in redux */
  const user = useSelectorRoot((rs) => (isAdmin ? rs.adminState.orderCreatorCustomer : rs.user))

  const [
    { locType, loc, locs, errorText, address, isCompatibleAddress, isEditingAddr, currWidth },
    set,
    setState,
    setters,
  ] = useKeyedState<{
    /** This location type is used to filter the product locations. It is initialized as undefined, and an effect will assign it a default value when the available schedules are defined. The user can then select a different value */
    locType?: LocationTypes
    /** The selected distro for pickup type */
    loc?: Distribution['location']
    /** locations compatible with the entered address, for nonpickup type */
    locs?: Distribution['location'][]
    errorText: string
    address?: UserAddress
    /** If null, the result is non-applicable. If boolean, means it is the result of an address' compatibility against the product's available locations for a nonpickup mode */
    isCompatibleAddress: boolean | null
    isEditingAddr: boolean
    /** The current width of the modal parent View */
    currWidth: number
  }>({
    locType: undefined,
    loc: undefined,
    locs: undefined,
    errorText: '',
    address: isValidAddress(user?.address) ? user!.address : undefined,
    isCompatibleAddress: null,
    isEditingAddr: !isValidAddress(user?.address),
    currWidth: 0,
  })

  const componentSizers = useDeviceSizeFromWidth(currWidth)
  const { isSmallDevice } = componentSizers

  /** These schedules are meant to be available for purchase, so they are expected to have pickups */
  const { availableSchedules: availSchedules } = useAvailableSchedules({
    prod,
    scheduleFilter: selectableSchedules?.map((sch) => sch.id),
    isAdmin,
  })

  /** Sets the initial location type based on the available schedules. The UI should wait for the locType to be defined */
  useEffect(() => {
    if (!availSchedules) return //wait till the array is defined
    set('locType', getDefaultLocationType(availSchedules)) // this setter should result in a defined locationtype after the available schedules is defined wether the array is empty or not
  }, [availSchedules, set])

  /** Gets locations of the selected type, from the available schedules. The UI should wait for the availLocs to be defined */
  const { locs: availLocs } = useFilteredLocations({ schedules: availSchedules, locationType: locType })

  const buttons: ButtonGroupButton[] = useMemo(
    () =>
      [
        hasPickup(availSchedules) && {
          label: isSmallDevice ? 'Pickup' : 'I want to pickup',
          onPress: () => set('locType', LocationTypes.FARM),
        },
        hasDelivery(availSchedules) && {
          label: isSmallDevice ? 'Delivery' : 'I want it delivered',
          onPress: () => set('locType', LocationTypes.Delivery),
        },
        hasShipping(availSchedules) && {
          label: isSmallDevice ? 'Shipping' : 'I want it shipped',
          onPress: () => set('locType', LocationTypes.Shipping),
        },
      ].filter(isTruthy),
    [availSchedules, set, isSmallDevice],
  )

  const styles = useStyles()

  const renderLocation = useCallback(
    ({ item }: ListRenderItemInfo<Distribution['location']>) => {
      return (
        <Hoverable>
          {(isHovered) => (
            <Pressable
              style={[styles.locCard, (item.id === loc?.id || isHovered) && styles.cardSelected]}
              onPress={() => setters.loc(item)}
            >
              <Icon name="map-marker-alt" color={Colors.green} style={styles.locCardMarker} />
              <View style={globalStyles.flex1}>
                <Text type="medium" size={16} numberOfLines={2}>
                  {item.name}
                </Text>
                {item.address && (
                  <Text numberOfLines={2} style={styles.locCardAddress}>
                    {formatAddress(item.address)}
                  </Text>
                )}
              </View>
            </Pressable>
          )}
        </Hoverable>
      )
    },
    [styles, setters, loc],
  )

  // On address change in nonPickup modes, checks if new address is compatible with the locations' regions
  useEffect(() => {
    if (!address || !availLocs || (locType && isLocalPickup(locType))) {
      // If we're not in nonPickup mode, or if the necessary variables aren't defined yet, we reset the nonPickup state
      return setState((prev) => ({ ...prev, isCompatibleAddress: null, locs: undefined }))
    }
    // Get the available locations compatible with the new address
    const compatibleLocs = availLocs
      .filter(isNonPickupDistLocation)
      .filter((availLoc) => isCompatibleLocation(availLoc, locType, address))

    setState((prev) => ({ ...prev, isCompatibleAddress: !!compatibleLocs.length, locs: compatibleLocs }))
  }, [address, locType, availLocs, set, setState])

  /** parses the address from the google place and sets address and error state */
  const setGooglePlace = useCallback(
    (item: AutoCompleteItem<GoogleLocationDetailResult>) => {
      try {
        const details = makeEstablishment(item)
        if (!details.address_components) {
          return setState((prev) => ({
            ...prev,
            address: undefined,
            errorText: 'Something went wrong while getting the address. ',
          }))
        }
        const address = new GoogleAddressParser(details.address_components).getAddress()
        setState((prev) => ({
          ...prev,
          address: { ...address, id: uuid(), coordinate: details.coordinate },
          errorText: '',
          isEditingAddr: false,
        }))
      } catch (error) {
        extendErr(error, 'There is a problem with the delivery address.\n\n')

        /** This is intended to only log the error if something unexpected happened with the address parsing.
         * Validation errors should not be logged because they will happen when the address is invalid, which means validation errors are not bugs */
        if (isErrorWithCode(error, 'ParsingError')) {
          // If it's a parsing error, means something unexpected went wrong
          Logger.error(error)
        }
        const errorText = errorToString(error)
        setState((prev) => ({ ...prev, address: undefined, errorText }))
      }
    },
    [setState],
  )

  /** Returns the result. In localPickup mode, returns the location selected. In nonPickup, returns the locations compatible with the address */
  const onSelect = useCallback(() => {
    if (!locType) return

    if (isLocalPickup(locType) && loc) {
      onSelectProp([loc])
    } else if (isNonPickup(locType) && address && locs && isCompatibleAddress) {
      // For nonPickup mode, it must assign the address to each location compatible with the address
      locs.forEach((loc) => (loc.address = address))
      onSelectProp(locs)
    }
  }, [onSelectProp, loc, locType, locs, address, isCompatibleAddress])

  /** Whether the requirements are met for the current location type mode */
  const canContinue = useMemo(
    () => (!locType ? false : isLocalPickup(locType) ? !!loc : !!isCompatibleAddress),
    [loc, isCompatibleAddress, locType],
  )

  return (
    <AppStatus scope="consumer">
      <View style={styles.container} onLayout={(evt) => setters.currWidth(evt.nativeEvent.layout.width)}>
        {buttons.length > 1 && <ButtonGroup buttons={buttons} />}
        {!locType || !availLocs ? (
          <Spinner />
        ) : isNonPickup(locType) ? (
          <View style={styles.nonPickupCont}>
            <View style={styles.halfContainersOnLarge}>
              <Divider clear large />
              {isEditingAddr || !address ? (
                <>
                  <Text>Enter your {formatDistributionType({ type: locType })} address.</Text>
                  <View style={styles.searchInputWrapper}>
                    <View style={styles.iconCont}>
                      <Icon iconSet="Fontisto" name="map-marker-alt" size={20} color={Colors.black} />
                    </View>
                    <GooglePlacesSearch
                      contStyle={styles.inputCont}
                      style={styles.inputStyle}
                      types="geocode"
                      selectTextOnFocus
                      onSelect={setGooglePlace}
                      noInitialValue
                      placeholder={user?.address ? formatAddress(user.address) : 'Enter address'}
                      inline
                      hasClearBtn
                    />
                  </View>
                </>
              ) : (
                <>
                  <Text>{[<Icon key="marker" name="map-marker-alt" size={14} />, '  ', formatAddress(address)]}</Text>
                  <ButtonClear title="Edit" onPress={() => setters.isEditingAddr(!isEditingAddr)} />
                </>
              )}
              <Divider clear large />
            </View>
            <View style={styles.halfContainersOnLarge}>
              <Divider clear large />
              {!!errorText && <ErrorText>{errorText}</ErrorText>}
              {isCompatibleAddress === true ? (
                <View style={styles.addressValidityView}>
                  <Icon name="check-circle" color={Colors.green} style={styles.addressValidityIcon} />
                  <Text>
                    {locType === LocationTypes.Shipping
                      ? 'Hooray! We can ship to you!'
                      : 'Hooray! Delivery is available in your area!'}
                  </Text>
                </View>
              ) : isCompatibleAddress === false ? (
                <View style={styles.addressValidityView}>
                  <Icon name="exclamation-circle" color={Colors.red} style={styles.addressValidityIcon} />
                  <ErrorText>
                    Unfortunately, we do not have {formatDistributionType({ type: locType })} for this address.
                  </ErrorText>
                </View>
              ) : null}
              <Divider clear large />
            </View>
          </View>
        ) : (
          <LoadingView loading={!locType || !availLocs} style={globalStyles.flex1}>
            <Divider clear />
            <Text>Choose your pickup location</Text>
            <ResponsiveGrid
              estimatedItemSize={100}
              itemBaseWidth={300}
              renderItem={renderLocation}
              data={availLocs}
              extraData={loc} // extraData={loc} is necessary to update the component when there is a change in renderLocation, because `loc` it's a dependency of renderLocation, but this is a pure component so renderItem won't update the component by itself.
              scrollEnabled
            />
          </LoadingView>
        )}
        <View style={styles.footer}>
          <Button title="Next: Choose schedule" onPress={onSelect} disabled={!canContinue} />
        </View>
      </View>
    </AppStatus>
  )
}

export const useStyles = () =>
  useLayoutFnStyles((layout) => ({
    container: {
      flex: 1,
      backgroundColor: Colors.white,
      padding: 15,
      /** It's only using the bottom inset on small size because the mobile large modal by default is a window that floats in the center of the screen, and is far away from the screen edges. */
      paddingBottom: layout.isSmallDevice ? layout.bottom : undefined,
      borderRadius: 10,
    },
    nonPickupCont: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    halfContainersOnLarge: {
      flexBasis: 400, // The flexBasis is the expected width of each horizontal container
      flex: 1,
    },
    footer: {
      width: '100%',
      alignItems: 'flex-end',
    },
    locCard: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: 10,
      padding: 10,
      borderWidth: 1,
      borderColor: Colors.shades['100'],
      borderRadius: 10,
      flex: 1,
      //Ensures height consistency across lines differences inside cards
      minHeight: layout.isExtraSmallDevice ? 100 : 120,
    },
    cardSelected: {
      borderWidth: 1,
      borderColor: Colors.green,
      backgroundColor: Colors.lightGray,
    },
    locCardMarker: {
      marginRight: 10,
    },
    locCardAddress: {
      marginVertical: 5,
      textDecorationLine: 'underline',
    },
    searchInputWrapper: {
      margin: 10,
    },
    iconCont: {
      position: 'absolute',
      left: 0,
      top: 0,
      height: 35,
      width: 35,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 5,
    },
    inputCont: {
      borderWidth: 1,
      borderRadius: 20,
      paddingLeft: 30,
      backgroundColor: Colors.white,
      borderColor: Colors.shades['100'],
    },
    inputStyle: {
      fontSize: 18,
      height: 35,
    },
    addressValidityView: {
      /** This 90% is not necessary in web, but it is needed in ipad. Do not remove without testing in ipad */
      width: '90%',
      flexDirection: 'row',
      alignItems: 'center',
    },
    addressValidityIcon: {
      marginRight: 10,
    },
  }))

/** Options for the setLocation flow function that handles the set location modal component */
type SetLocationOpts = Omit<SetLocationProps, 'onSelect'> & {
  modalOpts?: Omit<Parameters<typeof Modal>[1], 'onDismiss'>
}

/**
 * Given a product, this flow function will return an array of locations from the product schedules, which were selected by the user. If the user selects a localPickup location, the array will have a single one. If the user selects a nonPickup option, the array will include any locations compatible with the address they entered.
 *
 * - The set location modal should be accessed only through this fn */
export function setLocation({
  prod,
  selectableSchedules,
  isAdmin,
  modalOpts,
}: SetLocationOpts): Promise<Distribution['location'][] | undefined> {
  return new Promise((resolve) => {
    Modal(
      <SetLocation
        prod={prod}
        selectableSchedules={selectableSchedules}
        onSelect={(locs) => {
          hideModal()
          resolve(locs)
        }}
        isAdmin={isAdmin}
      />,
      {
        webWidth: 1000,
        title: 'How will you get your order?',
        noPadding: true,
        ...modalOpts,
        onDismiss: () => {
          hideModal()
          resolve(undefined)
        },
      },
    )
  })
}
