import { Modal, Text, TextH4 } from '@elements'
import { formatDateWithMonth, formatMiniDate, formatPickupTime } from '@helpers/display'
import { isNonNullish } from '@helpers/helpers'
import { isAfter } from '@helpers/time'
import { formatDistributionType, LocationTypes } from '@models/Location'
import { isPickupItemActive, Order, OrderItem, Pickup, PickupItem } from '@models/Order'
import { dateTimeInZone } from '@models/Timezone'
import { User, userName } from '@models/User'
import { CancelPickup } from '@screens/Orders/CancelPickup'
import { StyleSheet, ViewStyle } from 'react-native'

import { ActionBtn, ActionsMenuComponent } from '@/admin/components/elements/ActionsMenuComponent'
import { ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import Colors from '@/constants/Colors'
import { Reschedule } from '@screens/Orders/Reschedule'

import { configurePickupItemStatus } from '../../helpers/pickupStatusHelper'

/** Sub rows for level 2: From order items to pickup dates, for orderItems of multiPickup standard products */
export function SubRowsPickups(
  /** The pickups for the current orderItem, if any */
  multiStandardPickups: Pickup[],
  adminOrders: Order[],
  /** This would be used in pickupItems in line 34 */
  orderId: string,
  parentIndex: number,
  /** callback to invoke after making changes to pickups */
  reload: () => void,
  hasAccessEditOrder: boolean,
  customer?: User,
) {
  return (orderItem: OrderItem) => {
    if (multiStandardPickups.length < 1) return

    let rowIndex = 0
    const allRows = multiStandardPickups
      .map((pickup) => {
        const now = dateTimeInZone(pickup.date.zoneName)
        const isPast = isAfter(now, pickup.date, { granularity: 'day', zone: pickup.date.zoneName })

        const pickupItems = pickup.items.filter((itm) => itm.id === orderItem.id && itm.orderId === orderId)

        if (pickupItems.length < 1) return

        return pickupItems
          .map((pickupItem) => {
            const dateProcess = () => (
              <Text color={isPast ? Colors.shades['400'] : undefined}>{formatMiniDate(pickup.date)}</Text>
            )

            const rangeHourProcess = () => (
              <Text color={isPast ? Colors.shades['400'] : undefined}>
                {formatPickupTime(pickup.distribution.hours, pickup.distribution.locationType)}
              </Text>
            )

            const distroProcess = () => (
              <Text numberOfLines={3} color={isPast ? Colors.shades['400'] : undefined}>
                {pickup.distribution.name}
              </Text>
            )

            const locationProcess = () => (
              <Text numberOfLines={3} color={isPast ? Colors.shades['400'] : undefined}>
                {pickup.distribution.locationName}
              </Text>
            )

            const statusProcess = () => (
              <Text numberOfLines={3} color={isPast ? Colors.shades['400'] : undefined}>
                {configurePickupItemStatus(pickup, pickupItem)}
              </Text>
            )

            //cancel standard individual pickup
            const cancelPickup = (curPickup: Pickup) => () => {
              const cancelTitle =
                curPickup.distribution.locationType === LocationTypes.Delivery
                  ? 'Delivery to'
                  : curPickup.distribution.locationType === LocationTypes.Shipping
                  ? 'Shipping to'
                  : 'Pickup at'

              if (curPickup)
                Modal(
                  <CancelPickup
                    type="claim"
                    pickup={curPickup}
                    pickupItemFilter={(itm) => itm.id === orderItem.id && itm.orderId === orderId}
                    orders={adminOrders}
                    customerName={customer ? userName(customer) : undefined}
                    onSuccess={() => setTimeout(reload, 2000)}
                    isAdmin
                  />,
                  {
                    title: `${cancelTitle} ${curPickup.distribution.name}: ${formatDateWithMonth(curPickup.date)}`,
                  },
                )
            }

            // generate the action buttons
            const actionButtons: ActionBtn[] = [
              {
                title: 'Reschedule',
                onPress: reschedule(pickup, pickupItem.id, orderId, adminOrders, reload),
              },
              {
                title: `Cancel ${formatDistributionType({ type: pickup.distribution.locationType })}`,
                onPress: cancelPickup(pickup),
              },
            ]

            /**
             * actionDisabled is used to disable the action buttons for the standard pickupItems
             * 1. If the pickup is in the past
             * 2. If the user does not have access to edit Order
             * 3. If the pickupItem is not  active
             */
            const actionDisabled = !hasAccessEditOrder || !isPickupItemActive(pickupItem) || isPast

            const actionProcess = () => <ActionsMenuComponent buttons={actionButtons} disabled={actionDisabled} />

            const columnsData = [
              { process: dateProcess, widthFlex: 2 },
              { process: rangeHourProcess, widthFlex: 1.5 },
              { widthFlex: 1 },
              { process: distroProcess, widthFlex: 2 },
              { process: locationProcess, widthFlex: 1.85 },
              { process: statusProcess, widthFlex: 1.8 },
              { process: actionProcess, widthFlex: 1.2 },
            ]
            return (
              <ExpandableRow
                key={`standardMultiPickup-${orderItem.id}-${pickup.id}-${rowIndex}`}
                columns={columnsData}
                item={pickupItem}
                index={rowIndex}
                rowContainerStyle={styles.rowCont(parentIndex, rowIndex++)}
              />
            )
          })
          .filter(isNonNullish)
          .flat()
      })
      .filter(isNonNullish)
      .flat()

    return [
      <ExpandableRow
        key="standardMultiPickup-header"
        columns={columnsData}
        item={orderItem}
        index={rowIndex++}
        rowContainerStyle={reactNavStyles.subRowHeader}
      />,
      ...allRows,
    ]
  }
}

const styles = {
  rowCont: (parentIndex: number, rowIndex: number): ViewStyle => ({
    borderWidth: 0,
    paddingVertical: 4,
    backgroundColor:
      parentIndex % 2 === 0
        ? rowIndex % 2 === 0
          ? undefined
          : Colors.lightGray
        : rowIndex % 2 === 0
        ? Colors.lightGray
        : undefined,
  }),
}

const reactNavStyles = StyleSheet.create({
  marginHorizontal2: { marginHorizontal: 2 },
  blueText: { color: Colors.blue, textDecorationLine: 'underline' },
  grayText: { color: Colors.shades['300'], textDecorationLine: 'underline' },
  subRowHeader: { paddingVertical: 2 },
})

/** custom sub column for subRowsPickups */
const columnsData = [
  { process: () => <TextH4 size={10}>Date</TextH4>, widthFlex: 2 },
  { process: () => <TextH4 size={10}>Time</TextH4>, widthFlex: 1.5 },
  { widthFlex: 1 },
  { widthFlex: 2 },
  { widthFlex: 1.85 },
  { process: () => <TextH4 size={10}>Status</TextH4>, widthFlex: 1.8 },
  { process: () => <TextH4 size={10}>Action</TextH4>, widthFlex: 1.2 },
]

/** local reschedule helper function to reschedule only chosen item */
const reschedule =
  (pickup: Pickup, orderItemId: string, orderId: string, orders: Order[], reload: () => void) => () => {
    //the reschedule Item should only display this item and also only in this order
    let oneItemArray: PickupItem[] = []
    oneItemArray = pickup.items.filter((pItem) => pItem.id === orderItemId && pItem.orderId === orderId)
    const newPickup = { ...pickup, items: oneItemArray }

    Modal(<Reschedule pickup={newPickup} orders={orders} onSuccess={() => setTimeout(reload, 3000)} isAdmin />)
  }
