import { loadFuturePickupsByUser } from '@api/Pickups'
import { Alert } from '@elements'
import { formatAddress, formatMoney, unmarshalPhoneNumber } from '@helpers/display'
import { groupBy, haveSameItems } from '@helpers/helpers'
import { createAddressString, getDeliveryFee, getUniqueDates, NoDeliveryFees } from '@helpers/location'
import { MoneyCalc } from '@helpers/money'
import { calculatePayments } from '@helpers/order'
import { CoverFee, CoverOptId, getServiceFeeAmountFromTender } from '@helpers/serviceFee'
import { isAfter } from '@helpers/time'
import { isValidAddress } from '@models/Address'
import { isNonPickup } from '@models/Location'
import { Zero } from '@models/Money'
import { CartItem, isCartPhysical, isCartShare, SplitTenderPayment } from '@models/Order'
import { isEbtPayment, isFarmCreditPayment } from '@models/PaymentMethod'
import { User } from '@models/User'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { createContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFarmProp } from '../../../hooks/useFarmProp'
import { RootState } from '../../../redux/reducers/types'
import { consumerCartInfoSelector, userSelector } from '../../../redux/selectors'
import { CartItemGroupType } from './CartItemsCheckout'
import { billingAddressSchema } from './CheckoutContact'
import {
  BillingAddressFormType,
  getTotalAfterFeesNCredit,
  hasInstallments,
  initialTotal,
  makeTouched,
  Touched,
} from './helpers'
import useConfirmLeaveCheckout from './useConfirmLeaveCheckout'
import { useHandleAddOrder } from './useHandleAddOrder'
import { usePlaceOrder } from './usePlaceOrder'
import { usePreCheckout } from './usePreCheckout'
import { useValidateBillingAddress } from './useValidateBillingAddress'

import { Logger } from '@/config/logger'
import { useApiFx } from '@/hooks/useApiFx'
import { useCartService } from '@/hooks/useCart'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { useValidateCartAlert } from '@/hooks/useValidateCart'
import { ShoppingStackParamList } from '@/navigation/types'
import { ProductFeesForInvoice } from '@helpers/productFee'
import { pick } from '@helpers/typescript'
import { ProductType } from '@models/Product'
import { isFeeProductFee, isTaxProductFee } from '@models/ProductFee'
import { DateTime } from 'luxon'
import { Total } from '../../../constants/types'
import { PaymentSelectorOptions } from '../../PaymentMethods/PaymentSelection/helpers/types'

type CheckoutStateType = {
  formValidationLoading: boolean
  payMethodLoading: boolean
  /** Whether the cart was checked on screen load */
  initialCartCheckDone: boolean
  touched: Touched
  /** Ids of cartItems with errors */
  errors: string[] | undefined
  coverFee: CoverFee
  total: Total
  splitTender: SplitTenderPayment | undefined
  /** Additional fees that should be applied on products */
  additionalFees: ProductFeesForInvoice
}

export const checkoutInitialState: CheckoutStateType = {
  formValidationLoading: false,
  payMethodLoading: true,
  initialCartCheckDone: false,
  touched: {},
  errors: [],
  coverFee: { id: CoverOptId.None, value: Zero, tip: Zero, applyToInstallments: true },
  total: initialTotal,
  splitTender: undefined,
  additionalFees: [],
}

/** This hook will provide the data used by the checkout screen UI
 * - Anything not already available through other hooks should be returned from here
 * - Anything that would make JSX hard to read should go here
 */
export function useCheckoutData() {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'Checkout'>>()
  const { cartFarmId } = useSelector(consumerCartInfoSelector)
  const { cart, discount, loadingCart } = useCartService()
  const [
    {
      coverFee,
      errors,
      formValidationLoading,
      payMethodLoading,
      splitTender,
      total,
      additionalFees,
      touched,
      initialCartCheckDone,
    },
    set,
    ,
    setters,
  ] = useKeyedState(checkoutInitialState)
  const disableConfirmations = useConfirmLeaveCheckout(!!cart.length, navigation)
  const user = useSelector<RootState, User>(userSelector)
  const { data: farm, loading: loadingFarm, err: errorFarm } = useFarmProp({ farmSlug: cartFarmId })
  const validateCartAlert = useValidateCartAlert({ isAdmin: false })
  const { data: futurePickups, loading: loadingPickups } = useApiFx(loadFuturePickupsByUser, [user.id], !!user.id, {
    onError: (err) => Logger.error(err),
  })

  /** PO should be allowed when validating the current default address because here it is used for billing purposes.
   * - We might later need to differentiate between billing vs delivery addresses
   */
  const shouldRequireAddress = !isValidAddress(user.address, { allowPO: true })

  /** Initial address form values.
   * - Form should re-initialize if these change. That would help in case there's an invalid address, so it can show the most updated data */
  const initialAddressValues: BillingAddressFormType = useDeepCompareMemo(
    () => ({
      phoneNumber: user.phoneNumber ? unmarshalPhoneNumber(user.phoneNumber, false) : '', //Unmarshall with no country code
      street1: user.address?.street1 || '',
      street2: user.address?.street2 || '',
      city: user.address?.city || '',
      state: user.address?.state || '',
      zipcode: user.address?.zipcode || '',
    }),
    [user.address, user.phoneNumber],
  )
  /** The form schema: Only defined if the user address isn't valid */
  const schema = shouldRequireAddress ? billingAddressSchema : undefined

  /** The amount that is set to be paid with EBT */
  const ebtPmtAmount = useMemo(() => splitTender?.find((pay) => isEbtPayment(pay.paymentMethod)), [splitTender])?.amount

  /** The total delivery fees for the entire cart due now */
  const deliveryFeesTotalDueNow = useMemo(() => {
    if (loadingPickups || !futurePickups) return Zero
    return getDeliveryFee(
      [], // TODO: temporarily passing an empty cart array, for forcing Zero delivery fees due now. Later this might have the amount for first delivery/ies
      { pickups: futurePickups },
    ).itemsDeliveryFees
  }, [futurePickups, loadingPickups])

  /** The total delivery fees for the entire cart, regardless of date due */
  const { itemsDeliveryFees: deliveryFeesTotal } = useMemo(() => {
    if (loadingPickups || !futurePickups) return NoDeliveryFees
    return getDeliveryFee(cart, { pickups: futurePickups })
  }, [cart, futurePickups, loadingPickups])

  const serviceFeeAmount = useMemo(() => {
    const totalTaxAndFee = MoneyCalc.add(total.tax, ...additionalFees.map((v) => v.amount))
    // The portion we apply fees to should be based on the total after discounts, however we don't want to include delivery fees or taxes and product fees
    const totalForFees = MoneyCalc.subtract(MoneyCalc.subtract(total.total, deliveryFeesTotalDueNow), totalTaxAndFee)

    return splitTender
      ? getServiceFeeAmountFromTender(splitTender, totalForFees, farm?.pricingModel?.appFeePercent)
      : Zero
  }, [total, splitTender, deliveryFeesTotalDueNow, farm?.pricingModel?.appFeePercent, additionalFees])

  /** Update the 'touched' obj for Payment Schedules whenever the items in cart are added/ removed.
   * - This is unrelated to the address formik form. This is for the payment schedules component, which doesn't need formik
   */
  useFocusFx(() => {
    //Only run if the item ids don't match
    if (
      !haveSameItems(
        cart.map((itm: CartItem) => itm.id),
        Object.keys(touched),
      )
    ) {
      set('touched', makeTouched(cart, touched))
    }
  }, [cart, touched, set])

  const validateAddress = useValidateBillingAddress(set, shouldRequireAddress)

  /** Exit checkout if no farmId or no items in cart. */
  useFocusFx(() => {
    // This check is intended to only run at initial screen load, and not check ever again.
    if (initialCartCheckDone || loadingCart) return

    if (!cart.length) {
      disableConfirmations()
      Alert('Empty Cart', 'There are no items in your cart. Please add some products before checking out.')
      return cartFarmId ? navigation.navigate('FarmShop', { farmSlug: cartFarmId }) : navigation.navigate('Home')
    }
    set('initialCartCheckDone', true)
  }, [loadingCart, cartFarmId, cart, initialCartCheckDone, navigation, set, disableConfirmations])

  const itemGroups: CartItemGroupType[] = useMemo(() => {
    // groups of cart items which have the same address
    return groupBy(cart, (itm) =>
      isCartPhysical(itm)
        ? //group items by distro id and address.
          itm.distribution.location.id + createAddressString(itm.distribution.location.address!)
        : 'digital',
    ).map((group): CartItemGroupType => {
      const locId = group[0].distribution?.location.id
      const { itemsDeliveryFees, combinedDates, combinedPickups } = getDeliveryFee(group, {
        pickups: futurePickups,
        locId,
      })

      return {
        items: group,
        address: isCartPhysical(group[0]) ? formatAddress(group[0].distribution.location.address!) : undefined,
        locationFee:
          isCartPhysical(group[0]) && isNonPickup(group[0].distribution.location)
            ? group[0].distribution.location.cost
            : undefined,
        groupDeliveryTotal: itemsDeliveryFees,
        groupDeliveryTotalDueNow: undefined, //hard-coded because we invoice delivery fees always in the future
        combinedDeliveryDates: combinedDates,
        combinedDeliveryPickups: combinedPickups,
        uniqueDates: getUniqueDates(group),
        locType: isCartPhysical(group[0]) ? group[0].distribution.location.type : undefined,
      }
    })
  }, [cart, futurePickups])

  /** This holds all options for configuring the payment selector */
  const paymentSelectorOptions: PaymentSelectorOptions | undefined = useMemo(() => {
    if (!farm) return undefined
    return {
      farm: pick(farm, 'id', 'name', 'offlinePayments', 'paymentTypes'),
      amountTotal: MoneyCalc.add(total.total, coverFee.value || Zero),
      amountEbt: total.ebtEligibleAmount ?? Zero,
      hasInstallments: hasInstallments(cart),
      hasDelivery: MoneyCalc.isGTZero(deliveryFeesTotal),
      hasFarmBalanceItem: cart.some((ci) => ci.product.type === ProductType.FarmBalance),
      // If there is a total then it means the invoice is due now
      isInvoiceDueToday: MoneyCalc.isGTZero(total.total),
      allowOfflinePayments: true,
    }
  }, [cart, deliveryFeesTotal, total, farm, coverFee.value])

  const handleAddOrder = useHandleAddOrder(coverFee, total, disableConfirmations)
  const placeOrder = usePlaceOrder({
    total,
    handleAddOrder,
    disableConfirmations,
    validateCartAlert,
    splitTender,
    cartHasShares: cart.some(isCartShare),
  })
  const preCheckout = usePreCheckout(set, touched, placeOrder)

  /** Gets the cart total amounts */
  useFocusFx(() => {
    if (!cart.length || !farm?.timezone) return

    const payments = calculatePayments({ items: cart, discount }, splitTender)
    if (!payments) return
    // If there is no payment due today don't list it in the checkout page
    if (isAfter(payments[0].date, DateTime.now(), { granularity: 'day', zone: farm.timezone })) {
      setters.total(initialTotal)
      setters.additionalFees([])
      return
    }

    set('additionalFees', payments[0].taxesAndFees?.filter((itm) => isFeeProductFee(itm.productFee)) ?? [])

    const totalTaxesAndFees =
      payments[0].taxesAndFees?.reduce((acc, itm) => MoneyCalc.add(acc, itm.amount), Zero) ?? Zero

    const tax =
      payments[0].taxesAndFees
        ?.filter((itm) => isTaxProductFee(itm.productFee))
        .reduce((acc, itm) => MoneyCalc.add(acc, itm.amount), Zero) ?? Zero

    set('total', {
      subtotal: MoneyCalc.add(payments[0].subtotal, deliveryFeesTotalDueNow),
      total: MoneyCalc.add(payments[0].total, deliveryFeesTotalDueNow, totalTaxesAndFees),
      discounts: payments[0].discounts,
      ebtEligibleAmount: payments[0].ebtEligibleAmount,
      tax,
    })
    // splitTender is intentionally left out because we only need to update it when discounts change. Otherwise, there will
    // be an infinite loop of discount making total less which makes splitTender less which makes discount less and so on.
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discount, farm?.timezone, set, deliveryFeesTotalDueNow])

  /** The amount of farm credit applied for the order */
  const farmCreditAppliedAmount = splitTender?.find((pay) => isFarmCreditPayment(pay.paymentMethod))?.amount ?? Zero

  /** Disable when no selected payment unless it can be paid only with FC */
  const disablePlaceOrder: boolean = !splitTender || !splitTender.length

  const loadingPlaceOrderBtn = formValidationLoading || loadingCart || loadingFarm

  const totalAfterFeesText = formatMoney(getTotalAfterFeesNCredit(total.total, coverFee.value, farmCreditAppliedAmount))

  return {
    //base state
    set,
    setters,
    coverFee,
    errors,
    formValidationLoading,
    payMethodLoading,
    splitTender,
    total,
    additionalFees,
    touched,
    initialCartCheckDone,
    paymentSelectorOptions,
    //functions
    handleAddOrder,
    placeOrder,
    validateAddress,
    preCheckout,
    //props/ derived data / memo
    farm,
    loadingFarm,
    errorFarm,
    shouldRequireAddress,
    initialAddressValues,
    schema,
    serviceFeeAmount,
    farmCreditAppliedAmount,
    disablePlaceOrder,
    ebtPmtAmount,
    loadingPlaceOrderBtn,
    deliveryFeesTotalDueNow,
    itemGroups,
    totalAfterFeesText,
  }
}

export type CheckoutData = ReturnType<typeof useCheckoutData>

export const CheckoutContext = createContext<CheckoutData>({} as CheckoutData)
