import { FontAwesome5 } from '@expo/vector-icons'
import { ProductType } from '@models/Product'
import { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { BodyProps, Text } from '../../components/elements/Text'
import Colors, { withAlpha } from '../../constants/Colors'

export function Tag(props: {
  color: string
  title: string
  contStyle?: StyleProp<ViewStyle>
  textProps?: BodyProps
  inverse?: boolean
  icon?: ReactNode
}) {
  return (
    <View style={[style(props.color, props.inverse), props.contStyle]}>
      {props.icon ? (
        props.icon
      ) : props.title === 'Featured' ? (
        <FontAwesome5 name="star" color={props.color} size={8} style={{ paddingRight: 5 }} />
      ) : null}
      <Text center color={props.inverse ? Colors.white : props.color} size={8} type="medium" {...props.textProps}>
        {props.title}
      </Text>
    </View>
  )
}

export function ProductTag({ type, style }: { type: ProductType | 'featured'; style?: StyleProp<ViewStyle> }) {
  switch (type) {
    case ProductType.Standard:
    case ProductType.GiftCard:
    case ProductType.Membership:
      return <Tag color={Colors.darkGreen} title="Standard" contStyle={style} />
    case ProductType.PrimaryShare:
      return <Tag color={Colors.blue} title="Primary Share" contStyle={style} />
    case ProductType.AddonShare:
      return <Tag color={Colors.purple} title="Addon Share" contStyle={style} />
    case ProductType.Digital:
      return <Tag color={Colors.brown} title="Digital" contStyle={style} />
    case ProductType.FarmBalance:
      return <Tag color={Colors.gold} title="Farm Credit" contStyle={style} />
    case 'featured':
      return <Tag color={Colors.gold} title="Featured" contStyle={style} />
    default:
      return null
  }
}

const style = (color: string, inverse?: boolean): ViewStyle => ({
  backgroundColor: inverse ? color : withAlpha(color, 0.1),
  borderRadius: 10,
  padding: 5,
  margin: 5,
  borderWidth: 2,
  borderColor: inverse ? Colors.white : color,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
})
