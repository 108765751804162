import { getUnits, isInStock } from '@helpers/products'
import { CartItem } from '@models/Order'
import {
  PaymentSchedule,
  Product,
  Unit,
  hasGlobalStock,
  hasUnitStock,
  hasUnits,
  isGlobalUnit,
  isShare,
  isStandard,
} from '@models/Product'

import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { InsufficientStockError, OutOfStockError } from '@shared/errors/cart'
import { refreshCartItemData } from './cartItem'
import DecimalCalc from './decimal'
import { Optional } from './typescript'

/** Helper to easily check whether an existing item in cart can have its quantity updated, by delta only*/
export function makeCanUpdateHelper(cartItem: Parameters<typeof canUpdateQuantity>[0]['cartItem'], cart: CartItem[]) {
  return (delta = 1): boolean =>
    canUpdateQuantity({
      delta,
      cartItem,
      cart,
    })
}

type CanUpdateQtyArgs = {
  /** This is intended to be a cart item which may either come from the cart, or may be made up on the spot by providing a product and quantity, and unit if it's a unitProduct, and/or a paymentSchedule if it's a share.
   * - Pickups can be optionally provided if you want the calculation to consider a given number of pickups. If pickups are undefined they will default to 1 or the minPickups for a standard, whichever is greater */
  cartItem: Optional<
    Pick<CartItem, 'quantity' | 'product' | 'unit' | 'pickups' | 'paymentSchedule'>,
    'pickups' | 'paymentSchedule'
  >
  delta?: number
  cart: CartItem[]
}

/** Gets the correct dates multiplier for the stock sufficiency calculation, based on the item in the cart.
 * If digital, pickups will be undefined, in that case this defaults to 1, which doesn't affect the calculation.
 */
const getNPickupsMulti = (itemInCart?: CanUpdateQtyArgs['cartItem']) => itemInCart?.pickups?.length ?? 1

/** Helper for use within canUpdateQuantity. Its job is to return the data that will be used for quantity calculation.
 * The returned item's quantities will be counted against the base stock
 */
const getItemWithQties = ({
  cart,
  cartItem,
  unit,
}: CanUpdateQtyArgs & {
  /** This unit is used when iterating over the units of a product while calculating  the remaining stock */
  unit?: Unit
}) => {
  const { product, paymentSchedule } = cartItem

  let itemWithQuantities: typeof cartItem | undefined

  const itemFound = findItemInCart({ product, unit, paymentSchedule }, cart)
  if (itemFound) {
    // if an item is in the cart with this data, refresh it
    const freshItem = refreshCartItemData(itemFound, product)

    // if it matches with the cartItem, assign any values that affect quantity calculation, except for unit
    if (isCartUnique(freshItem, cartItem)) {
      freshItem.pickups = cartItem.pickups
      freshItem.quantity = cartItem.quantity
    }
    itemWithQuantities = freshItem
  } else if (isCartUnique({ product, unit, paymentSchedule }, cartItem)) {
    // if this data matches the cartItem provided, use that
    itemWithQuantities = cartItem
  }

  return itemWithQuantities
}

/** Gets the remaining stock for an item in relation to the data cart.
 * - If the item is in the cart, returns the remaining stock for this existing item.
 * - If the item is not in the cart, returns the remaining stock if you were to add this item.
 *
 * @returns rawStock (stock in base units format): The remaining raw stock after the item's quantities have been applied against the global or unit stock, considering multipliers, quantity, and pickups if applicable. This raw stock is in the format of the base stock, so it is not directly synonymous with how many quantities are available in the case of global stock units due to multipliers.
 */
export function getRemainingBaseStock({ cart, cartItem }: Omit<CanUpdateQtyArgs, 'delta'>): number {
  const { product, unit } = cartItem
  const units = getUnits(product)

  if (hasUnits(product) && (!unit || !units))
    throw new Error('Unit products must have a unit when checking for remaining stock')

  //Determine which stock to use. This must be the raw stock in base units, not based on any multiplier, and must be correct for the type of product (global stock or unit stock)
  let rawStock = hasUnitStock(product) ? unit!.quantity! : product.quantity

  // Subtract from the raw stock: any quantity of this product already in cart, considering other units and their respective multiplier
  if (hasGlobalStock(product)) {
    // Product is either a share or a globalstandard or digital
    if (hasUnits(product)) {
      // Product is globalstandard or digital
      if (!units) throw new Error('This item type should have units')
      for (const currUnit of units) {
        const itemWithQuantities = getItemWithQties({ cart, cartItem, unit: currUnit })
        if (!itemWithQuantities) continue

        // If an item with another unit is found, subtract its quantity from the global stock, times the unit multiplier, times the number of pickups selected for that item.
        const nPickups = getNPickupsMulti(itemWithQuantities)

        rawStock = DecimalCalc.subtract(
          rawStock,
          DecimalCalc.multiply(itemWithQuantities.quantity, currUnit.multiplier, nPickups),
        )
      }
    } else {
      // Product is a share
      const itemWithQuantities = getItemWithQties({ cart, cartItem })

      if (itemWithQuantities) {
        rawStock = DecimalCalc.subtract(rawStock, itemWithQuantities.quantity)
      }
    }
  } else if (hasUnitStock(product)) {
    // Product is unitStock-Standard
    // In this case it's not necessary to iterate over each unit (as in global stock units) because these have their own stock each
    if (!unit) throw new Error('Unit should be defined for this item')
    const itemWithQuantities = getItemWithQties({ cart, cartItem, unit })

    if (itemWithQuantities) {
      const nPickups = getNPickupsMulti(itemWithQuantities)

      rawStock = DecimalCalc.subtract(rawStock, DecimalCalc.multiply(itemWithQuantities.quantity, nPickups))
    }
  }

  return rawStock
}

/** Returns the number of remaining quantities that can be added for a cart item, considering all the quantities in the current cart and the specified cart item (Assuming said item is in the cart) */
export function getRemaining({
  cart,
  cartItem,
  attribute,
}: Omit<CanUpdateQtyArgs, 'delta'> & {
  /** Which CartItem attribute to get remaining quantity for */
  attribute: Extract<keyof CartItem, 'pickups' | 'quantity'>
}) {
  const baseStock = getRemainingBaseStock({ cart, cartItem })
  const { product, unit, pickups, quantity } = cartItem

  /** The multiplier to compare: it's the opposite of the attribute value ('pickups' or 'quantity') */
  const multiToCompare = (attribute === 'quantity' ? pickups?.length : attribute === 'pickups' ? quantity : 1) ?? 1

  if (hasUnits(product) && hasGlobalStock(product)) {
    if (!unit) throw new Error('Missing unit in unit product cartItem')
    if (!isGlobalUnit(unit)) throw new Error('Expected a global unit')

    // Must return a whole (floor) number because this is a whole quantity, and there's a possibility the stock exceeds the unit multiplier with a remainder from the division, which would produce a decimal
    return Math.floor(DecimalCalc.divide(baseStock, DecimalCalc.multiply(unit.multiplier, multiToCompare)))
  } else if (hasUnitStock(product)) {
    // for unit stock products, unit multiplier is ignored because each quantity counts against the unit stock 1-1
    return Math.floor(DecimalCalc.divide(baseStock, multiToCompare))
  } else if (isShare(product)) {
    // For shares there's no units nor date selection, so the base stock is the only relevant remaining value
    return baseStock
  } else {
    throw new Error('Invalid product type for calculating remaining quantity')
  }
}

/**
 * Determine if an item can be added to cart while fulfilling quantity requirements. Or determine if a quantity of a cart item can be updated.
 *
 * @param cartItem the item in question. If trying to add a new item to cart, this is the tentative data for the new item. If updating an existing item in cart, this is meant to be a reference to (or copy of) the existing item in the cart.
 * @param cart the current cart array. The check will consider quantities already in the cart, such as items that count against the same global stock through global stock units, each with their own multiplier, as well as multiple pickups.
 * - If checking whether the quantity of an existing item can be updated, `cart` is assumed to be the full list including the cartItem in question.
 * - Else, if checking whether a product can be added to cart for the first time, `cart` is expected to not include the cartItem in question.
 * @param delta if defined, it means we're trying to update the quantity of an existing item in cart by this difference. else, if undefined it is assumed we're adding an item that doesn't yet exist in cart and will instead use the item.quantity which should be 1 by default for a newly created item.
 */
export function canUpdateQuantity({ cartItem, cart, delta }: CanUpdateQtyArgs): boolean {
  // Abort if some part of the data make no sense or require no work
  // - If there's only a zero quantity or a zero delta, there's no changes to check.
  if ((delta === undefined && cartItem.quantity === 0) || delta === 0) return true

  if (delta === undefined || delta > 0) {
    // If it's a positive operation (add new or increase qty), remaining raw stock must be at least zero

    const remainingQty = getRemaining({
      cart,
      cartItem: { ...cartItem, quantity: cartItem.quantity + (delta ?? 0) },
      attribute: 'quantity',
    })

    if (isStandard(cartItem.product) && cartItem.product.minPickups && !cartItem.pickups?.length) {
      return remainingQty >= cartItem.product.minPickups - 1
    }

    return remainingQty >= 0
  } else {
    // If it's a negative operation (remove or decrease qty), absolute value of negative delta must not be greater than quantity in cart
    return Math.abs(delta) <= cartItem.quantity
  }
}

/** Variation of a cart item with the necessary fields to find it in the cart for the purposes of checking stock fulfillment matters */
export type CartItemFindable = {
  product: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>
  unit?: Pick<Unit, 'id'>
  paymentSchedule?: Pick<PaymentSchedule, 'frequency'>
}

/**
 * Finds an item in cart that has the same product and unit, (or any other "cartUnique" attribute, specified in the CartItemFindable type) .
 * - If no unit is provided it will only find shares in cart.
 * - The canUpdateQuantity helper depends on this working as-is. That's why this helper is in the same file. DO NOT modify this without analyzing in-depth how it may affect calculation.
 * - Item's product can be of algolia geo product type as well, for the purpose of this helper
 */
export function findItemInCart(item: CartItemFindable, cart: CartItem[]): CartItem | undefined {
  return cart.find((ci) => isCartUnique(item, ci))
}

/** Whether the data between 2 items represents the same item in the cart (I.e. these can't exist in the cart as two separate items)
 * - The cartUnique attributes are those which cannot exist in the cart as two separate products; rather they would refer to the same cart item. (For example, a product id and a unit id.)
 */
export function isCartUnique(item: CartItemFindable, item2: CartItemFindable) {
  if (item.product.id !== item2.product.id) return false

  if (hasUnits(item.product) && hasUnits(item2.product)) {
    // Unit products can exist multiple times in the cart if their buying options are different
    return item.unit?.id === item2.unit?.id
  }
  if (isShare(item.product) && isShare(item2.product)) {
    if (!item.paymentSchedule && !item2.paymentSchedule) return true
    // A cart share may exist in the cart twice if it has different payment schedules.
    // In practice this is only done in the order creator
    return item.paymentSchedule?.frequency === item2.paymentSchedule?.frequency
  }

  return false
}

/** decides which error to throw based on whether the product is either out-of-stock or simply has insuficient stock */
export function throwItemQuantityError(product: Product) {
  if (!isInStock(product)) throw new OutOfStockError()
  else throw new InsufficientStockError()
}
