import { buildCartItem, CartItemFields } from '@helpers/builders/buildCartItem'
import { isCompatibleLocation, isSameAddress } from '@helpers/location'
import { isOutofPickups } from '@helpers/products'
import { pick } from '@helpers/typescript'
import { Distribution } from '@models/Distribution'
import { isLocalPickup, isNonPickup, LocalPickup } from '@models/Location'
import { CartDigital, CartItem, CartItemBase, CartShare, CartStandard, isCartPhysical } from '@models/Order'
import { GlobalStandard, hasUnits, hasUnitStock, isShare, isStandard, Product, UnitStandard } from '@models/Product'
import { isNotFound } from '@shared/Errors'
import { dequal } from 'dequal'

import { MoneyCalc } from '@helpers/money'
import { getPaymentSchedules } from '@helpers/order'
import { isSameDay } from '@helpers/time'
import DecimalCalc from './decimal'
import { formatAddress } from './display'
import { hasOwnProperty } from './helpers'

/** Codes for cart item validation rejection */
export enum ItemRejectReason {
  Deleted = 'deleted',
  OutofStock = 'out_of_stock',
  /** The product stock can't fulfill the quantity in cart */
  Insufficient = 'insufficient',
  /** buying option no longer exists */
  UnitDeleted = 'unit_deleted',
  /** Unavailable means the product has no pickups left */
  Unavailable = 'unavailable',
  CartItemError = 'cart_item_error', // Error produced by cart item creator validation
  /** IncompatibleLocation will be used when a nonpickup address is no longer compatible with the current distro location region */
  IncompatibleLocation = 'incompatible_location',
  /** When there was a significant change in the schedule or location */
  ScheduleChanged = 'schedule_changed',
  /** LocationNotFound will be used when either the schedule was no longer part of the product, or its location is no longer of the same type */
  LocationNotFound = 'location_notfound',
  /** AddressChanged will be used if a localpickup location has a different address than the one when the item was added to the cart */
  AddressChanged = 'address_changed',
  /** When the selected price of the cart item is changed on the product */
  PriceChanged = 'price_changed',
  /** When the product has a set minimum number of pickups and the cart item does not satisfy the minimum */
  InsufficientPickups = 'insufficient_pickups',
}

/** Helps present a display string for a given invalid item scenario */
export const getInvalidItemMessageUi = (data: InvalidItemDetails) => {
  switch (data.reason) {
    case ItemRejectReason.AddressChanged:
      return `The location for this item has changed since the time it was added to the cart.${
        data.newAddress ? ` The new address is ${formatAddress(data.newAddress!)}` : ''
      }`
    case ItemRejectReason.CartItemError:
      return 'Something went wrong while validating this cart item'
    case ItemRejectReason.Deleted:
      return 'The product is no longer being sold'
    case ItemRejectReason.IncompatibleLocation:
      return 'The address for this product is no longer compatible with the current available region'
    case ItemRejectReason.Insufficient:
      return 'The quantity in the cart exceeds the most recent quantity in stock'
    case ItemRejectReason.LocationNotFound:
      return 'The product is no longer being offered at the location you selected'
    case ItemRejectReason.OutofStock:
      return 'The product is out of stock'
    case ItemRejectReason.PriceChanged:
      return 'The product price has changed'
    case ItemRejectReason.ScheduleChanged:
      return 'The schedule selected has changed significantly'
    case ItemRejectReason.Unavailable:
      return 'The product is no longer available'
    case ItemRejectReason.UnitDeleted:
      return 'The buying option selected is no longer available'
    case ItemRejectReason.InsufficientPickups:
      return 'The product requires a minimum number of pickups'
    default:
      return 'Something went wrong while validating this product'
  }
}

export type InvalidItemDetails = {
  /** The id of an invalid cart item  */
  id: string
  /** If the invalid reason is "insufficient", this should be the most recent remaining quantity available for this item. If item has units, should be the remaining quantity for the selected buying option & price */
  quantity?: number
  /** The reason why the item is invalid */
  reason: ItemRejectReason
  /** If the reject reason was AddressChanged, the 'newAddress' field will have the most recent address. */
  newAddress?: LocalPickup['address']
}

/** This type holds either the product or a load error */
export type ProdFetchResult = Product | { id: string; err: Error }

/** A load error represents an issue while fetching a product for the cart*/
export const isLoadErr = (res: ProdFetchResult): res is { id: string; err: Error } =>
  hasOwnProperty(res, 'err') && hasOwnProperty(res, 'id')

/** This function will validate all items in the cart, in a way that helps determine if the cart should be allowed to continue to checkout and placing an order. */
export function validateCart({
  cart,
  uniqueProdsInCartDb,
  onInvalidItem,
  isAdmin,
}: {
  /** An array of cart items to validate */
  cart: CartItem[]
  /** A list of products loaded from Firestore that are in the cart. This should be a unique list of products, so that the validation doesn't repeat the same product validation multiple times. */
  uniqueProdsInCartDb: ProdFetchResult[]
  /** A callback function that handles each invalid item. If the reject reason is `Insufficient`, it should receive the `quantity` argument with the most up-to-date quantity, so that cart quantity can be matched to it. */
  onInvalidItem: (details: InvalidItemDetails) => void
  /** Whether the validation is for an admin or consumer request */
  isAdmin: boolean
}): void {
  if (!cart.length) return

  uniqueProdsInCartDb.forEach((prodResult) => {
    //get any items in cart that have the same product id (could be more than one item, if it's a multi-unit prod)
    const itemsWithProduct = cart.filter((ci) => ci.product.id === prodResult.id)

    if (isLoadErr(prodResult)) {
      // Check if the product has been deleted
      if (isNotFound(prodResult.err)) {
        return itemsWithProduct.forEach((ci) => {
          onInvalidItem({ id: ci.id, reason: ItemRejectReason.Deleted })
        })
      }

      /** If there was an error other than not found, validation should not proceed because any results would be misleading. This error needs to be handled by the caller perhaps with an alert, and an option to retry */
      throw prodResult.err
    }

    // Check if the product price has changed
    itemsWithProduct.forEach((ci) => {
      if (isShare(prodResult)) {
        const typedItem = ci as CartShare

        const prodPaymentSchedules = getPaymentSchedules({ product: prodResult })
        // We need to check that no payment schedule has changed on the cart, because the user can still change the PS
        // from checkout, so if any have changed we should not let them proceed with this product
        typedItem.product.paymentSchedules.forEach((cartPs) => {
          const matchingPS = prodPaymentSchedules.find((prodPs) => prodPs.frequency === cartPs.frequency)
          // The fields we want to validate are amount, deposit, and endDate because these are ones that affect pricing
          if (
            !matchingPS ||
            !MoneyCalc.isEqual(matchingPS.amount, cartPs.amount) ||
            !MoneyCalc.isEqual(matchingPS.deposit, cartPs.deposit) ||
            !isSameDay(matchingPS.paymentDates.endDate, cartPs.paymentDates.endDate)
          ) {
            onInvalidItem({ id: typedItem.id, reason: ItemRejectReason.PriceChanged })
          }
        })
      } else if (hasUnits(prodResult)) {
        const typedItem = ci as CartStandard | CartDigital

        // Match the price selected in the cart with the product price
        const priceList = prodResult.units.flatMap((unit) => unit.prices)
        const unitPrice = priceList.find((price) => price.id === typedItem.price.id)
        // TODO: For wholesale when we implement different price lists, these checks will need to be considered for further restrictions
        // If the selected price no longer exists on the product or the amount has changed then the item is invalid
        if (!unitPrice || !MoneyCalc.isEqual(unitPrice.amount, typedItem.price.amount)) {
          onInvalidItem({ id: ci.id, reason: ItemRejectReason.PriceChanged })
        }
      }
    })

    // Check if product has enough in stock
    if (hasUnitStock(prodResult)) {
      //if the dbProd is unit stock, make sure the cartitems with this product don't exceed the quantity of their selected unit, based on the most recent data.
      //if there's multiple units, there may be multiple items in cart, each with the same prod but a different unit.
      itemsWithProduct.forEach((ci) => {
        const item = ci as CartItemBase<UnitStandard>
        const updatedUnit = prodResult.units.find((u) => u.id === item.unit.id)
        if (!updatedUnit) {
          return onInvalidItem({ id: item.id, reason: ItemRejectReason.UnitDeleted })
        }
        if (updatedUnit.quantity < 1) {
          return onInvalidItem({ id: item.id, reason: ItemRejectReason.OutofStock })
        }
        // for unit stock, there's no need divide by the multiplier for comparing quantity in this context
        if (ci.quantity > updatedUnit.quantity) {
          return onInvalidItem({ id: item.id, reason: ItemRejectReason.Insufficient, quantity: updatedUnit.quantity })
        }
      })
    } else if (hasUnits(prodResult)) {
      //if the dbProd is global stock, make sure the cartitems with this product don't exceed the global most-recent quantity available, considering all of these items and their multipliers.
      //if the sum of the items' adjusted quantities exceed the global up-to-date quantity, it's a scenario that doesn't have a clear-cut correct solution. so for the purpose of handling it gracefully, it may be better to use an arbitrary policy

      //arbitrary solution: algorithm that iterates over each item in cart, and keeps a counter variable of the global quantity remaining. for each item, get the adjusted quantity (quantity * multi) and subtract it from global stock. if remaining stock is zero or more, allow item in cart. if eventually the remaining stock is less than zero, it means the global stock maxed out, so stop the iteration, and reject this and every remaining item.

      let globStock = prodResult.quantity
      let maxedOut = false
      const globItms = itemsWithProduct as CartItemBase<GlobalStandard>[]
      //sorting by ascending multiplier will allow as many items as possible before getting insufficient quantity kicks in and rejected items will only be the last ones with large multipliers
      globItms.sort((a, b) => (a.unit.multiplier < b.unit.multiplier ? -1 : 1))

      //this should run as a `for` loop, not as `forEach`, because it needs to be sequential
      for (const item of globItms) {
        if (maxedOut) {
          // This refers to subsequent items which count against the same global stock, after it has been depleted by previous items in this iteration

          onInvalidItem({ id: item.id, reason: ItemRejectReason.OutofStock })
          continue
        }
        const adjustedUnits = DecimalCalc.multiply(item.quantity, item.unit.multiplier)
        if (globStock - adjustedUnits >= 0) {
          globStock = DecimalCalc.subtract(globStock, adjustedUnits)
          continue
        } else {
          maxedOut = true //Setting this to true means if there are more buying options to check after the current one, they will be out of stock

          const newQty = Math.floor(DecimalCalc.divide(globStock, item.unit.multiplier)) //how many times can this unit multiplier fit inside the remaining stock
          globStock = DecimalCalc.subtract(globStock, DecimalCalc.multiply(newQty, item.unit.multiplier))
          onInvalidItem({ id: item.id, reason: ItemRejectReason.Insufficient, quantity: newQty })
        }
      }
    } else if (isShare(prodResult)) {
      //for shares, there can't be multiple items in cart that have the same share. so just check that quantity is less than new data for the first and only item
      const item = itemsWithProduct[0]
      if (item) {
        if (prodResult.quantity < 1) {
          onInvalidItem({ id: item.id, reason: ItemRejectReason.OutofStock })
        } else if (item.quantity > prodResult.quantity) {
          onInvalidItem({ id: item.id, reason: ItemRejectReason.Insufficient, quantity: prodResult.quantity })
        }
      }
    }

    // Check for pickups left
    if (
      isOutofPickups(prodResult, {
        excludeHiddenDistros: true,
        ignoreOrderCutoffWindow: isAdmin,
        excludeClosedDistros: !isAdmin,
      })
    ) {
      itemsWithProduct.forEach((item) => {
        onInvalidItem({ id: item.id, reason: ItemRejectReason.Unavailable })
      })
    }

    // Check that all standard products meet the minimum pickups required
    if (isStandard(prodResult) && prodResult.minPickups !== undefined) {
      itemsWithProduct.forEach((item) => {
        if (item.pickups && item.pickups.length < prodResult.minPickups!) {
          onInvalidItem({ id: item.id, reason: ItemRejectReason.InsufficientPickups })
        }
      })
    }

    /** Validate any potential changes to the product schedules will not affect the cart items */
    itemsWithProduct.forEach((item) => {
      if (isCartPhysical(item)) {
        const itemDistro = item.distribution

        if (isNonPickup(itemDistro.location)) {
          const itemLoc = itemDistro.location
          const dbLoc = prodResult.distributions?.find((d) => d.id === itemDistro.id)?.location
          if (!dbLoc || !isNonPickup(dbLoc)) {
            onInvalidItem({ id: item.id, reason: ItemRejectReason.LocationNotFound })
          } else {
            dbLoc['address'] = itemLoc.address
            const isCompatible = isCompatibleLocation(dbLoc, dbLoc.type, dbLoc.address)
            if (!isCompatible) {
              onInvalidItem({ id: item.id, reason: ItemRejectReason.IncompatibleLocation })
            }
          }
        } else if (isLocalPickup(itemDistro.location)) {
          const itemLoc = itemDistro.location
          const dbSchedule = prodResult.distributions?.find((d) => d.id === itemDistro.id)
          const dbLoc = dbSchedule?.location

          if (!dbLoc || !isLocalPickup(dbLoc)) {
            onInvalidItem({ id: item.id, reason: ItemRejectReason.LocationNotFound })
          } else {
            if (!isSameAddress(dbLoc.address, itemLoc.address)) {
              onInvalidItem({ id: item.id, reason: ItemRejectReason.AddressChanged, newAddress: dbLoc.address })
            }
          }
        }

        /** This array of schedule fields to check should only be those fields that if changed would either make the item invalid (As in the case of "closed" or "isHidden") or would cause a very confusing change in the item's distribution schedule ("schedule"). Everything else at the moment of this writing doesn't seem like an impediment for keeping the item in the cart. For example, if the deliveryFee changed, that's OK they will still see the new total in the checkout screen. Things like that are harmless for the purpose of cart item validation in pre-checkout */
        const fieldsToCheck: (keyof Distribution)[] = ['isHidden', 'schedule']

        // We should only check the field "closed" if the user is not an admin, because admins can checkout even if the distro is closed
        if (!isAdmin) fieldsToCheck.push('closed')
        const dbSchedule = prodResult.distributions?.find((d) => d.id === itemDistro.id)

        if (dbSchedule && !dequal(pick(dbSchedule, ...fieldsToCheck), pick(itemDistro, ...fieldsToCheck))) {
          onInvalidItem({ id: item.id, reason: ItemRejectReason.ScheduleChanged })
        }
      }
    })
  })

  // General validation of each of the items in the cart, with the most recent db data for each product
  const dbProds = uniqueProdsInCartDb.filter((res): res is Product => !isLoadErr(res))

  cart.forEach((item) => {
    const dbProd = dbProds.find((p) => p.id === item.product.id)

    if (!dbProd) {
      onInvalidItem({ id: item.id, reason: ItemRejectReason.CartItemError })
    } else {
      // Substitute the item's product with the most recent product before cart item general validation
      const newItem: CartItemFields & { isAdmin: boolean } = { ...item, product: dbProd, isAdmin }
      try {
        buildCartItem(newItem)
      } catch (err) {
        onInvalidItem({ id: item.id, reason: ItemRejectReason.CartItemError })
      }
    }
  })
}
