import { HeaderText, Text, TextH2 } from '@elements'
import { canUpdateQuantity, findItemInCart } from '@helpers/canUpdateQuantity'
import { formatMoney, formatUnitPriceRange } from '@helpers/display'
import { getStock, getUnitPrice } from '@helpers/products'
import { sortUnits } from '@helpers/sorting'
import { ProductType, Unit, UnitProduct, hasUnits } from '@models/Product'
import { StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native'

import { Divider } from '../../components/elements/Divider'
import { Icon } from '../../components/elements/Icon/Icon'
import Colors from '../../constants/Colors'
import { useCartService } from '../../hooks/useCart'

/** Component that allows selecting buying options for unit products */
export function UnitSelection({ product, close }: { product: UnitProduct; close: (unit?: Unit) => void }) {
  const { cart } = useCartService()

  if (!hasUnits(product)) close()
  const isFarmCredit = product.type === ProductType.FarmBalance
  return (
    <View style={styles.container}>
      {[...product.units].sort(sortUnits).map((unit, index) => {
        const isLastUnit = index === product.units.length - 1
        const cartItem = findItemInCart({ product, unit }, cart)
        const canAddUnit = cartItem
          ? canUpdateQuantity({ cartItem, cart, delta: 1 })
          : canUpdateQuantity({ cartItem: { product, quantity: 1, unit }, cart })

        const stock = getStock(product, unit.id)
        const optionStyle = getStyle(stock, canAddUnit)
        return (
          <View key={unit.id}>
            <TouchableOpacity style={styles.item} onPress={() => close(unit)} disabled={!canAddUnit}>
              {isFarmCredit ? (
                <View>
                  <HeaderText style={optionStyle}>{unit.name}</HeaderText>
                </View>
              ) : (
                <View>
                  <Text style={optionStyle}>{unit.name}</Text>
                  {stock === 0 ? (
                    <Text style={[optionStyle, stock < 5 && { color: Colors.red }]} type="medium">
                      Out of stock
                    </Text>
                  ) : (
                    <Text style={[optionStyle, stock < 5 && { color: Colors.red }]}>{stock} left in stock</Text>
                  )}
                </View>
              )}

              <View style={styles.priceCont}>
                {isFarmCredit ? (
                  <TextH2 color={Colors.green}>Add</TextH2>
                ) : (
                  <>
                    <HeaderText style={optionStyle}>{`${formatUnitPriceRange(
                      product.units,
                      unit.id,
                    )} / ea`}</HeaderText>
                    <Text style={optionStyle}>{`(${formatMoney(getUnitPrice(product, unit.id))}/${
                      product.baseUnit
                    })`}</Text>
                  </>
                )}
              </View>

              <Icon name="chevron-right" style={{ flexShrink: 1, flexGrow: 0 }} />
            </TouchableOpacity>
            <Divider clear={isLastUnit} />
          </View>
        )
      })}
    </View>
  )
}

const getStyle = (qty: number, canAddUnit: boolean): TextStyle | undefined => {
  return qty < 1 || !canAddUnit ? { color: Colors.shades['300'] } : {}
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  priceCont: { flex: 1, alignItems: 'flex-end', marginRight: 5 },
})
