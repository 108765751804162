import { formatMoney } from '@helpers/display'
import { BuyingOptionSales, DistributionSalesForBuyingOption } from '@shared/types/v2/sales'
import { memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { DateValue } from '../../Analytics/util'
import { ExpandableRow } from '../../OfflineTable/ExpandableRow'
import { Tag } from '../../ProductTag'
import { Distribution } from './Distribution'
import { LoadingRow, createColumns, indent2, indent3 } from './helpers'

import Colors from '@/constants/Colors'
import { adminFarmSelector } from '@/redux/selectors'
import { fetchDistributionSalesForBuyingOption } from '@api/Analytics'

type BuyingOptionProps = {
  data: BuyingOptionSales
  index: number
  dateFilter: DateValue
  /** Whether to hide the subrows. If true, the item won't be expanded */
  hideSubRow: boolean
}

export const BuyingOption = memo(function BuyingOption({ data, index, dateFilter, hideSubRow }: BuyingOptionProps) {
  const farm = useSelector(adminFarmSelector)

  const [distributions, setDistributions] = useState<DistributionSalesForBuyingOption[] | null>(null)

  const buyingOptionElements = useMemo(() => {
    if (!distributions) return null
    return distributions.map((distribution, i) => (
      <Distribution data={distribution} index={i} indent={indent3} key={distribution.id} />
    ))
  }, [distributions])

  const fetchDistributions = useCallback(async () => {
    if (data.numDistributions > 0) {
      const res = await fetchDistributionSalesForBuyingOption(
        dateFilter,
        farm.id,
        data.productId,
        data.id,
        farm.timezone,
      )
      setDistributions(res)
    }
  }, [data.numDistributions, data.productId, data.id, dateFilter, farm.id, farm.timezone])

  const generateSubRows = useCallback(
    (buyingOption: BuyingOptionSales) => {
      if (!buyingOptionElements)
        return [...new Array(buyingOption.numDistributions)].map((x, i) => <LoadingRow key={i} />)
      return buyingOptionElements
    },
    [buyingOptionElements],
  )

  return (
    <ExpandableRow<BuyingOptionSales>
      item={data}
      index={index}
      onFirstExpand={fetchDistributions}
      columns={createColumns({
        name(buyingOption) {
          return buyingOption.name
        },
        sku(buyingOption) {
          return buyingOption.sku
        },
        type() {
          return <Tag color={Colors.red} title="Buying Option" />
        },
        quantitySold(buyingOption) {
          return buyingOption.quantitySold.toString()
        },
        grossAmount(buyingOption) {
          return formatMoney(buyingOption.grossAmount)
        },
      })}
      indent={indent2}
      indentMode="first-col"
      generateSubRows={hideSubRow ? undefined : generateSubRows}
    />
  )
})
