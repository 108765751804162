import { Printable } from '@helpers/typescript'

import { isObject, nonEmptyString } from '@helpers/helpers'
import { Address } from './Address'
import { Distribution } from './Distribution'
import { FarmStatus } from './Farm'
import { InvoiceStatus } from './Invoice'
import { Product, ProductType, Standard, UnitProduct } from './Product'

/** A static name of each algolia index type */
export enum AlgoliaIndexType {
  geosearch = 'geosearch',
  admindata = 'admindata',
}

/** These are different kinds of algolia documents shared over the various indices */
export enum AlgoliaDocType {
  FARM = 'farm',
  PRODUCT = 'product',
  DISTRO = 'distro',
  ORDER = 'order',
  INVOICE = 'invoice',
  CUSTOMER = 'customer',
}

export type AlgoliaGeoDoc<T = AlgoliaGeoFarm | AlgoliaGeoProduct | AlgoliaGeoDistro> = T & {
  index: AlgoliaIndexType.geosearch
  /** The objectId is an algolia-specific document id. In the case of a product it is a concatenation with the id of one of its distros. In all other cases it is the db id */
  objectID: T extends AlgoliaGeoProduct
    ? `${Distribution['id']}_${Product['id']}` | `digital_${Product['id']}`
    : `${Distribution['id']}`
  /** The id is the db id */
  id: string
  docType: AlgoliaDocType
  name: string
  description: string
  images: string[]
  imageSort: number
  isHidden?: boolean
  address: Omit<Address, 'coordinate'>
  _geoloc: {
    lat: number
    lng: number
  }
  /** Will be true if the product is either EBT-eligible or EBT-only.
   * Only relevant for standard products (default undefined for non-standard products) */
  isEbt: boolean
  farm: {
    id: string
    name: string
    status: FarmStatus
    logo?: string
    practices: string[]
    tags: string[]
    reviews?: {
      numRatings: number
      rating: number
    }
    hasProducts: boolean
    setupCount: number
    productCount: number
    urlSafeSlug: string
  }
}

export type AlgoliaGeoFarm = {
  locationCount: number
}

export type AlgoliaGeoDistro = {
  scheduleText: string
  distroNickname: string
  locationId: string
}

export type AlgoliaGeoProduct = Omit<AlgoliaGeoDistro, 'locationId'> & {
  category: string[]
  /** a price range string, or N/A if there's no valid price for the product. This price is used when the products are viewed in the map */
  price: string
  /** The price string to use in the product card. This should match the logic used to get this price inside the card for a full product */
  priceInCard: string
  shortDescription: Product['description']
  type: Product['type']
  /**Timestamp in miliseconds. After this timestamp the product has no pickups available. Digital products have a timestamp in the far distant future, while invalid data will get zero. */
  lastAvailStamp: number
  /** Whether the stock is greater than zero */
  isInStock: boolean
  /** isPrivate is meant to encode all logic about a product's privacy status into a boolean that has no external dependencies.
   * - If the product belongs to only private CSAs isPrivate will be true; Except if it's a Standard or Digital product it also needs to have the "hideFromShop" setting to true, to be considered private in this sense. */
  isPrivate: boolean
  /** A copy of the hideFromShop field. If non Standard or Digital, will be false because this field exists only for non shares. */
  hideFromShop: NonNullable<UnitProduct['hideFromShop']>
  /** This inherits the product field that holds the array of csa ids */
  csa: NonNullable<Product['csa']>
  /** The product's locations. Undefined for digital */
  locations?: {
    id: string
    name: string
  }[]
  urlSafeSlug: string
  /** Whether the product is featured. Featured prods are highlighted on FarmShop screen*/
  isFeatured: Product['isFeatured']
  /** Whether the product is EBT only. Look at EbtEligibility documentation for more info. */
  isEbtOnly: boolean
}

export type AlgoliaAdminDoc = {
  index: AlgoliaIndexType.admindata
  objectID: string
  id: string
  docType: AlgoliaDocType
  farmId: string
}

/*
  Lists of order metadata that can be used for filtering
  date represents when the order was placed
 */

export type AlgoliaAdminMetaArr = {
  id: string
  name: string
  date: number
}[]

export type AlgoliaAdminMeta = {
  csas: AlgoliaAdminMetaArr
  locations: AlgoliaAdminMetaArr
  distributions: AlgoliaAdminMetaArr
}

export type AlgoliaAdminOrderMeta = AlgoliaAdminMeta & {
  products: AlgoliaAdminMetaArr
}

export type AlgoliaAdminCustomer = AlgoliaAdminMeta &
  AlgoliaAdminDoc & {
    docType: AlgoliaDocType.CUSTOMER
    name: string
    email: string
    phone: string
    alternatePhoneNums: string[]
    farmCredit: number
    monthlyReloadAmt: number
  }

export type AlgoliaAdminOrder = AlgoliaAdminMeta &
  AlgoliaAdminDoc & {
    docType: AlgoliaDocType.ORDER
    user: {
      id: string
      name: string
      email: string
    }
    orderNum: number
    alternateOrderNums: string[]
    date: number
    total: number
    status: string
  }

export type AlgoliaAdminInvoice = AlgoliaAdminDoc & {
  docType: AlgoliaDocType.INVOICE
  status: InvoiceStatus
  user: {
    id: string
    name: string
    email: string
  }
  order: {
    id: string
    orderNum?: number
  }
  invoiceNum?: number
  amountDue: number
  payUrl?: string
  pdf?: string
  date: number
  paymentMethods: string[]
  paymentMethodsDisplay: string[]
}

export type AlgoliaAdminProduct = AlgoliaAdminDoc &
  AlgoliaAdminMeta & {
    docType: AlgoliaDocType.PRODUCT
    type: ProductType
    unitStock: Standard['unitStock']
    name: string
    description?: string
    longDescription?: string
    /** This sku is only for display purposes. For shares, it's the main sku. For standard products it's the unitSkuPrefix */
    skuDisplay: string
    productionMethod: string
    isHidden: boolean
    isFeatured: boolean
    category: string[]
    lastAvailStamp: number
    urlSafeSlug: string
    /** Will be true if the product is either EBT-eligible or EBT-only.
     * Only relevant for standard products (default undefined for non-standard products) */
    isEbt: boolean
    /** Whether the product is EBT only. Look at EbtEligibility documentation for more info. */
    isEbtOnly: boolean
    image: string
    price: string
    pricePerUnit: UnitProduct['pricePerUnit']
    /** The result of getStock() */
    stock: number
    availabilityDisplay: string
  }

/** All permutations of a filter property and its valid FacetFilter values, in the proper format */
export type FacetFilterBase<Prop extends string = string, Values extends Printable = Printable> = `${Prop}:${
  | '-'
  | ''}${Values}`

export type HiddenFilter = FacetFilterBase<'isHidden', boolean>
export type AdminDocTypeFilter = FacetFilterBase<
  'docType',
  AlgoliaDocType.INVOICE | AlgoliaDocType.ORDER | AlgoliaDocType.CUSTOMER
>
export type GeoDocTypeFilter = FacetFilterBase<
  'docType',
  AlgoliaDocType.FARM | AlgoliaDocType.DISTRO | AlgoliaDocType.PRODUCT
>
export type FarmStatusFilter = FacetFilterBase<'farm.status', FarmStatus>
export type EbtFilter = FacetFilterBase<'isEbt', boolean>
export type ProductTypeFilter = FacetFilterBase<'type', ProductType>

/** The different kinds of filter strings used in the algolia geosearch index */
export type GeoFilter = GeoDocTypeFilter | FarmStatusFilter | HiddenFilter | EbtFilter

/** enum that stores some commonly used GeoFilters for convenience, including each of the filters that can be toggled in the Explore screen.
 * - These literal enum members are expected to extend type `GeoFilter` */
export enum FILTERS {
  Product = 'docType:product',
  Farm = 'docType:farm',
  Distro = 'docType:distro',
  Registered = 'farm.status:Registered',
  NotRegistered = 'farm.status:-Registered',
  NotHidden = 'isHidden:-true',
  Ebt = 'isEbt:true',
  NotInactiveFarm = 'farm.status:-Inactive',
  NotPrivateProd = 'isPrivate:-true',
  NotAddon = 'type:-addon',
  /** This is intended to produce no results */
  Null = 'docType:null',
}

/** The FacetFilter type encodes the filtering information sent to algolia.
 * - Strings in the outer array are read as AND statements
 * - Strings in the inner arrays are read as OR statements
 * - Order matters: Filters are applied in the order they are defined within the array. The first filter should generally be the docType because it's the broadest. If you apply a hidden filter before narrowing by "docType:product", it might not apply correctly because some docTypes don't have a isHidden field */
export type FacetFilter<F extends FacetFilterBase = FacetFilterBase> = (F | F[])[]

/**Adds type checking to a string meant to be a facet filter */
export const checkFacetFilter = <Attr extends string, Val extends Printable>(filter: FacetFilterBase<Attr, Val>) =>
  filter

/** Type for the FacetFilter that controls the map results */
export type MapFilters = FacetFilter &
  [
    GeoDocTypeFilter[],
    FarmStatusFilter[],
    FILTERS.Ebt[],
    FILTERS.NotHidden,
    FILTERS.NotInactiveFarm,
    FILTERS.NotPrivateProd,
  ]

/** These are the filters for the map search. Their value will change later, as user interacts with map filters */
export const initialFiltersMapSearch: MapFilters = [
  [FILTERS.Farm, FILTERS.Product],
  [],
  [],
  FILTERS.NotHidden,
  FILTERS.NotInactiveFarm,
  FILTERS.NotPrivateProd,
]

/** These are the filters for the farm auto-complete on main app Header. They're static. */
export const filtersAutoCompleteFarm: FacetFilter = [FILTERS.Farm, FILTERS.NotInactiveFarm]

export const isGeoDocTypeFilter = (filter: FacetFilterBase): filter is GeoDocTypeFilter => {
  const [prop, value] = filter.split(':')
  return (
    prop === 'docType' &&
    (value.endsWith(AlgoliaDocType.FARM) ||
      value.endsWith(AlgoliaDocType.DISTRO) ||
      value.endsWith(AlgoliaDocType.PRODUCT))
  )
}

export const isStatusFilter = (filter: FacetFilterBase): filter is FarmStatusFilter =>
  filter.split(':')[0] === 'farm.status'
export const isEbtFilter = (filter: FacetFilterBase): filter is EbtFilter => filter?.split(':')[0] === 'isEbt'

/** Identifies an object being a geodoc of any subtype */
export const isGeoDoc = (obj: unknown): obj is AlgoliaGeoDoc => isObject(obj) && nonEmptyString(obj.objectID)

export const isGeoFarm = (obj: unknown): obj is AlgoliaGeoDoc<AlgoliaGeoFarm> =>
  isGeoDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.FARM
export const isGeoDistro = (obj: unknown): obj is AlgoliaGeoDoc<AlgoliaGeoDistro> =>
  isGeoDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.DISTRO
export const isGeoProduct = (obj: unknown): obj is AlgoliaGeoDoc<AlgoliaGeoProduct> =>
  isGeoDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.PRODUCT

/** Identifies an admin doc */
export const isAdminDoc = (obj: unknown): obj is AlgoliaAdminDoc =>
  isObject(obj) && 'index' in obj && obj.index === AlgoliaIndexType.admindata && nonEmptyString(obj.objectID)

export const isAlgoliaAdminCustomer = (obj: unknown): obj is AlgoliaAdminCustomer =>
  isAdminDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.CUSTOMER
export const isAlgoliaAdminProduct = (obj: unknown): obj is AlgoliaAdminProduct =>
  isAdminDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.PRODUCT
export const isAlgoliaAdminOrder = (obj: unknown): obj is AlgoliaAdminOrder =>
  isAdminDoc(obj) && 'docType' in obj && obj.docType === AlgoliaDocType.ORDER

/** Identifies an algolia document of any type */
export const isAlgoliaDoc = (obj: unknown): obj is AlgoliaGeoDoc | AlgoliaAdminDoc => isAdminDoc(obj) || isGeoDoc(obj)

/**A numeric filter type as specified in https://www.algolia.com/doc/api-reference/api-parameters/numericFilters/ */
export type NumericFilterBase<Attr extends Printable> = `${Attr} ${'<' | '<=' | '=' | '!=' | '>=' | '>'} ${number}`

/**Adds type checking to a string that is meant to be a numeric filter */
export const checkNumericFilter = <Attr extends string>(filter: NumericFilterBase<Attr>): NumericFilterBase<Attr> =>
  filter

/** A product category that will be automatically applied to any algolia geo product which belongs to a CSA, regardless of the product type.
 * - For example a standard or digital product that belongs to a csa should have this category added automatically to the al
 * - All share types will belong to a csa so they will all get this category in algolia.
 */
export const AlgoliaShareCategory = 'CSA Products' /** A static name of each algolia index type */
