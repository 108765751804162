import { BottomSheetList, Hoverable, Icon, LoadingView, Text, Toast } from '@elements'
import { memo, useCallback, useContext, useMemo, useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import Colors from '@/constants/Colors'
import { AlgoliaFarmDataContext } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData'
import { ShoppingStackParamList } from '@/navigation/types'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProcessedMenuItem, getLocationRefinementGroup } from '@screens/Shopping/FarmShop/Filtering/filterUtils'
import { FilterItem } from '../FilterItem/FilterItem'

const placeholder = 'Select location'

export const LocationDropdown = memo(function LocationDropdown() {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()
  const [visible, setVisible] = useState(false)

  const toggleVisible = useCallback(() => setVisible((c) => !c), [])

  const {
    params: { locationId },
  } = useRoute<RouteProp<ShoppingStackParamList, 'FarmShop'>>()

  const { locations: dbLocations, loadingLocations, locationFilter } = useContext(AlgoliaFarmDataContext)

  const locations = useMemo(
    () => getLocationRefinementGroup(dbLocations ?? [], locationFilter.items)?.items,
    [dbLocations, locationFilter.items],
  )

  const onLocationPress = useCallback(
    (item: ProcessedMenuItem) => {
      if (item?.shouldClear) {
        navigation.setParams({ locationId: undefined })
        Toast(`Showing all products`)
      } else {
        navigation.setParams({ locationId: item.value })
        Toast(`Showing products available at ${item.label}`)
      }

      setVisible(false)
    },
    [navigation],
  )

  const title = useMemo(
    () => locations?.find((loc) => locationId === loc.value)?.label ?? placeholder,
    [locations, locationId],
  )

  if (!locations?.length) return null

  return (
    <LocationDropdownUI
      locationId={locationId}
      loading={loadingLocations}
      toggleVisible={toggleVisible}
      title={title}
      locations={locations ?? []}
      visible={visible}
      onItemPress={onLocationPress}
    />
  )
})

type LocationDropdownUIProps = {
  disabled?: boolean
  loading?: boolean
  toggleVisible: () => void
  title: string
  locations: ProcessedMenuItem[]
  visible: boolean
  locationId: string | undefined
  onItemPress: (item: ProcessedMenuItem) => void
}

/** UI component for the LocationDropdown */
export const LocationDropdownUI = memo(function LocationDropdownUI(props: LocationDropdownUIProps) {
  const { disabled = false, toggleVisible, locations, visible, locationId, title, onItemPress, loading = false } = props
  const disabledOrLoading = disabled || loading
  return (
    <>
      <Hoverable>
        {(isHovered) => (
          <Pressable
            disabled={disabled}
            onPress={disabledOrLoading ? undefined : toggleVisible}
            style={[styles.cont, !disabledOrLoading && isHovered && styles.onHover]}
          >
            <LoadingView loading={loading} style={styles.iconCont} noDefaultLoadingContainerStyle>
              <Icon name="map-marker-alt" size={18} color={locationId ? Colors.shades['500'] : Colors.shades['200']} />
            </LoadingView>

            <Text
              numberOfLines={1}
              color={disabledOrLoading || title === placeholder ? Colors.shades['200'] : undefined}
            >
              {title}
            </Text>
          </Pressable>
        )}
      </Hoverable>
      <BottomSheetList
        onBackdropPress={toggleVisible}
        isVisible={visible}
        items={locations}
        renderItem={({ item }) => <FilterItem item={item} onPress={() => onItemPress(item)} />}
      />
    </>
  )
})

const styles = StyleSheet.create({
  cont: {
    flexDirection: 'row',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
    height: 40,
    alignItems: 'center',
    paddingRight: 8,
  },

  iconCont: {
    width: '10%',
    minWidth: 30,
    maxWidth: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onHover: {
    borderColor: Colors.green,
  },
})
