import { FormBuilder } from '@components'
import { DateTimePickerForm, DEPRECATED_FormMoneyInput, TextH4, ToggleButton, typography } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { PartialExcept } from '@helpers/typescript'
import { Infinite, makeMoney, Money, Zero } from '@models/Money'
import { PaymentType } from '@models/Payment'
import { isShare, PaymentSchedule, Product, ProductType } from '@models/Product'
import { useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'
import * as Yup from 'yup'

import FormSectionHeader from '../components/FormSectionHeader'
import FormWrapper from '../components/FormWrapper'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'
import { calculateDiscount, calculateTotalPrice, totalPriceDisplay } from './helpers/ShareBillingOptions.helper'

import InputLabel from '@/admin/components/InputLabel'
import Colors from '@/constants/Colors'
import { adminFarmSelector } from '@/redux/selectors'
import { useSelector } from 'react-redux'

type OptionType = {
  active: boolean
  pricePerPickup?: Money
  deposit?: Money
  finalPaymentDate?: DateTime
}

type BillingFormType = {
  payInFull: Pick<OptionType, 'pricePerPickup'>
  payMonthly: OptionType
  payWeekly: OptionType
}

function StatBox({ title, stat }: { title: string; stat: any }) {
  const styles = responsiveStyle()
  return (
    <View style={styles.statBox}>
      <TextH4 style={[styles.statText, { fontSize: 14, color: Colors.shades['500'] }]}>{title}</TextH4>
      <TextH4
        style={[
          styles.statText,
          {
            flex: 1,
            fontSize: 18,
            fontWeight: '600',
          },
        ]}
        size={18}
      >
        {stat}
      </TextH4>
    </View>
  )
}

const pricingValidation: Yup.ObjectSchema<BillingFormType> = Yup.object<BillingFormType>().shape({
  payInFull: Yup.object()
    .required()
    .shape({
      pricePerPickup: Yup.mixed<Money>()
        .transform((_, amt: Money) => (amt && amt.value !== Infinity ? amt.value : undefined))
        .when('$type', {
          is: (type: ProductType) => isShare({ type }),
          then: (schema) => schema.required('Price per pickup is required'),
        }),
    }),
  payMonthly: Yup.object()
    .required()
    .shape({
      active: Yup.boolean().required(),
      pricePerPickup: Yup.mixed<Money>()
        .transform((_, amt: Money) => (amt && amt.value !== Infinity ? amt.value : undefined))
        .when('active', {
          is: true,
          then: (schema) => schema.required('Price per pickup is required'),
        }),
      deposit: Yup.mixed<Money>()
        .transform((_, amt: Money) => (amt && amt.value !== Infinity ? amt.value : undefined))
        .when('active', {
          is: true,
          then: (schema) => schema.required('Deposit is required'),
        }),
      finalPaymentDate: Yup.mixed<DateTime>().when('active', {
        is: (active: boolean) => active === true,
        then: (schema) => schema.required('Final payment date is required'),
      }),
    }),
  payWeekly: Yup.object()
    .required()
    .shape({
      active: Yup.boolean().required(),
      pricePerPickup: Yup.mixed<Money>()
        .transform((_, amt: Money) => (amt && amt.value !== Infinity ? amt.value : undefined))
        .when('active', {
          is: true,
          then: (schema) => schema.required('Price per pickup is required'),
        }),
      deposit: Yup.mixed<Money>()
        .transform((_, amt: Money) => (amt && amt.value !== Infinity ? amt.value : undefined))
        .when('active', {
          is: true,
          then: (schema) => schema.required('Deposit is required'),
        }),
      finalPaymentDate: Yup.mixed<DateTime>().when('active', {
        is: (active: boolean) => active === true,
        then: (schema) => schema.required('Final payment date is required'),
      }),
    }),
})

const toFormik = (product: PartialExcept<Product, 'type'>): BillingFormType => {
  // Initial values, will be updated later in this function to hold correct values
  const base: BillingFormType = {
    payInFull: {
      pricePerPickup: Infinite,
    },
    payMonthly: {
      active: false,
      pricePerPickup: Infinite,
      deposit: Infinite,
      finalPaymentDate: undefined,
    },
    payWeekly: {
      active: false,
      pricePerPickup: Infinite,
      deposit: Infinite,
      finalPaymentDate: undefined,
    },
  }

  if (product && isShare(product)) {
    const calculatePricePerPickup = (ps: PaymentSchedule) => {
      return MoneyCalc.round(MoneyCalc.divide(ps.amount, product.numberPickups))
    }

    product.paymentSchedules?.map((ps) => {
      if (ps.frequency === 'ONCE') base.payInFull.pricePerPickup = calculatePricePerPickup(ps)
      if (ps.frequency === 'MONTHLY') {
        base.payMonthly.active = true
        base.payMonthly.pricePerPickup = calculatePricePerPickup(ps)
        base.payMonthly.deposit = MoneyCalc.round(ps.deposit)
        base.payMonthly.finalPaymentDate = ps.paymentDates.endDate
      }
      if (ps.frequency === 'WEEKLY') {
        base.payWeekly.active = true
        base.payWeekly.pricePerPickup = calculatePricePerPickup(ps)
        base.payWeekly.deposit = MoneyCalc.round(ps.deposit)
        base.payWeekly.finalPaymentDate = ps.paymentDates.endDate
      }
    })
  }
  return base
}

function fromFormik<T extends BillingFormType & { type?: ProductType; numberOfPickups?: number }>(
  values: T,
): Partial<Product> {
  const base: PartialExcept<Product, 'type'> = {
    type: values.type!,
  }

  // If this is not a share don't return anything
  if (!isShare(base)) return {}

  base.paymentSchedules = []
  base.paymentSchedules?.push({
    amount: MoneyCalc.round(MoneyCalc.multiply(values.payInFull.pricePerPickup ?? Zero, values.numberOfPickups!)),
    deposit: makeMoney(0),
    paymentDates: {
      // For upfront payments the dates are meaningless, so we set endDate to now
      endDate: DateTime.now(),
    },
    frequency: 'ONCE',
    paymentType: PaymentType.PAY_FULL,
  })
  if (values.payMonthly.active) {
    const monthlyPrice: Money = MoneyCalc.math(
      Math.round,
      MoneyCalc.multiply(values.payMonthly.pricePerPickup ?? Zero, values.numberOfPickups!),
    )

    base.paymentSchedules?.push({
      amount: MoneyCalc.round(monthlyPrice),
      deposit: MoneyCalc.round(values.payMonthly.deposit ?? Zero),
      paymentDates: {
        endDate: values.payMonthly.finalPaymentDate!,
      },
      frequency: 'MONTHLY',
      paymentType: PaymentType.INSTALLMENTS,
    })
  }
  if (values.payWeekly.active) {
    const weeklyPrice: Money = MoneyCalc.math(
      Math.round,
      MoneyCalc.multiply(values.payWeekly.pricePerPickup ?? Zero, values.numberOfPickups!),
    )

    base.paymentSchedules?.push({
      amount: MoneyCalc.round(weeklyPrice),
      deposit: MoneyCalc.round(values.payWeekly.deposit ?? Zero),
      paymentDates: {
        endDate: values.payWeekly.finalPaymentDate!,
      },
      frequency: 'WEEKLY',
      paymentType: PaymentType.INSTALLMENTS,
    })
  }

  return base
}

export const FormikShareBilling = new ProductFormikComponent<BillingFormType>(pricingValidation, toFormik, fromFormik)

export default function ShareBilling() {
  /*
   * We are adding the type of numberOfPickups as it is defined in the outer form context, and we need it here
   */
  const { values, setFieldValue, touched, errors } = useFormikContext<BillingFormType & { numberOfPickups: string }>()
  const numberOfPickups = values.numberOfPickups ? parseInt(values.numberOfPickups, 10) : 0
  const payInFullTotal = calculateTotalPrice(numberOfPickups, values.payInFull.pricePerPickup)
  const payMonthlyTotal = calculateTotalPrice(numberOfPickups, values.payMonthly.pricePerPickup)
  const payWeeklyTotal = calculateTotalPrice(numberOfPickups, values.payWeekly.pricePerPickup)
  const { timezone } = useSelector(adminFarmSelector)

  // Get the max pricePerPickup to calculate discounts based off of
  const largestTotalPrice = MoneyCalc.max(
    MoneyCalc.max(payInFullTotal, MoneyCalc.isInfinite(payMonthlyTotal) ? Zero : payMonthlyTotal),
    MoneyCalc.isInfinite(payWeeklyTotal) ? Zero : payWeeklyTotal,
  )
  const styles = responsiveStyle()

  return (
    <FormWrapper>
      <FormSectionHeader title="Billing Options" subtitle="You can offer different box prices per payment schedule." />
      <FormBuilder style={styles.billingOption}>
        <View style={styles.optionHeader}>
          <TextH4 style={styles.optionTitle}>Pay in full</TextH4>
        </View>
        <FormBuilder style={styles.payInFullContainer}>
          <FormBuilder style={styles.optionInputs}>
            <DEPRECATED_FormMoneyInput
              value={values.payInFull.pricePerPickup}
              label={<InputLabel label="Price Per Pickup" required />}
              onChangeText={(value) => setFieldValue('payInFull.pricePerPickup', value)}
              errorMessage={touched.payInFull ? (errors.payInFull?.pricePerPickup as string) : ''}
            />
          </FormBuilder>
          <View style={styles.stateBoxContainer}>
            <StatBox
              title="Discount"
              stat={calculateDiscount(
                calculateTotalPrice(numberOfPickups, values.payInFull.pricePerPickup),
                largestTotalPrice,
              )}
            />
            <StatBox title="Pickups" stat={numberOfPickups} />
            <StatBox title="Total Price" stat={totalPriceDisplay(numberOfPickups, values.payInFull.pricePerPickup)} />
          </View>
        </FormBuilder>
      </FormBuilder>
      <FormBuilder style={styles.billingOption}>
        <View style={styles.optionHeader}>
          <View style={{ minWidth: 150 }}>
            <TextH4 style={styles.optionTitle}>Pay monthly</TextH4>
          </View>

          <ToggleButton
            onChange={() => setFieldValue('payMonthly.active', !values.payMonthly.active)}
            title=""
            value={values.payMonthly.active}
          />
        </View>
        {values.payMonthly.active && (
          <FormBuilder style={styles.payMonthlyWeeklyContainer}>
            <FormBuilder style={styles.optionInputs}>
              <DEPRECATED_FormMoneyInput
                containerStyle={styles.inputContainer}
                style={{ width: '100%' }}
                value={values.payMonthly.pricePerPickup}
                label={<InputLabel label="Price Per Pickup" required />}
                onChangeText={(value) => setFieldValue('payMonthly.pricePerPickup', value)}
                errorMessage={errors.payMonthly?.pricePerPickup as string}
              />
              <DEPRECATED_FormMoneyInput
                containerStyle={styles.inputContainer}
                style={{ width: '100%' }}
                value={values.payMonthly.deposit}
                label={<InputLabel label="Deposit" required />}
                onChangeText={(value) => setFieldValue('payMonthly.deposit', value)}
                errorMessage={errors.payMonthly?.deposit as string}
              />
              <DateTimePickerForm
                label={<InputLabel label="Final Installment Date" required />}
                value={values.payMonthly.finalPaymentDate}
                onChange={(item) => setFieldValue('payMonthly.finalPaymentDate', item)}
                timezone={timezone}
                containerStyle={styles.dateInputContainerStyle}
                errorMessage={errors.payMonthly?.finalPaymentDate as string}
              />
            </FormBuilder>
            <View style={styles.stateBoxContainer}>
              <StatBox
                title="Discount"
                stat={calculateDiscount(
                  calculateTotalPrice(numberOfPickups, values.payMonthly.pricePerPickup),
                  largestTotalPrice,
                )}
              />
              <StatBox title="Pickups" stat={numberOfPickups} />
              <StatBox
                title="Total Price"
                stat={totalPriceDisplay(numberOfPickups, values.payMonthly.pricePerPickup)}
              />
            </View>
            {/* <View style={styles.stateBoxContainerVariation}>
              <View style={styles.twoStateBoxesWrapper}>
                <StatBox
                  title="Discount"
                  stat={calculateDiscount(
                    calculateTotalPrice(numberOfPickups, values.payMonthly.pricePerPickup, values.payMonthly.deposit),
                    largestTotalPrice,
                  )}
                />
                <StatBox title="Pickups" stat={numberOfPickups} />
              </View>
              <View style={styles.twoStateBoxesWrapper}>
                <StatBox
                  title="Total Price"
                  stat={totalPriceDisplay(numberOfPickups, values.payMonthly.pricePerPickup, values.payMonthly.deposit)}
                />
                <StatBox title="Monthly Installment" stat="NOT IMPLEMENTED" />
              </View>
            </View> */}
          </FormBuilder>
        )}
      </FormBuilder>
      <FormBuilder style={styles.billingOption}>
        <View style={styles.optionHeader}>
          <View style={{ minWidth: 150 }}>
            <TextH4 style={styles.optionTitle}>Pay Weekly</TextH4>
          </View>
          <ToggleButton
            onChange={() => setFieldValue('payWeekly.active', !values.payWeekly.active)}
            title=""
            value={values.payWeekly.active}
          />
        </View>
        {values.payWeekly.active && (
          <FormBuilder style={styles.payMonthlyWeeklyContainer}>
            <FormBuilder style={styles.optionInputs}>
              <DEPRECATED_FormMoneyInput
                containerStyle={styles.inputContainer}
                style={{ width: '100%' }}
                value={values.payWeekly.pricePerPickup}
                label={<InputLabel label="Price Per Pickup" required />}
                onChangeText={(value) => setFieldValue('payWeekly.pricePerPickup', value)}
                errorMessage={errors.payWeekly?.pricePerPickup as string}
              />
              <DEPRECATED_FormMoneyInput
                containerStyle={styles.inputContainer}
                style={{ width: '100%' }}
                value={values.payWeekly.deposit}
                label={<InputLabel label="Deposit" required />}
                onChangeText={(value) => setFieldValue('payWeekly.deposit', value)}
                errorMessage={errors.payWeekly?.deposit as string}
              />
              <DateTimePickerForm
                label={<InputLabel label="Final Installment Date" required />}
                value={values.payWeekly.finalPaymentDate}
                onChange={(item) => setFieldValue('payWeekly.finalPaymentDate', item)}
                timezone={timezone}
                containerStyle={styles.dateInputContainerStyle}
                errorMessage={errors.payWeekly?.finalPaymentDate as string}
              />
            </FormBuilder>
            <View style={styles.stateBoxContainer}>
              <StatBox
                title="Discount"
                stat={calculateDiscount(
                  calculateTotalPrice(numberOfPickups, values.payWeekly.pricePerPickup),
                  largestTotalPrice,
                )}
              />
              <StatBox title="Pickups" stat={numberOfPickups} />
              <StatBox title="Total Price" stat={totalPriceDisplay(numberOfPickups, values.payWeekly.pricePerPickup)} />
            </View>
            {/* <View style={styles.stateBoxContainerVariation}>
              <View style={styles.twoStateBoxesWrapper}>
                <StatBox
                  title="Discount"
                  stat={calculateDiscount(
                    calculateTotalPrice(numberOfPickups, values.payWeekly.pricePerPickup, values.payWeekly.deposit),
                    largestTotalPrice,
                  )}
                />
                <StatBox title="Pickups" stat={numberOfPickups} />
              </View>
              <View style={styles.twoStateBoxesWrapper}>
                <StatBox
                  title="Total Price"
                  stat={totalPriceDisplay(numberOfPickups, values.payWeekly.pricePerPickup, values.payWeekly.deposit)}
                />
                <StatBox title="Monthly Installment" stat="NOT IMPLEMENTED" />
              </View>
            </View> */}
          </FormBuilder>
        )}
      </FormBuilder>
    </FormWrapper>
  )
}

const responsiveStyle = CreateResponsiveStyle(
  {
    optionHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    optionTitle: {
      fontFamily: typography.body.medium,
      fontSize: 18,
      paddingLeft: 10,
    },
    billingOption: {
      paddingVertical: 8,
    },
    statBox: {
      flex: 1,
    },
    statText: {
      fontFamily: typography.body.regular,
      color: Colors.shades['500'],
      marginBottom: 5,
    },
    optionInputs: {
      paddingRight: 25,
    },
    payInFullContainer: {
      flexDirection: 'row',
    },
    payMonthlyWeeklyContainer: {
      flexDirection: 'row',
    },
    stateBoxContainer: {
      flexDirection: 'row',
      paddingTop: 20,
    },
    stateBoxContainerVariation: {
      flex: 1,
      justifyContent: 'space-around',
      paddingTop: 20,
    },
    twoStateBoxesWrapper: {
      flexDirection: 'row',
    },
    inputContainer: {
      flex: 1,
    },
    dateInputContainerStyle: {
      marginBottom: 0,
      fontSize: 14,
      marginTop: 8,
      padding: 13,
    },
  },
  {
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      payInFullContainer: {
        flexDirection: 'column',
      },
      payMonthlyWeeklyContainer: {
        flexDirection: 'column',
      },
      stateBoxContainer: {
        paddingLeft: 20,
      },
      stateBoxContainerVariation: {
        paddingLeft: 20,
      },
    },

    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      optionInputs: {
        paddingRight: 10,
      },
      optionHeader: {
        justifyContent: 'space-between',
      },

      stateBoxContainer: {
        paddingTop: 10,
      },
    },
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      optionInputs: {
        minWidth: 400,
        flexDirection: 'row',
      },
      stateBoxContainer: {
        flex: 1,
      },
      stateBoxContainerVariation: {
        flex: 1,
        justifyContent: 'space-around',
        flexDirection: 'column',
      },
      twoStateBoxesWrapper: {
        flex: 1,
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      optionInputs: {
        flex: 1,
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)]: {
      optionInputs: {
        minWidth: 550,
      },
      stateBoxContainerVariation: {
        flexDirection: 'row',
      },
    },
  },
)
