import { ButtonClear, HeaderText, Touchable } from '@elements'
import { Entypo } from '@expo/vector-icons'
import { memo, useCallback, useRef, useState } from 'react'
import {
  FlatList,
  FlatListProps,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'

import { SkeletonContent } from './SkeletonContent'
import Colors from '../constants/Colors'
import { globalStyles } from '../constants/Styles'

import { isWeb } from '@/constants/Layout'
import { useDeepCompareMemoize } from '@/hooks/useDeepEqualEffect'

type Props<ItemT> = {
  /**
   *
   * FIXME:
   *
   * We need a description here of the requirements for managing and future proofing instances of this component in which a card width value is provided inline. My concern is, when you use the SeeMoreList somewhere, you pass the card width of the component you're rendering, but if that component's width ever changes, this will be wrong because it was passed as an inline number and has no reusability. So that means the current design depends on someone being in responsible of updating card widths whenever card components change.
   *
   * - Will someone be responsible for periodically monitoring that all instances of the SeeMoreList have a cardWidth that correctly represents the render width of the card component?
   * - Are the card widths for a given component the same number across web, android and ios? For example, the real, measured card width, won't have a different value if I were to measure it in web, and mobile? I feel like a measurement we made in web might not be the same measurement in mobile, for the same component.
   * - If we reach the conclusion that passing card widths inline is not a very maintainable design, we need to provide best practices for exporting a constant for the card width of each component used in the SeeMoreList? That's what I suggested we start doing with the product card square, but it's not happening for other components in the SeeMoreList.
   * - If someone who has never used this component needed to use it, what would they need to understand about it, to make use of it in a low-maintenance way, and not prone to regressions, and not requiring constant monitoring and re-measuring?
   *   */
  cardWidth: number
  title: string
  data: ItemT[]
  renderItem: (item: ItemT, index: number) => JSX.Element
  // Can be a url or a callback
  seeAllPress?: (() => void) | string
  seeAllLabel?: string
}

const NavigateBtn = memo(function NavigateBtn({
  type,
  onPress,
  disabled,
}: {
  type: 'left' | 'right'
  /** This onPress must always be memoized, or it can produce the "excessive pending callbacks" error */
  onPress: (dir: number) => void
  disabled: boolean
}) {
  return (
    <Touchable onPress={() => onPress(type === 'left' ? -1 : 1)} style={styles.navBtn} disabled={disabled}>
      <Entypo name={`chevron-${type}`} size={14} color={Colors.shades[disabled ? '300' : '500']} />
    </Touchable>
  )
})

export type SeeMoreListProps<ItemT> = Props<ItemT> & Omit<FlatListProps<ItemT>, 'renderItem' | 'ItemSeparatorComponent'>

/**
 * Generic horizontal list that can handle scrolling with buttons
 *
 * ItemSeparatorComponent cannot be added as the items width will be wrongly calculated
 */
function SeeMoreListComp<ItemT extends Record<any, any> & { id: string }>({
  title,
  data,
  renderItem,
  cardWidth,
  seeAllPress,
  seeAllLabel = 'See All',
  keyExtractor,
  ...otherProps
}: SeeMoreListProps<ItemT>) {
  const scrollRef = useRef<FlatList>(null)

  const [currIndex, setCurrIndex] = useState<number>(0)
  const [reachedEnd, setReachedEnd] = useState(false)

  const _renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<ItemT>) => (
      <View style={{ width: cardWidth }} key={title + (item.id || index)}>
        {renderItem(item, index)}
      </View>
    ),
    [cardWidth, renderItem, title],
  )

  const scrollMore = useCallback(
    (dir: number) => {
      if (!scrollRef.current) return

      const offset = (currIndex + dir) * cardWidth
      scrollRef.current.scrollToOffset({ offset })
    },
    [currIndex, cardWidth],
  )

  const onScroll = useCallback(
    ({ nativeEvent: e }: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (e.contentSize.width - e.contentOffset.x - e.layoutMeasurement.width < cardWidth / 2) {
        if (!reachedEnd) setReachedEnd(true)
      } else {
        if (reachedEnd) setReachedEnd(false)
      }
      let newIndex = e.contentOffset.x / cardWidth
      newIndex = Math.round(newIndex)
      if (currIndex !== newIndex) setCurrIndex(newIndex)
    },
    [cardWidth, currIndex, reachedEnd],
  )

  const onContentSizeChange = useCallback(
    (w: number, _: number) => {
      if (data.length * cardWidth < w && reachedEnd === false) setReachedEnd(true)
    },
    [data.length, cardWidth, reachedEnd],
  )

  const contentContainerStyle: StyleProp<ViewStyle> = useDeepCompareMemoize([
    isWeb && globalStyles.flex1,
    { alignItems: 'flex-end' },
  ])

  if (data.length === 0) return null

  return (
    <View style={styles.container}>
      <View style={styles.headerView}>
        <HeaderText style={styles.marginLeft10}>{title}</HeaderText>
        <View style={styles.headerBtns}>
          {!!seeAllPress && (
            <>
              <ButtonClear
                url={typeof seeAllPress === 'string' ? seeAllPress : undefined}
                title={seeAllLabel}
                size={14}
                onPress={typeof seeAllPress === 'function' ? seeAllPress : undefined}
              />
              <View style={styles.horizSpacing} />
            </>
          )}
          <NavigateBtn type="left" onPress={scrollMore} disabled={currIndex === 0} />
          <View style={styles.horizSpacing} />
          <NavigateBtn type="right" onPress={scrollMore} disabled={reachedEnd} />
        </View>
      </View>
      <FlatList
        ref={scrollRef}
        onScroll={onScroll}
        contentContainerStyle={contentContainerStyle}
        showsHorizontalScrollIndicator={false}
        horizontal
        data={data}
        keyExtractor={keyExtractor ?? ((item: ItemT, idx) => title + (item.id || idx))}
        renderItem={_renderItem}
        onEndReachedThreshold={1 / data.length}
        onContentSizeChange={onContentSizeChange}
        ListEmptyComponent={<SkeletonContent isLoading={false} />}
        {...otherProps}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  headerView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  headerBtns: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  navBtn: {
    marginRight: 5,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -2,
    borderRadius: 25,
    borderColor: Colors.shades['100'],
    borderWidth: 1,
  },
  horizSpacing: {
    width: 5,
  },
  marginLeft10: {
    marginLeft: 10,
  },
})

export const SeeMoreList = memo(SeeMoreListComp) as typeof SeeMoreListComp
