import { isObject } from '@helpers/helpers'
import { DocumentSnapshot } from 'firebase/firestore'

export function prepareUnmarshal<T = FirebaseFirestore.DocumentData>(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  data?: FirebaseFirestore.DocumentData,
  opts?: { withMeta?: boolean },
): [string, T] {
  /**
   * Handle undefined case for backwards compatibility.
   * The type definition expects a value but some callers have not adhered to the types and may pass unexpected values to the marshalers.
   * This passes the values through like the original call would have when idOrSnapshot is undefined.
   */
  if (!idOrSnapshot) {
    // strip meta if not requested to keep it.
    if (isObject(data) && opts?.withMeta !== true) delete data.meta
    return [idOrSnapshot!, data! as T]
  }

  if (typeof idOrSnapshot === 'string') {
    if (!data || !isObject(data)) {
      throw new Error('A data record is required when id is a string')
    }
    // strip meta if not requested to keep it.
    if (opts?.withMeta !== true) delete data.meta

    return [idOrSnapshot, (data ?? {}) as T]
  }

  if ('data' in idOrSnapshot && typeof idOrSnapshot.data === 'function') {
    const result = idOrSnapshot.data()
    // strip meta if not requested to keep it.
    if (result && opts?.withMeta !== true) delete result.meta
    return [idOrSnapshot.id, (result ?? {}) as T]
  }

  // strip meta if not requested to keep it.
  if (isObject(idOrSnapshot) && opts?.withMeta !== true) delete (idOrSnapshot as unknown as any).meta

  return [idOrSnapshot.id, idOrSnapshot as unknown as T]
}

/**
 * prepareMarshal is a public helper and will be used for every marshal function in each Model.
 * - It will make sure that meta is striped from the data, so there is no way meta could be a part of the data after marshaling.
 */
export function prepareMarshal<T extends object>(data: T): T {
  const newData = { ...data }

  // @ts-expect-error: This should always fail because meta is not defined on models, but we want to confirm that no one tries to update meta and explicitly remove in case there is an issue
  delete newData.meta

  return newData
}
