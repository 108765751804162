import * as React from 'react'
import { createRef, memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, StyleSheet, TextInput, View, ViewStyle } from 'react-native'

import { SearchInputBar } from './SearchInputBar/SearchInputBar'

import { Logger } from '@/config/logger'
import {
  autocompleteContainerBaseStyle,
  AutoCompleteItem,
  AutoCompleteProps,
  getDefaultRenderAutocompleteItem,
  initialPos,
  Position,
  useAutoComplete,
} from '@/hooks/useAutoComplete'
import { useDeepCompareLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeepCompareFocusFx } from '@/hooks/useFocusFx'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { SearchInputBarProps } from './SearchInputBar/SearchInputBar.helpers'

export type AutoCompleteInputProps<T> = Omit<SearchInputBarProps, 'onFocus' | 'onTouchStart' | 'contStyle'> & {
  /** Autocomplete results */
  items: AutoCompleteProps<T>['items']
  /** Called on item press */
  onSelect: AutoCompleteProps<T>['onSelect']
  /** Helps differentiate this autocomplete from any other autocomplete instance */
  autoCompleteId?: AutoCompleteProps<T>['value']
  /** Autocomplete options */
  options?: AutoCompleteProps<T>['otherProps']
  inputRef?: RefObject<TextInput>
  /** If inline is true, this component will render results as elements directly below the Input, instead of using an overlay */
  inline?: boolean
  /** This style will wrap the input and inline content if any  */
  contStyle?: ViewStyle
  /** This style will be passed to the search input bar, as @type {SearchInputBarProps['contStyle']}. This is necessary here because both the SearchInputBar and the AutocompleteInput have their own "contStyle" prop */
  searchInputContStyle?: SearchInputBarProps['contStyle']
}

/**
 * Simple TextInput integrated with an autocomplete modal. Also supports inline rendering mode.
 *
 * Will show the autocomplete list from the `items`, and will call `onSelect` on item press.
 */
export const AutoCompleteInput = memo(function AutoCompleteInput<T>({
  items,
  onSelect: onSelectProp,
  autoCompleteId = '',
  options,
  /*This inputRef should not have a default value defined on prop destructuring, as is normally done with props objects, or the ref might misbehave.*/
  inputRef: inputRefProp,
  onChangeText: onChangeTextProp,
  inline,
  contStyle,
  searchInputContStyle,
  clear,
  ...inputProps
}: AutoCompleteInputProps<T>) {
  /** visibleInlineResults controls whether inline results are visible. Only used in inline mode */
  const [visibleInlineResults, setVisibleInlineResults] = useState(false)
  const [inputPos, setInputPos] = useState<Position>(initialPos)

  /** This manages the autocomplete in overlay mode only */
  const {
    state: acProps,
    autoCompleteOverlay,
    showAutocomplete,
    updateAutocomplete,
    hideAutocomplete,
  } = useAutoComplete()

  /** The inputRefProp?.current should be in the deps, so that on each re-render, the inputRef used will be either the prop if defined, or will be created if the prop is nullish*/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputRef = useMemo(() => inputRefProp ?? createRef<TextInput>(), [inputRefProp?.current])
  /** This ref is used to measure the input container, which helps with the sizing of the results list in inline mode. */
  const contRef = useRef<View>(null)

  const onSelect = useCallback(
    (itm: AutoCompleteItem<T>) => {
      //This will make sure that the input is blured everytime we click an item
      inputRef.current?.blur()
      onSelectProp?.(itm)
    },
    [inputRef, onSelectProp],
  )

  /** Measures the position of the text input container, to position the inline content */
  useDeepCompareFocusFx(() => {
    if (!contRef?.current) return

    contRef.current.measureInWindow((x, y, width, height) => {
      if (x === undefined || y === undefined || width === undefined || height === undefined) {
        // https://github.com/facebook/react-native/issues/29638
        throw new Error('Could not measure element, make sure the source element has collapsable set to false')
      }
      setInputPos({ top: y, left: x, width, height })
    })
    //Both items and ref are intentionally in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, contRef.current])

  /** On items change, if input focused, will show or update autocomplete list in the UI */
  useEffect(() => {
    // This will update the autocomplete list on change
    if (!inputRef.current?.isFocused()) return
    if (inline) {
      setVisibleInlineResults(true)
    } else {
      acProps?.value
        ? updateAutocomplete(inputRef, items)
        : showAutocomplete(autoCompleteId, inputRef, items, onSelect, options)
    }
    /** Only intended to run when items change. Doesn't need deep compare because it's already a memoed comp with deepcompare props */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  const onFocus = useCallback(() => {
    if (inputRef !== null && typeof inputRef === 'object') {
      if (inline) {
        setVisibleInlineResults(true)
      } else {
        showAutocomplete(autoCompleteId, inputRef, items, onSelect, options)
      }
    } else {
      Logger.warn('A ref object was expected')
    }
  }, [autoCompleteId, inputRef, items, onSelect, showAutocomplete, options, inline])

  /** This will clear the results in Inline mode when the user clicks out of the input. It is for inline only because the autocomplete dialog automatically hides itself when pressing the background */
  const onBlur = useCallback(() => {
    if (!inline) return
    // A timeout is needed here so the user option press is captured. If no timeout is provided, onBlur will be called before onPress from the chosen option, and the component will be unmounted
    setTimeout(() => {
      setVisibleInlineResults(false)
    }, 500)
  }, [inline])

  const onChangeText = useCallback(
    (text: string) => {
      onChangeTextProp?.(text)
      if (!items.length) return
      if (inline) {
        setVisibleInlineResults(true)
      } else {
        showAutocomplete(autoCompleteId, inputRef, items, onSelect, options)
      }
    },
    [onChangeTextProp, inputRef, onSelect, showAutocomplete, autoCompleteId, items, options, inline],
  )

  const respStyles = useStyles({
    matchWidth: options?.matchWidth,
    sourceWidth: inputPos.width,
    autoCompleteContStyle: options?.autoCompleteContStyle,
  })

  /** Content only shown when inline mode is true */
  const inlineContent = useMemo(() => {
    if (!inline || !visibleInlineResults || !items.length) return null
    return (
      // This FlatList is meant to be as similar as possible to the content rendered by the main autocomplete overlay, so it looks the same in inline and overlay mode
      <FlatList
        style={respStyles.autocompleteCont}
        scrollEnabled={false}
        keyboardShouldPersistTaps="always"
        data={items}
        renderItem={
          options?.renderItem ??
          getDefaultRenderAutocompleteItem({
            hideAutocomplete: () => setVisibleInlineResults(false),
            itemsLength: items.length,
            onSelect,
          })
        }
        keyExtractor={({ text }) => text}
      />
    )
  }, [
    inline,
    items,
    onSelect,
    visibleInlineResults,
    setVisibleInlineResults,
    options?.renderItem,
    respStyles.autocompleteCont,
  ])

  return (
    <View style={[styles.cont, inline && contStyle]}>
      <SearchInputBar
        contStyle={searchInputContStyle}
        contRef={contRef}
        inputRef={inputRef}
        onFocus={onFocus}
        onBlur={onBlur}
        onChangeText={onChangeText}
        onSubmitEditing={() => !inline && hideAutocomplete()}
        // We should show the clear option only if there is a value selected because it is meant to be used for clearing the current input value
        clear={inputProps.value ? clear : undefined}
        {...inputProps}
      />
      {inline ? inlineContent : autoCompleteOverlay}
    </View>
  )
}, propsAreDeepEqual) as <T>(props: AutoCompleteInputProps<T>) => JSX.Element

type UseInlineStylesOpts = Pick<AutoCompleteProps<any>['otherProps'], 'matchWidth' | 'autoCompleteContStyle'> & {
  sourceWidth: number
}

const styles = StyleSheet.create({
  /** This is the container that wraps everything including the inline content */
  cont: {
    flexDirection: 'column',
  },
})

const useStyles = ({ matchWidth, sourceWidth, autoCompleteContStyle }: UseInlineStylesOpts) =>
  useDeepCompareLayoutFnStyles(
    (_, { matchWidth, sourceWidth, autoCompleteContStyle }) => ({
      /** This style is meant to be an inline copy of the style autocompleteCont of useAutocomplete. They actually need different styling logic */
      autocompleteCont: {
        ...autocompleteContainerBaseStyle,
        /** If the matchWidth option is specified, the width will be the same as the source element */
        width: matchWidth && sourceWidth ? sourceWidth : 'auto',
        ...autoCompleteContStyle,
      },
    }),
    { matchWidth, sourceWidth, autoCompleteContStyle },
  )
