import { csasCollection } from '@api/framework/ClientCollections'
import { AvailAddonResult, getAvailAddons } from '@helpers/addons'
import { haveSameItems, removeDuplicates, wait } from '@helpers/helpers'
import { sortByName } from '@helpers/sorting'
import { AddonShare, isAddon, ProductType } from '@models/Product'
import { useDispatch, useSelector } from 'react-redux'

import { useAvailAddons } from '../../hooks/useAvailAddons'
import { useCancelableFx } from '../../hooks/useCancelablePromise'
import { useCartService } from '../../hooks/useCart'
import { setAvailAddonsAction, setAvailAddonsCSAs, setAvailAddonsPurchasesAction } from '../../redux/actions/appPersist'
import {
  addonsPurchasesSelector,
  consumerCartInfoSelector,
  useFarmDataFromCache,
  userSelector,
} from '../../redux/selectors'
import { generateOrderHash, getAvailAddons_orders } from './useSetAvailAddons-helpers'

import { snapshotOrdersByUserAndDueDate } from '@api/Orders'
import { Order } from '@models/Order'
import { useEffect, useMemo, useState } from 'react'
import { loadProductsByFarmAndType } from '@api/Products'

/**
 * Calculates and sets the available addons from both past purchases and cart
 */
export function useSetAvailAddons() {
  const { cart } = useCartService()
  const { cartFarmId } = useSelector(consumerCartInfoSelector)
  const availAddonsPurchases = useSelector(addonsPurchasesSelector)
  const { availAddonsResults: currentResults, availAddonCsas: currentCsas } = useAvailAddons()
  const dispatch = useDispatch()

  /** cartFarmAddons are the addons from the farm whose products are in the cart */
  const [cartFarmAddons, setCartFarmAddons] = useState<AddonShare[]>([])

  const { prods: cartFarmProdsCache, loadingCompleted: isCacheLoadingComplete } = useFarmDataFromCache(cartFarmId || '')

  /** gets the addons of the cartFarmId that may be available in the cache */
  const cartFarmAddonsFromCache = useMemo<AddonShare[]>(() => {
    if (cartFarmProdsCache?.length && isCacheLoadingComplete) {
      return cartFarmProdsCache.filter(isAddon)
    }
    return []
  }, [cartFarmProdsCache, isCacheLoadingComplete])

  /** Gets the cartFarmAddons when the cartFarm changes */
  useCancelableFx(
    async (isCurrent) => {
      if (!cartFarmId) return setCartFarmAddons([])

      if (!isCacheLoadingComplete) return

      // If the addons in cache were useable, set them and be done. Else we must fetch them directly
      if (cartFarmAddonsFromCache.length) {
        return setCartFarmAddons(cartFarmAddonsFromCache)
      }

      /** This is a simple way to debounce the run, in case the addons might become available in the cache, which would prevent wasting resources fetching them again here */
      await wait(500)
      if (!isCurrent) return

      const addons = (await loadProductsByFarmAndType(cartFarmId, ProductType.AddonShare)) as AddonShare[]

      if (!isCurrent) return

      setCartFarmAddons(addons)
    },
    [cartFarmId, cartFarmAddonsFromCache, isCacheLoadingComplete],
  )

  /** Gets the avail addons for the cart, merges with availAddons from purchases, and sets results to context */
  useEffect(
    () => {
      // These are the available addons based on the cart's products
      let resultsCart: AvailAddonResult[] = []

      // 1. Get the availAddons from the cart
      if (cart.length && cartFarmAddons.length) {
        resultsCart = getAvailAddons(cart, cartFarmAddons)
      }

      // 2. Merge availAddonsCart with availAddons from purchases, and handle the case where a result might be in both lists
      const newResults: AvailAddonResult[] = resultsCart.reduce((resultList, cartRes) => {
        const purchasesIds = resultList.map((a) => a.id)
        if (!purchasesIds.includes(cartRes.id)) {
          // If the results from purchases don't include the current result from cart, just add it to the list
          return [...resultList, cartRes]
        }

        // If the availAddon result is already in the results from purchases, we must merge them with no duplicates

        // get the result from the purchases list
        const purchasesRes = resultList.find((a) => a.id === cartRes.id)!

        // see if either one is available
        const availableResults = [purchasesRes, cartRes].filter((res) => res.isAvail)

        if (availableResults.length === 1) {
          // If one of the two results is available, return that one
          return resultList.map((res) => (res.id === availableResults[0].id ? availableResults[0] : res))
        } else if (availableResults.length === 0) {
          // If none are available prefer the result from the cart calculation
          return resultList.map((res) => (res.id === cartRes.id ? cartRes : res))
        } else {
          // If both are available, merge their matching ids, and prefer the result from the cart calculation for the data
          const merge: AvailAddonResult = {
            ...cartRes,
            matchingCSAIds: removeDuplicates([...purchasesRes.matchingCSAIds, ...cartRes.matchingCSAIds]),
            matchingDistIds: removeDuplicates([...purchasesRes.matchingDistIds, ...cartRes.matchingDistIds]),
          }
          return resultList.map((res) => (res.id === merge.id ? merge : res))
        }
      }, availAddonsPurchases.data)

      // 3. Compare the current and new availability results
      const newResultsSummary = newResults.map(({ id, isAvail }) => id + isAvail)
      const currResultsSummary = currentResults.map(({ id, isAvail }) => id + isAvail)

      // 4. If results aren't the same, set the new availAddons results
      if (!haveSameItems(newResultsSummary, currResultsSummary))
        dispatch(setAvailAddonsAction(newResults.sort((a, b) => sortByName(a, b, ({ addon }) => addon.name))))
    },
    // it doesn't need to depend on availAddonsPurchases.data, because the ".hash" property is meant to encode and change on each new version of data
    // shouldn't re-run on currentAvailAddons change, since that would re-trigger the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, availAddonsPurchases.hash, cartFarmAddons, dispatch],
  )

  /** When avail addons change, fetch any missing matching csas. These csas are part of the availAddons context */
  useCancelableFx(
    async (isFxActive) => {
      const newIds = removeDuplicates(currentResults.flatMap(({ matchingCSAIds }) => matchingCSAIds))
      const currentIds = currentCsas.map(({ id }) => id)
      if (haveSameItems(newIds, currentIds)) return

      const newCsas = await csasCollection.fetchByIds(newIds)
      if (!isFxActive) return

      dispatch(setAvailAddonsCSAs(newCsas))
    },
    [currentResults, currentCsas, dispatch],
  )
}

/** When orders change, will generate the available addons from past purchases and set them */
export function useSetAvailAddonsPurchases() {
  const { id: userId } = useSelector(userSelector)
  const availAddonsPurchases = useSelector(addonsPurchasesSelector)
  const [orders, setOrders] = useState<Order[]>([])
  const dispatch = useDispatch()

  /** Orders listener fx: Listens to orders data for the signed in user.
   * - This can't use useSnapshot because it depends on useIsFocused which requires navigation context. This runs outside the navigation container.
   */
  useEffect(() => {
    /**On signout, the user id will be an empty string. In this case, orders state should be cleared */
    if (!userId) return setOrders([])

    return snapshotOrdersByUserAndDueDate(setOrders, undefined, userId)
  }, [userId])

  /** Gets available addons from past purchases. Only runs if orders hash changed */
  useCancelableFx(
    async (isCurrent) => {
      if (!orders?.length) return

      // This hash is intended to only change if the orders change, thereby limiting the run conditions of this effect
      const hash = generateOrderHash(orders)
      if (hash === availAddonsPurchases.hash) return

      const availAddons = await getAvailAddons_orders(orders)
      if (!isCurrent) return

      dispatch(setAvailAddonsPurchasesAction(availAddons))
    },
    [orders, availAddonsPurchases.hash, dispatch],
  )
}
