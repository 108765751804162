import { uploadImageAsync } from '@api/FirebaseStorage'
import { updateUser } from '@api/Users'
import { AccountHelp, ConsumerScroll } from '@components'
import { ButtonClear, Modal, Text, Toast } from '@elements'
import shareContent from '@helpers/sharing'
import { format, fromJSDate } from '@helpers/time'
import { hasGrownByPrivilege, User, userName, UserRole } from '@models/User'
import { StackScreenProps } from '@react-navigation/stack'
import { SaveFormat } from 'expo-image-manipulator'
import * as Updates from 'expo-updates'
import { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { Logger } from '@/config/logger'
import { useLayout } from '@/hooks/useLayout'
import { withAuth } from '@/hooks/withAuth'
import { auth } from '@api/db'
import { SupportTicketType } from '@models/SupportTicket'
import ImageSelect from '../../components/ImageSelect'
import { Section, SectionView } from '../../components/SectionView'
import { nativeAppVersion, updatesAppVersion } from '../../config/Environment'
import Colors from '../../constants/Colors'
import { UserStackParamList } from '../../navigation/types'
import signout from '../../redux/actions/signout'
import { setUser } from '../../redux/actions/user'
import { RootState } from '../../redux/reducers/types'
import { isAdminSelector, userSelector } from '../../redux/selectors'
import Feedback from './Feedback'
import { PrivacyPolicy } from './PrivacyPolicy'
import { TermsAndConditions } from './TermsAndConditions'

type BaseProps = StackScreenProps<UserStackParamList, 'Profile'>

// Used to format the app update date from utc to local time
const debugDate = (date: Date | null) => (date ? format(fromJSDate(date, 'UTC'), 'MM/dd/yy HH:mm') : 'unknown')

const accountHelpModal = () => Modal(<AccountHelp />, { title: 'Account Help' })
const handleDeleteRequest = () =>
  Modal(<Feedback type={SupportTicketType.DELETE_REQUEST} />, { title: 'Delete Account' })

function UserScreenComp({ navigation }: BaseProps) {
  const user = useSelector<RootState, User>(userSelector)
  const dispatch = useDispatch()
  const isAdmin = useSelector(isAdminSelector)

  const hasPasswordAuth = auth().getProvider() === 'password'

  const [showUpdateId, setShowUpdateId] = useState(false)

  const inviteFriends = async () => {
    await shareContent({
      message: `${userName(
        user,
      )} invited you to GrownBy, a platform to find local food and support farmers. Download here: https://grownby.app/`,
      title: 'Invite to GrownBy',
    })
  }

  const sections: Section[] = [
    accountSection(hasPasswordAuth),
    {
      title: 'Farmers',
      buttons: [
        {
          title:
            isAdmin === undefined ? 'Loading your farmer status...' : isAdmin ? 'Manage your farm' : 'Start Selling',
          subtitle: isAdmin ? 'Click here to go to your farm' : 'Are you a farmer? Start selling your products today',
          onPress: () => {
            if (isAdmin === undefined) return Logger.debug('Still loading farmer status')
            else if (isAdmin) {
              return navigation.navigate('AdminDrawerNavigator')
            } else navigation.navigate('FarmerWalkthroughNavigator')
          },
          disabled: isAdmin === undefined,
        },
      ],
    },
    {
      title: 'Invitations',
      buttons: [
        {
          title: 'Invite Friends',
          onPress: inviteFriends,
        },
      ],
    },
    {
      title: 'Support',
      buttons: [
        {
          title: 'Get Help',
          onPress: accountHelpModal,
        },
        {
          title: 'Provide Feedback',
          onPress: () => Modal(<Feedback />, { title: 'Provide Feedback' }),
        },
      ],
    },
    {
      title: 'Legal',
      buttons: [
        {
          title: 'Terms of Service',
          onPress: () => Modal(<TermsAndConditions />, { title: 'Terms and Conditions' }),
        },
        {
          title: 'Privacy Policy',
          onPress: () => Modal(<PrivacyPolicy />, { title: 'Privacy Policy' }),
        },
      ],
    },
  ]

  const uploadNewImage = useCallback(
    async (image: string) => {
      try {
        const upload = await uploadImageAsync(`${user.id}/avatar`, {
          storageUrl: image,
          type: 'image',
        })
        const newUserData = { ...user }
        newUserData.avatar = upload.storageUrl
        await updateUser(newUserData)
        dispatch(setUser(newUserData))
      } catch (err) {
        Toast('Something went wrong. Please try again in a little while.')
      }
    },
    [dispatch, user],
  )

  const toggleUpdateId = useCallback(() => setShowUpdateId((prev) => !prev), [])

  const { top } = useLayout()

  return (
    <ConsumerScroll contentContainerStyle={{ paddingTop: top }}>
      <View style={styles.optionsWrapper}>
        <View>
          <ImageSelect
            imageType="profile"
            defaultImage={user.avatar}
            editOptions={{
              width: 200,
              format: SaveFormat.JPEG,
            }}
            onChange={uploadNewImage}
            imageStyle={styles.img}
          />
        </View>
        <View style={styles.nameContainer}>
          <Text size={24}>{userName(user)}</Text>
          <Text numberOfLines={1} size={14} color={Colors.shades['400']} testID="user-email">
            {user.email}
          </Text>
        </View>
      </View>
      {sections.map((section, index) => (
        <SectionView key={index} title={section.title} buttons={section.buttons} />
      ))}
      <ButtonClear style={styles.signOut} title="Sign out" onPress={signout} />
      <ButtonClear style={styles.signOut} color={Colors.red} title="Delete Account" onPress={handleDeleteRequest} />
      <Text style={styles.versionText} onPress={toggleUpdateId}>
        {showUpdateId
          ? `${debugDate(Updates.createdAt)} (${updatesAppVersion || 'unknown'})`
          : `Version: ${nativeAppVersion}`}
      </Text>
      {hasGrownByPrivilege(user, UserRole.Admin) && (
        <ButtonClear style={styles.signOut} title="GrownBy Admin" url="/farm-generations/admin" />
      )}
    </ConsumerScroll>
  )
}

export const UserScreen = withAuth(UserScreenComp)

const styles = StyleSheet.create({
  nameContainer: {
    marginLeft: 10,
    flex: 1,
  },
  signOut: {
    alignSelf: 'center',
  },
  versionText: {
    textAlign: 'center',
    color: Colors.semiTransparent,
  },
  img: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  optionsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -10,
  },
})

const accountSection = (hasPasswordAuth: boolean): Section => {
  const section = {
    title: 'Account',
    buttons: [
      {
        title: 'Personal Information',
        url: '/profile/view',
      },
      {
        title: 'Addresses',
        url: '/profile/addresses',
      },
      {
        title: 'Payments',
        url: '/profile/payments',
      },
      {
        title: 'Farm Credits',
        url: '/profile/balances',
      },
      {
        title: 'Notification Settings',
        url: '/profile/notifications',
      },
    ],
  }

  if (!hasPasswordAuth) return section

  return {
    ...section,
    buttons: [
      ...section.buttons,
      {
        title: 'Password & Security',
        url: '/profile/security',
      },
    ],
  }
}
