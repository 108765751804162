import { Coordinate } from '@models/Coordinate'
import { createContext, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { StyleProp, TextInput, TextInputProps, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import type { GeocodeResult } from 'use-places-autocomplete'

import { Logger } from '@/config/logger'
import { isMobile } from '@/constants/Layout'
import { CurrentLocation } from '@/constants/types'
import { AutoCompleteItem, useAutoComplete } from '@/hooks/useAutoComplete'
import useDeepCompareEffect from '@/hooks/useDeepEqualEffect'
import { searchLocationSelector, userLocationSelector } from '@/redux/selectors'

interface PlaceHolderOpts {
  placeholderArg?: string
  hasCurrentLocation?: boolean
  userLoc?: CurrentLocation
  searchLoc?: CurrentLocation
}
/** Standardized way to get the same placeholder in mobile and web versions of this component */
export function getPlaceholderPlacesSearch({
  placeholderArg,
  hasCurrentLocation,
  userLoc,
  searchLoc,
}: PlaceHolderOpts) {
  return placeholderArg || (hasCurrentLocation ? userLoc?.city || 'Current Location' : searchLoc?.city)
}

export interface Establishment {
  coordinate: Coordinate
  address_components?: GeocodeResult['address_components']
  /** The establishment name will be the city when searching for cities */
  name?: string
}

export const debouncedTimeGooglePlaces = 300

export const processTextGooglePlacesResult = (text: string) => text.replace(', USA', '')

/** These are the custom props we're adding to our component, to be combined with the TextInputProps */
export type GooglePlacesSearchCustomProps<DetailsType extends object> = {
  /** callback for externally handling the item selected */
  onSelect?: (item: AutoCompleteItem<DetailsType>) => void | Promise<void>
  /** This 'types' is borrowed from react-native-google-autocomplete's DefaultProps' queryTypes (The mobile library, which has better types). But it can't be imported directly because this type must be usable for both web and mobile. */
  types?: 'address' | 'geocode' | '(cities)' | 'establishment' | 'geocode|establishment'
  /** Will try to display the current location as placeholder if available */
  placeholderCurrLoc?: boolean
  /** If true, this component will not inherit any initial values that may be inferred from previous searches elsewhere in the app */
  noInitialValue?: boolean
  /** If true the list view will be inline with the page */
  inline?: boolean
  /** Right-sided button inside the input that will clear the input on press */
  hasClearBtn?: boolean
  /** Style applied to the view container which wraps the TextInput and the "close" icon */
  contStyle?: StyleProp<ViewStyle>
}

/** Props for the external api of this component.
 * The DetailsType will vary by the mobile and web api from different libraries.
 */
export type GooglePlacesSearchProps<DetailsType extends object> = Omit<TextInputProps, 'value'> &
  GooglePlacesSearchCustomProps<DetailsType>

export const googlePlacesSearchAutocompleteKey = 'googlePlacesSearch'

/** Input for data hook */
type UseGooglePlacesSearchDataInput<ResultType extends object, DetailType extends object> = {
  placeholder?: string
  searchResults?: ResultType[]
  setValue: (v: string) => void
  /** getResultDetails is supposed to be a fn that converts the result type into a detail type. In all google places search 3rd party libraries, there's a way to do that by passing a result item into a helper provided by the same library, which returns the detail item with an async request. This detail item is the one we pass to the final onSelect prop */
  getResultDetails: (itm: AutoCompleteItem<ResultType>) => Promise<AutoCompleteItem<DetailType>>
  inputValue: string
  /** isSearching comes from the google api */
  isSearching: boolean
} & Pick<GooglePlacesSearchCustomProps<DetailType>, 'onSelect' | 'inline' | 'placeholderCurrLoc' | 'noInitialValue'>

/** Output for data hook */
export type GooglePlacesSearchData<ResultType extends { description: string }> = {
  items: AutoCompleteItem<ResultType>[]
  placeholder?: TextInputProps['placeholder']
  hideInlineResults: boolean
  inputRef: RefObject<TextInput>
  onSubmit: TextInputProps['onSubmitEditing']
  inlineOnPressItem: (item: ResultType) => void
  onFocus: TextInputProps['onFocus']
  onChangeText: TextInputProps['onChangeText']
  onTouchStart: TextInputProps['onTouchStart']
  onClearText: () => void
  autoCompleteOverlay: JSX.Element | null
}

/** This hook provides the common data to the web and mobile components for the google places autotomplete, ensuring their behavior will remain on par going forward */
export function useGooglePlacesSearchData<ResultType extends { description: string }, DetailType extends object>({
  placeholder: placeholderArg,
  placeholderCurrLoc = false,
  noInitialValue = false,
  inline = false,
  searchResults = [],
  setValue,
  onSelect: onSelectProp,
  getResultDetails,
  inputValue,
  isSearching,
}: UseGooglePlacesSearchDataInput<ResultType, DetailType>): GooglePlacesSearchData<ResultType> {
  const userLoc = useSelector(userLocationSelector)
  const searchLoc = useSelector(searchLocationSelector)
  const inputRef = useRef<TextInput>(null)
  /** Whether to show/hide the inline results */
  const [hideInlineResults, setHideInlineResults] = useState(!inline)
  const { autoCompleteOverlay, showAutocomplete, updateAutocomplete, hideAutocomplete, state: ac } = useAutoComplete()
  const [items, setItems] = useState<AutoCompleteItem<ResultType>[]>([])

  const placeholder = getPlaceholderPlacesSearch({
    placeholderArg,
    hasCurrentLocation: placeholderCurrLoc,
    searchLoc,
    userLoc,
  })

  const itemsTimeout = useRef<NodeJS.Timeout | null>(null)

  /** Generates autotomplete items from location results */
  useEffect(() => {
    if (itemsTimeout.current) clearTimeout(itemsTimeout.current)
    if (searchResults.length)
      setItems(searchResults.map((item) => ({ text: processTextGooglePlacesResult(item.description), data: item })))
    /** If no results after searching done, and the input has a value, show the "No results" item after the debounce time. */ else if (
      inputValue?.length > 3 &&
      !isSearching
    ) {
      itemsTimeout.current = setTimeout(() => {
        setItems([{ text: 'No results', data: { description: '' } as ResultType }])
      }, debouncedTimeGooglePlaces)
    } else setItems([])
  }, [searchResults, inputValue, isSearching])

  /** Show or update autocomplete UI when results change.
   * This must EITHER show results inline, or dispatch showAutoComplete
   */
  useDeepCompareEffect(() => {
    /** only show results UI if the input is currently focused.
     * This is required to prevent the items from showing again after an item is selected. On selection, the items should hide in both inline and overlay mode. and they should remain hidden */
    if (!inputRef.current?.isFocused()) return

    if (inline) {
      setHideInlineResults(false)
    } else {
      ac?.value
        ? updateAutocomplete(inputRef, items)
        : showAutocomplete(googlePlacesSearchAutocompleteKey, inputRef, items, onSelectItem, { matchWidth: true })
    }
    //Only meant to run on items change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  /** Handles selecting an autocomplete item.
   * If there's an onSelect prop, will get the details on the result item, and pass it to the onSelect prop, which expects the details. */
  const onSelectItem = useCallback(
    async (item: AutoCompleteItem<ResultType>) => {
      setValue(item.text)

      if (onSelectProp) {
        try {
          const detailItem = await getResultDetails(item)
          onSelectProp(detailItem)
        } catch (error) {
          Logger.error('Error: ', error)
        }
      }
    },
    [setValue, onSelectProp, getResultDetails],
  )

  const inlineOnPressItem = useCallback(
    (item: ResultType) => {
      setHideInlineResults(true)
      onSelectItem?.({ text: processTextGooglePlacesResult(item.description), data: item })
    },
    [setHideInlineResults, onSelectItem],
  )

  const onSubmit: TextInputProps['onSubmitEditing'] = useCallback(() => {
    /** On press enter, hide results for both inline or autocomplete modes */
    if (inline) setHideInlineResults(true)
    else hideAutocomplete()

    /** If we press enter, select the first result */
    if (items[0]) {
      onSelectItem?.(items[0])
    }
  }, [hideAutocomplete, setHideInlineResults, onSelectItem, inline, items])

  const onFocus: TextInputProps['onFocus'] = useCallback(() => {
    /** On focus, show results for line and autocomplete modes */
    if (inline) setHideInlineResults(false)
    else showAutocomplete(googlePlacesSearchAutocompleteKey, inputRef, items, onSelectItem, { matchWidth: true })
  }, [inline, showAutocomplete, items, onSelectItem])

  const displayResults = useCallback(() => {
    if (inline && hideInlineResults) setHideInlineResults(false)
    else if (ac?.value !== googlePlacesSearchAutocompleteKey) {
      /** This will ensure if the autocomplete modal is currently not shown, it will appear again. (Most important on mobile, where the modal gets hidden if you tap outside it) */
      showAutocomplete(googlePlacesSearchAutocompleteKey, inputRef, items, onSelectItem, { matchWidth: true })
    }
  }, [inline, hideInlineResults, setHideInlineResults, showAutocomplete, items, onSelectItem, ac?.value])

  const onChangeText: TextInputProps['onChangeText'] = useCallback(
    (text: string) => {
      setValue(text)
      displayResults()
    },
    [setValue, displayResults],
  )

  /** This is helpful when the autocomplete has hidden and then the user presses the textInput area. Will make the dropdown reappear */
  const onTouchStart: TextInputProps['onTouchStart'] = useCallback(() => {
    /** This should display the results on mobile. However this has a bad behavior on web, so if not mobile it should return */
    if (!isMobile) return
    displayResults()
  }, [displayResults])

  const onClearText = useCallback(() => setValue(''), [setValue])

  /** If a search location is set, it will become the input value unless specifically instructed not to via the noInitialValue prop */
  useEffect(() => {
    if (!noInitialValue && searchLoc?.city && searchLoc?.city !== inputValue) {
      setValue(searchLoc.city)
    }
    // This must specifically ignore the inputValue as dep. Otherwise the input may become stuck
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLoc?.city])

  return {
    autoCompleteOverlay,
    items,
    placeholder,
    hideInlineResults,
    inputRef,
    onSubmit,
    inlineOnPressItem,
    onFocus,
    onChangeText,
    onTouchStart,
    onClearText,
  }
}

export type GooglePlacesSearchContextType = {
  textInputProps: TextInputProps
  customProps: GooglePlacesSearchCustomProps<any>
}

export const GooglePlacesSearchContext = createContext<GooglePlacesSearchContextType>(
  {} as GooglePlacesSearchContextType,
)
