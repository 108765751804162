/**
 * This can be used by any shared module, and can be imported into any file of frontend and backend.
 * - Any code here must not fail in any platform
 * - tsconfig requirement: lib: ["DOM"]. Else, will type error on `typeof window`.
 */

//Borrowed from https://github.com/flexdinesh/browser-or-node/blob/master/src/index.js

export const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined'

export const isNode = typeof process !== 'undefined' && process.versions != null && process.versions.node != null

//https://stackoverflow.com/questions/39468022/how-do-i-know-if-my-code-is-running-as-react-native
//https://github.com/facebook/react-native/issues/1331
export const isReactNative = typeof navigator !== 'undefined' && navigator.product === 'ReactNative'

/** Dependency-less helper to tell if code is running in frontend (web or mobile) */
export const isClient = isBrowser || isReactNative

/**
 * process.env.NODE_ENV is the only variable that's always available in react-native. In addition, jest forcibly sets NODE_ENV === 'test', but not cypress.
 */
export const isJest =
  typeof process !== 'undefined' && typeof process.env !== 'undefined' && process.env.NODE_ENV === 'test'

const platform = {
  isBrowser,
  isNode,
  isReactNative,
  isClient,
  isJest,
}
// console.log('platform:', platform)

export default platform
