import { ToolTips } from '@components'
import { Button, fontSize, HeaderText, Tooltip, typography } from '@elements'
import { formatMiniDate, formatMoney, formatTime, getOrderNum } from '@helpers/display'
import { openOrdersCSV, OrderFilters } from '@helpers/links'
import { AlgoliaAdminDoc, AlgoliaAdminOrder, AlgoliaDocType } from '@models/Algolia'
import { Farm } from '@models/Farm'
import { dateTimeInZone } from '@models/Timezone'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'
import { StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-native'
import { Platform, View } from 'react-native'
import { useSelector } from 'react-redux'

import { AdminTable } from '@/admin/components/AdminTable/AdminTable'
import { defaultRefinementLimit } from '@/admin/components/AdminTable/ConnectedRefinementDropdown'
import { AdminView } from '@/admin/components/AdminView'
import Export from '@/admin/components/Export'
import { AdminOrdersParamList } from '@/admin/navigation/types'
import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { withAdminIndex } from '@/hooks/withAlgoliaIndex'
import { RootState } from '@/redux/reducers/types'
import { adminFarmSelector } from '@/redux/selectors'
import { AccessRight, Permission } from '@helpers/Permission'
import { globalStyles } from '../../../constants/Styles'

function OrdersScreen() {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const navigation = useNavigation<StackNavigationProp<AdminOrdersParamList>>()
  const hasAccessCreateOrder = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)

  const [csaFilterShowMore, setCsaFilterShowMore] = useState(false)
  const [prodFilterShowMore, setProdFilterShowMore] = useState(false)
  const [locFilterShowMore, setLocFilterShowMore] = useState(false)
  const [scheduleFilterShowMore, setScheduleFilterShowMore] = useState(false)

  const { params } = useRoute<RouteProp<AdminOrdersParamList, 'AdminOrderList'>>()

  /** This function should be called after defaultCsaRefinement (if exists) is applied every time. */
  const onDefaultCsaRefinementApplied = useCallback(() => {
    // resets csaName parameter
    navigation.setParams({ csaName: undefined })
  }, [navigation])

  const styles = useStyles()

  return (
    <AdminView>
      <View style={styles.wrapper}>
        <View>
          <View style={globalStyles.flexRowCenter}>
            <HeaderText size={30}>Orders</HeaderText>
            <Tooltip size={15} title="Orders" id={ToolTips.ORDERS} />
          </View>
          <View style={styles.buttonContainer}>
            <ExportCSV />
            {hasAccessCreateOrder && (
              <Button
                small
                style={styles.createOrderButton}
                title="New Order"
                onPress={() => navigation.navigate('CreateOrder')}
              />
            )}
          </View>
        </View>
        <AdminTable<AlgoliaAdminOrder>
          type={AlgoliaDocType.ORDER}
          dateRange={{
            timezone: farm.timezone,
            attribute: 'date',
          }}
          columns={[
            {
              title: 'Order Number',
              key: 'orderNum',
              itemPressUrl: (ord) => `/admin/orders/view/${ord.id}`,
              process: (ord) => getOrderNum(ord.orderNum),
            },
            {
              title: 'Name',
              // @ts-expect-error, key here should be 'user.name' but just 'name' is used
              key: 'name',
              itemPressUrl: (ord) => `/admin/customers/${ord.user.id}`,
              process: (ord) => ord.user.name,
            },
            {
              title: 'Email',
              // @ts-expect-error, key here should be 'user.email' but just 'email' is used
              key: 'email',
              size: 2,
              itemPressUrl: (ord) => `/admin/customers/${ord.user.id}`,
              process: (ord) => ord.user.email,
            },
            {
              title: 'Order Date',
              key: 'date',
              process: (ord) => formatMiniDate(DateTime.fromMillis(ord.date).setZone(farm.timezone)),
            },
            {
              title: 'Order Time',
              // @ts-expect-error, key here should be 'date', but here we format date to only show time, so key is 'time' and 'date' is used already on another column.
              key: 'time',
              process: (ord) => formatTime(DateTime.fromMillis(ord.date).setZone(farm.timezone)),
            },
            {
              title: 'Total',
              key: 'total',
              process: (ord) => formatMoney(ord.total || 0),
            },
            {
              title: 'Status',
              key: 'status',
              process: (ord) => ord.status,
            },
          ]}
          refinements={[
            {
              attribute: 'csas.name',
              placeholder: 'All CSAs',
              limit: defaultRefinementLimit,
              showMoreLimit: defaultRefinementLimit * 3,
              setShowMore: setCsaFilterShowMore,
              showMore: csaFilterShowMore,
              defaultRefinement: params?.csaName,
              onDefaultRefinementApplied: onDefaultCsaRefinementApplied,
            },
            {
              attribute: 'products.name',
              placeholder: 'All Products',
              limit: defaultRefinementLimit,
              showMoreLimit: defaultRefinementLimit * 3,
              setShowMore: setProdFilterShowMore,
              showMore: prodFilterShowMore,
            },
            {
              attribute: 'locations.name',
              placeholder: 'All Locations',
              limit: defaultRefinementLimit,
              showMoreLimit: defaultRefinementLimit * 3,
              setShowMore: setLocFilterShowMore,
              showMore: locFilterShowMore,
            },
            {
              attribute: 'distributions.name',
              placeholder: 'All Schedules',
              limit: defaultRefinementLimit,
              showMoreLimit: defaultRefinementLimit * 3,
              setShowMore: setScheduleFilterShowMore,
              showMore: scheduleFilterShowMore,
            },
          ]}
          expandRefinementsInitial={params?.csaName ? true : undefined}
          searchPlaceholder="Order Number or Customer"
          searchBarExtendStyle={styles.searchBarExtendStyle}
        />
      </View>
    </AdminView>
  )
}
export default withAdminAuth(
  withAdminIndex(OrdersScreen, AlgoliaDocType.ORDER) as React.ComponentType,
  Permission.Orders,
)

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    wrapper: {
      marginHorizontal: isSmallDevice ? 10 : 30,
      marginTop: isSmallDevice ? 10 : 30,
    },
    createOrderButton: {
      marginHorizontal: 10,
      paddingHorizontal: 20,
    },
    receiptHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: -10,
      marginBottom: 10,
    },
    receipt: {
      borderWidth: 1,
      borderColor: Colors.shades['200'],
      borderRadius: 10,
      padding: 30,
      margin: 10,
    },
    buttonContainer: {
      marginVertical: 10,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    datePicker: {
      borderWidth: 2,
      backgroundColor: Platform.OS === 'web' ? Colors.black : Colors.white,
      width: Platform.OS === 'web' ? 40 : 100,
    },
    divText: {
      fontSize: fontSize(15, 2),
      padding: 5,
      fontWeight: 'bold',
      textAlign: 'center',
      color: Colors.black,
      fontFamily: typography.body.regular,
    },
    searchBarExtendStyle: {
      paddingLeft: 0,
    },
  }))

const ExportCSV = connectStateResults(({ searchState }: StateResultsProvided<AlgoliaAdminOrder & AlgoliaAdminDoc>) => {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  //default range is last 3 months
  const now = dateTimeInZone(farm.timezone)
  const defaultMinDate = now.minus({ months: 3 }).startOf('day')
  const defaultMaxDate = now
  let searchQuery: string | undefined
  const filters: OrderFilters = {
    csaName: searchState.menu?.['csas.name'],
    productName: searchState.menu?.['products.name'],
    locationName: searchState.menu?.['locations.name'],
    distributionName: searchState.menu?.['distributions.name'],
    dateRange: {
      min: searchState.range?.date.min ?? defaultMinDate.toMillis(),
      max: searchState.range?.date.max ?? defaultMaxDate.toMillis(),
    },
  }

  if (searchState.query) {
    searchQuery = searchState.query
  }

  return (
    <Export
      title="Export to CSV"
      onPress={() => {
        openOrdersCSV(farm.id, filters, searchQuery)
      }}
    />
  )
})
