import { CSA } from '@models/CSA'
import { Distribution } from '@models/Distribution'
import { Farm } from '@models/Farm'
import { Features } from '@models/Features'
import { Invoice } from '@models/Invoice'
import { Location } from '@models/Location'
import { Order } from '@models/Order'
import { Product } from '@models/Product'
import { User } from '@models/User'
import { DateTime } from 'luxon'

export type AdminNavParams = {
  customer?: User
  location?: Location
  distribution?: Distribution
  product?: Product
}

/** Comprehensive data about a farm, which is saved in a client-side redux cache */
export type FarmCachedData = {
  farm?: Farm
  /** The relevant products of the current farm in nav props */
  prods?: Product[]
  /** The relevant csas of the current farm */
  csas?: CSA[]
  /** Locations of a farm's products */
  locs?: Location[]
  /** Time the cache for this farm was last modified */
  time: DateTime
  /** Whether the loading of data for this farm was completed before the screen last navigated. If not true, the new screen should restart the loading because there may be more products to load */
  loadingCompleted: boolean
}

export const emptyFarmCachedData = (): FarmCachedData => ({
  farm: undefined,
  time: DateTime.now(),
  csas: undefined,
  locs: undefined,
  prods: undefined,
  loadingCompleted: false,
})

/** Data in this context should be used whenever possible in the screens that receive navigation params, instead of fetching this data every time, on screen load. */
export type NavParams = {
  /** Store of farm data fetched during app navigation */
  farmCache: {
    [farmSlug: Farm['urlSafeSlug']]: FarmCachedData
  }
  /** this should be the most recent farm for which the user opened a consumer screen, which loaded the farm data */
  farm?: Farm
  csa?: CSA
  order?: Order
  invoice?: Invoice
  /** Data for any use-case that requires details about a current product; For example the product detail screen */
  product?: Product
  /** The features available to the current farm in consumer screens.
   * - If a farm exists in navProps, this will represent the feature state for that farm if there's any feature specific to the farm.
   * - If a feature doesn't have a farm-specific setting, it will have the global feature state.
   * - If no farm is present, it will always have the global feature state */
  featuresAvailable?: Record<Features, boolean>
}
