import { ProductFields } from '@helpers/builders/buildProduct'
import { Distribution, DistributionConstraint } from '@models/Distribution'
import { PaymentSchedule, Product, Share } from '@models/Product'

//Generic product error. WIP. Should use these all across the produt validator
type CodesArgs = {
  InvalidFrequency: { dist?: Distribution; constraint?: DistributionConstraint }
  MissingPickups: { dist?: Distribution; numberPickups?: Share['numberPickups']; distPickups?: number }
  NeedsEndDate: { dist?: Distribution }
  InvalidDateRangeConstraint: { dist?: Distribution; constraint?: DistributionConstraint }
  InvalidSeason: { dist?: Distribution }
  InvalidConstraintStartDate: { dist?: Distribution; constraint?: DistributionConstraint }
  InvalidConstraintEndDate: { dist?: Distribution; constraint?: DistributionConstraint }
  LastPaymentDateTooEarly: { dist?: Distribution; frequency?: PaymentSchedule['frequency'] }
}

export type ProdErrCodes = keyof CodesArgs

type ErrOptBase<Code extends string, V extends Record<any, any> | undefined = undefined> = V extends undefined
  ? { code: Code; prod?: Partial<Product> | ProductFields }
  : { code: Code; prod?: Partial<Product> | ProductFields } & V

export type ErrOpt<Code extends ProdErrCodes = ProdErrCodes> = ErrOptBase<Code, CodesArgs[Code]>

type ErrOpts =
  | ErrOpt<'InvalidFrequency'>
  | ErrOpt<'MissingPickups'>
  | ErrOpt<'NeedsEndDate'>
  | ErrOpt<'InvalidSeason'>
  | ErrOpt<'InvalidDateRangeConstraint'>
  | ErrOpt<'InvalidConstraintStartDate'>
  | ErrOpt<'InvalidConstraintEndDate'>
  | ErrOpt<'LastPaymentDateTooEarly'>

export type ErrMsgMap = { [K in ProdErrCodes]: (args: CodesArgs[K]) => string }
const errMsgMap: ErrMsgMap = {
  InvalidFrequency: ({ dist }) =>
    `Frequency constraint must be narrower.${dist?.name ? ` Schedule: "${dist.name}".` : ''}`,
  MissingPickups: ({ dist, numberPickups, distPickups }) =>
    `Cannot accomodate the number of pickups required for the share, given the date constraints.${
      dist?.name ? ` Schedule: "${dist.name}".` : ''
    } ${numberPickups !== undefined ? `Required pickups: ${numberPickups}.` : ''} ${
      distPickups !== undefined ? `Pickups possible: ${distPickups}.` : ''
    }`,
  NeedsEndDate: ({ dist }) =>
    `A constraint for a year-round schedule needs an end date.${dist?.name ? ` Schedule: "${dist.name}"` : ''}`,
  InvalidDateRangeConstraint: ({ dist }) =>
    `The availability start date must be before the availability end date.${
      dist?.name ? ` Schedule: "${dist.name}"` : ''
    }`,
  InvalidSeason: ({ dist }) =>
    `A schedule season must have a start date before the end date.${dist?.name ? ` Schedule: "${dist.name}"` : ''}`,
  InvalidConstraintStartDate: ({ dist }) =>
    `The availability start date cannot be before the schedule start date.${
      dist?.name ? ` Schedule: "${dist.name}"` : ''
    }`,
  InvalidConstraintEndDate: ({ dist }) =>
    `The availability end date cannot be after the schedule end date.${dist?.name ? ` Schedule: "${dist.name}"` : ''}`,
  LastPaymentDateTooEarly: ({ dist, frequency }) =>
    `The last payment date must be after (or on) the first pickup date.${
      frequency ? ` See billing option: "${frequency}"` : ''
    }${dist?.name ? ` Conflicting schedule: "${dist.name}"` : ''}`,
}

/** An error specific to the product model.
 * - The error.name will have the error code, as well as the data.code.
 * - The data type will include additional data related to the error, dependent on the error code. Use typescript narrowing to see which data is available for each error code.
 * - Error messages may be customized with an optional error message map.
 * - The default message map is meant to be technically accurate for debugging purposes, albeit not intended to be friendly to the end-user. If errors are meant to be shown to the user, a custom message map may be provided.
 */
export class ProductError extends Error {
  data: ErrOpts

  constructor({ code, ...opts }: ErrOpts, getMsg: ErrMsgMap = errMsgMap) {
    super(getMsg[code](opts))
    this.name = code
    this.data = { code, ...opts }

    Object.setPrototypeOf(this, ProductError.prototype)
  }
}

export const isProdErr = (e: unknown): e is ProductError => e instanceof ProductError

export const getProdErrCode = (e: unknown): ProdErrCodes | undefined => (isProdErr(e) ? e.data.code : undefined)

/** A list of product error codes that can arise from the combination of a product and a schedule. This is meant to be only a subset of error codes */
export const prodDistroErrCodes: ProdErrCodes[] = [
  'InvalidConstraintEndDate',
  'InvalidConstraintStartDate',
  'InvalidDateRangeConstraint',
  'InvalidFrequency',
  'InvalidSeason',
  'MissingPickups',
  'NeedsEndDate',
]
