import { AutoCompleteInput, AutoCompleteInputProps } from '@elements'
import { AlgoliaAdminCustomer, AlgoliaDocType } from '@models/Algolia'
import connectAutocomplete, {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'
import { createRef, memo, RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { useConfigure, useConnector } from 'react-instantsearch'
import { Hit } from 'react-instantsearch-core'
import { TextInput } from 'react-native'
import { useSelector } from 'react-redux'

import { useDeepCompareMemo } from '../../hooks/useDeepEqualEffect'
import { withAdminIndexHooks } from '../../hooks/withAlgoliaIndex'
import { adminFarmSelector } from '../../redux/selectors'

import { useDebouncedValue } from '../../hooks/useDebounce'
import { useSizeFnStyles } from '@/hooks/useFnStyles'

export type AdminUserAutocompleteProps = {
  onSelect: (user: Hit<AlgoliaAdminCustomer>) => void
  inputRef?: RefObject<TextInput>
  inline?: AutoCompleteInputProps<any>['inline']
}

function AdminUserAutoCompleteComp({
  onSelect: onSelectProp,
  inputRef: inputRefProp,
  inline = false,
}: AdminUserAutocompleteProps) {
  const farm = useSelector(adminFarmSelector)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState(false)
  /** This inputRef should not have a default value defined on prop destructuring, as is normally done with props objects or the ref might misbehave. The inputRefProp?.current should be in the deps, so that on each re-render, the inputRef used will be either the prop if defined, or will be created if the prop is nullish*/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputRef = useMemo(() => inputRefProp ?? createRef<TextInput>(), [inputRefProp?.current])

  const debouncedSearchTerm = useDebouncedValue(searchTerm)

  useConfigure({
    facetFilters: [`farmId:${farm.id}`, `docType:${AlgoliaDocType.CUSTOMER}`],
    hitsPerPage: 5,
  })

  const { indices, refine, currentRefinement } = useConnector<
    AutocompleteConnectorParams,
    AutocompleteWidgetDescription
  >(connectAutocomplete)

  const items = useDeepCompareMemo(() => {
    //This `unknown` is necessary because the default `Hit` type from algolia hooks is coercive and incompatible with the `Hit` type from react-instantsearch-core
    const hits = (indices?.[0]?.hits ?? []) as unknown as Hit<AlgoliaAdminCustomer>[]
    return hits.map((user) => ({ text: user.name + ' | ' + user.email, data: user }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indices[0]?.hits])

  /** Refines search with the most recent searchTerm.  */
  useEffect(() => {
    refine(debouncedSearchTerm) //If empty string, it will clear the search
  }, [debouncedSearchTerm])

  /** Disables loader when currentRefinement reflects new searchTerm */
  useEffect(() => {
    if (loading && (debouncedSearchTerm === currentRefinement || !debouncedSearchTerm)) setLoading(false)
    // Only needs to re-run on change to debouncedSearchTerm and currentRefinement
  }, [currentRefinement, debouncedSearchTerm])

  const onChangeText = useCallback<NonNullable<AutoCompleteInputProps<Hit<AlgoliaAdminCustomer>>['onChangeText']>>(
    (t) => {
      setLoading(true)
      setSearchTerm(t)
    },
    [],
  )

  const onSelect = useCallback<NonNullable<AutoCompleteInputProps<Hit<AlgoliaAdminCustomer>>['onSelect']>>(
    (itm) => {
      setSearchTerm('')
      onSelectProp(itm.data)
    },
    [onSelectProp],
  )

  const clear = useCallback(() => setSearchTerm(''), [])

  const styles = useStyles()

  return (
    <AutoCompleteInput
      inline={inline}
      contStyle={styles.inputContainer}
      style={styles.input}
      inputRef={inputRef}
      value={searchTerm}
      onChangeText={onChangeText}
      items={items}
      onSelect={onSelect}
      autoCompleteId="AdminUserAutoComplete"
      options={{ matchWidth: true }}
      placeholder="Search for customers"
      loading={loading}
      clear={searchTerm ? clear : undefined}
    />
  )
}

export const AdminUserAutoComplete = memo(withAdminIndexHooks(AdminUserAutoCompleteComp))

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isMedDevice }) => ({
    inputContainer: {
      marginVertical: isLargeDevice ? 15 : isMedDevice ? 10 : 5,
    },
    input: { padding: 10 },
  }))
