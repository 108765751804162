import { Toast, TouchableProps } from '@elements'
import { CSA } from '@models/CSA'
import { Product } from '@models/Product'
import { NavigationAction, NavigationProp, StackActions, useNavigation } from '@react-navigation/native'
import { useCallback, useEffect, useMemo } from 'react'
import { ViewStyle } from 'react-native'
import { useDispatch } from 'react-redux'

import { addNavProp } from '../../redux/actions/appState'
import { AddCartBtnProps, makeTestIdPrefix as makeKeyAddCartBtn } from '../AddCartBtn'
import { ImageCardProps } from '../ImageCard'

import { isMobile, isWeb } from '@/constants/Layout'
import { useSnapshot } from '@/hooks/useApiFx'
import { useControlledState } from '@/hooks/useControlledState'
import { csasCollection, productsCollection } from '@api/framework/ClientCollections'
import { nonEmptyString } from '@helpers/helpers'
import { getCardPrice } from '@helpers/products'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, isGeoDoc } from '@models/Algolia'
import { getCardUrl, validateCardProps } from './useProductCardData-helpers'

export type ProductCardProps = {
  /** This style goes to the ImageCard and is passed to the main container Touchable*/
  style?: ViewStyle
  /** If a product id is passed, it will use a snapshot listener. Otherwise it will use the provided product data */
  product: string | Product | AlgoliaGeoDoc<AlgoliaGeoProduct>
  /** Applies "small" styling settings in this component, adjusting the card width, aspect ratio and text size */
  small?: ImageCardProps['small']
  /** Determines whether the card will show a `AddCartBtn` or a "View CSA" button. If "csa" is selected, you must also provide a `csa` prop */
  cardAction: 'addcart' | 'csa'
  /** `csa` will be passed to the `AddCartBtn` or "View CSA" button. Required if the `cardAction` prop receives the value "csa" */
  csa?: CSA
  /** Handler called when the main card area is pressed. Intended for side-effects */
  onPress?: (p: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>) => any
  /** Handler called only on mobile main area press, intended to have a navigate call, assuming no url will be passed to the ImageCard. Value will be memoized with no updates, to allow for inline fn */
  onPressMobileNavigate: (p: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>) => void
  /** Handler called when the `cardAction` button is pressed. (Either "addcart" or "csa")*/
  onActionPress?: (p: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>) => any
  /** Navigation handler called when the 'csa' card action is pressed, intended to have a navigate call, assuming no url will be passed to the csa button. We intentionally do not use urls for this button because it creates an invalid html link due to <a> becoming a descendant of <a>.
   * @param csa If a csa was provided to the card, this will be the csa. If no csa was provided, the card will fetch the product csas and attempt to find a non-hidden one and pass it here. This is necessary when using the product card in places like the HomeScreen where we don't have the csas pre-loaded, and we don't want to guess by automatically choosing the first id (product.csa[0]), since it might be hidden.
   */
  onPressCsaNavigate?: (p: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>, csa?: CSA) => void
  /** If provided, the add-to-cart button will add the product with this unit only. Else it will open the UnitSelection for products with multiple units. */
  unit?: AddCartBtnProps['unit']
  /** If provided, the url will include the '?goBack=home' portion which will return to the Homescreen on back press */
  goBack?: 'home'
} & Omit<TouchableProps, 'onPress' | 'style' | 'url' | 'navAction' | 'children'>

/** This hook should be as optimal as possible, for the best performance of large FlatLists in consumer screens */
export const useProductCardData = (props: ProductCardProps) => {
  const {
    style,
    product: productProp,
    small,
    cardAction,
    csa: csaProp,
    onPress,
    onPressMobileNavigate,
    onActionPress,
    onPressCsaNavigate,
    unit,
    goBack,
    ...touchableProps
  } = props

  const [loading, [enableLoading, disableLoading]] = useControlledState(false, [true, false])
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<any>>()

  /** If an id is provided, it listens to the snapshot from db. Else, it just uses the provided type as the data. */
  const {
    data: product,
    loading: loadingProd,
    error: errorLoadingProd,
  } = useSnapshot<[typeof productProp], Product | AlgoliaGeoDoc<AlgoliaGeoProduct>>(
    (cb, onErr, productData) => {
      if (nonEmptyString(productData)) {
        return productsCollection.snapshotDoc(productData, cb, onErr)
      } else if (productData) {
        cb(productData)
        return () => {}
      } else {
        onErr(new Error('The product card should not receive an empty string as product'))
        return () => {}
      }
    },
    [productProp],
  )

  const farmSlug = useMemo(
    // Gets the farmSlug from the product data, handling its various possible types
    () =>
      nonEmptyString(productProp)
        ? loadingProd || errorLoadingProd
          ? undefined
          : product?.farm.urlSafeSlug
        : productProp.farm.urlSafeSlug,
    [productProp, loadingProd, errorLoadingProd, product],
  )

  /** When the main card is pressed:
   * - Runs side effects (setting nav prop, generic onPress)
   * - Calls mobile navigate call if using a mobile device. This is necessary because url won't be used in mobile
   */
  const onPressCard = useCallback(() => {
    if (!product || loadingProd) return Toast('The product data is loading')
    if (!isGeoDoc(product)) dispatch(addNavProp({ product })) //This saves the need to load on product detail screen
    onPress?.(product) //Side effects

    if (isMobile) {
      //Mobile only navigation, because url is intentionally being used only in web
      onPressMobileNavigate(product)
    }
  }, [dispatch, onPress, onPressMobileNavigate, loadingProd, product])

  const testId = useMemo(() => (!product ? undefined : makeKeyAddCartBtn(product, unit)), [product, unit])

  /** Handles pressing the 'View CSA' action button inside the card. */
  const onCSAPress = useCallback(
    async () => {
      if (!onPressCsaNavigate) throw new Error('Missing navigate function for View CSA press')

      if (!product || loadingProd) return Toast('The product data is loading')
      enableLoading()
      let csa = (csaProp ? { ...csaProp } : undefined) as CSA | undefined
      if (!csa && product.csa) {
        // If no csa was passed to the card props, we must fetch the product csa data to ensure we're not navigating to a hidden or private csa
        const prodCsas = await csasCollection.fetchByIds(product.csa)
        csa = prodCsas.find((csa) => !csa.isHidden && !csa.isPrivate)
      }
      dispatch(addNavProp({ csa }))

      onActionPress?.(product) //Side effect
      onPressCsaNavigate(product, csa) //"View CSA" navigation to CSADetail screen (no urls allowed for "View CSA" button because it creates an invalid html error due to nested anchor tag)
      disableLoading()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, csaProp, onActionPress, onPressCsaNavigate, loadingProd, product],
  )

  /** Only use url navigation in web. For navigation in mobile we will use the onPressMobileNavigate prop, so as to force mobile to use navigate() instead, because it's faster on mobile.
   * - If 'csa' prop is not provided, do not try to guess the csa id because it might be hidden. Let the product details screen figure out which csa to use.
   */
  const url = useMemo(
    () => getCardUrl(product, farmSlug, csaProp?.id, goBack),
    [csaProp?.id, farmSlug, product, goBack],
  )

  /** When the product card is pressed in web, navigation is done via the url. In that case, this navigation action will customize the navigate call inside useLinkProps.
   * - This is only defined in web, because only on web we use url navigation.
   * - If a csa is not provided, don't try to guess a csa id with prod.csa[0]. It might be hidden and this wouldn't be able to check for that. Let the product details screen figure out which csa id to use
   */
  const urlPushAction: NavigationAction | undefined = useMemo(() => {
    /** Required for proper push navigation actions. It's an array of the valid route names of the current navigator */
    const validRoutes = navigation.getState().routeNames

    return isWeb && product?.id && validRoutes.includes('ProductDetails')
      ? StackActions.push('ProductDetails', { farmSlug, csaId: csaProp?.id, productId: product.id })
      : undefined
  }, [navigation, csaProp?.id, farmSlug, product?.id])

  const cardText = useMemo(() => getCardPrice(product), [product])

  useEffect(() => {
    // Validates props during development
    validateCardProps(props)
    // only meant to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    urlPushAction,
    onPressCard,
    testId,
    onCSAPress,
    url,
    touchableProps,
    style,
    small,
    cardAction,
    product,
    loading: loadingProd || loading,
    errorLoadingProd,
    onActionPress,
    csa: csaProp,
    unit,
    cardText,
  }
}
