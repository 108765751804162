import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { createElement } from 'react-native-web'
import { WebViewProps } from 'react-native-webview'
import { WebViewSourceUri } from 'react-native-webview/lib/WebViewTypes'

import { AppStackParamList } from '../../../navigation/types'
import { Button } from '../Button'

export default function WebView(
  props: WebViewProps & { source: WebViewSourceUri; iframe?: boolean; onClose?: () => void },
) {
  if (props.iframe) return <WebViewPopup {...props} />
  return <WebViewRedirect source={props.source.uri} />
}

function WebViewRedirect({ source }: { source: string }) {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  // On web will open url in new window
  const loadPage = () => {
    window.open(source, '_blank')
    if (navigation.canGoBack()) navigation.goBack()
    else navigation.navigate('Consumer')
  }
  useEffect(() => {
    return navigation.addListener('focus', loadPage)
  }, [navigation])
  return <Button title="Open Link in new Tab" onPress={() => loadPage} />
}

function WebViewPopup(props: WebViewProps & { source: WebViewSourceUri; onClose?: () => void }) {
  useEffect(() => {
    window.addEventListener(
      'message',
      (ev) => {
        if (ev.data === 'close-iframe') props.onClose && props.onClose()
      },
      false,
    )
    return () => {
      window.removeEventListener('message', () => {})
    }
  }, [])

  return createElement('iframe', {
    style: StyleSheet.flatten({ borderRadius: 10 }),
    src: props.source.uri,
    height: 500,
    width: 600,
    frameBorder: '0',
    seamless: true,
    onLoad: props.onLoad,
  })
}
