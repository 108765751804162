import { getPickupsCacheAddtoCartFlow } from '@/hooks/useCart/addToCartFlow/helpers'
import { isTruthy, removeDuplicates } from '@helpers/helpers'
import { getLocationKind, isCompatibleLocation } from '@helpers/location'
import { getOrderDeadlineFromPickup } from '@helpers/order'
import { sortByConstraint, sortByEarliest, sortByName, sortDistrosByLocationAndName } from '@helpers/sorting'
import { LocationTypes, isNonPickupDistLocation } from '@models/Location'
import { PhysicalProduct, isPhysical } from '@models/Product'
import { UserAddress } from '@models/UserAddress'
import { DistroExtended } from '@screens/Shopping/PickupLocation'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'

export type UsePickupLocationDataProps = {
  product: PhysicalProduct
  /**
   * - If passed, this function will filter distros to those having the specified type.
   * - If locationType is undefined, show all locationTypes.
   */
  locationType?: LocationTypes
  /** The user specified address for determining valid shipping and delivery options.
   * This is only used in the addToCart flow and not in the ProductDetails screen */
  address?: UserAddress
  /** Admin mode allows for greater privileges. Should be true when using this component in the order creator. */
  isAdmin?: boolean
}

/** Groups a product's schedules by location, and filters them by a given location type.
 *  @returns an array of arrays of distros, grouped by location. Each distro has extended attributes 'firstPickup' and 'pickups'
 * */
export const usePickupLocationData = ({ product, address, locationType, isAdmin }: UsePickupLocationDataProps) => {
  const [loading, setLoading] = useState(true)
  const [distrosByLoc, setDistrosByLoc] = useState<DistroExtended[][]>()

  useEffect(() => {
    if (!isPhysical(product)) return
    setLoading(true)
    const distros = product.distributions.filter((d) => !d.isHidden && (isAdmin ? true : !d.closed))
    if (!distros.length) return setDistrosByLoc([])

    const distroMap: { [key: string]: DistroExtended[] } = {} //keys are location ids. values are an array distros on that location, with extended attributes 'firstPickup' and 'pickups'
    distros.forEach((dist) => {
      /** If locationType is provided, filter out if locationType isn't of the same kind */
      if (locationType && getLocationKind(dist.location.type) !== getLocationKind(locationType)) {
        return
      }

      /** If the locationType is a NonPickup location type, and there's no address yet, we want to initially hide the checkboxes until there is an address, and we also should not show schedules as disabled yet until there's an address selected. When the user selects an address, we may then mark incompatible addresses as disabled */
      const hideCheckbox = locationType && getLocationKind(locationType) !== 'localPickup' && !address

      /** Check whether the distro is compatible for adding to cart. We don't filter out if it's incompatible. We only need to disable selection if they have not entered a compatible address. In the meantime, the benefit is they can continue seeing the options and their regions */
      const disableOpt = !!address && !isCompatibleLocation(dist.location, locationType, address)

      const pickups = getPickupsCacheAddtoCartFlow(dist, product, {
        excludeHiddenDistros: true,
        excludeClosedDistros: !isAdmin,
        ignoreOrderCutoffWindow: isAdmin,
        ignoreDisableBuyInFuture: isAdmin,
      })
      if (!pickups[0]) return

      if (!distroMap[dist.location.id]) distroMap[dist.location.id] = []
      const orderDeadline = getOrderDeadlineFromPickup(
        pickups[0],
        isAdmin ? 0 : dist.orderCutoffWindow,
        dist.schedule.hours.endTime,
        dist.location.timezone,
      )
      distroMap[dist.location.id].push({
        ...dist,
        firstPickup: pickups[0],
        pickups,
        disable: disableOpt,
        hideCheckbox,
        orderDeadline,
      })
    })
    const distroGroups = Object.values(distroMap)
      .sort((a, b) => sortByName(a[0].location, b[0].location))
      .sort((a, b) =>
        sortByEarliest('firstPickup')(
          { firstPickup: DateTime.min(...a.map((sch) => sch.firstPickup)) },
          { firstPickup: DateTime.min(...b.map((sch) => sch.firstPickup)) },
        ),
      )
      .sort(sortByConstraint((group) => group.some((s) => !s.disable)))
      // FIXME: This sorting sequence is starting to become fragmented. The last portion which includes sortdistrosByLocationAndName had been added inline in JSX in a recent commit, which was inappropriate. This is the correct place to put all sorting logic for schedules. sorting should never go inline in JSX because it will re-run on every render. Also shouldn't add new sorting logic instead of modifyig the existing logic.
      // Must revisit whether the earlier sorts are still required and whether the latter ones are overridding them.
      .sort((a, b) => sortDistrosByLocationAndName(a[0], b[0]))

    setDistrosByLoc(distroGroups)
    setLoading(false)
  }, [product, locationType, address, isAdmin])

  /** When the locationType is nonPickup, this should get the regions of the unique locations from all location groups, filtered by the locationType. This represents the regions where the product is available for the given location type */
  const regions = useMemo(() => {
    if (!distrosByLoc) return undefined
    return removeDuplicates(
      distrosByLoc
        .map((group) => group[0].location)
        .filter(isNonPickupDistLocation)
        .filter((loc) => (!locationType ? false : getLocationKind(locationType) === getLocationKind(loc)))
        .flatMap((loc) => loc.regions)
        .filter(isTruthy) ?? [],
    )
  }, [distrosByLoc, locationType])

  return { distrosByLoc, regions, loading }
}
