import { createStackNavigator } from '@react-navigation/stack'
import { PaymentsScreen } from '@screens/UserScreen/PaymentsScreen'

import { isWeb } from '@/constants/Layout'
import { SecurityScreen } from '@screens/UserScreen/SecurityScreen/SecurityScreen'
import { AddressScreen } from '../screens/UserScreen/AddressScreen'
import { BalanceScreen } from '../screens/UserScreen/BalanceScreen'
import { NotificationScreen } from '../screens/UserScreen/NotificationScreen'
import { PersonalInformationScreen } from '../screens/UserScreen/PersonalInformationScreen'
import { UserScreen } from '../screens/UserScreen/UserScreen'
import { UserStackParamList } from './types'

const UserScreenStack = createStackNavigator<UserStackParamList>()

function UserNavigator() {
  return (
    <UserScreenStack.Navigator screenOptions={{ headerShown: !isWeb }}>
      <UserScreenStack.Screen options={{ headerShown: false }} name="Profile" component={UserScreen} />

      <UserScreenStack.Screen
        name="PersonalInformation"
        component={PersonalInformationScreen}
        options={{
          title: 'Personal Information',
        }}
      />
      <UserScreenStack.Screen
        name="BalanceScreen"
        component={BalanceScreen}
        options={{
          title: 'My Farm Credits',
        }}
      />
      <UserScreenStack.Screen
        name="AddressScreen"
        component={AddressScreen}
        options={{
          title: 'My Addresses',
        }}
      />
      <UserScreenStack.Screen
        name="PaymentsScreen"
        component={PaymentsScreen}
        options={{
          title: 'My Payment Methods',
        }}
      />
      <UserScreenStack.Screen
        name="Notifications"
        component={NotificationScreen}
        options={{
          title: 'Notification Settings',
        }}
      />
      <UserScreenStack.Screen
        name="Security"
        component={SecurityScreen}
        options={{
          title: 'Password & Security',
        }}
      />
    </UserScreenStack.Navigator>
  )
}

export default UserNavigator
