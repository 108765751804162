import { ToolTips } from '@components'
import { Button, TextH1, Tooltip } from '@elements'
import { StyleSheet, View } from 'react-native'

import { AdminDrawerParamList } from '@/admin/navigation/types'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { Permission } from '@helpers/Permission'
import { StackScreenProps } from '@react-navigation/stack'
import { useLayoutEffect, useMemo } from 'react'
import { AdminView } from '../../components/AdminView'
import { TabSelector } from './components/TabSelector'
import { getVisibleTabs } from './helper'
import { MembersTab } from './tabs/MembersTab'
import { MembershipsTab } from './tabs/MembershipsTab'
import { PaymentsTab } from './tabs/PaymentsTab/PaymentsTab'
import { usePaymentsData } from './tabs/PaymentsTab/PaymentsTab.controller'
import { PayoutsTab } from './tabs/PayoutsTab'
import { ProductFeesTab } from './tabs/ProductFeesTab/ProductFeesTab'

type Props = StackScreenProps<AdminDrawerParamList, 'FarmSetup'>

function AdminFarmSetupScreenComp({ navigation, route }: Props) {
  const tab = route?.params?.tab

  const hasAccountingPermission = useHasPermissionWithFlag(Permission.Accounting) ?? false
  const hasProductSetupPermission = useHasPermissionWithFlag(Permission.ProductSetup) ?? false
  const hasSetupPermission = useHasPermissionWithFlag(Permission.AccountSetup) ?? false

  const visibleTabs = useMemo(
    () => getVisibleTabs({ hasProductSetupPermission, hasAccountingPermission, hasSetupPermission }),
    [hasAccountingPermission, hasProductSetupPermission, hasSetupPermission],
  )

  /** Data layer needed for the PaymentsTab. It is placed here because the save button must be at the screen level (sticky) */
  const paymentData = usePaymentsData()

  /** Redirects to the correct page if needed, based on the current permissions */
  useLayoutEffect(() => {
    if (!visibleTabs.length) return

    if (!tab) {
      return navigation.setParams({ tab: visibleTabs[0] })
    }

    if (!visibleTabs.includes(tab)) {
      navigation.setParams({ tab: visibleTabs[0] })
    }
  }, [navigation, tab, visibleTabs])

  // Custom sticky header for the screen
  const CustomHeader = useMemo(() => {
    return (
      <View>
        <View style={styles.headerContainer}>
          <TextH1>
            Account Settings <Tooltip title="Account & Setup" id={ToolTips.FARM_INFO} size={15} />
          </TextH1>
          {tab === 'payments' && paymentData.formik.dirty && (
            <Button loading={paymentData.loading} title="Save" onPress={() => paymentData.formik.handleSubmit()} />
          )}
        </View>
        {/* Show tab selector only if multiple tabs are visible */}
        {visibleTabs.length > 1 && (
          <TabSelector
            selectedTab={tab}
            visibleTabs={visibleTabs}
            onPress={(tab) => {
              navigation.setParams({ tab })
            }}
          />
        )}
      </View>
    )
  }, [navigation, paymentData.formik, paymentData.loading, tab, visibleTabs])

  return (
    <AdminView customHeader={CustomHeader}>
      <View style={styles.tabWrapper}>
        {tab === 'payments' && <PaymentsTab {...paymentData} />}

        <PayoutsTab isVisible={tab === 'payouts'} />
        <MembershipsTab isVisible={tab === 'memberships'} />
        <MembersTab isVisible={tab === 'members'} />
        <ProductFeesTab isVisible={tab === 'productFees'} />
      </View>
    </AdminView>
  )
}

export const AdminFarmSetupScreen = withAdminAuth(AdminFarmSetupScreenComp)

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 10,
    height: 60,
    alignItems: 'center',
  },
  tabWrapper: {
    width: '95%',
    alignSelf: 'center',
    maxWidth: 1500,
  },
})
