import * as Application from 'expo-application'
import Constants from 'expo-constants'
import { FirebaseOptions } from 'firebase/app'

import { ClientEnv } from '../constants/ClientEnvType'

/**
 * Environment variables defined in app.config.extra, plus NODE_ENV.
 *
 * `NODE_ENV` is the only variable provided by default in react-native as `process.env.NODE_ENV`, and can't be managed by us, so it's only useful for the `isJest` constant. Nevertheless it is included here for completeness.
 * This should be the central point for accessing the environment in the frontend. (This 'env' is client only)
 */
export const env = {
  ...(Constants.expoConfig?.extra ?? {}),
  NODE_ENV: process.env.NODE_ENV,
} as ClientEnv

export const isEmul = env.IS_EMULATOR === true

/** `isCypress` will only be true when code runs in the cypress browser, but not inside the app running in the cypress viewport. So: true for anything that gets imported into a cypress test like helpers, but false for the same helpers inside the app being tested. */
export const isCypress = env.IS_CYPRESS === true

export const releaseChannel = env.APP_ENV

/** nativeAppVersion is the version installed through the app store and tracks any native/ dependency changes.
 *  For web the native version is the most recent update(expoConfig.version) as all its changes are pushed OTA.
 */
export const nativeAppVersion = Application.nativeApplicationVersion || Constants.expoConfig?.version || '0'
/** updatesAppVersion is the version of the app installed through EAS updates. This tracks all JS code changes. */
export const updatesAppVersion = Constants.expoConfig?.version || '0'

export function getRunConfiguration(): FirebaseOptions {
  return {
    apiKey: env.API_KEY,
    authDomain: env.AUTH_DOMAIN,
    databaseURL: env.DATABASE_URL,
    projectId: env.PROJECT_ID,
    storageBucket: env.STORAGE_BUCKET,
    messagingSenderId: env.MESSAGING_SENDER_ID,
    appId: env.APP_ID,
  }
}

/** The base url for the app environment, will use localhost for dev */
export const grownbyWebsiteBaseUrl = () => {
  return __DEV__
    ? 'http://localhost:19006/'
    : releaseChannel === 'prod'
    ? 'https://grownby.app/'
    : releaseChannel === 'staging'
    ? 'https://grownby-staging.web.app/'
    : releaseChannel === 'beta'
    ? 'https://grownby-beta.web.app/'
    : 'https://grownby-dev.web.app/'
}
/** The base url for the app environment, using all deployed versions and never localhost */
export const grownbyWebsiteLiveUrl = () => {
  return releaseChannel === 'prod'
    ? 'https://grownby.app/'
    : releaseChannel === 'staging'
    ? 'https://grownby-staging.web.app/'
    : releaseChannel === 'beta'
    ? 'https://grownby-beta.web.app/'
    : 'https://grownby-dev.web.app/'
}

export default env
