import { createContext, useCallback, useMemo } from 'react'
import { SplitTenderPaymentItemBase } from '@models/Order'
import { PaymentOptionsContextType, SplitTenderErrors } from './helpers/types'
import { validateOptions, validateSplitTenderWithOptions } from './helpers/validation'
import { configureDefaultTender, listPaymentsWithFilters } from './helpers/helpers'
import { addUpdateSplitTender, adjustSplitTenderWithOptions } from './helpers/splitTender'
import { dequal } from 'dequal'
import { Alert } from '@elements'
import { errorToString } from '@helpers/helpers'
import { Logger } from '../../../config/logger'
import { useApiFx } from '../../../hooks/useApiFx'
import { loadFarmBalance } from '@api/Payments'
import useKeyedState from '../../../hooks/useKeyedState'
import { User } from '@models/User'
import { pick } from '@helpers/typescript'
import { useFocusFx } from '@/hooks/useFocusFx'
import { isEbtPayment, PaymentMethod } from '@models/PaymentMethod'

type ContextData = Pick<PaymentOptionsContextType, 'isValid' | 'validationError' | 'splitTender' | 'options'>

const getInitialContextData = (options: PaymentOptionsContextType['options']) => ({
  isValid: false,
  validationError: undefined,
  splitTender: [],
  options,
})

/** This hook handles all the logic for updating and storing the context values */
export const usePaymentOptionsData = (
  userId: User['id'],
  /** When the options change in the external component they are passed into here for validation*/
  options: PaymentOptionsContextType['options'],
  /** This unique ID will determine if we should reset the payment configuration. Each place that implements PaymentSelector
   * can determine the appropriate uniqueID */
  uniqueId: string,
  onSplitTenderUpdated: PaymentOptionsContextType['onSplitTenderUpdated'],
): PaymentOptionsContextType => {
  const [contextData, set, setState] = useKeyedState<ContextData>(getInitialContextData(options))

  const userFarmBalance = useApiFx(loadFarmBalance, [userId, options.farm.id])
  const userPaymentMethods = useApiFx(listPaymentsWithFilters, [userId, pick(options, 'farm', 'allowOfflinePayments')])

  /** This function will be called whenever the user selects a new payment method and will update the splitTender */
  const updateSplitTender = useCallback(
    (newTender: SplitTenderPaymentItemBase) => {
      const updatedSplitTender = addUpdateSplitTender(newTender, contextData.splitTender, contextData.options)
      set('splitTender', updatedSplitTender)
    },
    [contextData.splitTender, contextData.options, set],
  )

  /** This function handles updating the local state when we add a new payment method */
  const onAddNewPaymentMethod = useCallback(
    (newPmt: PaymentMethod) => {
      // When a new payment method is added we will locally mutate the state so that it has the new payment method
      userPaymentMethods.setState((prev) => ({ ...prev, data: [...(prev.data || []), newPmt] }))

      // If we are adding an EBT payment method then we should set the amount to the max EBT amount, otherwise undefined so
      // that it is treated as infinite
      updateSplitTender({ paymentMethod: newPmt, amount: isEbtPayment(newPmt) ? options.amountEbt : undefined })
    },
    [userPaymentMethods.setState, options.amountEbt, updateSplitTender],
  )

  /** This will update the options whenever they change */
  useFocusFx(() => {
    // We only want to run this effect when the options have changed, this prevents it triggering itself when the split tender is updated
    if (dequal(options, contextData.options)) return

    // Validate that the options provided are correct
    try {
      validateOptions(options)
    } catch (e) {
      // Options should never be invalid, and if they are we want to mark invalid and show an error to the user
      set('isValid', false)
      set('validationError', SplitTenderErrors.OPTIONS_INVALID)

      Logger.error(e)
      return Alert(
        'Invalid payment options',
        `The provided payment selection options are invalid, please contact support: ${errorToString(e)}`,
      )
    }

    // Update the options
    set('options', options)

    // If there is no previous options or splitTender doesn't exist then we don't need to adjust the split tender
    if (contextData.splitTender.length === 0) return

    // We attempt to adjust the split tender with the new options
    const updatedTender = adjustSplitTenderWithOptions(contextData.splitTender, contextData.options, options)
    set('splitTender', updatedTender)
  }, [contextData.options, options, set, contextData.splitTender])

  /** This will reset the context data when the farm user or uniqueId changes */
  useFocusFx(() => {
    setState(getInitialContextData(options))
    // We don't want this to update when options change, only when the farm user or uniqueId changes
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, uniqueId, options.farm.id, setState])

  /** This will notify the outer scope anytime the payment selection becomes invalid */
  useFocusFx(() => {
    if (!contextData.isValid) {
      onSplitTenderUpdated(undefined)
    }
  }, [contextData.isValid, onSplitTenderUpdated])

  /** This is where we select default payment methods for the invoice */
  useFocusFx(() => {
    // We only want to run this once all data has been loaded, and it should not be run if split tender has already been set
    if (!userPaymentMethods.loading && !userFarmBalance.loading && contextData.splitTender.length === 0) {
      const initialTender = configureDefaultTender(userFarmBalance, userPaymentMethods, contextData.options)
      set('splitTender', initialTender)
    }
    // We do not want to update when splitTender changes only when the data is loaded initially
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPaymentMethods, userFarmBalance, contextData.options, set])

  /** This effect will run whenever splitTender or options change to ensure that the isValid marker is always up-to-date */
  useFocusFx(() => {
    // Check if the new tender is valid and if not set the validation error
    const validationError = validateSplitTenderWithOptions(contextData.splitTender, contextData.options)

    // update the validation error and isValid state
    set('validationError', validationError)
    set('isValid', !validationError)

    // We should call the callback with the new split tender or undefined if it is not valid
    if (!validationError) {
      onSplitTenderUpdated(contextData.splitTender)
    } else {
      onSplitTenderUpdated(undefined)
    }
  }, [contextData.splitTender, contextData.options, onSplitTenderUpdated, set])

  return useMemo(() => {
    return {
      ...contextData,
      userId,
      updateSplitTender,
      userFarmBalance: userFarmBalance.data,
      userPaymentMethods: userPaymentMethods.data,
      refreshPaymentMethods: userPaymentMethods.refresh,
      isLoading: userFarmBalance.loading || userPaymentMethods.loading,
      onSplitTenderUpdated,
      onAddNewPaymentMethod,
    }
  }, [
    contextData,
    userId,
    updateSplitTender,
    userFarmBalance,
    userPaymentMethods,
    onSplitTenderUpdated,
    onAddNewPaymentMethod,
  ])
}

export const PaymentOptionsContext = createContext<PaymentOptionsContextType>({} as PaymentOptionsContextType)
