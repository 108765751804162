import { useMemo, useState } from 'react'

/** Allows you to use state with a pre-defined group of setters, which can change your state to values specified in advance.
 * - Setters will never update
 */
export function useControlledState<State>(initial: State, states: State[]): [State, (() => void)[]] {
  const [state, setState] = useState(initial)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setters = useMemo(() => states.map((state) => () => setState(state)), [])
  return [state, setters]
}
