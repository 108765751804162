import { MoneyCalc } from '@helpers/money'
import { PartialPick, RequireAtLeastOne } from '@helpers/typescript'
import { BankPaymentMethod, CardPaymentMethod, EbtPaymentMethod } from '@models/PaymentMethod'
import { DateTime } from 'luxon'

import { Discount } from './Coupon'
import { Farm } from './Farm'
import { Location } from './Location'
import { Money, Zero } from './Money'
import { CartItem, Order } from './Order'
import { Payout } from './Payout'
import { Product } from './Product'
import { ProductFee } from './ProductFee'
import { dateTimeInZone } from './Timezone'
import { User } from './User'

type InvoiceManualOrder = {
  id: 'balance_transaction' | 'manual_invoice'
  orderNum: undefined
}
type InvoiceDefaultOrder = Pick<Order, 'id' | 'orderNum'>

export enum InvoiceNextStep {
  STRIPE_3D_SECURE = 'stripe-3D-secure',
  PENDING_BANK_AUTHORIZATION = 'pending-bank-authorization',
}

// An Invoice represents and invoice recorded in the system.
export type Invoice<Type extends 'manual' | 'default' | 'unknown' = 'unknown'> = {
  // The firebase invoice ID
  id: string

  // Invoice number
  invoiceNum: number

  // The date the invoice is due
  dueDate: DateTime

  // Will be the status of the invoice useful for querying
  status: InvoiceStatus

  // The amount the user has already paid should equal amountTotal when the invoice is fully paid
  amountPaid?: Money

  // The total amount for the invoice
  amountTotal: Money

  // Payments methods and amounts as well as refunds
  payments: RequireAtLeastOne<Record<PaymentSources, InvoicePayment>>

  // If there is a coupon applied to this invoice then it should be added here, note that this is a snapshot at the time
  // of purchase and should not be relied on for current data
  couponApplied?: Discount

  // A list of items to display on the invoice
  items: InvoiceItem[]

  // User id for querying based on user
  user: Pick<User, 'id' | 'name' | 'email'>

  // Farm id for querying
  farm: Pick<Farm, 'id' | 'name' | 'timezone'>

  // Order associated with the invoice.
  // The order ID may be set to 'manual_invoice' when an invoice is created without an associated order. Readers utilizing this
  // value should take care to not treat it as a document reference.
  order: Type extends 'manual'
    ? InvoiceManualOrder
    : Type extends 'default'
    ? InvoiceDefaultOrder
    : InvoiceManualOrder | InvoiceDefaultOrder

  // The pdf receipt
  // TODO: pdf should no longer be used as we can have payUrl link to the receipt on paid
  pdf?: string

  // A url for the user to pay the invoice
  payUrl?: string

  // The date the invoice was last paid either partially or fully
  datePaid?: DateTime

  // The date the invoice was voided
  dateVoided?: DateTime

  // An internal note that can be added by the farmer
  note?: string

  // The stripe payout
  stripePayout?: {
    id: string
    arrivalDate: DateTime
  }

  // The worldpay payout
  worldpayPayout?: {
    id: string
    arrivalDate: DateTime
  }

  // The Co-op fee (2% on credit cards only for stripe)
  coopFee?: Money

  // Stripe credit card fee
  stripeCCFee?: Money

  // Stripe ACH fee
  stripeACHFee?: Money

  // Next step is an object containing the next step required for an incomplete invoice
  nextStep?:
    | {
        type: InvoiceNextStep.STRIPE_3D_SECURE
        url: string
      }
    | {
        type: InvoiceNextStep.PENDING_BANK_AUTHORIZATION
      }
}

export enum PaymentSources {
  STRIPE = 'stripe',
  STRIPE_ACH = 'stripe_ach',
  // This allows us to accept both invoices and payments from Stripe as we begin to transition away from invoices
  // This source is now deprecated and should not be used, we only leave it for now to support reporting for Stripe Invoices
  STRIPE_INVOICE = 'stripe_invoice',
  WORLD_PAY_EBT = 'worldpay_ebt',
  //Below two will be used in refund or orderCancel When we need to distinguish ebt payment between ebt cash and ebt snap during refund
  WORLD_PAY_EBT_CASH = 'worldpay_ebtcash',
  WORLD_PAY_EBT_SNAP = 'worldpay_ebtsnap',
  OFFLINE = 'offline',
  FARM_CREDIT = 'farmcredit',
}

export type InvoicePaymentMethod<Source extends PaymentSources> = Source extends PaymentSources.WORLD_PAY_EBT
  ? Pick<EbtPaymentMethod, 'id' | 'token' | 'last4' | 'card_type'>
  : Source extends PaymentSources.STRIPE
  ? Pick<CardPaymentMethod, 'id' | 'token' | 'last4' | 'card_type'>
  : Source extends PaymentSources.STRIPE_ACH
  ? Pick<BankPaymentMethod, 'id' | 'token' | 'last4' | 'bank_name'>
  : Source extends PaymentSources.OFFLINE
  ? Pick<BankPaymentMethod, 'id' | 'token'>
  : Source extends PaymentSources.FARM_CREDIT
  ? Pick<BankPaymentMethod, 'id' | 'token'>
  : // Be explicit about what types we expect so that we get errors to help when adding new payment methods
    never

type ProcessorOptions<Source extends PaymentSources> = Source extends PaymentSources.STRIPE | PaymentSources.STRIPE_ACH
  ? {
      /** Charge type determines how the charge should be made in Stripe, either directly through the connect account,
       or destination which goes first to the platform and is then routed to the connect account */
      chargeType: 'direct' | 'destination'
    }
  : Source extends PaymentSources.WORLD_PAY_EBT
  ? {
      /** This field is required when refunding to certain EBT networks like Conduent. It is optional because not all networks use this */
      specialProgramCaseKey?: string
    }
  : never

export type InvoicePayment<Source extends PaymentSources = PaymentSources> = {
  source: Source
  /** A payment ref to either worldpay or stripe payment */
  paymentRef?: string

  /** The payment method to charge to for cash and ebt */
  paymentMethod: InvoicePaymentMethod<Source> & {
    ebtRemainingBalance?: Money
    ebtCashRemainingBalance?: Money
    farmCreditRemainingBalance?: Money
  }

  /** The total amount paid by this payment method */
  totalPaid: Money

  /** The total amount that has been refunded */
  refundedAmount?: Money
  refunds?: Refund[]

  /** This object holds any processor specific options or settings that need to be stored on the invoice */
  processorOptions?: ProcessorOptions<Source>
}

export type Refund = {
  refundRef: string
  refundPayout?: Pick<Payout, 'id' | 'arrivalDate'>
  amount: Money
  date: DateTime
  note?: string
}

export enum InvoiceItemTypes {
  ADJUSTMENT = 'adjustment',
  DELIVERY_FEE = 'delivery_fee',
  SHIPPING_FEE = 'shipping_fee',
  TIP = 'tip',
}

/** Product taxes or fees are stored as tax_ or fee_ with the product fee id*/
export type InvoiceProductFeeId = `${'tax' | 'fee'}_${ProductFee['id']}`

/** An InvoiceItem represents the line items of an invoice. */
export type InvoiceItem = {
  id: CartItem['id'] | InvoiceItemTypes | InvoiceProductFeeId

  /** Will be true if the product is either EBT-eligible or EBT-only.
   * Only relevant for standard products (default undefined for non-standard products) */
  isEbtEligible?: boolean
  payments: {
    // The payment source the item was paid with or should be paid with, used to lookup in invoice.payments
    source: PaymentSources

    /** The below amounts are per quantity, so they can be represented as decimals and should be multiplied by quantity to get the total.**/

    // This amount represents a total for this item and payment source, this amount already has the discount removed from it.
    amount: Money

    // This amount is the discount for the item, this discount is already subtracted from the amount.
    discount?: Money

    /** refundedAmount is used to track productFees refundAmounts. (Only when invoice invoiceItem is a productFee, then we will track refund amount.) */
    refundedAmount?: Money
  }[]
  // The date this item was cancelled
  cancelled?: DateTime
  //TODO: currently this is used in ProductFees and Deliver/Shipping fees only, but will potentially be used in other invoice items later in the future. (We used to mark tips as refunded, but now we don't.)
  isRefunded?: boolean
  distId?: string
  quantity: number
  description: string
  /** If any product taxes or fees were applied to this invoice item they should be shown here. */
  appliedProductFees?: ProductFee[]
  /**
   * 1. Product adds additional data to the invoice item that allows more customization over the invoice description as well as more refined handling for discounts.
   * 2. In terms of when it will exist that would be when an invoice item is for a product as opposed to a fee item. And also invoice item created before 3.6 will not have product
   */
  product?: Pick<Product, 'id' | 'name' | 'category' | 'producer' | 'description'>
  /** The location holds information about how the item will be received. This is required for EBT payments and also may
   * be helpful in the future for fraud prevention (by comparing distance between billing address and shipping address.)
   * Invoice items before 3.6 will not have this information */
  location?: Pick<Location, 'id' | 'type' | 'address'>
}

export type InvoiceTemp = {
  dueDate: DateTime
  item: InvoiceItem
}

/** invoiceTotal returns the total due on the invoice.*/
export function invoiceTotal(invoice: PartialPick<Invoice, 'items'>): Money {
  let total = Zero
  const activeItems = invoice.items.filter(({ cancelled }) => !cancelled)
  for (const item of activeItems) {
    total = MoneyCalc.add(total, invoiceItemTotal(item))
  }
  return MoneyCalc.round(total)
}

/** the invoice subtotal which excludes all isFeeItems and discounts. */
export function invoiceSubtotal(invoice: PartialPick<Invoice, 'items'>): Money {
  let total = Zero
  const activeItems = invoice.items
    .filter(({ cancelled }) => !cancelled)
    // Remove fees from this total calculation
    .filter((i) => !isFeeItem(i.id))
  for (const item of activeItems) {
    total = MoneyCalc.add(total, invoiceItemSubtotal(item))
  }
  return MoneyCalc.round(total)
}

/** Will return the amount of tips that user paid. */
export function getInvoiceTips(invoice: PartialPick<Invoice, 'items'>): Money {
  const item = invoice.items.find((itm) => itm.id === 'tip')
  if (item) return invoiceItemTotal(item)
  else return Zero
}

/** Will return the amount of discounts applied on an invoice */
export function getInvoiceDiscounts(invoice: PartialPick<Invoice, 'items'>): Money {
  let total = Zero
  const activeItems = invoice.items.filter(({ cancelled }) => !cancelled)
  for (const item of activeItems) {
    total = MoneyCalc.add(total, getInvoiceItemDiscount(item))
  }
  return total
}

/** Will return the amount of discounts applied on an specific invoice source */
export function getInvoiceSourceDiscounts(invoice: PartialPick<Invoice, 'items'>, source: PaymentSources): Money {
  let total = Zero
  const activeItems = invoice.items.filter(({ cancelled }) => !cancelled)

  for (const item of activeItems) {
    total = MoneyCalc.add(total, getProcessorItemDiscount(item, source))
  }
  return total
}

/** Returns the ebt eligible subtotal of the invoice */
export function invoiceEbtEligibleAmount(invoice: PartialPick<Invoice, 'items'>): Money {
  let total = Zero
  const activeItems = invoice.items.filter(({ cancelled }) => !cancelled)
  for (const item of activeItems) {
    if (!item.isEbtEligible) continue
    total = MoneyCalc.add(total, invoiceItemTotal(item))
  }
  return total
}

/** The invoice item total will be the total of the invoice after discounts have been applied */
export function invoiceItemTotal(item: InvoiceItem) {
  let total = Zero
  for (const payment of item.payments) {
    total = MoneyCalc.add(total, MoneyCalc.multiply(payment.amount, item.quantity))
  }
  return total
}

/** The invoice item subtotal will be the invoice item amount before discounts have been applied */
export function invoiceItemSubtotal(item: InvoiceItem) {
  let total = Zero
  for (const payment of item.payments) {
    total = MoneyCalc.add(
      total,
      MoneyCalc.multiply(MoneyCalc.add(payment.amount, payment.discount ?? Zero), item.quantity),
    )
  }
  return total
}
/** The amount an item is discounted by */
export function getInvoiceItemDiscount(item: InvoiceItem) {
  let total = Zero
  for (const payment of item.payments) {
    total = MoneyCalc.add(total, MoneyCalc.multiply(payment.discount ?? Zero, item.quantity))
  }
  return total
}

/** getProcessorItemTotal returns the total amount of the item after discount has been applied by a processor for an item. */
export function getProcessorItemTotal(item: InvoiceItem, source: PaymentSources): Money {
  const payment = item.payments.find((itm) => itm.source === source)
  if (payment) {
    return MoneyCalc.multiply(payment.amount, item.quantity)
  } else {
    return Zero
  }
}

/** getProcessorItemSubtotal returns the total amount of the item before discount has been applied by a processor for an item. */
export function getProcessorItemSubtotal(item: InvoiceItem, source: PaymentSources): Money {
  const payment = item.payments.find((itm) => itm.source === source)
  if (payment) {
    return MoneyCalc.multiply(MoneyCalc.add(payment.amount, payment.discount ?? Zero), item.quantity)
  } else {
    return Zero
  }
}

/** getProcessorItemDiscount returns the amount of discount for a processor for an item. */
export function getProcessorItemDiscount(item: InvoiceItem, source: PaymentSources): Money {
  const payment = item.payments.find((itm) => itm.source === source)
  if (payment) {
    return MoneyCalc.multiply(payment.discount ?? Zero, item.quantity)
  } else {
    return Zero
  }
}

/** Will return true if any payment has been made on this invoice */
export function isPartiallyPaid(invoice: Invoice) {
  return MoneyCalc.isGTZero(totalPaid(invoice))
}

/** isPaid returns true if an invoice is settled. */
export function isPaid(invoice: Invoice): boolean {
  return MoneyCalc.isGTE(totalPaid(invoice), invoice.amountTotal)
}

/** Will return if the invoice is due */
export function isDue(invoice: Invoice): boolean {
  const now = dateTimeInZone(invoice.farm.timezone)
  // If the invoice is void, refunded or paid then it is not due
  if (
    invoice.status === InvoiceStatus.Void ||
    invoice.status === InvoiceStatus.Refunded ||
    invoice.status === InvoiceStatus.Paid
  ) {
    return false
  }
  return invoice.dueDate < now
}

/** isUnPaid returns true if the invoice is actually unpaid. */
export const isUnPaid = (invoice: Invoice) =>
  invoice.status === InvoiceStatus.Due ||
  invoice.status === InvoiceStatus.Failed ||
  invoice.status === InvoiceStatus.Incomplete

/** totalPaid returns the amount paid towards the invoice. */
export function totalPaid(invoice: Invoice): Money {
  return invoice.amountPaid || Zero
}

/** amountDue returns the amount left to be paid on the invoice */
export function amountDue(invoice: Invoice): Money {
  return MoneyCalc.subtract(invoice.amountTotal, invoice.amountPaid || Zero)
}

/** if there is any refund on the invoice */
export function isRefunded(invoice: Invoice): boolean {
  const payments = Object.values(invoice.payments)
  return payments.filter((val) => val.refundedAmount && val.refundedAmount.value > 0).length > 0
}

/**  The amount that has been refunded or Zero for none */
export function invoiceRefundAmount(invoice: Invoice): Money {
  const payments = Object.values(invoice.payments)
  return payments.map((val) => val.refundedAmount || Zero).reduce((a, b) => MoneyCalc.add(a, b))
}

/**
 *  If there was any payment made offline for the invoice
 * @param invoice the invoice to run against
 * @param full if true will change the function to only say it is offline if there are no other payments
 */
export function isOffline(invoice: Invoice, full = false) {
  const numPayments = Object.keys(invoice.payments).length
  const hasOffline = Object.keys(invoice.payments).includes(PaymentSources.OFFLINE)
  // If full is true then we should only return true if numPayments is 1
  return hasOffline && (!full || numPayments === 1)
}

/** If the invoice was paid fully offline */
export function isPaidOffline(invoice: Invoice) {
  const offlineAmt = invoice.payments.offline?.totalPaid || Zero
  return MoneyCalc.isGTE(offlineAmt, invoice.amountPaid || Zero) && isPaid(invoice)
}

/** Will return true if the item id is an invoice fee item */
export function isFeeItem(id: InvoiceItem['id']) {
  return isTipOrServiceFee(id) || isShippingOrDeliveryFee(id) || isProductFee(id)
}

/** Will return true if the item id is a tip */
export function isTipOrServiceFee(id: InvoiceItem['id']) {
  return id === InvoiceItemTypes.TIP
}

/** Will return true if the item id is a product fee */
export function isProductFee(id: InvoiceItem['id']) {
  return id.startsWith('tax_') || id.startsWith('fee_')
}

/** Will return true if the item id is an adjustment item */
export function isAdjustmentItem(id: InvoiceItem['id']) {
  return id === InvoiceItemTypes.ADJUSTMENT
}

/** Will return true if the item id is a shipping or delivery fee */
export function isShippingOrDeliveryFee(id: InvoiceItem['id']) {
  return id === InvoiceItemTypes.SHIPPING_FEE || id === InvoiceItemTypes.DELIVERY_FEE
}

export enum InvoiceStatus {
  Refunded = 'Refunded',
  Void = 'Void',
  Paid = 'Paid',
  Due = 'Due',
  Failed = 'Failed',
  // Means that a payment requires user action to proceed
  Incomplete = 'Incomplete',
}

export type DisplayInvStatus = InvoiceStatus | 'Unpaid' | 'Pending' | 'Pending (ACH)' | 'Pending (3D)'

/** This will show InvStatus with custom logic to mutate the invoice status with certain condition to be used in Admin Customer Invoice section, summaryInvoices and detailInvoices report. */
export function displayInvStatus(invoice: PartialPick<Invoice, 'status' | 'dueDate'>): DisplayInvStatus {
  // if the invoice is due, and the due date is passed, we need to show due status, and if not, we need to show unpaid status
  if (invoice.status === InvoiceStatus.Due) {
    if (dateTimeInZone(invoice.dueDate.zoneName) < invoice.dueDate) return 'Unpaid'
    else return InvoiceStatus.Due
  } else if (invoice.status === InvoiceStatus.Incomplete) {
    if (invoice.nextStep?.type === InvoiceNextStep.STRIPE_3D_SECURE) return 'Pending (3D)'
    else if (invoice.nextStep?.type === InvoiceNextStep.PENDING_BANK_AUTHORIZATION) return 'Pending (ACH)'
    else return 'Pending'
  } else return invoice.status
}

/** Will check and cast the invoice payment to Stripe Credit Card */
export function isCreditCardInvoicePayment(
  invoicePmt?: InvoicePayment,
): invoicePmt is InvoicePayment<PaymentSources.STRIPE> {
  return invoicePmt?.source === PaymentSources.STRIPE
}
/** Will check and cast the invoice payment to Stripe Ach */
export function isAchInvoicePayment(
  invoicePmt?: InvoicePayment,
): invoicePmt is InvoicePayment<PaymentSources.STRIPE_ACH> {
  return invoicePmt?.source === PaymentSources.STRIPE_ACH
}
/** Will check and cast the invoice payment to WorldPay */
export function isWorldPayInvoicePayment(
  invoicePmt?: InvoicePayment,
): invoicePmt is InvoicePayment<PaymentSources.WORLD_PAY_EBT> {
  return invoicePmt?.source === PaymentSources.WORLD_PAY_EBT
}
