import { addLocation, snapshotLocation } from '@api/Locations'
import { ToolTips } from '@components'
import { CustomInput, ErrorText, FormMoneyInput, KeyboardAvoidingScrollView, Text, Toast } from '@elements'
import { YUP_MONEY_OPTIONAL } from '@helpers/Yup'
import { nonEmptyString } from '@helpers/helpers'
import { ShortZip } from '@models/Address'
import {
  DeliveryLocationTypes,
  FeeWaiveOptionType,
  Location,
  LocationTypes,
  NonPickup,
  RegionType,
} from '@models/Location'
import { Money, Zero } from '@models/Money'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import LoaderWithMessage from '../../../components/LoaderWithMessage'
import Colors from '../../../constants/Colors'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { setAdminNav } from '../../../redux/actions/adminState'
import { adminFarmSelector, adminParamsSelector } from '../../../redux/selectors'
import { AdminView } from '../../components/AdminView'
import { LocationsAndZonesParamList } from '../../navigation/types'
import { EditHeader } from '../Schedules/components/EditHeader'
import { StateZipInput } from './StateZipsInput'
import { ReturnStates, validateDeliveryShippingEdit } from './editValidation'

import InputLabel from '@/admin/components/InputLabel'
import { getShortState } from '@/assets/data/states'
import { locationsCollection } from '@api/framework/ClientCollections'
import { AccessRight, Permission } from '@helpers/Permission'

type FormNonPickup<T extends DeliveryLocationTypes = DeliveryLocationTypes> = {
  type: T
  locationName: string
  regions: NonPickup<T>['regions']
  cost?: Money
  feeWaiveOption: FeeWaiveOptionType
}

type YupValidation<T extends DeliveryLocationTypes> = Omit<FormNonPickup<T>, 'type'>

enum EditState {
  LOADING = 0,
  FAILED = 1,
  SUCCESS = 2,
}

const deliveryValidationSchema = Yup.object<YupValidation<LocationTypes.Delivery>>().shape({
  locationName: Yup.string().trim().label('Location Name').required(),
  cost: YUP_MONEY_OPTIONAL('cost', { allowZero: true }),
  regions: Yup.array()
    .test('Has min', 'You must select at least one zip code', (v) => !!v && v.length > 0)
    .test('5 digits', 'Zip code must be 5 digits', (v) => {
      return !!v && v.every((zip: string) => !!zip && zip.length === 5)
    })
    .required(),
  feeWaiveOption: Yup.mixed<FeeWaiveOptionType>().oneOf(Object.values(FeeWaiveOptionType)).required(),
})

const shippingValidationSchema = Yup.object<YupValidation<LocationTypes.Shipping>>().shape({
  locationName: Yup.string().trim().label('Location Name').required(),
  cost: YUP_MONEY_OPTIONAL('cost', { allowZero: true }),
  regions: Yup.array()
    .required()
    .min(1, 'You must select at least one state')
    .test('Valid state', 'State must be a valid state code', (v) => {
      return !!v && v.every((s) => nonEmptyString(s) && !!getShortState(s))
    }),
  feeWaiveOption: Yup.mixed<FeeWaiveOptionType>().oneOf(Object.values(FeeWaiveOptionType)).required(),
})

const dynamicLocationText = (type?: LocationTypes) => {
  if (type === LocationTypes.Shipping) return 'Shipping'
  return 'Delivery'
}

function AddDeliveryShippingScreen() {
  const navigation = useNavigation<StackNavigationProp<LocationsAndZonesParamList, 'AddDeliveryShipping'>>()
  const dispatch = useDispatch()
  const { params } = useRoute<RouteProp<LocationsAndZonesParamList, 'AddDeliveryShipping' | 'EditDeliveryShipping'>>()
  const farm = useSelector(adminFarmSelector)
  const { location: editLocation } = useSelector(adminParamsSelector) as { location: NonPickup }
  const [editState, setEditState] = useState<EditState>(EditState.LOADING)

  const isEdit = !!params?.id
  const locationType = params?.type || LocationTypes.Delivery

  /** If the edit location isn't in context, listen to the location id */
  useEffect(() => {
    if (!(params?.id && (!editLocation || editLocation.id !== params.id))) {
      setEditState(EditState.SUCCESS)
      return
    }

    return snapshotLocation(
      params.id,
      farm.id,
      (location: Location) => {
        dispatch(setAdminNav({ location }))
        setEditState(EditState.SUCCESS)
      },
      () => setEditState(EditState.FAILED),
    )
  }, [params?.id, editLocation, farm?.id, dispatch])

  /** Sets form initial values if a location is being edited */
  const initialValues: FormNonPickup = useMemo(() => {
    if (isEdit && editLocation) {
      return {
        locationName: editLocation.name,
        type: editLocation.type,
        cost: editLocation.cost,
        regions: editLocation.regions,
        feeWaiveOption: FeeWaiveOptionType.None,
      }
    } else
      return {
        type: params?.type || LocationTypes.Delivery,
        locationName: '',
        cost: undefined,
        regions: [],
        feeWaiveOption: FeeWaiveOptionType.None,
      }
  }, [editLocation, isEdit, params?.type])

  const handleSubmitLocation = useCallback(
    async function handleSubmitLocation(values: FormNonPickup, { resetForm }: FormikHelpers<FormNonPickup>) {
      const type: LocationTypes = values.type

      // Set values from the form
      const newLocation: NonPickup = {
        id: '',
        farm: { id: farm?.id, urlSafeSlug: farm?.urlSafeSlug },
        name: values.locationName,
        type,
        timezone: editLocation?.timezone || farm.timezone,
        cost: values.cost ?? Zero,
        regions: values.regions as ShortZip[],
        feeWaiveOption: values.feeWaiveOption,
      }
      if (isEdit && editLocation) {
        // Check that we are actually updating anything and warn the farmer of any important changes
        const result = await validateDeliveryShippingEdit(editLocation, newLocation)
        switch (result.status) {
          case ReturnStates.GO_BACK:
            Toast('Nothing to update, going back')
            break
          case ReturnStates.DO_NOTHING:
            return // return here so we don't go back if something requires more attention or a popup was cancelled
          case ReturnStates.SAVE: {
            // If the farmer agrees and there are changes then proceed to update
            if (result.status === ReturnStates.SAVE) {
              await locationsCollection.update(result.data)
              Toast('Location was updated')
            }
            break
          }
          default: {
            Toast('Something unexpected happened while validating your edit')
            break
          }
        }
      } else {
        await addLocation(newLocation, farm)
        Toast('Location was added')
      }
      resetForm()
      navigation.navigate('Locations')
    },
    [editLocation, farm, isEdit, navigation],
  )

  const isDeliveryAction = editLocation?.type === LocationTypes.Delivery || locationType === LocationTypes.Delivery

  return (
    <AdminView>
      {!isEdit || editState === EditState.SUCCESS ? (
        <Formik
          validationSchema={
            locationType === LocationTypes.Delivery ? deliveryValidationSchema : shippingValidationSchema
          }
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmitLocation}
        >
          {({
            touched,
            errors,
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }: FormikProps<FormNonPickup>) => (
            <>
              <EditHeader
                goBack={() => navigation.navigate('Locations')}
                title={
                  isEdit && !!editLocation.name
                    ? `Edit ${dynamicLocationText(editLocation.type)} Zone - ${editLocation.name}`
                    : `Add ${dynamicLocationText(locationType)} Zone`
                }
                actionTitle="Save"
                isLoading={isSubmitting}
                onPress={handleSubmit}
                toolTipId={isDeliveryAction ? ToolTips.DELIVERY_ZONE : undefined}
              />
              <View style={styles.formWrapper}>
                <KeyboardAvoidingScrollView>
                  <CustomInput
                    onChangeText={handleChange('locationName')}
                    value={values.locationName}
                    onBlur={handleBlur('locationName')}
                    label={`${dynamicLocationText(values.type)} Zone Name`}
                    errorMessage={touched.locationName ? errors.locationName : ''}
                  />
                  <FormMoneyInput
                    maxLength={11}
                    label={
                      <InputLabel
                        label={isDeliveryAction ? 'Delivery fee' : 'Shipping fee (Optional)'}
                        tooltipId={isDeliveryAction ? ToolTips.DELIVERY_FEE : ToolTips.SHIPPING_FEE}
                      />
                    }
                    useCustomInput
                    placeholder="$0.00"
                    onBlur={handleBlur('cost')}
                    value={values.cost}
                    onChangeText={(value) => setFieldValue('cost', value)}
                    errorMessage={touched.cost && !!errors.cost ? (errors.cost as string) : ''}
                  />
                  {/* Temporarily hiding the feeWaiveOption from UI until the functionality is implemented. In the meantime, option "None" should be saved with the location. */}
                  {/* <View style={styles.pickerWrapper}>
                    <CustomInput
                      label="Fee waive option"
                      errorMessage={touched.feeWaiveOption ? errors.feeWaiveOption : ''}
                      InputComponent={forwardRef(() => (
                        <DropDownPicker
                          style={{ flex: 1, margin: 10 }}
                          inputStyle={{ fontSize: fontSize(14, 2) }}
                          value={values.feeWaiveOption}
                          minimal
                          noPlaceholder
                          onValueChange={handleChange('feeWaiveOption')}
                          items={[
                            {
                              label: FeeWaiveOptionType.None,
                              value: FeeWaiveOptionType.None,
                            },
                            {
                              label: FeeWaiveOptionType.PartialEBT,
                              value: FeeWaiveOptionType.PartialEBT,
                            },
                            {
                              label: FeeWaiveOptionType.FullEBT,
                              value: FeeWaiveOptionType.FullEBT,
                            },
                          ]}
                        />
                      ))}
                    />
                  </View> */}

                  <StateZipInput
                    helperText={
                      values.type === LocationTypes.Delivery
                        ? 'Any zip codes you deliver to'
                        : 'Any state you want to ship to, NY, CT, VA...'
                    }
                    values={values.regions}
                    label={
                      <InputLabel
                        label={`Enter ${values.type === LocationTypes.Shipping ? 'State' : 'Zip Code'}`}
                        tooltipId={
                          values.type === LocationTypes.Shipping ? ToolTips.ADD_SHIPPING_ZONE : ToolTips.ENTER_ZIP_CODE
                        }
                      />
                    }
                    onUpdate={(val) => setFieldValue('regions', val)}
                    type={values.type === LocationTypes.Delivery ? RegionType.Zipcode : RegionType.State}
                  />
                  {touched.regions && !!errors.regions && <ErrorText>{errors.regions}</ErrorText>}
                </KeyboardAvoidingScrollView>
              </View>
            </>
          )}
        </Formik>
      ) : (
        <>
          <EditHeader
            goBack={() => navigation.navigate('Locations')}
            title={`Edit ${dynamicLocationText(locationType)} Zone`}
            actionTitle="Back"
            onPress={() => navigation.navigate('Locations')}
            toolTipId={isDeliveryAction ? ToolTips.DELIVERY_ZONE : undefined}
          />
          <LoaderWithMessage
            loading={editState === EditState.LOADING}
            icon="map-marked"
            title={`${dynamicLocationText(locationType)} not found`}
          >
            <Text>
              This {dynamicLocationText(locationType)} could not be loaded, please click the "X" in the upper right
              corner and go back and select a location from the list.
            </Text>
          </LoaderWithMessage>
        </>
      )}
    </AdminView>
  )
}

const styles = StyleSheet.create({
  headerCont: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades[100],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  formWrapper: {
    marginVertical: 20,
    width: '90%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  pickerWrapper: {
    width: '50%',
  },
  marginBottom: {
    marginBottom: 5,
  },
})
export default withAdminAuth(AddDeliveryShippingScreen, Permission.ProductSetup, AccessRight.Edit)
