import { Farm } from '@models/Farm'
import { User } from '@models/User'

import { callEndpoint } from './v2'

/** sendUserMessage sends a message to the user. */
export async function sendUserMessage(
  to: User,
  from: Farm,
  farmerId: string,
  subject: string,
  message: string,
): Promise<void> {
  return await callEndpoint('v2.Messaging.sendUserMessageService', {
    customerId: to.id,
    farmId: from.id,
    farmerId,
    subject,
    message,
  })
}
