import { updateFarm } from '@api/Farms'
import { Button, Divider, HeaderText, Icon, ProgressBar, Text, TextLink, WebLink } from '@elements'
import { emailSupport } from '@helpers/client'
import { Farm, OnboardSteps } from '@models/Farm'
import { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Permission } from '@helpers/Permission'
import { Image } from '../../components/Image'
import { Youtube } from '../../components/Youtube'
import { globalStyles } from '../../constants/Styles'
import { AdminView } from '../components/AdminView'
import { adminCard } from '../constants/AdminCards'

import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import Colors from '@/constants/Colors'
import { useAdminFeatureFlag } from '@/hooks/useFeatureFlag'
import { useLayout } from '@/hooks/useLayout'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmSelector } from '@/redux/selectors'

function AdminOnboardScreen() {
  const { isSmallDevice, ...layout } = useLayout()
  const farm = useSelector(adminFarmSelector)
  const hasEbtFeature = useAdminFeatureFlag()('ebt')

  const STEPS = useCallback(
    (farm: Farm) => [
      {
        id: OnboardSteps.CLAIM,
        title: 'Claim your farm',
        details:
          "Congratulations, you've claimed your farm, complete the rest of this walkthrough to get your farm up and running",
        link: '/farm-onboard/walkthrough',
        video: 'mBPCS3QJ6bc',
        link_text: 'Claim your Farm',
      },
      {
        id: OnboardSteps.FARM_PROFILE,
        title: 'Update farm profile',
        details:
          'In your farm profile you can share your farm’s story, highlight your brand, and make it easy for customers to find you. Your profile also includes certifications, tags, and links to social pages.',
        video: '4weWHUzLnRM',
        link: '/admin/farm-profile',
        link_text: 'Update farm profile',
      },
      {
        id: OnboardSteps.PAYMENTS,
        title: 'Configure Payments',
        details:
          'Next, you’ll need to configure your payment preferences. In this step, you’ll have the option to enable offline payments and include an offline payment message. You’ll also need to create a new Stripe account that is connected to GrownBy. Stripe processes and tracks payments with GrownBy.',
        video: 'Wg9mP4_p3mQ',
        link: '/admin/setup/payments',
        link_text: 'Configure Payments',
      },
      ...(hasEbtFeature
        ? [
            {
              id: OnboardSteps.EBT,
              title: 'Configure EBT',
              details: 'Next, You can also connect to Worldpay for SNAP/EBT payments if you are an authorized farm.',
              video: '',
              link: '/admin/setup/payments',
              link_text: 'Configure EBT',
            },
          ]
        : []),
      {
        id: OnboardSteps.LOCATIONS_ZONES,
        title: 'Add locations',
        details:
          'Add the addresses where you’ll be distributing products and other details, such as order deadlines for those locations. ',
        video: 'EyBhXtLKQzQ',
        link: '/admin/locations',
        link_text: 'Add a Location',
      },
      {
        id: OnboardSteps.SCHEDULES,
        title: 'Add schedules',
        details:
          'Add a schedule for when you’ll be distributing your products, you can choose either seasonal or year-round distributions and you can add skip or rescheduled days.',
        video: 'ooTaFHNU-xc',
        link: '/admin/schedules',
        link_text: 'Add a Schedule',
      },
      {
        id: OnboardSteps.PRODUCTS,
        title: 'Add CSAs, Products, and Subscriptions',
        details:
          'GrownBy offers many options for selling your products, either for pickup one time or on subsciprtion. Learn about product creation here.',
        video: 'kCMlDdQItJ0',
        link: '/admin/products/list',
        link_text: 'Add a Product',
      },
      {
        id: OnboardSteps.REVIEW_SHOP,
        title: 'Review your shop',
        details:
          'This video will tell you how to get to your shop and the best practices for improving the customer experience.',
        video: 'CBLsOogtXWM',
        link: `/farms/${farm.id}/shop`,
        link_text: 'Go to My Shop',
      },
      {
        id: OnboardSteps.SHARE_FARM,
        title: 'Share your shop',
        details: 'Don’t forget to share your beautiful shop with your customers! Here is your link to share:',
        video: '',
        link: `/farms/${farm.id}/shop`,
        link_text: `${grownbyWebsiteBaseUrl()}farms/${farm.urlSafeSlug}/shop`,
      },
    ],
    [hasEbtFeature],
  )

  const NUM_STEPS = STEPS(farm).length

  const stepClicked = (id: string) => {
    if (id === OnboardSteps.SHARE_FARM || id === OnboardSteps.REVIEW_SHOP) {
      updateFarm({ id: farm.id, onboardSteps: { ...(farm.onboardSteps || {}), [id]: true } })
    }
  }

  return (
    <AdminView>
      <View style={styles.container}>
        <ProgressBar
          progress={
            Object.keys(farm.onboardSteps || {}).filter((step) =>
              [...(Object.values(OnboardSteps) as string[])].includes(step),
            ).length / NUM_STEPS
          }
          showValue
        />
        <View style={[styles.header, isSmallDevice && { flexDirection: 'column' }]}>
          <View style={{ width: isSmallDevice ? 'auto' : '40%', alignItems: 'center' }}>
            <Image
              source={require('../../assets/images/farmer-onboard/farmer-onboard-image.png')}
              style={[styles.image, { width: isSmallDevice ? 200 : 300 }]}
            />
          </View>
          <View style={[styles.textContainer, { width: isSmallDevice ? 'auto' : '60%', justifyContent: 'center' }]}>
            <HeaderText>Welcome to GrownBy!</HeaderText>
            <Divider clear />
            <Text>
              We’re so glad you’re here! Follow the steps below and you will be up and running in no time. If you have
              any questions please don’t hesitate to contact us at{' '}
              <TextLink onPress={() => emailSupport()}>support@grownby.app.</TextLink>
            </Text>
          </View>
        </View>
        {STEPS(farm).map((step, idx) =>
          !farm.onboardSteps || !farm.onboardSteps[step.id] ? (
            <View style={[adminCard(layout), { flexDirection: isSmallDevice ? 'column' : 'row' }]} key={step.id}>
              <View style={[styles.textContainer, { maxWidth: isSmallDevice ? '100%' : '50%' }]}>
                <HeaderText size={isSmallDevice ? 16 : 20}>
                  Step {idx + 1}: {step.title}
                </HeaderText>
                <Divider clear />
                <Text>{step.details}</Text>
                <Divider clear />
                <View style={{ justifyContent: 'flex-end', flex: 1 }}>
                  <WebLink url={step.link} style={{ color: Colors.green }} weight="medium">
                    {step.link_text}
                  </WebLink>
                </View>
                {(step.id === OnboardSteps.SHARE_FARM || step.id === OnboardSteps.REVIEW_SHOP) &&
                  (!farm.onboardSteps || !farm.onboardSteps[step.id]) && (
                    <Button
                      outline
                      style={{ marginTop: 20, width: 200 }}
                      title="Mark Complete"
                      onPress={() => stepClicked(step.id)}
                    />
                  )}
              </View>
              <View style={{ alignItems: 'center', justifyContent: 'center', width: isSmallDevice ? 'auto' : '50%' }}>
                {!!step.video && <Youtube videoId={step.video} width={300} height={200} />}
              </View>
            </View>
          ) : (
            <View style={[adminCard(layout), globalStyles.flexRowCenter]} key={step.id}>
              <Icon name="check-circle" solid style={{ marginHorizontal: 10 }} />
              <HeaderText color={Colors.shades['200']} size={isSmallDevice ? 16 : 20}>
                Step {idx + 1}: {step.title}
              </HeaderText>
            </View>
          ),
        )}
      </View>
    </AdminView>
  )
}

export default withAdminAuth(AdminOnboardScreen, Permission.AccountSetup)

const styles = StyleSheet.create({
  container: {
    margin: 20,
    alignItems: 'center',
  },

  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: 10,
  },
  image: {
    width: 300,
    height: 300,
  },
  textContainer: {
    marginHorizontal: 30,
    marginBottom: 10,
    flex: 1,
  },
})
