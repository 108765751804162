import { Pickup, PickupItem } from '@models/Order'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalPickup transforms the Pickup structure into a Firestore data. */
export function marshalPickup(pickup: Partial<Pickup>): DocumentData {
  const data = prepareMarshal(pickup) as DocumentData

  if (pickup.date) {
    data.date = marshalDate(pickup.date)
  }

  if (Array.isArray(pickup.items)) {
    data.items = pickup.items.map((item) => marshalPickupItem(item))
  }
  return data
}

/** unmarshalPickup transforms the Firestore data to an Pickup object. */
export function unmarshalPickup(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Pickup {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const pickup = { ...data, id } as Pickup

  if (data.date) {
    pickup.date = unmarshalDate(data.date)!
  }
  if (Array.isArray(data.items)) {
    pickup.items = data.items.map((item) => unmarshalPickupItem(item))
  }

  return pickup
}

/** marshalPickup transforms the Pickup structure into a Firestore data. */
export function marshalPickupItem(pickup: Partial<PickupItem>): DocumentData {
  const data = { ...pickup } as DocumentData
  return data
}

/** unmarshalPickupItem transforms the Firestore data to an Pickup object. */
export function unmarshalPickupItem(data: DocumentData): PickupItem {
  const pickup = { ...data } as PickupItem

  if (!data.productId) {
    pickup.productId = data.id
  }

  return pickup
}
