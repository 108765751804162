import { Farm } from '@models/Farm'
import { Money, Zero } from '@models/Money'

import { Invoice, PaymentSources, isTipOrServiceFee } from '@models/Invoice'
import { SplitTenderPayment } from '@models/Order'
import { isAchPayment, isCreditPayment, isInfinitePayment } from '@models/PaymentMethod'
import {
  DEFAULT_APP_FEE,
  MAX_STRIPE_ACH_FEE,
  STRIPE_ACH_FEE_PERCENT,
  STRIPE_CREDIT_ADDITIONAL_FEE,
  STRIPE_CREDIT_CARD_FEE_PERCENT,
} from '../CONSTANTS'
import { addFeeToInvoice } from '../services/InvoiceService'
import { MoneyCalc } from './money'

/** The types of fee coverage options we allow, Platform means the customer is covering fees only, and we use custom to
 * indicate that they are including a tip */
export enum CoverOptId {
  None = 'no',
  Platform = 'platform',
  Custom = 'custom',
}
type CoverOption = {
  /** The type of fee coverage the user is including */
  id: CoverOptId
  /** The amount the user is tipping */
  tip: Money
  /** The total fee amount including tip */
  value: Money
}
export type CoverFee = CoverOption & {
  /** Apply to all installments will only apply the platform service fee and will ignore custom amounts */
  applyToInstallments?: boolean
}

/**
 *  Will get the default selected fee value from the amount and farm settings
 * @param serviceFeeAmount The amount of service fees charged on the order that can be covered
 * @param settings the farm settings for fee options
 */
export const getDefaultOption = (serviceFeeAmount: Money, settings: Farm['tipsAndFees']): CoverOption => {
  if (settings?.customerChooseToPayFees === false) {
    return {
      id: CoverOptId.None,
      tip: Zero,
      value: Zero,
    }
  }
  return {
    id: CoverOptId.Platform,
    tip: Zero,
    value: serviceFeeAmount,
  }
}

/**
 *  Will return a list of fee selection options based on the serviceFee value and the farm settings
 * @param serviceFeeAmount The amount of service fees charged on the order that can be covered
 * @param settings the farm settings for fee options
 */
export const getCoverOptions = (serviceFeeAmount: Money, settings: Farm['tipsAndFees']): CoverOption[] => {
  const feesOnly: CoverOption[] = [
    {
      id: CoverOptId.None,
      tip: Zero,
      value: Zero,
    },

    {
      id: CoverOptId.Platform,
      tip: Zero,
      value: serviceFeeAmount,
    },
  ]
  /** treat undefined as true because this option will be pre-selected and not written to firestore yet */
  if (settings === undefined || settings?.showTipOption) {
    return [
      ...feesOnly,
      {
        id: CoverOptId.Custom,
        tip: Zero,
        // Will default to using the platform fee amount as that is the custom base then the user can add to that
        value: serviceFeeAmount,
      },
    ]
  }
  /** treat undefined as true because this option will be pre-selected and not written to firestore yet */
  if (settings === undefined || settings?.customerChooseToPayFees) {
    return feesOnly
  }
  return []
}
/**
 * Will calculate the total amount for service fees to be calculated on based on the split tender and the total amount
 * @param splitTender the split tender to calculate the fees for
 * @param amountForFees the total amount of the order or invoice, after discounts have been applied
 * @param farmPlatformFee If the farm has a custom platform fee then it should be applied instead of the default
 */
export function getServiceFeeAmountFromTender(
  splitTender: SplitTenderPayment,
  amountForFees: Money,
  farmPlatformFee?: number,
) {
  // The total amount we are paying with finite payment methods, finite payments (Farm Credit or EBT) don't have fees
  const finiteAmount =
    splitTender
      ?.filter((pmt) => !isInfinitePayment(pmt.paymentMethod.source))
      .reduce((tot, { amount }) => MoneyCalc.add(tot, amount ?? Zero), Zero) ?? Zero

  // Fees are calculated only for credit card payments. Do not calculate fee if we have none
  const creditOrAchPmt = splitTender?.find(
    (pay) => isCreditPayment(pay.paymentMethod) || isAchPayment(pay.paymentMethod),
  )
  if (!creditOrAchPmt) return Zero

  // To determine how much is being paid with CC we can subtract the finite amount from the total
  const totalForFees = MoneyCalc.max(MoneyCalc.subtract(amountForFees, finiteAmount), Zero)
  return getStripeServiceFeeForPayment(creditOrAchPmt.paymentMethod.source, totalForFees, farmPlatformFee)
}

/**
 * This function will get the correct fee amount given the payment method
 * @param paymentSource the payment source to get the fee percentage for
 * @param totalAmount the total amount of the order or invoice that the fee should apply to
 * @param farmPlatformFee If the farm has a custom platform fee then it should be applied instead of the default
 */
export function getStripeServiceFeeForPayment(
  paymentSource: PaymentSources,
  totalAmount: Money,
  farmPlatformFee?: number,
) {
  // If the total amount to charge fees on is Zero then don't charge any fees. Without this it will charge the flat fee like $0.30
  if (MoneyCalc.isZero(totalAmount)) return Zero

  const appFeePercent = farmPlatformFee ?? DEFAULT_APP_FEE
  if (paymentSource === PaymentSources.STRIPE_ACH) {
    // Calculate the Stripe portion of the fee
    const stripeFee = MoneyCalc.math(Math.ceil, MoneyCalc.multiply(totalAmount, STRIPE_ACH_FEE_PERCENT / 100))
    // Calculate the platform portion of the fee
    const platformFee = MoneyCalc.math(Math.ceil, MoneyCalc.multiply(totalAmount, appFeePercent / 100))
    // The total fee is the platform fee + Stripe fee Maxed out at $5
    return MoneyCalc.add(platformFee, MoneyCalc.min(stripeFee, MAX_STRIPE_ACH_FEE))
  } else if (paymentSource === PaymentSources.STRIPE) {
    const totalFee = appFeePercent + STRIPE_CREDIT_CARD_FEE_PERCENT
    const percentageFee = MoneyCalc.math(Math.ceil, MoneyCalc.multiply(totalAmount, totalFee / 100))

    // Add the additional $0.30 Stripe charges on top of the percentage
    return MoneyCalc.add(percentageFee, STRIPE_CREDIT_ADDITIONAL_FEE)
  } else {
    // If it is not Stripe payment then there are no fees for now
    return Zero
  }
}

/** the invoice description for InvoiceItem-tip */
enum ServiceFeeLanguages {
  TipAndCoveredFee = 'Thank you for your tip!',
  OnlyCoveredFee = 'Thank you for covering our fees!',
}

/** the service fee name that should be display in UI  */
enum ServiceFeeNames {
  TipsAndCoveredFees = 'Covered fees & Tips',
  CoveredFees = 'Covered Fees',
}

/**
 * Will add any tips or service fee coverage to the invoices
 * @param invoices the list of invoices to update
 * @param tips the tips configuration to use
 * @param payments the split tender configuration to calculate tips for
 * @param farm the farm the invoices are for
 */
export function addTipsAndFeesToInvoices(
  invoices: Invoice[],
  tips: Omit<CoverFee, 'value'> | undefined,
  payments: SplitTenderPayment,
  farm: Farm,
) {
  // If we are not adding tips return the invoices unaltered
  if (!tips || tips.id === CoverOptId.None) return invoices

  // If there are tips add them to the first invoice, or all invoices if apply to installments is true
  return invoices.map((inv, idx) => {
    // We are not using upfront here because we don't care if is an upfront invoice, we just want to add it to whatever the
    // first invoice is.
    const isFirstInvoice = idx === 0

    // If this is an installment, and we aren't charging tips for installments then don't add tips to this invoice
    if (!isFirstInvoice && !tips.applyToInstallments) return inv

    // Get the fee amount to apply to each invoice from the total and split tender.
    let feeAmt: Money = getServiceFeeAmountFromTender(payments, inv.amountTotal, farm.pricingModel?.appFeePercent)

    // Only add the tip to the first invoice
    if (isFirstInvoice) {
      feeAmt = MoneyCalc.add(feeAmt, tips.tip)
    }

    // Create a custom invoice message for the fee line item
    const description =
      isFirstInvoice && MoneyCalc.isGTZero(tips.tip)
        ? ServiceFeeLanguages.TipAndCoveredFee
        : ServiceFeeLanguages.OnlyCoveredFee

    // Add the fee to the invoice as a tip option. This allows us to alter that on the invoice detail page
    return addFeeToInvoice(inv, {
      description,
      amount: feeAmt,
      /** This is a preliminary assignment of a payment source to this fee item. The true payment source is applied below by rebuildInvoice */
      source: payments[0].paymentMethod.source,
    })
  })
}

/**
 * TODO: This is the only logic that we can get correct service fee name to be shown on the screen that need to display the service fee name from the invoice. Can have better implementation later. The reason for that is that we don't separate coveredFee and tip in the invoice. However, during checkout, the customer can have cover fee + tip or only cover fee, but we combine them into one item in the invoice. Thus, we only can get the service fee name based on the description of the tip item in the invoice. Separate covered fee and tips in the invoice will be a better solution, but it will require some changes when building the invoice and cancellation. Now, we only need to know the name of the service fee, and cancellation is working fine now, so we can keep it as is until we have to change it for different purpose.
 * This helper is to generate Service fee name from an invoice */
export function getServiceFeeNameForInvoice(invoice: Invoice): ServiceFeeNames | '' {
  const tip = invoice.items.find((item) => isTipOrServiceFee(item.id))

  if (!tip) return ''

  if (tip.description === ServiceFeeLanguages.TipAndCoveredFee) {
    return ServiceFeeNames.TipsAndCoveredFees
  } else if (tip.description === ServiceFeeLanguages.OnlyCoveredFee) {
    return ServiceFeeNames.CoveredFees
  } else {
    // Handle all older invoices cases
    return ServiceFeeNames.TipsAndCoveredFees
  }
}
