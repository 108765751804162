import { getDefaultRadius, getCoordString } from '@helpers/coordinate'
import { RouteProp, useRoute } from '@react-navigation/native'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { Configure, connectSearchBox } from 'react-instantsearch-native'
import { useSelector } from 'react-redux'

import { getDefaultCoords } from './helpers'

import { useFocusFx, useDeepCompareFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { HomeParamList } from '@/navigation/types'
import { searchFiltersSelector, searchLocationSelector, userLocationSelector } from '@/redux/selectors'

/** Map-specific virtual component that applies the MapFilters, coords, and radius to algolia Configure and performs searchbox refinement */
export const SearchConfigure = connectSearchBox(({ refine }: SearchBoxProvided) => {
  const { params } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'>>()
  const searchLoc = useSelector(searchLocationSelector)
  const userLoc = useSelector(userLocationSelector)
  const filters = useSelector(searchFiltersSelector)
  const [{ radius, aroundLatLng }, , setState] = useKeyedState({ radius: getDefaultRadius(), aroundLatLng: '' })

  // sets aroundLatLng and radius for search configure when locations change
  useDeepCompareFocusFx(() => {
    const coords = getDefaultCoords({ searchLoc, userLoc, near: params?.near })

    // This radius value must be a whole number to work with algolia's Configure "aroundRadius" prop
    const radius = searchLoc?.maxRadius ? Math.min(searchLoc.maxRadius, getDefaultRadius()) : getDefaultRadius()
    setState({ aroundLatLng: getCoordString(coords), radius })
  }, [params?.near, searchLoc, userLoc, setState])

  //Applies the search refinement for farms or products based on the nav 'q' param
  useFocusFx(() => {
    refine(params?.q)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.q])

  return <Configure aroundLatLng={aroundLatLng} aroundRadius={radius} facetFilters={filters} hitsPerPage={50} />
})
