import { Alert } from '@elements'
import { isNonNullish } from '@helpers/helpers'
import { useCallback } from 'react'

import { Touched } from './helpers'
import { checkoutInitialState } from './useCheckoutData'

import { useCartService } from '@/hooks/useCart'
import { SetHelper } from '@/hooks/useKeyedState'

export const usePreCheckout = (
  set: SetHelper<typeof checkoutInitialState>,
  touched: Touched,
  placeOrder: () => Promise<void>,
) => {
  const { cart } = useCartService()

  /** preCheckout is called when the place order button is first pressed. Form must've passed validation by this time. It simply checks that all items have been touched; If so, it will proceed to calling placeOrder. */
  return useCallback(
    (setSubmitting: (isSubmitting: boolean) => void) => {
      setSubmitting(true)
      set('errors', undefined)
      //If some items haven't received a selection for payment schedule, alert and return
      const untouchedIds = Object.keys(touched).filter((k) => !touched[k])
      const untouchedItms = untouchedIds
        .map((id) => cart.find((itm) => itm.id === id))
        .filter(isNonNullish)
        .map((itm) => itm.product.name)
      if (untouchedIds.length) {
        Alert('Please select share payment option', 'Select a payment option for ' + untouchedItms.join(', ') + '.')
        set('errors', untouchedIds)
        setSubmitting(false)
        return
      }

      placeOrder()
        .catch(() => {
          /* No-op, but needs to catch user cancelled error */
        })
        .finally(() => setSubmitting(false))
    },
    [cart, set, touched, placeOrder],
  )
}
