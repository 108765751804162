import { createStackNavigator } from '@react-navigation/stack'
import { InvoiceConsumer } from '@screens/InvoiceDetails/InvoiceConsumer'
import { Platform, StyleSheet } from 'react-native'

import { Header } from '../components/Header/Header'
import ShoppingCart from '../components/ShoppingCart'
import { isWeb } from '../constants/Layout'
import ExploreScreen from '../screens/Explore/ExploreScreen'
import { FarmDetailScreen } from '../screens/FarmDetailScreen/FarmDetailScreen'
import HomeScreen from '../screens/Home/HomeScreen'
import MyFarmsScreen from '../screens/MyFarmsScreen'
import NotificationHistoryScreen from '../screens/NotificationHistoryScreen'
import { OrderSummary } from '../screens/Orders/OrderSummary'
import OrdersScreen from '../screens/Orders/OrdersScreen'
import ViewInvoice from '../screens/Orders/ViewInvoice'
import { CSADetails } from '../screens/Shopping/CSADetails'
import Checkout from '../screens/Shopping/Checkout/Checkout'
import { MyCart } from '../screens/Shopping/Checkout/MyCart/MyCart'
import { FarmShop } from '../screens/Shopping/FarmShop'
import { ProductDetails } from '../screens/Shopping/ProductDetails'
import { HomeParamList, NotificationParamList, OrdersParamList, ShoppingStackParamList } from './types'

import { useDeviceSize } from '@/hooks/useLayout'
import FarmerOnboardScreen from '@screens/FarmerOnboard/FarmerOnboardScreen'
import { MobileCsaHeader } from '@screens/Shopping/MobileCsaHeader'
import { MobileShopHeader } from '@screens/Shopping/MobileShopHeader'

const HomeStack = createStackNavigator<HomeParamList>()

export function HomeNavigator() {
  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false }}>
      <HomeStack.Screen name="HomeScreen" options={{ title: 'Home' }} component={HomeScreen} />
      <HomeStack.Screen name="ExploreScreen" options={{ title: 'Explore' }} component={ExploreScreen} />
      <HomeStack.Screen name="FarmerOnboardScreen" component={FarmerOnboardScreen} />
    </HomeStack.Navigator>
  )
}

const ShoppingStack = createStackNavigator<ShoppingStackParamList>()

/** This navigator holds all the screens related to shopping activities such as browsing for shops, products, cart, and checkout */
export function ShoppingNavigator() {
  const { isLargeDevice } = useDeviceSize()

  /** Whether our app-wide custom `Header` should show instead of the default react navigation header */
  const shouldShowMainHeader = isWeb || isLargeDevice

  return (
    <ShoppingStack.Navigator
      screenOptions={() => ({
        header: shouldShowMainHeader ? () => <Header /> : undefined,

        /** `ShoppingCart` component is used in the screens that have the default header (The screens for which the main header isn't shown)
         * - To disable this for a specific screen, pass the `headerRight:undefined` option on the <Stack.Screen element
         */
        headerRight: () => <ShoppingCart style={styles.shoppingCartContainer} />,
        headerLeftLabelVisible: false,
        headerTitleAllowFontScaling: false,
      })}
    >
      <ShoppingStack.Screen
        name="MyFarmsScreen"
        options={{
          title: 'My Farms',
        }}
        component={MyFarmsScreen}
      />
      <ShoppingStack.Screen
        name="FarmDetailScreen"
        options={{
          title: 'Farm Details',
          headerShown: isLargeDevice, // In non-large, the screen component sets the parallax header
        }}
        component={FarmDetailScreen}
        /**
         * https://reactnavigation.org/docs/screen#getid
         * getId here is enabling a functionality of the stack screen where if you navigate to the screen again, with a farmId that was loaded before, it will load the existing screen already in the stack, instead of pushing a new copy of the same screen name on top of the stack.
         * Else, the navigator proceeds as usual, either pushing or doing a simple navigate.
         * This also prevents seeing the previous farm on the screen for a fragment of a second before the new farm loads */

        getId={({ params }) => params.farmSlug}
      />
      <ShoppingStack.Screen
        name="FarmShop"
        component={FarmShop}
        getId={({ params }) => params.farmSlug}
        options={{
          headerRight: undefined,
          header: () => (shouldShowMainHeader ? <Header /> : <MobileShopHeader />),
        }}
      />
      <ShoppingStack.Screen
        name="CSADetails"
        component={CSADetails}
        getId={({ params }) => params.farmSlug + params.csaId}
        options={{
          headerRight: undefined,
          header: () => (shouldShowMainHeader ? <Header /> : <MobileCsaHeader />),
        }}
      />
      <ShoppingStack.Screen
        name="ProductDetails"
        options={{
          title: 'Product Details',
          headerShown: isLargeDevice, // In non-large, the screen component sets the parallax header
        }}
        component={ProductDetails}
        getId={({ params }) => params.farmSlug + params.productId + params.csaId}
      />
      <ShoppingStack.Screen
        name="MyCart"
        options={{
          title: 'My Cart',
          headerRight: undefined, // Doesn't need the shopping cart on the default navigator header
        }}
        component={MyCart}
      />

      <ShoppingStack.Screen
        name="Checkout"
        options={{
          headerRight: undefined, // Doesn't need the shopping cart on the default navigator header
        }}
        component={Checkout}
      />
    </ShoppingStack.Navigator>
  )
}

const NotificationStack = createStackNavigator<NotificationParamList>()

export function NotificationNavigator() {
  return (
    <NotificationStack.Navigator
      screenOptions={{ headerTitleAllowFontScaling: false, headerShown: Platform.OS !== 'web' }}
    >
      <NotificationStack.Screen
        name="NotificationHistoryScreen"
        options={{
          title: 'Notifications',
          headerRight: () => <ShoppingCart style={styles.shoppingCartContainer} />,
        }}
        component={NotificationHistoryScreen}
      />
    </NotificationStack.Navigator>
  )
}

const OrdersStack = createStackNavigator<OrdersParamList>()

export function OrdersNavigator() {
  return (
    <OrdersStack.Navigator screenOptions={{ headerTitleAllowFontScaling: false, headerShown: Platform.OS !== 'web' }}>
      <OrdersStack.Screen
        name="Orders"
        options={{ headerRight: () => <ShoppingCart style={styles.shoppingCartContainer} /> }}
        component={OrdersScreen}
      />
      <OrdersStack.Screen
        name="OrderSummary"
        options={{
          title: 'Order Summary',
        }}
        component={OrderSummary}
      />
      <OrdersStack.Screen
        name="InvoiceConsumer"
        options={{
          title: 'Invoice Details',
        }}
        component={InvoiceConsumer}
      />
      <OrdersStack.Screen name="ViewInvoice" component={ViewInvoice} />
    </OrdersStack.Navigator>
  )
}

const styles = StyleSheet.create({
  shoppingCartContainer: {
    marginHorizontal: 10,
  },
})
