import { groupBy, removeObjDuplicates } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { Address, ShortZip } from '@models/Address'
import { Distribution, isDistroNonPickup } from '@models/Distribution'
import {
  isDelivery,
  isDeliveryDistLocation,
  isLocalPickup,
  isLocalPickupDistLocation,
  isNonPickup,
  isShipping,
  isShippingDistLocation,
  Location,
  LocationTypes,
  NonPickup,
} from '@models/Location'
import { Money, Zero } from '@models/Money'
import { CartItem, CartPhysical, isCartPhysical, isPickupCancelled, ItemNonPickup, Pickup } from '@models/Order'
import { UserAddress } from '@models/UserAddress'
import { DateTime } from 'luxon'

import { sortByName } from './sorting'
import { entries, PartialPick, pick, PickExcept } from './typescript'

import { getShortState } from '@/assets/data/states'

/** Helper to determine whether a user address is compatible with a schedule's location and location type, for adding to cart.
 * - The location type must be specified in order to guarantee compatibility. Therefore if the locationType is not defined, the result should be false (Incompatible).
 * - The location must match with the location type specified.
 * - If the location type is nonPickup, the address must be defined, and must match the location's regions.
 */
export function isCompatibleLocation(
  scheduleLoc: Distribution['location'],
  locTypeFilter?: LocationTypes,
  address?: UserAddress,
) {
  /** locType filter must be defined for this to give a positive result, because this is meant to be a strict helper that validates a location is compatible with a location type and an address */
  if (!locTypeFilter) return false

  if (isShippingDistLocation(scheduleLoc)) {
    if (!address?.state || locTypeFilter !== LocationTypes.Shipping) return false

    // State must be validated
    const state = getShortState(address.state)
    if (!state) return false

    const regions = scheduleLoc.regions
    if (!regions.includes(state)) return false
  } else if (isDeliveryDistLocation(scheduleLoc)) {
    if (!address?.zipcode || locTypeFilter !== LocationTypes.Delivery) return false

    const regions = scheduleLoc.regions
    if (!regions.includes(address.zipcode as ShortZip)) return false
  } else if (isLocalPickupDistLocation(scheduleLoc)) {
    if (!isLocalPickup(locTypeFilter)) return false
  }

  return true
}

/** This allows us to categorize location types into 3 groups. These don't coincide 100% with the locationTypes because there are several localPickup types, aside from Farm */
export function getLocationKind(
  val: Location['type'] | PartialPick<Location, 'type'>,
): 'localPickup' | 'delivery' | 'shipping' {
  const typeObj: Pick<Location, 'type'> = typeof val === 'object' ? val : { type: val }
  if (isDelivery(typeObj)) return 'delivery'
  if (isShipping(typeObj)) return 'shipping'
  return 'localPickup'
}

/** Will create an address string that formats the address consistently to make it possible to compare with another*/
export const createAddressString = (addr: AddresExceptCoord) => {
  const addressFields: (keyof AddresExceptCoord)[] = ['street1', 'street2', 'city', 'state', 'zipcode', 'country']
  const addressExceptCoord = pick(addr, ...addressFields)
  return (
    entries(addressExceptCoord)
      // Will sort the entries by their key to be consistent
      .sort((a, b) => sortByName(a, b, (entry) => entry[0]))
      .map(([key, fieldValue]) => {
        if (key === 'state') {
          /** For state, we don't pass the fieldValue through baseString because it should not remove empty spaces other than String.trim(). Otherwise, the state name won't be found correctly */
          return getShortState(fieldValue)
        } else {
          return baseString(fieldValue)
        }
      })
      .join('')
  )
}

export type GetDeliveryFeeResult = { itemsDeliveryFees: Money; combinedDates: DateTime[]; combinedPickups: Pickup[] }

export const NoDeliveryFees: GetDeliveryFeeResult = { itemsDeliveryFees: Zero, combinedDates: [], combinedPickups: [] }

/** Calculates the delivery fee for an array of cart items. It will consider only unique dates at each location id.
 * @param items the cart items to calculate delivery for
 * @param locId will narrow the response to items in a single location id. If undefined the result will reflect all the items in the array.
 * @param pickups the user's existing future pickups from past orders
 */
export function getDeliveryFee(items: CartItem[], opts?: { pickups?: Pickup[]; locId?: string }): GetDeliveryFeeResult {
  const { pickups, locId } = opts ?? {}
  if (!items.length) return NoDeliveryFees

  const deliveryItems: ItemNonPickup[] = items
    .filter((itm): itm is CartPhysical & ItemNonPickup => isCartPhysical(itm) && isDistroNonPickup(itm.distribution))
    .filter((itm) => (locId ? itm.distribution.location.id === locId : true))
  if (!deliveryItems.length) return NoDeliveryFees

  return groupBy(deliveryItems, (itm) => {
    // It must group by location id because each physical item will have its own location and fee
    // Not necessary to group by address here because this is only for getting the delivery fees of a group of items that have the same base location fee
    // Not necessary to group by distro.id because we only care about the location fee for the purpose of this helper
    return itm.distribution.location.id
  })
    .map((itemGroup) => {
      // We must multiply the delivery fee times the number of unique pickup dates because each pickup to this location on the same day should count only once

      /** TODO: combining delivery fees is most likely OK for regional delivery but maybe isn't good for shipping because shipping more items at the same location will definitely incur more costs to the farmer */

      const uniqueDatesInGroup = getUniqueDates(itemGroup)
      const { nonCombinedDates, combinedDates, combinedPickups } = getDatesWithNewFees(
        uniqueDatesInGroup,
        itemGroup[0].distribution.id,
        itemGroup[0].distribution.location.address!,
        pickups,
      )

      const locationFee = itemGroup[0].distribution.location.cost
      const groupFees =
        locationFee && MoneyCalc.isGTZero(locationFee) ? MoneyCalc.multiply(locationFee, nonCombinedDates.length) : Zero
      return { groupFees, combinedDates, combinedPickups }
    })
    .reduce((prev, curr) => {
      return {
        itemsDeliveryFees: MoneyCalc.add(prev.itemsDeliveryFees, curr.groupFees),
        combinedDates: removeObjDuplicates(prev.combinedDates.concat(curr.combinedDates), (d) => d.toISO()),
        combinedPickups: removeObjDuplicates(prev.combinedPickups.concat(curr.combinedPickups)),
      }
    }, NoDeliveryFees)
}

/** Gets the unique dates from all cart items */
export function getUniqueDates(cart: CartItem[]): DateTime[] {
  const allPickups = cart.filter((itm): itm is CartPhysical => isCartPhysical(itm)).flatMap((itm) => itm.pickups)

  return removeObjDuplicates(allPickups, (d) => d.toISODate())
}

/** Determines which of the cartitem's pickup dates will require delivery fees, by matching them against current pickups.
 * @param dates the pickup dates of a physical cart item
 * @param futurePickups the active pickups for the user
 * @param distId the distribution id for the cart item
 * @param address the delivery address of the cart item
 */
export function getDatesWithNewFees(
  dates: CartPhysical['pickups'],
  distId: CartPhysical['distribution']['id'],
  address: NonNullable<NonPickup['address']>,
  futurePickups?: Pickup[],
) {
  let combinedPickups: Pickup[] = [] // The existing Pickups that had a combined date
  const combinedDates: DateTime[] = [] //The item's pickup dates which got combined

  const nonCombinedDates = dates.filter((date) => {
    if (!futurePickups || !futurePickups.length) return true

    /** Filter pickups that would cover the fee */
    const combinedPickupsForCurrDate = futurePickups.filter(
      (p) =>
        !isPickupCancelled(p) &&
        isNonPickup(p.distribution.locationType) &&
        p.date.toISO() === date.toISO() &&
        p.distribution.id === distId &&
        isSameAddress(address, p.distribution.address),
    )
    //If no matching pickups, then this date would require new fees
    const isNewDate = combinedPickupsForCurrDate.length === 0
    if (!isNewDate) combinedDates.push(date)
    if (combinedPickupsForCurrDate.length)
      combinedPickups = removeObjDuplicates(combinedPickups.concat(combinedPickupsForCurrDate))

    return isNewDate
  })

  return { nonCombinedDates, combinedDates, combinedPickups }
}

function baseString(val: string | undefined) {
  const s = typeof val === 'string' ? val : typeof val === 'undefined' ? '' : null
  if (s === null) {
    throw new Error('Address field has a wrong type. Expected string or undefined. Received: ' + typeof val)
  }
  return s.trim().toLowerCase().replace(' ', '')
}

type AddresExceptCoord = PickExcept<Address, 'coordinate'>

/** Compares two addresses except for the coordinates. Should be true if they're practically the same address. */
export function isSameAddress(
  addr1: AddresExceptCoord,
  addr2: AddresExceptCoord,
  onErr?: (err: unknown) => void,
): boolean {
  try {
    return createAddressString(addr1) === createAddressString(addr2)
  } catch (err) {
    onErr?.(err)
    return false
  }
}
