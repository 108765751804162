import { hasOwnProperty, isObject } from '@helpers/helpers'
import { ErrorCode, ErrorWithCodeOpts } from '@shared/Errors'
import { FirebaseError } from 'firebase/app'

/** isFirebaseError returns a true type assertion if the supplied error is a Firebase error. */
export function isFirebaseError(err: unknown): err is FirebaseError {
  return err instanceof FirebaseError
}

/** An structure through which the client can access additional information about the error, which was provided by the server at the time it sent the error */
interface ServerErrorBase extends FirebaseError {
  details?: Record<string, unknown>
}

/** Identifies an error thrown from the server, which include additional custom details for the client */
export function isServerErrorBase(err: unknown): err is ServerErrorBase {
  return isFirebaseError(err) && hasOwnProperty(err, 'details') && isObject(err.details)
}

/** Returns a true type assertion if the supplied error is a Firebase error returned by the server and it contains an internal error code matching the supplied code.
 * @deprecated use isServerErrorWithCode for new development
 */
export function isServerErrorWithCodeLegacy(err: unknown, code: ErrorCode): err is ServerErrorBase {
  if (!isServerErrorBase(err)) {
    return false
  }

  return err.details?.internalCode === code
}

/** isServerErrorWithCode returns true if the supplied error was thrown by the server and is of the type ErrorWithCode.
 * The type is intentionally generic as we don't want to assume anything about the error shape.
 * @param code if a code is provided, it will return true only if the server error code matches this code */
export function isServerErrorWithCode(
  err: unknown,
  code?: string,
): err is ServerErrorBase & { details: ErrorWithCodeOpts } {
  if (!isServerErrorBase(err)) {
    return false
  }
  return err.details?.isErrorWithCode === true && (code ? err.details.code === code : true)
}

/** This identifies an internal error coming **directly** from the firebase client.
 * - The "direct" quality means this is an error coming straight from the 3rd party firebase library, and which we have not yet caught, nor processed, nor rethrown. This helper will be used to identify these raw errors and then convert them to some of our custom app error types for better standardized handling downstream.
 */
export function isFirebaseClientInternalErrorRaw(err: unknown): err is FirebaseError & { code: 'internal' } {
  return isFirebaseError(err) && !!err.code && err.code === 'internal'
}
