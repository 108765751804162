import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { isLabelAccessible } from './helpers/helpers'
import { formStyles, useDynamicFormStyles } from './helpers/styles'
import { FormSharedProps } from './helpers/types'
import { NumberInput, NumberInputProps } from '../MaskedInput/NumberInput'

type FormNumberInputProps = FormSharedProps & NumberInputProps

/** This input component can be used to produce consistently styled number inputs */
export function FormNumberInput(props: FormNumberInputProps) {
  const styles = useDynamicFormStyles(!!props?.errorMessage, !!props.row)

  return (
    <NumberInput
      placeholderTextColor={Colors.shades['200']}
      errorStyle={formStyles.error}
      label={props.label || props.placeholder}
      accessibilityLabel={isLabelAccessible(props.label) ? props.label : props.placeholder}
      labelStyle={formStyles.inputLabel}
      inputStyle={styles.inputStyle}
      inputContainerStyle={styles.inputContainer}
      keyboardType="decimal-pad"
      containerStyle={props.row ? globalStyles.flex1 : {}}
      {...props}
    />
  )
}
