import * as React from 'react'
import { ComponentType, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { globalStyles } from '../constants/Styles'
import { useDeviceSize, useLayout } from '../hooks/useLayout'
import { ColumnSize, containerFlex, getNumCols, ResponsiveList, ResponsiveListProps } from './ResponsiveList'

import { useDeepCompareCallback } from '@/hooks/useDeepEqualEffect'

export type ItemCardListData<CardPropsType, ItemProp extends keyof CardPropsType> = {
  items: CardPropsType[ItemProp][]
  itemProp: ItemProp
  cardProps?: Omit<CardPropsType, ItemProp>
  component: React.ComponentType<CardPropsType>
}

//This will force you to correctly set the data for this component. You must, because it can't do type-checking on the component once you pass it in. You will call the function returned here, and annotate it with the PropTypes of the component, and the name of the prop that receives the data item. The call back can be a useState dispatch function

export function setItemCardListData<T>(callBack: (data: T & ItemCardListData<any, any>) => void) {
  return function <CardPropsType, ItemProp extends keyof CardPropsType>(
    data: T & ItemCardListData<CardPropsType, ItemProp>,
  ) {
    callBack(data)
  }
}

type ItemCardListProps<T, K extends keyof T> = {
  items: T[K][]
  /** The name of the prop in the card component which receives the data item */
  itemProp: K
  /** The props that will be passed to every card */
  cardProps?: Omit<T, K>
  /** The card component to be rendered by the flat list */
  Component: ComponentType<T>
  smallHorizontal?: ResponsiveListProps<T>['smallHorizontal']
  onPress?: (item: any) => void
  columnSize?: ResponsiveListProps<T>['columnSize']
} & Pick<ResponsiveListProps<T>, 'ListEmptyComponent'>

export function ItemCardList<T, K extends keyof T>({
  items,
  itemProp,
  cardProps,
  Component,
  smallHorizontal = true,
  onPress,
  columnSize = ColumnSize.Medium,
}: ItemCardListProps<T, K>) {
  const { size: deviceSize } = useDeviceSize()
  const layout = useLayout()

  const stylesMemo = useMemo(() => {
    return StyleSheet.create({
      cardStyle: {
        ...containerFlex(layout, columnSize),
      },
      justifyContentStyle: {
        //If there's at least two rows, center, else align left
        justifyContent: items.length > getNumCols(deviceSize, columnSize) ? 'center' : 'flex-start',
      },
    })
  }, [deviceSize, columnSize, items.length, layout])

  const renderItem = useDeepCompareCallback(
    (item: T[K]) => (
      <View style={stylesMemo.cardStyle}>
        {/* @ts-ignore */}
        <Component {...{ [itemProp]: item, ...cardProps }} style={stylesMemo.cardStyle} onPress={onPress} />
      </View>
    ),
    // Component and "itemProp" should not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardProps, onPress, stylesMemo.cardStyle],
  )

  return (
    <ResponsiveList
      key={getNumCols(deviceSize, columnSize)} // Key change on numCol change will make it render a new component
      columnSize={columnSize}
      style={styles.listStyle}
      contentContainerStyle={styles.contentContainerStyle}
      data={items}
      smallHorizontal={smallHorizontal}
      columnWrapperStyle={stylesMemo.justifyContentStyle}
      renderItem={renderItem}
    />
  )
}

const styles = StyleSheet.create({
  listStyle: globalStyles.padding10,
  contentContainerStyle: { paddingHorizontal: 15 },
})
