import { loadLocationsByFarm } from '@api/Locations'
import { Location } from '@models/Location'
import { PickupItemStatus } from '@models/Order'
import { SignInSummary } from '@models/SignInSummary'
import * as Clipboard from 'expo-clipboard'

import { DistributionDetailsSummary, DistributionSummaryItem } from '@models/Summary'
import { DateTime } from 'luxon'
import { createContext, useCallback, useContext, useMemo } from 'react'

import { getBQSummary, getSummaryItems, isDistroSummaryItemActive, isDistroSummaryItemSkipped } from './helpers'

import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import { Logger } from '@/config/logger'
import useKeyedState from '@/hooks/useKeyedState'
import { loadInvoicesByOrderIds } from '@api/Invoices'
import { getSignInSummaryShareToken, onUpdateItemStatuses, updateSignInSummary } from '@api/SignInSummaries'
import { farmsCollection, signinSummariesCollection } from '@api/framework/ClientCollections'
import { Alert } from '@elements'
import { deepClone, errorToString } from '@helpers/helpers'
import { Farm } from '@models/Farm'
import { Invoice } from '@models/Invoice'

/**Type of the SignInContext */
type ContextType = ReturnType<typeof useSignInSheetData>

/**Type of the  initial state of `SignInContext` */
type InitialType = {
  /** Farm for the SignIn */
  farm?: Farm

  /** All Farm's location */
  locations?: Location[]

  /** The location for the Signin */
  location?: Location

  /** Summary data which is received from BigQuery */
  summary?: DistributionDetailsSummary

  /** The SignIn summary document, received from signin_summaries collection */
  signInSummary?: SignInSummary

  /** If the data is currently being loaded (by onFetchAllData) */
  dataLoading: boolean

  /** If the data has already been loaded (by onFetchAllData) */
  dataLoaded: boolean
}

/**Initial state of the `SignInContext` */
const signInInitialState: InitialType = {
  location: undefined,
  summary: undefined,
  locations: undefined,
  farm: undefined,
  dataLoading: true,
  dataLoaded: false,
}

const SignInContext = createContext<ContextType>({} as ContextType)

/** Provider for all Electronic SignInSheet screnes */
export function SignInDataProvider({ children }: { children: JSX.Element[] | JSX.Element }) {
  const signInData = useSignInSheetData()
  return <SignInContext.Provider value={signInData}>{children}</SignInContext.Provider>
}

export const useSignInSheetContext = () => useContext(SignInContext)

/**Hook used for creating `SignInContext`
 *
 * - Used for shared data and business logic, used in ElectronicSignInSheet screens.
 */
const useSignInSheetData = () => {
  const [{ farm, locations, location, summary, signInSummary, dataLoading, dataLoaded }, set, setState, setters] =
    useKeyedState(signInInitialState)

  /** Fetches all the necessary data. Used when no data is available (i.e. when refreshing the screen on Web)
   * - Should be used on each screen that depends of the this hook data
   */
  const onFetchAllData = useCallback(
    async (id: string, isCurrent: boolean) => {
      try {
        if (dataLoaded) {
          return
        }

        set('dataLoading', true)
        const signInSummary = await signinSummariesCollection.fetch(id)

        if (!isCurrent) return
        const farm = await farmsCollection.fetch(signInSummary.farm.id)

        if (!isCurrent) return
        const summary = await getBQSummary(farm.id, signInSummary.date, signInSummary.location.id)

        if (!isCurrent) return
        const locations = await loadLocationsByFarm(farm.id)
        const location = locations.find((loc) => loc.id === signInSummary.location.id)

        if (!isCurrent) return
        setState({
          signInSummary,
          location,
          summary,
          farm,
          dataLoading: false,
          dataLoaded: true,
        })
      } catch (err) {
        Logger.error(err)
        Alert('Oops, something went wrong', errorToString(err))
        if (!isCurrent) return
        set('dataLoading', false)
      }
    },
    [dataLoaded, set, setState],
  )

  const items = useMemo(() => {
    return getSummaryItems(summary)
  }, [summary])

  /**Updates item statuses of in the local state */
  const updateLocalStatuses = useCallback(
    (itemIds: string[], orderIds: string[], status: PickupItemStatus) => {
      if (!summary) return
      const updatedItems = [...summary.items].map((summaryItm) => {
        if (!itemIds.includes(summaryItm.pickupItem.id)) return summaryItm
        if (!orderIds.includes(summaryItm.order.id)) return summaryItm
        // Skip item if has vacation / cancelled status
        if (isDistroSummaryItemSkipped(summaryItm)) return summaryItm

        const updatedSelectedUnits = [...summaryItm.item.selectedUnits].map((su) => ({ ...su, status }))
        return { ...summaryItm, item: { ...summaryItm.item, selectedUnits: updatedSelectedUnits } }
      })

      set('summary', { ...summary, items: updatedItems })
    },
    [set, summary],
  )

  /**Trigger that updates local state and firebase data
   * - Extracts necessary data for each update call
   * @param itemsToUpdate - items that will have the status updated
   * @param status - new status value of those items
   */
  const updateApiStatuses = useCallback(
    async (itemsToUpdate: DistributionSummaryItem[], status: PickupItemStatus) => {
      if (!signInSummary) return
      const pickupItemIds = itemsToUpdate.map((itm) => itm.pickupItem.id)
      const pickupIds = itemsToUpdate.map((itm) => itm.pickup.id)
      const orderIds = itemsToUpdate.map((itm) => itm.order.id)
      const originalSummary = deepClone(summary)

      try {
        updateLocalStatuses(pickupItemIds, orderIds, status)
        await onUpdateItemStatuses({
          signInId: signInSummary.id,
          pickupIds,
          pickupItemIds,
          orderIds,
          status,
        })
      } catch (err) {
        Alert(
          'Error updating status',
          'There was an error updating the status. Please check your connection and try again.',
        )
        set('summary', originalSummary)
        Logger.error(err)
      }
    },
    [set, signInSummary, summary, updateLocalStatuses],
  )

  /**Updates the `status` of one specific item
   * - Used on swipe action for specific item
   */
  const onUpdateStatusByItem = useCallback(
    async (item: DistributionSummaryItem, status: PickupItemStatus) => {
      const filtered = [item]
      await updateApiStatuses(filtered, status)
    },
    [updateApiStatuses],
  )

  /**Updates all the items for a specific user
   * - Used on swipe action for a user card
   */
  const onUpdateStatusByUser = useCallback(
    async (userId: string, status: PickupItemStatus) => {
      if (!summary) return
      const filtered = summary.items.filter((itm) => itm.user.id === userId)
      await updateApiStatuses(filtered, status)
    },
    [summary, updateApiStatuses],
  )

  /**Updates all the items from a SignInSummary which have not been completed (with `active` status) */
  const onUpdateUnpickedItems = useCallback(
    async (status: PickupItemStatus.Missed | PickupItemStatus.Donated) => {
      if (!summary) return
      const filtered = summary.items.filter(isDistroSummaryItemActive)
      await updateApiStatuses(filtered, status)
    },
    [summary, updateApiStatuses],
  )

  /**Updates all the items that belong to a specific order*/
  const onUpdateOrderItemsStatus = useCallback(
    async (orderId: string, status: PickupItemStatus) => {
      if (!summary) return
      const filtered = summary.items.filter((itm) => itm.order.id === orderId)
      await updateApiStatuses(filtered, status)
    },
    [summary, updateApiStatuses],
  )

  /**Saves the `notes` field on the backend */
  const onSaveNotes = useCallback(
    async (value: string) => {
      try {
        if (!signInSummary) return
        await updateSignInSummary({ id: signInSummary.id, notes: value })
        set('signInSummary', { ...signInSummary, notes: value })
      } catch (err) {
        Logger.error(err)
        Alert('Something went wrong', errorToString(err))
      }
    },
    [set, signInSummary],
  )

  /**Marks the SignInSummary as `ended` */
  const onFinishSignIn = useCallback(async () => {
    try {
      if (!signInSummary) return
      await updateSignInSummary({ id: signInSummary.id, status: 'ended', endDate: DateTime.now() })
    } catch (err) {
      Logger.error(err)
      throw err
    }
  }, [signInSummary])

  const onShareSignIn = useCallback(async () => {
    try {
      if (!signInSummary) return
      const token = await getSignInSummaryShareToken(signInSummary)
      const url = `${grownbyWebsiteBaseUrl()}sign-in-sheet/electronic-signin-sheet/${signInSummary.id}?token=${token}`
      await Clipboard.setStringAsync(url)
    } catch (err) {
      Logger.error(err)
      Alert('Error Sharing Signin', errorToString(err))
    }
  }, [signInSummary])

  /**Loads `invoices` for a specific user, by extracting the `orderIds` from the
   * `summary items`, for that specific user
   */
  const getUserInvoices = useCallback(
    async (userId: string): Promise<Invoice[]> => {
      try {
        if (!summary || !farm) return []
        const filtered = summary.items.filter((itm) => itm.user.id === userId)
        const orderIds = filtered.map((itm) => itm.order.id)
        const res = await loadInvoicesByOrderIds(orderIds, farm.id)
        return res
      } catch (err) {
        Logger.error(err)
        throw err
      }
    },
    [farm, summary],
  )

  return {
    setters,
    signInSummary,
    set,
    locations,
    location,
    summary,
    dataLoading,
    items,
    farm,
    onUpdateStatusByUser,
    onUpdateStatusByItem,
    onUpdateUnpickedItems,
    onUpdateOrderItemsStatus,
    onSaveNotes,
    onFinishSignIn,
    onShareSignIn,
    getUserInvoices,
    onFetchAllData,
    setState,
  }
}
