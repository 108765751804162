import { Farm, FarmStatus } from '@models/Farm'
import { User, userName } from '@models/User'
import { limit, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

import { functions } from './db'
import { farmsCollection, usersCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'
import { Features } from '@models/Features'
import { ZohoCRMLeadData } from '@shared/types/v2/zoho'

/** searchUserByEmail returns the user identified by the supplied email address. */
export async function searchUserByEmail(email: string): Promise<User[]> {
  return usersCollection.fetchAll(where('email', '>=', email.toLowerCase()), limit(5))
}

/** loadFarmByName returns the farm identified by the supplied name. */
export async function searchFarmByName(name: string): Promise<Farm[]> {
  return farmsCollection.fetchAll(where('nameUppercase', '>=', name.toUpperCase()), limit(5))
}

/**
 * Allows Farm Generations Admin team to make select changes to a farm from the admin panel
 * @param farmId the farmId to make the changes for
 * @param properties the properties to change
 */
export async function updateFarmProperty(
  farmId: string,
  properties: { status?: FarmStatus; memberId?: string; feePercent?: number; enableFeatureFlag?: Features },
) {
  return await callEndpoint('v2.FarmGenAdmin.updateFarmDetails', { farmId, ...properties })
}

/** Will generate a URL for the admin user to login as (impersonate) any GrownBy user. */
export async function loginAsUser(userId: string) {
  const res = await httpsCallable<unknown, string>(functions(), 'FarmGenAdmin-loginAsUser')({ userId })
  return res.data
}
/**
 * Will toggle the user as enabled or disabled
 * @param userId the user to toggle
 * @param disabled the new status of the user
 */
export async function toggleDisableUser(userId: string, disabled: boolean) {
  const res = await httpsCallable(functions(), 'FarmGenAdmin-updateAuthUser')({ userId, data: { disabled } })
  return res.data
}

/**
 * Will add a new lead to Zoho CRM saying this farm was invited to our platform by a customer
 * @param farm The farm we are adding as a lead
 * @param user The user who invited the farm, can be undefined
 * @param message An optional message the user left for the farm
 */
export async function addNewLead(farm: Farm, user?: User, message?: string) {
  const customMessage = message ? ` They added a message to the farm saying: "${message}"` : ''
  const userString = user ? `by the customer ${userName(user)} (${user.email})` : 'by an unknown user.'
  const lead: ZohoCRMLeadData = {
    data: [
      {
        Company: farm.name,
        Email: farm.email,
        Last_Name: '.',
        Lead_Source: 'Referral',
        Lead_Status: 'Warm',
        State: farm.address.state,
        Website: `https://grownby.app/farms/${farm.urlSafeSlug}`,
        Description: `This farm was invited to GrownBy ${userString}.${customMessage}`,
      },
    ],
  }
  return await callEndpoint('v2.Zoho.addNewZohoLead', { lead })
}
