import { EbtIcon, getCartItemForAddCartBtn, makeTestIdPrefix } from '@components'
import { Text } from '@elements'
import { formatMoney, getPrice } from '@helpers/display'
import { getPaymentSchedules } from '@helpers/order'
import { getProductAvailability, getUnits } from '@helpers/products'
import { formatDateRange } from '@helpers/time'
import { PaymentSchedule, Product, Share, Unit, UnitProduct, hasUnits, isShare } from '@models/Product'
import { memo, useCallback, useContext, useMemo, useState } from 'react'

import { useFocusFx } from '../../../../hooks/useFocusFx'
import { ExpandableRow, ExpandableRowProps } from '../../../components/OfflineTable/ExpandableRow'
import { CreateOrderScreenContext } from '../CreateOrderScreen'
import { AddBtnShare, AddBtnStandard } from './CreateOrderAddCart'
import { PayScheduleSelector } from './PayScheduleSelector'

import { useCartService } from '@/hooks/useCart'
import { canUpdateQuantity, findItemInCart } from '@helpers/canUpdateQuantity'

export type ShareRowProps = {
  title: string
  prod: Product
  index: number
}
/** Row component for share products of the order creator table  */
export const ShareRow = memo(function ShareRow({ title, prod, index }: ShareRowProps) {
  const [{ distro, csa }] = useContext(CreateOrderScreenContext)
  const cartSrv = useCartService(true)
  const cartItem = useMemo(() => getCartItemForAddCartBtn(prod, cartSrv.cart), [prod, cartSrv.cart])
  const [paySchedule, setPaySchedule] = useState<PaymentSchedule>()

  //We need to reset the component on hitId change, because the table will swap the hitId
  //when recycling the same component instance on a new data point
  useFocusFx(() => {
    /**Although this is expected to receive a share, algolia sometimes returns different data for a split second, before
     * the results reflect the actual filters. In that flash of a second, this component might receive a standard product instead of a share. If it does, the getPaymentSchedules will throw an error if it receives a standard product because
     * it expects a unit price. So this should prevent this fx from running on any non-share product
     */
    if (!isShare(prod)) return
    setPaySchedule(getPaymentSchedules({ product: prod })[0])
    //eslint thinks prod isn't necessary in this hook, but we know it needs to reset on prod change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prod])

  const cols = useMemo<ExpandableRowProps<Share>['columns']>(
    () => [
      { process: (itm) => itm.name, widthFlex: 2 },
      { process: (itm) => <PayScheduleSelector product={itm} setter={setPaySchedule} paySchedule={paySchedule} /> },
      { process: (itm) => itm.type },
      {
        process: (itm) => {
          const avail = getProductAvailability(itm, distro, {
            excludeHiddenDistros: true,
          })
          return avail ? formatDateRange(avail, 'MM/dd/yy') : 'Bad data'
        },
      },
      { process: () => (paySchedule ? formatMoney(paySchedule.amount) : '-') },
      {
        widthFlex: 2,
        process: (itm) => (
          <AddBtnShare
            prod={itm}
            cartItem={cartItem}
            distro={distro}
            csa={csa}
            title={title}
            paySchedule={paySchedule}
          />
        ),
      },
    ],
    [cartItem, csa, distro, paySchedule, title],
  )
  if (!isShare(prod)) return null

  return <ExpandableRow item={prod} index={index} columns={cols} />
})

export type StandardRowProps = {
  prod: Product
  index: number
}

/** Row component for standard products of the order creator table */
export const StandardRow = memo(function StandardRow({ prod: p, index }: StandardRowProps) {
  const [{ distro, pickupDate }] = useContext(CreateOrderScreenContext)
  const cartSrv = useCartService(true)

  const canAddUnit = useCallback(
    (product: Product, unit: Unit) => {
      const cartItem = findItemInCart({ product, unit }, cartSrv.cart)
      return cartItem
        ? canUpdateQuantity({ cartItem, cart: cartSrv.cart, delta: 1 })
        : canUpdateQuantity({ cartItem: { product, quantity: 1, unit }, cart: cartSrv.cart })
    },
    [cartSrv.cart],
  )

  const filteredUnits = useMemo(() => getUnits(p)?.filter((u) => canAddUnit(p, u)) || [], [p, canAddUnit])

  const genSubRows = useCallback<NonNullable<ExpandableRowProps<UnitProduct>['generateSubRows']>>(
    (p) => {
      return filteredUnits.length > 1
        ? filteredUnits.map((u, i) => (
            <ExpandableRow<Unit>
              item={u}
              columns={[
                { process: () => u.name, widthFlex: 2 },
                { process: () => formatMoney(u.prices[0].amount) },
                { process: () => 'x' + u.multiplier },
                {
                  process: () => (
                    <AddBtnStandard
                      prod={p}
                      unit={u}
                      distro={distro}
                      pickupDate={pickupDate}
                      key={makeTestIdPrefix(p, u)}
                    />
                  ),
                },
              ]}
              indent={30}
              index={i}
              key={`subRow-p.name:${p.name}_p.id:${p.id}_unit:${u.id}`}
            />
          ))
        : undefined
    },
    [filteredUnits, distro, pickupDate],
  )

  const cols = useMemo<ExpandableRowProps<UnitProduct>['columns']>(
    () => [
      {
        process: (product: Product) => (
          <Text>
            <EbtIcon product={product} /> {product.name}
          </Text>
        ),
        widthFlex: 2,
      },
      {
        process: (p) => (p.units.length === 1 ? getPrice(p, p.units[0].prices[0]) : getPrice(p)),
      },
      { process: (p) => p.baseUnit },
      {
        process: (p) => (
          <AddBtnStandard
            prod={p}
            distro={distro}
            pickupDate={pickupDate}
            key={makeTestIdPrefix(p)}
            /*We only want to add the unit if there is just one. Otherwise, the expandable row will handle units*/
            unit={filteredUnits.length === 1 ? filteredUnits[0] : undefined}
          />
        ),
      },
    ],
    [distro, pickupDate, filteredUnits],
  )

  if (!hasUnits(p)) return null

  return <ExpandableRow item={p} index={index} columns={cols} generateSubRows={genSubRows} expandInitially />
})
