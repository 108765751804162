import { Features } from '@models/Features'
import { hasGrownByPrivilege, UserRole } from '@models/User'

import { callEndpoint } from './v2'

/**
 * loadFeatures returns the features enabled globally, with consideration for the optional farm.
 * @param farmId the optional farm to load permissions for
 * @param userRole the role of the logged-in user, will determine if they have unique features enabled
 */
// TODO: This should be optimized with caching, this is called 10 times from the home screen alone
export async function loadFeatures(farmId?: string, userRole?: UserRole): Promise<Record<Features, boolean>> {
  const { features } = await callEndpoint('v2.FeatureFlag.featuresAvailableService', { farmId })

  // FIXME: This should only be active during EBT testing
  // If this is an ebt enabled user then we allow it regardless of the feature flag state
  features.ebt = isEbtEnabledUser(userRole) ? true : features.ebt

  return features
}

// This is a temporary function to use during the duration of EBT testing

export function isEbtEnabledUser(role?: UserRole) {
  return hasGrownByPrivilege({ role }, UserRole.EbtTester)
}
