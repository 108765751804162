import { AlgoliaGeoDoc, AlgoliaGeoProduct, isGeoProduct } from '@models/Algolia'
import { Distribution } from '@models/Distribution'
import { AddonShare, isAddon, Product, ProductType, Unit } from '@models/Product'
import { Frequency, isWiderFreq } from '@models/Schedule'
import { User } from '@models/User'
import { DateTime } from 'luxon'
import { getDayofWeekName } from './display'
import jaro_winkler from './jaro_winkler'
import { MoneyCalc } from './money'
import { isInStock } from './products'
import { PartialExcept } from './typescript'

export function sortByEarliest<T extends { [key: string]: any }>(attr: keyof T) {
  return (a: T, b: T) => a[attr].toMillis() - b[attr].toMillis()
}

export function sortByLatest<T extends { [key: string]: any }, K extends keyof T>(attr: K) {
  return (a: T, b: T) => b[attr].toMillis() - a[attr].toMillis()
}

export function sortByUserEmail<T extends { user: PartialExcept<User, 'email'> }>(a: T, b: T) {
  if (a.user.email.toLowerCase() > b.user.email.toLowerCase()) return -1
  if (a.user.email.toLowerCase() < b.user.email.toLowerCase()) return 1
  return 0
}

export function sortByName<T>(a: T, b: T, getName?: (obj: T) => string): 0 | 1 | -1
/** Sorts two items by name.
 * @param getName will obtain the string to be used as name.
 */
export function sortByName<T extends { name: string }>(a: T, b: T, getName?: (obj: object) => string) {
  if ((getName ? getName(a) : a.name.toLowerCase()) < (getName ? getName(b) : b.name.toLowerCase())) return -1
  if ((getName ? getName(a) : a.name.toLowerCase()) > (getName ? getName(b) : b.name.toLowerCase())) return 1
  return 0
}

export function sortByOrderNum<T extends { orderNum: number }>(a: T, b: T) {
  if (a.orderNum > b.orderNum) return -1
  if (a.orderNum < b.orderNum) return 1
  return 0
}

export function sortByRankAndName<T extends { name: string; rankOrder?: number }>(a: T, b: T) {
  // If one item has a higher rank then sort on that
  if ((a.rankOrder || Infinity) < (b.rankOrder || Infinity)) return -1
  if ((a.rankOrder || Infinity) > (b.rankOrder || Infinity)) return 1

  // If not sort by name
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  return 0
}

export const sortDistrosByName = (a: Distribution, b: Distribution) => {
  const aName = a.name || a.location.name
  const bName = b.name || b.location.name

  if (aName.toLowerCase() < bName.toLowerCase()) return -1
  if (aName.toLowerCase() > bName.toLowerCase()) return 1

  return 0
}

export function sortByIncluded(arr: string[], attr = 'id') {
  return (a: any, b: any) => {
    const a_incl = arr.includes(a[attr]) ? 1 : -1
    const b_incl = arr.includes(b[attr]) ? 1 : -1
    return a_incl > b_incl ? -1 : 1
  }
}

// Will sort a list putting items that match the constraint first

export function sortByConstraint<T>(constraint: (val: T) => boolean) {
  return (a: T, b: T) => {
    const a_incl = constraint(a) ? 1 : -1
    const b_incl = constraint(b) ? 1 : -1
    return a_incl > b_incl ? -1 : 1
  }
}

export function sortByAmount(getter: (item: any) => number) {
  return (a: any, b: any) => {
    if (getter(a) > getter(b)) return 1
    if (getter(a) < getter(b)) return -1
    return 0
  }
}

/**
 * @description Takes an array of properties and a property-getter function, and returns a function to sort the actual objects by the order of properties.
 *
 * @param arr string[] These properties must come sorted in the order desired for the items T.
 * @param getter Function of object T, that returns one of the properties in arr.
 *
 * @returns Function of object T, which returns a number: 1, -1 or 0. To be used in [].sort()
 */
export function sortByProperty<T>(arr: string[], getter: (item: T) => string | undefined) {
  const getSortOrder = (val: T) => {
    // Will get the index of the sort item in the array of properties
    const sortIdx = arr.findIndex((v) => getter(val) === v)

    //If it does not exist, we will use the arr.length which is greater than all priorities
    return sortIdx === -1 ? arr.length : sortIdx
  }
  return (a: T, b: T) => {
    //Get the sorting order of each item
    const a_val = getSortOrder(a)
    const b_val = getSortOrder(b)

    //If the property of the items exists in the array, sort them by the order value
    if (a_val > b_val) return 1
    if (a_val < b_val) return -1
    return 0
  }
}

//id refers to the cartitem id which is supposed to concatenate the product id with the distro id
//this type will work for both CartItem(units) and OrderItem(unit)
type SortItemT = {
  product: Pick<Product, 'name' | 'type'> & { units?: Unit[] }
  id: string
  unit?: Unit | null
}

/** Returns a string suitable for reliable alphabetical sorting of cart items and order items. Concatenates product name, unit (or default), and item id.*/
function getSortableItemName(i: SortItemT) {
  let name = i.product.name
  name += `_${(i.unit ?? i.unit ?? i.product.units?.[0])?.name ?? 'single-unit'}_`
  return name + i.id
}

/** This is used to sort CartItems and OrderItems.
The `SortItemT` type will be compatible with both item types*/
export function sortCartItems(a: SortItemT, b: SortItemT) {
  //Try to sort by product type
  const propSort = sortByProperty(
    [ProductType.PrimaryShare, ProductType.AddonShare, ProductType.Standard],
    (item: SortItemT) => item.product.type,
  )
  const propSortRes = propSort(a, b)
  if (propSortRes !== 0) return propSortRes
  //If same type, sort by name
  return sortByName(a, b, getSortableItemName)
}

/**
 * Provided a search string, will sort an array of strings by greater to lesser similarity
 */
export const fuzzySortJaroWinkler = (search: string) => (a: string, b: string) => {
  const a_score = jaro_winkler.distance(a, search)
  const b_score = jaro_winkler.distance(b, search)
  return a_score > b_score ? -1 : 1
}
/** Sort unit products by unit price */
export const sortByUnitPrice = (a: Unit, b: Unit): number => {
  return MoneyCalc.isLessThan(a.prices[0].amount, b.prices[0].amount) ? -1 : 1
}

/** Sorts product units by ascending multiplier amount, and then by alphabetic name */
export const sortUnits = (a: Unit, b: Unit): number => {
  return a.multiplier < b.multiplier ? -1 : a.multiplier > b.multiplier ? 1 : sortByUnitPrice(a, b)
}

export const sortByValue = <T extends Record<any, any>>(getter: (obj: T) => any) => {
  return (a: T, b: T): number => {
    const aVal = getter(a)
    const bVal = getter(b)
    return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
  }
}

export const randomSort = (): number => {
  const float = Math.random()
  return float > 0.5 ? 1 : float < 0.5 ? -1 : 0
}

export const sortDistrosByLocationAndName = (a: Distribution, b: Distribution): number => {
  //If they have the same location name, sort by schedule name.
  //Else, sort by location name
  if (a.location.name === b.location.name) return sortByName(a, b)
  return sortByValue((d) => d.location.name)(a, b)
}

/** Sorts schedules by increasing frequency */
export function sortSchedulesByFreq(a: Distribution, b: Distribution) {
  if (isWiderFreq(b.schedule.frequency, a.schedule.frequency)) return -1
  if (isWiderFreq(a.schedule.frequency, b.schedule.frequency)) return 1
  return 0
}

/** Sunday-Saturday sorting for schedules */
export function sortSchedulesByDayofWeek(a: Distribution, b: Distribution) {
  const dowNameA = getDayofWeekName(a.schedule.dayOfWeek)
  const dowNameB = getDayofWeekName(b.schedule.dayOfWeek)

  const daysOfWeekFromSunday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const indexA = daysOfWeekFromSunday.findIndex((name) => name === dowNameA)
  const indexB = daysOfWeekFromSunday.findIndex((name) => name === dowNameB)

  return indexA < indexB ? -1 : indexA > indexB ? 1 : 0
}

/** Sorts schedules by the following rules:
 * 1. Daily
 * 2. Sunday-Saturday (for example: Sunday, Weekly; Monday, Weekly; Saturday, Weekly)
 * 3. If 2 schedules on same day, list by Most frequent-Least Frequent (Weekly, Bi-weekly, Monthly)
 */
export function sortSchedulesByDailyAndDayOfWeek(a: Distribution, b: Distribution) {
  // daily schedules go first, regardless of any other criteria
  if (a.schedule.frequency === Frequency.DAILY || b.schedule.frequency === Frequency.DAILY) {
    const freqSortDailyFirstRule = sortSchedulesByFreq(a, b)
    if (freqSortDailyFirstRule !== 0) return freqSortDailyFirstRule
  }

  // day of week is next criteria
  const dowSort = sortSchedulesByDayofWeek(a, b)
  if (dowSort !== 0) return dowSort

  // general frequency sort is last criteria (weekly, bi, monthly)
  const freqSort = sortSchedulesByFreq(a, b)
  if (freqSort !== 0) return freqSort

  // highly unlikely, but if everything else where similar, name will be the default
  return sortDistrosByName(a, b)
}

/**
 * Sorts products by the following rules:
 * 1. featured and available products go first, sorted A-Z if same
 * 2. available but not featured products go next, sorted A-Z if same
 * 3. all other unavailable products go last, sorted A-Z if same
 */
export function getSortAlgoliaProducts(availAddonIds: string[]) {
  const DateNowInMs = DateTime.now().toMillis()

  /** Finds whether a product is available for sorting purposes. To be considered available it must be both in stock and have a last available stamp greater than now */
  const isAvailable = (p: AlgoliaGeoDoc<AlgoliaGeoProduct> | AddonShare) => {
    if (isGeoProduct(p)) {
      return p.isInStock && p.lastAvailStamp >= DateNowInMs
    } else {
      const prodIsInStock = isInStock(p)

      /** FIXME: This is the correct logic for this purpose, however it slows the app down because the lastAvailStamp calculation depends on getPickups. The solution is to add the lastAvailStamp to the product model, which I've been saying for a long time, is necessary to optimize the app for things like this, which require calls to getPickups.
       *
       * The temporary solution is: Since we know these db products are only avail addons, we can assume they are available. This is not an ideal long term configuration, but it's good for now.
       */
      // const lastAvail = getLastAvailTimestamp(p, true)
      // const isAvail = lastAvail && lastAvail.toMillis() >= DateNowInMs
      // return prodIsInStock && isAvail

      return prodIsInStock
    }
  }

  return function sortAlgoliaProdsFn(
    a: AlgoliaGeoDoc<AlgoliaGeoProduct> | AddonShare,
    b: AlgoliaGeoDoc<AlgoliaGeoProduct> | AddonShare,
  ): -1 | 0 | 1 {
    const AisAvailable = isAvailable(a)
    const BisAvailable = isAvailable(b)
    const AisFeatured = a.isFeatured
    const BisFeatured = b.isFeatured
    const AisUnavailAddon = isAddon(a) && !availAddonIds.includes(a.id)
    const BisUnavailAddon = isAddon(b) && !availAddonIds.includes(b.id)

    const AisBoth = AisAvailable && AisFeatured
    const BisBoth = BisAvailable && BisFeatured
    const AisNone = !AisAvailable && !AisFeatured
    const BisNone = !BisAvailable && !BisFeatured

    // Unavail Addon criteria: This criteria applies only if there's an unavailable addon
    if (AisUnavailAddon || BisUnavailAddon) {
      // If one is an unavail addon, it should go last
      // This check is outside the main criteria because it should not exclude the rest of the logic from being checked
      if (BisUnavailAddon && !AisUnavailAddon) return -1
      if (AisUnavailAddon && !BisUnavailAddon) return 1

      // If these conditions did not apply because both were unavail addons, the default criteria will apply
    }

    // Main criteria: These criteria are applicable to all products
    if (AisBoth) {
      if (BisBoth) return sortByName(a, b)
      else return -1
    } else if (AisNone) {
      if (BisNone) return sortByName(a, b)
      else return 1
    } else {
      // A is only either available or featured but not both
      if (BisBoth) return 1
      else if (BisNone) return -1
      // Both A and B are either available or featured but not both
      else if (AisAvailable && BisFeatured) return -1 // available but not featured next
      else if (BisAvailable && AisFeatured) return 1
      // Both are the same
      return sortByName(a, b)
    }
  }
}
