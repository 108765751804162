import { createStackNavigator } from '@react-navigation/stack'

import { ProductType } from '@models/Product'
import { CategoriesScreen } from '@screens/Categories/CategoriesScreen'
import { ProducersScreen } from '@screens/Producers/ProducersScreen'
import ShareTemplatesScreen from '@screens/ShareTemplates/ShareTemplatesScreen'
import { CouponDetails } from '../screens/Coupons/CouponDetails'
import { CouponList } from '../screens/Coupons/CouponList'
import { CreateOrderScreen } from '../screens/CreateOrderScreen/CreateOrderScreen'
import { CSADetailScreen } from '../screens/CSAs/CSADetailScreen'
import CSAGroupScreen from '../screens/CSAs/CSAGroupScreen'
import AdminCustomerDetailsScreen from '../screens/Customer/AdminCustomerDetailsScreen'
import AdminCustomerScreen from '../screens/Customer/AdminCustomerScreen'
import DistributionSummaryScreen from '../screens/Distributions/DistributionSummaryScreen'
import { LocationSummaryScreen } from '../screens/Distributions/ElectronicSignInSheet/LocationSummaryScreen'
import { SignInSheetSelectorScreen } from '../screens/Distributions/ElectronicSignInSheet/SignInSheetSelectorScreen'
import AddDeliveryShippingScreen from '../screens/LocationsAndZones/AddDeliveryShippingScreen'
import AddLocationScreen from '../screens/LocationsAndZones/AddLocationScreen'
import LocationScreen from '../screens/LocationsAndZones/LocationScreen'
import AdminOrderDetailsScreen from '../screens/Order/AdminOrderDetails'
import AdminOrdersScreen from '../screens/Order/AdminOrdersScreen'
import { AdminProductDetailsScreen } from '../screens/Products/AdminProductDetailsScreen'
import { AdminProductListScreen } from '../screens/Products/AdminProductListScreen/AdminProductListScreen'
import AdminViewProductScreen from '../screens/Products/AdminViewProductScreen'
import TemplateDetailScreen from '../screens/Products/Templates/TemplateDetailScreen'
import { DistributionDetailScreen } from '../screens/Schedules/DistributionDetailScreen'
import DistributionScreen from '../screens/Schedules/DistributionScreen'
import {
  AdminOrdersParamList,
  AdminProductsParamList,
  CouponsParamList,
  CSAParamList,
  CustomerParamList,
  DistributionSchedulesParamList,
  DistributionSummaryParamList,
  LocationsAndZonesParamList,
} from './types'

//LocationsAndZonesStack - Locations&Zones Screen
const LocationsAndZonesStack = createStackNavigator<LocationsAndZonesParamList>()

export function LocationsAndZonesNavigator() {
  return (
    <LocationsAndZonesStack.Navigator
      initialRouteName="Locations"
      screenOptions={{
        headerShown: false,
      }}
    >
      <LocationsAndZonesStack.Screen name="Locations" component={LocationScreen} />
      <LocationsAndZonesStack.Screen name="AddLocation" component={AddLocationScreen} />
      <LocationsAndZonesStack.Screen name="EditLocation" component={AddLocationScreen} />
      <LocationsAndZonesStack.Screen name="AddDeliveryShipping" component={AddDeliveryShippingScreen} />
      <LocationsAndZonesStack.Screen name="EditDeliveryShipping" component={AddDeliveryShippingScreen} />
    </LocationsAndZonesStack.Navigator>
  )
}

//DistributionSchedulesStack - Schedule Screen
const DistributionSchedulesStack = createStackNavigator<DistributionSchedulesParamList>()

export function DistributionSchedulesNavigator() {
  return (
    <DistributionSchedulesStack.Navigator
      initialRouteName="Schedules"
      screenOptions={{
        headerShown: false,
      }}
    >
      <DistributionSchedulesStack.Screen name="Schedules" component={DistributionScreen} />
      <DistributionSchedulesStack.Screen name="AddDistribution" component={DistributionDetailScreen} />
      <DistributionSchedulesStack.Screen name="EditDistribution" component={DistributionDetailScreen} />
    </DistributionSchedulesStack.Navigator>
  )
}

const CustomerStack = createStackNavigator<CustomerParamList>()

export function CustomerNavigator() {
  return (
    <CustomerStack.Navigator
      initialRouteName="CustomerList"
      screenOptions={{
        headerShown: false,
      }}
    >
      <CustomerStack.Screen name="CustomerList" component={AdminCustomerScreen} />
      <CustomerStack.Screen name="CustomerDetails" component={AdminCustomerDetailsScreen} />
      <AdminOrdersStack.Screen name="AdminOrderDetails" component={AdminOrderDetailsScreen} />
    </CustomerStack.Navigator>
  )
}

const AdminOrdersStack = createStackNavigator<AdminOrdersParamList>()

export function AdminOrdersNavigator() {
  return (
    <AdminOrdersStack.Navigator initialRouteName="AdminOrderList" screenOptions={{ headerShown: false }}>
      <AdminOrdersStack.Screen name="AdminOrderList" component={AdminOrdersScreen} />
      <AdminOrdersStack.Screen name="AdminOrderDetails" component={AdminOrderDetailsScreen} />
      <AdminOrdersStack.Screen
        name="CreateOrder"
        component={CreateOrderScreen}
        initialParams={{ orderType: 'standard', goBack: 'orders' }}
      />
    </AdminOrdersStack.Navigator>
  )
}

const CSAStack = createStackNavigator<CSAParamList>()

export function CSAStackNav() {
  return (
    <CSAStack.Navigator screenOptions={{ headerShown: false }}>
      <CSAStack.Screen name="CSAGroup" options={{ headerTitle: 'CSA' }} component={CSAGroupScreen} />
      <CSAStack.Screen name="CSADetail" options={{ headerTitle: 'Add CSA' }} component={CSADetailScreen} />
    </CSAStack.Navigator>
  )
}

const ProductStack = createStackNavigator<AdminProductsParamList>()

/** Navigator that handles all product-related admin screens */
export function AdminProducts() {
  return (
    <ProductStack.Navigator screenOptions={{ headerShown: false }}>
      <ProductStack.Screen name="ProductList" component={AdminProductListScreen} />
      <ProductStack.Screen
        name="AddProduct"
        component={AdminProductDetailsScreen}
        initialParams={{ type: ProductType.Standard }}
      />
      <ProductStack.Screen name="EditProduct" component={AdminProductDetailsScreen} />
      <ProductStack.Screen name="ViewProduct" component={AdminViewProductScreen} />
      <ProductStack.Screen name="AddTemplate" component={TemplateDetailScreen} />
      <ProductStack.Screen name="EditTemplate" component={TemplateDetailScreen} />
      <ProductStack.Screen name="ShareTemplates" component={ShareTemplatesScreen} />
      <ProductStack.Screen name="Categories" component={CategoriesScreen} />
      <ProductStack.Screen name="Producers" component={ProducersScreen} />
    </ProductStack.Navigator>
  )
}

const CouponsStack = createStackNavigator<CouponsParamList>()

export function AdminCouponsNavigator() {
  return (
    <CouponsStack.Navigator screenOptions={{ headerShown: false }}>
      <CouponsStack.Screen name="CouponList" options={{ headerTitle: 'Promotions' }} component={CouponList} />
      <CouponsStack.Screen name="CouponDetails" options={{ headerTitle: 'Coupon Details' }} component={CouponDetails} />
    </CouponsStack.Navigator>
  )
}

const DistributionSummaryStack = createStackNavigator<DistributionSummaryParamList>()

export function AdminDistributionSummaryNavigator() {
  return (
    <DistributionSummaryStack.Navigator screenOptions={{ headerShown: false }}>
      <DistributionSummaryStack.Screen name="DistributionSummary" component={DistributionSummaryScreen} />
      <DistributionSummaryStack.Screen name="SignInSheetSelector" component={SignInSheetSelectorScreen} />
      <DistributionSummaryStack.Screen name="LocationSummary" component={LocationSummaryScreen} />
    </DistributionSummaryStack.Navigator>
  )
}
