import { Image } from '@components'
import { Button, HeaderText, Icon, Text, TextH1, Touchable } from '@elements'
import { openUrl } from '@helpers/client'
import { ProductType, TemplateType } from '@models/Product'
import { useEffect, useState } from 'react'
import { ImageSourcePropType, ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { adminFarmIdSelector } from '@/redux/selectors'
import { loadProductsByFarmAndType } from '@api/Products'

type ModalDataType = {
  [key: string]: {
    title: string
    options: { title: string; type: string; icon: ImageSourcePropType; description?: string; bottomText?: string }[]
  }
}
const modalData: ModalDataType = {
  type: {
    title: 'What kind of product do you want to create?',
    options: [
      {
        title: 'Standard or Digital product',
        type: 'StandardDigital',
        icon: require('../../../assets/productIcons/spAndDigitalGroup.png'),
      },
      {
        title: 'Subscription or CSA Share',
        type: 'SubscriptionCSA',
        icon: require('../../../assets/productIcons/subAndCsaGroup.png'),
      },
    ],
  },
  StandardDigital: {
    title: 'Nice! Choose the type of product.',

    options: [
      {
        title: 'Standard Product',
        type: ProductType.Standard,
        icon: require('../../../assets/productIcons/standardProductIcon.png'),
        description: 'Standard Products are sold for one or more pickups or deliveries.',
        bottomText: 'ie. carrots sold by the bunch',
      },
      {
        title: 'Digital-Only Product',
        type: ProductType.Digital,
        icon: require('../../../assets/productIcons/digitalProductIcon.png'),
        description: 'Digital products do not have associated pickups.',
        bottomText: 'ie. farm donation',
      },
      {
        title: 'Farm credit',
        type: ProductType.FarmBalance,
        icon: require('../../../assets/productIcons/farmCreditIcon.png'),
        description: 'Money that customers can use to purchase goods from your farm shop',
      },
    ],
  },
  SubscriptionCSA: {
    title: 'Nice! Choose the type of subscription.',

    options: [
      {
        title: 'Primary Share',
        type: ProductType.PrimaryShare,
        icon: require('../../../assets/productIcons/standardProductIcon.png'),
        description: 'A primary share is required for the purchase of an add-on share.',
        bottomText: 'ie. Fall Vegetable Share',
      },
      {
        title: 'Add-on Share',
        type: ProductType.AddonShare,
        icon: require('../../../assets/productIcons/addonIcon.png'),
        description: 'Items that can be added onto a primary share order.',
        bottomText: 'ie. A dozen eggs',
      },
      {
        title: 'Share collection template',
        type: TemplateType,
        icon: require('../../../assets/productIcons/shareCollectionIcon.png'),
        description: 'A share collection template allows selling the same share to different CSA Groups.',
      },
    ],
  },
}

function Header({ onBackPress }: { onBackPress: () => void }) {
  return (
    <View style={styles.headerCont}>
      <Touchable onPress={onBackPress} style={styles.row}>
        <Icon color={Colors.black} name="chevron-left" />
        <TextH1 style={{ marginLeft: 10 }} size={18}>
          Product type
        </TextH1>
      </Touchable>
    </View>
  )
}

function Footer({ phase }: { phase: PhaseType }) {
  const url =
    phase === 'SubscriptionCSA'
      ? 'https://grownby.zohodesk.com/portal/en/kb/articles/all-about-adding-a-share'
      : 'https://grownby.zohodesk.com/portal/en/kb/articles/all-about-standard-products'

  const text = phase === 'SubscriptionCSA' ? 'Learn more about subscriptions' : 'Learn more about products'

  return (
    <Text center style={styles.link} onPress={() => openUrl(url)}>
      {text}
    </Text>
  )
}

type PhaseType = keyof typeof modalData

/** Modal component that provides options for choosing a product type to create  */
export function ChooseProductType({ onSelect }: { onSelect(type: ProductType | string): void }) {
  const farmId = useSelector(adminFarmIdSelector)
  const [farmBalProdExist, setFarmBalProdExist] = useState<boolean>(false)
  const [phase, setPhase] = useState<PhaseType>('type')

  //check if current farm has a farm balance product
  useEffect(() => {
    if (!farmId) return
    loadProductsByFarmAndType(farmId, ProductType.FarmBalance, 1)
      .then((products) => {
        if (products.length > 0) {
          setFarmBalProdExist(true)
        }
      })
      .catch((err) => {
        Logger.debug(err)
      })
  }, [farmId])

  return (
    <ScrollView style={styles.prodContainer}>
      {phase !== 'type' && <Header onBackPress={() => setPhase('type')} />}
      <View style={styles.container}>
        <HeaderText center>{modalData[phase].title}</HeaderText>
        <View style={styles.optionsWrapper}>
          {modalData[phase].options.map((opt) => {
            //if farm balance product already exists, disable 'create farm balance' button
            if (opt.type === ProductType.FarmBalance && farmBalProdExist) return
            return (
              <View key={opt.type} style={styles.optContWrapper}>
                <View style={styles.optionContainer}>
                  <Image
                    source={opt.icon}
                    style={phase === 'type' ? styles.groupImage : styles.iconStyle}
                    resizeMode="contain"
                  />
                  {phase !== 'type' && (
                    <>
                      <View style={styles.descriptionCont}>
                        {!!opt.description && (
                          <>
                            <Text center size={14}>
                              {opt.description}
                            </Text>
                          </>
                        )}
                      </View>
                      <View style={styles.bottomCont}>
                        {!!opt.bottomText && (
                          <Text center size={14}>
                            {opt.bottomText}
                          </Text>
                        )}
                      </View>
                    </>
                  )}

                  <Button
                    small
                    title={opt.title}
                    onPress={() => (opt.type in modalData ? setPhase(opt.type as PhaseType) : onSelect(opt.type))}
                  />
                </View>
              </View>
            )
          })}
        </View>
      </View>
      {phase !== 'type' && <Footer phase={phase} />}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  prodContainer: {
    flex: 1,
  },
  container: {
    alignItems: 'center',
    padding: 15,
  },
  headerCont: {
    flex: 1,
    padding: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  link: {
    marginTop: 10,
    marginBottom: 20,
    textDecorationLine: 'underline',
  },
  description: {
    marginTop: 10,
    marginBottom: 20,
  },
  optionContainer: {
    borderRadius: 10,
    borderColor: Colors.shades[100],
    borderWidth: 1,
    padding: 20,
    margin: 10,
    width: 280,
  },
  optContWrapper: {
    flex: 1,
    minWidth: 300, // opt cont width 280 + 20 margin
    alignItems: 'center',
  },
  groupImage: {
    alignSelf: 'center',
    width: 200,
    height: 200,
  },
  iconStyle: {
    alignSelf: 'center',

    width: 60,
    height: 60,
  },
  optionsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  descriptionCont: {
    minHeight: 120,
    justifyContent: 'center',
  },
  bottomCont: {
    minHeight: 50,
    justifyContent: 'center',
  },
})
