import { csasCollection, distrosCollection } from '@api/framework/ClientCollections'
import { CsaSelector, DateSelector } from '@components'
import { Loader, Modal, Toast, hideModal } from '@elements'
import { errorToString } from '@helpers/helpers'
import { getUnits, isInStock } from '@helpers/products'
import { CSA } from '@models/CSA'
import { NonPickup, formatDistributionType, isNonPickupDistLocation } from '@models/Location'
import { CartItem, CartStandard, ItemNonPickup, isCartStandard } from '@models/Order'
import { Product, ProductType, isAddon, isPhysical, isShare, isStandard } from '@models/Product'
import { UserAddress } from '@models/UserAddress'
import { UnitSelection } from '@screens/Shopping/UnitSelection'
import { useCallback, useMemo, useState } from 'react'

import { useAvailAddons } from '../../useAvailAddons'
import { useCartService } from '../useCartService'

import { Logger } from '@/config/logger'
import { AddToCartServiceOpts } from '@/constants/types/cartService'
import { addToCartConfirmation } from '@/hooks/useCart'
import { useCartInfo } from '@/redux/selectors'
import { defaultAddonNotAvailableText } from '@helpers/addons'
import { canUpdateQuantity } from '@helpers/canUpdateQuantity'
import { plural } from '@helpers/display'
import { Distribution } from '@models/Distribution'
import { EditDeliveryAddress } from '@screens/Shopping/EditDeliveryAddress'
import { DataError } from '@shared/Errors'
import { DateTime } from 'luxon'
import { clearGetPickupsCacheAddtoCartFlow, getMatchingCartSchedules } from './helpers'
import { SetLocationFlow, useSetLocationFlow } from './useSetLocationFlow'

export type AddToCartFlow = (
  /** These props are the most basic starting point for adding a product to the cart. They're all optional except the product because the flow guides the user through any necessary selection */
  opts: Pick<AddToCartServiceOpts, 'product' | 'unit' | 'csa' | 'pickups' | 'paymentSchedule' | 'distribution'>,
) => Promise<CartItem | null>

type UseAddToCartFlowReturn = {
  addToCartFlow: AddToCartFlow
  setLocationFlow: SetLocationFlow
  modifyDates: (id: CartStandard['id']) => Promise<void>
  addingToCart: boolean
  selectCsa: (opts: {
    /** Product to add to cart */
    prod: Product
    /** Pre-selected csa for this product, if any */
    csa?: CSA
  }) => Promise<CSA | undefined>
  editDeliveryAddress: (
    /** a cartItem expected to have a delivery schedule */ item: ItemNonPickup,
  ) => Promise<NonNullable<NonPickup['address']> | void>
}

export type OnSuccessFn = (result: CartItem | void, opts?: Parameters<typeof addToCartConfirmation>[1]) => void

/** Provides a set of high level helpers for interacting with the cart service through elaborate sequences of modals that await each other in a flow that culminates in calling cart service APIs
 * @param isAdmin will modify behaviors to be usable in the admin cart (order creator)
 */
export const useAddToCartFlow = (isAdmin = false): UseAddToCartFlowReturn => {
  const [loading, setLoading] = useState(false)
  const { cartFarmId } = useCartInfo(isAdmin)
  const { cart, addToCart, updateCartItem } = useCartService(isAdmin)
  const { availAddonsResults } = useAvailAddons(cartFarmId)
  const setLocationFlow = useSetLocationFlow({ isAdmin })

  const selectCsa = useCallback<UseAddToCartFlowReturn['selectCsa']>(
    async ({ prod, csa: csaArg }) => {
      let selectedCsa: CSA | undefined = undefined

      // If there's more than 1 csa, allow choosing
      if (isShare(prod) && !csaArg && prod.csa.length > 1) {
        // Open csa selector modal
        selectedCsa = await new Promise<CSA | undefined>((resolve) =>
          Modal(
            <CsaSelector
              prod={prod}
              onSelect={(csa) => {
                hideModal()
                resolve(csa)
              }}
              isAdmin={isAdmin}
            />,
            {
              title: 'Select a CSA for this Share',
              onDismiss: () => {
                hideModal()
                resolve(undefined)
              },
              webWidth: 1000,
            },
          ),
        )
      } else {
        // Try to use any csaArg
        selectedCsa = csaArg

        if (!selectedCsa && isShare(prod)) {
          // If there's only one csa, fetch and assign if not hidden
          if (prod.csa[0]) {
            const csa = await csasCollection.fetch(prod.csa[0])
            if (!csa.isHidden) selectedCsa = csa
          } else {
            throw new Error(`Tried to add-to-cart a share without any CSA IDs. Product ID: (${prod.id})`)
          }
        }
      }
      return selectedCsa
    },
    [isAdmin],
  )

  /**
   * The first step in adding a product to cart. Presents one or more modals to the user, with the appropriate options for adding the particular type of product to cart.
   */
  const addToCartFlow = useCallback<AddToCartFlow>(
    ({ product, unit: unitProp, csa: csaArg, pickups, paymentSchedule, distribution }) =>
      new Promise(async (resolve, reject) => {
        setLoading(true)

        /** onErr should only be called when there is an actual unexpected error */
        const onErr = (err: unknown) => {
          Logger.error(err)
          hideModal()
          setLoading(false)
          addToCartConfirmation(undefined, { msg: errorToString(err), showMsg: false })
          clearGetPickupsCacheAddtoCartFlow()
          reject(err)
        }

        /** onSuccess should be called either on early return, cancellation and on successful adding to cart */
        const onSuccess: OnSuccessFn = (result, opts) => {
          hideModal()
          setLoading(false)
          addToCartConfirmation(result, opts)
          clearGetPickupsCacheAddtoCartFlow()
          resolve(result || null)
        }

        /** Validate the product has some stock to add. The UI should prevent this before the addToCart flow is called */
        if (!isInStock(product)) {
          return onSuccess(undefined, { msg: 'The product is out of stock', alertType: 'alert', showMsg: true })
        }

        let selectedCsa = csaArg
        try {
          selectedCsa = await selectCsa({
            prod: product,
            csa: csaArg,
          })
          if (!selectedCsa && isShare(product)) {
            return onSuccess(undefined, { msg: 'There are no CSAs available for this product', showMsg: true })
          }
        } catch (err) {
          return onErr(err)
        }

        //If it's an addon, restrict which distros can be selected based on cart and past purchases
        if (isAddon(product)) {
          // The matching distros for the addon must be obtained from the cart items, to ensure any nonPickup location within them has a delivery address
          const matchingSchedulesCart = getMatchingCartSchedules(cart, product, isAdmin)

          let matchingSchedulesAvailAddon: Distribution[] | undefined = undefined

          if (!isAdmin) {
            //If it's an addon in consumer mode, get the availAddonResult for the addon id
            const availAddonResult = availAddonsResults.find((addon) => addon.id === product.id)

            /** The addon result will only be calculated for the cart farm and the past purchases.
             * Therefore not all addons will be found within the availAddon results when the cart is empty
             * If the addon is not available and we're in consumer mode, should abort with onSuccess(), and provide the reason.
             * Ideally the UI would prevent this one too, but it's not as bad.
             * What matters is the user should be told why the addon isn't available  */
            if (!availAddonResult?.isAvail) {
              // If the addon is not available or is not in the results, should abort with onSuccess(), passing a void result, and a user friendly message.
              return onSuccess(undefined, {
                alertType: 'alert',
                showMsg: true,
                msg: availAddonResult?.unavailReason ?? defaultAddonNotAvailableText,
              })
            }

            // get a list of the matchingDistIds for the availAddon which are not in the cart
            const matchingDistIdsToFetch = availAddonResult.matchingDistIds.filter(
              (id) => !matchingSchedulesCart.find((d) => d.id === id),
            )
            // if a matchingDistId is not in the cart, it must be from a past purchase, so fetch it
            const matchingDistrosPurchases = await distrosCollection.fetchByIds(matchingDistIdsToFetch)

            // get each of the availAddon matchingDistIds in either the cart or the db.
            matchingSchedulesAvailAddon = availAddonResult.matchingDistIds.map(
              (id) =>
                matchingSchedulesCart.find((d) => d.id === id) ?? matchingDistrosPurchases.find((d) => d.id === id)!,
            )
          }

          return setLocationFlow({
            prod: product,
            matchingSchedulesCart,
            matchingSchedulesAvailAddon,
            csa: selectedCsa,
            paymentSchedule,
            distribution,
            onSuccess,
            onErr,
          })
        } else if (isPhysical(product)) {
          const matchingSchedules = getMatchingCartSchedules(cart, product, isAdmin)

          // If Standard, you must pass a unit to the location selection flow.
          // So either it needs to be pre-defined as a prop, or must be selected in UnitSelection.
          if (isStandard(product) && !unitProp && product.units.length > 1) {
            return Modal(
              <UnitSelection
                product={product}
                close={(selectedUnit) =>
                  setLocationFlow({
                    prod: product,
                    matchingSchedulesCart: matchingSchedules,
                    unit: selectedUnit,
                    csa: selectedCsa,
                    paymentSchedule,
                    distribution,
                    providedPickups: pickups,
                    onSuccess,
                    onErr,
                  })
                }
              />,
              {
                halfModal: true,
                webWidth: 500,
                title: 'Select size to add to cart',
                onDismiss: () => {
                  onSuccess()
                },
              },
            )
          } else {
            //Go straight to set location flow, because either the product is a primary share, or there is only one buying option in the product, or we specified the unit as prop.
            return setLocationFlow({
              prod: product,
              matchingSchedulesCart: matchingSchedules,
              unit: unitProp ?? getUnits(product)?.[0],
              csa: selectedCsa,
              paymentSchedule,
              distribution,
              providedPickups: pickups,
              onSuccess,
              onErr,
            })
          }
        } else if (product.type === ProductType.Digital || product.type === ProductType.FarmBalance) {
          /** Add digital product to cart without distro & pickups */

          //If the digital product has several units, and there's no unit prop, let them choose the unit
          if (!unitProp && product.units.length > 1) {
            return Modal(
              <UnitSelection
                product={product}
                close={async (unit) => {
                  // We should close the modal before awaiting for addToCart server response
                  Loader(true)
                  try {
                    const canAddResult = canUpdateQuantity({
                      cart,
                      cartItem: { product, quantity: 1, unit },
                    })
                    if (!canAddResult) {
                      Loader(false)
                      return onSuccess?.(undefined, {
                        msg: 'The stock is insufficient',
                        alertType: 'alert',
                        showMsg: true,
                      })
                    }
                    const res = await addToCart({
                      product,
                      unit,
                      price: unit?.prices[0],
                      csa: selectedCsa,
                      isAdmin,
                    })
                    Loader(false)
                    return onSuccess(res, { autoAddedDistro: false, autoAddedDates: false })
                  } catch (error) {
                    Loader(false)
                    return onErr(error)
                  }
                }}
              />,
              {
                halfModal: true,
                webWidth: 500,
                title: 'Select unit to add to cart',
                onDismiss: () => {
                  Loader(false)
                  onSuccess()
                },
              },
            )
          } else {
            try {
              //If the digital product has a single unit, or there's a unit prop, no modal needed. Just add it to cart.
              const unit = unitProp ?? product.units[0]
              Loader(true)
              const canAddResult = canUpdateQuantity({
                cart,
                cartItem: { product, quantity: 1, unit },
              })
              if (!canAddResult) {
                Loader(false)
                return onSuccess?.(undefined, {
                  msg: 'The stock is insufficient',
                  alertType: 'alert',
                  showMsg: true,
                })
              }
              const res = await addToCart({ product, unit, price: unit.prices[0], csa: selectedCsa, isAdmin })
              Loader(false)
              return onSuccess(res, { autoAddedDistro: false, autoAddedDates: false })
            } catch (err) {
              Loader(false)
              return onErr(err)
            }
          }
        } else {
          /** Implement add gift cards and memberships to cart */
          const err = new DataError('Tried to add an unsupported product type to cart. ', { product })
          return onErr(err)
        }
      }),
    [cart, addToCart, availAddonsResults, setLocationFlow, selectCsa, isAdmin],
  )

  /** Allows modifying the pickup dates for a standard product in cart */
  const modifyDates = useCallback<UseAddToCartFlowReturn['modifyDates']>(
    (id) =>
      new Promise(async (resolve, reject) => {
        setLoading(true)
        const cartItem = cart.find((ci) => ci.id === id)

        if (!cartItem) {
          Toast('The item could not be found')
          setLoading(false)
          return reject(new Error('Item not found: ' + id))
        }
        if (!isCartStandard(cartItem)) {
          Toast("Can't modify the dates for this item")
          setLoading(false)
          return reject(new DataError('Dates cannot be modified for this item type. ', { cartItem }))
        }

        const onSelectDates = async (dates: DateTime[]) => {
          // We will close the modal immediately on selection end, instead of waiting for the server response.
          hideModal()

          try {
            if (!dates.length) {
              throw new Error('No dates were selected.')
            }
            if (cartItem.product.minPickups !== undefined && dates.length < cartItem.product.minPickups) {
              throw new Error(
                `You must select at least ${cartItem.product.minPickups} ${plural(
                  cartItem.product.minPickups,
                  'date',
                )}`,
              )
            }
            await updateCartItem(id, { pickups: dates })
            Toast(
              `Your item now has ${dates.length} ${formatDistributionType(cartItem.distribution.location, {
                action: true,
              })} dates`,
            )
            resolve()
          } catch (error) {
            Toast('Something went wrong while updating the item')
            reject(error)
          } finally {
            setLoading(false)
            clearGetPickupsCacheAddtoCartFlow()
          }
        }

        return Modal(
          <DateSelector
            distro={cartItem.distribution}
            product={cartItem.product}
            preselectedDates={cartItem.pickups}
            onSelect={onSelectDates}
            isAdmin={isAdmin}
            unit={cartItem.unit}
          />,
          {
            webWidth: 1000,
            title: 'Select schedule',
            onDismiss: () => {
              hideModal()
              setLoading(false)
              clearGetPickupsCacheAddtoCartFlow()
              resolve()
            },
          },
        )
      }),
    [cart, updateCartItem, isAdmin],
  )

  /** Allows modifying the delivery address of an item in the cart */
  const editDeliveryAddress = useCallback<UseAddToCartFlowReturn['editDeliveryAddress']>(
    (item) =>
      new Promise((resolve, reject) => {
        setLoading(true)
        if (!isNonPickupDistLocation(item.distribution.location)) {
          setLoading(false)
          const err = new DataError(
            "An attempt was made to edit the address of an item that doesn't support address editing.",
            { item },
          )
          Logger.error(err)
          Toast('This item does not support address selection')
          return reject(err)
        }

        const onSelect = (newAddress: UserAddress) =>
          updateCartItem(item.id, {
            distribution: {
              ...item.distribution,
              location: {
                ...item.distribution.location,
                address: newAddress,
              },
            },
          })
            .then((newItm) => {
              hideModal()
              Toast(newItm ? 'Delivery Address updated' : 'There was a problem while updating the delivery address')
              setLoading(false)
              resolve(newItm ? (newItm as ItemNonPickup).distribution?.location.address : undefined)
            })
            .catch((e) => {
              Logger.error(e)
              hideModal()
              Toast('There was a problem while updating the delivery address')
              setLoading(false)
              reject(e)
            })

        try {
          return Modal(<EditDeliveryAddress item={item} onSelect={onSelect} />, {
            webWidth: 1000,
            title: 'Select Delivery Address',
            onDismiss: () => {
              hideModal()
              Toast('Editing canceled')
              setLoading(false)
              resolve()
            },
          })
        } catch (error) {
          Logger.error(error)
          hideModal()
          Toast('Something went wrong during address editing')
          setLoading(false)
          reject(error)
        }
      }),
    [updateCartItem],
  )

  return useMemo<UseAddToCartFlowReturn>(
    () => ({
      addToCartFlow,
      setLocationFlow,
      modifyDates,
      addingToCart: loading,
      selectCsa,
      editDeliveryAddress,
    }),
    [addToCartFlow, loading, modifyDates, selectCsa, editDeliveryAddress, setLocationFlow],
  )
}
