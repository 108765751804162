import { loadUnpaidInvoicesByUserAndFarm } from '@api/Invoices'
import { Spinner, Text } from '@elements'
import { memo, useCallback, useContext, useMemo, useState } from 'react'
import { View } from 'react-native'

import { InvoicesByFarm } from './InvoiceTab'

import { INITIAL_LIMIT_N_PICKUPS, snapshotFuturePickupsByUser } from '@api/Pickups'
import { LoaderWithMessage } from '@components'
import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Order, Pickup, isPickupItemActive, isPickupItemOnVacation } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import { useDispatch, useSelector } from 'react-redux'

import { OrdersParamList } from '../../navigation/types'
import { addNavProp } from '../../redux/actions/appState'
import { userSelector } from '../../redux/selectors'
import { PickupCard } from './PickupCard'

import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll'
import { removeDuplicates } from '@helpers/helpers'
import { isBefore } from '@helpers/time'
import { OrdersScreenContext } from './OrdersScreen'

type Props = {
  goToPickupInvoices: (invoices: string[]) => void
}

export const PickupTab = memo(function PickupTab({ goToPickupInvoices }: Props) {
  const [{ pickups, tab }, set] = useContext(OrdersScreenContext)
  const visible = tab === 'schedule'

  const user = useSelector(userSelector)
  const navigation = useNavigation<StackNavigationProp<OrdersParamList, 'Orders'>>()
  const dispatch = useDispatch()

  /** Invoices that need to be paid. These get used by the pickups tab. */
  const [activeInvoices, setActiveInvoices] = useState<InvoicesByFarm>({})

  const { increaseLimit, limit, loading, setLoading } = useInfiniteScroll(INITIAL_LIMIT_N_PICKUPS, pickups.length)

  const activePickups = useMemo(() => pickups.filter(isActivePickup), [pickups])

  const nextPickupId: string | null = useMemo(() => {
    const sortedPickupsByDate = [...activePickups].sort((a, b) => (isBefore(a.date, b.date) ? -1 : 1))
    return sortedPickupsByDate[0]?.id ?? null
  }, [activePickups])

  /** Snapshots of pickups based on current date and a variable result limit */
  useFocusFx(() => {
    setLoading(true)
    return snapshotFuturePickupsByUser(
      user.id,
      (newPickups: Pickup[]) => {
        if (shouldFetchMorePickups(newPickups, limit)) {
          increaseLimit()
          return
        }
        set('pickups', newPickups)
        disableLoading()
      },
      limit > MaxLimit ? null : limit,
    )
    // No other more dependencies should be passed, in order to avoid unintented snapshot calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, user.id])

  /** When active pickups change, sets active invoices for pickups' farms */
  useCancelableFocusFx(
    async (isCurrent) => {
      // Only show pickups that have at least 1 item that is not cancelled
      if (!activePickups.length) return

      // Get all unique farms from pickups
      const farmIdsNew = removeDuplicates(activePickups.map((p) => p.farm.id))
      const farmIdsCurr = Object.keys(activeInvoices)

      //check if the current farmIds already includes the new farmIds. If all are included, do nothing
      if (farmIdsNew.every((id) => farmIdsCurr.includes(id))) return

      //Will get all invoices for each farmId, and group active invoices by farmId
      const activeInvoicesNew = await getActiveInvoices(farmIdsNew, user.id)

      if (isCurrent) setActiveInvoices(activeInvoicesNew)
    },
    [activePickups, activeInvoices, user.id],
  )

  /** This delay ensures that the dispatch has finished */
  const disableLoading = () =>
    setTimeout(() => {
      setLoading(false)
    }, 150)

  const goToOrderDetails = useCallback(
    (order: Order) => {
      dispatch(addNavProp({ order }))
      navigation.navigate('OrderSummary', { orderId: order.id })
    },
    [dispatch, navigation],
  )

  const ListEndLoader = useCallback(() => {
    if (loading) {
      return <Spinner />
    }
    return <View />
  }, [loading])

  const renderPickup = useCallback(
    (info: ListRenderItemInfo<Pickup>) => {
      const pickupInvoices = getPickupInvoices(info.item, activeInvoices)
      const dueStr =
        nextPickupId === info.item.id && pickupInvoices.length > 0
          ? formatMoney(pickupInvoices.reduce((amt, inv) => amt + MoneyCalc.cents(inv.amountTotal), 0))
          : undefined
      return (
        <PickupCard
          pickup={info.item}
          due={dueStr}
          goToOrderDetails={goToOrderDetails}
          goToPickupInvoices={() => goToPickupInvoices(pickupInvoices.map((inv) => inv.id))}
        />
      )
    },
    [activeInvoices, goToOrderDetails, goToPickupInvoices, nextPickupId],
  )

  if (!visible) {
    return <View />
  }

  return (
    <FlashList
      ListEmptyComponent={
        <LoaderWithMessage loading={loading} icon="truck-pickup" title="No Pickups!">
          <Text>After you make a purchase on GrownBy, you can find your pickups here.</Text>
        </LoaderWithMessage>
      }
      onEndReached={increaseLimit}
      onEndReachedThreshold={0.9}
      ListFooterComponent={ListEndLoader}
      estimatedItemSize={700}
      renderItem={renderPickup}
      data={activePickups}
      // extraData should include the dependencies of renderItem function. otherwise the pure component memoization will not update the renderItem memo
      extraData={[activeInvoices, goToOrderDetails, goToPickupInvoices, nextPickupId, loading]}
    />
  )
})

export const MaxLimit = 300

const getPickupInvoices = (pickup: Pickup, activeInvoices: InvoicesByFarm) => {
  // All invoices for farm
  const invoicesForFarm = activeInvoices[pickup.farm.id] ?? []
  // Out of the invoices for this farm, only return the ones that are due by the pickup date
  const invoicesDueByPickup = invoicesForFarm.filter((inv) => isBefore(inv.dueDate, pickup.date, { orEqual: true }))
  return invoicesDueByPickup
}

/** As long as some pickup items' status are 'active' or 'vacation', the entire pickup is seen active for this function*/
export const isActivePickup = (pickup: Pickup) =>
  pickup.items.some((item) => isPickupItemActive(item) || isPickupItemOnVacation(item))

/** Checks whether an additional limit increase is necessary
 *If there are no active pickups, determine whether if loading additional raw pickups is need before displaying that there are no active pickups */
const shouldFetchMorePickups = (pickups: Pickup[], limit: number) => {
  const activePickups = pickups.filter(isActivePickup)

  if (!activePickups.length) {
    /** Ensure that there are more pickups to fetch (limit is reached)*/
    if (pickups.length === limit && limit < MaxLimit) {
      return true
    }
  }
  return false
}

/** Gets active invoices for each farm and returns an object with farmId as keys and active invoices as values */
const getActiveInvoices = async (farmIds: string[], userId: string): Promise<InvoicesByFarm> => {
  const activeInvoices: InvoicesByFarm = {}

  await Promise.all(
    farmIds.map(async (farmId) => {
      activeInvoices[farmId] = await loadUnpaidInvoicesByUserAndFarm(userId, farmId)
    }),
  )
  return activeInvoices
}
