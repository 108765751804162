import { PartialPick } from '@helpers/typescript'
import { ProductFee } from '@models/ProductFee'
import { ArchiveProductFeeRequest, ArchiveProductFeeResponse } from '@shared/types/v2/productFee'
import { orderBy } from 'firebase/firestore'
import { productFeesCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** Will add a new coupon to the farm */
export async function addProductFee(productFee: PartialPick<ProductFee, 'farm'>): Promise<ProductFee> {
  const doc = await productFeesCollection.resolve(productFee.farm.id).create(productFee)
  return doc
}

/** Will update the given productFee */
export async function updateProductFee(productFee: PartialPick<ProductFee, 'id' | 'farm'>): Promise<void> {
  return productFeesCollection.resolve(productFee.farm.id).update(productFee)
}

/** Loads product fees with a snapshot listener*/
export function snapshotProductFee(
  callback: (productFees: ProductFee[]) => void,
  onError: (err: Error) => void,
  farmId: string,
) {
  const q = productFeesCollection.resolve(farmId).query(orderBy('meta.createdAt.utc', 'desc'))
  return productFeesCollection.resolve(farmId).snapshotMany(q, (productFees) => callback(productFees), onError)
}

/** Loads existing product fees list */
export async function loadProductFees(farmId: string): Promise<ProductFee[]> {
  return productFeesCollection.resolve(farmId).fetchAll()
}

/** Whether a productFee can be archived. (A ProductFee can be archived only if it is not used by any products.) */
export async function canArchiveProductFee(data: ArchiveProductFeeRequest): Promise<ArchiveProductFeeResponse> {
  return await callEndpoint('v2.ProductFee.canArchiveProductFeeService', data)
}
