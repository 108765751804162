import { Button, ButtonClear, Divider, FormInput, Text, TextH2 } from '@elements'

import React, { useMemo, useState } from 'react'

import { AdminCard } from '@/admin/components/AdminCard'
import { Image } from '@components'
import { openUrl } from '@helpers/client'
import { Farm } from '@models/Farm'
import { FormikProps } from 'formik'
import { StyleSheet, View } from 'react-native'
import { PaymentsFormProps } from './types'

type Props = FormikProps<PaymentsFormProps> & {
  farm: Pick<Farm, 'ebt' | 'worldPayMerchantId'>
  hasGrownByAdminPrivilege: boolean
}
type EbtStep = 'UnRegistered' | 'Enrolling' | 'Enrolled'

const goHelpDesk = () => {
  openUrl('https://grownby.zohodesk.com/portal/en/newticket')
}
const goEbtEligibility = () => {
  openUrl('https://marketlink.org/apply')
}

/** EBT payments section */
export function EBTPayments({ values, handleChange, setFieldValue, farm, hasGrownByAdminPrivilege, errors }: Props) {
  const [step, setStep] = useState<EbtStep>(farm.worldPayMerchantId ? 'Enrolled' : 'UnRegistered')

  const Comp = useMemo(() => {
    if (step === 'UnRegistered') {
      return (
        <View style={styles.unregisteredMain}>
          <Text>
            With GrownBy, you can accept USDA Food and Nutrition Service SNAP/EBT payments online. This service is
            provided at no cost through our partner, MarketLink.
          </Text>
          <Text>To get started, please take their eligibility survey.</Text>
          <Button style={styles.startBtn} outline title="Start Eligibility Survey" onPress={goEbtEligibility} />

          {hasGrownByAdminPrivilege && (
            <ButtonClear
              small
              textStyle={styles.underline}
              onPress={() => {
                setStep('Enrolling')
                setFieldValue('ebtPaymentEnabled', true)
              }}
              title="I am ready to submit my SNAP Online credentials"
            />
          )}
        </View>
      )
    }
    if (step === 'Enrolling') {
      return (
        <View>
          <Divider clear />
          <Text>The following information is required for SNAP Online.</Text>
          <Divider clear />
          <Divider clear />

          <FormInput
            label="WorldPay Merchant ID*"
            placeholder="Ex: 1234567890123"
            value={values.worldPayMerchantId}
            onChangeText={handleChange('worldPayMerchantId')}
            errorMessage={errors.worldPayMerchantId}
          />
          <FormInput
            label="FNS Online Number*"
            placeholder="Ex: 1234567..."
            value={values.fnsOnline}
            onChangeText={handleChange('fnsOnline')}
            secureTextEntry
            errorMessage={errors.fnsOnline}
          />
        </View>
      )
    }
    if (step === 'Enrolled') {
      return (
        <View style={styles.unregisteredMain}>
          <Text>Your account is connected to SNAP Online</Text>
          <View>
            <Text>Worldpay MerchantID</Text>
            <Text>{farm.worldPayMerchantId}</Text>
          </View>

          <View>
            <Text>FNS Internet Retailer Number</Text>
            <Text>****{farm.ebt?.fnsOnline.slice(-4)}</Text>
          </View>
          <View />
          <ButtonClear
            onPress={goHelpDesk}
            small
            textStyle={styles.underline}
            title="Need help? Submit a support ticket."
          />
        </View>
      )
    }
  }, [
    errors.fnsOnline,
    errors.worldPayMerchantId,
    farm.ebt?.fnsOnline,
    farm.worldPayMerchantId,
    handleChange,
    hasGrownByAdminPrivilege,
    step,
    values.fnsOnline,
    values.worldPayMerchantId,
  ])

  return (
    <AdminCard noMargins>
      <View style={styles.ebtHeaderCont}>
        <TextH2 style={styles.snapTitle} size={18}>
          SNAP Online
        </TextH2>
        <Image style={styles.marketLinkImg} source={require('../../../../../assets/images/market_link_logo.png')} />
      </View>
      {Comp}
    </AdminCard>
  )
}

const styles = StyleSheet.create({
  startBtn: {
    marginLeft: 0,
    alignSelf: 'flex-start',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  unregisteredMain: {
    gap: 20,
    marginTop: 20,
  },
  snapTitle: {
    minWidth: 150,
  },
  marketLinkImg: {
    flexBasis: 200,
    aspectRatio: 3,
    resizeMode: 'contain',
    flexShrink: 1,
  },
  ebtHeaderCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
