import { ConsumerView } from '@components'
import {
  FormButton,
  KeyboardAvoidingScrollView,
  KeyboardAvoidingScrollViewRef,
  LoadingView,
  ScreenView,
  Text,
} from '@elements'
import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Formik, FormikConfig } from 'formik'
import { memo, useCallback, useContext, useRef, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import Colors from '../../../constants/Colors'
import { consumerCartInfoSelector, userSelector } from '../../../redux/selectors'
import { FarmSnippet } from '../CartFarmSnippet'
import { EnterDiscount } from '../components/EnterDiscount'
import { OrderCoverComponent } from '../components/OrderCover'
import { CartItemsCheckout } from './CartItemsCheckout'
import CheckoutContact from './CheckoutContact'
import { CheckoutContext } from './useCheckoutData'

import { useCartService } from '@/hooks/useCart'
import { CoverOptId } from '@helpers/serviceFee'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'
import { useDeviceSize } from '../../../hooks/useLayout'
import { PaymentSelector } from '../../PaymentMethods/PaymentSelection/PaymentSelector'

export const CheckoutUi = memo(function CheckoutUi() {
  const {
    preCheckout,
    farm,
    loadingFarm,
    errorFarm,
    total,
    additionalFees,
    shouldRequireAddress,
    initialAddressValues,
    validateAddress,
    schema,
    coverFee,
    ebtPmtAmount,
    farmCreditAppliedAmount,
    setters,
    serviceFeeAmount,
    disablePlaceOrder,
    loadingPlaceOrderBtn,
    paymentSelectorOptions,
    totalAfterFeesText,
  } = useContext(CheckoutContext)
  const user = useSelector(userSelector)
  const { discount, addDiscount, removeDiscount, loadingCart } = useCartService()
  const { cartId } = useSelector(consumerCartInfoSelector)
  const scrollViewRef = useRef() as KeyboardAvoidingScrollViewRef
  const [viewableAreaHeight, setViewableAreaHeight] = useState(0)
  const [contentHeight, setContentHeight] = useState(0)
  const styles = responsiveStyle()
  const { isSmallDevice } = useDeviceSize()

  /** Fixme. We should find a way to not let this entire screen be so dependent on formik, when it really only needs formik for the billing address. Also the onSubmit should come from the useContext. The design of the data hook is supposed to be that all logic comes from the hook data. new logic and functions should not be defined here in the UI component */
  const onSubmit = useCallback<FormikConfig<any>['onSubmit']>(
    (_, { setSubmitting }) => preCheckout(setSubmitting),
    [preCheckout],
  )

  return (
    <Formik
      initialValues={initialAddressValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={schema}
      validate={validateAddress}
      validateOnChange={false} //Not on change or blur, because validation fn shows alerts, so it would be annoying
      validateOnBlur={false}
      validateOnMount={
        /** validate on mount if the address exists but is invalid */
        shouldRequireAddress && !!user.address
      }
    >
      {(formikProps) => (
        <ScreenView>
          <ConsumerView style={styles.consumerView}>
            <KeyboardAvoidingScrollView
              ref={scrollViewRef}
              onContentSizeChange={(_, h) => setContentHeight(h)}
              onLayout={(e) => setViewableAreaHeight(e.nativeEvent.layout.height)}
            >
              <FarmSnippet
                loading={loadingFarm}
                error={errorFarm}
                farm={farm}
                rightText={`Total: ${totalAfterFeesText}`}
                actionButton={
                  <FormButton
                    loading={loadingPlaceOrderBtn || formikProps.isSubmitting}
                    title="Place Order"
                    onPress={() => {
                      // If the header place order button is clicked and the content is scrollable, then we should scroll to the bottom so that the user can see all the order details. Otherwise, we should submit the form if the user already has everything in view.
                      if (contentHeight > viewableAreaHeight) {
                        scrollViewRef.current?.scrollToEnd(true)
                      } else {
                        if (!disablePlaceOrder) formikProps.handleSubmit()
                      }
                    }}
                  />
                }
              />
              <View style={styles.main}>
                <CartItemsCheckout />
                {shouldRequireAddress && <CheckoutContact formikProps={formikProps} />}
                <View style={styles.totalContainer}>
                  <View style={styles.totalItem}>
                    <Text size={16}>Subtotal</Text>
                    <Text size={16}>{formatMoney(total.subtotal)}</Text>
                  </View>
                  <EnterDiscount
                    addDiscount={addDiscount}
                    removeDiscount={removeDiscount}
                    discount={discount}
                    loadingCart={loadingCart}
                    ebtAmount={ebtPmtAmount}
                  />
                  {!!total.discounts && !MoneyCalc.isZero(total.discounts) && (
                    <View style={styles.totalItem}>
                      <Text color={Colors.green} size={16}>
                        Discounts
                      </Text>
                      <Text color={Colors.green} size={16}>
                        {`-${formatMoney(total.discounts)}`}
                      </Text>
                    </View>
                  )}
                  {MoneyCalc.isGTZero(coverFee.value) && (
                    <View style={styles.totalItem}>
                      <Text size={16}>Covered fees{MoneyCalc.isZero(coverFee.tip) ? '' : ' & Tips'}</Text>
                      <Text size={16}>{formatMoney(coverFee.value)}</Text>
                    </View>
                  )}
                  {MoneyCalc.isGTZero(total.tax) && (
                    <View style={styles.totalItem}>
                      <Text size={16}>Taxes</Text>
                      <Text size={16}>{formatMoney(total.tax)}</Text>
                    </View>
                  )}
                  {additionalFees.length > 0 &&
                    additionalFees.map((item) => (
                      <View style={styles.totalItem} key={item.productFee.id}>
                        <Text size={16}>{item.productFee.name}</Text>
                        <Text size={16}>{formatMoney(item.amount)}</Text>
                      </View>
                    ))}
                  {MoneyCalc.isGTZero(farmCreditAppliedAmount) && (
                    <View style={styles.totalItem}>
                      <Text color={Colors.green} size={16}>
                        Farm credit applied
                      </Text>
                      <Text color={Colors.green} size={16}>
                        -{formatMoney(farmCreditAppliedAmount)}
                      </Text>
                    </View>
                  )}
                  <View style={styles.totalItem}>
                    <Text size={16} type="bold">
                      Total
                    </Text>
                    <Text size={16} type="bold">
                      {totalAfterFeesText}
                    </Text>
                  </View>
                </View>
                <LoadingView loading={!paymentSelectorOptions}>
                  <PaymentSelector
                    disabled={formikProps.isSubmitting}
                    userId={user.id}
                    // We use cartId as the identifier because on the checkout screen that determines a session
                    uniqueId={cartId ?? ''}
                    options={paymentSelectorOptions!}
                    onSplitTenderUpdated={setters.splitTender}
                  />
                  {/* We should only hide this component when there is no amount to apply fees to, and there is no fee currently selected */}
                  {(!MoneyCalc.isZero(serviceFeeAmount) || coverFee.id !== CoverOptId.None) && (
                    <OrderCoverComponent
                      tipOptions={farm?.tipsAndFees}
                      serviceFeeAmount={serviceFeeAmount}
                      coverFee={coverFee}
                      onUpdateCoverFee={setters.coverFee}
                      // Show installments only when there are products with multiple installments
                      hideInstallments={!paymentSelectorOptions?.hasInstallments}
                    />
                  )}
                </LoadingView>
                <View style={styles.footerSection}>
                  {!isSmallDevice && (
                    <Text type="medium" size={14}>
                      Amount due today
                    </Text>
                  )}
                  <View style={styles.footerRightSection}>
                    <Text type="bold" size={16}>
                      {totalAfterFeesText}
                    </Text>
                    <FormButton
                      disabled={disablePlaceOrder}
                      loading={loadingPlaceOrderBtn || formikProps.isSubmitting}
                      title="Place Order"
                      onPress={formikProps.handleSubmit}
                      style={styles.placeOrder}
                      testID="place-order"
                    />
                  </View>
                </View>
              </View>
            </KeyboardAvoidingScrollView>
          </ConsumerView>
        </ScreenView>
      )}
    </Formik>
  )
})

const responsiveStyle = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      backgroundColor: Colors.white,
    },
    main: {
      padding: 20,
    },
    totalContainer: {
      marginVertical: 5,
    },
    itemName: {
      fontSize: 18,
    },
    itemPrice: {
      color: Colors.shades['400'],
    },
    totalItem: {
      flexDirection: 'row',
      marginVertical: 5,
      justifyContent: 'space-between',
    },
    totalTitle: {
      fontSize: 14,
    },
    headerTitle: {
      width: '100%',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 18,
      lineHeight: 20,
      textAlign: 'center',
      alignSelf: 'center',
    },
    footerSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
    },
    footerRightSection: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    placeOrder: {
      marginLeft: 20,
      width: 250,
    },
    offlinePaymentMsg: {
      margin: 10,
      marginTop: 0,
    },
    consumerView: {
      paddingHorizontal: 10,
    },
  },
  {
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      totalContainer: {
        alignItems: 'flex-end',
      },
      totalItem: {
        width: '55%',
      },
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      footerRightSection: {
        justifyContent: 'space-between',
        flex: 1,
      },
      main: {
        padding: 10,
      },
    },
  },
)
