import Colors from '../../../constants/Colors'
import { Input, InputProps } from 'react-native-elements'
import { isLabelAccessible } from './helpers/helpers'
import { formStyles, useDynamicFormStyles } from './helpers/styles'
import { FormSharedProps } from './helpers/types'
import { globalStyles } from '../../../constants/Styles'

type FormInputProps = FormSharedProps & InputProps

/** This input component can be used to produce consistently styled inputs*/
export function FormInput(props: FormInputProps) {
  const styles = useDynamicFormStyles(!!props?.errorMessage, !!props.row)

  return (
    <Input
      placeholderTextColor={Colors.shades['200']}
      errorStyle={formStyles.error}
      label={props.label || props.placeholder}
      accessibilityLabel={isLabelAccessible(props.label) ? props.label : props.placeholder}
      labelStyle={formStyles.inputLabel}
      inputStyle={styles.inputStyle}
      inputContainerStyle={styles.inputContainer}
      containerStyle={props.row ? globalStyles.flex1 : {}}
      {...props}
    />
  )
}
