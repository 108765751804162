import { getCoordString, validCoords } from '@helpers/coordinate'
import { extendErr } from '@helpers/helpers'
import { Optional } from '@helpers/typescript'
import { Address, isPO } from '@models/Address'
import { Coordinate } from '@models/Coordinate'
import { DataError } from '@shared/Errors'
import * as Location from 'expo-location'

import { env } from '../config/Environment'
import { CurrentLocation } from '../constants/types'

import { Logger } from '@/config/logger'

/** Coords for germantown, ny, Useful for debugging hearty roots specific issues dealing with location */
export const germanTownCoords: Coordinate = { latitude: 42.1345339, longitude: -73.8917982 }

export const defaultLoc = {
  coordinate: germanTownCoords,
  city: 'Germantown',
  timestamp: Date.now(),
}

export const GOOGLE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json'

/** These types were borrowed from expo Location */
type GoogleApiGeocodingAddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

export type GoogleApiGeocodingResult = {
  address_components: GoogleApiGeocodingAddressComponent[]

  formatted_address: string

  geometry: {
    location: {
      lat: number

      lng: number
    }
  }
}

export type GoogleApiGeocodingResponse = {
  results: GoogleApiGeocodingResult[]

  status: string

  error_message?: string
}

/** Receives an address with no coordinates, geocodes it and returns the same address with the coordinates assigned. */
export async function geocode(address: Optional<Address, 'coordinate'>): Promise<Address & { coordinate: Coordinate }> {
  let query = `${address.city} ${address.state} ${address.zipcode}`
  if (!isPO(address)) {
    /** If it's a PO box address, the @type {Address['street1']} field may cause the geocoding to fail because as of now google api doesn't handle the PO box string well, but it should succeed with the rest of the address fields */

    /** Any "#" signs will make it fail */
    query = `${address.street1.replace('#', '').trim()} ` + query
  }
  const fetchUrl = `${GOOGLE_API_URL}?address=${encodeURI(query)}&key=${env.API_KEY}`
  const response = await fetch(fetchUrl)

  const geocodeResponse: GoogleApiGeocodingResponse = await response.json()
  if (!geocodeResponse.results?.[0] || !validCoords(geocodeResponse.results[0].geometry.location)) {
    /** In case of error, please pass a data error to Logger.error, which contains data that can help debug the problem */
    const err = new DataError('Could not geo-code address', { geocodeResponse, fetchUrl, address })
    Logger.error(err)
    throw err
  }

  return {
    ...address,
    coordinate: {
      latitude: geocodeResponse.results[0].geometry.location.lat,
      longitude: geocodeResponse.results[0].geometry.location.lng,
    },
  }
}

/** Converts a coordinate into a set of results that include raw address data */
export async function reverseGeocode(coords: Coordinate): Promise<GoogleApiGeocodingResult[]> {
  const params = {
    latlng: getCoordString(coords),
  }
  const query = Object.entries(params)
    .map((entry) => `${entry[0]}=${encodeURI(entry[1])}`)
    .join('&')
  const fetchUrl = `${GOOGLE_API_URL}?key=${env.API_KEY}&${query}`
  const fetchResponse = await fetch(fetchUrl)
  const geocodingResponse = (await fetchResponse.json()) as GoogleApiGeocodingResponse

  if (geocodingResponse.error_message) {
    /** In case of error, please pass a data error to Logger.error, which contains data that can help debug the problem */
    const err = new DataError('Could not reverse geo-code', { coords, fetchUrl, geocodingResponse })
    Logger.error(err)
    throw err
  }

  return geocodingResponse.results
}

/** geo location based on true device coordinates */
export const loadExactCoords = async (): Promise<Coordinate | undefined> => {
  try {
    const { status } = await Location.requestForegroundPermissionsAsync()

    if (status !== 'granted') return

    const currPosition = await Location.getCurrentPositionAsync({
      accuracy: Location.Accuracy.High,
      timeInterval: 5000,
    })
    const coords = { latitude: currPosition.coords.latitude, longitude: currPosition.coords.longitude }
    if (validCoords(coords)) return coords
    return undefined
  } catch {
    //If we can't get the exact location we fall back to the ipLocation, so this is not a sentry error
    return Promise.resolve(undefined)
  }
}

/** geolocation based on ip address. Will get an approximate location for the device */
export async function geoLocateIPAddr(): Promise<CurrentLocation> {
  try {
    const { status } = await Location.requestForegroundPermissionsAsync()
    if (status !== 'granted') return defaultLoc

    const corsUrl = `https://geolocation-db.com/json/`
    const response = await fetch(corsUrl)
    const body = (await response.json()) ?? {}

    const loc: CurrentLocation = {
      coordinate: {
        latitude: body.latitude,
        longitude: body.longitude,
      },
      // city is not always returned by the service (when using VPN for example)
      city: body.city === 'Not found' || typeof body.city !== 'string' ? '' : body.city,
      timestamp: Date.now(),
    }

    return validCoords(loc.coordinate) ? loc : defaultLoc
  } catch (e) {
    Logger.error(extendErr(e, 'Something went wrong while trying to get IP location:'))
    return defaultLoc
  }
}
