import React from 'react'

import { useHitTrack } from '../../hooks/useHitTrack'

import { useFarmDetailData } from './FarmDetailsScreen.controller'
import { FarmDetailsScreenView } from './FarmDetailsScreen.view'

/** Presentation screen for the farm. It contains farm info, and featured products */
export function FarmDetailScreen() {
  const props = useFarmDetailData()

  // Provide analytics data for the home view action.
  useHitTrack(props.farm?.id ?? '', 'home')

  return <FarmDetailsScreenView {...props} />
}
