import { loadDistributionDetails } from '@api/Summaries'
import { getOrderNum } from '@helpers/display'
import { groupByObj, removeDuplicates } from '@helpers/helpers'
import { getPickups } from '@helpers/order'
import { Distribution } from '@models/Distribution'
import { Farm } from '@models/Farm'
import { Location } from '@models/Location'
import { PickupItemStatus } from '@models/Order'
import { ProductType } from '@models/Product'
import { DateRange } from '@models/Schedule'
import { SignInItem } from '@models/SignInSummary'
import { DistributionDetailsSummary, DistributionSummaryItem } from '@models/Summary'
import { DateTime } from 'luxon'
import { ItemType } from './components'

/**Structured data used for grouping `DistributionSummaryItem` by `user` and `order`
 * - All the `DistributionSummaryItem`s with the same `orderData` will be grouped into the same `orderInfo`
 * - All the `orderInfo`s with the same `userData` will be grouped into a single `GroupedItemType` item
 */
export type GroupedItemType = Pick<DistributionSummaryItem, 'user' | 'order_due_amounts' | 'paymentStatus'> & {
  orders: {
    orderInfo: DistributionSummaryItem['order']
    items: DistributionSummaryItem[]
  }[]
}

/**Gets category name for a specific `ProductType` group */
export const getCategoryName = (item: Pick<ItemType, 'type'>): string => {
  switch (item.type) {
    case ProductType.AddonShare:
      return 'Add-on Shares'
    case ProductType.PrimaryShare:
      return 'Shares'
    case ProductType.Standard:
      return 'Standard Products'
    default:
      return item.type
  }
}

/**Whether the item has at least an active item,
 * and should be in the 'picking up' tab
 */
export const isPickingUp = (item: GroupedItemType): boolean => {
  const statuses = item.orders.flatMap((order) => order.items).map((el) => el.item.selectedUnits[0].status)
  return statuses.some((status) => status === PickupItemStatus.Active)
}
/**Whether the item has all the items different than active,
 * and should be in the 'received' tab
 */
export const isReceived = (item: GroupedItemType): boolean => !isPickingUp(item)

/**Creates a list of `GroupedItemType`, used for grouping `DistributionSummaryItem` items into orders and users
 * - All the `DistributionSummaryItem`s with the same `orderData` will be grouped into the same `orderInfo`
 * - All the `orderInfo`s with the same `userData` will be grouped into a single `GroupedItemType` item
 */
export const getSummaryItems = (summary?: DistributionDetailsSummary) => {
  if (!summary) return []

  const data: GroupedItemType[] = []

  summary.items.forEach((si) => {
    const userIdx = data.findIndex((value) => value.user.id === si.user.id)

    if (userIdx === -1) {
      //If user is not already in the list, create the user with an array of orders consisted of this specific item
      data.push({
        user: si.user,
        orders: [{ orderInfo: si.order, items: [si] }],
        order_due_amounts: si.order_due_amounts,
        paymentStatus: si.paymentStatus,
      })
      return
    }
    const orderIdx = data[userIdx].orders.findIndex((ord) => ord.orderInfo.id === si.order.id)

    if (orderIdx === -1) {
      // If user is found but order is not, create a new order with this specific item
      data[userIdx].orders.push({ items: [si], orderInfo: si.order })

      return
    }
    // If user and order were found, add this item in the order's items
    data[userIdx].orders[orderIdx].items.push(si)
  })

  return data
}

/**Transforms `DistributionDetailsSummary items` into `SignInSummary` items
 * - Used for creating a new `SignInSummary` doc with the existing BQ data
 */
export const toSignInSummaryItems = (items: DistributionSummaryItem[]) => {
  const parsed: SignInItem[] = items.map((summaryItm) => {
    return {
      id: summaryItm.pickupItem.id,
      status: summaryItm.item.selectedUnits[0].status,
      product: { id: summaryItm.item.product.id },
      pickup: { id: summaryItm.pickup.id },
      order: { id: summaryItm.order.id },
      user: { id: summaryItm.user.id },
    }
  })
  return parsed
}

/**Gets all the pickups for all the distributions for a specific location */
export const getPickupsForLocation = async (distros: Distribution[], location: Location): Promise<DateTime[]> => {
  const monthAgo = DateTime.now().minus({ month: 1 })
  const locDistros = distros.filter((distro) => distro.location.id === location.id)
  const allPickups = locDistros.flatMap((distro) =>
    getPickups(distro, undefined, {
      currDate: monthAgo,
      ignoreDisableBuyInFuture: true,
      ignoreOrderCutoffWindow: true,
    }),
  )
  const unique = removeDuplicates(allPickups.map((date) => date.toISO())).map((iso) => DateTime.fromISO(iso))
  const sorted = unique.sort((a, b) => a.toMillis() - b.toMillis())
  return Promise.resolve(sorted)
}

/**Creates a unified status for a user, based on all his items statuses */
export const getUserStatus = (item: GroupedItemType): PickupItemStatus => {
  const allStatuses = item.orders.flatMap((ord) => ord.items.map((oi) => oi.item.selectedUnits[0].status))
  const statuses = removeDuplicates(allStatuses)

  // If all the items have the same status, return that status
  if (statuses.length === 1) {
    return statuses[0]
  }

  // Else if at least one item is Active, return Active
  if (statuses.includes(PickupItemStatus.Active)) {
    return PickupItemStatus.Active
  }
  return PickupItemStatus.Received
}

/**Extracts the number of `active`, `missed` and `received` customers from a `DistributionDetailsSummary` item */
export const getCustomerNoByStatus = (summary: DistributionDetailsSummary) => {
  const mappedItems = summary.items.map((itm) => ({
    userId: itm.user.id,
    status: itm.item.selectedUnits[0].status,
  }))
  const active = mappedItems.filter((itm) => itm.status === PickupItemStatus.Active)

  //Donated And Missed items are classified as missed
  const missed = mappedItems.filter(
    (itm) => itm.status === PickupItemStatus.Missed || itm.status === PickupItemStatus.Donated,
  )
  const received = mappedItems.filter((itm) => itm.status === PickupItemStatus.Received)

  const activeNo = removeDuplicates(active.map((itm) => itm.userId)).length
  const missedNo = removeDuplicates(missed.map((itm) => itm.userId)).length
  const receivedNo = removeDuplicates(received.map((itm) => itm.userId)).length

  return { activeNo, missedNo, receivedNo }
}

/**Filters GroupedItemType items by a specific `searchValue`
 * @param searchValue is compared with `user.name`, `orderNumber` and `product.name`
 */
export const filterBySearchValue =
  (searchValue: string) =>
  (item: GroupedItemType): boolean => {
    const value = searchValue.toLowerCase()

    if (item.user.name.firstName.toLowerCase().includes(value)) return true
    if (item.user.name.lastName.toLowerCase().includes(value)) return true

    for (let i = 0; i < item.orders.length; i++) {
      const ord = item.orders[i]
      const orderNum = getOrderNum(ord.orderInfo.orderNum)
      if (orderNum.includes(value)) return true
      for (let j = 0; j < ord.items.length; j++) {
        const orderItem = ord.items[j]
        if (orderItem.item.product.name.toLowerCase().includes(value)) {
          return true
        }
      }
    }

    return false
  }

/**Creates a range based on a date, from the start until the end of that specific day */
export const createDayRange = (startDate: DateTime): DateRange => {
  startDate = startDate.startOf('day')
  const endDate = startDate.endOf('day')
  return { startDate, endDate }
}

/** Whether the `DistributionSummaryItem` has `Vacation` or `Cancelled` status*/
export const isDistroSummaryItemSkipped = (distroItem: DistributionSummaryItem) => {
  const status = getDistroSummaryStatus(distroItem)
  return status === PickupItemStatus.Cancelled || status === PickupItemStatus.Vacation
}

/**
 * Extracts the `status` of the item. Each `DistributionSummaryItem` will have only one status
 * @returns `status` of the item
 */
export const getDistroSummaryStatus = (distroItem: DistributionSummaryItem) => {
  return distroItem.item.selectedUnits[0].status
}

/** Whether the `DistributionSummaryItem` has `Active` status*/
export const isDistroSummaryItemActive = (distroItem: DistributionSummaryItem) => {
  const status = getDistroSummaryStatus(distroItem)
  return status === PickupItemStatus.Active
}

/**Extracts and transforms the data from a `DistributionDetailsSummary` to a `LocationSummary` compatible data
 * - Each product is shown once, with all its quantities summed
 * - Products are grouped by `Product.type`
 * - The result does not include cancelled / vacation items, as they should not show in the LocationSummary
 */
export const getLocationSummaryRows = (data: DistributionDetailsSummary) => {
  const elements: ItemType[] = []

  data.items.forEach((element) => {
    const val = element.item

    const status = val.selectedUnits[0].status

    // This will skip the item if cancelled / vacation
    if (status === PickupItemStatus.Cancelled || status === PickupItemStatus.Vacation) {
      return
    }

    const elIdx = elements.findIndex((item) => item.id === val.product.id)
    if (elIdx === -1) {
      elements.push({
        quantity: val.selectedUnits[0].quantity,
        status,
        id: val.product.id,
        name: val.product.name,
        type: val.product.type,
        unit: val.selectedUnits[0].unit,
      })
    } else {
      elements[elIdx] = { ...elements[elIdx], quantity: elements[elIdx].quantity + val.selectedUnits[0].quantity }
    }
  })

  return groupByObj(elements, (itm) => itm.type)
}

export const parseDateString = (date: string, timezone: Farm['timezone']) => {
  return DateTime.fromISO(date, { zone: timezone }).startOf('day')
}

/**Loads the Big Query summary by creating a one-day date range
 * @throws error if the summary has no items
 */
export const getBQSummary = async (farmId: string, date: DateTime, locationId: string) => {
  const dateRange = createDayRange(date)

  const summary = await loadDistributionDetails(farmId, dateRange, { locationId })

  if (!summary.items.length) {
    throw Error('No available data for selected date and location')
  }
  return summary
}

/** Whether the current summary data is invalid so new data can be loaded */
export const shouldLoadBQData = (summary: DistributionDetailsSummary, locationId: string, date: DateTime) => {
  if (summary.items.some((item) => item.location.id !== locationId)) return true
  if (!summary.dateRange.startDate.equals(date)) return true

  return false
}
