import { auth, isSetApp, setApp, setAppCheck, setAuth, setDb, setFunctions, setStorage } from '@api/db'
import { isJest } from '@helpers/Platform'
import Constants from 'expo-constants'
import { deleteApp, getApp, initializeApp } from 'firebase/app'
import {
  connectFirestoreEmulator,
  initializeFirestore,
  memoryLocalCache,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

import { AppCheck } from './AppCheck'
import env, { getRunConfiguration, isEmul } from './Environment'

import { isWeb } from '@/constants/Layout'
import { Authentication } from './Auth'

/** This determines the type of cache to use for Firestore, on mobile we want memoryLocal, and on web we want persistentLocal */
const dbCache =
  isWeb && !isEmul && !env.IS_COMP
    ? persistentLocalCache({ tabManager: persistentMultipleTabManager() })
    : memoryLocalCache()

/** Helper for initializing the database client */
export function initializeDb() {
  /** This effect will not run a second time during regular development, except when you make changes to this file or any of its dependencies. In such case, deleting the app before reinitializing will prevent a development error in android on reload: "FirebaseError: Firebase: Error (auth/already-initialized)." */
  if (isSetApp()) deleteApp(getApp())

  if (isJest && !env.IS_COMP) return // This shouldn't run on jest tests, except if they're for the rn-testing-lib

  if (env.IS_COMP && !isEmul) return //Db is not allowed for component navigator testing on live environments. Please use the emulator

  const firebaseApp = initializeApp(getRunConfiguration())
  const functions = getFunctions(firebaseApp)
  const storage = getStorage(firebaseApp)
  const db = initializeFirestore(firebaseApp, {
    ignoreUndefinedProperties: true,
    /**
     * I'm setting this to undefined because 'experimentalAutoDetectLongPolling' is set to true, and it will try longPolling as the default
     */
    experimentalForceLongPolling: undefined,
    /**
     *This is only a setting that puts the above one ('experimentalForceLongPolling') in automatic, so it's only used when necessary supposedly. Also, it's true by default, but here I'm setting it to true anyway, in the hopes nobody else changes this by accident. https://firebase.google.com/docs/reference/js/firestore_.firestoresettings.md#firestoresettings_interface
     *
     */
    experimentalAutoDetectLongPolling: true,
    localCache: dbCache,
  })

  setAppCheck(new AppCheck(firebaseApp))

  setApp(firebaseApp)
  setDb(db)
  setFunctions(functions)
  setAuth(new Authentication(firebaseApp))
  setStorage(storage)

  if (isEmul) {
    const origin = Constants.expoConfig?.hostUri?.split(':').shift() || 'localhost'
    // const origin = '10.0.2.2' // 10.0.2.2 is the special IP address to connect to the 'localhost' of the host computer from an Android emulator.
    connectFunctionsEmulator(functions, origin, 5001)
    auth().connectAuthEmulator(`http://${origin}:9099`)
    connectFirestoreEmulator(db, origin, 8080, {})
    connectStorageEmulator(storage, origin, 9199)
  }
}
