import { MultiFactorManage } from '@/components'
import { Logger } from '@/config/logger'
import { shouldShowReLoginAlert } from '@/hooks/useMultiFactor/helpers'
import { useMultiFactor } from '@/hooks/useMultiFactor/useMultiFactor'
import { userSelector } from '@/redux/selectors'
import { createResetPassword, createVerifyEmail } from '@api/ExternalLinks'
import { auth } from '@api/db'
import { Alert, Modal } from '@elements'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { SecurityScreenView } from './SecurityScreen.view'

/** User Security screen*/
export function SecurityScreen() {
  const user = useSelector(userSelector)
  const { onUnEnroll, isEnrolled, canBeUnEnrolled } = useMultiFactor()

  const isEmailVerified = auth().getEmailVerified()

  const sendResetPasswordEmail = () => {
    Alert('Reset Password', `An email to reset your password was sent to ${user.email}.`)
    createResetPassword(user.email)
  }

  const onVerifyEmail = useCallback(async () => {
    try {
      await createVerifyEmail(user.email, user.id)

      Alert(
        'Email Sent',
        `We have sent an email to ${user.email} with a link to verify this address. Please check your email.`,
      )
    } catch (err) {
      Alert(
        'Error',
        'We were unable to send a verification email to you. Please try again or contact support if the issue persists.',
      )
      Logger.error(err)
    }
  }, [user.email, user.id])

  const onTwoFactorPress = useCallback(() => {
    if (isEmailVerified) {
      return Modal(<MultiFactorManage />, { title: 'Two-Factor Authentication (2FA)' })
    }

    onVerifyEmail()
  }, [isEmailVerified, onVerifyEmail])

  const onDisableMFA = useCallback(async () => {
    try {
      if (!isEnrolled) return

      await onUnEnroll?.()
    } catch (err) {
      if (shouldShowReLoginAlert(err)) {
        return Alert('Security Alert', 'For security reasons, please log out and log back in to continue.')
      }
      Alert('Error', 'There was an error in the unenrollment process. Please try again later.')
    }
  }, [isEnrolled, onUnEnroll])

  return (
    <SecurityScreenView
      isEnrolled={isEnrolled}
      sendResetPasswordEmail={sendResetPasswordEmail}
      canBeUnEnrolled={canBeUnEnrolled}
      onDisableMFA={onDisableMFA}
      onTwoFactorPress={onTwoFactorPress}
    />
  )
}
