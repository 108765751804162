import { snapshotFarm } from '@api/Farms'
import { Distribution } from '@models/Distribution'
import { Certification, Farm } from '@models/Farm'
import { Location } from '@models/Location'
import { Product } from '@models/Product'
import { isFarmAdmin } from '@models/User'
import { dequal } from 'dequal'
import { Dispatch } from 'redux'

import { RootState } from '../reducers/types'
import {
  SET_ADMIN_DISTROS,
  SET_ADMIN_FARM,
  SET_ADMIN_FARMID,
  SET_ADMIN_LOCS,
  SET_ADMIN_PERMISSIONS,
  SET_ADMIN_PRODUCTS,
  SET_CATEGORIES,
  SET_CERTIFICATIONS,
  SET_FARM_LISTENER,
  SET_IS_ADMIN,
} from './actionTypes'

export const setIsAdmin = (value: boolean) => ({
  type: SET_IS_ADMIN,
  payload: value,
})

/** Sets a listener to the farm id, which saves farm data updates to adminPersist.
 * - Used for setting which is the currently selected admin farm.
 * - It will only run if the farmId is different than the current, or if the listener isn't set.
 * - Won't run if there isn't a farm association for the provided id
 * - It can be used for the current admin selected farm, AND also for waiting for a farm registration status to become "Registered".
 * */
export const setCurrFarmAdmin = (adminFarmId: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    // check if there's a farm association with the id
    const association = getState().appPersist.farmsAssoc.find(
      (fassoc) => fassoc.id === adminFarmId && isFarmAdmin(fassoc),
    )

    //Check if we need to update the admin farmId. Only do so if a matching association was found
    const currentAdminFarmId = getState().adminPersist.adminFarmId
    if (currentAdminFarmId !== adminFarmId && !!association) {
      dispatch({
        type: SET_ADMIN_FARMID,
        payload: adminFarmId,
      })

      // Set the permission data (role and custom permissions) and keep it updated whenever we setting the current admin farm or switch to different farm.
      dispatch({
        type: SET_ADMIN_PERMISSIONS,
        payload: { role: association?.role, permissions: association?.customPermissions },
      })
    }

    // don't recreate listener if it's already set to the current farm
    const listenerExists = getState().adminState.farmUnListener !== undefined
    const adminFarm = getState().adminPersist.farm
    if (adminFarmId === adminFarm.id && listenerExists) return

    // remove any previous listener we may have
    getState().adminState.farmUnListener?.()
    // Create a new listener
    const newUnListener = await snapshotFarm(adminFarmId, (farm?: Farm) => {
      //find a maching farm association on each update
      const association = getState().appPersist.farmsAssoc.find(
        (fassoc) => fassoc.id === adminFarmId && isFarmAdmin(fassoc),
      )

      if (!dequal(farm, getState().adminPersist.farm) && !!association)
        dispatch({
          type: SET_ADMIN_FARM,
          payload: farm,
        })

      if (getState().adminPersist.adminFarmId !== adminFarmId && !!association) {
        dispatch({
          type: SET_ADMIN_FARMID,
          payload: adminFarmId,
        })
      }

      const currentPermission = getState().adminPersist.permissions

      //Should update the permissions when the farm data is changed and the permissions are different
      if (!dequal(currentPermission, { role: association?.role, permissions: association?.customPermissions }))
        dispatch({
          type: SET_ADMIN_PERMISSIONS,
          payload: { role: association?.role, permissions: association?.customPermissions },
        })
    })
    // Add the new unListener and current farm to redux
    dispatch({
      type: SET_FARM_LISTENER,
      payload: newUnListener,
    })
  }
}

export const setAdminSchedules = (distros: Distribution[]) => ({
  type: SET_ADMIN_DISTROS,
  payload: distros,
})

export const setAdminLocations = (locs: Location[]) => ({
  type: SET_ADMIN_LOCS,
  payload: locs,
})

export const setCertifications = (certs: Certification[]) => ({
  type: SET_CERTIFICATIONS,
  payload: certs,
})

export const setCategories = (cats: string[]) => ({
  type: SET_CATEGORIES,
  payload: cats,
})

export const setAdminProducts = (prods: Product[]) => ({
  type: SET_ADMIN_PRODUCTS,
  payload: prods,
})
