import { useSelector } from 'react-redux'

import { CartService } from '../../constants/types/cartService'

import { adminCartServiceSelector, cartServiceSelector } from '@/redux/selectors'

/** Selects the cart service for either consumer or admin cart.
 * @param isAdmin if true, will return the admin cart. Else the consumer cart.
 */
export function useCartService(isAdmin = false): CartService {
  return useSelector(isAdmin ? adminCartServiceSelector : cartServiceSelector)
}
