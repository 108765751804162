import { CheckBox, Text } from '@elements'
import { memo, useContext } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import Colors from '../../../constants/Colors'
import { SIDEBAR_WIDTH } from '../../../constants/Layout'

import { AlgoliaFarmDataContext } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData'
import { FarmDataContext } from '@/hooks/useFarmData'
import { ShoppingStackParamList } from '@/navigation/types'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { LocationDropdown } from '../components/LocationDropdown/LocationDropdown'
import { ProductTermFilter } from '../components/ProductTermFilter/ProductTermFilter'
import { useShopSidebarData } from './sidebarHelpers'

export const ShopSideBar = memo(function ShopSideBar() {
  const { canFilterByEbt, ebtOnly, searchTerm } = useContext(AlgoliaFarmDataContext)
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()

  const { farm } = useContext(FarmDataContext)
  const { filterGroups, onButtonPress } = useShopSidebarData()
  return (
    <View style={styles.container}>
      <ProductTermFilter farmName={farm.data?.name ?? ''} initialQuery={searchTerm ?? ''} />
      <LocationDropdown />
      {canFilterByEbt ? (
        // If there are no products and this was not caused by this filter, hide it.
        <View style={styles.spacing}>
          <CheckBox
            color={Colors.shades[200]}
            onChange={(value) => navigation.setParams({ ebtOnly: value || undefined })}
            checked={!!ebtOnly}
            titleStyle={styles.checkBoxText}
            textWeight="regular"
            title="SNAP/EBT eligible"
            size={18}
          />
        </View>
      ) : null}
      {filterGroups.map((group) => (
        <View key={group.title}>
          <View key={group.title} style={styles.titleCont}>
            <Text type="medium">{group.title}</Text>
          </View>
          {group.items.map((itm) => (
            <TouchableOpacity onPress={() => onButtonPress(itm)} style={styles.categoryCont} key={itm.value}>
              <Text color={itm.isRefined ? Colors.green : undefined}>{itm.label}</Text>
            </TouchableOpacity>
          ))}
        </View>
      ))}
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    width: SIDEBAR_WIDTH,
    borderRightWidth: 1,
    borderRightColor: Colors.shades['100'],
    padding: 20,
    gap: 10,
  },
  spacing: {
    marginVertical: 10,
  },

  categoryCont: {
    marginLeft: 16,
    marginBottom: 10,
  },
  titleCont: {
    marginTop: 10,
    marginBottom: 5,
  },
  checkBoxText: {
    color: Colors.shades['600'],
    fontSize: 14,
  },
})
