import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Invoice, invoiceRefundAmount, InvoiceStatus } from '@models/Invoice'
import { moneyIsZero } from '@models/Money'
import { hasGrownByPrivilege, User, UserRole } from '@models/User'

import { ActionBtn } from '@/admin/components/elements/ActionsMenuComponent'
import {
  addInvoiceAdjustment,
  changeInvoicePayments,
  chargeInvoice,
  markInvoicePaid,
  refundInvoice,
  toggleVoidInvoice,
} from '../../Customer/AdminCustomerInvoicesSection/helpers/invoiceActions'

/** This function will generate all the action depend on current Invoice status */
export const invoiceActionGenerator = (invoice: Invoice, user: User): ActionBtn<Invoice>[] => {
  const result: ActionBtn<Invoice>[] = []

  if (moneyIsZero(invoice.amountTotal)) {
    result.push({
      title: `N/A`,
      onPress: () => null,
      disabled: () => true,
    })
    return result
  }

  // if invoice is fully refunded, then no action can be taken
  if (MoneyCalc.isGTE(invoiceRefundAmount(invoice), invoice.amountTotal)) {
    result.push({
      title: `Refunded (${formatMoney(invoiceRefundAmount(invoice))})`,
      onPress: () => null,
      disabled: () => true,
    })

    return result
  }

  // paid invoice can be refunded and refunded invoice can be refunded again
  if (invoice.status === InvoiceStatus.Paid || invoice.status === InvoiceStatus.Refunded) {
    result.push({
      title: `Refund (${formatMoney(MoneyCalc.subtract(invoice.amountTotal, invoiceRefundAmount(invoice)))})`,
      onPress: (invoice) => refundInvoice(invoice),
    })
    return result
  } else if (invoice.status === InvoiceStatus.Void) {
    result.push({ title: 'Un-void', onPress: (invoice) => toggleVoidInvoice(invoice, false) })
    return result
  } else if (invoice.status === InvoiceStatus.Due || invoice.status === InvoiceStatus.Failed) {
    if (
      !invoice.payments.worldpay_ebt &&
      (invoice.payments.stripe || invoice.payments.stripe_ach || invoice.payments.farmcredit)
    ) {
      result.push({ title: 'Charge', onPress: (invoice) => chargeInvoice(invoice) })
    }
    if (hasGrownByPrivilege(user, UserRole.Admin)) {
      result.push({ title: 'Change Payments', onPress: (invoice) => changeInvoicePayments(invoice) })
    }
    result.push({ title: 'Adjust Invoice', onPress: (invoice) => addInvoiceAdjustment(invoice) })
    result.push({ title: 'Mark as paid', onPress: (invoice) => markInvoicePaid(invoice) })
    result.push({ title: 'Void', onPress: (invoice) => toggleVoidInvoice(invoice, true) })
    return result
  } else if (invoice.status === InvoiceStatus.Incomplete) {
    result.push({ title: 'Void', onPress: (invoice) => toggleVoidInvoice(invoice, true) })
    return result
  }
  return result
}
