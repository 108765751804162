import { BodyProps, Text, Touchable } from '@elements'
import { Entypo } from '@expo/vector-icons'
import { ReactElement, ReactNode, isValidElement, memo, useEffect, useMemo, useState } from 'react'
import { ListRenderItemInfo, StyleProp, TextStyle, View, ViewStyle } from 'react-native'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'

export type Column<T> = {
  /** Flex value for column width */
  widthFlex?: number
  /** Function that returns the content to display on the column, for a given row, based on the current data point */
  process?: (item: T) => string | ReactElement
  /** Destination url for the column of a given row */
  colPressUrl?: (item: T) => string
}

export type ExpandableRowProps<T> = {
  /** Specification for the row's columns */
  columns?: Column<T>[]
  /** Will be called on expand */
  onExpand?: () => void
  /** Will be called on the first expand */
  onFirstExpand?: () => void
  /** If provided, row container will be a Touchable instead of a View */
  onRowPress?: (item: T) => void
  /** If provided, row container will be a Touchable link, instead of View */
  onRowPressUrl?: (item: T) => string
  /** Indentation for entire row component. Meant for use by subRows, if returning this component inside generateSubRows */
  indent?: number
  /** Determines how the indent is applied. 'first-col' will apply the indent as paddingLeft on the left-most column of the row, thereby not interfering with the position of other columns. If 'row', indent will be  applied to the entire row container. Default value is 'first-col' */
  indentMode?: 'first-col' | 'row'
  /** If the given list item should have collapsable sub-rows, this function must return those additional row elements. It's possible to return ExpandableRows here too */
  generateSubRows?: (item: T) => JSX.Element[] | undefined
  /** Style for the entire row container  */
  rowContainerStyle?: StyleProp<ViewStyle>
  /** Give the name for accordion Icon (string). DO NOT REPLACE THIS WITH YOUR FAVORITE ICON LIBRARY. */
  entypoAccordionIcon?: { expand: keyof typeof Entypo.glyphMap; collapse: keyof typeof Entypo.glyphMap }
  /** The expandInitially refers to whether the subRows should be expanded (shown) as the initial state of the component. By default the subRows will be collapsed. */
  expandInitially?: boolean
  /** Whether this is the last row in the list. This is useful for handling styles differently in the last row of the table */
  isLastRow?: boolean
} & Omit<ListRenderItemInfo<T>, 'separators'>

function ExpandableRowComp<T>({
  columns,
  onFirstExpand,
  onRowPress,
  onRowPressUrl,
  item,
  index: rowIdx, //The row index that will be used as unique react key for the row
  isLastRow,
  indent = 0,
  indentMode = defaultIndentMode,
  generateSubRows,
  rowContainerStyle,
  entypoAccordionIcon,
  expandInitially = false,
  onExpand,
}: ExpandableRowProps<T>) {
  const [showSubRows, setShowSubRows] = useState(expandInitially)
  const [hasExpanded, setHasExpanded] = useState(false)

  /** This effect must only run once, when the row first expands */
  useEffect(() => {
    onExpand?.()
    if (showSubRows && !hasExpanded) {
      onFirstExpand?.()
      setHasExpanded(true)
    }
    // Will intentionally ignore changes to onFirstExpand and onExpand
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSubRows, hasExpanded])

  // The elements for each column of the given row
  const rowElements = useMemo(
    () =>
      columns?.map(function ({ process, colPressUrl, widthFlex = 1 }, colIdx) {
        /** The base content of the column */
        const colContent = process?.(item) ?? null

        /** A basic adapter for the column content which may be a string */
        const colContentStringAdapter =
          typeof colContent === 'string' ? (
            <ColumnText colIdx={colIdx} rowIdx={rowIdx} isUrl={!!colPressUrl}>
              {colContent}
            </ColumnText>
          ) : isValidElement(colContent) ? (
            colContent
          ) : null

        const needsSpacer = getNeedsSpacer({ colIdx, hasSubrows: !!generateSubRows })

        /** If it's the first column and has a subRows generator, it needs to have the icon added as a sibling, while maintaining the flexWidth controlled by a View parent */
        if (needsSpacer) {
          return (
            <SpacerColumnWrapper
              key={`tableRow-${rowIdx.toString()}_tableCol-${colIdx.toString()}`}
              widthFlex={widthFlex}
              indent={indent}
              indentMode={indentMode}
              name={
                showSubRows
                  ? entypoAccordionIcon?.expand || 'chevron-up'
                  : entypoAccordionIcon?.collapse || 'chevron-down'
              }
              onPress={() => setShowSubRows((prev) => !prev)}
              colContainer={
                colPressUrl ? (
                  <Touchable
                    key={`tableRow-${rowIdx.toString()}_tableCol-${colIdx.toString()}`}
                    // if needsSpacer true, it gets flex1 because it is inside the wrapper
                    style={globalStyles.flex1}
                    url={colPressUrl(item)}
                    children={colContentStringAdapter}
                  />
                ) : (
                  colContentStringAdapter
                )
              }
            />
          )
        } else {
          return colPressUrl ? (
            // If there is a column url will wrap in a touchable container
            <Touchable
              key={`tableRow-${rowIdx.toString()}_tableCol-${colIdx.toString()}`}
              style={styles.colContainer(widthFlex)}
              url={colPressUrl(item)}
              children={colContentStringAdapter}
            />
          ) : (
            // By default the column content is wrapped in a View
            <View
              key={`tableRow-${rowIdx.toString()}_tableCol-${colIdx.toString()}`}
              style={styles.colContainer(widthFlex)}
              children={colContentStringAdapter}
            />
          )
        }
      }),
    [columns, entypoAccordionIcon, generateSubRows, indent, indentMode, item, rowIdx, showSubRows],
  )

  /** The container element for the entire row */
  const rowContainer = useMemo(
    () =>
      onRowPress || onRowPressUrl ? (
        <Touchable
          style={[styles.tableRow({ indent, indentMode, isLastRow }), rowContainerStyle]}
          onPress={() => onRowPress?.(item)}
          url={onRowPressUrl?.(item)}
          children={rowElements}
          key={`tableRowContainer-${rowIdx}`}
        />
      ) : (
        <View
          style={[styles.tableRow({ indent, indentMode, isLastRow }), rowContainerStyle]}
          children={rowElements}
          key={`tableRowContainer-${rowIdx}`}
        />
      ),
    [rowElements, onRowPress, onRowPressUrl, indent, indentMode, item, rowContainerStyle, rowIdx, isLastRow],
  )

  const subRows = useMemo(() => generateSubRows?.(item) ?? [], [item, generateSubRows])

  //Returns the entire row element and sub-rows, if any
  return (
    <>
      {rowContainer}
      {showSubRows && subRows}
    </>
  )
}

/** Helper component for rows of the OfflineTable. Supports nested accordions and extensive configuration of rows, by columns as sections.
 * - This should be kept as a reusable component, agnostic to algolia or anything else.
 * - It's stateless other than for showing/hiding the `subRows`, so if you need state inside a row, just manage the state in a wrapper component instead of modifying this one for a single use-case.
 */
export const ExpandableRow = memo(ExpandableRowComp) as typeof ExpandableRowComp

/** Determines if the column of a row needs special spacing to handle the first column which has the chevron */
export function getNeedsSpacer({ colIdx, hasSubrows }: { colIdx: number; hasSubrows: boolean }) {
  return colIdx === 0 && !!hasSubrows
}

/** Wraps the column container inside a special View that adds a chevron arrow to represent the show/hide status of the subRows */
export function SpacerColumnWrapper({
  colContainer,
  indent = 0,
  indentMode = defaultIndentMode,
  widthFlex = 1,
  onPress,
  name,
}: { colContainer: ReactNode; widthFlex?: number; onPress?: () => void; name?: keyof typeof Entypo.glyphMap } & Pick<
  ExpandableRowProps<any>,
  'indentMode' | 'indent'
>) {
  return (
    <View style={styles.firstContainer(widthFlex)}>
      <Entypo
        name={name}
        size={20}
        style={styles.accordionIcon({ indentMode, indent })}
        color={Colors.shades['500']}
        onPress={onPress}
        key="accordion"
      />
      {colContainer}
    </View>
  )
}

type ColumnTextProps = {
  rowIdx: number
  colIdx: number
  isUrl?: boolean
} & BodyProps

/** The default text component for the table row when the column data is a plain string. */
export function ColumnText({ rowIdx, colIdx, isUrl = false, children, style, numberOfLines = 2 }: ColumnTextProps) {
  return (
    <Text
      style={[styles.colContentStringAdapter(isUrl), style]}
      key={`tableRow-${rowIdx.toString()}_tableCol-${colIdx.toString()}_colChild`}
      numberOfLines={numberOfLines}
    >
      {children}
    </Text>
  )
}

const paddingHor = 20

const defaultIndentMode = 'first-col'

export const styles = {
  tableRow: ({
    indent = 0,
    indentMode = defaultIndentMode,
    isLastRow = false,
  }: Pick<ExpandableRowProps<any>, 'indent' | 'indentMode'> & { isLastRow?: boolean } = {}): ViewStyle => ({
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 15,
    paddingHorizontal: paddingHor,
    paddingLeft: paddingHor + (indentMode === 'row' ? indent : 0),
    ...(isLastRow
      ? {}
      : {
          borderBottomWidth: 1,
          borderBottomColor: Colors.shades['100'],
        }),
  }),
  colContainer: (widthFlex = 1): ViewStyle => ({ flex: widthFlex, marginHorizontal: 10 }),
  /** The firstContainer style fn is meant to be the same as the 'colContainer', with the addition of row and center */
  firstContainer: (widthFlex = 1): ViewStyle => ({
    flex: widthFlex,
    marginHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  accordionIcon: ({
    indent = 0,
    indentMode = defaultIndentMode,
  }: Pick<ExpandableRowProps<any>, 'indent' | 'indentMode'>): ViewStyle => ({
    paddingLeft: indentMode === 'first-col' ? indent : 0,
    minWidth: 20,
    minHeight: 20,
    marginRight: 10,
  }),
  colContentStringAdapter: (isUrl: boolean): StyleProp<TextStyle> => [
    { flex: 1 },
    isUrl ? { color: Colors.blue, textDecorationLine: 'underline' } : {},
  ],
}
