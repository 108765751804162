import { updateProduct } from '@api/Products'
import { ArrElement } from '@helpers/typescript'
import { Money } from '@models/Money'
import { DigitalProduct, GlobalStandard, StockType, UnitBase } from '@models/Product'

export const saveGlobalUnitPrice = async (
  newPrice: Money,
  product: GlobalStandard | DigitalProduct,
  unit: UnitBase<StockType.Global>,
) => {
  const updatedUnit: ArrElement<GlobalStandard['units']> = { ...unit }

  // This is updating the first price only, since we're currently not using the rest of the prices
  updatedUnit.prices = [{ ...unit.prices[0], amount: newPrice }, ...unit.prices.slice(1)]

  const updatedProduct = { ...product } as typeof product

  updatedProduct.units[product.units.findIndex((u) => u.id === unit.id)] = updatedUnit

  return updateProduct(updatedProduct)
}
