import { addCustomer, AddCustomerData, GetCustomerImportTemplate } from '@api/Customers'
import { validatePhoneNumber } from '@api/Users'
import { PhoneInput, ToolTips } from '@components'
import { Button, Divider, fontSize, HeaderText, Modal, TextH1, Toast, Tooltip, typography } from '@elements'
import { openUrl } from '@helpers/client'
import { formatMoney, unmarshalPhoneNumber } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { CustomerFilters, openCustomersCSV } from '@helpers/links'
import { AlgoliaAdminCustomer, AlgoliaAdminDoc, AlgoliaDocType } from '@models/Algolia'
import { Farm } from '@models/Farm'
import { User } from '@models/User'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { FormikProps } from 'formik'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-native'
import { View } from 'react-native'
import { Input } from 'react-native-elements'
import { useSelector } from 'react-redux'
import { FormBuilder } from '../../../components/FormBuilder'
import { FormikStep, FormikStepper } from '../../../components/FormikStepper'
import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { isWeb } from '../../../constants/Layout'
import { flexContainer, globalStyles } from '../../../constants/Styles'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { withAdminIndex } from '../../../hooks/withAlgoliaIndex'
import { RootState } from '../../../redux/reducers/types'
import { adminFarmSelector, userSelector } from '../../../redux/selectors'
import { AdminTable } from '../../components/AdminTable/AdminTable'
import { AdminView } from '../../components/AdminView'

import { AdminCard, AdminHalfCardsContainer } from '@/admin/components/AdminCard'
import { defaultRefinementLimit } from '@/admin/components/AdminTable/ConnectedRefinementDropdown'
import Export from '@/admin/components/Export'
import { InviteCustomerPrompt } from '@/admin/components/InviteCustomerPrompt'
import { CustomerParamList } from '@/admin/navigation/types'
import { customerDetailSchema } from '@/admin/screens/Customer/Yup/customerDetailYup'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { UseLayoutReturn } from '@/hooks/useLayout'
import { Permission } from '@helpers/Permission'

function AdminCustomerScreen() {
  const [screen, setScreen] = useState(1)
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const user = useSelector(userSelector)
  const [newCustomer, setNewCustomer] = useState<Partial<User>>({})
  const styles = useStyles()
  const inviteCustomerPermit = useHasPermissionWithFlag(Permission.AccountSetup)

  const [csaFilterShowMore, setCsaFilterShowMore] = useState(false)
  const [locFilterShowMore, setLocFilterShowMore] = useState(false)
  const [scheduleFilterShowMore, setScheduleFilterShowMore] = useState(false)

  const { params } = useRoute<RouteProp<CustomerParamList, 'CustomerList'>>()

  const navigation = useNavigation<StackNavigationProp<CustomerParamList, 'CustomerList'>>()

  /** This function should be called after defaultCsaRefinement (if exists) is applied every time. */
  const onDefaultCsaRefinementApplied = useCallback(() => {
    // resets csaName parameter
    navigation.setParams({ csaName: undefined })
  }, [navigation])

  const goInviteCustomerPrompt = () => {
    Modal(<InviteCustomerPrompt setScreen={setScreen} setNewCustomer={setNewCustomer} />, { title: 'Add Customer' })
  }

  const onSubmit = async (values: AddCustomerData) => {
    if (values.phoneNumber) {
      try {
        //The user.id part should be empty string because the new user we would like to add is not in the database yet
        values.phoneNumber = await validatePhoneNumber(values.phoneNumber, '', false)
      } catch (err) {
        Logger.debug(err)
        Toast(errorToString(err))
        return
      }
    }
    try {
      values.email = values.email.trim()
      values.email2 = values.email2 ? values.email2.trim() : ''
      await addCustomer(values, farm, user)
      setScreen(1)
    } catch (err) {
      Logger.debug(err)
      Toast('Email already in use for a customer.')
      setScreen(1)
    }
  }

  const onDownloadTemplate = async () => {
    const url = await GetCustomerImportTemplate()
    try {
      if (isWeb) {
        window.open(url, '_parent')
      } else {
        openUrl(url)
      }
    } catch (err) {
      Logger.debug(err)
      throw err
    }
  }

  if (screen === 1) {
    return (
      <AdminView>
        <View style={styles.wrapper}>
          <View style={globalStyles.flexRowCenter}>
            <HeaderText size={30}>Customers</HeaderText>
            <Tooltip title="Customers" size={15} id={ToolTips.CUSTOMERS} />
          </View>
          <View style={styles.buttonContainer}>
            <ExportCSV />
            {inviteCustomerPermit && (
              <Button
                small
                size={15}
                title="Add or Invite Customer"
                style={styles.addButton}
                textStyle={styles.addButtonText}
                onPress={goInviteCustomerPrompt}
              />
            )}
          </View>
          <AdminTable<AlgoliaAdminCustomer>
            type={AlgoliaDocType.CUSTOMER}
            sortOptionKeys={['farmCredit']}
            columns={[
              {
                title: 'Name',
                key: 'name',
              },
              {
                title: 'Email',
                key: 'email',
                size: 2,
                process: (user) => user.email,
              },
              {
                title: 'Phone Number',
                key: 'phone',
                process: (user) => unmarshalPhoneNumber(user.phone, false),
              },
              {
                title: 'Farm Credit',
                key: 'farmCredit',
                process: (user) => (user.farmCredit ? formatMoney(user.farmCredit) : formatMoney(0)),
              },
            ]}
            refinements={[
              {
                attribute: 'csas.name',
                placeholder: 'All CSAs',
                limit: defaultRefinementLimit,
                showMoreLimit: defaultRefinementLimit * 3,
                setShowMore: setCsaFilterShowMore,
                showMore: csaFilterShowMore,
                defaultRefinement: params?.csaName,
                onDefaultRefinementApplied: onDefaultCsaRefinementApplied,
              },

              {
                attribute: 'locations.name',
                placeholder: 'All Locations',
                limit: defaultRefinementLimit,
                showMoreLimit: defaultRefinementLimit * 3,
                setShowMore: setLocFilterShowMore,
                showMore: locFilterShowMore,
              },
              {
                attribute: 'distributions.name',
                placeholder: 'All Schedules',
                limit: defaultRefinementLimit,
                showMoreLimit: defaultRefinementLimit * 3,
                setShowMore: setScheduleFilterShowMore,
                showMore: scheduleFilterShowMore,
              },
            ]}
            expandRefinementsInitial={params?.csaName ? true : undefined}
            searchPlaceholder="Email, name or phone"
            onRowPress={(user) => navigation.navigate('CustomerDetails', { custId: user.id })}
          />
        </View>
      </AdminView>
    )
  } else if (screen === 2) {
    return (
      <AdminView>
        <TextH1 style={styles.addCustomerTextH1}>Add Customer</TextH1>
        <Divider />
        <FormikStepper
          initialValues={{
            firstName: newCustomer.name?.firstName || '',
            lastName: newCustomer.name?.lastName || '',
            email: newCustomer.email || '',
            email2: newCustomer.email2 || '',
            phoneNumber: newCustomer.phoneNumber || '',
            street1: newCustomer.address?.street1 || '',
            street2: newCustomer.address?.street2 || '',
            city: newCustomer.address?.city || '',
            state: newCustomer.address?.state || '',
            zipcode: newCustomer.address?.zipcode || '',
          }}
          onSubmit={(values) => onSubmit(values as AddCustomerData)}
          hasBack
          backAction={() => setScreen(1)}
        >
          <FormikStep validationSchema={customerDetailSchema}>
            {({ touched, errors, values, handleChange, handleBlur }: FormikProps<AddCustomerData>) => (
              <>
                <AdminHalfCardsContainer>
                  <View>
                    <FormBuilder label="Name & Email">
                      <Input
                        placeholder="First Name"
                        value={values.firstName}
                        onChangeText={handleChange('firstName')}
                        onBlur={handleBlur('firstName')}
                        errorMessage={touched.firstName ? errors.firstName : ''}
                        rightIcon={
                          touched.firstName && errors.firstName
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                      <Input
                        placeholder="Last Name"
                        value={values.lastName}
                        onChangeText={handleChange('lastName')}
                        onBlur={handleBlur('lastName')}
                        errorMessage={touched.lastName ? errors.lastName : ''}
                        rightIcon={
                          touched.lastName && errors.lastName
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                      <Input
                        placeholder="Email Address 1"
                        value={values.email}
                        onChangeText={handleChange('email')}
                        onBlur={handleBlur('email')}
                        errorMessage={touched.email ? errors.email : ''}
                        rightIcon={
                          touched.email && errors.email
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                      <Input
                        placeholder="Email Address 2"
                        value={values.email2}
                        onChangeText={handleChange('email2')}
                        onBlur={handleBlur('email2')}
                        errorMessage={touched.email2 ? errors.email2 : ''}
                        rightIcon={
                          touched.email2 && errors.email2
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                      <PhoneInput
                        value={values.phoneNumber}
                        maskHandler={handleChange('phoneNumber')}
                        onBlur={handleBlur('phoneNumber')}
                        errorMessage={errors.phoneNumber}
                        renderErrorMessage={touched.phoneNumber}
                      />
                    </FormBuilder>
                  </View>
                  <View>
                    <FormBuilder label="Billing Address">
                      <Input
                        placeholder="Address Line 1"
                        value={values.street1}
                        onChangeText={handleChange('street1')}
                        onBlur={handleBlur('street1')}
                        errorMessage={touched.street1 ? errors.street1 : ''}
                        rightIcon={
                          touched.street1 && errors.street1
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                      <Input
                        placeholder="Address Line 2"
                        value={values.street2}
                        onChangeText={handleChange('street2')}
                        onBlur={handleBlur('street2')}
                        errorMessage={touched.street2 ? errors.street2 : ''}
                        rightIcon={
                          touched.street2 && errors.street2
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                      <Input
                        placeholder="City"
                        value={values.city}
                        onChangeText={handleChange('city')}
                        onBlur={handleBlur('city')}
                        errorMessage={touched.city ? errors.city : ''}
                        rightIcon={
                          touched.city && errors.city
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                      <Input
                        placeholder="State"
                        value={values.state}
                        onChangeText={handleChange('state')}
                        onBlur={handleBlur('state')}
                        errorMessage={touched.state ? errors.state : ''}
                        rightIcon={
                          touched.state && errors.state
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                      <Input
                        placeholder="Zip Code"
                        value={values.zipcode}
                        onChangeText={handleChange('zipcode')}
                        onBlur={handleBlur('zipcode')}
                        errorMessage={touched.zipcode ? errors.zipcode : ''}
                        rightIcon={
                          touched.zipcode && errors.zipcode
                            ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                            : false
                        }
                      />
                    </FormBuilder>
                  </View>
                </AdminHalfCardsContainer>
              </>
            )}
          </FormikStep>
        </FormikStepper>
      </AdminView>
    )
  }
  if (screen === 3) {
    return (
      <AdminView>
        <AdminCard>
          <TextH1 style={styles.overlayTitle}>Invite Customers to Join</TextH1>
          <View style={flexContainer()}>
            <Button
              icon="download"
              style={globalStyles.flex1}
              size={25}
              title="Download .csv template"
              onPress={() => onDownloadTemplate()}
              textStyle={{ fontSize: fontSize(15, 2) }}
            />
            {/* Hiding Import customers for now */}
            {/* <Button
                icon="file-export"
                style={globalStyles.flex1}
                size={25}
                title="Import customers from template"
                onPress={() => onUploadCSV()}
                textStyle={{ fontSize: fontSize(15, 2) }}
              /> */}
            <Button
              icon="backspace"
              style={globalStyles.flex1}
              size={25}
              title="Cancel / Go Back"
              onPress={() => setScreen(1)}
              textStyle={{ fontSize: fontSize(15, 2) }}
            />
          </View>
        </AdminCard>
      </AdminView>
    )
  } else return null
}

export default withAdminAuth(
  withAdminIndex(AdminCustomerScreen, AlgoliaDocType.CUSTOMER) as React.ComponentType,
  Permission.Orders,
)

/** connect to Customers list CSV EXCEL document */
const ExportCSV = connectStateResults(
  ({ searchState }: StateResultsProvided<AlgoliaAdminCustomer & AlgoliaAdminDoc>) => {
    const farm = useSelector<RootState, Farm>(adminFarmSelector)
    const styles = useStyles()

    let searchQuery: string | undefined
    const filters: CustomerFilters = {
      csaName: searchState.menu?.['csas.name'],
      productName: searchState.menu?.['products.name'],
      locationName: searchState.menu?.['locations.name'],
      distributionName: searchState.menu?.['distributions.name'],
    }

    if (searchState.query) {
      searchQuery = searchState.query
    }

    return (
      <Export
        style={styles.exportButton}
        title="Export to CSV"
        onPress={() => {
          openCustomersCSV(farm.id, filters, searchQuery)
        }}
      />
    )
  },
)

const useStyles = () =>
  useLayoutFnStyles((layout: UseLayoutReturn) => ({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      marginHorizontal: layout.width * 0.05 - 10,
    },

    containerRow: {
      flexDirection: 'row',
      marginTop: 10,
    },
    overlayTitle: {
      fontSize: 22,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 30,
    },
    exportButton: {
      marginRight: 10,
      marginBottom: layout.isExtraSmallDevice ? 10 : 0,
      alignSelf: layout.isExtraSmallDevice ? 'flex-start' : 'auto',
    },
    addButton: {
      paddingHorizontal: 10,
      alignSelf: layout.isExtraSmallDevice ? 'flex-start' : 'auto',
    },
    addButtonText: { fontFamily: typography.body.regular, fontWeight: '600', fontSize: 14 },
    buttonContainer: {
      marginVertical: 10,
      flexDirection: layout.isExtraSmallDevice ? 'column' : 'row',
    },
    errMsg: {
      color: Colors.red,
      fontSize: 12,
      marginHorizontal: 10,
    },
    wrapper: {
      marginHorizontal: layout.isSmallDevice ? 10 : 30,
      marginTop: layout.isSmallDevice ? 10 : 30,
    },
    addCustomerTextH1: { marginVertical: 20, marginLeft: 15, color: Colors.black },
    screen3Container: { alignItems: 'center', marginBottom: 20 },
  }))
