import { documentId, limit, where } from 'firebase/firestore'

import { Logger } from '@/config/logger'
import { splitToGroups } from '../constants/helpers/helpers'
import { Distribution } from '../models/Distribution'
import { Farm } from '../models/Farm'
import { isDigital, Product } from '../models/Product'
import { errorCatcher } from './Errors'
import { updateFarm } from './Farms'
import { distrosCollection, pickupsCollection, productsCollection } from './framework/ClientCollections'

// snapshotDistributionsByFarm returns the schedules associated with the supplied farm ID.
export function snapshotDistributionsByFarm(
  farmId: string,
  updateFn: (distributions: Distribution[]) => void,
  errorFn = errorCatcher,
): () => void {
  const q = distrosCollection.query(where('farm.id', '==', farmId))
  const snapshotDistroByFarm = distrosCollection.snapshotMany(q, updateFn, errorFn)
  return snapshotDistroByFarm
}

// loadDistributionsForProduct returns all of the schedules that have been assigned to the supplied product.
// FIXME: Apparently batching does not improve performance or lower cost, so it would probably be simpler to convert
// these back to regular requests
export async function loadDistributionsForProduct(product: Product): Promise<Distribution[]> {
  if (isDigital(product)) return []

  /* Firebase allows batch requests using the 'in' operation string,
  however it has a 10 request maximum, so I am splitting the requests into blocks
  of 10 then calling them all at once and waiting for them to all resolve
   */
  const requests: Promise<Distribution[]>[] = []
  const distributions: Distribution[] = []
  const groups = splitToGroups(product.distributions, 10)

  groups.forEach((group: Distribution[]) => {
    const ids = group.map((dist) => dist.id)
    requests.push(distrosCollection.fetchAll(where(documentId(), 'in', ids)))
  })
  try {
    const results = await Promise.all(requests)
    results.forEach((data) => {
      distributions.push(...data)
    })
  } catch (err) {
    Logger.error(err)
  }
  return distributions
}

// addDistribution adds a new distribution to the database.
export async function addDistribution(distribution: Distribution, farm?: Farm): Promise<Distribution> {
  const newDistro = await distrosCollection.create(distribution)
  // Update onboard walk-through
  if (farm && farm.onboardSteps && !farm.onboardSteps?.schedules) {
    updateFarm({ id: farm.id, onboardSteps: { ...(farm.onboardSteps || {}), schedules: true } })
  }
  return newDistro
}

// checks if a distribution has any pickups associated with it, or if any products are linked to it.
// If it does, then it cannot be deleted.  maybe there is a better way to do this check
export async function canDistributionBeDeleted(farmId: string, distroId: string): Promise<boolean> {
  const products = await productsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where(`distributions.${distroId}.id`, '==', distroId),
    limit(1),
  )

  const pickups = await pickupsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where('distribution.id', '==', distroId),
    limit(1),
  )

  return products.length === 0 && pickups.length === 0
}
