import { Hoverable, Portal, Text } from '@elements'
import * as React from 'react'
import { RefObject, createRef, memo, useCallback, useMemo, useState } from 'react'
import {
  FlatList,
  GestureResponderEvent,
  ListRenderItem,
  ListRenderItemInfo,
  Platform,
  Pressable,
  StatusBar,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from 'react-native'

import Colors from '../constants/Colors'
import { SHADOW_10 } from '../constants/Styles'
import { useDeepCompareLayoutFnStyles } from './useFnStyles'
import { useDeepCompareFocusFx } from './useFocusFx'
import { useDeviceSize, useLayout } from './useLayout'

export type AutoCompleteProps<T> = {
  /** Similar to an Id for the autocomplete instance */
  value: string
  /** The element using the autocomplete */
  sourceRef: RefObject<TextInput | View>
  /** Data to display in the autocomplete overlay */
  items: AutoCompleteItem<T>[]
  /** Callback to invoke when an autocomplete item is pressed */
  onSelect?: (item: AutoCompleteItem<T>) => void
  /** Props to customize how the autocomplete behaves */
  otherProps: {
    /** Will set the width of the autocomplete overlay to be the same as the source ref width. Else the width of the autocomplete will not be specified (auto based on the items) */
    matchWidth?: boolean
    /** renderItem is for customization of the element to render for each item in the list. The default uses a simple touchable text element */
    renderItem?: ListRenderItem<AutoCompleteItem<T>>
    /** Will act as an override to the container of the autocomplete list, useful for things like maxHeight, etc. */
    autoCompleteContStyle?: ViewStyle
  }
}

export type AutoCompleteItem<T> = {
  /** Text to display on the autocomplete item element */
  text: string
  /** Data about the item, may be useful inside the onSelect callback */
  data: T
}

/** Absolute position of an element on the screen */
export type Position = {
  top: number
  left: number
  width: number
  height: number
}

export const initialPos: Position = { top: 0, left: 0, width: 0, height: 0 }

type AutoCompleteState<T> = AutoCompleteProps<T> & {
  /** This is the position of the source element */
  sourcePos: Position
}

export type AutoCompleteReturn<T> = {
  /** Render this overlay inside any component and it will remain invisible until the showAutocomplete helper is called. When it becomes visible it will display content above any other screen content */
  autoCompleteOverlay: JSX.Element | null
  /** Shows the autocomplete overlay.
   * - This should be called from a TextInput onFocus, for example.
   * - Params are the properties of @type {AutoCompleteProps}
   */
  showAutocomplete<T>(
    value: AutoCompleteProps<T>['value'],
    sourceRef: NonNullable<AutoCompleteProps<T>['sourceRef']>,
    items: AutoCompleteProps<T>['items'],
    onSelect: AutoCompleteProps<T>['onSelect'],
    otherProps?: AutoCompleteProps<T>['otherProps'],
  ): void
  /** This is intended to update the autocomplete items when the overlay is already being shown.
   * - For example, if the user types a search term, which produces new hits, this should be called to update the items visible as the hits reflect the new debounced search term.
   * - Params are from @type {AutoCompleteProps}
   */
  updateAutocomplete<T>(sourceRef: RefObject<TextInput | View>, items: AutoCompleteItem<T>[]): void
  /** Hides the autocomplete. By default, on backdrop press the autocomplete overlay will hide */
  hideAutocomplete(event?: GestureResponderEvent): void
  /** Internal autocomplete state. Can be used for determining which autocomplete is currently open, or anything else */
  state: AutoCompleteState<T>
}

const defaultState: AutoCompleteState<any> = {
  items: [],
  otherProps: {},
  sourcePos: initialPos,
  value: '',
  sourceRef: createRef(),
}

/** Provides a dialog and helpers for displaying a list of touchable items anchored to a source input.
 * - This can be used in any component and doesn't need to be set up globally.
 * - The screen that uses this must have a bound height because the dialog positioning calculation is based on the dimensions of the physical screen, not the dimensions of a screen's content which may be larger than the physical screen size if there is a scroll area.
 */
export function useAutoComplete<T>(): AutoCompleteReturn<T> {
  const [state, setState] = useState<AutoCompleteState<any>>(defaultState)
  const layout = useLayout()

  /** Measures the position of the source element */
  useDeepCompareFocusFx(() => {
    if (!state.sourceRef?.current) return

    state.sourceRef.current.measureInWindow((x, y, width, height) => {
      if (x === undefined || y === undefined || width === undefined || height === undefined) {
        // https://github.com/facebook/react-native/issues/29638
        throw new Error('Could not measure element, make sure the source element has collapsable set to false')
      }
      setState((p) => ({
        ...p,
        sourcePos: { top: y, left: x, width, height },
      }))
    })
    //Both items and ref are intentionally in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.items, state.sourceRef.current])

  const respStyles = useStyles(state)

  const showAutocomplete = useCallback<AutoCompleteReturn<any>['showAutocomplete']>(
    function <T>(
      value: AutoCompleteProps<T>['value'],
      sourceRef: AutoCompleteProps<T>['sourceRef'],
      items: AutoCompleteProps<T>['items'],
      onSelect: AutoCompleteProps<T>['onSelect'],
      otherProps: AutoCompleteProps<T>['otherProps'] = {},
    ) {
      setState((prev) => ({ ...prev, value, sourceRef, items, onSelect, otherProps }))
    },
    [setState],
  )

  const updateAutocomplete = useCallback<AutoCompleteReturn<any>['updateAutocomplete']>(
    function <T>(sourceRef: RefObject<TextInput | View>, items: AutoCompleteItem<T>[]) {
      setState((prev) => ({ ...prev, sourceRef, items }))
    },
    [setState],
  )

  const hideAutocomplete = useCallback<AutoCompleteReturn<any>['hideAutocomplete']>(
    (event) => {
      // If the autocomplete is called without an event then hide the autocomplete
      if (event === undefined) {
        state.sourceRef.current?.blur()
        setState((prev) => ({ ...prev, items: [], value: '' }))
        return
      }

      // On Android the status bar is included in the vertical offset, but on iOS it is not so we subtract that offset to line up the click with the input position
      const verticalOffset = Platform.OS === 'android' ? layout.top : 0
      // if vertical offset exists which it will only on android then we subtract it from the y position
      const yPos = event.nativeEvent.pageY - verticalOffset
      const xPos = event.nativeEvent.pageX

      // When the backdrop is clicked if that click is inside the input, we want to focus the input instead of hiding
      // the autocomplete, otherwise we hide the autocomplete
      if (
        xPos > state.sourcePos.left &&
        (!state.sourcePos.width || xPos < state.sourcePos.left + state.sourcePos.width) &&
        yPos > state.sourcePos.top &&
        yPos < state.sourcePos.top + state.sourcePos.height
      ) {
        state.sourceRef.current?.focus()
      } else {
        state.sourceRef.current?.blur()
        setState((prev) => ({ ...prev, items: [], value: '' }))
      }
    },
    [setState, state.sourcePos, state.sourceRef, layout],
  )

  /** renderItem is a simple text by default, but it can be overridden by custom renderItem prop */
  const renderItem: ListRenderItem<AutoCompleteItem<any>> = useMemo(
    () =>
      state?.otherProps?.renderItem ??
      getDefaultRenderAutocompleteItem({
        onSelect: state.onSelect,
        hideAutocomplete,
        itemsLength: state.items.length,
        borderStyle: styles.border,
        itemStyle: styles.itemTouchable,
      }),
    [state, hideAutocomplete],
  )

  const autoCompleteOverlay = useMemo(
    () =>
      !state.value || state.items.length === 0 ? null : (
        <Portal>
          <Pressable style={StyleSheet.absoluteFillObject} onPress={hideAutocomplete}>
            <FlatList
              style={respStyles.autocompleteCont}
              keyboardShouldPersistTaps="always"
              data={state.items}
              renderItem={renderItem}
              keyExtractor={({ text }) => text}
            />
          </Pressable>
        </Portal>
      ),
    [state, hideAutocomplete, renderItem, respStyles.autocompleteCont],
  )

  return {
    autoCompleteOverlay,
    showAutocomplete,
    updateAutocomplete,
    hideAutocomplete,
    state,
  }
}

/** These regular styles are kept separate from the dynamic style hook because they are needed in another fn external to the main hook */
const styles = StyleSheet.create({
  itemTouchable: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  border: {
    borderBottomColor: Colors.shades['100'],
    borderBottomWidth: 0.5,
  },
})

/** This base style will be expanded by both the inline and overlay modes of the autocomplete input component */
export const autocompleteContainerBaseStyle: ViewStyle = {
  ...SHADOW_10,
  borderWidth: 1,
  borderColor: Colors.shades['100'],
  backgroundColor: Colors.white,
  borderRadius: 5,
}

const useStyles = (state: AutoCompleteState<any>) =>
  useDeepCompareLayoutFnStyles(
    (_, { sourcePos, otherProps: { autoCompleteContStyle, matchWidth } }) => ({
      /** This is the style of the autocomplete overlay container which holds the items. This style must ensure it is positioned exactly where it is needed: below the source element */
      autocompleteCont: {
        ...autocompleteContainerBaseStyle,
        position: 'absolute',
        /** add the status bar height for accurate position on mobile */
        top: sourcePos.top + sourcePos.height + (StatusBar.currentHeight ?? 0),
        left: sourcePos.left,
        /** If the matchWidth option is specified, the width will be the same as the source element */
        width: matchWidth ? sourcePos.width : 'auto',
        ...autoCompleteContStyle,
      },
    }),
    state,
  )

type GetDefaultRenderAutocompleteItemOpts = {
  onSelect?: (itm: AutoCompleteItem<any>) => void
  hideAutocomplete: (event: GestureResponderEvent) => void
  itemsLength: number
  /** style for an item's touchable element */
  itemStyle?: ViewStyle
  /** style to append to itemStyle when the item is not the last in the list */
  borderStyle?: ViewStyle
}
/** This fn will return a renderItem fn for use in a FlatList, configured to use common features of an autocomplete touchable element */
export const getDefaultRenderAutocompleteItem = (
  opts: GetDefaultRenderAutocompleteItemOpts,
): ListRenderItem<AutoCompleteItem<any>> =>
  function DefaulRendertAutoCompleteItem({ item, index }) {
    return <DefaultAutocompleteItem {...opts} item={item} index={index} />
  }

export const DefaultAutocompleteItem = memo(function DefaultAutocompleteItem({
  onSelect,
  hideAutocomplete,
  itemsLength,
  itemStyle = styles.itemTouchable,
  borderStyle = styles.border,
  item,
  index,
}: GetDefaultRenderAutocompleteItemOpts & Omit<ListRenderItemInfo<any>, 'separators'>) {
  const { isSmallDevice } = useDeviceSize()
  return (
    <Hoverable>
      {(isHovered) => (
        <Pressable
          onPress={(evt) => {
            onSelect?.(item)
            hideAutocomplete(evt)
          }}
          style={[
            itemStyle,
            index < itemsLength - 1 && borderStyle,
            isHovered && { backgroundColor: Colors.shades['75'] },
          ]}
          key={item.text}
        >
          {/* numLines should be 1 on small, to ensure we get a few results visible in the small screen space */}

          <Text numberOfLines={isSmallDevice ? 1 : 2} size={14}>
            {item.text}
          </Text>
        </Pressable>
      )}
    </Hoverable>
  )
})
