import { listPaymentMethods } from '@api/Payments'
import {
  isAchPayment,
  isCashPayment,
  isCreditPayment,
  isEbtPayment,
  isInfinitePayment,
  PaymentMethod,
  pmt_FarmCredit,
} from '@models/PaymentMethod'
import { PaymentSelectorOptions } from './types'
import { SplitTenderPayment } from '@models/Order'
import { MoneyCalc } from '@helpers/money'
import { FarmBalance } from '@models/Payment'
import { Zero } from '@models/Money'

/** This function is a wrapper around the API method that will filter out any payment methods not allowed for the farm */
export const listPaymentsWithFilters = async (
  userId: string,
  { farm, allowOfflinePayments }: Pick<PaymentSelectorOptions, 'farm' | 'allowOfflinePayments'>,
) => {
  // Show only offline, ebt or ach if the farm allows it
  const paymentMethods = await listPaymentMethods(userId)
  return paymentMethods.filter((pay) => {
    if (isCashPayment(pay) && (!farm.paymentTypes.cash || allowOfflinePayments === false)) return false
    if (isEbtPayment(pay) && !farm.paymentTypes.ebt) return false
    if (isAchPayment(pay) && !farm.paymentTypes.check) return false
    else return true
  })
}

/**
 * This function will configure the default tender for the payment selector based on the provided options. This will be
 * run only once when the payment selector is initialized
 * @param userFarmBalance the farm balance api response
 * @param userPaymentMethods the users payment methods api response
 * @param options the options for the payment selector
 */
export function configureDefaultTender(
  userFarmBalance: { data?: FarmBalance | undefined; loading: boolean },
  userPaymentMethods: { data?: PaymentMethod[] | undefined; loading: boolean },
  options: PaymentSelectorOptions,
): SplitTenderPayment {
  // If the total is Zero and there are no installments or delivery fees then we should set the farm balance to cover it
  if (MoneyCalc.isZero(options.amountTotal) && !options.hasDelivery && !options.hasInstallments) {
    return [{ paymentMethod: pmt_FarmCredit, amount: Zero }]
  }

  const farmBalanceAmount = MoneyCalc.fromCents(userFarmBalance?.data?.amount ?? 0)
  const newTender: SplitTenderPayment = []
  // The maximum farm balance we can apply to this invoice
  const maxFarmBalanceAllowed = MoneyCalc.min(options.amountTotal, farmBalanceAmount)

  // We will set farm balance as the default payment option if there are no farm balance items in the cart and if this
  // is an invoice that is due upfront
  if (!options.hasFarmBalanceItem && options.isInvoiceDueToday && MoneyCalc.isGTZero(farmBalanceAmount)) {
    newTender.push({ paymentMethod: pmt_FarmCredit, amount: maxFarmBalanceAllowed })
    // If the farm balance does not cover the total then we will add an infinite payment method to cover the rest.
    // However, only credit card can be used here because we have some farm credit being applied
    if (MoneyCalc.isLessThan(maxFarmBalanceAllowed, options.amountTotal)) {
      const infinitePayment = userPaymentMethods.data?.find((pay) => isCreditPayment(pay))
      if (infinitePayment) {
        newTender.push({ paymentMethod: infinitePayment })
      }
    }
  } else {
    // We will not default to offline, so we should select the first infinite payment that is not offline
    const infinitePayment = userPaymentMethods.data?.find((pay) => isInfinitePayment(pay.source) && !isCashPayment(pay))
    if (infinitePayment) {
      newTender.push({ paymentMethod: infinitePayment })
    }
  }

  return newTender
}
