/**
 * A Note about using hyphens with number in safe slugs:
 * - Safe slugs can contain numbers, but if a safe slug contains a number, it must contain a hyphen.
 * We do this to avoid collisions with firebase IDs, which cannot contain hyphens. Without this restriction, the chance of
 * a collision between a safe slug and a firebase ID is 1 in 52,000. With this restriction, the probability goes to 1 in 1,050,000.
 */

/** The maximum number of collisions before we assume the safe slug is invalid. This prevents any potential infinite loops. */
export const MAX_SAFE_SLUG_COLLISIONS = 20

export type CollectionsWithUrlSlug = 'products' | 'csas' | 'farms'

/** this function is used to format name like (CSA name, product name and farm name) into safeSlug with standard processing procedure. */
export function formatToSafeSlug(name: string): string {
  // Convert to lowercase
  let safeSlug = name.toLowerCase()

  // remove single quote inside words
  // This regex looks for a single quote that is preceded and followed by a word character, and removes it
  safeSlug = safeSlug.replace(/\b'\b/g, '')

  // Replace all characters except lowercase letters, numbers, and spaces with single space
  safeSlug = safeSlug.replace(/[^a-z0-9\s]/g, ' ')

  // Replace extra spaces with single space
  safeSlug = safeSlug.replace(/\s+/g, ' ').trim()

  // Replace all spaces with '-'
  safeSlug = safeSlug.replace(/ /g, '-')

  // Insert a hyphen before the first number sequence only if there isn't already a hyphen in the slug (see comment at top of file)
  if (!safeSlug.includes('-') && /\d/.test(safeSlug)) {
    // If the string has no hyphen and starts with a number
    if (/^\d/.test(safeSlug)) {
      // This regex looks for a sequence of numbers at the start of the string and adds a hyphen after them
      safeSlug = safeSlug.replace(/^(\d+)/, '$1-')
    } else {
      // Otherwise, insert a hyphen before the first number sequence
      // This regex looks for a sequence of numbers that is preceded by a non-digit character, and adds a hyphen separating them
      safeSlug = safeSlug.replace(/(\D+)(\d+)/, '$1-$2')
    }
  }

  return safeSlug
}

/** This function will generate a new slug from a duplicate */
export function buildConflictingSafeSlug(safeSlug: string, docId: string, attempt: number): string {
  // The first collision we will use the first 3 characters of the docId and start adding chars until there is no longer a collision
  // docId string will include either numbers, uppercase or lower case letters, so we must convert the slice to lowercase to make sure it matches the slug pattern
  const newSlug = `${safeSlug}-${docId.slice(0, attempt + 3).toLowerCase()}`
  if (!isValidSlug(newSlug)) throw new Error('A de-duplicated slug was found to be invalid.')

  return newSlug
}

/** Validates a urlSafeSlug against the data in the database.
 * @param requestedSlug - The initially requested slug to be verified.
 * @param documentId - The id of the document we are generating the safe slug for
 * @param checkHasDuplicates - The api function to check for duplicates.
 * @returns A valid urlSafeSlug after verification and resolution of duplicates, or an empty string if the maximum number of attempts is reached.
 */
export async function validateUrlSafeSlugWithDb(
  requestedSlug: string,
  documentId: string,
  checkHasDuplicates: (slug: string) => Promise<boolean>,
): Promise<string> {
  let attempt = 0
  let slugResult = requestedSlug

  while (attempt < MAX_SAFE_SLUG_COLLISIONS) {
    const hasDuplicates = await checkHasDuplicates(slugResult)
    if (!hasDuplicates) {
      // if has no duplicates, end the iteration. we're done
      break
    } else {
      // if it has duplicates, must generate a new one
      slugResult = buildConflictingSafeSlug(requestedSlug, documentId, attempt)
      attempt++
    }
  }

  // If the iteration ended due to the increment, means we reached the max tries
  if (attempt === MAX_SAFE_SLUG_COLLISIONS) return ''

  return slugResult
}

/**
 * Checks if the given slug is a safe slug or a farm ID. Note, there is a chance of a false positive here, but the only way
 * that can happen is if we have a firebaseID that is all lower case letters. The probability is 1 in 1,050,000.
 * @param slug - The slug or ID to be checked.
 *  slugs comply with this pattern:
 * - all lowercase letters
 * - can contain numbers
 * - can contain hyphens
 * - can contain only one hyphen in a row
 * - can't contain any other special characters
 * - can't contain any capital letters
 * - can't be a farm ID in the format "farmid-<number>"
 * @returns True if the given slug is a safe slug, false otherwise.
 */
export function isSafeSlug(slug: string) {
  if (!slug) return false

  //check if slug has any capital letter if so return false
  if (slug !== slug.toLowerCase()) return false

  // Check for Farm ID in the format "farmid-<number>"
  // Check for safe slug: all lowercase, can contain letters, numbers, and hyphens
  const farmIdPattern = /^farmid-\d+$/
  if (farmIdPattern.test(slug)) {
    return false
  }

  const safeSlugPattern = /^[a-z0-9-]+$/
  if (safeSlugPattern.test(slug)) {
    // If the slug contains a number, it must contain a hyphen, or it is a firebase ID (see comment at top of file)
    if (/\d/g.test(slug) && !/-/g.test(slug)) {
      return false
    }

    return true
  }

  return false
}

/** Checks if a url safe slug is valid */
export function isValidSlug(slug: string) {
  return isSafeSlug(slug) && !!slug.match(/[a-z]{3,}/)
}

/**
 * machesIdOrSlug is a function that checks whether a data object (farm, product or CSA) matches the given id or slug.
 * @returns True if the data object (farm,product,csa) id or slug matches the given idOrSlug, false otherwise.
 */
export function matchesIdOrSlug<Type extends Partial<{ id: string; urlSafeSlug: string }>>(
  data: Type | undefined,
  idOrSlug: string,
) {
  if (!data) return false
  return data.id === idOrSlug || data.urlSafeSlug === idOrSlug
}

/** Gets the id of an object from the slug and an object that may or may not belong to the slug */
export const getIdFromSlug = (slugOrId?: string, data?: { id: string; urlSafeSlug: string }) => {
  if (!slugOrId) return ''

  if (isSafeSlug(slugOrId)) {
    // the slug must match the data slug
    if (data && data.urlSafeSlug === slugOrId) return data.id
    return ''
  } else return slugOrId // means the slug is really an id
}

/** Get warning text on changing name which will change URL if the URL uses urlSafeSlug */
export const getNameChangeOnUrlSafeSlugWarningText = (slugCollection: CollectionsWithUrlSlug) =>
  `Changing ${slugCollection} names also changes the ${slugCollection} URL. If you previously linked to this ${slugCollection} in your shop, the old link may no longer work after this name change.`
