import { formatMoney } from '@helpers/display'
import { Money, moneyIsZero, Zero } from '@models/Money'
import * as Yup from 'yup'

export type EBTPaymentFormType = {
  ebtPaymentEnabled: boolean
  worldPayMerchantId: string
  fnsOnline: string
}
export type OfflinePaymentFormType = {
  offlinePaymentsMessage: string
  offlineEnabled: boolean
}
export type TipsOptionType = {
  showTipOption: boolean
  customerChooseToPayFees: boolean
  minCartSelected: boolean
  minCartAmount?: Money
}
export type ACHPaymentFormType = {
  achPaymentEnabled: boolean
}

export type PaymentsFormProps = OfflinePaymentFormType & EBTPaymentFormType & TipsOptionType & ACHPaymentFormType

export const validationSchema = Yup.object<PaymentsFormProps>().shape({
  ebtPaymentEnabled: Yup.boolean(),
  worldPayMerchantId: Yup.string().when('ebtPaymentEnabled', {
    is: true,
    then: (schema) => schema.required('WorldPay Merchant ID is required'),
  }),
  fnsOnline: Yup.string().when('ebtPaymentEnabled', {
    is: true,
    then: (schema) => schema.required('FNS Online Number is required'),
  }),

  offlinePaymentsMessage: Yup.string(),
  offlineEnabled: Yup.boolean(),
  showTipOption: Yup.boolean(),
  customerChooseToPayFees: Yup.boolean(),
  minCartSelected: Yup.boolean(),
  minCartAmount: Yup.mixed<Money>()
    .when('minCartSelected', {
      is: true,
      then: (schema) => schema.required('Minimum cart amount is required'),
    })
    .test('minCartTest', `Amount must be greater than ${formatMoney(Zero)}`, (val: any) =>
      val ? !moneyIsZero(val as Money) : true,
    ),

  achPaymentEnabled: Yup.boolean(),
})
