import { createManualInvoice } from '@api/Invoices'
import { Alert, Button, CheckBox, Divider, fontSize } from '@elements'
import { formatMoney } from '@helpers/display'
import { Farm } from '@models/Farm'
import { makeMoney } from '@models/Money'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Input } from 'react-native-elements'
import { TextInputMask } from 'react-native-masked-text'
import * as Yup from 'yup'

import { Logger } from '../../../../../config/logger'
import Colors from '../../../../../constants/Colors'

type FormType = {
  amount: string
  message: string
  markAsPaid: boolean
  note: string
}

export function CreateInvoice({
  userId,
  farm,
}: {
  userId: string
  farm: Pick<Farm, 'name' | 'id'>
  uniqueId?: string
  onUpdate?: () => void
}) {
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    amount: Yup.number().label('Amount').min(5.0, 'The amount cannot be less than $5.00').required(),
    message: Yup.string()
      .trim()
      .min(5, 'Please enter a message at least 5 characters')
      .trim()
      .label('Message')
      .required(),
  })

  const onSubmitHandler = async ({ amount, message, markAsPaid, note }: FormType) => {
    const formatAmount = makeMoney(Number(amount) * 100)
    setLoading(true)
    try {
      await createManualInvoice({
        userId,
        farmId: farm.id,
        amount: formatAmount,
        description: message,
        markAsPaid,
        note,
      })
      setLoading(false)
      Alert(
        'Invoice Successfully Created',
        `You have successfully created an invoice for ${formatMoney(formatAmount)}.`,
      )
    } catch (err) {
      Logger.error(err)
      setLoading(false)
      Alert(
        'Invoice Creation Failed',
        'Failed to create invoice, please try again later or contact support if this issue persists.',
      )
    }
  }
  return (
    <Formik
      initialValues={{
        amount: '',
        message: '',
        markAsPaid: false,
        note: '', // this is the note that will be added to the invoice
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values, errors, setFieldValue, handleSubmit, handleBlur, touched }: FormikProps<FormType>) => (
        <View style={styles.container}>
          <Divider clear />
          <Text>Amount to invoice the customer</Text>
          <TextInputMask
            style={styles.amountInput}
            type="money"
            maxLength={11}
            options={{ delimiter: ',', unit: '$', separator: '.' }}
            placeholder="$0.00"
            onBlur={handleBlur('amount')}
            onChangeText={(_, raw) => setFieldValue('amount', raw!)}
            includeRawValueInChangeText
            value={values.amount}
          />
          {touched.amount && !!errors.amount && (
            <Text style={{ color: Colors.red, margin: 10, marginTop: -5, fontSize: fontSize(12, 2) }}>
              {errors.amount}
            </Text>
          )}
          <Input
            multiline
            numberOfLines={2}
            placeholder="Message to customer"
            errorMessage={touched.message ? errors.message : ''}
            value={values.message}
            onChangeText={(val) => setFieldValue('message', val)}
            onBlur={handleBlur('message')}
          />
          <CheckBox
            style={{ marginLeft: 10 }}
            titleStyle={{ width: '60%' }}
            checked={values.markAsPaid}
            onChange={(val) => setFieldValue('markAsPaid', val)}
            title="Mark invoice as paid offline"
          />
          <Divider clear />
          {values.markAsPaid && (
            <Input
              multiline
              numberOfLines={2}
              placeholder="Make a note for this invoice (optional)"
              value={values.note}
              onChangeText={(val) => setFieldValue('note', val)}
            />
          )}

          <Button loading={loading} title="Create Invoice" onPress={() => handleSubmit()} />
          <Text style={styles.container}>
            This invoice and message will be emailed to the customer. If marked "paid offline", the customer will
            receive a receipt.
          </Text>
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  amountInput: {
    fontSize: 20,
    fontWeight: '600',
    margin: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['300'],
  },
  reloadCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
})
