import { distrosCollection } from '@api/framework/ClientCollections'
import { MessageWithIcon } from '@components'
import { Modal, Text, TextH4 } from '@elements'
import { formatPickupDate, formatPickupTime } from '@helpers/display'
import { getProductFrequency } from '@helpers/products'
import { sortByEarliest } from '@helpers/sorting'
import { format, isAfter } from '@helpers/time'
import { Order, OrderItem, Pickup, PickupItem, isPickupItemActive, isPickupItemOnVacation } from '@models/Order'
import { Product, isShare, isStandard } from '@models/Product'
import { dateTimeInZone } from '@models/Timezone'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CancelPickup } from '@screens/Orders/CancelPickup'
import { Reschedule } from '@screens/Orders/Reschedule'
import { Dispatch, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSelector } from 'react-redux'

import { globalStyles } from '../../../../constants/Styles'
import { ShareRowProp, SubscriptionAccordion } from '../../../components/SubscriptionAccordion'
import { Header } from '../AdminCustomerOrdersSection/components/Header'

import { ActionBtn, ActionsMenuComponent } from '@/admin/components/elements/ActionsMenuComponent'
import { AdminDrawerParamList, CustomerParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { adminFarmSelector } from '@/redux/selectors'
import { AccessRight, Permission } from '@helpers/Permission'

import { AdminCard } from '@/admin/components/AdminCard'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { ListItem } from 'react-native-elements'
import { configurePickupItemStatus } from '../helpers/pickupStatusHelper'

export type ShareData = {
  quantity: number
  pickups: Pickup[]
  orderNum: number | null
}

type Props = {
  customerId: string
  customerName: string
  pickups: Pickup[]
  productsMap: Map<string, Product>
  ordersMap: Map<string, Order>
  orders: Order[]
  onChange: () => void
  setShareRowProps: React.Dispatch<React.SetStateAction<ShareRowProp[]>>
}

const createShareData = (quantity = 0, ordN?: number): ShareData => {
  return {
    quantity,
    pickups: [],
    orderNum: ordN ?? null,
  }
}

/** props type for ShareRowExpandedContext  */
export type ContextType = {
  shareExpandedGroup?: string[]
  setShareExpandedGroup?: Dispatch<SetStateAction<string[]>>
  shareMap: Map<string, ShareData>
  onChange?: () => void
}

//Create local Context to let child components know if they are expanded and pass down the props that child components can access
export const ShareRowExpandedContext = createContext<ContextType>({ shareMap: new Map() })

/** The Subscription Section Component inside AdminCustomerDetail Screen */
export function AdminCustomerSubscriptions({
  customerId,
  customerName,
  pickups,
  productsMap,
  ordersMap,
  orders,
  onChange,
  setShareRowProps,
}: Props) {
  const { isExtraSmallDevice } = useDeviceSize()
  const { params } = useRoute<RouteProp<CustomerParamList, 'CustomerDetails'>>()
  const [shareMap, setShareMap] = useState<Map<string, ShareData>>(new Map())
  const farm = useSelector(adminFarmSelector)
  const navigation = useNavigation<StackNavigationProp<CustomerParamList>>()
  const [subViews, setSubViews] = useState<JSX.Element[]>()
  // set the limit of how many subscriptions to show
  const [limit, setLimit] = useState(10)
  const [openSection, setOpenSection] = useState<boolean>(false)
  const [shareExpandedGroup, setShareExpandedGroup] = useState<string[]>([])
  const hasAccessEditSubs = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)

  const styles = useStyles()

  const listItemAccordionOnPress = useCallback(() => {
    setOpenSection((value) => !value)
    // Reset limit
    setLimit(10)
  }, [])

  useEffect(() => {
    const productCount = new Map<string, number>()
    const newShareMap = new Map<string, ShareData>()

    const countedShares = new Set<string>()
    pickups.forEach((pickup) => {
      const pickupAlreadySet = new Set<string>()
      pickup.items.forEach(({ productId, orderId, quantity }) => {
        //get the full product information from list of products
        const curProduct = productsMap.get(productId)
        if (curProduct && isStandard(curProduct)) {
          const oldQty = productCount.get(curProduct.id)
          productCount.set(curProduct.id, oldQty ? quantity + oldQty : quantity)
        } else if (curProduct && isShare(curProduct)) {
          if (!countedShares.has(curProduct.id + '_' + orderId)) {
            let shareData = newShareMap.get(curProduct.id + '_' + orderId)
            if (shareData) {
              shareData.quantity += quantity
            } else {
              const orderNum = ordersMap.get(orderId)?.orderNum
              shareData = createShareData(quantity, orderNum)
              shareData.pickups.push(pickup)
            }
            newShareMap.set(curProduct.id + '_' + orderId, shareData)
          } else if (!pickupAlreadySet.has(curProduct.id + '_' + orderId)) {
            const oldData = newShareMap.get(curProduct.id + '_' + orderId)
            oldData?.pickups.push(pickup)
            pickupAlreadySet.add(curProduct.id + '_' + orderId)
          }
        }
      })
      newShareMap.forEach((val, key) => {
        countedShares.add(key)
      })
    })

    setShareMap(newShareMap)
  }, [productsMap, pickups, ordersMap])

  useEffect(() => {
    if (shareMap)
      subscriptionsData(shareMap).then((subViews) => {
        setSubViews(subViews)
      })
    // Only run this effect when shareMap changes intentionally
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareMap])

  /** When the users clicks subscription button on AdminOrderDetailScreen, they should be bring to AdminCustomerDetailScreen and scroll to matched subscription. By default the subscriptions section is collapsed, so we should open it and we can not guarantee that the subscription will be displayed within first 10 subscriptions, so we have to open all subscriptions to expand correct one in this reason.  */
  useEffect(() => {
    if (params?.orderNum && params?.productId) {
      setOpenSection(true)
      setLimit(subViews?.length ?? 10)
    }
  }, [params?.orderNum, params?.productId, subViews?.length])

  const vacationAction = useCallback(
    (pickup: Pickup, orderItem: OrderItem, curOrderId: string, pickupItem: PickupItem) => () => {
      Modal(
        <CancelPickup
          pickup={pickup}
          pickupItemFilter={(itm) => itm.id === orderItem.id && itm.orderId === curOrderId}
          customerName={customerName}
          orders={orders}
          onSuccess={onChange}
          type={isPickupItemOnVacation(pickupItem) ? 'unclaim' : 'claim'}
          isAdmin
        />,
        {
          title: isPickupItemOnVacation(pickupItem)
            ? 'Cancel Vacation'
            : `Cancel or Claim Vacation for ${format(pickup.date, 'EEEE, MMMM do, yyyy')}`,
        },
      )
    },
    [customerName, onChange, orders],
  )

  const rescheduleAction = useCallback(
    (pickup: Pickup) => () => {
      Modal(<Reschedule pickup={pickup} orders={orders} onSuccess={onChange} isAdmin />)
    },
    [onChange, orders],
  )

  const handleLoadMore = useCallback(() => {
    setLimit((prev) => prev + 10)
  }, [])

  const bgColors = useMemo(() => [(Colors.shades['50'], Colors.shades['75'])], [])

  const subscriptionsData = async (shareMap: Map<string, ShareData>) => {
    const subView: JSX.Element[] = []

    async function singleSubscription(curShareData: ShareData, shareId: string): Promise<void> {
      const curProdId = shareId.split('_')[0]
      const curOrderId = shareId.split('_')[1]
      const prod = productsMap.get(curProdId)
      if (!prod || !isShare(prod)) return

      const order = ordersMap.get(curOrderId)
      const { name, sku } = prod

      let nextOrLastPickup = undefined
      const now = dateTimeInZone(farm.timezone)
      for (let i = 0; i < curShareData.pickups.length; i++) {
        const pickup = curShareData.pickups[i]
        if (pickup.date > now) {
          nextOrLastPickup = pickup
          break
        }
        //If it's the last pickup and it's in the past, then nextOrLastPickup should be the last pickup
        if (i === curShareData.pickups.length - 1) nextOrLastPickup = pickup
      }

      //For look for share orderItem in order.items. the productId here is enough because a share should be unique in each order.
      const orderItem = order?.items.find((itm) => itm.product.id === curProdId)

      // Product name could be change after the order is created, so it should use the name from orderItem if it exists
      let shareName = orderItem?.product.name ?? name
      if (curShareData.quantity > 1) {
        shareName += ' x' + curShareData.quantity.toString()
      }

      if (!orderItem) {
        // FIXME: Hiding this error until we do a refactor as every new subscription triggers this
        // Logger.error(`Error loading order information. ORDER: ${curOrderId}, PRODUCT: ${prodData.id}`)
        return
      }
      const distroId = orderItem.distribution?.id
      //These orderItems are supposedly for shares, so if all else is working correctly, distroId should always be defined because that would only happen with a digital product
      if (!distroId) return

      //When getting the distro for an order, it should not assume the product.distributions still has it
      //If the distro is no longer present in product.distributions, load the distro directly
      let distro = prod.distributions.find((distro) => distro.id === distroId)
      if (!distro) distro = await distrosCollection.fetch(distroId)

      //Should pass the 'distro' (not 'distroId') to getProductFrequency, in case the distro is no longer associated with the product
      const frequency = getProductFrequency(prod, distro, { strict: false }) ?? 'Bad data: Frequency'

      const cancelled = Boolean(orderItem.cancelled)

      /** pickups is descending order by date by default */
      const lastDate = format(curShareData.pickups[0].date, 'M/d/yy')
      const firstDate = format(curShareData.pickups.slice(-1)[0].date, 'M/d/yy')
      const dates = `${firstDate} - ${lastDate}`

      //It should compare the date or day, not exact time to determine if it's past
      const isPast = isAfter(now, curShareData.pickups[0].date, { granularity: 'day', zone: farm.timezone })

      subView.push(
        <SubscriptionAccordion
          setShareRowProps={setShareRowProps}
          key={curProdId + curOrderId + distroId}
          prodId={prod.id}
          itemName={shareName}
          frequency={frequency}
          nextOrLastPickup={nextOrLastPickup}
          cancelled={cancelled}
          SKU={sku || ''}
          dates={dates}
          isPast={isPast}
          order={{ orderNum: curShareData.orderNum || 0, id: curOrderId }}
        >
          {curShareData.pickups.sort(sortByEarliest('date')).map((pickup, idx) => {
            const bg = bgColors[idx % 2]
            const pickupItem = pickup.items.find((itm) => itm.orderId === order?.id && itm.productId === prod.id)
            if (!pickupItem) {
              Logger.error(`Error loading pickup information. PICKUP: ${pickup.id}, PRODUCT: ${prod.id}`)
              return
            }
            //It should compare the date or day, not exact time to determine if it's past
            const isPast = isAfter(now, pickup.date, { granularity: 'day', zone: farm.timezone })

            //Generate the action buttons for each pickup
            const actionButtons: ActionBtn[] = [
              {
                title: isPickupItemOnVacation(pickupItem) ? 'Cancel Vacation' : 'Cancel or Claim Vacation',
                onPress: vacationAction(pickup, orderItem, curOrderId, pickupItem),
              },
              {
                title: 'Reschedule',
                onPress: rescheduleAction(pickup),
              },
            ]
            // when share item is on vacation, it should not show the reschedule button
            if (isPickupItemOnVacation(pickupItem)) actionButtons.pop()

            /**
             * actionDisabled is used to disable the action buttons for share pickupItems
             * 1. If the current orderItem is cancelled
             * 2. If the pickup is in the past
             * 3. If the user does not have access to edit subscriptions
             * 4. If the pickupItem is not on vacation or active
             */
            const actionDisabled =
              cancelled ||
              isPast ||
              !hasAccessEditSubs ||
              !(isPickupItemOnVacation(pickupItem) || isPickupItemActive(pickupItem))

            return (
              <View style={[styles.SubscriptionAccordionContainer, { backgroundColor: bg }]} key={idx}>
                <View style={globalStyles.flex2}>
                  <Text style={styles.accordionSubRowItemOneText}>{formatPickupDate(pickup.date)}</Text>
                </View>
                <Text style={globalStyles.flex1}>
                  {formatPickupTime(pickup.distribution.hours, pickup.distribution.locationType)}
                </Text>
                <View style={globalStyles.flex1} />
                <View style={globalStyles.flex1} />
                <Text style={styles.flex15}>{pickup.distribution.name}</Text>
                <View style={globalStyles.flex1} />
                <Text style={globalStyles.flex1}>{configurePickupItemStatus(pickup, pickupItem)}</Text>
                <ActionsMenuComponent
                  containerStyle={globalStyles.flex1}
                  buttons={actionButtons}
                  disabled={actionDisabled}
                />
              </View>
            )
          })}
        </SubscriptionAccordion>,
      )
    }

    //loop through all shareMap and run singleSubscription function to expand subView
    for (const [shareId, shareData] of shareMap.entries()) {
      await singleSubscription(shareData, shareId)
    }

    if (subView.length === 0) return undefined

    return subView
  }

  return (
    <AdminCard style={styles.adminCardContainer}>
      <ShareRowExpandedContext.Provider value={{ shareExpandedGroup, setShareExpandedGroup, shareMap, onChange }}>
        <ListItem.Accordion
          isExpanded={openSection}
          onPress={listItemAccordionOnPress}
          containerStyle={styles.listItemContainer}
          pad={isExtraSmallDevice ? 0 : 10}
          content={
            <Header
              title={`Subscriptions (${subViews?.length ?? 0})`}
              containerStyle={styles.headerContainer}
              btns={
                hasAccessEditSubs && openSection
                  ? [
                      {
                        title: 'New subscription',
                        onPress: () => {
                          ;(navigation as StackNavigationProp<AdminDrawerParamList>).navigate('Orders', {
                            screen: 'CreateOrder',
                            params: {
                              custId: customerId,
                              orderType: 'share',
                              goBack: 'customerDetails',
                            },
                          })
                        },
                      },
                    ]
                  : []
              }
            />
          }
        >
          {subViews && (
            <Text style={styles.description}>
              Here you can cancel a week for a customer or claim a vacation week on their behalf, should vacation be
              available.
            </Text>
          )}

          <ScrollView horizontal contentContainerStyle={subViews ? styles.container : globalStyles.flex1}>
            {subViews ? (
              <View style={styles.subViewContainer}>
                <View style={styles.subViewHeaderContainer}>
                  <View style={styles.subViewFirstHeader}>
                    <View style={styles.subViewFirstHeaderIcon} />
                    <TextH4>Share</TextH4>
                  </View>
                  <TextH4 style={globalStyles.flex1}>Frequency</TextH4>
                  <TextH4 style={globalStyles.flex1}>Order#</TextH4>
                  <TextH4 style={globalStyles.flex1}>SKU</TextH4>
                  <TextH4 style={styles.flex15}>Schedule</TextH4>
                  <TextH4 style={globalStyles.flex1}>Dates</TextH4>
                  <TextH4 style={globalStyles.flex1}>Status</TextH4>
                  <TextH4 style={globalStyles.flex1}>Action</TextH4>
                </View>
                {subViews.slice(0, limit)}
                {subViews.length > limit && (
                  <Text style={styles.showMore} type="bold" size={16} color={Colors.green} onPress={handleLoadMore}>
                    Show More
                  </Text>
                )}
              </View>
            ) : (
              <MessageWithIcon icon="box" title="No Subscriptions" style={styles.marginAuto}>
                <Text>
                  This customer does not have any CSA subscriptions. You can create one for them with the link above.
                </Text>
              </MessageWithIcon>
            )}
          </ScrollView>
        </ListItem.Accordion>
      </ShareRowExpandedContext.Provider>
    </AdminCard>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isExtraSmallDevice }) => ({
    description: {
      marginTop: 10,
      marginBottom: 30,
    },
    container: { flex: 1, minWidth: 1400, marginBottom: 15 },
    subViewContainer: {
      flex: 1,
      minWidth: 1400,
    },
    image: {
      width: 40,
      height: 40,
      borderRadius: 20,
      marginRight: 5,
    },
    heading: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    shareLink: {
      marginHorizontal: 5,
      color: Colors.blue,
      textDecorationLine: 'underline',
    },
    grayShareLink: {
      marginHorizontal: 5,
      color: Colors.shades['300'],
      textDecorationLine: 'underline',
    },
    complete: {
      marginHorizontal: 5,
      color: Colors.shades['200'],
    },
    showMore: {
      alignSelf: 'center',
      marginTop: 10,
    },
    SubscriptionAccordionContainer: {
      flexDirection: 'row',
      paddingVertical: 3,
    },
    accordionSubRowItemOneText: {
      marginLeft: 35,
    },
    marginAuto: { margin: 'auto' },
    subViewHeaderContainer: { flexDirection: 'row' },
    subViewFirstHeader: { flex: 2, flexDirection: 'row' },
    subViewFirstHeaderIcon: { marginRight: 35 },
    flex15: { flex: 1.5 },
    adminCardContainer: {
      paddingVertical: isSmallDevice ? 10 : 20,
      paddingHorizontal: isExtraSmallDevice ? 10 : 20,
      marginTop: isSmallDevice ? 10 : 20,
    },
    headerContainer: {
      flex: 1,
      marginBottom: 0,
    },
    listItemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
    },
  }))
