import { marshalPhoneNumber } from '@helpers/display'
import { limit, onSnapshot, where } from 'firebase/firestore'

import { keys, omit, PartialPick } from '@helpers/typescript'
import { User, userName } from '@models/User'
import { NotFoundError } from '@shared/Errors'
import { ChangeUserRequest } from '@shared/types/v2/user'
import { marshalUser, unmarshalUser } from './encoding/User'
import { usersCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

export function snapshotUser(userId: string, callback: (user: User) => void): () => void {
  const usersDoc = usersCollection.reference(userId)

  return onSnapshot(usersDoc, (snap) => {
    if (snap.exists()) callback(unmarshalUser(snap.id, snap.data()))
  })
}

// loadUserByPhone returns the user identified by the supplied phone number. Phone numbers are expected to be unique
// to a single user.
export async function loadUserByPhone(phoneNumber: string): Promise<User> {
  const users = await usersCollection.fetchAll(where('phoneNumber', '==', phoneNumber), limit(1))
  if (users.length === 0) {
    throw new NotFoundError('users', { phoneNumber })
  }
  return users[0]
}

// loadUserByEmail returns the user identified by the supplied email address.
export async function loadUserByEmail(email: string): Promise<User> {
  const users = await usersCollection.fetchAll(where('email', '==', email.toLowerCase().trim()), limit(1))
  if (users.length === 0) {
    throw new NotFoundError('users', { email })
  }
  return users[0]
}

// loadUser returns the user identified by the supplied user ID. It raises a NotFoundError if the user is not found.
export async function loadUser(userId: string): Promise<User> {
  return usersCollection.fetch(userId)
}

/** Checks whether a number is in the right format, and it doesnt exist in the db, except by the same user. If checks are passed, will return the marshalled number, else will throw errors */
export async function validatePhoneNumber(
  number: string,
  userId: string,
  hasCountryCode: boolean | undefined = true,
): Promise<string> {
  const formatted = marshalPhoneNumber(number, hasCountryCode)
  if (!formatted) throw new Error('There was an error while validating the phone number')
  let loadedU: User | undefined = undefined
  try {
    loadedU = await loadUserByPhone(formatted)
  } catch (e) {
    //If the user wasn't found, means it doesn't exist, so the number can be added
    return formatted
  }
  //If number was found, and it belongs to a different user, it can't be used
  if (loadedU.id !== userId) throw new Error('Phone number already exists')
  return formatted
}

/** setUser assigns the data in the supplied user to the document referenced by the included ID. If the document
does not exist, a new document will be created.
 * - This should only be used for updating simple fields in the user. For complete validation of updates, use @see {changeUser}
 */
export async function setUser(user: User): Promise<void> {
  return usersCollection.createWithId(user)
}

/** updateUser is like setUser but the document must exist.
 * - This should only be used for updating simple fields in the user. For complete validation of updates, use @see {changeUser} */
export async function updateUser(user: PartialPick<User, 'id'>): Promise<void> {
  return usersCollection.update(omit(user, 'role'))
}

export const hideTips = async (userId: string) => {
  return usersCollection.update({ id: userId, hideTips: true })
}

/** Allows for changing account related user fields, with thorough validation server side.
 * - This should be used whenever the user changes involve fields that require validation and/or non-duplicate fields, such as email or phone number.
 */
export async function changeUser(userId: string, oldUser: User, newUser: Partial<User>): Promise<void> {
  const deltas = {} as ChangeUserRequest['deltas']
  const oldDeltas = {} as ChangeUserRequest['oldDeltas']

  keys(newUser).forEach((field) => {
    if (oldUser[field] !== newUser[field]) {
      //@ts-expect-error
      deltas[field] = newUser[field]
      //@ts-expect-error
      oldDeltas[field] = oldUser[field]
    }
  })

  if (!!newUser.name?.firstName && !!newUser.name?.lastName && userName(newUser as User) !== userName(oldUser)) {
    deltas.name = newUser.name
    oldDeltas.name = oldUser.name
  }

  await callEndpoint('v2.User.changeUserService', { userId, deltas, oldDeltas })
}

/** check if the customer details can be edited by farmers */
export async function canEditCustomer(farmId: string, customerId: string): Promise<boolean> {
  return await callEndpoint('v2.User.canEditCustomerService', { farmId, customerId })
}

/** one call to create firebase user and auth user */
export async function createFirestoreAndAuthUser(userData: Omit<User, 'id'>, farmId: string): Promise<void> {
  return await callEndpoint('v2.User.createFirestoreAndAuthUserService', {
    userData: marshalUser(userData),
    farmId,
  })
}
