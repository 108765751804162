import { hasOwnProperty, isObject } from '@helpers/helpers'
import { isLuxonDateTime, isValidDateRange } from '@helpers/time'
import { isImmutable } from '@helpers/typescript'
import { DateTime, DurationLikeObject, Zone } from 'luxon'

import { Distribution } from './Distribution'
import { Location } from './Location'
import { PickupItemStatus } from './Order'

/** A Schedule's frequency describes how often a distribution will take place. */
export enum Frequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
}

/** Uses ISO Day (start monday at index 1) for better compatibility with luxon DateTime.weekday.
 * WARNING: date-fns library requires 0-6 format for the day of week */
export enum DayOfWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export type SkipException = {
  /** The source date specifies the skipped date */
  sourceDate: DateTime

  /** Skip exceptions have no target date */
  targetDate?: undefined

  dayOfWeek?: undefined
}

export type RescheduleException = {
  /** The source date specifies the rescheduled date */
  sourceDate: DateTime

  /** The target date indicates when the rescheduled date should occur instead, it
  takes the place of the source date. */
  targetDate: DateTime

  dayOfWeek?: undefined
}

/** An exception based on a specific date */
export type InstanceException = SkipException | RescheduleException

/** An exception based on a dayOfWeek pattern */
export type PatternException = {
  sourceDate?: undefined
  targetDate?: undefined

  /** A day of the week that should be skipped */
  dayOfWeek: DayOfWeek
}

/** An exception represents a skipped distribution */
export type Exception = InstanceException | PatternException

export const isInstanceException = (e: Exception): e is InstanceException =>
  isObject(e) && hasOwnProperty(e, 'sourceDate') && isLuxonDateTime(e.sourceDate)

export const isSkipException = (e: Exception): e is SkipException =>
  isInstanceException(e) && e.targetDate === undefined

export const isRescheduleException = (e: Exception): e is RescheduleException =>
  isInstanceException(e) && !!e.targetDate

export const isPatternException = (e: Exception): e is PatternException =>
  isObject(e) &&
  hasOwnProperty(e, 'dayOfWeek') &&
  isImmutable(e.dayOfWeek) &&
  !!e.dayOfWeek &&
  Object.values(DayOfWeek).includes(e.dayOfWeek)

export const isValidException = (e: Exception): boolean => {
  const isInst = isInstanceException(e)
  const isPat = isPatternException(e)
  const isBoth = isInst && isPat
  const isNone = !isInst && !isPat
  return !isBoth && !isNone
}

export const isYearRoundSchedule = (schedule: Schedule): schedule is YearRoundSchedule =>
  'pickupStart' in schedule && schedule.pickupStart !== undefined

export const isSeasonalSchedule = (schedule: Schedule): schedule is SeasonalSchedule =>
  'season' in schedule && schedule.season !== undefined

/** A Schedule represents a set of rules that define when something is available. */
export type Schedule = SeasonalSchedule | YearRoundSchedule

export type SeasonalSchedule = ScheduleBase & {
  /*The season specifies the period of the year when the distribution is available. Will be undefined if yearround schedule.
  The season start date will determine the dayOfWeek as well as schedule in the case of for biweekly pickups
   */
  season: DateRange
}

export type YearRoundSchedule = ScheduleBase & {
  /**
   * Pickup start date for yearround distributions, this will determine the dayOfWeek as well as schedule  in the case of for biweekly pickups
   */
  pickupStart: DateTime
}

export type ScheduleBase = {
  /**
   * Specifies how frequently the distributions happen.
   * - daily: Distributed each day
   * - weekly: Distributed on the same day each week.
   * - biweekly: Distributed on the same day every other week.
   * - monthly: Distributed once a month.
   */
  frequency: Frequency

  /**
   * Operating hours. In 24 Hour format with leading 0, Ex. 13:20 or 09:20
   */
  hours: Hours

  /**
   * Indicates the week of the month during which the schedule takes place for biweekly and monthly schedules.
   *
   * For example, a value of 1 defines that the monthly pickup would take place in the second week of the month.
   *
   * In the case of a biweekly schedule, the pickup would take place in the second and fourth weeks.
   *
   * Zero indexed
   */
  week?: number

  /**
   * The day of the week when the distribution takes place. 1-indexed. It is supposed to be the day of week of the start date. This is irrelevant for daily schedules.
   */
  dayOfWeek: DayOfWeek

  /**
   * Exceptions specify times during the schedule when a product may not be available. An exception can indicate that a date is cancelled, moved to another date, or added an additional date. A schedule may have multiple exceptions.
   */
  exceptions?: Exception[]
}

/** The hours at which the schedule fulfills pickups. This is entered as a fixed time in the distributions timezone.

Represented in 24hr format e.g. for 5:00pm it would be '17:00' */
export type Hours = {
  /** Represented in 24hr format e.g. for 5:00pm it would be '17:00' */
  startTime: string
  /**
   * Represented in 24hr format e.g. for 5:00pm it would be '17:00'.
   *
   * The end time is also used as the time up to which a pickup can be ordered in the same date at this schedule */
  endTime: string
}

/** A DateRange identifies a range of dates marked by the starting and ending dates. */
export type DateRange = {
  // Start marks the beginning of the season.
  startDate: DateTime

  // End marks the end of the season.
  endDate: DateTime
}

/** makeDateRange returns a date range initialized with the supplied start and end dates.
 * - a 'zone' argument can be used to convert the dates to the new timezone.
 */
export function makeDateRange(startDate: DateTime, endDate: DateTime, zone?: Location['timezone'] | Zone): DateRange {
  const dateRange: DateRange = {
    startDate: zone ? startDate.setZone(zone) : startDate,
    endDate: endDate.setZone(zone ?? startDate.zone),
  }
  if (!isValidDateRange(dateRange)) throw new Error('Invalid date range')
  return dateRange
}

export enum ProductFilterType {
  Both = 'All',
  StandardProduct = 'Standard Product',
  Share = 'Share',
}

/** SummaryFilter identifies filter options that may be applied to the summary reports. */
export type SummaryFilter = {
  // An ID of a CSA.
  csaId?: string

  // A location ID.
  locationId?: string

  productType?: ProductFilterType

  // pickupItemStatus is the status of the pickup item.
  pickupItemStatus?: PickupItemStatus
}

/**
 * Gets the availability of a schedule as DateRange, and handles both seasonal and yearround schedules.
 * - A maxDuration can be specified for calculating yearround availability endDate. If maxDuration undefined, will use a default n of months from the yearround `pickupStart`
 */
export const getScheduleAvailability = (
  schedule: Schedule,
  maxDuration: DurationLikeObject = { months: 12 },
  zone?: Location['timezone'] | Zone,
): DateRange => {
  const range = isSeasonalSchedule(schedule)
    ? makeDateRange(schedule.season.startDate, schedule.season.endDate, zone)
    : isYearRoundSchedule(schedule)
    ? // the computed end date of a year round distro will always be at least a year from now
      makeDateRange(schedule.pickupStart, DateTime.max(DateTime.now(), schedule.pickupStart).plus(maxDuration), zone)
    : undefined
  if (!range) throw new Error('Invalid schedule data is neither seasonal or year round')
  return range
}

/** Frequencies ordered by an increasing interval value */
const freqsByWidth: Record<Frequency, number> = { daily: 0, weekly: 1, biweekly: 2, monthly: 3 }

/** Whether freq1 is wider than freq2
 * @param strict if false, it will return frequencies that are same-width or wider (>=). else will only return wider ones (>)
 */
export const isWiderFreq = (freq1: Frequency, freq2: Frequency, strict = true): boolean => {
  return strict ? freqsByWidth[freq1] > freqsByWidth[freq2] : freqsByWidth[freq1] >= freqsByWidth[freq2]
}

/** This helper determines which frequencies will show up as available constraints in the product add screen.
 * Given a schedule's original frequency, returns the frequencies that are wider or same-width and can thus serve as constraints.
 * @param strict if false, it will return frequencies that are same-width or wider (>=). else will only return wider ones (>)
 */
export const getFreqConstraintOptions = (scheduleFreq: Frequency, strict = true): Frequency[] => {
  return Object.keys(freqsByWidth).filter((k) => isWiderFreq(k as Frequency, scheduleFreq, strict)) as Frequency[]
}

/** Converts the Schedule model's day of week number to the 0-6 format used by date-fns */
export function getDayOfWeek_DateFns({ schedule }: Pick<Distribution, 'schedule'>): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
  return schedule.dayOfWeek === 7 ? 0 : schedule.dayOfWeek
}
