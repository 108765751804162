import { Meta } from '@models/Meta'
import { DocumentData } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'

/** marshalMeta transforms a Meta structure to a Firestore document structure. */
export function marshalMeta(meta: Partial<Meta>): DocumentData {
  const data: DocumentData = {}

  if (meta.createdAt) {
    data.createdAt = marshalDate(meta.createdAt)
  }
  if (meta.updatedAt) {
    data.updatedAt = marshalDate(meta.updatedAt)
  }
  if (meta.lastAccessed) {
    data.lastAccessed = marshalDate(meta.lastAccessed)
  }

  return data
}

/** unmarshalMeta transforms a data structure containing meta to a Meta application structure. */
export function unmarshalMeta(data: DocumentData): Meta | undefined {
  if (!data) {
    return undefined
  }

  const meta = { ...data } as Meta

  if (data.createdAt) {
    meta.createdAt = unmarshalDate(data.createdAt)
  }
  if (data.updatedAt) {
    meta.updatedAt = unmarshalDate(data.updatedAt)
  }
  if (data.lastAccessed) {
    meta.lastAccessed = unmarshalDate(data.lastAccessed)
  }

  return meta
}
