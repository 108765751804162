import { loadFarmBalance } from '@api/Payments'
import { Alert, LoadingView, Text } from '@elements'
import { plural } from '@helpers/display'
import { nonEmptyString } from '@helpers/helpers'
import { CartItem } from '@models/Order'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { ConsumerScroll } from '../../../../components/ConsumerView'
import { Button } from '../../../../components/elements/Button'
import { ButtonClear } from '../../../../components/elements/ButtonClear'
import Colors from '../../../../constants/Colors'
import { isWeb } from '../../../../constants/Layout'
import { useApiFx } from '../../../../hooks/useApiFx'
import { useCartService } from '../../../../hooks/useCart'
import { ShoppingStackParamList } from '../../../../navigation/types'
import { consumerCartInfoSelector, userSelector } from '../../../../redux/selectors'
import { FarmSnippet } from '../../CartFarmSnippet'
import { CartItemComponent } from './CartItemComponent'

import { useFarmProp } from '@/hooks/useFarmProp'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useLayout } from '@/hooks/useLayout'
import { useValidateCartAlert } from '@/hooks/useValidateCart'
import { setNavProps } from '@/redux/actions/appState'
import { MessageWithIcon } from '@components'

type Props = StackScreenProps<ShoppingStackParamList, 'MyCart'>

/** Screen that serves as a preview of cart items before checking out */
export function MyCart({ navigation }: Props) {
  const dispatch = useDispatch()
  const { isLargeDevice } = useLayout()
  const { cart, loadingCart } = useCartService()
  const { cartFarmId } = useSelector(consumerCartInfoSelector)
  const { data: farm, loading: loadingFarm, err } = useFarmProp({ farmSlug: cartFarmId })

  const user = useSelector(userSelector)

  /** this balance is optional and is only used for display purposes. If a user has no farm balance, this data will remain undefined, but that shouldn't block checkout. */
  const balanceFx = useApiFx(
    loadFarmBalance,
    [user.id, cartFarmId],
    nonEmptyString(user?.id) && nonEmptyString(cartFarmId),
  )

  const [isValidating, setIsValidating] = useState(false)
  const validateCartAlert = useValidateCartAlert({ isAdmin: false })

  useFocusFx(() => {
    if (!isWeb && isLargeDevice)
      navigation.setOptions({
        headerBackTitle: 'Back',
        headerTitle: 'My Cart',
      })
  }, [isLargeDevice, navigation])

  const goBack = useCallback(() => {
    if (cartFarmId && cart[0] && cartFarmId === cart[0].product.farm.id)
      return navigation.navigate('FarmShop', { farmSlug: cartFarmId })
    else if (navigation.canGoBack()) navigation.goBack()
    else navigation.navigate('Home')
  }, [cartFarmId, cart, navigation])

  const goToCheckout = useCallback(async () => {
    if (!cartFarmId) return Alert('Invalid Cart!', 'No farm association was found for this cart.') // This should never happen if there's products in cart

    // Update or delete all invalid items from cart
    setIsValidating(true)

    /** This async validation doesn't need trycatch because any error will be caught inside. The result will contain the user input from an awaited alert that handles the error */
    const res = await validateCartAlert({
      onGoToShopPress() {
        // This will be called if the user decided to continue shopping, to make up for any unavailable items
        dispatch(setNavProps()) // This will clear the farm cache, to force loading updated product data
        navigation.navigate('FarmShop', { farmSlug: cartFarmId })
      },
    })
    setIsValidating(false)

    if (res.cancelCheckout || res.unavailCount > 0) dispatch(setNavProps()) // This will clear the farm cache, to force loading updated product data

    // If the user decided to cancel checkout, we abort.
    // If the cart still has items in it after validation, we can continue to checkout
    if (!res.cancelCheckout && res.newCartLength > 0) {
      return navigation.navigate('Checkout')
    }
  }, [navigation, validateCartAlert, cartFarmId, dispatch])

  const viewProduct = useCallback(
    (item: CartItem) => {
      navigation.navigate('ProductDetails', {
        productId: item.product.id,
        farmSlug: cartFarmId || '',
        csaId: item.csa?.id,
      })
    },
    [cartFarmId, navigation],
  )

  return (
    <ConsumerScroll>
      {!cartFarmId ? null : (
        <FarmSnippet
          loading={loadingFarm}
          error={err}
          farm={farm}
          rightText={`${cart.length} ${plural(cart.length, 'item')}`}
          credit={balanceFx.data?.amount}
        />
      )}
      <View style={styles.actionButtonsWrapper}>
        <ButtonClear
          title="Continue Shopping"
          style={styles.buttonClear}
          small
          textStyle={styles.actionButtonText}
          onPress={goBack}
        />
        {!!cartFarmId && (
          <Button
            loading={isValidating || loadingFarm || loadingCart}
            title="Checkout Now"
            onPress={goToCheckout}
            testID="checkout-mycart"
            disabled={!cart.length}
            style={styles.actionButton}
            textStyle={styles.actionButtonText}
            small
          />
        )}
      </View>
      <LoadingView loading={loadingCart} style={styles.cartWrapper}>
        {cart.length ? (
          <>
            {cart.map((item, idx) => (
              <View key={item.id + idx}>
                <CartItemComponent item={item} viewProduct={viewProduct} key={item.id + idx} />
                {idx < cart.length - 1 && <View style={styles.cardSpacer} />}
              </View>
            ))}
          </>
        ) : (
          <MessageWithIcon
            title="Empty Cart!"
            style={styles.emptyCart}
            icon="shopping-cart"
            iconColor={Colors.shades['300']}
          >
            <Text>Your cart is empty, click continue shopping to add products to your cart.</Text>
          </MessageWithIcon>
        )}
      </LoadingView>
    </ConsumerScroll>
  )
}

const styles = StyleSheet.create({
  cartWrapper: {
    gap: 10,
  },
  emptyCart: {
    flex: 1,
    padding: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ECTitle: {
    padding: 20,
  },
  headerTitle: {
    maxWidth: '100%',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 20,
    textAlign: 'center',
    alignSelf: 'center',
  },
  actionButtonsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 20,
  },
  actionButton: { flex: 1, width: '100%', height: 40 },
  buttonClear: {
    textAlign: 'center',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: Colors.green,
    borderRadius: 10,
    height: 40,
    marginRight: 10,
  },
  actionButtonText: {
    fontSize: 14,
    textAlign: 'center',
  },
  cardSpacer: { paddingVertical: 10 },
  padding20: { padding: 20 },
})
