import { isFunction, nonEmptyString } from '@helpers/helpers'
import mergeDeep from '@helpers/mergeDeep'
import {
  DependencyList,
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { DeepPartial } from 'redux'

import { useDeepCompareFocusFx } from './useFocusFx'

/** The set helper is useful for setting the state of one key. Accepts a value or a fn of current state */
export type SetHelper<T> = <K extends keyof T>(key: K, val: T[K] | ((prev: T[K]) => T[K])) => void

/** Individual setters are a group of functions that set the state of each key.
 *
 * - Important tip: A setter will only exist for all keys currently in the state object. If a key is not present in the initialState, and is not later set by either the setHelper or setState, there can be no setter function for it because it technically doesn't exist, from the fact that the state type is not a real object at compile time. A simple rule to avoid the potential issue of missing setters, is to provide an initialState object that includes every key in the state type, even if its initial value is undefined. That'll guarantee a setter fn was generated for every key. */
type IndividualSetters<T> = { [P in keyof T]-?: (v: T[P]) => void }

/** Allows you to pass a deep partial of the state which will get merged with the existing state. This is basically a clean shortcut to doing `setState((p)=>({...p, prop: newValue}))` */
export type SetPartial<T> = (partialState: DeepPartial<T>) => void

export type KeyedState<T extends Record<any, any>> = [
  T,
  SetHelper<T>,
  Dispatch<SetStateAction<T>>,
  IndividualSetters<T>,
  SetPartial<T>,
]

type KeyedStateOpts<T extends Record<any, any>> = {
  /** Called whenever a new state is set. Can be defined inline */
  onStateSet?: (newState: T) => void
  /** Effect dependencies for onStateSet */
  deps?: DependencyList
}

/** Wrapper of useState that manages several variables as a single state object and returns special setter functions for easily setting the value of a specific state key, or the entire state at once.
 *
 * - Designed with loading state objects in mind. I.e: `setLoading('prod', true)`
 * - Could be used for holding an entire screen's state, with the benefit that you can set the entire state at once, or set only portions of it.
 * - It's easier to manage many state variables in a single hook, as compared to having 10+ useState instances.
 * - It might use less memory resources than many useState calls.
 */
export const useKeyedState = <T extends Record<any, any>>(
  initialState: T,
  { deps = [], onStateSet = () => {} }: KeyedStateOpts<T> = {},
): KeyedState<T> => {
  const [state, setState] = useState(initialState)

  const setHelper: SetHelper<T> = useCallback(
    function setHelper<K extends keyof T>(key: K, val: T[K] | ((prev: T[K]) => T[K])) {
      if (isFunction(val)) {
        setState((prev) => ({ ...prev, [key]: val(prev[key]) }))
      } else setState((prev) => ({ ...prev, [key]: val }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState],
  )

  const individualSetters = useMemo(() => {
    return createIndividualSetters(state, setHelper)
    // We don't need this to re-run on change to state because setHelper and setState should update on state change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHelper])

  const setPartial: SetPartial<T> = useCallback(
    (ps: DeepPartial<T>) => {
      setState((p) => mergeDeep(p, ps))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setState],
  )

  /** When deps change, the callback will update */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleStateChange = useCallback(onStateSet, deps)

  /** On state change, pass to memoized handler */
  useDeepCompareFocusFx(() => {
    handleStateChange(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return useMemo(() => {
    return [state, setHelper, setState, individualSetters, setPartial]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState])
}

/** Builds an object of individual setter functions for each key of tha main state object */
function createIndividualSetters<T extends Record<any, any>>(state: T, setHelper: SetHelper<T>) {
  const setters: Partial<IndividualSetters<T>> = {}
  Object.keys(state).map(<K extends keyof T>(k: K) => {
    setters[k] = (v: T[typeof k]) => setHelper(k, v)
  })
  return setters as IndividualSetters<T>
}

/** Helps creating an initial dummy @type {KeyedState} for a given type.
 * - Useful when creating a context that holds the keyed state, and requires an default context value.
 */
export function createInitialKeyedState<T extends Record<any, any>>(initialState: T): KeyedState<T> {
  return [initialState, () => {}, () => {}, createIndividualSetters(initialState, () => {}), () => {}]
}

/** Returns a new component wrapped in a keyed state context provider, as well as the context created from the initial state */
export function withKeyedStateProvider<T extends Record<any, any>, P extends object = object>(
  Component: FC<P>,
  initialState: T,
) {
  const Context = createContext<KeyedState<T>>(createInitialKeyedState<T>(initialState))

  const ComponentWithProvider = memo(function ComponentWithProvider(props: P) {
    const keyedState = useKeyedState<T>(initialState)

    return (
      <Context.Provider value={keyedState}>
        <Component {...props} />
      </Context.Provider>
    )
  })
  if ('displayName' in Component && nonEmptyString(Component.displayName))
    ComponentWithProvider.displayName = Component.displayName

  return { ComponentWithProvider, Context }
}

export default useKeyedState
