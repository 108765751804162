import { DropdownMenu, Text } from '@elements'
import { Ionicons } from '@expo/vector-icons'
import { PickupItemStatus } from '@models/Order'
import { DistributionSummaryItem } from '@models/Summary'
import React, { memo, useCallback, useMemo } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { ListItem } from 'react-native-elements'

import { getDistroSummaryStatus, isDistroSummaryItemActive, isDistroSummaryItemSkipped } from '../helpers'

import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { SignInItemRow } from '.'

type Props = {
  /** The Distribution Summary item */
  summaryItem: DistributionSummaryItem
  /** Callback for swipe action button press */
  onUpdate: (item: DistributionSummaryItem, status: PickupItemStatus) => void
}
/** Component that extends `SignInItemRow` with swipe functionality. The swipe action will affect only that item */
export const SwipeableSignInItem = memo(function SwipeableSignInItem({ summaryItem, onUpdate }: Props) {
  const onUpdateStatus = useCallback(
    (status: PickupItemStatus) => onUpdate(summaryItem, status),
    [onUpdate, summaryItem],
  )

  // Pick only needed data to use in the `SignInItemRow` component
  const signInItem = useMemo(
    () => ({
      ...summaryItem.item.selectedUnits[0],
      ...summaryItem.item.product,
    }),
    [summaryItem.item],
  )
  const LeftContent = useMemo(
    function LeftContent() {
      if (isDistroSummaryItemSkipped(summaryItem)) return
      if (isDistroSummaryItemActive(summaryItem)) return
      return (
        <Pressable onPress={() => onUpdateStatus(PickupItemStatus.Active)} style={styles.undoActionBtn}>
          <Text color={Colors.white} type="medium" size={14}>
            Undo
          </Text>
        </Pressable>
      )
    },
    [onUpdateStatus, summaryItem],
  )

  const RightContent = useMemo(
    function RightContent() {
      if (isDistroSummaryItemSkipped(summaryItem)) return
      return (
        <View style={styles.actionBtnsCont}>
          <Pressable onPress={() => onUpdateStatus(PickupItemStatus.Donated)} style={styles.donationBtnCont}>
            <Ionicons name="heart" size={14} color={Colors.white} />
            <Text color={Colors.white} type="medium" size={12}>
              Donation
            </Text>
          </Pressable>
          <Pressable onPress={() => onUpdateStatus(PickupItemStatus.Received)} style={styles.receivedBtnCont}>
            <Ionicons name="checkmark-circle" size={14} color={Colors.white} />
            <Text color={Colors.white} type="medium" size={12}>
              Received
            </Text>
          </Pressable>
        </View>
      )
    },
    [onUpdateStatus, summaryItem],
  )

  const ActionButtons = useMemo(
    function ActionButtons() {
      if (isDistroSummaryItemSkipped(summaryItem)) {
        return <View style={styles.dropdownCont} />
      }

      const buttons = [
        {
          title: 'Received',
          onPress: () => onUpdateStatus(PickupItemStatus.Received),
          status: PickupItemStatus.Received,
        },
        { title: 'Donated', onPress: () => onUpdateStatus(PickupItemStatus.Donated), status: PickupItemStatus.Donated },
        {
          title: 'Not received',
          onPress: () => onUpdateStatus(PickupItemStatus.Active),
          status: PickupItemStatus.Active,
        },
      ].filter((btn) => btn.status !== getDistroSummaryStatus(summaryItem))

      return (
        <DropdownMenu containerStyle={styles.dropdownCont} buttons={buttons} data={undefined}>
          <Text type="medium" color={Colors.green}>
            Mark as
          </Text>
        </DropdownMenu>
      )
    },
    [onUpdateStatus, summaryItem],
  )
  if (isWeb) {
    return (
      <View style={globalStyles.flexRow}>
        <View style={globalStyles.flex1}>
          <SignInItemRow item={signInItem} />
        </View>

        {ActionButtons}
      </View>
    )
  }
  return (
    <ListItem.Swipeable containerStyle={styles.padding0} leftContent={LeftContent} rightContent={RightContent}>
      <SignInItemRow item={signInItem} />
    </ListItem.Swipeable>
  )
})

const styles = StyleSheet.create({
  padding0: {
    padding: 0,
  },
  receivedBtnCont: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.green,
  },
  donationBtnCont: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.darkGreen,
  },
  actionBtnsCont: {
    flex: 1,
    flexDirection: 'row',
  },
  dropdownCont: {
    justifyContent: 'center',
    borderBottomWidth: 1,
    width: 80,
    alignItems: 'center',
    borderColor: Colors.shades[100],
  },

  undoActionBtn: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.shades['100'],
  },
})
