import { useHandleSubmitProd } from '@/admin/screens/Products/components/ProductHeader'
import { buildInitialValues, ProductDetailsForm } from '@/admin/screens/Products/ProductForm/helpers/formHelpers'
import { FormikSchedule, SchedulesComponent } from '@/admin/screens/Products/ProductForm/SchedulesComponent'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { ModalComponentProps } from '@/constants/types/modalTypes'
import { createInitialApiFxReturn, useApiFx, UseApiFxReturn } from '@/hooks/useApiFx'
import { useCancelableFx } from '@/hooks/useCancelablePromise'
import useKeyedState, { createInitialKeyedState, KeyedState } from '@/hooks/useKeyedState'
import { setAdminNav } from '@/redux/actions/adminState'
import { productsCollection } from '@api/framework/ClientCollections'
import { Button, ErrorText, hideModal, LoadingView, Modal, Text } from '@elements'
import { buildProduct } from '@helpers/builders/buildProduct'
import { bullet } from '@helpers/display'
import { errorToString, isNonNullish, nonEmptyString, removeObjDuplicates } from '@helpers/helpers'
import { isPhysical, PhysicalProduct, Product, ProductType } from '@models/Product'
import { Formik, useFormikContext, yupToFormErrors } from 'formik'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { BulkEditModalGenericProps } from './product'

export type BulkEditProductSchedulesProps = BulkEditModalGenericProps<
  Product,
  Pick<PhysicalProduct, 'distributions' | 'distributionConstraints'>
>

const fetchByIds = productsCollection.fetchByIds.bind(productsCollection)

type State = {
  isSubmitting: boolean
  submitError: unknown
  validationErrors: { error: unknown; id: string }[]
}

type ContextType = {
  keyedState: KeyedState<State>
  dataFx: UseApiFxReturn<typeof fetchByIds>
}

const initialContextState: ContextType = {
  keyedState: createInitialKeyedState<State>({
    isSubmitting: false,
    submitError: undefined as unknown,
    validationErrors: [] as { error: unknown; id: string }[],
  }),
  dataFx: createInitialApiFxReturn<Product[]>(undefined),
}

const BulkEditProductSchedulesContext = createContext(initialContextState)

function BulkEditProductSchedules(props: BulkEditProductSchedulesProps) {
  const { ids, fetchByIds, validate, onPassValidation } = props

  const keyedState = useKeyedState({
    isSubmitting: false,
    submitError: undefined as unknown,
    validationErrors: [] as { error: unknown; id: string }[],
  })
  const [, set, setState] = keyedState

  const dispatch = useDispatch()

  useEffect(() => {
    /** This is necessary because the schedules component uses this product from redux, and here we are clearing it because we want the schedules component to think it is running in "add" mode */
    dispatch(setAdminNav({ product: undefined }))
  }, [dispatch])

  const dataFx = useApiFx(
    (ids) => {
      return fetchByIds(ids)
    },
    [ids],
  )

  const onSubmit = async () => {
    if (!dataFx.data || dataFx.loading) return
    setState((p) => ({ ...p, isSubmitting: true, validationErrors: [], submitError: undefined }))
    const errors = dataFx.data
      .filter((obj) => {
        // only need to validate those for which the field exists, because only those will get updated
        return props.getField(obj) !== 'Field_not_exist'
      })
      .map((obj) => {
        try {
          validate(obj)
        } catch (error) {
          return { error, id: obj.id }
        }
      })
      .filter(isNonNullish)

    if (errors.length) {
      setState((p) => ({ ...p, isSubmitting: false, validationErrors: errors }))
      return
    }

    try {
      await onPassValidation(dataFx.data)
      hideModal()
    } catch (error) {
      Logger.error(error)
      set('submitError', error)
    } finally {
      set('isSubmitting', false)
    }
  }

  const initialValues = useMemo<ProductDetailsForm | undefined>(() => {
    if (dataFx.loading) return undefined

    const prods = dataFx.data?.filter(isPhysical)
    const distributions = removeObjDuplicates(prods?.flatMap((prod) => prod.distributions) ?? [])

    return buildInitialValues(
      /** This is a dummy new product base, and won't become a full product. The type should be a share for now, so we don't need to manage the behavior of "only allow next day purchases" field inside the schedules component, since it only shows that for unit products and not shares */ {
        product: {
          type: ProductType.PrimaryShare,
          distributions,
        },
        name: 'AddProduct',
        params: { type: ProductType.PrimaryShare },
      },
    )
  }, [dataFx])

  return (
    <BulkEditProductSchedulesContext.Provider value={{ keyedState, dataFx }}>
      <LoadingView loading={dataFx.loading || !initialValues} error={dataFx.err} switchMode>
        <Formik
          initialValues={initialValues!}
          enableReinitialize={false}
          onSubmit={onSubmit}
          validate={async (values) => {
            try {
              await FormikSchedule.validator.validate(values, {
                abortEarly: false,
                context: { type: ProductType.PrimaryShare },
              })
              return {}
            } catch (e) {
              return yupToFormErrors(e)
            }
          }}
        >
          {/* The content is rendered as a component inside Formik because it needs to access formik context */}
          <FormContent {...props} />
        </Formik>
      </LoadingView>
    </BulkEditProductSchedulesContext.Provider>
  )
}

function FormContent({
  getField,
  updateField,
  fieldNameDisplay,
  getItemDisplayName = (doc) => {
    if ('name' in doc && nonEmptyString(doc.name)) return doc.name
    return doc.id
  },
  ids,
}: BulkEditProductSchedulesProps) {
  const {
    keyedState: [{ isSubmitting, validationErrors, submitError }, , setState],
    dataFx: { data, loading: isFetchingData, setState: setDataState },
  } = useContext(BulkEditProductSchedulesContext)
  const formik = useFormikContext<ProductDetailsForm>()
  const submit = useHandleSubmitProd(formik)

  const isLoading = isSubmitting || isFetchingData

  /** This tells us whether the field exists within all the items selected */
  const { allSelectedSupportField, itemsWithNoField, noneSelectedSupportField } = useMemo(() => {
    const itemsWithNoField = data?.filter((doc) => getField(doc) === 'Field_not_exist') || []

    return {
      allSelectedSupportField: itemsWithNoField.length === 0,
      itemsWithNoField,
      noneSelectedSupportField: itemsWithNoField.length === data?.length,
    }
  }, [data, getField])

  /** When form schedules change, applies the changes to the data */
  useCancelableFx(async () => {
    if (!data || isLoading) return

    setState((p) => ({ ...p, validationErrors: [], submitError: undefined }))

    /** builds the product fields from the form state. we need to cast the physical product type because the form component sees the product in its most abstract possible way */
    const { distributions, distributionConstraints } = (await FormikSchedule.fromFormik(
      formik.values,
    )) as PhysicalProduct

    // apply the edit to each item and update state
    const newData = data.map((doc) => {
      return updateField({ distributions, distributionConstraints }, doc)
    })
    setDataState((s) => ({ ...s, data: newData }))
  }, [data, isLoading, setDataState, setState, updateField, formik.values])

  const errorText = useMemo(() => {
    let txt = ''
    if (!data) return ''
    if (validationErrors.length) {
      txt += 'Some items are incompatible with these edits: \n\n'
      validationErrors.forEach(({ id, error }) => {
        txt += ` ${bullet} ` + getItemDisplayName(data.find((p) => p.id === id)!) + ': ' + errorToString(error) + '\n'
      })
    } else if (submitError) {
      txt += 'There was a problem submitting this request. Try again \n\n'
    }
    return txt
  }, [validationErrors, submitError, getItemDisplayName, data])

  const warning = useMemo(() => {
    let txt = ''
    if (noneSelectedSupportField) {
      txt += `None of the items in your selection support the field "${fieldNameDisplay}". Update your selection or choose a different field.`
    } else if (!allSelectedSupportField) {
      txt += `The field "${fieldNameDisplay}" is not supported by the following items: \n\n`
      itemsWithNoField.forEach((doc) => {
        txt += ` ${bullet} ${getItemDisplayName(doc)}\n`
      })
      txt += '\n\nYour edits will be applied to the rest of your selection.'
    }
    return txt
  }, [allSelectedSupportField, itemsWithNoField, fieldNameDisplay, getItemDisplayName, noneSelectedSupportField])

  return (
    <ScrollView style={styles.main}>
      <View style={styles.content}>
        <Text>{`The following schedules will be applied to the products selected (${ids.length}).`}</Text>
        {!!warning && (
          <Text style={styles.warningText} color={Colors.brown}>
            {warning}
          </Text>
        )}
        {!isLoading && <SchedulesComponent style={styles.schedulesComp} noHeader noTooltips noBottomSheet />}
      </View>
      {errorText ? <ErrorText>{errorText}</ErrorText> : null}
      <View style={styles.buttonCont}>
        <Button
          style={styles.button}
          title="Update multiple"
          onPress={submit}
          loading={isLoading}
          disabled={noneSelectedSupportField}
        />
      </View>
    </ScrollView>
  )
}

export type OpenBulkEditProductSchedulesOpts = {
  contentProps: Pick<BulkEditProductSchedulesProps, 'ids'> & { onSuccess?: (newData: Product[]) => any }
} & ModalComponentProps

/** opens the bulk edit modal for boolean fields */
export function openBulkEditProductSchedules({
  contentProps: { ids, onSuccess },
  ...modalComponentProps
}: OpenBulkEditProductSchedulesOpts) {
  const getField = (p: Product) =>
    isPhysical(p)
      ? {
          distributions: p.distributions,
          distributionConstraints: p.distributionConstraints,
        }
      : 'Field_not_exist'

  Modal(
    <BulkEditProductSchedules
      ids={ids}
      fetchByIds={fetchByIds}
      getField={getField}
      onPassValidation={async (newData) => {
        await Promise.all(
          newData.map((updatedProd) => {
            const newDataValues = getField(updatedProd)
            if (newDataValues === 'Field_not_exist') return Promise.resolve()
            return productsCollection.update({ id: updatedProd.id, ...newDataValues })
          }),
        )
        onSuccess?.(newData)
      }}
      updateField={(newData, prod) => (isPhysical(prod) ? { ...prod, ...newData } : prod)}
      validate={buildProduct}
      fieldNameDisplay="schedules"
    />,
    {
      webWidth: 1000,
      title: 'Edit Product Schedules & Availability',
      ...modalComponentProps,
    },
  )
}

const styles = StyleSheet.create({
  main: {
    padding: 10,
    alignContent: 'center',
  },
  content: {
    padding: 15,
  },
  warningText: {
    backgroundColor: Colors.shadeGold,
    borderRadius: 20,
    padding: 10,
    margin: 10,
  },
  schedulesComp: {
    /**  These styles are overriding the default styles of the schedules component. We want it to have no padding whatsoever when rendered here because this modal already handles padding, so we don't want double padding coming from the schedules component's default settings */
    paddingHorizontal: 0,
    paddingBottom: 0,
  },
  buttonCont: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    width: 200,
  },
})
