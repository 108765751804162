import { updateInvalidCartItems } from '@api/helpers/updateInvalidCartItems'
import { Alert } from '@elements'
import { bullet, plural } from '@helpers/display'
import { Resolved } from '@helpers/typescript'
import { useCallback } from 'react'
import type { AlertButton } from 'react-native'

import { useCartService } from './useCart'

import { Logger } from '@/config/logger'
import { isNonNullish } from '@helpers/helpers'
import { InvalidItemDetails, getInvalidItemMessageUi } from '@helpers/validateCart'
import { isAddon } from '@models/Product'
import { DataError } from '@shared/Errors'
import { useAvailAddons } from './useAvailAddons'

export type CartAlertProps = {
  /** If the user decides to continue shopping when told some of their items were invalid or removed, this fn should navigate the user to the shop for the farm in their cart. This is only meant to be used for consumer shopping, not for admin side/ order creator. This should only be provided for the consumer side. */
  onGoToShopPress?: () => void
}

export type ValidateCartAlertReturn = Promise<
  | {
      /** cart length after any removed items */
      newCartLength: number
      /** how many items were invalid */
      unavailCount: number
      /** details about the invalid items */
      invalidItemData: InvalidItemDetails[]
      /** Whether the user decided to cancel checkout */
      cancelCheckout: boolean
    }
  | {
      /** Whether the user decided to cancel checkout */
      cancelCheckout: true
    }
>

export type ValidateCartAlert = (props?: CartAlertProps) => ValidateCartAlertReturn

/** Returns a callback that validates cart and handles any user facing alerts that depend on the validation result */
export function useValidateCartAlert(opts: { isAdmin: boolean } = { isAdmin: false }) {
  const { cart, updateQuantity, loadingCart } = useCartService(opts.isAdmin)
  const { availAddonsResults } = useAvailAddons()

  return useCallback<ValidateCartAlert>(
    async function validateCartAlert({ onGoToShopPress } = { onGoToShopPress: undefined }) {
      // Make sure that the cart is not loading or updating before we validate it
      if (loadingCart) {
        Alert(
          'Cart is updating',
          `Please wait a few seconds and try again. If the problem persists, please contact support.`,
        )
        return Promise.resolve<Resolved<ValidateCartAlert>>({ cancelCheckout: true })
      }

      // Check cart isn't empty
      if (!cart.length) {
        return new Promise((resolve) => {
          Alert('Empty Cart!', `Please ${!opts.isAdmin ? 'visit a farm shop and ' : ''}add something to your cart`, [
            {
              text: 'Continue shopping',
              onPress: () => {
                resolve({ cancelCheckout: true })
                if (!opts.isAdmin) onGoToShopPress?.()
              },
            },
          ])
        })
      }

      // For admin, we don't require addons to be in the list of available addons, they can order any they want
      if (!opts.isAdmin) {
        // Ensure all addons in the cart are available for purchase
        const unavailAddonsInCartResults = cart
          .map((ci) => {
            if (!isAddon(ci.product)) return undefined
            const res = availAddonsResults.find((res) => res.id === ci.product.id)
            if (!res)
              Logger.error(
                new DataError('A cart addon was not found in the avail addon results.', {
                  product: ci.product,
                  results: availAddonsResults,
                }),
              )
            return res
          })
          .filter(isNonNullish)
          .filter((res) => !res.isAvail)

        if (unavailAddonsInCartResults.length) {
          return new Promise((resolve) => {
            Alert(
              'Addons',
              `Addons require a compatible primary share in your cart, or in your past purchases.\n\n${unavailAddonsInCartResults.map(
                (res) => ` ${bullet} "${res.addon.name}": ${res.unavailReason}\n`,
              )}`,
              [
                {
                  text: 'Continue shopping',
                  onPress: () => {
                    resolve({ cancelCheckout: true })
                    if (!opts.isAdmin) onGoToShopPress?.()
                  },
                },
                {
                  text: 'Cancel',
                  style: 'destructive',
                  onPress: () => {
                    resolve({ cancelCheckout: true })
                  },
                },
              ],
            )
          })
        }
      }

      try {
        const { unavailCount, newCartLength, invalidItemData } = await updateInvalidCartItems({
          cart,
          updateQuantity,
          isAdmin: opts.isAdmin,
        })

        const alertText = `Some of your items became unavailable, prices changed or their quantities exceeded the most recent quantity in stock. ${unavailCount} ${plural(
          unavailCount,
          'item',
        )} in your cart ${plural(unavailCount, 'was')} updated or removed.${
          newCartLength > 0
            ? ' You may wish to review your items before proceeding.'
            : "\n\nYour cart has no items left. Please visit the farm's shop to see their current products."
        }\n\n${invalidItemData.map((data) => {
          const item = cart.find((ci) => ci.id === data.id)!
          const message = getInvalidItemMessageUi(data)
          return ` ${bullet} "${item.product.name}": ${message}\n`
        })}`

        /** If any changes were made to the cart, we must notify the user and ask for input */
        if (unavailCount > 0) {
          /** getButtons will generate the buttons for the alert. It should use the promise resolve */
          const getButtons = (resolve: (v: Resolved<ValidateCartAlert>) => void): AlertButton[] => {
            // For admin, we won't show this default option, since we do not want to send them to the shop if they are using the order creator
            const buttons: AlertButton[] = !opts.isAdmin
              ? [
                  {
                    text: "Visit the farm's shop",
                    onPress() {
                      /** The user can always choose to continue shopping, to make up for any removals. This option should exist regardless of the new cart length */
                      resolve({ unavailCount, newCartLength, invalidItemData, cancelCheckout: true })
                      if (!opts.isAdmin) onGoToShopPress?.()
                    },
                  },
                ]
              : []

            /** If there's items left, the user can also continue or cancel */
            if (newCartLength > 0) {
              buttons.unshift(
                {
                  text: 'Continue',
                  onPress() {
                    resolve({ unavailCount, newCartLength, invalidItemData, cancelCheckout: false })
                  },
                },
                {
                  text: 'Cancel',
                  style: 'destructive',
                  onPress() {
                    /** This 'cancel' option should serve as a way for the user to review their cart with any changes made automatically, before proceeding */
                    resolve({ unavailCount, newCartLength, invalidItemData, cancelCheckout: true })
                  },
                },
              )
            }
            return buttons
          }

          /** Navigation, or any other action outside this fn should await for this promise, which will return the user input from this alert */
          return new Promise((resolve) => {
            Alert('Some items no longer available', alertText, getButtons(resolve))
          })
        } else {
          /** If no items were changed or removed, just return the validation results, no alerts required */
          return { unavailCount, newCartLength, invalidItemData, cancelCheckout: false }
        }
      } catch (error) {
        Logger.error(error)

        /** If there was an error while validating the cart, we will give them the chance to try again or cancel */
        return new Promise<Resolved<ValidateCartAlert>>((resolve) => {
          Alert(
            'Cart validation error',
            'Something went wrong while trying to validate the items in your cart. Please check your internet connection and try again, otherwise please contact support so we can help you finish placing your order.',
            [
              {
                text: 'Try again',
                onPress() {
                  /** If they try again, this promise is resolving with a new promise by recursively referencing itself, so the outer caller should continue awaiting for the next try */
                  resolve(validateCartAlert({ onGoToShopPress }))
                },
              },
              {
                text: 'Cancel',
                style: 'destructive',
                onPress() {
                  /** This 'cancel' option should serve as a way for the user to review their cart with any changes made automatically, before proceeding */
                  resolve({ cancelCheckout: true })
                },
              },
            ],
          )
        })
      }
    },
    [cart, loadingCart, updateQuantity, opts.isAdmin, availAddonsResults],
  )
}
