import { formatMoney, InvalidAmount } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Infinite, Money, Zero } from '@models/Money'

export const totalPriceDisplay = (numberPickups: number, pricePerPickup?: Money): string => {
  if (pricePerPickup === undefined) return InvalidAmount

  const priceWithDeposit = MoneyCalc.multiply(pricePerPickup, numberPickups || 0)
  if (priceWithDeposit.value === Infinity) return InvalidAmount
  // returns the amount as a formatted string
  return formatMoney(priceWithDeposit)
}

export const calculateTotalPrice = (numberPickups: number, pricePerPickup?: Money): Money => {
  if (pricePerPickup === undefined) return Zero

  const priceWithDeposit = MoneyCalc.multiply(pricePerPickup, numberPickups || 0)
  if (priceWithDeposit.value === Infinity) return Infinite
  if (priceWithDeposit.value === 0 || isNaN(priceWithDeposit.value)) return Infinite
  return priceWithDeposit
}

export const calculateDiscount = (totalPrice: Money, largestTotalPrice: Money): string => {
  if (MoneyCalc.isEqual(largestTotalPrice, totalPrice)) return '0%'
  if (MoneyCalc.isZero(totalPrice)) return InvalidAmount
  if (isNaN(totalPrice.value)) return InvalidAmount
  if (MoneyCalc.isInfinite(totalPrice)) return InvalidAmount
  // Calculate the percent discount from the largest price
  const discountDecimal = MoneyCalc.divide(
    MoneyCalc.subtract(largestTotalPrice, totalPrice),
    largestTotalPrice.value,
  ).value
  const discountPercent = Math.round(discountDecimal * 100)
  if (isNaN(discountDecimal)) return InvalidAmount
  return `${discountPercent}%`
}
