import { ToolTips } from '@components'
import { Button, ButtonClear, IconProps, TextH2, Tooltip } from '@elements'
import { StyleProp, View, ViewStyle } from 'react-native'

import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '../../../../../hooks/useLayout'

export type WithRightTouchableIcon = {
  name: IconProps['name']
  onPress: () => void
}

type Props = {
  title: string
  btns: {
    title: string
    clear?: boolean
    onPress?: () => void
  }[]
  toolTipId?: ToolTips
  toolTipTitle?: string
  containerStyle?: StyleProp<ViewStyle>
}
export function Header({ title, btns, toolTipId, toolTipTitle, containerStyle }: Props) {
  const { isSmallDevice, isLargeDevice } = useDeviceSize()

  const styles = useStyles()

  return (
    <View style={[styles.header, containerStyle]}>
      <TextH2>
        {title} {toolTipId && <Tooltip title={toolTipTitle ?? title} id={toolTipId} />}
      </TextH2>
      <View style={styles.btnCont}>
        {btns.map((btn) =>
          btn.clear ? (
            <ButtonClear
              size={isLargeDevice ? 15 : 12}
              small={isSmallDevice}
              style={isSmallDevice && styles.smallBtn}
              key={btn.title}
              title={btn.title}
              onPress={btn.onPress}
            />
          ) : (
            <Button
              small={isSmallDevice}
              size={isLargeDevice ? 15 : 12}
              outline
              style={isSmallDevice && styles.smallBtn}
              key={btn.title}
              title={btn.title}
              onPress={btn.onPress}
            />
          ),
        )}
      </View>
    </View>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
    },
    title: {
      marginRight: 20,
    },
    btnCont: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexWrap: 'wrap',
      flex: 1,
      gap: isSmallDevice ? 10 : undefined,
    },
    smallBtn: {
      paddingHorizontal: 10,
    },
  }))
