import { omit, PartialPick, pick } from '@helpers/typescript'
import { Coupon, CouponType, PromoCode } from '@models/Coupon'
import { Invoice, InvoiceStatus } from '@models/Invoice'
import { where } from 'firebase/firestore'

import { marshalDate } from './encoding/Time'
import { couponsCollection, invoicesCollection, promoCodeCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** Get coupon by ID */
export async function getCoupon(farmId: string, couponId: Coupon['id']) {
  return await couponsCollection.resolve(farmId).fetch(couponId)
}

/** list all coupons for a farm */
export async function listCoupons(farmId: string, includeArchived?: boolean) {
  const q = includeArchived ? [] : [where('archived', '==', false)]
  return await couponsCollection.resolve(farmId).fetchAll(...q)
}

/** List all promo codes by coupon */
export async function listPromosForCoupon(farmId: string, couponId: Coupon['id']) {
  return await promoCodeCollection.resolve(farmId).fetchAll(where('coupon.id', '==', couponId))
}

/** Will list all invoices that have redeemed the given coupon */
export async function listCouponRedemptions(farmId: string, couponId: Coupon['id']): Promise<Invoice[]> {
  return await invoicesCollection.fetchAll(
    where('couponApplied.coupon.id', '==', couponId),
    where('status', '!=', InvoiceStatus.Void),
    where('farm.id', '==', farmId),
  )
}

/** Will add a new coupon to the farm */
export async function addCoupon<Type extends CouponType>(
  coupon: PartialPick<Coupon<Type>, 'type' | 'value' | 'farm' | 'name'>,
): Promise<Coupon<Type>> {
  const newCoupon: Coupon<Type> = { ...coupon, id: '', timesRedeemed: 0, archived: false }
  return (await couponsCollection.resolve(coupon.farm.id).create(newCoupon)) as Coupon<Type>
}

/** Will update the given coupon only if it has not been redeemed before */
export async function updateCoupon(coupon: PartialPick<Coupon, 'id' | 'farm'>) {
  const fbCoupon = await couponsCollection.resolve(coupon.farm.id).fetch(coupon.id)
  if (fbCoupon.timesRedeemed > 0)
    throw new Error(
      'Cannot update this coupon since it has been redeemed, you can archive this coupon and make a new one.',
    )
  if (coupon.type && fbCoupon.type !== coupon.type) {
    throw new Error('Cannot update a coupons type, you can archive this coupon and make a new one.')
  }
  return couponsCollection.resolve(coupon.farm.id).update(coupon)
}

/** Will archive this coupon */
export async function archiveCoupon(coupon: PartialPick<Coupon, 'id' | 'farm'>) {
  return couponsCollection.resolve(coupon.farm.id).update({ id: coupon.id, archived: true })
}
/** Will unarchive this coupon */
export async function unArchiveCoupon(coupon: PartialPick<Coupon, 'id' | 'farm'>) {
  return couponsCollection.resolve(coupon.farm.id).update({ id: coupon.id, archived: false })
}

/** Will add a promo code to a given coupon */
export async function addPromoCode(
  promo: Omit<PromoCode, 'id' | 'coupon'>,
  coupon: PartialPick<Coupon, 'farm' | 'id'>,
): Promise<PromoCode> {
  const { promoCode } = await callEndpoint('v2.Coupon.addPromoCodeService', {
    promo: {
      ...promo,
      expiration: promo.expiration ? marshalDate(promo.expiration) : undefined,
    },
    coupon,
  })
  return {
    ...promo,
    coupon: pick(coupon, 'id', 'farm'),
    id: promoCode,
  }
}

/** Will update a promo code, there are no restrictions as promo codes are meant to be temporary */
export async function updatePromoCode(promo: PartialPick<PromoCode, 'id' | 'coupon'>) {
  // timesRedeemed should never be updated
  const newPromo = omit(promo, 'timesRedeemed')
  return promoCodeCollection.resolve(promo.coupon.farm.id).update(newPromo)
}

/** Will delete a promo code from a coupon, this cannot be un-done */
export async function deletePromoCode(promo: Pick<PromoCode, 'coupon' | 'id'>) {
  return promoCodeCollection.resolve(promo.coupon.farm.id).delete(promo.id)
}
