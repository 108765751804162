import { checkIsValidWorldPayMerchantId, updateFarm } from '@api/Farms'
import { Farm } from '@models/Farm'
import { PaymentsFormProps } from './types'

/** This will verify the merchant id. Will throw errors if invalid or unsuccessful farm ebt enabling */
export const onFarmMerchantValidate = async (farm: Farm, values: PaymentsFormProps): Promise<void> => {
  // This will throw an error if not valid
  await checkIsValidWorldPayMerchantId(values.worldPayMerchantId)

  // If merchant is valid, mark the ebt onboarding step as completed
  await setEbtStepCompleted(farm)
}

/** Whether the merchant verification should run
 * @returns true if the ebt payment is enabled and db merchant id is different than the form value
 */
export const shouldValidateMerchantId = (farm: Farm, values: PaymentsFormProps): boolean => {
  if (!values.ebtPaymentEnabled) return false

  return values.worldPayMerchantId !== farm.worldPayMerchantId
}

/** Will set the "ebt" onboard step as completed */
export const setEbtStepCompleted = async (farm: Farm) => {
  if (!farm.onboardSteps?.ebt) {
    const onboardSteps = { ...farm.onboardSteps, ebt: true }

    await updateFarm({ id: farm.id, onboardSteps })
  }
}

/** Will get the tipsAndFees object, according to current values
 * - `minimumCartOption` prop will exist only if a min cart amount is defined
 */
export const getTipOptionsFromValues = (values: PaymentsFormProps): Farm['tipsAndFees'] => {
  const tipOptions = {
    showTipOption: values.showTipOption,
    customerChooseToPayFees: values.customerChooseToPayFees,
    minimumCartAmount: values.minCartAmount,
  }

  if (!values.minCartAmount) {
    delete tipOptions.minimumCartAmount
  }

  return tipOptions
}

/** Maps farm data to Formik object values */
export const mapFarmToFieldValues = (farm: Farm): PaymentsFormProps => {
  const obj: PaymentsFormProps = {
    offlinePaymentsMessage: farm.offlinePayments || '',
    offlineEnabled: farm.paymentTypes.cash,
    worldPayMerchantId: farm.worldPayMerchantId || '',
    ebtPaymentEnabled: farm.paymentTypes.ebt,
    fnsOnline: farm.ebt?.fnsOnline || '',
    showTipOption: farm.tipsAndFees?.showTipOption ?? true,
    customerChooseToPayFees: farm.tipsAndFees?.customerChooseToPayFees ?? true,
    minCartSelected: farm.tipsAndFees?.minimumCartAmount !== undefined,
    minCartAmount: farm.tipsAndFees?.minimumCartAmount,
    achPaymentEnabled: farm.paymentTypes.check,
  }
  return obj
}
