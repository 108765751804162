import { hasHitEbtEventLimit, logEvent } from '@api/Event'
import { addPaymentMethod } from '@api/Payments'
import { verifyAppCheck } from '@api/appCheck'
import { Alert, Button, Divider, ErrorText, Toast } from '@elements'
import { errorToString } from '@helpers/helpers'
import { uuidString } from '@helpers/uuid'
import { EventTypes } from '@models/Event'
import { PaymentSources } from '@models/Invoice'
import { EbtPaymentMethod, PaymentForms, PaymentMethod } from '@models/PaymentMethod'
import { addHours } from 'date-fns'
import { useCallback, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import EProtect from '../../../components/EBT/eProtect/eProtect'
import { EProtectClientInterface } from '../../../components/EBT/eProtect/helpers/types'
import { useApiFx } from '../../../hooks/useApiFx'

/** Modal for adding a new EBT card */
export function AddEbtCard({
  onDismiss,
  userId,
}: {
  userId: string
  /** Will be called either on create or on abort */
  onDismiss: (newPayment?: PaymentMethod) => void
}) {
  // Defaults to true as it will be modified when EProtect finishes loading
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const eProtectClient = useRef<EProtectClientInterface>()
  const appCheck = useApiFx(verifyAppCheck, [], undefined, { noRefocus: true })

  const onSubmitHandler = useCallback(async () => {
    if (await hasHitEbtEventLimit(EventTypes.EbtCardChangeAttempt, userId)) {
      return setError('The EBT card has been changed too many times. Please contact support.')
    }
    // If the app check has not loaded or failed then we should not allow the user to add an EBT card
    if (!appCheck.data) {
      return setError(
        'Unable to verify identity, please reload GrownBy and try again. If the issue persists contact support.',
      )
    }

    setLoading(true)
    const uniqueId = uuidString(16)

    const message = {
      id: uniqueId,
      orderId: uniqueId + '-order',
      // Since some EBT cards are not mod10 compliant, we need to mark it as pciNonSensitive.
      // https://developerengine.fisglobal.com/apis/usecomm/eprotect/integrating/iframe-checkout#notes-on-the-pci-non-sensitive-value-feature
      pciNonSensitive: true,
    }
    try {
      const res = await eProtectClient.current?.getCombinedTokens(message)

      setLoading(false)
      // Show any WorldPay errors here
      if (!res) return setError('No response from caller')

      if (res?.panResponse.message !== 'Success') {
        return setError(res?.panResponse.message)
      }
      if (res?.pinResponse.message !== 'Success') {
        return setError(res?.pinResponse.message)
      }

      const ebt: EbtPaymentMethod = {
        id: '',
        source: PaymentSources.WORLD_PAY_EBT,
        type: PaymentForms.EBT,
        isDefault: true,
        tokenType: 'low-value',
        token: res.panResponse.paypageRegistrationId,
        last4: res.panResponse.lastFour,
        holder_name: 'EBT Card',
        pin: {
          token: res.pinResponse.pinCheckoutId,
          expires: addHours(Date.now(), 24).getTime(),
        },
      }
      const payMethod = await addPaymentMethod(userId, ebt)
      Toast('Payment method added successfully')
      await logEvent({
        type: EventTypes.EbtCardChangeAttempt,
        data: { user: { id: userId }, paymentMethod: { id: payMethod.id } },
      })
      onDismiss(payMethod)
    } catch (e) {
      Alert('Error adding card', 'There was an error adding your EBT card' + errorToString(e))
      setLoading(false)
    }
  }, [onDismiss, userId, appCheck.data])

  const onFinishLoading = useCallback(() => setLoading(false), [])
  return (
    <View>
      <View style={styles.eProtectCont}>
        <EProtect onFinishLoading={onFinishLoading} eProtectRef={eProtectClient} mode="combined" />
      </View>
      <ErrorText>{error}</ErrorText>
      <Button loading={isLoading || appCheck.loading} title="Add EBT Card" onPress={onSubmitHandler} />
      <Divider clear bottom={20} />
    </View>
  )
}

const styles = StyleSheet.create({
  eProtectCont: {
    padding: 20,
    minHeight: 200,
  },
})
