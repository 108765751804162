import { TextProps, StyleSheet } from 'react-native'

import { ToolTips } from '../ToolTips/ToolTips'
import { useToolTip } from '@/hooks/useToolTip'
import { useFlatStyle } from '../../hooks/useMergeStyle'
import { useMemo } from 'react'
import { InfoTooltip } from '../ToolTips/InfoTooltip'

// TODO: Should add the option to have dynamic content for a tooltip, we can pass in a custom tooltip element

interface Props extends TextProps {
  /** @deprecated This will no longer show and should be removed, and removal validated or put in tooltip content. */
  title?: string
  id: ToolTips
  size?: number
  //TODO: Let's remove this and use a dynamic tooltip for this
  duration?: number
}

/** The tooltip component renders a ? icon and allows clicking or hovering to open the tooltip */
export function Tooltip({ id, size = 10, duration }: Props) {
  const content = useMemo(() => <InfoTooltip id={id} duration={duration} />, [id, duration])
  const {
    TipButton,
    TipOverlay,
    overlayProps: { overlayStyle },
  } = useToolTip(content)

  return (
    <>
      <TipOverlay overlayStyle={useFlatStyle([overlayStyle, styles.tooltipWrapper])} />
      <TipButton size={size} />
    </>
  )
}

const styles = StyleSheet.create({
  tooltipWrapper: {
    maxWidth: 300,
  },
})
