import { Button, SearchInputBar, Text } from '@elements'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useMemo, useState } from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet, View } from 'react-native'

import { useSignInSheetContext } from './useSignInSheetData'

import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { SignInSheetParamList } from '@/navigation/types'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { formatLongDate } from '@helpers/display'
import { isDelivery, isShipping } from '@models/Location'
import { SharedScreenHeader, SignInUserRow, TabSelector, sharedStyles } from './components'
import { GroupedItemType, filterBySearchValue, isPickingUp, isReceived } from './helpers'
import { withDataAndAuth } from './withDataAndAuth'

type Props = StackScreenProps<SignInSheetParamList, 'ElectronicSignInSheet'>

function ElectronicSignInSheetComp({ navigation, route }: Props) {
  const { location, signInSummary, items, onUpdateStatusByUser } = useSignInSheetContext()

  const [tabIdx, setTabIdx] = useState(0)
  const [expandedUserIds, setExpandedUserIds] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState('')

  const onToggleExpanded = useCallback(
    (id: string) => {
      if (!expandedUserIds.includes(id)) {
        setExpandedUserIds([...expandedUserIds, id])
      } else {
        setExpandedUserIds(expandedUserIds.filter((usrId) => usrId !== id))
      }
    },
    [expandedUserIds],
  )

  /**Items used in Active & Received tabs */
  const { receivedItems, activeItems } = useMemo(() => {
    const filtered = items.filter(filterBySearchValue(searchValue))

    const receivedItems = filtered.filter(isReceived)
    const activeItems = filtered.filter(isPickingUp)
    return { receivedItems, activeItems }
  }, [items, searchValue])

  const tabBtns = useMemo(() => {
    if (!location) return []

    const leftBtnTitle = isDelivery(location) ? 'For Delivery' : isShipping(location) ? 'For Shipping' : 'Picking Up'

    return [
      { title: leftBtnTitle, badge: activeItems.length },
      { title: 'Received', badge: receivedItems.length },
    ]
  }, [activeItems.length, receivedItems.length, location])

  const Header = useMemo(() => {
    if (!signInSummary) return null

    return (
      <View>
        <SharedScreenHeader />

        <View style={styles.margin5} />
        <Text size={20} type="medium" center>
          {location?.name}
        </Text>
        <Text center>{formatLongDate(signInSummary.date)}</Text>
        <View style={globalStyles.margin10} />
        <SearchInputBar
          value={searchValue}
          onChangeText={setSearchValue}
          style={undefined}
          placeholder="Search name, item, order number"
        />
        <View style={globalStyles.margin10} />

        <TabSelector buttons={tabBtns} selectedIndex={tabIdx} onSelect={setTabIdx} />
        <View style={styles.margin5} />

        {!isWeb && (
          <View style={styles.swipeIconCont}>
            {tabIdx === 1 && (
              <>
                <Text size={16} color={Colors.shades[200]}>
                  Swipe right to undo
                </Text>
              </>
            )}
            <View style={styles.margin5}>
              <MaterialCommunityIcons
                name={`chevron-triple-${tabIdx === 0 ? 'left' : 'right'}`}
                color={Colors.shades[100]}
                size={30}
              />
            </View>

            {tabIdx === 0 && (
              <>
                {location && (
                  <Text size={16} color={Colors.shades[200]}>
                    Swipe left to {isDelivery(location) ? 'deliver' : isShipping(location) ? 'ship' : 'pickup'}
                  </Text>
                )}
              </>
            )}
          </View>
        )}

        <View style={globalStyles.margin10} />
      </View>
    )
  }, [signInSummary, location, searchValue, tabBtns, tabIdx])

  /** Navigates to the next screen
   - If there are unpicked items, `EndSignIn` screen will be displayed, allowing statuses update for those items
   - Else, it goes to `AddNotes` screen
  */
  const onEndSignInPress = () => {
    if (activeItems.length) {
      navigation.navigate('EndSignIn', { id: route.params.id, token: route.params.token })
    } else {
      navigation.navigate('AddNotes', { id: route.params.id, token: route.params.token })
    }
  }

  const onOrderDetailsPress = useCallback(
    (userId: string) => {
      navigation.navigate('PickupSummary', { userId, id: route.params.id, token: route.params.token })
    },
    [navigation, route.params.id, route.params.token],
  )

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<GroupedItemType>) => {
      return (
        <SignInUserRow
          item={item}
          isExpanded={expandedUserIds.includes(item.user.id)}
          onToggleExpanded={onToggleExpanded}
          onUpdateStatus={onUpdateStatusByUser}
          onOrderDetailsPress={onOrderDetailsPress}
        />
      )
    },
    [expandedUserIds, onOrderDetailsPress, onToggleExpanded, onUpdateStatusByUser],
  )

  return (
    <FlatList
      contentContainerStyle={sharedStyles.wrapperNoAlign}
      ListHeaderComponent={Header}
      data={tabIdx === 0 ? activeItems : receivedItems}
      showsVerticalScrollIndicator={isWeb}
      renderItem={renderItem}
      keyExtractor={(itm) => itm.user.id}
      ListFooterComponent={<Button onPress={onEndSignInPress} title="Continue" />}
    />
  )
}

export const ElectronicSignInSheet = withDataAndAuth(ElectronicSignInSheetComp)

const styles = StyleSheet.create({
  margin5: {
    margin: 5,
  },
  swipeIconCont: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerBtnsCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
