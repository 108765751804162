import { FormBuilder } from '@components'
import { YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { PartialExcept } from '@helpers/typescript'
import { isShare, Product, ProductType } from '@models/Product'
import { useFormikContext } from 'formik'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

import InputLabel from '../../../components/InputLabel'
import FormSectionHeader from '../components/FormSectionHeader'
import FormWrapper from '../components/FormWrapper'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'

import { useDeviceSize } from '@/hooks/useLayout'

type PricingFormType = {
  numberOfPickups: number
  quantity: number
}

const pricingValidation = Yup.object<PricingFormType>().shape({
  numberOfPickups: Yup.number().when('type', {
    is: (type: ProductType) => isShare({ type }),
    then: () => YUP_WHOLE_NUMBER_REAL('Number Of Pickups'),
  }),
  quantity: Yup.number().when('type', {
    is: (type: ProductType) => isShare({ type }),
    then: () => YUP_WHOLE_NUMBER_REAL('Quantity', { allowZero: true }).required('Quantity is required.'),
  }),
})

const toFormik = (product: Pick<Product, 'type'>): PricingFormType => {
  if (isShare(product)) {
    return {
      numberOfPickups: product.numberPickups,
      quantity: product.quantity,
    }
  }

  return {
    numberOfPickups: 1,
    quantity: 1,
  }
}

function fromFormik<T extends Partial<PricingFormType> & { type?: ProductType }>(values: T): Partial<Product> {
  const base: PartialExcept<Product, 'type'> = {
    type: values.type!,
    quantity: Number(values.quantity),
  }
  // If it is not a share return nothing
  if (!isShare(base)) return {}

  base.numberPickups = Number(values.numberOfPickups)

  return base
}

export const FormikSharePricing = new ProductFormikComponent(pricingValidation, toFormik, fromFormik)

export default function SharePricing() {
  const { isSmallDevice } = useDeviceSize()
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext<
    PricingFormType & { isChild: boolean; templateProductId: string; type: ProductType }
  >()

  return (
    <FormWrapper>
      <FormSectionHeader title="Pickups and Inventory" />
      <FormBuilder row={!isSmallDevice}>
        <Input
          value={values.numberOfPickups?.toString() || ''}
          placeholder="0"
          label={<InputLabel label="Number of Pickups" required />}
          onChangeText={handleChange('numberOfPickups')}
          onBlur={handleBlur('numberOfPickups')}
          errorMessage={touched.numberOfPickups ? errors.numberOfPickups : ''}
        />

        {isShare({ type: values.type }) && (
          <Input
            value={values.quantity?.toString() || ''}
            placeholder="0"
            label={<InputLabel label="In Stock" required />}
            onChangeText={handleChange('quantity')}
            onBlur={handleBlur('quantity')}
            errorMessage={touched.quantity ? errors.quantity : ''}
          />
        )}
      </FormBuilder>
    </FormWrapper>
  )
}
