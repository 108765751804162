import { createContext, Dispatch, SetStateAction } from 'react'

type ContextType = {
  condensedView: 'map' | 'list'
  setCondensedView: Dispatch<SetStateAction<'map' | 'list'>>
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  showCarousel: boolean
  setShowCarousel: Dispatch<SetStateAction<boolean>>
}

export const ExploreContext = createContext<ContextType>({} as ContextType)
