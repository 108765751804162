import { FontAwesome } from '@expo/vector-icons'
import { openUrl } from '@helpers/client'
import { capitalize, getOrderNum } from '@helpers/display'
import { format } from '@helpers/time'
import { ProductType } from '@models/Product'
import { DistributionDetailsSummary, selectedUnitName } from '@models/Summary'
import { userName } from '@models/User'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ListRenderItemInfo, StyleSheet, TouchableOpacity, View } from 'react-native'

import { Text } from '../../../components/elements/Text'
import { grownbyWebsiteBaseUrl } from '../../../config/Environment'
import { globalStyles } from '../../../constants/Styles'
import { useSort } from '../../../hooks/useSort'

import { OfflineTable } from '@/admin/components/OfflineTable/OfflineTable'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { PickupItemStatus } from '@models/Order'
import { memo } from 'react'
import { getProductLink } from './helpers'

type SignInSheetProps = {
  summary: DistributionDetailsSummary
}

export const SignInSheets = memo(function SignInSheets({ summary }: SignInSheetProps) {
  const navigation = useNavigation<StackNavigationProp<any>>()

  const rows = summary.items.flatMap((item) =>
    item.item.selectedUnits.map((selectedUnit) => ({
      product: item.item.product,
      deliveryDate: format(item.deliveryDate, 'MM/dd/yyyy'),
      locationName: item.location.name,
      customer: item.user,
      customerName: userName(item.user),
      productName: item.item.product.templateProduct ? item.item.product.templateProduct.name : item.item.product.name,
      selectedUnit: item.item.product.templateProduct
        ? item.item.product.name
        : [ProductType.AddonShare, ProductType.PrimaryShare].includes(item.item.product.type)
        ? ''
        : selectedUnitName(item.item, selectedUnit),
      quantity: selectedUnit.quantity,
      orderId: item.order.id,
      orderNum: getOrderNum(item.order.orderNum),
      pickupItemStatus: capitalize(selectedUnit.status === PickupItemStatus.Active ? '' : selectedUnit.status),
      templateProductId: item.item.product.templateProduct?.id,
    })),
  )
  const sorter = useSort<typeof rows[0]>()
  const sortedRows = sorter.apply(rows)

  function SortIcon(field: keyof typeof rows[0]) {
    return (
      <TouchableOpacity style={tableStyles.iconCont} onPress={() => sorter.sortBy(field)}>
        <FontAwesome name="sort" size={16} color={Colors.primaryGray} />
      </TouchableOpacity>
    )
  }

  const renderItem = ({ item }: ListRenderItemInfo<typeof rows[0]>) => {
    const onCustomerPress = () => {
      if (isWeb) {
        const url = `${grownbyWebsiteBaseUrl()}admin/customers/${item.customer.id}`
        openUrl(url, { target: '_blank' })
      } else {
        navigation.navigate('Customer', { screen: 'CustomerDetails', params: { custId: item.customer.id } })
      }
    }

    /**
     * Product Name can be a template product name or a product name, if it's a template product name then it should be a link to the template product edit screen. Otherwise it should be a link to the product edit screen.
     */
    const onProductPress = () => getProductLink(navigation, item.product.id, item.templateProductId)

    const onOrderPress = () => {
      if (isWeb) {
        const url = `${grownbyWebsiteBaseUrl()}admin/orders/view/${item.orderId}`
        openUrl(url, { target: '_blank' })
      } else {
        navigation.navigate('Orders', { orderId: item.orderId })
      }
    }

    return (
      <View style={tableStyles.rowCont}>
        <View style={tableStyles.mediumCell}>
          <Text>{item.deliveryDate}</Text>
        </View>
        <View style={tableStyles.largeCell}>
          <Text>{item.locationName}</Text>
        </View>

        <TouchableOpacity onPress={onCustomerPress} style={tableStyles.mediumCell}>
          <Text color={Colors.blue}>{item.customerName}</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={onProductPress} style={tableStyles.largeCell}>
          <Text color={Colors.blue}>{item.productName}</Text>
        </TouchableOpacity>

        <View style={tableStyles.mediumCell}>
          <Text>{item.selectedUnit}</Text>
        </View>
        <View style={tableStyles.smallCell}>
          <Text>{item.quantity}</Text>
        </View>

        <TouchableOpacity onPress={onOrderPress} style={tableStyles.smallCell}>
          <Text color={Colors.blue}>{item.orderNum}</Text>
        </TouchableOpacity>

        <View style={tableStyles.mediumCell}>
          <Text>{item.pickupItemStatus}</Text>
        </View>
      </View>
    )
  }
  function Header() {
    return (
      <View style={tableStyles.headerRow}>
        <View style={tableStyles.mediumCell}>
          <Text>Date</Text>
          {SortIcon('deliveryDate')}
        </View>
        <View style={tableStyles.largeCell}>
          <Text>Location</Text>
          {SortIcon('locationName')}
        </View>
        <View style={tableStyles.mediumCell}>
          <Text>Customer</Text>
          {SortIcon('customerName')}
        </View>

        <View style={tableStyles.largeCell}>
          <Text>Product</Text>
          {SortIcon('productName')}
        </View>
        <View style={tableStyles.mediumCell}>
          <Text>Buying</Text>
          {SortIcon('selectedUnit')}
        </View>
        <View style={tableStyles.smallCell}>
          <Text>Qty</Text>
          {SortIcon('quantity')}
        </View>
        <View style={tableStyles.smallCell}>
          <Text>Order #</Text>
          {SortIcon('orderNum')}
        </View>
        <View style={tableStyles.mediumCell}>
          <Text>Status</Text>
          {SortIcon('pickupItemStatus')}
        </View>
      </View>
    )
  }

  return (
    <OfflineTable<typeof rows[0]>
      minWidth={1000}
      containerStyle={globalStyles.margin10}
      ListHeaderComponent={<Header />}
      data={sortedRows}
      renderItem={renderItem}
    />
  )
})

export const tableStyles = StyleSheet.create({
  largeCell: {
    flex: 2,
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  smallCell: {
    flex: 1,
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  mediumCell: {
    flex: 1.5,
    paddingHorizontal: 5,
    flexDirection: 'row',
  },

  rowCont: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades[100],
    padding: 5,
  },
  iconCont: {
    marginLeft: 5,
    alignSelf: 'center',
  },
  headerRow: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: Colors.lightGreen,
  },
})
