import { NavigationRoute } from '@/admin/navigation/helperTypes'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { useState } from 'react'
import { useFocusFx } from './useFocusFx'

/** Gets the navigation route name of the current component.
 * - This is different from the current route. The current route refers to the current route focused. The component route is not expected to change a component instance will never change, whereas the current route is expected to change as the user navigates to a new screen.
 * - The only situation when the component route might change is if the same component instance is used in more than one screen, which can only happen with a component like the react-navigation main app header in which the same instance might be shared by multiple screens. In other navigators the Header would be a different instance of the same Header component.
 *  */
export function useComponentRoute() {
  const [route, setRoute] = useState<NavigationRoute>()
  const navigation = useNavigation<NavigationProp<any>>()

  useFocusFx(() => {
    setRoute(navigation.getState().routes[navigation.getState().index])
  }, [navigation])

  return route
}
