import { loadFarmBaseUnits } from '@api/BaseUnits'
import { ToolTips } from '@components'
import {
  AddNewDropdownItem,
  ButtonClear,
  CheckBox,
  FormDisplayRow,
  FormInput,
  FormMoneyInput,
  FormPickerInput,
} from '@elements'
import { YUP_MONEY_OPTIONAL, YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { PartialPick } from '@helpers/typescript'
import { Money } from '@models/Money'
import { hasUnits, isDigital, isShare, isStandard, Product, ProductType, Unit } from '@models/Product'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import InputLabel from '../../../components/InputLabel'
import FormSectionHeader from '../components/FormSectionHeader'
import { createUnitOrSetValue } from './helpers/baseUnitAdd.helper'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'

import { useApiFx } from '@/hooks/useApiFx'
import { adminFarmSelector } from '@/redux/selectors'
import DecimalCalc from '@helpers/decimal'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

export type UnitsFormType = {
  baseUnit?: string
  costPerUnit?: Money
  showCostPerUnit?: boolean
  pricePerUnit?: Money
  globalQuantity?: number
  unitStock: boolean
}

const unitSchema = Yup.object<UnitsFormType>().shape({
  baseUnit: Yup.string().when('type', {
    is: (type: ProductType) => hasUnits({ type }),
    then: (schema) => schema.required('Unit is required'),
  }),
  costPerUnit: YUP_MONEY_OPTIONAL('Cost per unit'),
  pricePerUnit: Yup.mixed<Money>().when('$unitStock', {
    is: true,
    then: () => YUP_MONEY_REQUIRED('Price per unit'),
  }),
  globalQuantity: Yup.number().when(['$unitStock', 'type'], {
    is: ($unitStock: boolean, type: ProductType) => !$unitStock && hasUnits({ type }),
    then: () => YUP_WHOLE_NUMBER_REAL('Quantity', { allowDecimal: true, allowZero: true }),
  }),
  unitStock: Yup.boolean().required(),
  showCostPerUnit: Yup.boolean().optional(),
})

const toFormik = (product: PartialPick<Product, 'type'>): UnitsFormType => {
  // Editing product since it has units
  if (hasUnits(product)) {
    return {
      baseUnit: product.baseUnit ?? '',
      costPerUnit: isStandard(product) ? product.costOfProduction ?? undefined : undefined,
      showCostPerUnit: !!(isStandard(product) && product.costOfProduction),
      pricePerUnit: product.pricePerUnit ?? undefined,
      globalQuantity: product.quantity, // OK for this to be optional, in case of unit stock.
      unitStock: product.unitStock ?? false,
    }
  }

  // This case should never happen, this component shouldn't render if the product type has no units
  return {
    baseUnit: '',
    costPerUnit: undefined,
    showCostPerUnit: false,
    pricePerUnit: undefined,
    globalQuantity: undefined,
    unitStock: false,
  }
}

function fromFormik<T extends UnitsFormType & { type?: ProductType }>(values: T): Partial<Product> {
  const base: PartialPick<Product, 'type'> = {
    type: values.type!,
  }

  // If this is not an advanced pricing product then don't return anything
  if (isShare(base)) return {}

  if (isStandard(base)) {
    base.costOfProduction = values.costPerUnit
  }

  if (hasUnits(base)) {
    base.baseUnit = values.baseUnit! // schema should've enforced it as required for unit products
    base.unitStock = values.unitStock
    base.pricePerUnit = !values.unitStock ? undefined : values.pricePerUnit
    base.quantity = !values.unitStock ? Number(values.globalQuantity ?? 0) : undefined

    return base
  }

  return {}
}

export const FormikUnits = new ProductFormikComponent(unitSchema, toFormik, fromFormik)

/** Displays form fields related to units on the form */
export function UnitsComponent() {
  const farm = useSelector(adminFarmSelector)
  const baseUnits = useApiFx(loadFarmBaseUnits, [farm.localBaseUnits], !!farm)
  const styles = useStyles()

  const {
    setFieldValue,
    values: formValues,
    handleBlur,
    errors,
    touched,
    setTouched,
    setValues,
    setFieldTouched,
  } = useFormikContext<
    UnitsFormType & {
      type: ProductType
      /** form' `buyingOptions` is populated primarily in AdvancedPricing component but we also access it here */
      buyingOptions: Unit[]
    }
  >()
  const type = formValues.type

  // Manage Stock Globally checkbox handler
  const stockGlobalHandler = useCallback(() => {
    if (formValues.unitStock) {
      // If we're switching from unit-stock to global-stock, the new global stock will be the sum of unit quantities
      setFieldValue(
        'globalQuantity',
        formValues.buyingOptions.reduce(
          (agg, curr) => DecimalCalc.add(agg, DecimalCalc.multiply(curr.quantity ?? 0, curr.multiplier ?? 1)),
          0,
        ),
      )
      //should manually set touched after setFieldValue happens to trigger validation again, so it will prevent falsy error message, 'setTimeout' can force setFieldTouched to run after setFieldValue and not sync.
      setTimeout(() => {
        setFieldTouched('globalQuantity', true)
      }, 10)
    } else {
      // Set globalQuantity to undefined when switching from global-stock to unit-stock. Note: using setFieldValue to set undefined value will let formik.values to delete the key (like it never existed), so setValues is used instead.
      setValues({ ...formValues, globalQuantity: undefined })
    }

    setFieldValue('unitStock', !formValues.unitStock)
  }, [formValues, setFieldTouched, setFieldValue, setValues])

  // unitBase onchange handler
  const unitBaseOnChange = useCallback(
    (value: string) => {
      if (value !== formValues.baseUnit) {
        setTouched({ ...touched, baseUnit: true })
      }
      createUnitOrSetValue(value, 'baseUnit', farm.id, (v) => setFieldValue('baseUnit', v))
    },
    [farm.id, formValues.baseUnit, setFieldValue, setTouched, touched],
  )

  const units = useMemo(() => {
    if (!baseUnits.data) return []

    return [...baseUnits.data]
      .sort()
      .map((baseUnit) => ({ label: baseUnit, value: baseUnit }))
      .concat([AddNewDropdownItem])
  }, [baseUnits.data])

  return (
    <View style={styles.formContainer}>
      <FormSectionHeader title="Units" />
      <CheckBox
        title="Manage Stock Globally"
        disabled={isDigital(formValues)}
        onChange={stockGlobalHandler}
        checked={!formValues.unitStock}
        style={styles.paddingLeft10}
        toolTipId={ToolTips.BASE_UNIT_GLOBAL}
        toolTipTitle="Global Inventory"
      />
      <FormDisplayRow ignoreSmall>
        <FormPickerInput
          loading={baseUnits.loading}
          label={<InputLabel label="Unit" tooltipId={ToolTips.BASE_UNIT} required />}
          items={units}
          onValueChange={unitBaseOnChange}
          value={formValues.baseUnit}
          errorMessage={touched.baseUnit ? errors.baseUnit : ''}
        />
        {formValues.unitStock ? (
          <FormMoneyInput
            value={formValues.pricePerUnit}
            label={<InputLabel label="Price Per Unit" tooltipId={ToolTips.PRICE_UNIT} required />}
            onChangeText={(value) => setFieldValue('pricePerUnit', value)}
            onBlur={handleBlur('pricePerUnit')}
            /** Bug: Formik should set all fields as touched during before submitting, but pricePerUnit is not set as touched during submitting, the following way to display error message could prevent falsy error message problem. */
            errorMessage={
              (touched.baseUnit && errors.pricePerUnit) || touched.pricePerUnit
                ? (errors.pricePerUnit as string)
                : undefined
            }
          />
        ) : (
          <FormInput
            value={formValues.globalQuantity?.toString()}
            placeholder="0"
            label={
              <InputLabel label="In Stock" tooltipId={ToolTips.IN_STOCK_GLOBAL} tooltipTitle="Global Stock" required />
            }
            onChangeText={(value: string) => setFieldValue('globalQuantity', value)}
            onBlur={handleBlur('globalQuantity')}
            errorMessage={touched.globalQuantity ? errors.globalQuantity : undefined}
          />
        )}
        {isStandard({ type }) &&
          (formValues.showCostPerUnit ? (
            <FormMoneyInput
              row
              value={formValues.costPerUnit}
              label={<InputLabel label="Cost Per Unit" tooltipId={ToolTips.COST_PER_UNIT} />}
              onChangeText={(value) => setFieldValue('costPerUnit', value)}
              errorMessage={errors.costPerUnit as string}
            />
          ) : (
            <ButtonClear
              style={styles.addCostPerUnitBtn}
              title="+ Add Cost Per Unit"
              onPress={() => setFieldValue('showCostPerUnit', true)}
            />
          ))}
      </FormDisplayRow>
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    formContainer: {
      paddingHorizontal: 25,
      paddingBottom: 40,
    },
    inputLabel: {
      marginTop: 2.2,
      marginBottom: 6,
    },
    picker: {
      minWidth: 299,
      height: 45,
    },
    inputMaxWidth: {
      maxWidth: 300,
    },
    paddingLeft10: {
      paddingLeft: 10,
    },
    addCostPerUnitBtn: {
      alignSelf: 'center',
      // We add a margin here because the other inputs have labels, so this positions the button about center
      marginTop: 20,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      picker: {
        marginBottom: 35,
        padding: 5,
      },
      formContainer: {
        paddingHorizontal: 0,
      },
    },
  },
)
