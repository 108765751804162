import { useCreateSearchParams } from '@components'
import { getRegionForCoordinates, maxZoom, shortenCoordinate } from '@helpers/coordinate'
import { AlgoliaGeoDoc } from '@models/Algolia'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import { useCallback, useMemo, useState } from 'react'
import { connectGeoSearch } from 'react-instantsearch-native'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import env from '../../config/Environment'
import { useKeyboard } from '../../hooks/useKeyboard'
import { HomeParamList } from '../../navigation/types'
import { setRegionAction, setSearchLocation } from '../../redux/actions/appState'
import { searchLocationSelector, userLocationSelector } from '../../redux/selectors'
import UserLocationMarker from './CurrentLocation'
import { GenerateMarkers } from './Markers/Markers'
import PopUp from './PopUp'
import { LoadingState } from './components/HelperComponents'
import { MapBottomOverlay } from './components/MapBottomOverlay'
import MapControls from './components/MapControls'
import { getDefaultCoords } from './helpers'

import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import { Coordinate } from '@models/Coordinate'

type Props = {
  hits: AlgoliaGeoDoc[]
}

function ConnectedGeoSearch({ hits }: Props) {
  const dispatch = useDispatch()
  const searchLoc = useSelector(searchLocationSelector)
  const { params } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'>>()
  const userLoc = useSelector(userLocationSelector)
  const [hasRendered, setHasRendered] = useState(false)
  const [selectedMarker, setSelectedMarker] = useState<{
    x: number | undefined
    y: number | undefined
    docs: AlgoliaGeoDoc[]
  } | null>(null)
  const [showRedoSearch, setShowRedoSearch] = useState(false)
  const isKeyboardVisible = useKeyboard()
  const coords = useMemo(
    () => getDefaultCoords({ userLoc, searchLoc, near: params?.near }),
    [params?.near, searchLoc, userLoc],
  )
  // This stores the current map bounds and updates on move
  const [region, setRegion] = useState<Partial<ChangeEventValue>>({
    center: shortenCoordinate(coords),
    zoom: 10,
  })

  /**defaultCenter is never meant to change, because it's only for initialization purposes. Prevents a warning in the map saying this prop isn't supposed to change */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultCenter = useMemo(() => shortenCoordinate(coords), [])

  /** Tracks whether the map had its initial render */
  useFocusFx(() => {
    if (hits.length && !hasRendered) setHasRendered(true)
  }, [hits, hasRendered])

  /** On hits change, will set a new map center and zoom based on the results */
  useDeepCompareFocusFx(() => {
    if (!hits?.length) return

    const newRegion = getRegionForCoordinates(hits.map((v) => v._geoloc))
    setRegion((prev) => ({ ...prev, center: shortenCoordinate(newRegion) }))
  }, [hits])

  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()

  const createSearchParams = useCreateSearchParams()

  const redoSearch = useCallback(() => {
    if (region.center) {
      const coordinate: Coordinate = { longitude: region.center.lng, latitude: region.center.lat }
      dispatch(setSearchLocation({ coordinate, city: '' }))
      navigation.setParams(createSearchParams({ coords: coordinate }))
      setShowRedoSearch(false)
    }
  }, [region, createSearchParams, dispatch, navigation])

  return (
    <View style={styles.container}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: env.API_KEY!, libraries: ['places'] }}
        defaultCenter={defaultCenter}
        center={region.center}
        defaultZoom={10}
        options={{
          minZoom: 5,
          maxZoom,
          fullscreenControl: false,
          zoomControlOptions: { position: 8 },
        }}
        onChange={(region) => {
          setRegion(region)
          dispatch(
            setRegionAction({
              latitude: region.center.lat,
              longitude: region.center.lng,
              longitudeDelta: region.marginBounds.ne.lng - region.marginBounds.nw.lng,
              latitudeDelta: region.marginBounds.nw.lat - region.marginBounds.sw.lat,
            }),
          )
          setSelectedMarker(null)
        }}
        onDrag={() => {
          setShowRedoSearch(true)
          setSelectedMarker(null)
        }}
      >
        {userLoc && <UserLocationMarker lat={userLoc.coordinate.latitude} lng={userLoc.coordinate.longitude} />}
        {GenerateMarkers({ hits, onMarkerPress: setSelectedMarker })}
      </GoogleMapReact>
      <LoadingState />
      {!isKeyboardVisible && (
        <>
          <PopUp data={selectedMarker} />
          <MapControls showRedo={showRedoSearch} redoSearch={redoSearch} />
          <MapBottomOverlay hasSelectedMarker={!!selectedMarker} />
        </>
      )}
    </View>
  )
}

export default connectGeoSearch(ConnectedGeoSearch)

const styles = StyleSheet.create({
  container: { flex: 1, position: 'relative' },
})
