import { Logger } from '@/config/logger'
import { initialFiltersMapSearch } from '@models/Algolia'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { memo, useCallback } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { LogoResponsive } from '../../admin/components/LogoResponsive'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { useDeviceSize } from '../../hooks/useLayout'
import { setSearchFilter, setSearchLocation } from '../../redux/actions/appState'
import { isAdminSelector, searchLocationSelector, userLocationSelector, userSelector } from '../../redux/selectors'
import ConsumerSearch from '../ConsumerSearch'
import HamburgerMenu from '../HamburgerMenu'
import ShoppingCart from '../ShoppingCart'
import UserDropdown from '../UserDropdown'
import { Button } from '../elements/Button'
import { ButtonClear } from '../elements/ButtonClear'
import { makeHeaderTestId, useCreateSearchParams } from './Header-helper'

import { useCartService } from '@/hooks/useCart'
import { useComponentRoute } from '@/hooks/useComponentRoute'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { spreadStyle } from '@/hooks/useMergeStyle'
import { MAX_SHOP_WIDTH } from '@screens/Shopping/FarmShop/helpers'

type HeaderProps = {
  /** Whether the safe area margin should be disabled. This should be used when the safe area margin is being handled through a container, for example when rendering the header from inside the screen component as opposed to from the navigator. */
  noSafeareaMargin?: boolean
}

/**
 * The main app header, intended for large devices.
 * - Since it is sometimes rendering at the navigator level, and sometimes directly inside the screen component, it's better for it to not rely on the StackHeaderProps, and instead always useNavigation.
 */
export const Header = memo(function Header({ noSafeareaMargin = false }: HeaderProps) {
  //StackNavigationProp<T> is "any" because this could be used in many different screens with different possible routes
  const navigation = useNavigation<StackNavigationProp<any>>()
  const route = useRoute()
  const key = route.key //The key is the only way cypress can select the Header of a particular screen, because in a stack navigator, there will be a Header for each screen, in the DOM.
  const dispatch = useDispatch()
  const { isExtraLargeDevice, isLargeDevice, isSmallDevice } = useDeviceSize()

  const user = useSelector(userSelector)
  const isAdmin = useSelector(isAdminSelector)
  const { cart } = useCartService()

  /** This screen name variable should not be obtained from the global "routeName" redux state, because the goal is to identify the route of the current instance of the Header component, meanwhile the navRoute refers to the global currently focused route. Those are two different things. */
  const screenName = useComponentRoute()?.name

  const isExplore = screenName === 'ExploreScreen'
  const isShop = screenName === 'FarmShop'
  const isHomeScreen = screenName === 'HomeScreen'

  const searchLoc = useSelector(searchLocationSelector)
  const userLoc = useSelector(userLocationSelector)

  const styles = useStyles(isShop, noSafeareaMargin)

  const hasCartItems = cart.length > 0

  const goAdmin = useCallback(() => {
    if (user.id && isAdmin === undefined) {
      Logger.debug('Still loading farmer status')
    } else if (isAdmin) {
      navigation.navigate('AdminDrawerNavigator')
    } else {
      if (navigation.getState().routeNames.includes('FarmerOnboardScreen')) {
        navigation.navigate('FarmerOnboardScreen')
      } else {
        navigation.navigate('Consumer', {
          screen: 'Home',
          params: { screen: 'FarmerOnboardScreen' },
        })
      }
    }
  }, [user.id, isAdmin, navigation])

  const createSearchParams = useCreateSearchParams()

  const goToMapSearch = useCallback(() => {
    dispatch(setSearchFilter(initialFiltersMapSearch)) //resets filters when pressing this button
    const loc = searchLoc ?? userLoc
    if (loc) dispatch(setSearchLocation({ ...loc }))

    //Only navigate directly if destination is available to current navigator
    if (navigation.getState().routeNames.includes('ExploreScreen')) {
      navigation.navigate('ExploreScreen', createSearchParams({ coords: loc?.coordinate }))
    } else {
      //Else specify nested navigator path
      navigation.navigate('Consumer', {
        screen: 'Home',
        params: {
          screen: 'ExploreScreen',
          params: createSearchParams({ coords: loc?.coordinate }),
        },
      })
    }
  }, [createSearchParams, userLoc, dispatch, navigation, searchLoc])

  const logoPress = useCallback(
    () =>
      navigation.navigate('Consumer', {
        screen: 'Home',
        params: { screen: 'HomeScreen' },
      }),
    [navigation],
  )

  const isCheckout = (() => {
    const state = navigation.getState()
    const ix = state.index
    return state.routes[ix]?.name === 'Checkout'
  })()

  return (
    <View style={styles.main} testID={makeHeaderTestId(key)}>
      <View style={styles.header}>
        <View style={styles.sideContainer}>
          <HamburgerMenu clear />
          <LogoResponsive white style={globalStyles.padding10} onPress={logoPress} />
          {!isExplore && isExtraLargeDevice && (
            <ButtonClear
              style={styles.exploreBtn}
              color={Colors.white}
              title="Explore"
              onPress={goToMapSearch}
              icon="map"
              disabled={!userLoc}
            />
          )}
        </View>

        <View style={styles.middleContainer}>{!isHomeScreen && !isShop && !isSmallDevice && <ConsumerSearch />}</View>

        <View style={[styles.rightContent, styles.sideContainer]}>
          {isLargeDevice && !hasCartItems && (
            <ButtonClear
              style={styles.adminBtn}
              color={Colors.white}
              title={isAdmin ? 'Farmer Dashboard' : 'Start selling on GrownBy'}
              onPress={goAdmin}
              testID="header_farmer_text"
            />
          )}
          <UserDropdown />
          {isCheckout ? null : hasCartItems && isLargeDevice ? (
            <Button
              style={styles.checkoutBtn}
              outline
              title={`Checkout (${cart.length})`}
              icon="shopping-cart"
              url="/farms/shop/my-cart"
              testID="header-cart-large"
              disabled={isCheckout}
            />
          ) : (
            <ShoppingCart color={Colors.white} testID="header-cart-small" />
          )}
        </View>
      </View>
    </View>
  )
})

const useStyles = (isShop: boolean, noSafeareaMargin: boolean) =>
  useLayoutFnStyles(
    ({ isExtraSmallDevice, isMedDevice, isSmallDevice, top }, isShop, noSafeareaMargin) => ({
      main: spreadStyle([
        {
          backgroundColor: Colors.darkGreen,
          alignItems: 'center',
        },
        !noSafeareaMargin && { marginTop: top },
      ]),
      header: {
        flexDirection: 'row',
        paddingVertical: isSmallDevice || isMedDevice ? 0 : 10,
        paddingHorizontal: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        elevation: 10,
        maxWidth: MAX_SHOP_WIDTH,
        width: '100%',
      },
      avatar: {
        width: isSmallDevice || isMedDevice ? 50 : 60,
        height: isSmallDevice || isMedDevice ? 50 : 60,
        borderWidth: 5,
        borderRadius: 40,
        borderColor: Colors.transparent,
      },
      onHover: {
        borderColor: Colors.green,
      },
      link: {
        color: Colors.black,
        padding: 10,
        backgroundColor: Colors.transparent,
      },
      activeLink: {
        color: Colors.white,
        padding: 10,
        backgroundColor: Colors.green,
        overflow: 'visible',
      },
      checkoutBtn: { backgroundColor: Colors.white, justifyContent: 'center', flex: 1, flexGrow: 1, maxWidth: 200 },
      middleContainer: {
        flex: 0.8,
        justifyContent: isSmallDevice ? 'flex-end' : 'center',
        marginRight: isExtraSmallDevice && isShop ? 25 : 0,
      },
      sideContainer: {
        flexGrow: isSmallDevice ? 0.3 : isMedDevice ? 0.4 : 0.5,
        flexBasis: 0,
        flexDirection: 'row',
        alignItems: 'center',
      },
      rightContent: {
        justifyContent: 'flex-end',
        marginRight: 10,
      },
      exploreBtn: {
        justifyContent: 'center',
        flex: 1,
        flexGrow: 1,
      },
      adminBtn: { justifyContent: 'center', flex: 1, flexGrow: 1 },
    }),
    isShop,
    noSafeareaMargin,
  )
