import {
  ProductCardSquare,
  ResponsiveGrid,
  paddedProdWidth,
  paddedProdWidthSM,
  prodHeight,
  prodSharedStyles,
} from '@components'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ListRenderItemInfo } from '@shopify/flash-list'
import React, { useCallback, useRef, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { ShoppingStackParamList } from '../../navigation/types'
import { addNavProp } from '../../redux/actions/appState'
import { paramsSelector } from '../../redux/selectors'
import { ListFooter, ShopListHeader } from './FarmShop/components'

import { AlgoliaFarmDataContext, useAlgoliaFarmData } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData'
import { FarmDataContext, useFarmData } from '@/hooks/useFarmData'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useLayout } from '@/hooks/useLayout'
import { withConsumerIndexHooks } from '@/hooks/withAlgoliaIndex'
import { KeyboardAvoidingScrollView, KeyboardAvoidingScrollViewRef, ScreenView } from '@elements'

import { ArrElement } from '@helpers/typescript'
import { matchesIdOrSlug } from '@helpers/urlSafeSlug'
import { AboutCSA } from './CSADetails/AboutCSA'
import { ListEmptyCSA } from './CSADetails/ListEmptyCSA'
import { FarmShopHeader } from './FarmShop/FarmShopHeader'
import { ShopSideBar } from './FarmShop/ShopSideBar'
import { MAX_SHOP_WIDTH, shouldShowSidebar } from './FarmShop/helpers'

interface Props {
  route: RouteProp<ShoppingStackParamList, 'CSADetails'>
  navigation: StackNavigationProp<ShoppingStackParamList, 'CSADetails'>
}

function CSADetailsComp({ route, navigation }: Props) {
  const { csaId, farmSlug, locationId, categoryId, goBack, type, searchTerm, ebtOnly } = route.params
  const { csa } = useSelector(paramsSelector)

  const [csaState, setCsaState] = useState({ loading: true, invalid: false })

  const farmData = useFarmData(farmSlug, { fetchCsas: true, fetchProds: false, fetchLocs: true })
  const {
    farm: { data: farm },
    csas: { data: farmCsas, loading: loadingCsas, err: errorCsas },
  } = farmData

  const algoliaData = useAlgoliaFarmData({
    farmSlug,
    csaId,
    locationId,
    categoryId,
    type,
    ebtOnly,
    searchTerm,
  })
  const { products, errorMsg, isLoadingProducts, activeFiltersNo } = algoliaData

  const scrollRef = useRef() as KeyboardAvoidingScrollViewRef

  const layout = useLayout()
  const dispatch = useDispatch()

  const styles = useStyles()

  /** Get the csa from farmCsas nav prop if the csa nav prop is not set, and set it to redux */
  useFocusFx(() => {
    if (loadingCsas) {
      setCsaState({ loading: true, invalid: false })
      return
    }

    if (!csaId) {
      setCsaState({ loading: false, invalid: true })
      return
    }

    if (csaId === csa?.id) {
      return setCsaState({ loading: false, invalid: false })
    }
    if (errorCsas || !farmCsas) {
      setCsaState({ loading: false, invalid: false })
      return
    }

    /** We try to find the csa in the farmCsas state from useFarmData*/
    const csaProp = farmCsas.find(({ id }) => id === csaId)
    if (csaProp) {
      dispatch(addNavProp({ csa: csaProp }))
      setCsaState({ loading: true, invalid: false })
    } else {
      /** If the csa prop is not found, it might be because the csa is hidden, in which case, it won't exist in useFarmData.
       *  If it wasn't found, and loadingCsas is no longer loading (already checked above), and there was no error, it should be considered invalid */
      setCsaState({ loading: false, invalid: true })
    }
  }, [dispatch, farmCsas, csaId, loadingCsas, errorCsas, csa?.id])

  const renderItem = ({ item: prod }: ListRenderItemInfo<ArrElement<typeof products>>) => {
    return (
      <ProductCardSquare
        small={layout.isExtraSmallDevice}
        product={prod}
        style={prodSharedStyles.responsiveWrapper}
        cardAction="addcart"
        onPressMobileNavigate={(p) => {
          navigation.navigate('ProductDetails', {
            farmSlug,
            productId: p.id,
            csaId: csa?.id,
          })
        }}
        csa={csa}
      />
    )
  }

  /** On Learn More button press, the screen should scroll to the bottom of the page, where the `AboutCSA` component is */
  const onLearnMorePress = useCallback(() => {
    scrollRef.current?.scrollToEnd()
  }, [])

  return (
    <FarmDataContext.Provider value={farmData}>
      <AlgoliaFarmDataContext.Provider value={algoliaData}>
        {/* ScreenView is necessary here to make the main app Header stick to the top, while being outside of the screen component */}
        <ScreenView>
          <KeyboardAvoidingScrollView ref={scrollRef}>
            <FarmShopHeader
              farm={matchesIdOrSlug(farm, farmSlug) ? farm : undefined}
              onBackPress={() => {
                if (goBack === 'home') {
                  return navigation.navigate('Home', { screen: 'HomeScreen' })
                }
                navigation.navigate('FarmShop', { farmSlug })
              }}
            />
            <View style={styles.main}>
              {shouldShowSidebar(layout.isLargeDevice) && <ShopSideBar />}
              <View style={styles.prodListContainer}>
                <ResponsiveGrid
                  estimatedItemSize={prodHeight}
                  itemBaseWidth={layout.isExtraSmallDevice ? paddedProdWidthSM : paddedProdWidth}
                  showsVerticalScrollIndicator={false}
                  renderItem={renderItem}
                  ListHeaderComponent={
                    <ShopListHeader
                      title={csa?.name ?? ''}
                      onLearnMorePress={csaState.invalid ? undefined : onLearnMorePress}
                    />
                  }
                  /** Return empty list when hits are loading or if there is any error, in order to show the `ListEmpty` component in the UI
                   * - This will help when filtering is applied for example, and the connection is slow. So the user will see that something is loading
                   */
                  data={isLoadingProducts || errorMsg ? [] : products}
                  ListFooterComponent={<AboutCSA loading={csaState.loading} csa={csa} />}
                  ListEmptyComponent={
                    <ListEmptyCSA
                      prodsLoading={isLoadingProducts}
                      hasError={!!errorMsg}
                      invalidCsa={csaState.invalid}
                      hasFiltering={!!activeFiltersNo}
                    />
                  }
                />
              </View>
            </View>
            <ListFooter loading={csaState.loading} />
          </KeyboardAvoidingScrollView>
        </ScreenView>
      </AlgoliaFarmDataContext.Provider>
    </FarmDataContext.Provider>
  )
}

export const CSADetails = withConsumerIndexHooks(CSADetailsComp)

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isSmallDevice }) => ({
    main: {
      maxWidth: MAX_SHOP_WIDTH,
      alignSelf: 'center',
      width: '100%',
      flex: 1,
      /** Direction must be `row` if Sidebar is visible */
      flexDirection: shouldShowSidebar(isLargeDevice) ? 'row' : 'column',
    },
    prodListContainer: {
      padding: isSmallDevice ? 10 : 15,
      flex: 1,
    },
  }))
