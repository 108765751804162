import { isMoney, Money, Zero } from '@models/Money'
import * as Yup from 'yup'
import { MoneyCalc } from './money'

export const YUP_MONEY_REQUIRED = (label: string, { allowZero = false } = {}): Yup.ObjectSchema<Money> =>
  Yup.object()
    .typeError(`${label} must be a valid amount`)
    .test('is-valid-money', `${label} must be a valid amount`, (money) => isMoney(money))
    .test('is-greater-than-zero', `${label} must be greater than $0`, (money) => {
      // There is no reason this will be false as we check it before, but this casts the type as money so we can be confident
      if (!isMoney(money)) return false
      return allowZero ? MoneyCalc.isGTE(money, Zero) : MoneyCalc.isGTZero(money)
    }) as Yup.ObjectSchema<Money>

export const YUP_MONEY_OPTIONAL = (label: string, { allowZero = false } = {}): Yup.ObjectSchema<Money | undefined> =>
  Yup.object()
    .typeError(`${label} must be a valid amount`)
    .optional()
    .test('is-valid-money', `${label} must be a valid amount`, (money) => money === undefined || isMoney(money))
    .test('is-greater-than-zero', `${label} must be greater than $0`, (money) => {
      // This field is optional, so if it is undefined we should pass the test
      if (money === undefined) return true
      // There is no reason this will be false as we check it before, but this casts the type as money so we can be confident
      if (!isMoney(money)) return false
      return allowZero ? MoneyCalc.isGTE(money, Zero) : MoneyCalc.isGTZero(money)
    }) as Yup.ObjectSchema<Money | undefined>

export const YUP_WHOLE_NUMBER_REAL = (label: string, { allowDecimal = false, allowZero = false } = {}) =>
  Yup.number()
    .label(label)
    .required(`${label} is required`)
    .test(
      'Is whole?',
      `You can only specify whole numbers for ${label}`,
      (val) => typeof val === 'number' && (allowDecimal ? true : Math.round(val) === val),
    )
    .test(
      'Is greater than zero?',
      `${label} must be greater than zero`,
      (val) => typeof val === 'number' && (allowZero ? val >= 0 : val > 0),
    )
    .typeError(`${label} must be a number`)

export const YUP_WHOLE_NUMBER_OPTIONAL_REAL = (label: string, { allowDecimal = false, allowZero = false } = {}) =>
  Yup.number()
    .label(label)
    .test(
      'Is whole?',
      `You can only specify whole numbers for ${label}`,
      (val) => val === undefined || (typeof val === 'number' && (allowDecimal ? true : Math.round(val) === val)),
    )
    .test(
      'Is greater than zero?',
      `${label} must be greater than zero`,
      (val) => val === undefined || (typeof val === 'number' && (allowZero ? val >= 0 : val > 0)),
    )
    .typeError(`${label} must be a number`)
