import { ShortState } from '@/assets/data/states'
import { PartialExcept } from '@helpers/typescript'
import { ShortZip } from './Address'
import { Farm } from './Farm'
import { RegionType } from './Location'
import { Money, isMoney } from './Money'

/** Fee type for Product fee */
export enum FeeType {
  Tax = 'tax',
  /** flat fee */
  Fee = 'fee',
}

/** Value type for Product fee */
export enum FeeValueType {
  Fixed = 'fixed',
  Percent = 'percent',
}

/** none general means no value for it. It is used in ProductFee.regionType. If regionType is not State or Zipcode. It should be none. */
export const NoneValue = 'none'

/** Identifies product fees like taxes or flat fees for farms and should be applied for products */
export type ProductFee<Type = FeeType, Region = RegionType> = {
  id: string

  /** Name of the tax or fee */
  name: string

  farm: Pick<Farm, 'id'>

  /** The type of fee, which can be a tax or flat fee */
  type: Type

  /** It should be fixed or percent type. For now all taxes are percentage and all fees are fixed */
  valueType: Type extends FeeType.Tax ? FeeValueType.Percent : Type extends FeeType.Fee ? FeeValueType.Fixed : never

  /** Will be the value of the Fee, either money amount or percent amount. Percent represented as decimal, ex. 20% = 0.2. For now, all taxes values are number and all fees are Money */
  value: Type extends FeeType.Tax ? number : Type extends FeeType.Fee ? Money : never

  /** If the tax or fee is archived then it will be hidden */
  archived: boolean

  /** The type of region we will apply the fee to */
  regionType: Region | typeof NoneValue

  /** A list of states or zipcodes that the tax or fee will apply to */
  regions: Region extends RegionType.Zipcode ? ShortZip[] : Region extends RegionType.State ? ShortState[] : []
}

/** Whether the product fee is percent and its value is number, without being restricted on the fee type */
export function isPercentProductFee(
  productFee: PartialExcept<ProductFee, 'valueType' | 'value'>,
): productFee is Omit<ProductFee, 'valueType' | 'value'> & { valueType: FeeValueType.Percent; value: number } {
  return productFee.valueType === FeeValueType.Percent && typeof productFee.value === 'number'
}

/** Whether the product fee is fixed and its value is Money, without being restricted on the fee type */
export function isFixedProductFee(
  productFee: PartialExcept<ProductFee, 'valueType' | 'value'>,
): productFee is Omit<ProductFee, 'valueType' | 'value'> & { valueType: FeeValueType.Fixed; value: Money } {
  return productFee.valueType === FeeValueType.Fixed && isMoney(productFee.value)
}

/** Verify ProductFee is Fee */
export function isFeeProductFee(productFee: ProductFee): productFee is ProductFee<FeeType.Fee> {
  return productFee.type === FeeType.Fee
}

/** Verify ProductFee is Tax */
export function isTaxProductFee(productFee: ProductFee): productFee is ProductFee<FeeType.Tax> {
  return productFee.type === FeeType.Tax
}
