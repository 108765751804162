import { PartialPick } from '@helpers/typescript'
import { Product, ProductType, isShare } from '@models/Product'
import * as Yup from 'yup'
import { ProductFormikComponent } from './ProductFormikComponent'

/** information that is not controlled by Formik, but we want type to be available for every component, so it is injected here */
type FormikTypeInformationType = {
  type: ProductType
  hasTemplate: boolean
}

const productTypeFormik = Yup.object<FormikTypeInformationType>().shape({
  type: Yup.mixed<ProductType>().oneOf(Object.values(ProductType)).required(),
  hasTemplate: Yup.boolean().required(),
})

const hasTemplate = (product: PartialPick<Product, 'type'>): boolean => {
  if (isShare(product) && product.templateProduct?.id) return true

  return false
}

const toFormik = (product: PartialPick<Product, 'type'>): FormikTypeInformationType => {
  return {
    type: product.type,
    hasTemplate: hasTemplate(product),
  }
}

function fromFormik(values: { type: ProductType }): Partial<Product> {
  return {
    type: values.type,
  }
}
export const FormikTypeInformation = new ProductFormikComponent(productTypeFormik, toFormik, fromFormik)
