import { useCallback } from 'react'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'

import { isCoord } from '../models/Coordinate'
import {
  debouncedTimeGooglePlaces,
  Establishment,
  GooglePlacesSearchContext,
  GooglePlacesSearchProps,
} from './googlePlacesSearch.helper'
import { GooglePlacesSearchUi } from './GooglePlacesSearchUi'

import { AutoCompleteItem } from '@/hooks/useAutoComplete'

export * from './googlePlacesSearch.helper'

export function GooglePlacesSearch({
  onSelect,
  types = '(cities)',
  placeholderCurrLoc,
  noInitialValue,
  inline,
  hasClearBtn,
  contStyle,
  //Any props not part of the textInput props (Any custom props) must be destructured here and passed to context
  ...textInputProps
}: GooglePlacesSearchProps<google.maps.GeocoderResult>) {
  const {
    value: inputValue,
    suggestions: { data: searchResults, loading: isSearching },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: [types],
      componentRestrictions: { country: 'us' },
    },
    debounce: debouncedTimeGooglePlaces,
  })

  const getResultDetails = useCallback(async (item: AutoCompleteItem<google.maps.places.AutocompletePrediction>) => {
    //If this is the "Current location" item, it will be a coordinate
    const geoCode = await getGeocode(
      isCoord(item.data)
        ? { location: { lat: item.data.latitude, lng: item.data.longitude } }
        : { address: item.data.description },
    )
    const detailItem: AutoCompleteItem<google.maps.GeocoderResult> = {
      text: item.text,
      data: geoCode[0],
    }
    return detailItem
  }, [])

  return (
    <GooglePlacesSearchContext.Provider
      value={{
        textInputProps,
        customProps: {
          onSelect,
          inline,
          placeholderCurrLoc,
          noInitialValue,
          types,
          hasClearBtn,
          contStyle,
        },
      }}
    >
      <GooglePlacesSearchUi
        getResultDetails={getResultDetails}
        inputValue={inputValue}
        setValue={setValue}
        isSearching={isSearching}
        searchResults={searchResults}
      />
    </GooglePlacesSearchContext.Provider>
  )
}
//Components that use this component can make an establishment from the item passed to onSelect prop
export const makeEstablishment = (item: AutoCompleteItem<google.maps.GeocoderResult>): Establishment => {
  const data: Establishment = {
    coordinate: {
      latitude: item.data.geometry.location.lat(),
      longitude: item.data.geometry.location.lng(),
    },
    address_components: item.data.address_components,
    name: item.text,
  }
  return data
}
