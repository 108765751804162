import { loadCSAsByFarm } from '@api/CSAs'
import { loadLocationsByFarm } from '@api/Locations'
import { loadDailySummary, loadDistributionDetails, loadLocationSummary, loadPackListSummary } from '@api/Summaries'
import { ToolTips } from '@components'
import { Picker, Tooltip } from '@elements'
import { capitalize, formatFormalShortDate, formatMoney } from '@helpers/display'
import {
  openDailySummaryCSV,
  openLocationSummaryCSV,
  openPackListCSV,
  openPackListPDF,
  openSignInCSV,
  openSignInPDF,
} from '@helpers/links'
import { displayTimeStamp } from '@helpers/time'
import { CSA } from '@models/CSA'
import { Farm } from '@models/Farm'
import { Location } from '@models/Location'
import { DateRange, ProductFilterType, SummaryFilter } from '@models/Schedule'
import {
  DailySummary,
  DistributionDetailsSummary,
  LocationSummary,
  PackListSummary,
  Summary,
  summaryEmails,
} from '@models/Summary'
import { dateTimeInZone } from '@models/Timezone'
import { StackScreenProps } from '@react-navigation/stack'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { SpinnerWithTitle as Spinner } from '../../../components/SpinnerWithTitle'
import { Button } from '../../../components/elements/Button'
import { DateTimePicker } from '../../../components/elements/DateTimePicker/DateTimePicker'
import { Icon } from '../../../components/elements/Icon/Icon'
import { Alert, Modal } from '../../../components/elements/Overlays/Popups'
import { Text, TextH1 } from '../../../components/elements/Text'
import { Logger } from '../../../config/logger'
import { globalStyles } from '../../../constants/Styles'
import { useLoadingState } from '../../../hooks/useLoadingState'
import { useSerialState } from '../../../hooks/useSerialState'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { RootState } from '../../../redux/reducers/types'
import { adminFarmSelector } from '../../../redux/selectors'
import { AdminView } from '../../components/AdminView'
import { Grid } from '../../components/Dashboard/Grid'
import { Tally } from '../../components/Dashboard/Tally'
import { ButtonGroupNav } from '../../navigation/ButtonGroupNav'
import { DistributionSummaryParamList } from '../../navigation/types'
import CopyEmails from './CopyEmails'
import { DailySummarySheet } from './DailySummarySheet'
import LocationSummarySheet from './LocationSummarySheet'
import PackingSheets from './PackingSheets'
import { SignInSheets } from './SignInSheets'
import { styles } from './styles'

import Colors from '@/constants/Colors'
import useDeepCompareEffect from '@/hooks/useDeepEqualEffect'
import { useDeviceSize } from '@/hooks/useLayout'
import { Permission } from '@helpers/Permission'
import { isNonNullish } from '@helpers/helpers'
import { omit } from '@helpers/typescript'
import { PickupItemStatus, isPickupItemCancelled } from '@models/Order'
import { dequal } from 'dequal'

type Props = StackScreenProps<DistributionSummaryParamList, 'DistributionSummary'>

const defaultStatusFilter = 'All Statuses'
const defaultCSAFilter = 'All CSAs'
const defaultLocationFilter = 'All Locations & Zones'

/** static pickupItem status data */
const pickupItemStatusDropDown = Object.values(PickupItemStatus)
  .filter((itm) => !isPickupItemCancelled({ status: itm }))
  .map((itm) => ({ label: capitalize(itm), value: itm }))

/** This will be used in ButtonGroupNav component */
const topNavigatorIndices = {
  daily: 0,
  location: 1,
  signIn: 2,
  packList: 3,
}

function DistributionSummaryScreen({ route, navigation }: Props) {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const [updateTime, setUpdateTime] = useState(dateTimeInZone(farm.timezone))

  const [isLoading, startLoading, stopLoading] = useLoadingState()
  const [report, setReport, getReportSerial] = useSerialState<
    (
      | { type: 'daily'; summary?: DailySummary }
      | { type: 'location'; summary?: LocationSummary }
      | { type: 'signIn'; summary?: DistributionDetailsSummary }
      | { type: 'packList'; summary?: PackListSummary }
    ) & { error?: unknown }
  >({ type: route.params?.tab ?? 'daily' })

  const [productCSAFilter, setProductCSAFilter] = useState<ProductFilterType>(ProductFilterType.Both)
  const [csaFilter, setCSAFilter] = useState<string>(defaultCSAFilter)
  const [locationFilter, setLocationFilter] = useState<string | undefined>(route.params?.locationId)
  const [statusFilter, setStatusFilter] = useState<PickupItemStatus | undefined>(route.params?.pickupItemStatus)

  const [csaDropDown, setCSADropDown] = useState<any[]>([])
  const [locationDropDown, setLocationDropDown] = useState<any[]>([])
  const [csvLoading, setCSVLoading] = useState(false)

  const now = dateTimeInZone(farm.timezone)
  const [dateRange, setDateRangeState] = useState(
    route.params?.fromDate && route.params?.endDate
      ? {
          startDate: DateTime.fromISO(route.params.fromDate, { zone: farm.timezone }).startOf('day'),
          endDate: DateTime.fromISO(route.params.endDate, { zone: farm.timezone }).endOf('day'),
        }
      : {
          startDate: now.startOf('day'),
          endDate: now.plus({ days: 6 }).endOf('day'),
        },
  )
  const dateRangeRef = useRef(dateRange)

  /** This will be used to set the dateRangeRef.current */
  const setDateRange = useCallback((range: Partial<DateRange>) => {
    const newRange = { ...dateRangeRef.current, ...range }
    setDateRangeState(newRange)
    dateRangeRef.current = newRange
  }, [])

  /** This helper will handle the change of location filter */
  const onLocFilterChange = (value: string) => {
    if (value === defaultLocationFilter) {
      setLocationFilter(undefined)
    } else {
      setLocationFilter(value)
    }
  }

  /** This helper will handle the change of pickupItemStatus filter */
  const onStatusFilterChange = (value: string) => {
    if (value === defaultStatusFilter) {
      setStatusFilter(undefined)
    } else {
      setStatusFilter(value as PickupItemStatus)
    }
  }

  /** 4 report navigator buttons */
  const topNavigator = [
    {
      label: 'Daily Summary',
      onPress: () => {
        loadSummary('daily')
        /* When changing the report type, we need to set the tab so the condition can be cached when navigating back to this screen. However, we have to set the pickupItemStatus to undefined to reset status after clicking different report tabs */
        navigation.setParams({ tab: 'daily', pickupItemStatus: undefined })
      },
    },
    {
      label: 'Location Summary',
      onPress: () => {
        loadSummary('location')
        navigation.setParams({ tab: 'location', pickupItemStatus: undefined })
      },
    },
    {
      label: 'Sign-in',
      onPress: () => {
        loadSummary('signIn')
        navigation.setParams({ tab: 'signIn', pickupItemStatus: undefined })
      },
    },
    {
      label: 'Packing Sheets',
      onPress: () => {
        loadSummary('packList')
        navigation.setParams({ tab: 'packList', pickupItemStatus: undefined })
      },
    },
  ]

  /* Display that an error occurred when the report error value is present. */
  useEffect(() => {
    if (!report.error) {
      return
    }
    Logger.error(report.error)
    Alert('An error occurred while fetching your results. Please try again later.')
  }, [report.error])

  /* This useEffect will run only first time when the screen is loaded and also when the farm id is changed */
  useEffect(() => {
    async function loadReportData() {
      try {
        startLoading()

        const [csa, locations] = await Promise.all([loadCSAsByFarm(farm.id), loadLocationsByFarm(farm.id)])

        setCSADropDown(
          csa
            .filter((csa) => !csa.isHidden)
            .map((val: CSA) => ({ label: val.name, value: val.id }))
            .sort((a, b) => (a.label > b.label ? 1 : -1)),
        )
        setLocationDropDown(
          locations
            .map((val: Location) => ({ label: val.name, value: val.id }))
            .sort((a, b) => (a.label > b.label ? 1 : -1)),
        )
      } finally {
        stopLoading()
      }
    }

    loadReportData()
    reloadSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm.id])

  /** This useEffect will run only if route.params exists or value of route.params get change */
  useDeepCompareEffect(() => {
    if (!route.params) {
      return
    }
    if (route.params.tab) {
      setLocationFilter(route.params.locationId)

      if (route.params.fromDate && route.params.endDate) {
        setDateRange({
          startDate: DateTime.fromISO(route.params.fromDate, { zone: farm.timezone }).startOf('day'),
          endDate: DateTime.fromISO(route.params.endDate, { zone: farm.timezone }).endOf('day'),
        })
      } else {
        setDateRange({
          startDate: now.startOf('day'),
          endDate: now.plus({ days: 6 }).endOf('day'),
        })
      }

      if (route.params.tab === 'signIn' && route.params.pickupItemStatus) {
        if (!route.params.pickupItemStatus) {
          setStatusFilter(undefined)
        } else {
          setStatusFilter(route.params.pickupItemStatus)
        }
      } else {
        setStatusFilter(undefined)
      }

      loadSummary(route.params.tab)
    }
    // Only listen the route params change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params, farm.id])

  /** filters will be used in BigQuery when sending request */
  const filters = useMemo(() => {
    const filters: SummaryFilter = {}
    filters.productType = productCSAFilter
    if (productCSAFilter === ProductFilterType.Share && csaFilter !== defaultCSAFilter) {
      filters.csaId = csaFilter
    }

    if (locationFilter) {
      filters.locationId = locationFilter
    }
    /* This is needed since we are using this filter value in the params and it will make sure filters are correct when locationId is undefined in the params */
    if (!isNonNullish(route.params?.locationId)) delete filters.locationId

    /* The status filter should only be applied to signIn report because we only show that filter in signIn tab. */
    if (!!statusFilter && report.type === 'signIn') {
      filters.pickupItemStatus = statusFilter
    }
    /* This is needed since we are using this filter value in the params and it will make sure filters are correct when pickupItemStatus is undefined in the params */
    if (!isNonNullish(route.params?.pickupItemStatus)) delete filters.pickupItemStatus

    return filters
  }, [
    csaFilter,
    locationFilter,
    productCSAFilter,
    report.type,
    statusFilter,
    route.params?.locationId,
    route.params?.pickupItemStatus,
  ])

  /** loadSummary function will be used in reloadSummary function and also be called directly when farmer directly click any report tab buttons */
  const loadSummary = useCallback(
    async function loadSummary(type: typeof report.type) {
      const serial = getReportSerial()
      setReport({ type })
      setUpdateTime(dateTimeInZone(farm.timezone))

      try {
        startLoading()

        const date = { startDate: dateRangeRef.current.startDate, endDate: dateRangeRef.current.endDate }

        if (type === 'daily') {
          const summary = await loadDailySummary(farm.id, date, filters)
          setReport({ type, summary }, serial)
        } else if (type === 'location') {
          const summary = await loadLocationSummary(farm.id, date, filters)
          setReport({ type, summary }, serial)
        } else if (type === 'signIn') {
          const summary = await loadDistributionDetails(farm.id, date, filters)
          setReport({ type, summary }, serial)
        } else if (type === 'packList') {
          const summary = await loadPackListSummary(farm.id, date, filters)
          setReport({ type, summary }, serial)
        }
      } catch (err) {
        setReport({ type, error: err }, serial)
      } finally {
        stopLoading()
      }
    },
    // Only listen the filters, dateRangeRef and farm.id change to rebuild the loadSummary function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, dateRangeRef, farm.id],
  )

  /** This will be applied when clicking 'Apply Filters' button and also run in the useEffect for first time loaded. */
  const reloadSummary = useCallback(() => {
    /* If the localProps is not equal to paramsProps, then we need to setParams and when params change, it will trigger reload the summary. OtherWise, we just need to load the summary with current all filters to fetch up-to-date data again. */
    const localProps = {
      tab: report.type,
      locationId: locationFilter,
      fromDate: formatFormalShortDate(dateRangeRef.current.startDate),
      endDate: formatFormalShortDate(dateRangeRef.current.endDate),
      pickupItemStatus: statusFilter,
    }

    const paramsProps = route.params

    if (dequal(localProps, paramsProps)) loadSummary(report.type)
    else navigation.setParams(localProps)
  }, [loadSummary, locationFilter, navigation, report.type, route.params, statusFilter])

  const handleGenerateEmails = useCallback(() => {
    if (report.type !== 'signIn' || !report.summary) {
      return
    }

    const emails = summaryEmails(report.summary)
    Modal(<CopyEmails list={emails} />, { title: 'Exported Emails from Sign-in' })
  }, [report.summary, report.type])

  /** Will return the component based on the report type and report summary */
  const AllSpreadSheets = useCallback(() => {
    if (report.type === 'daily' && report.summary) {
      return <DailySummarySheet summary={report.summary} />
    } else if (report.type === 'location' && report.summary) {
      return <LocationSummarySheet summary={report.summary} />
    } else if (report.type === 'signIn' && report.summary) {
      return <SignInSheets summary={report.summary} />
    } else if (report.type === 'packList' && report.summary) {
      return <PackingSheets summary={report.summary} />
    }
    return <View />
  }, [report.summary, report.type])

  /** To handle PDF export */
  const onExportToPdf = useCallback(() => {
    if (report.type === 'signIn') {
      //should not have pickupItemStatus as filter for signIn PDF because
      openSignInPDF(farm.id, dateRange, omit(filters, 'pickupItemStatus'))
    } else if (report.type === 'packList') {
      openPackListPDF(farm.id, dateRange, filters)
    }
  }, [dateRange, farm.id, filters, report.type])

  /** To handle CSA dropDown Picker value change */
  const onCsaChange = useCallback((value: string) => {
    setCSAFilter(value)
  }, [])

  /** To handle product type change on dropDown Picker */
  const onProdCsaChange = useCallback((value: string) => {
    setProductCSAFilter(value as ProductFilterType)
  }, [])

  function ComboBoxes() {
    const { isSmallDevice, isExtraSmallDevice } = useDeviceSize()
    return (
      <View style={globalStyles.padding10}>
        <View style={styles.filterCont}>
          <View style={styles.filter}>
            <Picker
              items={[
                { label: ProductFilterType.Share, value: ProductFilterType.Share },
                { label: ProductFilterType.StandardProduct, value: ProductFilterType.StandardProduct },
              ]}
              value={productCSAFilter}
              onValueChange={onProdCsaChange}
              placeholder={ProductFilterType.Both}
            />
          </View>

          {productCSAFilter === ProductFilterType.Share && (
            <View style={styles.filter}>
              <Picker
                items={csaDropDown}
                value={csaFilter}
                onValueChange={onCsaChange}
                placeholder={defaultCSAFilter}
              />
            </View>
          )}

          <View style={styles.filter}>
            <Picker
              items={locationDropDown}
              value={locationFilter}
              onValueChange={onLocFilterChange}
              placeholder={defaultLocationFilter}
            />
          </View>

          {report.type === 'signIn' && (
            <View style={styles.filter}>
              <Picker
                items={pickupItemStatusDropDown}
                value={statusFilter}
                onValueChange={onStatusFilterChange}
                placeholder={defaultStatusFilter}
              />
            </View>
          )}
        </View>

        <View style={styles.datesCont}>
          <Icon name="calendar" size={isExtraSmallDevice ? 16 : 20} color={Colors.shades[200]} />
          <View style={styles.dateWrapper}>
            <DateTimePicker
              containerStyle={isExtraSmallDevice ? styles.smallDateCont : undefined}
              mode="date"
              onChange={(date) => {
                setDateRange({ startDate: date })
              }}
              value={dateRange.startDate}
              timezone={farm.timezone}
            />
          </View>
          <View style={styles.margin5}>
            <Text size={15}>to</Text>
          </View>

          <Icon name="calendar" size={isExtraSmallDevice ? 16 : 20} color={Colors.shades[200]} />
          <View style={styles.dateWrapper}>
            <DateTimePicker
              containerStyle={isExtraSmallDevice ? styles.smallDateCont : undefined}
              mode="date"
              onChange={(date) => {
                setDateRange({ endDate: date })
              }}
              value={dateRange.endDate}
              timezone={farm.timezone}
            />
          </View>

          <Button small title={!isSmallDevice ? 'Apply Filters' : 'Apply'} onPress={reloadSummary} />
        </View>

        <View style={styles.filterCont}>
          {report.type === 'signIn' ? (
            <>
              <View style={styles.margin5}>
                <Button
                  small
                  title="Start Sign-in"
                  style={styles.exportBtn}
                  onPress={() => navigation.navigate('SignInSheetSelector')}
                />
              </View>
              <View style={styles.margin5}>
                <Button
                  small
                  outline
                  title="Generate Emails"
                  style={styles.exportBtn}
                  disabled={csvLoading}
                  onPress={handleGenerateEmails}
                />
              </View>
            </>
          ) : (
            <></>
          )}
          <View style={styles.margin5}>
            <Button
              small
              outline
              style={styles.exportBtn}
              title="Export to CSV"
              onPress={useCallback(() => {
                if (report.type === 'daily') {
                  openDailySummaryCSV(farm.id, dateRange, filters)
                } else if (report.type === 'location') {
                  openLocationSummaryCSV(farm!.id, dateRange, filters)
                } else if (report.type === 'signIn') {
                  openSignInCSV(farm.id, dateRange, filters)
                } else if (report.type === 'packList') {
                  openPackListCSV(farm.id, dateRange, filters)
                }
                setCSVLoading(true)
                setTimeout(() => setCSVLoading(false), 8000)
              }, [])}
              disabled={csvLoading}
            />
          </View>
          {report.type === 'signIn' || report.type === 'packList' ? (
            <View style={styles.margin5}>
              <Button small outline style={styles.exportBtn} title="Export to PDF" onPress={onExportToPdf} />
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    )
  }

  function TopDistroInfo() {
    return (
      <Grid>
        <Tally title="Orders" value={report.summary && (report.summary as Summary).numberOfOrders} />
        <Tally title="Gross Revenue" value={report.summary && formatMoney((report.summary as Summary).grossRevenue)} />
      </Grid>
    )
  }

  //get the correct tooltip for the report type
  const getReportTooltips = useCallback((reportType: string) => {
    switch (reportType) {
      case 'daily':
        return { tip: ToolTips.DAILY_SUMMARY, text: 'Daily Summary' }
      case 'location':
        return { tip: ToolTips.LOCATION_SUMMARY, text: 'Location Summary' }
      case 'signIn':
        return { tip: ToolTips.SIGN_IN_SHEETS, text: 'Sign-in' }
      case 'packList':
        return { tip: ToolTips.PACKING_SHEETS, text: 'Packing Sheets' }
      default:
        return { tip: ToolTips.DAILY_SUMMARY, text: 'Daily Summary' }
    }
  }, [])
  return (
    <AdminView>
      <View style={styles.headerStyle}>
        <View style={globalStyles.flexRowCenter}>
          <TextH1 style={styles.margin5}>{getReportTooltips(report.type).text}</TextH1>
          <Tooltip
            title={`How to use ${getReportTooltips(report.type).text}`}
            id={getReportTooltips(report.type).tip}
          />
        </View>
        <Text color={Colors.red}>Updated {displayTimeStamp(updateTime)}</Text>
      </View>
      <TopDistroInfo />
      <ButtonGroupNav
        active={topNavigatorIndices[report.type]}
        screens={topNavigator}
        containerStyle={styles.btnGroupCont}
        textStyle={styles.btnGroupText}
      />
      <ComboBoxes />

      {isLoading ? <Spinner title="Generating reports!" /> : <AllSpreadSheets />}
    </AdminView>
  )
}

export default withAdminAuth(DistributionSummaryScreen, Permission.Distributions)
