import { FEATURED_FARMS, getAlgoliaFarmsByIds } from '@api/Farms'
import { memo, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { FeaturedFarmsList } from './FeaturedFarmsList'
import { NearbyProdsGroup } from './NearbyProdsGroup'
import { NearbyProdsGroupSkeleton } from './NearbyProdsGroupSkeleton'

import { useCancelableFx } from '@/hooks/useCancelablePromise'
import { getRequiredNFarms, useGetNearbyProds } from '@/hooks/useGetNearbyProds'
import { searchLocationSelector, userLocationSelector } from '@/redux/selectors'
import { Spinner } from '@elements'
import { groupBy } from '@helpers/helpers'

import { useAvailAddons } from '@/hooks/useAvailAddons'
import { getSortAlgoliaProducts } from '@helpers/sorting'
import { AlgoliaGeoDoc, AlgoliaGeoFarm } from '@models/Algolia'

/** Searches for nearby products based on the search or user location, and progressively displays the results */
export const NearbyProducts = memo(function NearbyProducts() {
  const userLoc = useSelector(userLocationSelector)
  const searchLoc = useSelector(searchLocationSelector)
  const { availAddons } = useAvailAddons()

  /*** If a selected location is defined, it will be used for the product search. Otherwise it will use the user coordinate if available. */
  const { loading, data: nearbyProds } = useGetNearbyProds(searchLoc?.coordinate ?? userLoc?.coordinate)
  const [featured, setFeatured] = useState<AlgoliaGeoDoc<AlgoliaGeoFarm>[]>([])

  /** This will load the featured farms ONLY IF: the search is done and returned no results. This is a form of lazy loading, which helps save time and resources by not loading in advance anything we might not need. */
  useCancelableFx(async () => {
    if (!loading && nearbyProds?.length === 0) {
      const farms = await getAlgoliaFarmsByIds(FEATURED_FARMS)
      setFeatured(farms)
    }
  }, [loading, nearbyProds?.length])

  /** Products grouped by farm */
  const farmgroups = useMemo(() => {
    const groups = groupBy(nearbyProds, (p) => p.farm.id)
    groups.forEach((group) => {
      // it is OK to sort inline because these groups are being recreated on memo update
      group.sort(getSortAlgoliaProducts(availAddons.map((adn) => adn.id)))
    })
    return groups
  }, [nearbyProds, availAddons])

  // If loading finishes without products, show Featured
  if (!loading && nearbyProds.length === 0) {
    // Show spinner while featured are loaded
    if (!featured.length) return <Spinner style={styles.spinner} />

    return <FeaturedFarmsList items={featured} />
  }

  // This checks if there are more farms that are still loading
  // If so, we should show skeleton for those farms
  const nPendingFarms = loading ? getRequiredNFarms() - farmgroups.length : 0

  return (
    <View>
      {farmgroups.map((products) => (
        <NearbyProdsGroup key={products[0].farm.id} prods={products} />
      ))}
      {Array.from({ length: nPendingFarms }).map((_, i) => (
        <NearbyProdsGroupSkeleton key={i} />
      ))}
      {/*loading state should not block results*/ loading && <Spinner style={styles.spinner} />}
    </View>
  )
})

const styles = StyleSheet.create({
  spinner: {
    margin: 20,
  },
})
