import { createStackNavigator } from '@react-navigation/stack'

import AdminRegisterScreen from '../admin/screens/Auth/AdminRegisterScreen'
import { Header } from '../components/Header/Header'
import { AddFarmScreen } from '../screens/AddFarmScreen'
import { AppStatus } from '../screens/AppStatus'
import FarmLanding from '../screens/ExternalLinking/FarmLanding'
import { FarmerWalkthroughParamList } from './types'

const FarmerWalkthroughStack = createStackNavigator<FarmerWalkthroughParamList>()

export function FarmerWalkthroughNavigator() {
  return (
    <AppStatus scope="farmer">
      <FarmerWalkthroughStack.Navigator
        initialRouteName="RegisterFarm"
        screenOptions={{
          header: () => <Header />,
          headerMode: 'screen',
        }}
      >
        <FarmerWalkthroughStack.Screen name="RegisterFarm" component={AdminRegisterScreen} />
        <FarmerWalkthroughStack.Screen name="AddFarmScreen" component={AddFarmScreen} />
        <FarmerWalkthroughStack.Screen name="ClaimScreen" component={FarmLanding} />
      </FarmerWalkthroughStack.Navigator>
    </AppStatus>
  )
}
