import { Divider, LoadingView, Picker, Text, TextH2 } from '@elements'
import { formatMiniDate } from '@helpers/display'
import { Money } from '@models/Money'
import { Order, OrderItem } from '@models/Order'
import { OrderCancelPreflightResponse } from '@shared/types/v2/order'
import { useCallback } from 'react'
import { ScrollView, View } from 'react-native'

import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { cancelOrderPreflight } from '@api/Orders'
import { isSameDay } from '@helpers/time'
import { dateTimeInZone } from '@models/Timezone'
import { DateTime } from 'luxon'
import { OrderCancelView } from './components/CancelOrder/OrderCancelView'

export type FormType = {
  stripeAmount: Money
  farmCreditAmount: Money
  ebtAmount: { ebtCASH: Money; ebtSNAP: Money }
  noRefund: boolean
}

export type AdminOrderCancelProps = {
  /** @currentOrder refers to current order */
  currentOrder: Order
  /** @cancelledItems refers to current chosen items that are going to be cancelled */
  cancelledItems: OrderItem[]
  /** @onClose refers to an action will be run after confirmCancellation */
  onClose: () => void
  /** @entireOrder refers to if the entire orderItems are going to be cancelled */
  entireOrder: boolean
}

export function AdminOrderCancel({ currentOrder, cancelledItems, onClose, entireOrder }: AdminOrderCancelProps) {
  const [{ errorMessage, dataLoading, chosenDate, dateOptions, orderCancelPreflightResponse }, set, setAll] =
    useKeyedState<{
      errorMessage?: string
      dataLoading: boolean
      chosenDate: string
      dateOptions?: { label: string; value: string }[]
      orderCancelPreflightResponse?: OrderCancelPreflightResponse
    }>({ dataLoading: false, chosenDate: '' })
  const styles = useStyles()

  useFocusFx(() => {
    set('dataLoading', true)
    /** fetching cancelOrderPreflight data */
    cancelOrderPreflight({
      orderId: currentOrder.id,
      items: cancelledItems.map((itm) => itm.id),
      cancelDate: !chosenDate ? undefined : DateTime.fromISO(chosenDate),
    })
      .then((res) => {
        const curDate = res.cancelDateOptions.find((date) =>
          isSameDay(date, dateTimeInZone(res.cancelDateOptions[0].zoneName)),
        )

        if (!curDate) {
          throw new Error('The current date is not in the list of cancel date options.')
        }

        /** locDateOptions shows the date option for farmers to choose start date of cancellation. */
        const locDateOptions = res.cancelDateOptions.map((date) => ({
          label: formatMiniDate(date),
          value: date.toISO(),
        }))

        // For the first time loading, chosenDate should be the current date by default. (locDateOptions should include the current date.)
        if (!chosenDate) set('chosenDate', curDate.toISO())

        // Only update dateOptions when it is not set yet
        if (!dateOptions) {
          setAll((data) => ({ ...data, orderCancelPreflightResponse: res, dateOptions: locDateOptions }))
        } else {
          set('orderCancelPreflightResponse', res)
        }
      })
      .catch((err) => {
        set('errorMessage', 'There was an error while loading the order cancel data. Please try again.')
        Logger.error(err)
      })
      .finally(() => {
        set('dataLoading', false)
      })
  }, [cancelledItems, chosenDate, currentOrder.id, dateOptions, set, setAll])

  /** the function to handle date picker change */
  const handleDateChange = useCallback(
    (date: string) => {
      set('chosenDate', date)
    },
    [set],
  )

  return (
    <ScrollView horizontal contentContainerStyle={styles.container}>
      <View style={globalStyles.flex1}>
        <Divider />
        <ScrollView contentContainerStyle={styles.verticalContainer}>
          <View style={styles.cancellationTitleCont}>
            <TextH2 type="bold" size={12}>
              Cancellation Start
            </TextH2>
          </View>
          {dateOptions && dateOptions.length === 1 && chosenDate ? (
            <View style={styles.cancellationDateCont}>
              <Text>{formatMiniDate(DateTime.fromISO(chosenDate))}</Text>
            </View>
          ) : (
            <>
              <View style={styles.datePickerCont}>
                <Picker
                  loading={!dateOptions}
                  style={styles.datePickerInnerCont}
                  items={dateOptions ?? []}
                  value={chosenDate}
                  placeholder={null}
                  onValueChange={handleDateChange}
                />
              </View>
              <Divider clear bottom={5} />
            </>
          )}

          <LoadingView loading={dataLoading || !orderCancelPreflightResponse} error={errorMessage}>
            <OrderCancelView
              orderCancelPreflightResponse={orderCancelPreflightResponse!}
              currentOrder={currentOrder}
              cancelledItems={cancelledItems}
              onClose={onClose}
              chosenDate={chosenDate}
              entireOrder={entireOrder}
            />
          </LoadingView>
        </ScrollView>
      </View>
    </ScrollView>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice, isSmallDevice }) => ({
    container: { flex: 1, minWidth: 400 },
    verticalContainer: {
      flex: 1,
      maxHeight: isExtraSmallDevice ? 400 : isSmallDevice ? 600 : '100%',
    },
    cancellationTitleCont: {
      flexDirection: 'row',
      marginHorizontal: 12,
    },
    cancellationDateCont: {
      marginVertical: 5,
      flexDirection: 'row',
      marginHorizontal: 12,
    },
    datePickerCont: {
      marginTop: 5,
      marginHorizontal: 12,
    },
    datePickerInnerCont: {
      height: 30,
      width: 180,
    },
  }))
