import { useIsFocused } from '@react-navigation/native'
import { dequal } from 'dequal'
import { DependencyList, useEffect, useRef } from 'react'

import { useDeepCompareMemoize } from './useDeepEqualEffect'

export type FocusFxOpts = {
  /** If true, the effect will not run on re-focus, only on dependency change while already focused.
   * - Warning: "noRefocus" should not be used for listeners because useFocusFx calls cleanup on focus change.
   */
  noRefocus?: boolean
}

type FocusFXCallback = (focusInfo: {
  isRefocus: boolean
  depsChanged: boolean
  hasFocused: boolean
}) => void | (() => void)

/** useEffect that will not run when the screen is out of focus.*/
export const useFocusFx = (
  callback: FocusFXCallback,
  deps: DependencyList,
  { noRefocus = false }: FocusFxOpts = {},
) => {
  const hasFocused = useRef(false)
  const isFocused = useIsFocused()
  const prevFocus = useRef(true)

  const depsRef = useRef<DependencyList>([])

  useEffect(() => {
    let cleanup: void | (() => void) = () => {}

    // Whether the current run is a refocus
    const isRefocus = isFocused && prevFocus.current === false
    // Whether deps have an inequality
    const depsChanged = !dequal(depsRef.current, deps)

    // If not focused, should not run
    // If it's the first time focusing, should run
    // If focused and deps changed, should run
    // If focused and deps not changed, run if it's not refocusing, or if noRefocus isn't true
    if (isFocused && (!hasFocused.current || depsChanged || !isRefocus || !noRefocus)) {
      cleanup = callback({ isRefocus, depsChanged, hasFocused: hasFocused.current })
    }

    if (isFocused && !hasFocused.current) hasFocused.current = true

    // Update refs
    prevFocus.current = isFocused
    depsRef.current = deps

    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps.concat(isFocused))
}

/** useEffect that will not run when the screen is out of focus.
 * - re-runs only on deep inequality in deps
 */
export function useDeepCompareFocusFx(
  callback: FocusFXCallback,
  deps: DependencyList,
  { noRefocus }: FocusFxOpts = {},
) {
  const hasFocused = useRef(false)
  const isFocused = useIsFocused()
  const prevFocus = useRef(true)

  const depsRef = useRef<DependencyList>([])

  useEffect(() => {
    let cleanup: void | (() => void) = () => {}

    // Whether the current run is a refocus
    const isRefocus = isFocused && prevFocus.current === false
    // Whether deps have an inequality
    const depsChanged = !dequal(depsRef.current, deps)

    // If not focused, should not run
    // If it's the first time focusing, should run
    // If focused and deps changed, should run
    // If focused and deps not changed, run if it's not refocusing, or if noRefocus isn't true
    if (isFocused && (!hasFocused.current || depsChanged || !isRefocus || !noRefocus)) {
      cleanup = callback({ isRefocus, depsChanged, hasFocused: hasFocused.current })
    }

    if (isFocused && !hasFocused.current) hasFocused.current = true

    // Update refs
    prevFocus.current = isFocused
    depsRef.current = deps

    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize(deps.concat(isFocused)))
}
