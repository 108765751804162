import { makeHandle } from '@helpers/helpers'
import algoliasearch, { SearchClient } from 'algoliasearch'

import { AlgoliaIndexType } from '@models/Algolia'
import env from './Environment'

export const [getSearchClient, setSearchClient, isSetSearchClient] = makeHandle<SearchClient>('client')

setSearchClient(algoliasearch(env.ALGOLIA_ID, env.ALGOLIA_SEARCH_KEY))

/** The initialized search client */
export const searchClient = getSearchClient()

/** The names of algolia indices for the current environment */
export const indexNameEnvName = {
  consumer: `${env.APP_ENV}_${AlgoliaIndexType.geosearch}`,
  admin: `${env.APP_ENV}_${AlgoliaIndexType.admindata}`,
}
