import { AlgoliaGeoDoc } from '@models/Algolia'
import { useCallback, useEffect, useState } from 'react'
import { Overlay } from 'react-native-elements'

import Colors from '../../constants/Colors'
import { isTablet, isWeb } from '../../constants/Layout'
import PopUpCard from './PopUpCard'

import { useFnStyles } from '@/hooks/useFnStyles'
import { useLayout } from '@/hooks/useLayout'

type PopUpInfo = {
  top: number
  left: number
}
type Props = {
  data: {
    x: number | undefined
    y: number | undefined
    docs: AlgoliaGeoDoc[]
  } | null
  onBackdropPress?: () => void
}

const MARKER_SIZE = 30
const POPUP_LIST_HEIGHT = 400
const POPUP_HEIGHT = 220

/** Component used for the map marker onPress overlays */
export default function PopUp({ data, onBackdropPress: onBackdropPressProp }: Props) {
  const [position, setPosition] = useState<PopUpInfo>({
    top: 0,
    left: 0,
  })
  const { isSmallDevice, ...layout } = useLayout()

  const POPUP_WIDTH = Math.min(350, layout.width - 20)

  useEffect(() => {
    if (!data) return setPosition({ top: 0, left: 0 })

    const { x, y, docs } = data!
    ////horizontal
    const listWidth = Math.min(3 * (layout.width / 5), 600)
    let left = (x || layout.width / 2) - POPUP_WIDTH / 2
    if (isSmallDevice || isTablet(layout)) {
      //center the popup on the small screen
      left = layout.width / 2 - POPUP_WIDTH / 2
    } else {
      //center the popup on the marker, and prevent it from overlapping with list view on its left-side
      if (left > layout.width - POPUP_WIDTH - 10) left = layout.width - POPUP_WIDTH - 10
      if (left < listWidth + 10) left = listWidth + 10
    }

    ////vertical
    let top = y || layout.height / 2
    const popupHeight = docs.length > 1 ? POPUP_LIST_HEIGHT : POPUP_HEIGHT
    if (isWeb || !isSmallDevice || isTablet(layout)) {
      if (top < popupHeight + layout.height * 0.2) {
        top = Math.min(top + MARKER_SIZE, layout.height * 0.9 - popupHeight)
      } else {
        top -= MARKER_SIZE + popupHeight
      }
    } else {
      top = layout.height * 0.8 - popupHeight
    }
    setPosition({ left: Math.max(left, 1), top: Math.max(top, 1) })
    // should only trigger with "data" to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onBackdropPress = useCallback(() => {
    onBackdropPressProp?.()
    setPosition({ top: 0, left: 0 })
  }, [setPosition, onBackdropPressProp])

  const styles = useStyles(position.top, position.left, POPUP_WIDTH, data?.docs.length)

  if (data && position.top > 0 && position.left > 0)
    return (
      <Overlay
        isVisible
        onBackdropPress={onBackdropPress}
        backdropStyle={styles.backdrop}
        overlayStyle={styles.overlay}
      >
        <PopUpCard data={data.docs} close={onBackdropPress} />
      </Overlay>
    )
  return null
}

const useStyles = (top: number, left: number, width: number, length = 0) =>
  useFnStyles(
    (top, left, width, length) => ({
      overlay: {
        position: 'absolute',
        top,
        left,
        borderRadius: 10,
        height: length > 1 ? POPUP_LIST_HEIGHT : POPUP_HEIGHT,
        width,
        padding: 0,
      },
      backdrop: { backgroundColor: Colors.transparent },
    }),
    top,
    left,
    width,
    length,
  )
