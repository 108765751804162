import { AdminProductsParamList, ProductDetailsRoutes } from '@/admin/navigation/types'
import { PartialPick } from '@helpers/typescript'
import { Farm } from '@models/Farm'
import { Product, ProductType } from '@models/Product'
import { RouteProp } from '@react-navigation/native'
import { ObjectSchema } from 'yup'
import { FormikPricing } from '../AdvancedPricing'
import { FormikBasicInformation } from '../BasicInformation'
import { FormikSchedule } from '../SchedulesComponent'
import { FormikShareBilling } from '../ShareBillingOptions'
import { FormikSharePricing } from '../SharePricing'
import { FormikUnits } from '../UnitsComponent'
import { ProductFormikComponent } from './ProductFormikComponent'
import { FormikTypeInformation } from './ProductTypeInfo'

export const productDetailsFormComponents = [
  FormikTypeInformation,
  FormikBasicInformation,
  FormikSchedule,
  FormikPricing,
  FormikSharePricing,
  FormikShareBilling,
  FormikUnits,
]

export type GetGenericType<C extends ProductFormikComponent<any>> = C extends ProductFormikComponent<infer T>
  ? T
  : unknown

export type ProductDetailsForm = GetGenericType<typeof FormikTypeInformation> &
  GetGenericType<typeof FormikBasicInformation> &
  GetGenericType<typeof FormikSchedule> &
  GetGenericType<typeof FormikPricing> &
  GetGenericType<typeof FormikUnits> &
  GetGenericType<typeof FormikSharePricing> &
  GetGenericType<typeof FormikShareBilling>

/** Combines validation and initialValues from the imported components helpers */
export const productFormSchema = () =>
  productDetailsFormComponents
    .map((c) => c.validator as ObjectSchema<Partial<ProductDetailsForm>>)
    .reduce((a, b) => b.concat(a)) /** Generates the initial formik values for the product details screen */

/** Generates the complete product data from the entire details form state */
export const buildProductFromFormik = async (values: ProductDetailsForm): Promise<Partial<Product>> => {
  const request = productDetailsFormComponents.map((comp) => comp.fromFormik(values))

  const result = await Promise.all(request)

  return result.reduce((a, b) => ({ ...a, ...b } as Partial<Product>))
}

/** Used when generating the initial form values for formik, based on the product and the screen params */
export const buildInitialValues = ({
  product,
  farm,
  params,
  name,
}: {
  /** the product being added or edited. If being added, the type must be defined */
  product: PartialPick<Product, 'type'>
  /** farm is required only for the initial values of a new farm balance */
  farm?: Pick<Farm, 'media' | 'name'>
} & Pick<RouteProp<AdminProductsParamList, ProductDetailsRoutes>, 'params' | 'name'>) => {
  let formValues = productDetailsFormComponents
    .map((comp) => comp.toFormik(product, { params, name }))
    .reduce((a, b) => ({ ...a, ...b }), {}) as ProductDetailsForm

  if (name === 'AddProduct' && !!farm && product.type === ProductType.FarmBalance) {
    formValues = {
      ...formValues,
      name: 'Farm Credit',
      media: farm.media,
      shortDescription: 'Farm credit',
      longDescription: `Buy credit from ${farm.name}`,
      category: 'Farm Credit',
      baseUnit: 'USD',
      globalQuantity: 1000000,
    }
  }
  return formValues
}

/**
 * It is used to validate single field of values in Product Formik Yup validation schema
 * @param field - The field to validate (expects a string)
 * @param formikHelper - The Formik helper object
 * @param values - The values to validate
 * @param type - The type of product
 */
export async function validateProductSingleField<T extends object>(
  field: keyof T,
  formikHelper: ProductFormikComponent<T>,
  values: T,
  type: ProductType,
) {
  /** validateAt return Promise */
  await formikHelper.validator.validateAt(field as string, values, {
    abortEarly: false,
    context: { type },
  })
}
