/** Common UI for the mobile and web versions of google places search */

import { Ionicons } from '@expo/vector-icons'
import { useContext } from 'react'
import { FlatList, StyleSheet, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'

import { Divider } from './elements/Divider'
import { Text } from './elements/Text'
import { GooglePlacesSearchContext, useGooglePlacesSearchData } from './googlePlacesSearch.helper'

import Colors from '@/constants/Colors'
import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { useFlatStyle } from '@/hooks/useMergeStyle'

type UiProps<ResultType extends { description: string }, DetailType extends object> = {
  getResultDetails: (res: AutoCompleteItem<ResultType>) => Promise<AutoCompleteItem<DetailType>>
  inputValue: string
  setValue: (v: string) => void
  isSearching: boolean
  searchResults: ResultType[]
}

const hitSlop = { top: 4, right: 4, bottom: 4, left: 4 }

/** UI for the google places search input. This is meant to be identical for web and mobile, since the only difference necessary is in the data layer, which is where it uses different libraries in different platforms, but they all use this same UI */
export function GooglePlacesSearchUi<ResultType extends { description: string }, DetailType extends object>({
  getResultDetails,
  inputValue,
  isSearching,
  searchResults,
  setValue,
}: UiProps<ResultType, DetailType>) {
  const {
    customProps: { placeholderCurrLoc, noInitialValue, inline, onSelect, hasClearBtn, contStyle },
    textInputProps: { style, placeholder: placeholderArg, ...textInputRest },
  } = useContext(GooglePlacesSearchContext)

  const {
    inputRef,
    hideInlineResults,
    onFocus,
    onChangeText,
    onSubmit,
    items,
    inlineOnPressItem,
    placeholder,
    onTouchStart,
    onClearText,
    autoCompleteOverlay,
  } = useGooglePlacesSearchData({
    placeholderCurrLoc,
    noInitialValue,
    getResultDetails,
    setValue,
    inline,
    onSelect,
    placeholder: placeholderArg,
    searchResults,
    inputValue,
    isSearching,
  })
  return (
    <>
      {autoCompleteOverlay}
      <View style={contStyle}>
        <TextInput
          ref={inputRef}
          style={useFlatStyle([styles.input, hasClearBtn && styles.paddingRight30, style])}
          value={inputValue}
          onFocus={onFocus}
          onChangeText={onChangeText}
          onSubmitEditing={onSubmit}
          onTouchStart={onTouchStart}
          placeholder={placeholder}
          placeholderTextColor={Colors.shades['400']}
          importantForAutofill="no"
          blurOnSubmit
          {...textInputRest}
        />

        {hasClearBtn && inputValue.length > 0 && (
          <View style={styles.iconCont}>
            <TouchableWithoutFeedback hitSlop={hitSlop} onPress={onClearText}>
              <Ionicons color={Colors.shades['200']} name="close" size={20} />
            </TouchableWithoutFeedback>
          </View>
        )}
      </View>

      {!hideInlineResults && (
        <FlatList
          data={items}
          renderItem={({ item, index }) => (
            <TouchableOpacity key={item.data.description} onPress={() => inlineOnPressItem(item.data)}>
              <Text style={styles.inlineItemText}>{item.text}</Text>
              {index < items.length - 1 && <Divider top={0} bottom={0} />}
            </TouchableOpacity>
          )}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  /** These styles must work for mobile and web */
  input: {
    // flex: 1, // Don't do it!. Made the input become invisible on android
    height: 50,
    fontSize: 14,
    paddingHorizontal: 15,
    borderRadius: 5,
  },
  inlineItemText: {
    padding: 10,
  },
  paddingRight30: {
    // If clear button is enabled, it has to have space in the view
    paddingRight: 30,
  },
  iconCont: {
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 5,
  },
})
