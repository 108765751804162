import { Logger } from '@/config/logger'
import { logOrderFailed, logOrderPlaced } from '@api/FBAnalytics'
import { addOrder } from '@api/Orders'
import { Alert, Loader } from '@elements'
import { errorToString } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { CoverFee } from '@helpers/serviceFee'
import { SplitTenderPayment } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setNavProps } from '../../../redux/actions/appState'
import { paramsSelector } from '../../../redux/selectors'

import { Total } from '@/constants/types'
import { ShoppingStackParamList } from '@/navigation/types'
import { omit } from '@helpers/typescript'

export function useHandleAddOrder(coverFee: CoverFee, total: Total, disableConfirmations: () => void) {
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'Checkout'>>()
  const { farm } = useSelector(paramsSelector)

  /** handleAddOrder will try to place the order by calling the addOrder api */
  return useCallback(
    async (tender: SplitTenderPayment, cartId: string) => {
      try {
        Loader(true)
        // If the user chose to cover fees then pass the fee amount and if it should be applied to installments
        const fees = coverFee.id === 'no' ? undefined : omit(coverFee, 'value')

        const { id } = await addOrder({ cartId, tender, fees })
        if (!id) throw new Error('Order ID was not returned from the created order')
        logOrderPlaced(farm!.id, MoneyCalc.cents(total.total))
        disableConfirmations() //Requirement: Must mark checkout as completed BEFORE navigating away, to prevent unwanted confirmation alerts.
        dispatch(setNavProps()) // This will clear the farm cache, so as to load the updated product data next time useFarmData gets called
        navigation.popToTop() //Requirement: Must reset this navigator (or Pop the current screen) before leaving, so next time they enter this navigator they won't land on checkout screen
        navigation.navigate('OrdersNavigator', {
          screen: 'OrderSummary',
          params: { orderId: id, confirmation: true },
        })
        Loader(false)
      } catch (err) {
        Logger.error(err)
        Loader(false)

        const msg = errorToString(err)
        logOrderFailed(msg)

        let displayMsg = msg || 'There was an error placing this order please try again in a little while. '
        if (msg.includes('declined'))
          displayMsg = 'Your credit card was declined. Please fix this card or try a different card.'
        if (msg.includes('security code'))
          displayMsg = "Your credit card's security code is incorrect, please fix it or try a different card."
        if (msg.includes('requires additional user action'))
          displayMsg =
            'The card you are using requires special authentication that is not supported at the time. Please try a different card.'
        Alert('Error Placing Order', displayMsg)
      }
    },
    [coverFee, dispatch, farm, navigation, disableConfirmations, total.total],
  )
}
