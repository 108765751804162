import { logGeneric } from '@api/FBAnalytics'
import { Alert } from '@elements'
import { SplitTenderPayment } from '@models/Order'
import { isEbtPayment } from '@models/PaymentMethod'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Logger } from '../../../config/logger'
import { setNavProps } from '../../../redux/actions/appState'
import { consumerCartInfoSelector, paramsSelector, userSelector } from '../../../redux/selectors'
import { validateEbtPin } from '../../PaymentMethods/validateEbtPin'

import { Total } from '@/constants/types'
import { ValidateCartAlert } from '@/hooks/useValidateCart'
import { ShoppingStackParamList } from '@/navigation/types'
import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'

type PlaceOrderType = {
  total: Total
  handleAddOrder: (tender: SplitTenderPayment, cartId: string) => Promise<void>
  disableConfirmations: () => void
  validateCartAlert: ValidateCartAlert
  splitTender?: SplitTenderPayment
  cartHasShares: boolean
}

export const usePlaceOrder = ({
  total,
  handleAddOrder,
  disableConfirmations,
  validateCartAlert,
  splitTender,
  cartHasShares,
}: PlaceOrderType) => {
  const { farm } = useSelector(paramsSelector)
  const { id: userId } = useSelector(userSelector)
  const dispatch = useDispatch()
  const { cartFarmId, cartId } = useSelector(consumerCartInfoSelector)
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'Checkout'>>()

  /** placeOrder will check everything required for order placement is correct, and will run business logic on payment data to handle things like farm credit, ebt, split tender, and also will run cart validation */
  return useCallback(async () => {
    logGeneric('placing_order')

    if (!farm) {
      Logger.warn('The farm for this order could not be loaded')
      return Alert('Farm data was not loaded')
    }
    if (!cartFarmId || !cartId) {
      return Alert('Order Error', 'Could not associate a cart with this order')
    }
    // Make sure payments are defined, this should never be false after the payment selector confirms it
    if (!splitTender || splitTender.length === 0) {
      return Alert('Order Error', 'No payment methods specified for this order')
    }

    /** onGoToShopPress will be called if the user cancels checkout on cart validation errors */
    const onGoToShopPress = () => {
      disableConfirmations()

      dispatch(setNavProps()) // This will clear the farm cache, so as to load the updated product data
      navigation.navigate('FarmShop', { farmSlug: cartFarmId })
    }
    const res = await validateCartAlert({ onGoToShopPress })
    /** We will only proceed to adding the order if the user didn't cancel on any alert prompts notifying them of cart changes AND the remaining cart isn't empty */
    if (res.cancelCheckout || res.newCartLength === 0) return

    // If the cart has shares or an EBT payment then we should not check the cart minimum. Otherwise, we should respect it
    if (
      !cartHasShares &&
      !splitTender?.some((pay) => isEbtPayment(pay.paymentMethod)) &&
      farm.tipsAndFees?.minimumCartAmount
    ) {
      if (MoneyCalc.isGreaterThan(farm.tipsAndFees.minimumCartAmount, total.total)) {
        return Alert(
          'Minimum amount required',
          `Oops, it seems that the minimum cart amount for this farm is ${formatMoney(
            farm.tipsAndFees.minimumCartAmount,
          )}\nPlease update your cart and try again`,
        )
      }
    }

    // If there is an ebt payment than check the pin and request it if necessary
    const ebtPayment = splitTender?.find((pmt) => isEbtPayment(pmt.paymentMethod))
    if (ebtPayment && isEbtPayment(ebtPayment.paymentMethod)) {
      const { pin } = await validateEbtPin(ebtPayment.paymentMethod, userId, true)
      ebtPayment.paymentMethod.pin = pin
    }

    return handleAddOrder(splitTender, cartId)
  }, [
    userId,
    cartFarmId,
    dispatch,
    cartId,
    farm,
    handleAddOrder,
    navigation,
    disableConfirmations,
    splitTender,
    total.total,
    validateCartAlert,
    cartHasShares,
  ])
}
