import { useCallback } from 'react'

import { RootState } from '@/redux/reducers/types'
import { useSelectorRoot } from '@/redux/selectors'
import { isSafeSlug } from '@helpers/urlSafeSlug'

/**
 * Accessor for avail addons anywhere. Intended for the consumer side.
 *
 * @param farmId If provided, the returned addons will be only for this farmId. Otherwise will return all the availAddons in redux context
 * @returns availAddons calculation results and their matching csas and schedules
 */
export const useAvailAddons = (farmId?: string) => {
  if (farmId && isSafeSlug(farmId)) throw new Error('This hook does not work with slugs')

  const selectorFn = useCallback(
    ({ appPersist: { availAddonCsas, availAddons } }: RootState) => ({
      /** availAddons includes only the availAddons where isAvail is true, meaning they are available to add to cart. Use this when you only need to access the available addons for adding to cart and don't care to explain why a given addon may not be in the list */
      availAddons: (farmId ? availAddons.filter(({ addon: { farm } }) => farm.id === farmId) : availAddons)
        .filter(({ isAvail }) => isAvail)
        .map((res) => res.addon),

      /** availAddonsResults includes all the availAddons results, which will include those where isAvail is false, and their reason for being unavailable. Use this when you need to explain why an addon is not available */
      availAddonsResults: farmId ? availAddons.filter(({ addon: { farm } }) => farm.id === farmId) : availAddons,

      /** availAddonCsas includes the CSAs for any addons in the list, whether available or not */
      availAddonCsas: farmId ? availAddonCsas.filter(({ farm }) => farm.id === farmId) : availAddonCsas,
    }),
    [farmId],
  )
  return useSelectorRoot(selectorFn)
}
