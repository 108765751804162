import { Divider, LoadingView, Text, TextH1, Toast, Touchable } from '@elements'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import { PaymentCard } from '@screens/Orders/PaymentCard'
import React, { useCallback, useState } from 'react'
import { FlatList, Platform, ScrollView, StyleSheet, View } from 'react-native'
import { ListItem } from 'react-native-elements'

import { SharedScreenHeader, sharedStyles, SignInSummaryOrder } from './components'
import { useSignInSheetContext } from './useSignInSheetData'

import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { SignInSheetParamList } from '@/navigation/types'
import { openUrl } from '@helpers/client'
import { unmarshalPhoneNumber } from '@helpers/display'
import { withDataAndAuth } from './withDataAndAuth'

type Props = StackScreenProps<SignInSheetParamList, 'PickupSummary'>

// Android does not support the SMS link, so we use tel instead
const smsLinkPrefix = Platform.OS === 'android' ? 'tel' : 'sms'

function PickupSummaryScreenComp({ route }: Props) {
  const { items, getUserInvoices } = useSignInSheetContext()
  const [invoicesExpanded, setInvoicesExpanded] = useState(false)

  const invoices = useApiFx(getUserInvoices, [route.params.userId], !!route.params.userId, {
    onError: () => Toast('Error fetching invoices'),
  })

  const item = items.find((itm) => itm.user.id === route.params.userId)

  const toggleExpanded = () => {
    setInvoicesExpanded((c) => !c)
  }

  const UserDetails = useCallback(() => {
    if (!item) return null
    return (
      <View style={styles.card}>
        <ListItem.Accordion
          content={
            <ListItem.Content>
              <Text type="medium" size={16}>
                {item.user.name.firstName}
              </Text>
            </ListItem.Content>
          }
          noIcon
          isExpanded
        >
          <View style={globalStyles.padding10}>
            <Text size={14}>{item.user.phoneNumber ? unmarshalPhoneNumber(item.user.phoneNumber, true) : ''}</Text>
            <View style={globalStyles.margin10} />
            <View style={styles.rowJustify}>
              {item.user.phoneNumber ? (
                <>
                  <Touchable style={styles.actionCard} onPress={() => openUrl(`tel:${item.user.phoneNumber}`)}>
                    <Ionicons size={16} color={Colors.green} name="phone-portrait-sharp" />
                    <Divider />
                    <Text color={Colors.green}>Call</Text>
                  </Touchable>
                  <Touchable
                    style={styles.actionCard}
                    onPress={() => openUrl(`${smsLinkPrefix}:${item.user.phoneNumber}`)}
                  >
                    <MaterialCommunityIcons size={16} color={Colors.green} name="message" />
                    <Divider />
                    <Text color={Colors.green}>Text</Text>
                  </Touchable>
                </>
              ) : null}
              <Touchable style={styles.actionCard} onPress={() => openUrl(`mailto:${item.user.email}`)}>
                <Ionicons size={16} color={Colors.green} name="mail" />
                <Divider />
                <Text color={Colors.green}>Email</Text>
              </Touchable>
            </View>
          </View>
        </ListItem.Accordion>
      </View>
    )
  }, [item])

  const InvoicesSection = useCallback(() => {
    return (
      <LoadingView success={invoices.data} loading={invoices.loading} style={styles.card}>
        {(data) => (
          <ListItem.Accordion
            content={
              <>
                <ListItem.Content>
                  <TextH1 size={18}>{`Invoices (${data.length})`}</TextH1>
                </ListItem.Content>
              </>
            }
            isExpanded={invoicesExpanded}
            onPress={toggleExpanded}
          >
            <FlatList
              data={data}
              scrollEnabled={isWeb}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item.id}
              renderItem={({ item: inv }) => (
                <PaymentCard
                  invoice={inv}
                  onPress={() => openUrl(`${grownbyWebsiteBaseUrl()}external/invoice/${inv.id}`)}
                />
              )}
            />
          </ListItem.Accordion>
        )}
      </LoadingView>
    )
  }, [invoices, invoicesExpanded])

  function OrdersSection() {
    if (!item) return null
    return (
      <View style={styles.marginV5}>
        {item.orders.map((orderData) => (
          <SignInSummaryOrder key={orderData.orderInfo.id} orderData={orderData} showOrderInfo />
        ))}
      </View>
    )
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={sharedStyles.wrapperNoAlign}>
      <SharedScreenHeader title="Customer details" />
      <UserDetails />
      <View style={globalStyles.margin10} />
      <InvoicesSection />
      <View style={globalStyles.margin10} />
      <OrdersSection />
    </ScrollView>
  )
}

export const PickupSummaryScreen = withDataAndAuth(PickupSummaryScreenComp)

const styles = StyleSheet.create({
  rowJustify: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  card: {
    borderRadius: 8,
    borderColor: Colors.shades[100],
    borderWidth: 1,
    width: '100%',
    marginVertical: 10,
    overflow: 'hidden',
  },
  actionCard: {
    borderRadius: 8,
    width: 100,
    height: 76,
    backgroundColor: Colors.lightGreen,
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginV5: {
    marginVertical: 5,
  },
})
