import { CheckBox, Divider, HeaderText, Text } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { CoverFee, CoverOptId, getCoverOptions, getDefaultOption } from '@helpers/serviceFee'
import { Farm } from '@models/Farm'
import { Money, Zero } from '@models/Money'
import { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'

import Colors from '@/constants/Colors'
import { ToolTips } from '@components'
import { formatMoney } from '@helpers/display'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'
import { FormMoneyInput } from '../../../components/elements/Form/FormMoneyInput'
import { formStyles } from '../../../components/elements/Form/helpers/styles'
import { useDebouncedCallback } from '../../../hooks/useDebounce'

type Props = {
  /** the settings the farm specified about whether they want the customers to cover fees or not */
  tipOptions: Farm['tipsAndFees']
  /** The amount of service fees being applied to the order which indicates how much the user will pay if they choose to cover fees */
  serviceFeeAmount: Money
  /** coverFee holds all options to control fee coverage like the type of fee coverage and the amount as well as if it
   * should apply to installments or not */
  coverFee: CoverFee
  /** The handler that will update the order when the CoverFee change */
  onUpdateCoverFee: (data: CoverFee) => void
  /** If true will not show option to apply to installments */
  hideInstallments?: boolean
}

/** This component is responsible for displaying fees to the customer and allowing them to configure the fee amount */
export function OrderCoverComponent({
  serviceFeeAmount,
  tipOptions,
  coverFee,
  onUpdateCoverFee,
  hideInstallments,
}: Props) {
  // We store tip value in internal state as well as part of the fee option, it is used here for debouncing only so that
  // we update the state locally and only update the cover fee option once the user has stopped typing
  const [tipValue, setTipValue] = useState<Money | undefined>(undefined)
  const styles = useStyles()

  const options = useMemo(() => {
    if (MoneyCalc.isLTE(serviceFeeAmount, Zero)) {
      return []
    }
    return getCoverOptions(serviceFeeAmount, tipOptions)
    // We use value here instead of just totalForFees, because when the fees change splitTender will be recomputed which \
    // will trigger totalForFees to change. Even though the amount will be the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipOptions, serviceFeeAmount.value])

  // This will make sure that we don't update the fee until the user stops typing to reduce some lag with excessive computations
  const debouncedUpdateOrderCover = useDebouncedCallback(onUpdateCoverFee, 500)

  const handleTextChange = (value: Money | undefined) => {
    // We are setting tip value here only for the purpose of showing it in the UI while we wait for the debounce to resolve
    setTipValue(value)
    debouncedUpdateOrderCover({
      ...coverFee,
      tip: value ?? Zero,
      // Will calculate the total to be the platform fee amount + the additional tip
      value: MoneyCalc.add(value ?? Zero, serviceFeeAmount),
    })
  }

  const setApplyInstallments = (value: boolean) => onUpdateCoverFee({ ...coverFee, applyToInstallments: value })

  const setNewFeeOption = (newId: CoverOptId) => {
    const newOpt = options.find((opt) => opt.id === newId)

    if (newOpt && newId !== coverFee.id) {
      // Clear the tip amount if we switch fee types
      setTipValue(undefined)
      onUpdateCoverFee({ ...newOpt, tip: Zero, applyToInstallments: coverFee.applyToInstallments })
    }
  }

  /** This effect will re-select and update the caller when the options change*/
  useEffect(() => {
    // Don't set fee options until the options have been computed
    if (!options.length) return
    // If the options change or amounts change we need to re-calculate the fee
    const newOption = options.find((o) => o.id === coverFee.id)
    if (newOption) {
      // If the value is changing meaning either the order changed or the tip type changed. Then we should recompute the
      // value, meaning we should re-add the tip amount.
      const newValue =
        newOption.value !== coverFee.value ? MoneyCalc.add(newOption.value, coverFee.tip) : newOption.value

      onUpdateCoverFee({
        ...coverFee,
        value: newValue,
      })
    } else {
      onUpdateCoverFee({
        id: CoverOptId.None,
        tip: Zero,
        value: Zero,
        applyToInstallments: true,
      })
    }
    // We only want to update if the options changed, changes to other fields are handled in other hooks
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    if (MoneyCalc.isLTE(serviceFeeAmount, Zero)) {
      // If the total is zero, set fee to None
      onUpdateCoverFee({
        id: CoverOptId.None,
        tip: Zero,
        value: Zero,
        applyToInstallments: true,
      })
    }
    // See same comment in options for description
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceFeeAmount.value, onUpdateCoverFee])

  /** This will set the order cover default option, only on first load */
  useEffect(() => {
    onUpdateCoverFee({ ...getDefaultOption(serviceFeeAmount, tipOptions), applyToInstallments: true })
    // We only want to run this on first load not when deps change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputBlur = () => {
    // If the user exits the input and has typed a custom amount of 0 or not typed anything we will jump back to No Cover
    if (MoneyCalc.isZero(coverFee.value)) {
      onUpdateCoverFee({
        id: CoverOptId.None,
        tip: Zero,
        value: Zero,
        applyToInstallments: false,
      })
    }
  }

  // If there is no fees payable then don't show the fees section
  if (MoneyCalc.isLTE(serviceFeeAmount, Zero)) return null

  // If farmers don't let customer pay fees then don't show the fees section
  if (!tipOptions?.customerChooseToPayFees) return null

  return (
    <View style={styles.wrapper}>
      <Text size={8} color={Colors.shades[400]}>
        OPTIONAL
      </Text>
      <HeaderText size={16} style={styles.headerText}>
        Farmer love
      </HeaderText>
      <CheckBox
        size={20}
        checked={coverFee.id === CoverOptId.Platform}
        onChange={(val) => setNewFeeOption(val ? CoverOptId.Platform : CoverOptId.None)}
        title={`Cover farmer fees (${formatMoney(serviceFeeAmount)})`}
        toolTipTitle="Service Fees"
        toolTipId={ToolTips.SERVICE_FEE}
      />
      <Divider clear />
      {tipOptions?.showTipOption && (
        <View style={styles.coverFeesContainer}>
          <CheckBox
            size={20}
            checked={coverFee.id === CoverOptId.Custom}
            onChange={(val) => setNewFeeOption(val ? CoverOptId.Custom : CoverOptId.None)}
            title={`Cover farmer fees (${formatMoney(serviceFeeAmount)}) + tip`}
          />
          {coverFee.id === 'custom' && (
            <FormMoneyInput
              autoFocus
              maxLength={9}
              style={styles.customAmount}
              inputStyle={[styles.customAmount, formStyles.inputStyle]}
              containerStyle={styles.customAmount}
              inputContainerStyle={[styles.customAmount, formStyles.inputContainer]}
              value={tipValue}
              onChangeText={handleTextChange}
              onBlur={handleInputBlur}
            />
          )}
        </View>
      )}

      {!hideInstallments && coverFee.id !== CoverOptId.None && (
        <>
          <Divider bottom={10} />
          <CheckBox
            size={20}
            checked={!!coverFee.applyToInstallments}
            onChange={setApplyInstallments}
            title="Cover farmer fees for my remaining payments"
          />
        </>
      )}
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    wrapper: {
      flex: 1,
      borderRadius: 10,
      padding: 15,
      borderWidth: 1,
      borderColor: Colors.shades[100],
      margin: 10,
    },
    headerText: {
      paddingBottom: 5,
    },
    coverFeesContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    coverFeePicker: {
      maxWidth: 400,
    },
    customAmount: {
      maxWidth: 100,
      width: 100,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      coverFeesContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
)
