import { ISO8601Time, Time, Timestamp } from '@api/encoding/Time'
import { DateRange, DayOfWeek } from '@models/Schedule'
import { Timezone, dateTimeInZone } from '@models/Timezone'
import {
  differenceInCalendarWeeks as differenceInCalendarWeeksDate,
  differenceInMonths as differenceInMonthsDate,
  eachDayOfInterval as eachDayOfIntervalDate,
  eachMonthOfInterval as eachMonthOfIntervalDate,
  eachWeekOfInterval as eachWeekOfIntervalDate,
  endOfToday,
  endOfYear,
  endOfYesterday,
  format as formatDate,
  getWeekOfMonth as getWeekOfMonthDate,
  isWithinInterval as isWithinIntervalDate,
  parse as parseDate,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  sub,
} from 'date-fns'
import { DateTime, Zone } from 'luxon'

import { hasOwnProperty, isObject } from './helpers'

// eachDayOfInterval provides a wrapper around the eachDayOfInterval function included by date-fns to maintain
// existing logic using under the usage of the DateTime object.

export function eachDayOfInterval(
  interval: { start: DateTime; end: DateTime },
  options?: { step?: number; zone?: Timezone },
): DateTime[] {
  return eachDayOfIntervalDate(
    {
      start: toJSDate(interval.start),
      end: toJSDate(interval.end),
    },
    options,
  ).map((date) => {
    if (options?.zone) {
      return fromJSDate(date, options.zone)
    }
    return DateTime.fromJSDate(date)
  })
}

// eachWeekOfInterval provides a wrapper around the eachWeekOfInterval function included by date-fns to maintain
// existing logic using under the usage of the DateTime object.

export function eachWeekOfInterval(
  interval: { start: DateTime; end: DateTime },
  options?: { weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6; zone?: Timezone; fromStartDate?: boolean },
): DateTime[] {
  const weeks = eachWeekOfIntervalDate(
    {
      start: toJSDate(interval.start),
      end: toJSDate(interval.end),
    },
    options,
  ).map((date) => {
    if (options?.zone) {
      return fromJSDate(date, options.zone)
    }
    return DateTime.fromJSDate(date)
  })

  if (!options?.fromStartDate) return weeks

  // If fromStartDate is specified we will return weekly dates based on the interval start
  const dayOfWeek = interval.start.weekday // zero indexes
  return weeks.map((week) => week.plus({ days: dayOfWeek })).filter((day) => day <= interval.end)
}

// eachMonthOfInterval provides a wrapper around the eachMonthOfInterval function included by date-fns to maintain
// existing logic using under the usage of the DateTime object.

export function eachMonthOfInterval(
  interval: { start: DateTime; end: DateTime },
  options?: { zone?: Timezone; fromStartDate?: boolean },
): DateTime[] {
  // The day of the month to calculate intervals from
  // const startDate = options?.startsOn || 1
  const months = eachMonthOfIntervalDate({
    start: toJSDate(interval.start),
    end: toJSDate(interval.end),
  }).map((date) => {
    if (options?.zone) {
      return fromJSDate(date, options.zone)
    }
    return DateTime.fromJSDate(date)
  })

  if (!options?.fromStartDate) return months

  // If fromStartDate is specified we will return monthly dates based on the interval start
  const dayOfMonth = interval.start.day // starts at 1 so we need to subtract 1 to get the offset
  return months.map((mo) => mo.plus({ days: dayOfMonth - 1 })).filter((day) => day <= interval.end)
}

export const getDayOfWeek = (d: DateTime): DayOfWeek => {
  return Number(format(d, 'i'))
}

// getWeekOfMonth provides a wrapper around the getWeekOfMonth function included by date-fns to maintain
// existing logic using under the usage of the DateTime object.
// Not zero-indexed

export function getWeekOfMonth(date: DateTime): number {
  return getWeekOfMonthDate(toJSDate(date))
}

// differenceInCalendarWeeks provides a wrapper for the differenceInCalendarWeeks function provided by date-fns.

export function differenceInCalendarWeeks(left: DateTime, right: DateTime): number {
  return differenceInCalendarWeeksDate(toJSDate(left), toJSDate(right))
}

// differenceInMonths provides a wrapper for the differenceInMonths function provided by date-fns.

export function differenceInMonths(left: DateTime, right: DateTime): number {
  return differenceInMonthsDate(toJSDate(left), toJSDate(right))
}

type DateComparisonOpts =
  | { orEqual?: boolean; zone?: string | Zone } & (
      | {
          granularity?: 'day'
          zone: string | Zone
        }
      | { zone?: undefined; granularity?: 'exact' }
    )

/**
 * Whether date1 is after the date2.
 * - granularity: 'day' will make the comparison using DateTime.startOf('day') on both dates
 */
export function isAfter(date1: DateTime, date2: DateTime, opts?: DateComparisonOpts): boolean {
  const { granularity = 'exact', orEqual = false, zone }: DateComparisonOpts = opts ?? {}

  // If we want to check by day, put both dates in the same zone but keep local times
  if (granularity === 'day') {
    if (zone) {
      date1 = date1.setZone(zone)
      date2 = date2.setZone(zone)
    } else date2 = date2.setZone(date1.zone)

    // Set both dates to the start of day of the common timezone
    date1 = date1.startOf('day')
    date2 = date2.startOf('day')
  }
  return orEqual ? date1 >= date2 : date1 > date2
}

/**
 * Whether date1 is before the date2.
 * - granularity: 'day' will make the comparison using DateTime.startOf('day') on both dates
 */
export function isBefore(date1: DateTime, date2: DateTime, opts?: DateComparisonOpts): boolean {
  const { granularity = 'exact', orEqual = false, zone }: DateComparisonOpts = opts ?? {}

  // If we want to check by day, put both dates in the same zone but keep local times
  if (granularity === 'day') {
    if (zone) {
      date1 = date1.setZone(zone)
      date2 = date2.setZone(zone)
    } else date2 = date2.setZone(date1.zone)

    // Set both dates to the start of day of the common timezone
    date1 = date1.startOf('day')
    date2 = date2.startOf('day')
  }

  return orEqual ? date1 <= date2 : date1 < date2
}

// A useful helper to check if a date is in the future
export const isFuture =
  (tz: Timezone = 'utc', granularity: 'day' | 'now' = 'now') =>
  (value: DateTime) =>
    granularity === 'now'
      ? value > dateTimeInZone(tz)
      : value.setZone(tz).endOf('day') > dateTimeInZone(tz).endOf('day')

/** Determines if two DateTime instances are on the same date of a specified timezone */
export const isSameDay = (d1: DateTime, d2: DateTime, zone: Timezone | Zone = d1.zone): boolean => {
  return d1.setZone(zone).toISODate() === d2.setZone(zone).toISODate()
}

// parses a date string into the reference date object by wrapping the parse date-fns function.

export function parse(dateString: string, formatString: string, referenceDate: DateTime): DateTime {
  return fromJSDate(parseDate(dateString, formatString, toJSDate(referenceDate)), referenceDate.zoneName)
}

// format provides a wrapper around the format function included by date-fns to maintain
// existing logic using under the usage of the DateTime object.

export function format(date: DateTime, formatString: string): string {
  if (typeof date === 'number' || typeof date === 'string') {
    //FIXME: this is just to identify places in the UI that will break the app
    return 'date is not the right format'
  }

  //FIXME: Some dates are coming in as null for some reason on physical android devices without remote debugging enabled.
  // if remote debugging is enabled, it seems to work.  not sure what the issue is here, but this check seems to prevent
  // the app from crashing -Simon
  if (!isNaN(date.toMillis())) {
    return formatDate(toJSDate(date), formatString)
  } else {
    return 'jsDate error'
  }
}

// formatDateRange returns the start and end dates formatted using the supplied date format string, separated by the
// defined separator.

export function formatDateRange(range: DateRange, formatString: string, separator = '-'): string {
  if (formatString === 'MMM dd, yyyy' && range.startDate.year === range.endDate.year)
    return [format(range.startDate, 'MMM dd'), format(range.endDate, 'MMM dd, yyyy')].join(` ${separator} `)
  return [format(range.startDate, formatString), format(range.endDate, formatString)].join(` ${separator} `)
}

// formatDateRange returns the start and end dates formatted using the supplied date format string, separated by the
// defined separator.

export function isWithinInterval(range: DateRange, date: DateTime): boolean {
  return isWithinIntervalDate(toJSDate(date), { start: toJSDate(range.startDate), end: toJSDate(range.endDate) })
}

/** Converts the date to a javascript date factoring in timezone offset */
export function toJSDate(date: DateTime) {
  return date.setZone('local', { keepLocalTime: true }).toJSDate()
}

/** Converts the date from a javascript date factoring in timezone offset */
export function fromJSDate(date: Date, zone: Timezone = 'America/New_York') {
  return DateTime.fromJSDate(date).setZone(zone, { keepLocalTime: true })
}

/**
 * Check that starting date happens before or the same day as the ending date
 */
export const isValidDateRange = (dateRange: Partial<DateRange>): dateRange is DateRange =>
  !!dateRange.startDate &&
  !!dateRange.endDate &&
  isBefore(dateRange.startDate, dateRange.endDate, {
    granularity: 'day',
    orEqual: true,
    zone: dateRange.startDate.zone,
  })

/** Identify an object is a luxon DateTime type */
export function isLuxonDateTime(obj: any): obj is DateTime {
  return (
    !!obj &&
    typeof obj === 'object' &&
    !Array.isArray(obj) &&
    hasOwnProperty(obj, 'isLuxonDateTime') &&
    obj.isLuxonDateTime === true
  )
}

export const isEncodedTime = (time: any): time is Time | ISO8601Time | Timestamp => {
  return typeof time === 'number' || typeof time === 'string' || isTime(time) || hasOwnProperty(time, '_nanoseconds')
}

/** Identifies a marshalled luxon DateTime, in the database format */
export const isTime = (time: any): time is Time => {
  return (
    isObject(time) && hasOwnProperty(time, 'utc') && hasOwnProperty(time, 'local') && hasOwnProperty(time, 'timezone')
  )
}

/** Limits a date to a given date range */
export const limitToRange = (date: DateTime, range: DateRange): DateTime => {
  if (!isValidDateRange(range)) throw new Error("The date range isn't valid")
  return DateTime.min(DateTime.max(date, range.startDate), range.endDate)
}

/** To compare whether two dateTimes are in the same week. */
export function isSameWeek(a: DateTime, b: DateTime): boolean {
  return a.weekNumber === b.weekNumber
}

//The date range is displayed in the format of MMM dd - MMM dd, yyyy or MMM dd, yyyy - MMM dd, yyyy
export const displayDateRange = (dateRange: DateRange): string => {
  if (dateRange.startDate.year === dateRange.endDate.year) {
    return `${formatDateRange(dateRange, 'MMM dd', '-')}, ${dateRange.startDate.year}`
  } else {
    return `${formatDateRange(dateRange, 'MMM dd, yyyy', '-')}`
  }
}

//The date range is displayed in the format of MM/dd/yy - MM/dd/yy
export const displayShortDateRange = (dateRange: DateRange): string => {
  return `${formatDateRange(dateRange, 'MM/dd/yy', '-')}`
}

/** To get the time format like 06/16/23 at 7:09pm EST.
 * @param dateTime - The date to format
 * @param local - A flag to determine if the timestamp should be displayed in local time. By default, it is set to true.
 */
export function displayTimeStamp(dateTime: DateTime, local = true): string {
  const localDateTime = local ? dateTime.toLocal() : dateTime

  return `${format(localDateTime, 'MM/dd/yy')} at ${format(localDateTime, 'h:mma').toLowerCase()} ${
    localDateTime.offsetNameShort
  }`
}

/** Pre-defined ranges used in the app */
export enum DatePeriods {
  Today = 'Today',
  Yesterday = 'Yesterday',
  This_Week = 'This Week',
  Last_7_days = 'Last 7 days',
  This_Month = 'This Month',
  Last_30_days = 'Last 30 days',
  Last_3_Months = 'Last 3 Months',
  Last_6_Months = 'Last 6 Months',
  This_Year = 'This Year',
  Last_Year = 'Last Year',
  Custom = 'Custom',
}

/**
 * Converts an DatePeriod into `DateRange` object
 * @param period - The period to be converted.
 * @param zone - An optional parameter specifying the time zone for date conversion.
 * @returns An object containing the start and end date for the specified period, or undefined if period is `DatePeriods.Custom`
 */
export const convertPeriodToRange = (period: DatePeriods, zone?: Timezone): DateRange | undefined => {
  const today = startOfToday()

  let range: {
    startDate: Date
    endDate: Date
  }

  switch (period) {
    case DatePeriods.Today:
      range = { startDate: today, endDate: endOfToday() }
      break

    case DatePeriods.Yesterday:
      range = { startDate: startOfYesterday(), endDate: endOfYesterday() }
      break

    case DatePeriods.This_Week:
      range = { startDate: startOfWeek(today), endDate: endOfToday() }
      break

    case DatePeriods.Last_7_days:
      range = { startDate: sub(today, { days: 7 }), endDate: endOfToday() }
      break

    case DatePeriods.This_Month:
      range = { startDate: startOfMonth(today), endDate: endOfToday() }
      break

    case DatePeriods.Last_30_days:
      range = { startDate: sub(today, { days: 30 }), endDate: endOfToday() }
      break

    case DatePeriods.Last_3_Months:
      range = { startDate: sub(today, { months: 3 }), endDate: endOfToday() }
      break

    case DatePeriods.Last_6_Months:
      range = { startDate: sub(today, { months: 6 }), endDate: endOfToday() }
      break

    case DatePeriods.This_Year:
      range = { startDate: startOfYear(today), endDate: endOfToday() }
      break

    case DatePeriods.Last_Year: {
      const lastYear = sub(today, { years: 1 })
      range = { startDate: startOfYear(lastYear), endDate: endOfYear(lastYear) }
      break
    }

    case DatePeriods.Custom:
      return undefined
  }

  return {
    startDate: fromJSDate(range.startDate, zone),
    endDate: fromJSDate(range.endDate, zone),
  }
}
