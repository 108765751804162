import { FontAwesome } from '@expo/vector-icons'
import { formatMoney } from '@helpers/display'
import { Money } from '@models/Money'
import { DailySummary, selectedUnitName } from '@models/Summary'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ListRenderItemInfo, TouchableOpacity, View } from 'react-native'

import { Text } from '../../../components/elements/Text'
import { useSort } from '../../../hooks/useSort'
import { tableStyles } from './SignInSheets'

import { OfflineTable } from '@/admin/components/OfflineTable/OfflineTable'
import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { isShare } from '@models/Product'
import { memo } from 'react'
import { getProductLink } from './helpers'

type DailyProps = {
  summary: DailySummary
}

type Row = {
  id: string
  name: string
  quantity: number
  unit: string
  total: Money
  sku: string
  templateProductId?: string
}
export const DailySummarySheet = memo(function DailySummarySheet({ summary }: DailyProps) {
  const navigation = useNavigation<StackNavigationProp<any>>()

  const rows: Row[] = []

  summary.items.forEach((item) => {
    item.selectedUnits.forEach((selectedUnit) => {
      const name = item.product.name
      rows.push({
        id: item.product.id,
        name,
        quantity: selectedUnit.quantity,
        unit: selectedUnitName(item, selectedUnit),
        total: selectedUnit.amount,
        sku: isShare(item.product.type) ? item.product.sku || '' : selectedUnit.unit?.sku || '',
        templateProductId: item.product.templateProduct?.id,
      })
    })
  })

  const sorter = useSort<Row>()
  const sortedRows = sorter.apply(rows)

  function SortIcon(field: keyof Row) {
    return (
      <TouchableOpacity style={tableStyles.iconCont} onPress={() => sorter.sortBy(field)}>
        <FontAwesome name="sort" size={16} color={Colors.primaryGray} />
      </TouchableOpacity>
    )
  }
  function Header() {
    return (
      <View style={tableStyles.headerRow}>
        <View style={tableStyles.largeCell}>
          <Text>Product</Text>
          {SortIcon('name')}
        </View>
        <View style={tableStyles.mediumCell}>
          <Text>SKU</Text>
          {SortIcon('sku')}
        </View>
        <View style={tableStyles.smallCell}>
          <Text>Quantity</Text>
          {SortIcon('quantity')}
        </View>
        <View style={tableStyles.mediumCell}>
          <Text>Unit</Text>
          {SortIcon('unit')}
        </View>

        <View style={tableStyles.mediumCell}>
          <Text>Total</Text>
          {SortIcon('total')}
        </View>
      </View>
    )
  }
  const renderItem = ({ item }: ListRenderItemInfo<Row>) => {
    /**
     * Product Name can be a template product name or a product name, if it's a template product name then it should be a link to the template product edit screen. Otherwise it should be a link to the product edit screen.
     */
    const onProductPress = () => getProductLink(navigation, item.id, item.templateProductId)

    return (
      <View style={tableStyles.rowCont}>
        <TouchableOpacity onPress={onProductPress} style={tableStyles.largeCell}>
          <Text color={Colors.blue}>{item.name}</Text>
        </TouchableOpacity>

        <View style={tableStyles.mediumCell}>
          <Text>{item.sku}</Text>
        </View>

        <View style={tableStyles.smallCell}>
          <Text>{item.quantity}</Text>
        </View>
        <View style={tableStyles.mediumCell}>
          <Text>{item.unit}</Text>
        </View>

        <View style={tableStyles.mediumCell}>
          <Text>{formatMoney(item.total)}</Text>
        </View>
      </View>
    )
  }

  return (
    <OfflineTable<Row>
      minWidth={600}
      containerStyle={globalStyles.margin10}
      ListHeaderComponent={<Header />}
      data={sortedRows}
      renderItem={renderItem}
    />
  )
})
