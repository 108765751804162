import { MultiSelectAutocompleteComp } from '@/admin/components/MultiSelectAutocomplete'
import { AdminDrawerParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { useFocusFx } from '@/hooks/useFocusFx'
import { adminFarmIdSelector } from '@/redux/selectors'
import { loadProductFees } from '@api/ProductFees'
import { ToolTips } from '@components'
import { ButtonClear, CheckBox, ErrorText, hideModal, LoadingView, Text, Toast } from '@elements'
import { isNonNullish } from '@helpers/helpers'
import { isFeeProductFee, ProductFee } from '@models/ProductFee'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useFormikContext } from 'formik'
import { memo, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import FormSectionHeader from '../components/FormSectionHeader'
import { BasicInfoFormType } from './BasicInformation'

/** ProductFeeSection is a section in the add or edit product form that allows the user to select taxes or fees for the product */
export const ProductFeeSection = memo(function ProductFeeSection() {
  const { values: formValues, setFieldValue } = useFormikContext<BasicInfoFormType>()
  const farmId = useSelector(adminFarmIdSelector)
  const productFeesFx = useApiFx(loadProductFees, [farmId], !!farmId)
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()

  /** Should update each productFee object inside formValues.fees whenever productFeesFx.data changes during editing product and prevent UI lag or errors. Also, remove all taxes if the product is isTaxExempt. */
  useFocusFx(
    () => {
      if (!productFeesFx.data || !formValues.fees?.length) return
      const updatedFees = formValues.fees
        /** Filter out taxes if the product is tax exempt */
        .filter((fee) => (formValues.isTaxExempt ? isFeeProductFee(fee) : true))
        .map((formFee) => productFeesFx.data?.find((fee) => fee.id === formFee.id))
        .filter(isNonNullish)

      setFieldValue('fees', updatedFees)
    },
    /** Only need to listen to productFeesFx.data and formValues.isTaxExempt changes because its purpose is to up-to-date existing formValues.fees value during editing product, and in the meantime, productFee is updated as well. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productFeesFx.data, formValues.isTaxExempt],
    { noRefocus: true },
  )

  const onProductFeeSelect = useCallback(
    /** product fee select callback
     * @param productFees the registered product fees for the farm
     * @param selectedFee the selected fee to be added to the form
     */
    (productFees: ProductFee[], selectedFee: AutoCompleteItem<ProductFee['id']>) => {
      const selectedId = selectedFee.data

      const selectedFeeInForm = (formValues.fees ?? []).find((formFee) => formFee.id === selectedId)
      if (selectedFeeInForm) {
        Toast(`${selectedFeeInForm.name} was already added`)
        return
      }
      const newProductFee = productFees.find((fee) => fee.id === selectedId)
      if (!newProductFee) {
        Logger.error('A selected fee was not found in the provided array of product fees')
        Toast('Something went wrong while adding this fee. Please contact support')
        return
      }
      setFieldValue('fees', [...(formValues.fees ?? []), newProductFee])
    },
    [setFieldValue, formValues.fees],
  )

  /** product fee removal callback  */
  const onRemoveProductFee = useCallback(
    (feeToRemove: AutoCompleteItem<ProductFee['id']>) => {
      setFieldValue(
        'fees',
        (formValues.fees ?? []).filter((cur) => cur.id !== feeToRemove.data),
      )
    },
    [setFieldValue, formValues.fees],
  )

  return (
    <View>
      <FormSectionHeader title="Taxes & Fees" toolTipId={ToolTips.TAXES_AND_FEES_TITLE} />
      <CheckBox
        title="This product is tax exempt"
        onChange={(val) => setFieldValue('isTaxExempt', val)}
        checked={formValues.isTaxExempt}
        style={styles.exemptCheckBox}
      />
      <View style={globalStyles.marginHorizontal10}>
        <Text style={styles.textPosition}>Choose taxes or fees (Optional)</Text>
        <LoadingView loading={productFeesFx.loading} success={productFeesFx.data} error={productFeesFx.err}>
          {(productFees) => {
            if (productFees.length === 0) {
              return (
                <>
                  <ButtonClear
                    textStyle={styles.createTaxAndFeeBtn}
                    title="Create a tax or fee"
                    onPress={() => {
                      navigation.navigate('FarmSetup', {
                        tab: 'productFees',
                      })
                      /** If this section is opened inside bulkEditor then, we need this to hide Modal */
                      hideModal()
                    }}
                  />
                  <ErrorText style={globalStyles.padding10}>
                    Please click this link and go to Taxes & Fees page to create a tax or fee. Then you can add it to
                    this product.
                  </ErrorText>
                </>
              )
            }

            const nonSelectedItems = productFees
              /** Filter out archived productFees */
              .filter((productFee) => !productFee.archived)
              /** Filter out already selected productFees */
              .filter((productFee) => !formValues.fees?.find((formFee) => formFee.id === productFee.id))
              /** Filter out taxes if the product is tax exempt */
              .filter((fee) => (formValues.isTaxExempt ? isFeeProductFee(fee) : true))
              .map((productFee) => ({ text: productFee.name, data: productFee.id }))

            const selectedItems = (formValues.fees ?? [])
              /** Filter out taxes if the product is tax exempt */
              .filter((fee) => (formValues.isTaxExempt ? isFeeProductFee(fee) : true))
              .map((formFee) => ({
                text: formFee.name,
                data: formFee.id,
              }))

            return (
              <MultiSelectAutocompleteComp
                inline
                onSelect={(selectedFee) => onProductFeeSelect(productFees, selectedFee)}
                onRemove={onRemoveProductFee}
                items={nonSelectedItems}
                autoCompleteId="productFeeSearch"
                placeholder="Search for Tax & Fee"
                selectedItems={selectedItems}
              />
            )
          }}
        </LoadingView>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  textPosition: {
    marginTop: 10,
  },
  exemptCheckBox: {
    paddingLeft: 10,
    alignItems: 'center',
  },
  createTaxAndFeeBtn: { textDecorationLine: 'underline' },
})
