import { StyleSheet, View } from 'react-native'

import { useHasPermission } from '@/hooks/useHasPermission'
import { useDeviceSize, useLayout } from '@/hooks/useLayout'
import { adminFarmSelector } from '@/redux/selectors'
import { ButtonClear, Text } from '@elements'
import { Permission } from '@helpers/Permission'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import HamburgerMenu from '../../components/HamburgerMenu'
import UserDropdown from '../../components/UserDropdown'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { AdminNotifications } from './AdminNotifications'
import { LogoResponsive } from './LogoResponsive'

export const AdminHeader = memo(function AdminHeader() {
  const { top } = useLayout()
  return (
    <View style={[styles.header, { paddingTop: top || 10 }]}>
      <HamburgerMenu isAdmin clear />
      <LogoResponsive style={globalStyles.flex1} url="/admin/dashboard" />
      <CoopMember />
      <AdminNotifications />
      <UserDropdown admin />
    </View>
  )
})

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['100'],
  },
})

/** This component will be used to display COOP Member number and a invitation link to join CO-OP */
function CoopMember() {
  const farm = useSelector(adminFarmSelector)
  const hasPermission = useHasPermission()
  const { isExtraSmallDevice } = useDeviceSize()

  if (!hasPermission(Permission.AccountSetup) || isExtraSmallDevice) return null

  if (!farm.memberId)
    return <ButtonClear size={12} title="Become a GrownBy member-owner" url="https://www.farmgenerations.coop/coop" />

  return (
    <Text color={Colors.green} size={12}>
      CO-OP MEMBER {farm.memberId}
    </Text>
  )
}
