import { germanTownCoords } from '@api/Addresses'
import { validCoords } from '@helpers/coordinate'
import { ArrElement } from '@helpers/typescript'
import { Coordinate } from '@models/Coordinate'
import { RefinementListProvided } from 'react-instantsearch-core'

import { CurrentLocation } from '../../constants/types'

/** Will try to get the coordinates for map, with graceful degradation. Will try params first, else searchLoc, else userLoc */
export function getDefaultCoords(params: {
  userLoc?: CurrentLocation
  searchLoc?: CurrentLocation
  near?: string
}): Coordinate {
  // If we passed a url with a location that takes precedence
  const [lat, lng] = (params.near || ',').split(',')
  const urlCoords = { latitude: Number(lat), longitude: Number(lng) }
  if (validCoords(urlCoords)) return urlCoords

  // If we have searched for a location that follows
  if (params.searchLoc?.coordinate && validCoords(params.searchLoc.coordinate)) return params.searchLoc.coordinate

  // If not we use current location or hearty roots
  return params.userLoc && validCoords(params.userLoc.coordinate)
    ? {
        latitude: params.userLoc.coordinate.latitude,
        longitude: params.userLoc.coordinate.longitude,
      }
    : germanTownCoords
}

/** Category refinement handler */
export const handleRefine = ({
  item,
  listProps,
  updateParam,
  initiallyOn = false,
}: {
  /** toggled item, or hit, originating from connectRefinementList() */
  item: ArrElement<RefinementListProvided['items']>
  listProps: RefinementListProvided
  /** helper that takes a category string and sets it as the navigation "categories" parameter of the explore screen, the string is expected to be a single string that encodes the various categories considered active navigation parameters */
  updateParam: (stringifiedArr: string | undefined) => void
  /** If "initiallyOn", the new categories will be all possible categories except that of the toggled hit; this is what you would want if all options were assumed to be initially "on". If false, the new categories will be only the toggled hit */
  initiallyOn?: boolean
}) => {
  const { items, currentRefinement } = listProps
  if (currentRefinement.length || !initiallyOn) {
    //Item.value already holds the new currentRefinement array you would need, if you were to toggle this item
    const newParam: string | undefined = item.value.join(',')
    //If toggling the only item selected, value will be empty string (''). This should be handled by VirtualParamRefinement to clear refinement.
    updateParam(newParam)
  } else
    updateParam(
      items
        .filter((itm) => itm.label !== item.label)
        .map((itm) => itm.label)
        .join(','),
    )
}
