import { loadFarm, searchFarmByName } from '@api/Farms'
import { TextInputBox } from '@components'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { openUrl } from '@helpers/client'
import { AlgoliaGeoDoc, AlgoliaGeoFarm, FILTERS } from '@models/Algolia'
import { Farm, FarmStatus } from '@models/Farm'
import { isFarmAdmin, SignInProviders } from '@models/User'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as Linking from 'expo-linking'
import { useEffect, useState } from 'react'
import { ActivityIndicator, FlatList, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '../../../components/elements/Button'
import { Divider } from '../../../components/elements/Divider'
import { Icon } from '../../../components/elements/Icon/Icon'
import { Toast } from '../../../components/elements/Overlays/Popups'
import { TextH1, TextH4 } from '../../../components/elements/Text'
import Colors from '../../../constants/Colors'
import { isLargeDevice, isSmallDevice } from '../../../constants/Layout'
import { globalStyles } from '../../../constants/Styles'
import { Layout } from '../../../constants/types'
import { useDebouncedValue } from '../../../hooks/useDebounce'
import { withAuth } from '../../../hooks/withAuth'
import { FarmerWalkthroughParamList } from '../../../navigation/types'
import { loadFarmAssociations } from '../../../redux/actions/appPersist'
import { farmsSelector, layoutSelector, userSelector } from '../../../redux/selectors'
import { adminCard } from '../../constants/AdminCards'

interface AdminRegisterProps {
  navigation: StackNavigationProp<FarmerWalkthroughParamList, 'RegisterFarm'>
  route: RouteProp<FarmerWalkthroughParamList, 'RegisterFarm'>
}

function AdminRegisterScreen({ navigation, route }: AdminRegisterProps) {
  const dispatch = useDispatch()
  const [auth, setAuth] = useState(false)

  const farmAssociations = useSelector(farmsSelector)
  const layout = useSelector(layoutSelector)

  const user = useSelector(userSelector)
  const [farm, setFarm] = useState<Farm>()
  const [results, setResults] = useState<AlgoliaGeoDoc<AlgoliaGeoFarm>[]>([])

  const [query, setQuery] = useState<string>('')
  const debouncedQuery = useDebouncedValue(query, 500)

  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageTotal, setPageTotal] = useState<number>(0)
  const [totalHits, setTotalHits] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const resetParameters = () => {
    setFarm(undefined)
    setQuery('')
    setResults([])
  }
  const onClose = () => {
    setFarm(undefined)
  }

  const handleClaim = async () => {
    if (!farm) {
      return Toast('Could not load farm')
    }

    for (const association of farmAssociations.filter(isFarmAdmin)) {
      if (association.id === farm.id && isFarmAdmin(association)) {
        Toast('You are already an admin for this farm, loading it now.')
        const url = Linking.createURL('/admin/welcome')
        openUrl(url)
        return
      }
    }
    navigation.navigate('ClaimScreen', { farmSlug: farm.urlSafeSlug })
  }

  //Handles navigating here from AddFarmScreen, after adding a new farm. Will receive the newly added farm in route.param
  useEffect(() => {
    if (route.params?.farm) setFarm(route.params?.farm)
  }, [route.params])

  useEffect(() => {
    if (user.email !== '') {
      dispatch(loadFarmAssociations(user.id))
      setAuth(true)
    }
  }, [])

  const loadResults = async (name: string, page: number) => {
    setLoading(true)
    const searchResults = await searchFarmByName(name, page, [
      FILTERS.NotHidden,
      FILTERS.Farm,
      FILTERS.NotRegistered,
      FILTERS.NotInactiveFarm,
    ])
    setPageTotal(searchResults.nbPages)
    setTotalHits(searchResults.nbHits)
    setResults(searchResults.hits ?? [])
    setLoading(false)
    if (searchResults.nbPages <= pageIndex && searchResults.nbPages > 0) {
      setPageIndex(searchResults.nbPages - 1)
    }
  }

  useEffect(() => {
    if (debouncedQuery) loadResults(debouncedQuery, pageIndex)
  }, [debouncedQuery, pageIndex])

  useEffect(() => {
    return () => {
      resetParameters() //do this upon unmounting
      // replaces old X button in favor of a faster back navigation
    }
  }, [])

  //When we select a result from algolia, we get the firebase farm.
  ////Why not just use algolia farm type? Because the farm variable also needs to support receiving a Farm in route.param.farm
  const setFarmFromResults = async (algoliaFarm: AlgoliaGeoDoc<AlgoliaGeoFarm>) => {
    const fbFarm = await loadFarm(algoliaFarm.id)
    setFarm(fbFarm)
  }

  if (!auth) return null
  return (
    <View style={{ flex: 1, maxHeight: layout.height - (isSmallDevice(layout) ? 70 : 80) }}>
      <View style={styles.container}>
        {!farm && (
          <View
            style={
              isLargeDevice()
                ? { flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }
                : { flexDirection: 'column', marginTop: 10 }
            }
          >
            <TextH1 style={{ fontStyle: 'normal', fontSize: 24, lineHeight: 29 }}>
              Search for your farm on GrownBy
            </TextH1>
            <TouchableOpacity
              style={styles.missingFarmButton}
              onPress={() => navigation.navigate('AddFarmScreen', { fromInvite: false })}
            >
              <TextH4
                style={{
                  fontStyle: 'normal',
                  fontSize: 18,
                  lineHeight: 24,
                  color: Colors.green,
                  textDecorationLine: 'underline',
                }}
              >
                Can't find your farm? Register it.
              </TextH4>
            </TouchableOpacity>
          </View>
        )}
      </View>
      {!farm ? (
        // Show the search input if no farm is selected
        <View style={{ flex: 1, marginHorizontal: '2%' }}>
          <TextInputBox
            placeholder="Search for your farm"
            onChangeText={setQuery}
            value={query}
            rightIcon={
              debouncedQuery !== query || loading ? <ActivityIndicator color={Colors.green} size="large" /> : undefined
            }
          />
          {pageTotal > 1 && (
            <>
              <View style={styles.searchResultMetaBar}>
                <View style={[styles.searchPageButtonContainer, globalStyles.alignCenter]}>
                  <TouchableOpacity
                    disabled={pageIndex === 0}
                    onPress={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                  >
                    <MaterialCommunityIcons
                      name="arrow-left"
                      size={24}
                      color={pageIndex === 0 ? Colors.shades[200] : Colors.shades[600]}
                    />
                  </TouchableOpacity>
                  <Text style={{ fontWeight: 'bold' }}>
                    Page {pageIndex + 1} / {pageTotal} :
                  </Text>
                  <Text>{totalHits} farms found</Text>
                  <TouchableOpacity
                    disabled={pageIndex === pageTotal - 1}
                    onPress={() => {
                      setPageIndex(pageIndex + 1)
                    }}
                  >
                    <MaterialCommunityIcons
                      name="arrow-right"
                      size={24}
                      color={pageIndex === pageTotal - 1 ? Colors.shades[200] : Colors.shades[600]}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <Text
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 14,
                  lineHeight: 24,
                  color: Colors.shades[300],
                }}
              >
                Results from Google
              </Text>
            </>
          )}
          {!!query && query === debouncedQuery && !loading && (
            <FlatList
              data={results}
              keyExtractor={(item) => item.id}
              renderItem={({ item }) =>
                FarmResult({ algoliaFarm: item, onPress: setFarmFromResults, smallerScreen: isSmallDevice })
              }
              ItemSeparatorComponent={() => <Divider top={1} bottom={1} />}
              ListEmptyComponent={() => (
                <Text style={[styles.errMsg, styles.farmResultTitle, globalStyles.margin20]}>No farms found</Text>
              )}
            />
          )}
          {!!query && loading && <ActivityIndicator color={Colors.green} />}
        </View>
      ) : (
        //Show the farm details if a farm is selected
        <ScrollView>
          <View style={[adminCard(layout), { alignSelf: 'center', minHeight: 300 }]}>
            <Icon name="times" size={30} onPress={onClose} style={{ position: 'absolute', top: 15, left: 15 }} />
            <View
              style={{
                flexDirection: isLargeDevice() ? 'row' : 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginHorizontal: 32,
              }}
            >
              <View style={{ flexDirection: 'column', width: isLargeDevice() ? '45%' : '100%' }}>
                <TextH1
                  style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 24, lineHeight: 29, marginBottom: 16 }}
                >
                  {farm.status !== FarmStatus.Listed && farm.status !== FarmStatus.Pending
                    ? 'This farm already has an Admin.'
                    : 'This farm is claimable'}
                </TextH1>
                <View>
                  <Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: 18,
                      lineHeight: 24,
                      marginBottom: 10,
                    }}
                  >
                    By claiming this farm, you certify that you are the owner or operator of this farm.
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', width: isLargeDevice() ? '45%' : '100%' }}>
                <View style={{ marginBottom: 32 }}>
                  <Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: 24,
                      lineHeight: 24,
                      marginBottom: isLargeDevice() ? 32 : 0,
                    }}
                  >
                    {farm.name}
                  </Text>
                  <Text style={styles.cardAddress}>{farm.address.street1}</Text>
                  <Text style={styles.cardAddress}>
                    {farm.address.city}, {farm.address.state} {farm.address.zipcode}
                  </Text>
                </View>
                <Button
                  title="Claim this farm"
                  disabled={farm.status !== FarmStatus.Listed && farm.status !== FarmStatus.Pending}
                  onPress={handleClaim}
                  style={buttonNav(layout)}
                />
              </View>
            </View>
          </View>
          <View style={[styles.containerRow, { marginTop: 20, justifyContent: 'center' }]}>
            <View style={{ flexDirection: isSmallDevice() ? 'column' : 'row' }}>
              <TextH4 style={[styles.helpText, { fontWeight: 'bold' }]}>Need help? </TextH4>
              <View style={globalStyles.flexRow}>
                <TextH4 style={styles.helpText}>Contact </TextH4>
                <TextH4 style={[styles.helpText, { color: Colors.green, textDecorationLine: 'underline' }]}>
                  support@grownby.app.
                </TextH4>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </View>
  )
}

export default withAuth(AdminRegisterScreen, {
  provider: SignInProviders.Email,
  type: 'signup',
  noSafeAreaInsets: true,
})

const buttonNav = (layout: Layout) => ({
  width: layout.width / 3,
  maxWidth: 200,
})

function FarmResult({
  algoliaFarm,
  onPress,
  smallerScreen,
}: {
  algoliaFarm: AlgoliaGeoDoc<AlgoliaGeoFarm>
  onPress(arg0: AlgoliaGeoDoc<AlgoliaGeoFarm>): void
  smallerScreen: () => boolean
}) {
  return (
    <TouchableOpacity
      key={algoliaFarm.id}
      style={[
        styles.farmResultContainer,
        { justifyContent: 'space-between', flexDirection: smallerScreen() ? 'column' : 'row' },
      ]}
      onPress={() => onPress(algoliaFarm)}
    >
      <View style={styles.farmResultText}>
        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.farmResultTitle}>
          {algoliaFarm.name}
        </Text>
        <Text numberOfLines={1} ellipsizeMode="tail">
          {algoliaFarm.address.street1}
        </Text>
        <Text numberOfLines={1} ellipsizeMode="tail">
          {algoliaFarm.address.city}, {algoliaFarm.address.state} {algoliaFarm.address.zipcode}
        </Text>
      </View>

      <View style={smallerScreen() ? { marginTop: 10, justifyContent: 'center' } : { justifyContent: 'center' }}>
        {algoliaFarm.farm.status === FarmStatus.Listed ? (
          <Button
            title="Claim this farm"
            outline
            textStyle={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 14, lineHeight: 17, color: Colors.green }}
            style={{ backgroundColor: Colors.white }}
            onPress={() => onPress(algoliaFarm)}
          />
        ) : (
          <Text style={styles.statusText}>{`Status: ${algoliaFarm.farm.status}`}</Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: '2%',
  },
  containerRow: {
    flexDirection: 'row',
  },
  errMsg: {
    color: Colors.red,
    fontSize: 12,
    marginHorizontal: 10,
  },
  contactButton: {
    marginHorizontal: 8,
  },
  buttonWithBorder: {
    borderColor: Colors.black,
    borderWidth: 1,
    borderRadius: 10,
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonRow: {
    alignSelf: 'center',
  },
  farmInformation: {
    textAlign: 'center',
    lineHeight: 24,
    fontSize: 16,
  },
  missingFarmButton: {
    fontSize: 16,
    margin: 4,
    marginBottom: 20,
  },
  farmResultContainer: {
    marginVertical: 8,
    paddingVertical: 12,
    margin: 4,
    flexDirection: 'row',
  },
  farmResultText: {
    marginRight: 56,
  },
  farmResultTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  milesText: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 4,
  },
  searchResultMetaBar: {
    padding: 5,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.semiTransparent,
    height: 50,
  },
  searchPageButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: 20,
  },
  input: {
    height: 48,
    width: 300,
    backgroundColor: Colors.transparent,
    fontSize: 16,
    borderRadius: 14,
  },
  cardAddress: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 24,
    lineHeight: 29,
  },
  helpText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 17,
  },
  statusText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    color: Colors.green,
    marginHorizontal: 10,
    marginVertical: 5,
    paddingHorizontal: 20,
  },
})
