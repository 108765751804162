import { getOrderNum } from '@helpers/display'
import { format } from '@helpers/time'
import { Order } from '@models/Order'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

// import CustomImage from '../../components/Image'
import { ItemList } from './ItemList'
import { Divider } from '../../components/elements/Divider'
import { Text, TextH1, typography } from '../../components/elements/Text'
import Colors from '../../constants/Colors'

type PropTypes = {
  order: Order
  goToOrderDetails: (order: Order) => void
}

type OrderInfoType = {
  title: string
  value: string | number
}

export function OrderCard({ order, goToOrderDetails }: PropTypes) {
  const farm = order.farm

  function OrderInfo({ title, value }: OrderInfoType) {
    return (
      <View style={styles.orderInfo}>
        <Text size={12} type="regular" style={styles.orderInfoTitle}>
          {title}
        </Text>
        <Text size={14} type="regular" style={{ fontFamily: typography.body.regular }}>
          {value}
        </Text>
      </View>
    )
  }
  const isEmpty = order.items.every((item) => item.cancelled)

  return (
    <View style={styles.card}>
      <View style={styles.info}>
        <View style={styles.farmInfo}>
          {/* TODO: We don't have the farm logo in order docs currently */}
          {/* <CustomImage type="logo" style={styles.farmLogo} source={{ uri: farm?.logo }} /> */}
          <TextH1 numberOfLines={2} size={18}>
            {farm.name}
          </TextH1>
          {isEmpty && (
            <View style={styles.cancelledWarning}>
              <Text color={Colors.white}>Order Cancelled</Text>
            </View>
          )}
        </View>
        <View style={styles.otherInfo}>
          <OrderInfo title="Order placed" value={format(order.date, 'MMM do yyyy')} />
          <OrderInfo title="# of items" value={order.items.length} />
          <View style={[styles.orderInfo, { marginRight: 0 }]}>
            <Text size={12} type="regular" style={styles.orderInfoTitle}>
              Order {getOrderNum(order.orderNum)}
            </Text>
            <TouchableOpacity onPress={() => goToOrderDetails(order)}>
              <Text type="bold" style={styles.orderInfoAction}>
                View Order
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {!isEmpty ? (
        <>
          <Divider />
          <ItemList title="Order items" items={order.items} style={styles.items} />
        </>
      ) : (
        <View />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginVertical: 16,
    marginHorizontal: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
    flexWrap: 'wrap',
  },
  info: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 32,
  },
  farmInfo: {
    alignItems: 'center',
    maxWidth: '100%',
    marginRight: 'auto',
  },
  otherInfo: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  // farmLogo: {
  //   width: 58,
  //   height: 58,
  //   marginRight: 15,
  //   borderRadius: 30,
  // },
  orderInfo: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 60,
    marginTop: 10,
  },
  orderInfoTitle: {
    fontFamily: typography.body.regular,
    marginBottom: 4,
    color: Colors.primaryGray,
  },
  orderInfoAction: {
    color: Colors.green,
  },
  items: {
    paddingHorizontal: 32,
    paddingBottom: 16,
  },
  cancelledWarning: {
    backgroundColor: Colors.red,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 100,
    marginTop: 10,
  },
})
