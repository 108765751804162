import { radiusFromRegion } from '@helpers/coordinate'
import { removeUndefined } from '@helpers/helpers'
import { MapFilters } from '@models/Algolia'
import { Features } from '@models/Features'
import { DateTime } from 'luxon'
import { ScaledSize } from 'react-native'
import { Dispatch } from 'redux'

import { EdgeInsets } from 'react-native-safe-area-context'
import { CurrentLocation } from '../../constants/types'
import { CartService } from '../../constants/types/cartService'
import { FarmCachedData, NavParams } from '../../constants/types/navParams'
import { AppState, RootState } from '../reducers/types'
import {
  ADD_NAV_PROP,
  SET_ADMIN_CART_SERVICE,
  SET_ADMIN_OPEN,
  SET_CART_SERVICE,
  SET_CURRENT_HOVER,
  SET_FEATURES_AVAILABLE,
  SET_LAYOUT,
  SET_LISTENERS,
  SET_NAV_PROPS,
  SET_NAV_ROUTE,
  SET_REGION,
  SET_SEARCH_FILTERS,
  SET_SEARCH_LOCATION,
} from './actionTypes'

/** Used by the map on change, to set the current region, which is then used by other actions */
export const setRegionAction = (region: AppState['region']) => ({ type: SET_REGION, payload: region })

/** Sets or partially overwrites the global search location. It may be the user location or some other location the user searched for */
export const setSearchLocation =
  (
    newLocation: Partial<Omit<CurrentLocation, 'timestamp'>> & {
      maxRadius?: number
    },
  ) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    //Adds the max radius to the search location
    // The radius is used for limiting algolia results to that area around the coords
    // The radius depends on the region, which should be set by the map, on change
    const region = getState().appState.region
    const maxRadius = newLocation.maxRadius ?? (region ? radiusFromRegion(region) : undefined)
    const payload: Partial<CurrentLocation> & {
      maxRadius?: number
    } = removeUndefined({ ...newLocation, maxRadius, timestamp: DateTime.now().toMillis() })

    dispatch({
      type: SET_SEARCH_LOCATION,
      payload,
    })
  }

export const setCurrentHover = (payload: string | null) => ({
  type: SET_CURRENT_HOVER,
  payload,
})

export const setSearchFilter = (payload: MapFilters) => ({
  type: SET_SEARCH_FILTERS,
  payload,
})

/** Merges a partial set of nav params with the existing nav params */
export const addNavProp = (prop: Partial<NavParams>) => ({
  type: ADD_NAV_PROP,
  payload: prop,
})

/** Completely replaces the current nav params with the provided nav params. If undefined value is passed, will reset to default (empty) navParams value */
export const setNavProps = (navProps?: NavParams) => ({
  type: SET_NAV_PROPS,
  payload: navProps,
})

/** Updates the farm cache data for a farm. The partial data will be shallow merged */
export const updateFarmCache =
  (farmSlug: string, partialDataUpdate: Partial<FarmCachedData>) => (dispatch: Dispatch, getState: () => RootState) => {
    const time = DateTime.now()
    const cache = getState().appState.navParams.farmCache
    const cachedFarmData = cache[farmSlug]
    const cacheUpdate: NavParams['farmCache'] = { [farmSlug]: { ...cachedFarmData, time, ...partialDataUpdate } }
    dispatch(addNavProp({ farmCache: { ...cache, ...cacheUpdate } }))
  }

/** Sets the global navigation route (The currently focused screen) */
export const setNavRoute = (route: string) => ({
  type: SET_NAV_ROUTE,
  payload: route,
})

/** - isAdminOpen must be run anywhere in the app before a navigation action that goes from a non-admin screen to a admin screen.
 * - the admin layout depends on this variable to subtract the width of the admin sidebar from the screen width
 */
export const isAdminOpen = (value: boolean) => ({
  type: SET_ADMIN_OPEN,
  payload: value,
})

/** Sets the global layout data. The new state is intended to replace entirely the current state
 * @param layout should come from merging the result of useWindowDimensions and useSafeAreaInsets
 */
export const setLayout = (layout: ScaledSize & EdgeInsets) => ({
  type: SET_LAYOUT,
  payload: layout,
})

export const setListeners = (value: boolean) => ({
  type: SET_LISTENERS,
  payload: value,
})

/** Sets the cart service for either the consumer or admin cart */
export const setCartService = (service: CartService, admin = false) => ({
  type: admin ? SET_ADMIN_CART_SERVICE : SET_CART_SERVICE,
  payload: service,
})

/** Sets the available features for the current farm in consumer screens */
export const setFeaturesAvailable = (featuresAvailable: Record<Features, boolean>) => ({
  type: SET_FEATURES_AVAILABLE,
  payload: featuresAvailable,
})
