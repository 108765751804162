import { FormBuilder, ToolTips } from '@components'
import { Button, ButtonClear, DEPRECATED_FormMoneyInput } from '@elements'
import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { createId, hasOwnProperty, isArray } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { Optional, PartialPick } from '@helpers/typescript'
import { Infinite, isMoney, Money, Zero } from '@models/Money'
import {
  DigitalProduct,
  hasGlobalStock,
  hasUnits,
  hasUnitStock,
  isShare,
  Product,
  ProductType,
  Standard,
  StockType,
  Unit,
  UnitBase,
} from '@models/Product'
import { useFormikContext } from 'formik'
import { useMemo, useRef } from 'react'
import { View } from 'react-native'
import { Input } from 'react-native-elements'
import { CreateResponsiveStyle } from 'rn-responsive-styles'
import * as Yup from 'yup'

import FormSectionHeader from '../components/FormSectionHeader'
import FormWrapper from '../components/FormWrapper'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'

import InputLabel from '@/admin/components/InputLabel'
import FormikArray, { RenderType } from '@/admin/components/elements/FormikArray'
import { globalStyles } from '@/constants/Styles'
import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import { useDeviceSize } from '@/hooks/useLayout'
import { UnitsFormType } from './UnitsComponent'

/** sku is required on the type but optional in the form because it is built with the prefix. */
type FormUnit = Optional<Unit, 'sku'>

type PricingFormType = {
  /** form's "buyingOptions" holds the UnitProduct['units'] array data */
  buyingOptions: FormUnit[]
  unitStock?: Standard['unitStock']
  /** the sku value in this form will be dedicated to the global sku for the given product type. If type is share, it will be the Share['sku']. If it is standard or digital, it will be the StandardCommon['unitSkuPrefix'] */
  sku?: string
}

const pricingValidation: Yup.ObjectSchema<Optional<PricingFormType, 'buyingOptions'>> = Yup.object().shape({
  sku: Yup.string(),
  unitStock: Yup.boolean(),
  // Should only validate when we are on advanced pricing
  buyingOptions: Yup.array()
    .of(
      Yup.object<Unit>().shape({
        id: Yup.string().required(),
        name: Yup.string().required('Name is required'),
        sku: Yup.string().optional(),
        quantity: Yup.number().when('$unitStock', {
          is: true,
          then: () => YUP_WHOLE_NUMBER_REAL('Quantity', { allowDecimal: true, allowZero: true }),
        }),
        multiplier: Yup.number()
          .min(0.001, 'Multiplier must be greater than 0')
          .required('Multiplier is required')
          .typeError('Multiplier must be a number'),
        prices: Yup.array()
          .of(
            Yup.object<Unit['prices']>().shape({
              id: Yup.string().required(),
              name: Yup.string().required('Price Name is required'),
              amount: YUP_MONEY_REQUIRED('Price', { allowZero: true }),
              maxCount: Yup.number(),
              skuSuffix: Yup.string(),
            }),
          )
          .required(),
      }),
    )
    .when('$type', {
      is: (type: ProductType) => hasUnits({ type }),
      then: (schema) => schema.min(1, 'You must specify a buying option'),
      otherwise: (schema) => schema,
    }),
})

/** removes the StandardCommon['unitSkuPrefix'] from the concatenated unit.sku to display correct data in the screen */
export const noPrefixUnitSku = (product: Standard | DigitalProduct, unit: Unit): string => {
  if (product.type === ProductType.FarmBalance) {
    return ''
  }
  if (product.unitSkuPrefix) {
    const prefix = product.unitSkuPrefix
    return unit.sku.replace(prefix + '-', '')
  } else {
    return unit.sku
  }
}

//create independent newUnit every time
const newUnitCreator = (multiplier?: number, amount?: Money): Unit => {
  const newUnit = {
    id: createId(),
    name: '',
    sku: '',
    multiplier: multiplier ? multiplier : 0,
    quantity: undefined,
    prices: [
      {
        id: createId(),
        name: 'default',
        amount: amount ? amount : Infinite,
        maxCount: undefined,
        skuSuffix: '',
      },
    ],
  }
  return newUnit
}

const toFormik = (product: PartialPick<Product, 'type'>): PricingFormType => {
  // If it is a share , we stop here and return this
  if (isShare(product)) return { buyingOptions: [] }

  // This case handles product creation, for which there's no units yet
  if (!hasOwnProperty(product, 'units') || !isArray(product.units) || !product.units.length) {
    return {
      buyingOptions: [newUnitCreator(1)],
    }
  }

  return {
    buyingOptions: (product as Standard).units.map((unit) => {
      return {
        id: unit.id,
        name: unit.name,
        sku: noPrefixUnitSku(product as Standard, unit),
        quantity: hasUnitStock(product) ? unit.quantity : undefined,
        multiplier: unit.multiplier,
        prices: unit.prices.map((price) => ({
          id: price.id,
          name: price.name,
          amount: price.amount,
          maxCount: price.maxCount,
          skuSuffix: price.skuSuffix,
        })),
      }
    }),
  }
}

function fromFormik<
  T extends Optional<PricingFormType, 'buyingOptions'> & Pick<UnitsFormType, 'pricePerUnit'> & { type?: ProductType },
>(values: T): Partial<Product> {
  const base: PartialPick<Product, 'type'> = {
    type: values.type!,
  }
  // If this product type shouldn't have units, then don't return anything
  if (isShare(base)) return {}

  if (hasUnits(base)) {
    const newUnits: Unit[] = values.buyingOptions!.map((buyingOption) => {
      // Since SKU is not required in the form, we default to using the multiplier as the SKU
      const buyingOptSku = buyingOption.sku || String(buyingOption.multiplier)
      return {
        id: buyingOption.id,
        name: buyingOption.name,
        sku: values.sku ? values.sku + '-' + buyingOptSku : buyingOptSku, // combine unitSkuPrefix(values.sku) and buyingOption.sku and re-save it in sku(in the unit) to the firestore
        quantity: values.unitStock ? Number(buyingOption.quantity!) : undefined,
        multiplier: Number(buyingOption.multiplier),
        prices: buyingOption.prices.map((price) => ({
          id: price.id,
          name: price.name,
          amount: MoneyCalc.round(price.amount),
          maxCount: price.maxCount,
          skuSuffix: price.skuSuffix,
        })),
      }
    })

    base.units = hasGlobalStock(base)
      ? (newUnits as UnitBase<StockType.Global>[])
      : (newUnits as UnitBase<StockType.Unit>[])

    return base
  }

  return {}
}

export const FormikPricing = new ProductFormikComponent(pricingValidation, toFormik, fromFormik)

export default function AdvancedPricingComponent() {
  const firstRenderIsDone = useRef(false)

  const { values: formValues, setFieldValue } = useFormikContext<
    PricingFormType & Pick<UnitsFormType, 'pricePerUnit'> & { type?: ProductType }
  >()

  /** When pricePerUnit value changes, all buyingOption amount will be re-calculate, and buyingOption amount is editable. This useEffect will only have functionality after first render is done and pricePerUnit change. */
  useDeepCompareFocusFx(
    () => {
      if (firstRenderIsDone.current) {
        const newBuyingOptions: FormUnit[] = formValues.buyingOptions.map((buyingOption) => ({
          ...buyingOption,
          prices: [
            {
              ...buyingOption.prices[0],
              amount: MoneyCalc.multiply(formValues.pricePerUnit ?? Zero, Number(buyingOption.multiplier) || 0),
            },
          ],
        }))
        setFieldValue('buyingOptions', newBuyingOptions)
      } else {
        firstRenderIsDone.current = true
      }
    },
    // This only need to listen formValues.pricePerUnit change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues.pricePerUnit],
    { noRefocus: true },
  )

  return (
    <FormWrapper>
      <FormSectionHeader title="Buying Options & Prices" />

      <FormikArray<PricingFormType, 'buyingOptions'>
        name="buyingOptions"
        renderItem={({ formik, arrayHelpers, index }) => (
          <RenderItem
            key={`units[${index}]`}
            formik={formik}
            arrayHelpers={arrayHelpers}
            index={index}
            formValues={formValues}
            firstRenderIsDone={firstRenderIsDone.current}
          />
        )}
      />
    </FormWrapper>
  )
}

type RenderItemProps = {
  formik: RenderType<PricingFormType, 'buyingOptions'>['formik']
  arrayHelpers: RenderType<PricingFormType, 'buyingOptions'>['arrayHelpers']
  index: number
  firstRenderIsDone: boolean
  formValues: PricingFormType &
    Pick<UnitsFormType, 'pricePerUnit'> & {
      type?: ProductType | undefined
    }
}

function RenderItem({ formik, arrayHelpers, index, formValues, firstRenderIsDone }: RenderItemProps) {
  const { values: boValues, handleChange, showError, setFieldValue, handleBlur } = formik
  const isFirst = index === 0
  const { isLargeDevice } = useDeviceSize()
  // This will tell app in what conditions to show the label for AdvancedPricingComponent
  const showLabel = isFirst || !isLargeDevice
  const canDelete = formValues.buyingOptions.length > 1

  const styles = useStyles()

  /** This will be handle to only calculate single row calculation when multiplier value change and only run after first render */
  useFocusFx(() => {
    if (firstRenderIsDone && formValues.unitStock && isMoney(formValues.pricePerUnit)) {
      const multiplier = boValues.multiplier || 0
      setFieldValue('prices[0].amount', MoneyCalc.multiply(formValues.pricePerUnit, multiplier))
    }
    // Only listen multiplier change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boValues.multiplier])

  const priceLabel = useMemo(
    () => (
      <InputLabel
        label={`Price${formValues.unitStock ? ' (calculated-editable)' : '*'}`}
        tooltipId={formValues.unitStock ? ToolTips.PRICE_OPTION : ToolTips.PRICE_GLOBAL}
        tooltipTitle="Price"
      />
    ),
    [formValues.unitStock],
  )

  return (
    <View key={`units[${index}]`}>
      {formValues.type === ProductType.FarmBalance ? (
        <FormBuilder row style={styles.buyingOptionContainer}>
          <DEPRECATED_FormMoneyInput
            value={boValues.prices[0].amount}
            label={isFirst ? <InputLabel label="Credit options" required /> : undefined}
            onChangeText={(value) => {
              setFieldValue('multiplier', 1)
              setFieldValue('prices[0].amount', value)
              setFieldValue('name', `$${value.value / 100}`)
            }}
            errorMessage={isFirst ? showError('prices[0].amount') : showError('prices[0].amount', false)}
            onBlur={handleBlur('prices[0].amount')}
          />
          {canDelete && (
            <ButtonClear
              icon="times"
              size={14}
              style={styles.removeBtn}
              onPress={() => {
                arrayHelpers.remove(index)
              }}
            />
          )}
        </FormBuilder>
      ) : (
        <FormBuilder row style={styles.buyingOptionContainer}>
          <Input
            placeholder="1 Pound"
            label={
              showLabel ? (
                <InputLabel label="Buying Option Name" tooltipId={ToolTips.OPTION_NAME} required />
              ) : undefined
            }
            onChangeText={handleChange('name')}
            value={boValues.name}
            errorMessage={showLabel ? showError('name') : showError('name', false)}
            onBlur={handleBlur('name')}
          />
          <Input
            placeholder="1"
            label={showLabel ? <InputLabel label="Units Each" tooltipId={ToolTips.OPTION_UNIT} required /> : undefined}
            value={boValues.multiplier.toString()}
            onChangeText={handleChange('multiplier')}
            errorMessage={showLabel ? showError('multiplier') : showError('multiplier', false)}
            onBlur={handleBlur('multiplier')}
          />
          <DEPRECATED_FormMoneyInput
            containerStyle={formValues.unitStock ? { flex: 1.1 } : globalStyles.flex1}
            value={boValues.prices[0].amount}
            label={showLabel ? priceLabel : undefined}
            onChangeText={(value) => setFieldValue('prices[0].amount', value)}
            errorMessage={showLabel ? showError('prices[0].amount') : showError('prices[0].amount', false)}
            onBlur={handleBlur('prices[0].amount')}
          />
          <Input
            placeholder="123456"
            label={showLabel ? <InputLabel label="SKU" tooltipId={ToolTips.OPTION_SKU} /> : undefined}
            onChangeText={handleChange('sku')}
            value={boValues.sku}
            errorMessage={showError('sku')}
            onBlur={handleBlur('sku')}
          />
          {!!formValues.unitStock && (
            <Input
              onChangeText={handleChange('quantity')}
              value={boValues.quantity ? boValues.quantity.toString() : ''}
              placeholder="Eg. 10"
              label={showLabel ? <InputLabel label="In Stock" tooltipId={ToolTips.IN_STOCK} required /> : undefined}
              errorMessage={showLabel ? showError('quantity') : showError('quantity', false)}
              onBlur={handleBlur('quantity')}
            />
          )}
          {canDelete && (
            <ButtonClear icon="times" size={14} style={styles.removeBtn} onPress={() => arrayHelpers.remove(index)} />
          )}
        </FormBuilder>
      )}
      {index === formValues.buyingOptions.length - 1 && (
        <Button
          title="Add buying option"
          icon="plus"
          outline
          style={{ width: 250 }}
          onPress={() => arrayHelpers.push(newUnitCreator(undefined, formValues.unitStock ? Zero : undefined))}
        />
      )}
    </View>
  )
}

const useStyles = CreateResponsiveStyle({
  buyingOptionContainer: {
    alignItems: 'flex-start',
    marginVertical: 5,
    flex: 1,
  },
  removeBtn: {
    alignSelf: 'flex-end',
    marginBottom: 10,
  },
  colorCircle: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginVertical: 'auto',
  },
  pricesContainer: {
    paddingLeft: 10,
  },
  pricing: {
    alignItems: 'flex-start',
  },
})
