import { Text, Touchable } from '@elements'
import { formatMiniDate, formatMoney, getOrderNum, plural } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { orderItemTotal } from '@helpers/order'
import { Zero } from '@models/Money'
import { Order, orderStatusText } from '@models/Order'
import { isStandard } from '@models/Product'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleSheet, TextStyle } from 'react-native'

import { CustomerParamList } from '@/admin/navigation/types'
import Colors from '@/constants/Colors'

//data loaded for main layer ExpandableRow component in offlineTable
export const columnsElementsGen = (navigation: StackNavigationProp<CustomerParamList>) => {
  const totalProcess = (order: Order) => {
    const total = order.items
      .map((itm) => {
        //calculate total price, multiPickup Standard products should consider numPickups
        if (isStandard(itm.product)) {
          return orderItemTotal(itm)
        }
        return orderItemTotal(itm)
      })
      .reduce((a, b) => MoneyCalc.add(a, b), Zero)

    return formatMoney(total)
  }
  const orderProcess = (order: Order) => {
    const orderNum = order.orderNum
    return <Text style={styles.textMargin}>{getOrderNum(orderNum)}</Text>
  }
  const orderDateProcess = (order: Order) => {
    const orderDate = order.date
    return <Text style={styles.textMargin}>{formatMiniDate(orderDate)}</Text>
  }
  const orderItemsNumProcess = (order: Order) => {
    const orderItemsNum = order.items.length
    return <Text>{orderItemsNum + plural(orderItemsNum, ' item')}</Text>
  }

  const pickupLocationProcess = (order: Order) => {
    //Filter digital orderItems because they don't have a pickup location
    const orderItemsWithoutDigital = order.items.filter((itm) => {
      return !!itm?.distribution?.location.name
    })
    //Get the first orderItems's distribution location name
    const locationName = orderItemsWithoutDigital[0]?.distribution?.location.name
    const locationId = orderItemsWithoutDigital[0]?.distribution?.location.id
    //Check if all orderItems' distribution locations are the same, if there is one different, then it is multiple pickups Locations
    let allOrderItemsLocationsAreEqual = true
    orderItemsWithoutDigital.forEach((item) => {
      if (item?.distribution?.location.id !== (locationId as string)) {
        allOrderItemsLocationsAreEqual = false
      }
    })

    //this means the all orderItems in this order are digital products, so no pickup location
    if (orderItemsWithoutDigital.length === 0) {
      return 'No pickups'
    } else if (locationName && allOrderItemsLocationsAreEqual) {
      return locationName
    } else {
      return 'Multiple locations'
    }
  }

  const manageProcessPress = (order: Order) => () => navigation.navigate('AdminOrderDetails', { orderId: order.id })
  const manageProcess = (order: Order) => {
    return (
      <Touchable children={<Text style={manageProcessStyle}>Manage Order</Text>} onPress={manageProcessPress(order)} />
    )
  }

  return [
    { process: totalProcess, widthFlex: 1.5 },
    { process: orderProcess, widthFlex: 1.5 },
    { process: orderDateProcess, widthFlex: 2.4 },
    { process: orderItemsNumProcess, widthFlex: 2 },
    { process: pickupLocationProcess, widthFlex: 2.1 },
    { process: orderStatusText, widthFlex: 2 },
    { process: manageProcess, widthFlex: 1.4 },
  ]
}

const manageProcessStyle: TextStyle = {
  color: Colors.blue,
  textDecorationLine: 'underline',
}

const styles = StyleSheet.create({
  //set marginLeft to -5 to exactly align with the header
  textMargin: { marginLeft: -5 },
})
