import { inviteFarmUser } from '@api/ExternalLinks'
import { Alert, FormButton, KeyboardAvoidingScrollView, Text, TextH3 } from '@elements'
import { InviteType } from '@models/ExternalLink'
import { Farm } from '@models/Farm'
import { Formik, FormikProps } from 'formik'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { userSelector } from '@/redux/selectors'
import { capitalize } from '@helpers/display'
import { getRoleDescription, Role } from '@models/User'
import InputLabel from '../InputLabel'

import { FormInput } from '../../../components/elements/Form/FormInput'
import { FormPickerInput } from '../../../components/elements/Form/FormPickerInput'

/** Team Role dropdown selection */
const TeamRoleDropDown = [
  { label: capitalize(Role.Admin), value: Role.Admin },
  { label: capitalize(Role.Distributor), value: Role.Distributor },
  { label: capitalize(Role.Accountant), value: Role.Accountant },
  // { label: capitalize(Role.Seller), value: Role.Seller },
]

type FormType = {
  email: string
  role: string
}

/** Add Farm Admin component */
export function AddFarmAdmin({ farm }: { farm: Farm }) {
  const user = useSelector(userSelector)
  const validationSchema = Yup.object<FormType>().shape({
    email: Yup.string().label('Email').email('Enter a valid email').required('Please enter a valid email'),
    role: Yup.string().label('Role').required('Please select a role'),
  })

  const onSubmitHandler = async (values: FormType) => {
    inviteFarmUser(farm, values.email, user, InviteType.Admin, values.role as Role)
      .then(() => {
        Alert('Success', `An email was sent to ${values.email} inviting them to be a ${capitalize(values.role)}.`)
      })
      .catch((err) => {
        Logger.error(err)
        Alert(
          'Error',
          `There was an error sending an admin invite, please try again in a little while or contact support.`,
        )
      })
  }
  return (
    <Formik
      initialValues={{
        email: '',
        role: '',
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values, errors, setFieldValue, handleSubmit, handleBlur, touched }: FormikProps<FormType>) => (
        <KeyboardAvoidingScrollView>
          <Text style={globalStyles.margin10}>
            Enter an email to invite a new person to join your farm team on GrownBy. They will receive an email with a
            link to accept your invitation.
          </Text>

          <FormPickerInput
            items={TeamRoleDropDown}
            value={values.role}
            onValueChange={(val) => setFieldValue('role', val)}
            onBlur={handleBlur('role')}
            errorMessage={touched.role ? errors.role : ''}
            useWebNativePicker
            label={<InputLabel label="Select Role" style={styles.inputLabel} />}
          />
          {!!getRoleDescription(values.role as Role) && (
            <TextH3 style={styles.roleDescription}>{getRoleDescription(values.role as Role)}</TextH3>
          )}
          <FormInput
            multiline
            placeholder="Email Address"
            errorMessage={touched.email ? errors.email : ''}
            value={values.email}
            onChangeText={(val) => setFieldValue('email', val)}
            onBlur={handleBlur('email')}
          />

          <FormButton title="Invite" onPress={handleSubmit} />
        </KeyboardAvoidingScrollView>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  inputLabel: {
    marginVertical: 3,
  },
  roleDescription: {
    marginBottom: 10,
    marginTop: 5,
    marginLeft: 15,
  },
})
