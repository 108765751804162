import { CartItem } from '@models/Order'

import { removeObjDuplicates } from '@helpers/helpers'
import { InvalidItemDetails, ItemRejectReason, ProdFetchResult, validateCart } from '@helpers/validateCart'
import { CartService } from '../../constants/types/cartService'
import { loadProduct } from '../Products'

type UpdateInvalidItemsResult = Promise<{
  newCartLength: number
  unavailCount: number
  invalidItemData: InvalidItemDetails[]
}>

/** Will validate cart items and update their quantities in cart or remove them depending on the problem */
export async function updateInvalidCartItems({
  cart,
  updateQuantity,
  isAdmin,
}: Pick<CartService, 'cart' | 'updateQuantity'> & { isAdmin: boolean }): UpdateInvalidItemsResult {
  const invalidItemData: InvalidItemDetails[] = []

  const uniqueProdsInCartDb = await fetchUniqueProdsInCart(cart)
  validateCart({ cart, uniqueProdsInCartDb, onInvalidItem: (args) => invalidItemData.push(args), isAdmin })

  let newCartLength = cart.length
  let unavailCount = 0

  /** If there were items unavailable, we must remove or update them */
  if (invalidItemData.length > 0) {
    const promises = cart.map((ci) => {
      /** Look for any issues related to each item. There may be more than 1 issue for a given item. */
      const itemIssues = invalidItemData.filter((data) => data.id === ci.id)
      if (!itemIssues.length) return

      // Should update item quantity to the available quantity if "Insufficient" is the ONLY issue with the item.
      if (
        itemIssues.length === 1 &&
        itemIssues[0].reason === ItemRejectReason.Insufficient &&
        typeof itemIssues[0].quantity === 'number' &&
        itemIssues[0].quantity >= 1 // Only update quantity if the remainder is still at least 1
      ) {
        return updateQuantity(ci.id, itemIssues[0].quantity!)
      } else {
        // If any other issue is present, remove the item from cart
        newCartLength--
        unavailCount++
        return updateQuantity(ci.id, 0) //Will remove the product from cart
      }
    })
    await Promise.all(promises)
  }

  return { newCartLength, unavailCount, invalidItemData }
}

/** Gets the most recent item's products from db, and return any error data. */
export const fetchUniqueProdsInCart = async (items: CartItem[]): Promise<ProdFetchResult[]> => {
  const uniqueProdsInCart = removeObjDuplicates(items.map((ci) => ci.product))
  return Promise.all(
    uniqueProdsInCart.map((p) =>
      loadProduct(p.id).catch((e) => ({
        id: p.id,
        err: e as Error,
      })),
    ),
  )
}
