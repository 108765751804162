import { ToolTips } from '@components'
import { Icon, Text, typography } from '@elements'
import { GetPickupsProductOpt, getPickups } from '@helpers/order'
import { isValidScheduleNDateConstr } from '@helpers/products'
import { format } from '@helpers/time'
import { ProductType, isShare, isStandard } from '@models/Product'
import { makeDateRange } from '@models/Schedule'
import { useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Calendar } from 'react-native-calendars'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '../../../../constants/Colors'
import { AvailabilityDayComponent } from '../components/AvailabilityDayComponent'
import FormSectionHeader from '../components/FormSectionHeader'
import { FormSchedule, SchedulesFormType } from './SchedulesComponent'

import useDeepCompareEffect from '@/hooks/useDeepEqualEffect'
import { Distribution } from '@models/Distribution'

/** This type is supposed to be a subset of other form components which may be useful in calculating pickups for the calendar */
type OverviewForm = {
  type: ProductType
  /** Should be defined for shares */
  numberOfPickups?: number
} & SchedulesFormType

export type DistroMarking = Pick<Distribution, 'id' | 'name' | 'color'>

/** A key for each date and each value is a list of distributions that appear on that date */
export type DateMarking = Record<string, DistroMarking[]>

/** This fn should generate pickup dates for the calendar, in such a way that gives the farmer a reliable preview of which pickups will be available to their customers.
 * - It must support pickups for rolling schedules, so it is not supposed to show pickups from the beginning of the schedule. Rather, pickups should start from the current date.
 * - It must honor any settings that affect how pickups are calculated for the consumer side, such as "closed" distros, and "disableBuyInFuture".
 */
const getMarkedDates = (
  values: Pick<OverviewForm, 'formSchedules' | 'numberOfPickups' | 'disableBuyInFuture' | 'type'>,
): DateMarking => {
  const formSchedules = values.formSchedules
  const numPickups = isShare(values.type) ? values.numberOfPickups : undefined

  const markedDates = {} as DateMarking
  // We need to filter out any constraint that still doesn't have a distro, startDate and endDate yet
  const localFormSchedules = formSchedules
    .filter((fs): fs is FormSchedule => !!fs.distribution && !!fs.startDate && !!fs.endDate)
    .filter(
      (fs) =>
        isValidScheduleNDateConstr({ startDate: fs.startDate, endDate: fs.endDate }, fs.distribution.schedule) ===
        undefined,
    )

  for (const fs of localFormSchedules) {
    /** Create a dummy product, to pass to getPickups() */
    const dummyProduct: GetPickupsProductOpt = isShare(values)
      ? {
          type: values.type,
          distributions: [fs.distribution],
          distributionConstraints: [
            {
              id: fs.distribution.id,
              dateRange: makeDateRange(fs.startDate, fs.endDate, fs.distribution.location.timezone),
              frequency: fs.frequency ?? fs.distribution!.schedule.frequency,
            },
          ],
          numberPickups: values.numberOfPickups, //Careful to differentiate between "numberOfPickups" and "numberPickups". IMPROVEMENT: This is one of the reasons why the form type is badly designed. It has its own "similar" names for certain fields which are duplicates of real product fields
        }
      : isStandard(values)
      ? {
          type: values.type,
          distributions: [fs.distribution],
          distributionConstraints: [
            {
              id: fs.distribution.id,
              dateRange: makeDateRange(fs.startDate, fs.endDate, fs.distribution.location.timezone),
              frequency: fs.frequency ?? fs.distribution!.schedule.frequency,
            },
          ],
          disableBuyInFuture: values.disableBuyInFuture,
        }
      : undefined

    const pickups = getPickups(fs.distribution, dummyProduct, {
      excludeHiddenDistros: true,
      excludeClosedDistros:
        true /** By setting excludeCloseDistros:true, we're making the calendar not display pickups for closed schedules. This should be correct because this calendar is meant to show the farmer a sample of what the user would see on the shop */,
      ignoreOrderCutoffWindow:
        false /** By setting ignoreOrderCutoffWindow:false, we're saying the calendar should enforce the order cutoff window when displaying pickups. This is because this calendar is meant to show the farmer which pickups would be available on the consumer side. Normally on the admin side we set this to true, to give the farmer elevated privileges; This is the exception */,
      ignoreDisableBuyInFuture:
        false /** Do not ignore disable buy in the future, because this would apply to shoppers */,
    })

    const pickupDateStrings = pickups
      // Will map all pickup dates to dates in the form "01/30/2022"
      .map((pickup) => pickup.toISODate())
      // If we pass in a number of pickups then we should only show the dates for the next n pickups
      .slice(0, numPickups)

    for (const dateString of pickupDateStrings) {
      if (!markedDates[dateString]) {
        markedDates[dateString] = []
      }

      markedDates[dateString].push({
        id: fs.distribution.id,
        name: fs.distribution.name,
        color: fs.color,
      })
    }
  }
  return markedDates
}

export default function AvailabilityOverview() {
  const styles = useStyles()
  const [minDate, setMinDate] = useState<DateTime>(DateTime.now().startOf('month'))
  const [maxDate, setMaxDate] = useState<DateTime>(DateTime.now().endOf('month'))
  const [dateMap, setDateMap] = useState<DateMarking>({})
  const { values } = useFormikContext<OverviewForm>()

  useDeepCompareEffect(() => {
    const markedDates: DateMarking = getMarkedDates(values)

    // The default sort will order them by date since ISO strings are built to be sorted
    const dates = Object.keys(markedDates).sort()

    // If there are no dates to mark reset date map and return
    if (dates.length === 0) return setDateMap({})

    // Set min/max and the mapping of all distros to dates
    setMinDate(DateTime.fromISO(dates[0]))
    setMaxDate(DateTime.fromISO(dates[dates.length - 1]))
    setDateMap(markedDates)
    // Doesn't need to update when the entire values object changes. Only when there's actual differences in these pieces of formik state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.formSchedules, values.numberOfPickups, values.disableBuyInFuture])

  return (
    <View style={styles.container}>
      <FormSectionHeader title="Product Schedule Overview" toolTipId={ToolTips.PRODUCTS_AVAILABILITY_OVERVIEW} />
      <View style={styles.overviewContainer}>
        <View style={styles.overviewStat}>
          <Text>Start Date</Text>
          <Text size={14} type="medium" style={styles.paddingTop}>
            {format(minDate, 'MMMM d, yyyy')}
          </Text>
        </View>
        <View style={styles.overviewStat}>
          <Text>End Date</Text>
          <Text size={14} type="medium" style={styles.paddingTop}>
            {format(maxDate, 'MMMM d, yyyy')}
          </Text>
        </View>
        <View style={styles.overviewStat}>
          <Text>This product availability is automatically calculated by the schedules set above.</Text>
        </View>
      </View>
      <Calendar
        dayComponent={({ date }) => <AvailabilityDayComponent dateMap={dateMap} date={date} />}
        theme={{
          weekVerticalMargin: 0,
          textMonthFontFamily: typography.title.bold,
          textMonthFontSize: 22,
        }}
        initialDate={minDate.toISO()}
        headerStyle={styles.calendarHeader}
        enableSwipeMonths
        maxDate={maxDate.toISO()}
        minDate={minDate.toISO()}
        style={styles.calendar}
        renderArrow={(direction) => {
          if (direction === 'left') {
            return <Icon name="chevron-left" />
          }
          return <Icon name="chevron-right" />
        }}
      />
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginHorizontal: 35,
    },
    overviewContainer: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
      flexDirection: 'row',
      padding: 15,
      borderRadius: 10,
      marginBottom: 15,
      alignItems: 'flex-start',
    },
    overviewStat: {
      flex: 1,
      paddingHorizontal: 5,
    },
    calendar: {
      borderWidth: 1,
      borderLeftWidth: 0,
      borderColor: Colors.shades[100],
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      paddingLeft: 0,
      paddingRight: 0,
    },
    calendarHeader: {
      backgroundColor: Colors.lightGreen,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      borderColor: Colors.shades[100],
      borderLeftWidth: 1,
    },
    paddingTop: {
      paddingTop: 5,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      container: {
        marginHorizontal: 10,
      },
    },
  },
)
