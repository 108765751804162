import { getNearbyShares } from '@api/Products'
import { Header, makeEstablishment, paddedProdWidthSM, ProductCardSquare, useCreateSearchParams } from '@components'
import { Divider, HeaderText, KeyboardAvoidingScrollView, LightStatusBar, ModalInline, Text } from '@elements'
import { validCoords } from '@helpers/coordinate'
import { groupBy } from '@helpers/helpers'
import { sortByName } from '@helpers/sorting'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { Farm } from '@models/Farm'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { ComponentProps, memo, useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import { GoogleLocationDetailResult } from 'react-native-google-autocomplete/dist/services/Google.service'
import { useDispatch, useSelector } from 'react-redux'

import { ItemCardList, ItemCardListData, setItemCardListData } from '../../components/ItemCardList'
import { ColumnSize } from '../../components/ResponsiveList'
import { SeeMoreList } from '../../components/SeeMoreList'
import Colors from '../../constants/Colors'
import { isLargeDevice, isWeb } from '../../constants/Layout'
import { withConsumerIndex } from '../../hooks/withAlgoliaIndex'
import withLocation from '../../hooks/withLocation'
import { farmsSelector, searchLocationSelector, userLocationSelector } from '../../redux/selectors'
import { GeoSearchCard, geoSearchCardWidth } from '../Explore/GeoSearchCard'
import { DoubleFeatureCards, FarmerOwnedFeatureCard, RiverQueenFeatureCard } from './FeatureCards'
import HomeFooter from './HomeFooter'
import { HomeHeader } from './HomeHeader'
import { SmallHomeHeader } from './MobileHomeHeader'
import { NearbyProdsSearch } from './NearbyProdsSearch'
import { NearbyProducts } from './NearbyProducts'
import { TopFarmCard, topFarmCardWidth } from './TopFarmCard'

import { globalStyles } from '@/constants/Styles'
import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useLayout } from '@/hooks/useLayout'
import { HomeParamList } from '@/navigation/types'
import { setSearchLocation } from '@/redux/actions/appState'

/** Several differences between the setup for different platforms and device sizes depend on knowing whether the custom app header is shown. Sometimes the purpose of code isn't obvious, especially if different files are involved in the multi-platform design. So please reference this on code related to the visibility of the custom app header */
export const homeHasCustomAppHeader = (): boolean => isLargeDevice()

function HomeScreen() {
  const { isLargeDevice, isSmallDevice, ...layout } = useLayout()
  const userLoc = useSelector(userLocationSelector)
  const searchLoc = useSelector(searchLocationSelector)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'HomeScreen'>>()
  const { availAddonCsas, availAddonsResults } = useAvailAddons()
  const [nearbyShares, setNearbyShares] = useState<AlgoliaGeoDoc<AlgoliaGeoProduct>[]>([])
  const [modalData, _setModalDataState] = useState<(ItemCardListData<any, any> & { title: string }) | null>(null)
  const fassocs = useSelector(farmsSelector)
  const favorites = useMemo(() => fassocs.filter((f) => f.isFavorite).map((f) => f.farm!), [fassocs])
  const setModalData = setItemCardListData<{ title: string }>(_setModalDataState)
  const dispatch = useDispatch()
  const createSearchParams = useCreateSearchParams()

  useCancelableFocusFx(
    async (isCurrent) => {
      if (!userLoc?.coordinate || !validCoords(userLoc.coordinate)) return
      const shares = await getNearbyShares(userLoc?.coordinate)
      if (!isCurrent) return

      setNearbyShares(shares)
    },
    // Only re-run if primitive values change in user location
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userLoc?.coordinate.latitude, userLoc?.coordinate.longitude],
    { noRefocus: true },
  )

  const onSelectCity = useCallback(
    (item: AutoCompleteItem<GoogleLocationDetailResult>) => {
      const establishment = makeEstablishment(item)
      // When selecting a city we should set it as the global search location so it can be used everywhere
      dispatch(setSearchLocation({ city: establishment.name ?? '', coordinate: establishment.coordinate }))
    },
    [dispatch],
  )
  const availAddons = useMemo(() => availAddonsResults.filter((aa) => aa.isAvail), [availAddonsResults])

  const memoAvailAddons = useDeepCompareMemo(
    () =>
      groupBy(availAddons, (aa) => aa.addon.farm.id).map((group) => (
        <SeeMoreList
          key={group[0].addon.farm.id}
          cardWidth={paddedProdWidthSM}
          title={`Available Add-Ons from ${group[0].addon.farm.name}`}
          data={group.sort((a, b) => sortByName(a, b, (aa) => aa.addon.name))}
          keyExtractor={({ id }) => id}
          renderItem={({ addon, matchingCSAIds }) => {
            const csa = availAddonCsas.find(({ id }) => matchingCSAIds.includes(id))
            return (
              <ProductCardSquare
                product={addon}
                small
                cardAction="addcart"
                csa={csa}
                key={addon.id}
                onPressMobileNavigate={() =>
                  navigation.navigate('Shopping', {
                    screen: 'ProductDetails',
                    params: {
                      farmSlug: addon.farm.urlSafeSlug,
                      productId: addon.id,
                      csaId: csa?.id,
                      goBack: 'home',
                    },
                  })
                }
              />
            )
          }}
        />
      )),
    [availAddonCsas, availAddons, navigation],
  )

  const onExplorePress = useCallback(() => {
    // If an actual location is not selected, take user's location
    const city = searchLoc?.city || userLoc?.city
    const coordinate = searchLoc?.coordinate || userLoc?.coordinate

    if (coordinate)
      dispatch(
        setSearchLocation({
          city,
          coordinate,
        }),
      )
    navigation.navigate('ExploreScreen', createSearchParams({ coords: coordinate }))
  }, [createSearchParams, dispatch, navigation, searchLoc, userLoc?.city, userLoc?.coordinate])

  const styles = useStyles()

  return (
    <View style={styles.screen}>
      {/* When not showing the custom header, the homescreen uses the light status bar for contrast with the dark image background, and no safe area insets so the background image covers the status bar area, that's intentional as part of the mobile-small design */}
      {homeHasCustomAppHeader() ? <Header noSafeareaMargin /> : <LightStatusBar />}
      <KeyboardAvoidingScrollView contentContainerStyle={styles.scroll}>
        {!isLargeDevice ? <SmallHomeHeader /> : <HomeHeader />}
        <View style={styles.main}>
          {favorites.length > 0 && (
            <SeeMoreList
              cardWidth={topFarmCardWidth}
              title="Your Favorite Farms"
              data={favorites}
              seeAllPress={() => navigation.navigate('Shopping', { screen: 'MyFarmsScreen' })}
              renderItem={(farm: Farm) => <TopFarmCard farm={farm} />}
              keyExtractor={({ id }) => id}
            />
          )}
          <View style={styles.spacing30} />
          <NearbyProdsSearch onSelectCity={onSelectCity} onExplorePress={onExplorePress} />

          <View style={styles.spacing10} />
          <NearbyProducts />

          <View style={globalStyles.margin20} />

          <DoubleFeatureCards />
          <View style={globalStyles.margin20} />
          <RiverQueenFeatureCard />
          <View style={globalStyles.margin20} />
          <SeeMoreList
            cardWidth={geoSearchCardWidth}
            title="Subscribe for the Season"
            seeAllPress={() =>
              setModalData<ComponentProps<typeof GeoSearchCard>, 'data'>({
                items: nearbyShares,
                itemProp: 'data',
                title: 'Subscribe for the Season',
                component: GeoSearchCard,
                cardProps: { onCardPress: () => _setModalDataState(null) },
              })
            }
            data={nearbyShares}
            renderItem={(product: AlgoliaGeoDoc<AlgoliaGeoProduct>) => <GeoSearchCard data={product} />}
            keyExtractor={({ objectID }) => objectID}
          />
          <View style={globalStyles.margin20} />

          <FarmerOwnedFeatureCard />

          {availAddons.length > 0 && (
            <View style={styles.addonsCont}>
              <Divider large clear />
              <HeaderText size={26}>Add to your subscription</HeaderText>
              <Text style={styles.addonsText}>
                These add-ons are available for you to purchase as an addition to your primary share.
              </Text>
              {memoAvailAddons}
            </View>
          )}

          <Divider large clear />
        </View>
        {isWeb && <HomeFooter />}
      </KeyboardAvoidingScrollView>
      <ModalInline
        visible={!!modalData}
        webWidth={layout.width * (isSmallDevice ? 1 : 0.9)}
        onDismiss={() => _setModalDataState(null)}
        header
        title={modalData?.title ?? ''}
      >
        <>
          {modalData && (
            <ItemCardList
              items={modalData.items}
              Component={modalData.component}
              itemProp={modalData.itemProp as string}
              cardProps={modalData.cardProps}
              smallHorizontal={false}
              onPress={() => _setModalDataState(null)}
              columnSize={ColumnSize.Large}
            />
          )}
        </>
      </ModalInline>
    </View>
  )
}

const useStyles = () =>
  useLayoutFnStyles(({ height, isLargeDevice, top }) => ({
    screen: {
      /** This screen must have a defined height, because the autocomplete positioning calculation assumes the screen component has a container acting as a viewport or window, with a height limited to the physical screen's height  */
      height,
      backgroundColor: Colors.shadeGold,
      // marginTop needs the top inset only when the custom app header is shown in mobile, which only happens for mobile-large
      marginTop: homeHasCustomAppHeader() ? top : undefined,
    },
    scroll: { paddingBottom: isWeb ? 0 : 30 },
    main: { marginHorizontal: !isLargeDevice ? 10 : '8%' },
    addonsCont: { marginLeft: 10 },
    addonsText: { paddingTop: 10 },
    infoBanner: {
      flexDirection: 'row',
      backgroundColor: Colors.lightGold,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
    },
    bannerImage: {
      width: 60,
      height: 60,
      marginRight: 30,
    },
    infoCardCont: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      marginVertical: 10,
    },
    spacing30: {
      height: 30,
    },
    spacing10: {
      height: 10,
    },
  }))

// Wrap consumer index here so that we can use algolia in the dual search
export default withLocation(withConsumerIndex(memo(HomeScreen), true))
