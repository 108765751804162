import { Coupon, PromoCode } from '@models/Coupon'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalCoupon returns the Firestore data for the supplied Coupon. */
export function marshalPromoCode(promo: Partial<PromoCode>): DocumentData {
  const data = prepareMarshal(promo) as DocumentData

  if (promo.expiration) {
    data.expiration = marshalDate(promo.expiration)
  }
  return data
}

/** unmarshalPromoCode returns a PromoCode created from the Firestore data structure.*/
export function unmarshalPromoCode(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): PromoCode {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const promo = { ...data, id } as PromoCode
  if (data.expiration) {
    promo.expiration = unmarshalDate(data.expiration)
  }

  return promo
}

/** marshalCoupon returns the coupon structure for Firestore. */
export function marshalCoupon(coupon: Partial<Coupon>): DocumentData {
  return coupon
}

/** unmarshalCoupon returns a Coupon created from the Firestore data structure.*/
export function unmarshalCoupon(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Coupon {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  return { ...data, id } as Coupon
}
