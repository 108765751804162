import { addFarm } from '@api/Farms'
import { TextInputBox } from '@components'
import { Alert, Divider, ErrorText, HeaderText, KeyboardAvoidingScrollView, ScreenView } from '@elements'
import { errorToString } from '@helpers/helpers'
import { Farm } from '@models/Farm'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { SaveFormat } from 'expo-image-manipulator'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { Text, View } from 'react-native'
import { Input, SearchBarProps } from 'react-native-elements'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'
import * as Yup from 'yup'

import { Establishment, GooglePlacesSearch, makeEstablishment } from '../components/GooglePlacesSearch'
import ImageSelect from '../components/ImageSelect'
import { Button } from '../components/elements/Button'
import Colors from '../constants/Colors'
import { GoogleAddressParser } from '../constants/GoogleAddressParser'
import { FarmerWalkthroughParamList } from '../navigation/types'

import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'

interface Props {
  navigation: StackNavigationProp<FarmerWalkthroughParamList>
  route: RouteProp<FarmerWalkthroughParamList, 'AddFarmScreen'>
}

type FormType = {
  image: string
  name: string
  about: string
  street1: string
  city: string
  state: string
  zipcode: string
}

/** Screen to register a new farm to claim */
export function AddFarmScreen({ navigation, route }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const styles = useStyles()

  const { fromInvite } = route.params

  const validationSchemaForAdminRegistration = Yup.object<FormType>().shape({
    image: Yup.string().label('Image').required('Please upload an image'),
    name: Yup.string().trim().label('Name').required().min(3, 'Farm name must have at least 3 characters '),
    about: Yup.string().trim().label('Description').required(),
    street1: Yup.string().trim().label('Address').required(),
    city: Yup.string().trim().label('City').required(),
    state: Yup.string().trim().label('State').required(),
    zipcode: Yup.string().trim().label('ZIP Code').required(),
  })

  const validationSchemaForInviteFarms = Yup.object<Omit<FormType, 'image' | 'about'>>().shape({
    name: Yup.string().trim().label('Name').required().min(3, 'Farm name must have at least 3 characters '),
    street1: Yup.string().trim().label('Address').required(),
    city: Yup.string().trim().label('City').required(),
    state: Yup.string().trim().label('State').required(),
    zipcode: Yup.string().trim().label('ZIP Code').required(),
  })

  function onSubmitHandler(values: FormType) {
    const { name } = values
    setIsLoading(true)
    addFarm({
      name: values.name.trim(),
      about: values.about,
      address: {
        street1: values.street1,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
      },
      media: [{ storageUrl: values.image, type: 'image' }],
    })
      .then((farm: Farm) => {
        setIsLoading(false)
        Alert(
          `Hooray! You just added ${name} to our records.`,
          'In order to start selling on GrownBy, please follow the steps to claim your farm.',
          [
            {
              text: 'Claim Farm',
              style: 'cancel',
            },
          ],
        )
        if (fromInvite) {
          navigation.goBack()
        } else {
          navigation.navigate('RegisterFarm', { farm })
        }
      })
      .catch((err) => {
        Logger.error(err)
        setIsLoading(false)
        Alert('Error while adding farm', errorToString(err))
      })
  }

  const setGooglePlace = (details: Establishment, setFieldValue: (key: string, value: string) => void) => {
    if (details.name) setFieldValue('name', details.name)
    if (!details.address_components) return
    const address = new GoogleAddressParser(details.address_components).result()
    setFieldValue('street1', `${address.street_number || ''} ${address.street_name || ''}`)
    setFieldValue('city', address.city || '')
    setFieldValue('state', address.state || '')
    setFieldValue('zipcode', address.postal_code || '')
    setFieldValue('country', address.country || '')
  }

  return (
    <ScreenView>
      <KeyboardAvoidingScrollView
        style={globalStyles.flex1}
        contentContainerStyle={styles.mainContent}
        keyboardShouldPersistTaps="always"
      >
        <Formik
          initialValues={{
            image: '',
            name: '',
            about: '',
            street1: '',
            city: '',
            state: '',
            zipcode: '',
          }}
          onSubmit={(values) => {
            onSubmitHandler(values)
          }}
          validationSchema={fromInvite ? validationSchemaForInviteFarms : validationSchemaForAdminRegistration}
        >
          {({
            handleChange,
            setFieldValue,
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
          }: FormikProps<FormType>) => (
            <View>
              <HeaderText style={styles.headerText}>Register your farm</HeaderText>
              <GooglePlacesSearch
                onSelect={(item) => setGooglePlace(makeEstablishment(item), setFieldValue)}
                placeholder="Search for your farm"
                types="establishment"
                contStyle={styles.placesCont}
                style={styles.placesInput}
                inline
              />
              <Divider large />
              <View>
                <View style={styles.rowOrColumn}>
                  <View style={styles.mainFields}>
                    <HeaderText style={styles.headerText}>Contact information</HeaderText>
                    <Input
                      placeholder="Farm Name"
                      errorStyle={{ color: Colors.red }}
                      errorMessage={touched.name ? errors.name : ''}
                      value={values.name}
                      onChangeText={handleChange('name')}
                      onBlur={handleBlur('name')}
                    />

                    <Input
                      placeholder="Address"
                      errorStyle={{ color: Colors.red }}
                      errorMessage={touched.street1 ? errors.street1 : ''}
                      value={values.street1}
                      onChangeText={handleChange('street1')}
                      onBlur={handleBlur('street1')}
                    />
                    <View style={styles.rowOrColumn}>
                      <Input
                        placeholder="City"
                        errorStyle={{ color: Colors.red }}
                        errorMessage={touched.city ? errors.city : ''}
                        value={values.city}
                        onChangeText={handleChange('city')}
                        onBlur={handleBlur('city')}
                        containerStyle={styles.inputContainer}
                        inputStyle={styles.inputStyle}
                      />
                      <Input
                        placeholder="State"
                        errorStyle={{ color: Colors.red }}
                        errorMessage={touched.state ? errors.state : ''}
                        value={values.state}
                        onChangeText={handleChange('state')}
                        onBlur={handleBlur('state')}
                        containerStyle={styles.inputContainer}
                        inputStyle={styles.inputStyle}
                      />
                      <Input
                        placeholder="ZIP Code"
                        errorStyle={{ color: Colors.red }}
                        errorMessage={touched.zipcode ? errors.zipcode : ''}
                        value={values.zipcode}
                        onChangeText={handleChange('zipcode')}
                        onBlur={handleBlur('zipcode')}
                        containerStyle={styles.inputContainer}
                        inputStyle={styles.inputStyle}
                      />
                    </View>
                    <HeaderText style={styles.headerText}>About your farm</HeaderText>
                    <TextInputBox
                      onBlur={handleBlur('about') as SearchBarProps['onBlur']}
                      onChangeText={handleChange('about')}
                      multiline
                      numberOfLines={5}
                      inputContainerStyle={styles.aboutContainer}
                      value={values.about}
                      inputStyle={styles.aboutInput}
                      placeholder="3-5 sentences about your farm. This will appear on your farm profile for customers to see."
                    />
                  </View>
                  <View>
                    <HeaderText>Add an Image</HeaderText>
                    <Text>Recommended Aspect Ratio is 3:2 (ex. 1200x800)</Text>
                    <View style={styles.imgSelectCont}>
                      <ImageSelect
                        defaultImage={values.image}
                        editOptions={{ width: 1200, format: SaveFormat.JPEG }}
                        onChange={handleChange('image')}
                      />
                    </View>
                    {!!errors.image && <ErrorText>{errors.image}</ErrorText>}
                  </View>
                </View>

                <Text style={{ color: Colors.red }}>{touched.about ? errors.about : ''}</Text>
                <Button
                  title="Register farm"
                  loading={isLoading}
                  onPress={() => handleSubmit()}
                  style={styles.submit}
                />
              </View>
            </View>
          )}
        </Formik>
      </KeyboardAvoidingScrollView>
    </ScreenView>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    mainContent: {
      paddingHorizontal: 20,
      paddingVertical: 20,
    },
    rowOrColumn: {
      flexDirection: 'column',
    },
    mainFields: { flexDirection: 'column', width: '60%' },
    inputContainer: {
      maxWidth: '100%',
    },
    errMsg: {
      color: Colors.red,
      fontSize: 12,
      marginHorizontal: 10,
    },
    missingFarmButton: {
      fontSize: 32,
      margin: 20,
    },
    imgSelectCont: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
      margin: 10,
      borderRadius: 10,
      height: 100,
      width: 150,
      overflow: 'hidden',
    },
    placesCont: {
      borderWidth: 1,
      borderRadius: 10,
      borderColor: Colors.shades[200],
      padding: 10,
    },
    placesInput: { color: Colors.shades[600], fontSize: 16, height: 30 },
    headerText: { marginBottom: 16 },
    inputStyle: { maxWidth: '100%' },
    aboutContainer: { padding: 10, maxWidth: 700 },
    aboutInput: { height: 136 },
    submit: { maxWidth: 195, marginLeft: 0 },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      inputContainer: {
        maxWidth: '30%',
      },
      rowOrColumn: {
        flexDirection: 'row',
      },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      mainFields: { width: '100%' },
    },
  },
)
