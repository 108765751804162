import { plural } from '@helpers/display'
import { PartialExcept, PartialPick } from '@helpers/typescript'
import { Invoice, InvoiceItemTypes, PaymentSources } from '@models/Invoice'
import { PaymentForms, PaymentMethod, PaymentMethodBase } from '@models/PaymentMethod'
import { DateTime } from 'luxon'

import { orderItemProductSchema } from '@helpers/builders/OrderBuilder'
import { hasOwnProperty } from '@helpers/helpers'
import { Address } from './Address'
import { CSA } from './CSA'
import { Distribution } from './Distribution'
import { Farm } from './Farm'
import { Location, LocationTypes, NonPickup, isNonPickup } from './Location'
import { Money } from './Money'
import {
  CancellationTypes,
  DigitalProduct,
  GlobalStandard,
  PayInFull,
  PayPerPickup,
  PaymentSchedule,
  Product,
  ProductType,
  Share,
  Standard,
  StockType,
  Unit,
  UnitBase,
  UnitPrice,
  UnitStandard,
  isDigital,
  isPayInFull,
  isPhysical,
} from './Product'
import { Hours } from './Schedule'
import { SignInSummary } from './SignInSummary'
import { User } from './User'
import { UserAddress } from './UserAddress'

/** OrderState identifies what state the order is in through its lifecycle. */
export enum OrderState {
  // Preparing is the initial state, used when the order is being prepared.
  Preparing = 'preparing',
  // Created is assigned when the order record is stored.
  Created = 'created',
  // Numbered is assigned when the order has been numbered.
  Numbered = 'numbered',
  // Invoiced is assigned when the order has associated invoices.
  Invoiced = 'invoiced',
  // Finalized is assigned when the order has finished processing. An order is considered to be processed after
  // it has been numbered an invoiced.
  Finalized = 'finalized',
}

// Order Status should tell entire order items condition

export enum OrderStatusType {
  Active = 'active',
  Cancelled = 'cancelled',
  ItemCancelled = 'item_cancelled',
}

// The Order encompasses the products purchased during a checkout session. An order is independent of billing.

export interface OrderBase {
  // The document identifier.

  id: string | 'balance_transaction' | 'manual_invoice'

  // The state of order creation.

  state: OrderState

  // Order number

  orderNum: number

  // The user who placed the order.

  user: Pick<User, 'id' | 'name' | 'email'>

  // The farm under which the order was placed.

  farm: PartialPick<Farm, 'id' | 'name' | 'offlinePayments'>

  // The shopping cart items that were ordered for purchase.

  items: OrderItem[] | CartItem[]

  // The order date

  date: DateTime
}

// isSpecialOrder returns true if the order is not a regular order. A special order has a particular ID value
// that we can look for.

export function isSpecialOrder({ id }: Pick<OrderBase, 'id'>): boolean {
  return id === 'manual_invoice' || id === 'balance_transaction'
}

// get order status (active, cancelled, item_cancelled)
export const orderStatus = (order: Order): OrderStatusType => {
  if (order.items.every((itm) => itm.cancelled)) {
    return OrderStatusType.Cancelled
  } else if (order.items.some((itm) => itm.cancelled)) {
    return OrderStatusType.ItemCancelled
  } else return OrderStatusType.Active
}

// The text variation will depend on the order status
export const orderStatusText = (order: Order): string => {
  if (orderStatus(order) === OrderStatusType.Cancelled) {
    return 'Order cancelled'
  } else if (orderStatus(order) === OrderStatusType.ItemCancelled) {
    const count = order.items.filter((itm) => itm.cancelled).length
    return `${count} ${plural(count, ' item')} cancelled`
  } else return 'Active'
}

/** Snapshot of the order item at time of purchase. */
export type OrderItem = {
  id: CartItem['id']

  // The quantity of product being ordered.

  quantity: number

  // The date this item was cancelled, only if all items are cancelled

  cancelled?: DateTime

  // The denormalized product.

  product: {
    id: string
    type: ProductType
    image: string
    name: string
    description: string
    vacationWeeks: number
    /** Will be true if the product is either EBT-eligible or EBT-only.
     * Only relevant for standard products (default undefined for non-standard products) */
    isEbtEligible: boolean | undefined
    cancellationPolicy: CancellationTypes | null
  }

  // The unit and price of the product if it is standard or null

  purchasedUnit: Unit | null

  purchasedUnitPrice: UnitPrice | null

  // A CSA optionally associated with the item.

  csa?: Omit<CSA, 'images' | 'numOfCustomers' | 'numOfSharesSold' | 'farm' | 'description'>

  // Distribution so that we can get the id for pickups. In digital products it will be undefined.

  distribution?: Pick<Distribution, 'id' | 'name'> & { location: Pick<Location, 'id' | 'timezone' | 'name' | 'type'> }

  // The planned payment schedule for an item.

  paymentSchedule: PaymentSchedule

  // The number of pickups for a share, useful for refunding from claim vacation, or the number of pickups for a multi-pickup standard product

  numPickups?: number

  // The pickup dates for this item, at time of purchase.

  pickups?: DateTime[]

  /** If the Location was a non-pickup type, the address at checkout will be stored here */
  deliveryAddress?: NonPickup['address']
}

/** Identifies the nested product object type from an order item */
export const isOrderItemProduct = (prod: object): prod is OrderItem['product'] => {
  return orderItemProductSchema.isValidSync(prod)
}

export interface Order extends OrderBase {
  items: OrderItem[]
}

export interface CartOrder extends OrderBase {
  // A list of payments to make

  payments: {
    source: PaymentSources
    // The payment method to use

    paymentMethod: string | PaymentForms.CASH | PaymentForms.FARM_CREDIT
  }[]

  items: CartItem[]
}

export type CartItemBase<P extends Product, PS extends PaymentSchedule = PaymentSchedule> = {
  /** The id must encode the product id, unit id and distro id. Should be updated to reflect changes in these */
  id: string

  // The quantity of product being ordered.

  quantity: number

  /** The product added to cart */
  product: P

  /** The planned payment schedule for an item. In shares, we may have pay-full, monthly or weekly. In standard: If single-pickup, only pay-full. If multi-pickup standard, could be pay-full or pay-per-pickup. */
  paymentSchedule: PS

  /** The dates the product will be picked up. In a standard prod, these dates are selected by the user, and allows buying in the future, as well as multiple dates. In a share, it is automatically calculated by getPickups(). In digital, it is undefined. */
  pickups?: DateTime[]
} & ProductDependent<P>

type ProductDependent<P extends Product> = P extends Share
  ? CartItemBaseShare
  : P extends Standard
  ? CartItemBaseStandard<P>
  : CartItemBaseDigital

type CartItemBaseShare = {
  // A CSA optionally associated with the item.
  csa: CSA
  unit?: undefined
  price?: undefined
  // The selected distribution schedule.
  distribution: Distribution
  pickups: DateTime[]
}

type CartItemBaseStandard<P extends Standard> = {
  csa?: CSA
  // The selected unit, for standard products
  unit: P extends GlobalStandard
    ? UnitBase<StockType.Global>
    : P extends UnitStandard
    ? UnitBase<StockType.Unit>
    : unknown
  price: UnitPrice
  // The selected distribution schedule.
  distribution: Distribution
  pickups: DateTime[]
}

type CartItemBaseDigital = {
  csa?: CSA
  unit: UnitBase<StockType.Global>
  price: UnitPrice
  pickups?: undefined
  distribution?: undefined
}

/** A cartItem with a standard product and a single pickup date, with payInFull as only valid payment schedule */
export type CartStandardSingle = CartItemBase<Standard, PayInFull>

/** A cartItem with standard product, and multiple pickup dates, with either payInFull or payPerPickup payment schedules */
export type CartStandardMulti = CartItemBase<Standard, PayPerPickup | PayInFull>

export type CartStandard = CartStandardMulti | CartStandardSingle

/** FIXME: The CartShare type should only have payment options: full, monthly, weekly, but no per-pickup */
export type CartShare = CartItemBase<Share>

export type CartPhysical = CartShare | CartStandard

export type CartDigital = CartItemBase<DigitalProduct, PayInFull>

export type CartItem = CartShare | CartStandardMulti | CartStandardSingle | CartDigital

export type ItemNonPickup = CartPhysical & {
  distribution: Distribution<NonPickup>
}

/** A Payment represents the portion of an invoice allocated to paying for an order item. */
export type Payment = {
  // The firebase invoice ID

  invoiceId: string

  // The stripe invoice idea for lookups and webhooks

  invoiceRef: string

  // Whether or not the invoice has been successfully paid

  success: boolean

  // The date the invoice was paid

  date: DateTime

  // The amount the user has already paid should equal amount_due

  amount_paid?: number

  // The total amount for the invoice

  amount_due: number

  // The pdf receipt

  pdf?: string

  // A url for the user to pay the invoice

  payUrl?: string
}

export type LocationFeeMapping = {
  amount: Money

  type: InvoiceItemTypes.SHIPPING_FEE | InvoiceItemTypes.DELIVERY_FEE

  invoice: Pick<Invoice, 'id'> // Maybe we want more fields here, we need a denormalizer if so
}

export type Pickup = {
  // The ID of the pickup

  id: string

  // User id for querying based on user

  user: Pick<User, 'id'>

  // Farm id for querying

  farm: Pick<Farm, 'id' | 'name' | 'logo' | 'email' | 'phoneNumber'>

  // The date of the pickup

  date: DateTime

  // This field will contain the original distribution for rescheduled items,
  // here it means that an item in the pickup was rescheduled

  oldDistribution?: Pick<Distribution, 'id'>

  // locationFee will hold and shipping or delivery fee necessary for this pickup

  locationFee?: LocationFeeMapping

  // Items to be picked up

  items: PickupItem[]

  // The distribution information

  distribution: {
    id: string

    name: string
    // TODO: In the future we should move the location to be a nested object like this:
    // location: Pick<Location, 'id' | 'type' | 'name' | 'nickname'>
    /** locationType in the Pickup model is intended to help distinguish between delivery and pickup locations */
    locationType: LocationTypes

    locationName: string

    locationNickname?: string

    notes?: string

    // Address if it's a local pickup or UserAddress if it's a non-pickup (delivery or shipment)
    address: Address | UserAddress

    hours: Hours
  }

  // If a sign in summary has started that includes this pickup then we will store the id here
  signInSummary?: Pick<SignInSummary, 'id'>
}

export type SplitTenderPaymentItemBase = {
  paymentMethod: PaymentMethod

  /** If amount undefined, will be treated as Infinite */
  amount?: Money
}

type SplitTenderPaymentItemPartial = Omit<SplitTenderPaymentItemBase, 'paymentMethod'> & {
  paymentMethod: Pick<PaymentMethodBase, 'source'>
}

export type SplitTenderPaymentPartial = SplitTenderPaymentItemPartial[]

/** Defines the split of money between payment items. One must be an infinite amount. */
export type SplitTenderPayment = SplitTenderPaymentItemBase[]

/** isPickupCancelled returns true if a pickup has no available items to pickup. An item is considered unavailable if it won't be picked up */
export function isPickupCancelled(pickup: Pickup): boolean {
  return !pickup.items.length || pickup.items.every((item) => isPickupItemSkipped(item))
}

export enum PickupItemStatus {
  Cancelled = 'cancelled',
  Vacation = 'vacation',
  Active = 'active',
  Received = 'received',
  Missed = 'missed',
  Donated = 'donated',
}

export type PickupItem = {
  // This ID matches the ID of the order item and invoice item, however it does not guarantee it is unique across this pickup.
  // So you must compare both id and orderId to ensure uniqueness across a pickup

  id: CartItem['id']

  // The order ID.

  orderId: string

  // Only for standard product pickup items

  invoiceId?: string

  // The product ID.

  productId: string

  // The quantity of product to be picked up.

  quantity: number

  // The status of the current pickup item

  status:
    | PickupItemStatus.Active
    | PickupItemStatus.Cancelled
    | PickupItemStatus.Vacation
    | PickupItemStatus.Received
    | PickupItemStatus.Missed
    | PickupItemStatus.Donated

  // This field will contain the original distribution for rescheduled items

  oldDistribution?: Pick<Distribution, 'id'>
}

/** isPickupItemActive returns true if the pickup item is active */
export function isPickupItemActive(item: PartialExcept<PickupItem, 'status'>): boolean {
  return item.status === PickupItemStatus.Active
}

/** isPickupItemCancelled returns true if the pickup item is cancelled */
export function isPickupItemCancelled(item: PartialExcept<PickupItem, 'status'>): boolean {
  return item.status === PickupItemStatus.Cancelled
}

/** isPickupItemOnVacation returns true if the pickup item is on vacation */
export function isPickupItemOnVacation(item: PartialExcept<PickupItem, 'status'>): boolean {
  return item.status === PickupItemStatus.Vacation
}

/** isPickupItemSkipped returns true if the pickup item status is cancelled or on vacation */
export function isPickupItemSkipped(item: PartialExcept<PickupItem, 'status'>): boolean {
  return isPickupItemCancelled(item) || isPickupItemOnVacation(item)
}

/** findOrderItem returns the order item matching the supplied identifier. It will return undefined if no item matches the identifier. */
export function findOrderItem(order: Order, itemId: string): OrderItem | undefined {
  return order.items.find((item) => item.id === itemId)
}

/** findOrderItemForPickupItem returns the order item that relates to the supplied pickup item. */
export function findOrderItemForPickupItem(
  order: Pick<Order, 'id' | 'items'>,
  pickupItem: Pick<PickupItem, 'id' | 'orderId'>,
): OrderItem | undefined {
  if (order.id !== pickupItem.orderId) {
    return undefined
  }
  return order.items.find((item) => item.id === pickupItem.id)
}

export function isCartShare(item: Pick<CartItem, 'product'>): item is CartShare {
  return item.product.type === ProductType.PrimaryShare || item.product.type === ProductType.AddonShare
}

export function isCartStandard(item: Pick<CartItem, 'product'>): item is CartStandard {
  return item.product.type === ProductType.Standard
}

export function isCartDigital(item: Pick<CartItem, 'product'>): item is CartDigital {
  return isDigital(item.product)
}

export function isCartPhysical(item: { product: Pick<Product, 'type'> }): item is CartPhysical {
  return isPhysical(item.product)
}

/**
 * Will determine if a cart item is a single pickup standard product. It is more useful for type narrowing than checking for multiPickup
 */
export function isCartStandardSingle(
  item: Pick<CartItem, 'product' | 'pickups' | 'paymentSchedule'>,
): item is CartStandardSingle {
  return (
    item.product.type === ProductType.Standard &&
    item.pickups?.length === 1 &&
    item.paymentSchedule.frequency === 'ONCE'
  )
}

/** Type that represents a cartItem with multiple pickups while extending both CartItem and OrderItem types */
type ItemStandardMulti<T> = T extends CartItem
  ? CartStandardMulti
  : Omit<OrderItem, 'pickups' | 'paymentSchedule'> & {
      pickups: DateTime[]
      paymentSchedule: CartStandardMulti['paymentSchedule']
    }

/**
 * Will determine if a cart item is a single pickup standard product. It is more useful for type narrowing than checking for multiPickup.
 * - It works with OrderItems as well
 */
export function isCartStandardMulti<T extends CartItem | OrderItem>(
  item: Pick<T, 'product' | 'pickups' | 'paymentSchedule'>,
): item is ItemStandardMulti<T> {
  return !!(
    item.product.type === ProductType.Standard &&
    item.pickups &&
    item.pickups.length > 1 &&
    (isPayInFull(item.paymentSchedule) || item.paymentSchedule.frequency === 'PER-PICKUP')
  )
}

/** Defferentiates a cart item from an order item */
export function isCartItem(item: CartItem | OrderItem): item is CartItem {
  // This works because 'purchasedUnit' is a property of OrderItem, which has a different representation in the CartItem model
  return !hasOwnProperty(item, 'purchasedUnit')
}

/** Identifies a cartItem with delivery or shipping location */
export function isCartItemNonPickup(item: CartPhysical): item is ItemNonPickup {
  return isNonPickup(item.distribution.location)
}
