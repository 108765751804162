import { Button, HeaderText, Icon, LoadingView, Modal, ReadMore, Text } from '@elements'
import { Farm } from '@models/Farm'
import { Review } from '@models/Review'
import { userName } from '@models/User'
import { memo, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import Colors from '../constants/Colors'
import { WithAuthCallback } from '../hooks/WithAuthCallback'
import { userSelector } from '../redux/selectors'
import { Image } from './Image'
import { RatingStars } from './Rating'
import { ResponsiveGrid } from './ResponsiveGrid'
import EditReview from './modals/EditReview'

type Props = {
  farm: Farm
  data?: {
    myReview?: Review | undefined
    reviews: Review[]
  }
  loading: boolean
  error?: string
  onUpdateReview: (item: Review) => void
  onDeleteReview: () => void
  onFlagPress: (item: Review) => void
}

/** Grid of farm reviews */
export const Reviews = memo(function Reviews({
  farm,
  onDeleteReview,
  onFlagPress,
  onUpdateReview,
  data,
  loading,
  error,
}: Props) {
  const user = useSelector(userSelector)
  const [needsAuth, setNeedsAuth] = useState(false)
  const { isExtraSmallDevice } = useDeviceSize()

  const styles = useStyles()

  const editMyReviewModal = () => {
    if (!farm || loading || !data) return
    if (!user.id) return setNeedsAuth(true)
    Modal(<EditReview review={data.myReview} farmId={farm.id} onDelete={onDeleteReview} onUpdate={onUpdateReview} />, {
      title: `Review ${farm.name}`,
    })
  }

  return (
    <View style={styles.main}>
      {needsAuth && (
        <WithAuthCallback
          onDismiss={() => {
            setNeedsAuth(false)
          }}
          callback={() => {
            setNeedsAuth(false)
            editMyReviewModal()
          }}
        />
      )}
      <HeaderText size={18}>Reviews</HeaderText>
      <LoadingView style={styles.main} loading={loading} success={data} error={error}>
        {({ reviews, myReview }) => (
          <>
            <View style={reviews.length > 0 && styles.gridWrapper}>
              <ResponsiveGrid
                estimatedItemSize={150}
                itemBaseWidth={isExtraSmallDevice ? 250 : 350}
                keyExtractor={(item) => item.id}
                data={reviews}
                renderItem={({ item }) => (
                  <View style={styles.reviewCont}>
                    <View style={globalStyles.flexRow}>
                      <Image type="profile" style={styles.avatar} source={{ uri: item.user.avatar }} />
                      <View style={globalStyles.flex1}>
                        <Text size={16} numberOfLines={1}>
                          {userName(item.user)}
                        </Text>
                        <RatingStars stars={item.rating} />
                      </View>
                      <View>
                        {item.user.id === user.id ? (
                          <Icon
                            name="user-edit"
                            size={15}
                            solid={item.flaggedBy && item.flaggedBy[user.id]}
                            onPress={() => editMyReviewModal()}
                          />
                        ) : (
                          user.id && (
                            <Icon
                              name="flag"
                              size={15}
                              solid={item.flaggedBy && item.flaggedBy[user.id]}
                              color={Colors.red}
                              onPress={() => onFlagPress(item)}
                            />
                          )
                        )}
                      </View>
                    </View>
                    {!!item.description && <ReadMore numLines={10}>{item.description.replace('\\n', '\n\n')}</ReadMore>}
                  </View>
                )}
                ListEmptyComponent={() => (
                  <Text>No reviews yet! Be the first one to add a review for {farm.name}.</Text>
                )}
              />
            </View>

            {!myReview && (
              <Button outline title="Add a review" style={styles.addReviewBtn} onPress={editMyReviewModal} />
            )}
          </>
        )}
      </LoadingView>
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice }) => ({
    main: {
      gap: isExtraSmallDevice ? 10 : 20,
    },
    reviewCont: {
      borderWidth: 1,
      padding: 10,
      flex: 1,
      marginHorizontal: isExtraSmallDevice ? 0 : 10,
      marginBottom: isExtraSmallDevice ? 15 : 20,
      borderColor: Colors.shades[100],
      gap: 10,
      borderRadius: 10,
    },
    avatar: {
      borderWidth: 2,
      borderColor: Colors.green,
      width: 50,
      height: 50,
      borderRadius: 100,
      marginRight: 10,
    },

    addReviewBtn: {
      maxWidth: 350,
      marginLeft: 0,
    },
    gridWrapper: {
      minHeight: 100,
    },
  }))
