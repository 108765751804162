import { isWeb } from '@/constants/Layout'
import { MenuItem } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData-helpers'
import { ProductCardProps } from '@components'
import { formatAddress } from '@helpers/display'
import { isNonNullish } from '@helpers/helpers'
import { isValidAddress } from '@models/Address'
import { isGeoDoc } from '@models/Algolia'
import { Location, isDelivery, isLocalPickup } from '@models/Location'
import { isAddon, isShare, isStandard } from '@models/Product'

/** Whether the farm sidebar is shown */
export const shouldShowSidebar = (isLargeDevice: boolean) => {
  return isLargeDevice && isWeb
}
/** The maximum width that the shop can have. It includes the enitre screen, header, content and footer */
export const MAX_SHOP_WIDTH = 1800

/** Determines which card action to show on the product card for the shop specifically. Other screens may not need this, or may have a different requirement.
 * @param prod a product to show a product card in the shop. It is assumed this product won't be private because private products should not be shown outside of the CSADetail screen. However it may be set to "Only show in CSA". If so, this should return 'csa' for the product.
 * @param availAddonIds If provided and the product is an addon, if the addon id is included in the list it will have the 'addcart' action. Otherwise it will have the 'csa' action.
 * @param cartProdIds A list of product ids in the cart, if the prod matches one of these we will show the stepper.
 */
export const getCardActionShop = (
  prod: Exclude<ProductCardProps['product'], string>,
  availAddonIds: string[],
  cartProdIds: string[],
): ProductCardProps['cardAction'] => {
  if (isGeoDoc(prod)) {
    if (isStandard(prod) && prod.hideFromShop && prod.csa?.length) return 'csa'
    if (isShare(prod)) {
      if (isAddon(prod) && availAddonIds?.includes(prod.id)) return 'addcart'
      if (cartProdIds.includes(prod.id)) return 'addcart' // This will show the stepper
      return 'csa'
    }
  } else {
    /**The checks here might be repeated for geodocs and db products. But this is correct because the geodoc and Product types might not always have the same data in the fields involved here, and if you use isShare() or isStandard() without checking for isGeoDoc, typescript will assume the prod is a db prod, in which case typescript won't check the algolia field is correct. So the "double" check, just makes this future proof and will absolutely prevent bugs if anything changes in the algolia model later */

    if (isStandard(prod) && prod.hideFromShop && prod.csa?.length) return 'csa'
    if (isShare(prod)) {
      if (isAddon(prod) && availAddonIds?.includes(prod.id)) return 'addcart'
      if (cartProdIds.includes(prod.id)) return 'addcart' // This will show the stepper
      return 'csa'
    }
  }
  /** INFO: This helper doesn't check the isPrivate field because private products are expected to be excluded for the shop and similar places before they reach the UI. So this helper is assuming products as not private, but they might be set to hideFromShop true ("Only show in CSA") */

  return 'addcart'
}

/** Transforms db locations to MenuItem type, in order to be used in the UI */
export const locationsToMenuItems = (dbLocs: Location[], algoliaLocItems: MenuItem[]): MenuItem[] => {
  const items = dbLocs.map((loc) => {
    const algoliaLoc = algoliaLocItems.find((el) => loc.id === el.value)
    if (!algoliaLoc) return undefined

    return {
      ...algoliaLoc,
      label: loc.name,
      subtitle: getLocationSubtitle(loc),
    }
  })

  return items.filter(isNonNullish)
}

/** Creates a text value that represents the address (LocalPickup) or the regions (NonPickup) for the location */
export const getLocationSubtitle = (item: Location) => {
  if (isLocalPickup(item)) {
    if (!isValidAddress(item.address)) {
      return ''
    }
    return formatAddress(item.address)
  }

  const text = isDelivery(item) ? 'Zip codes: ' : 'States: '
  const regions = item.regions.join(', ')
  return text + regions
}
