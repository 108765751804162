import { LoaderWithMessage, SkeletonContent } from '@components'
import { Text, Touchable } from '@elements'
import { FontAwesome } from '@expo/vector-icons'
import { capitalize } from '@helpers/display'
import { AlgoliaAdminDoc, AlgoliaDocType } from '@models/Algolia'
import { ReactElement, useState } from 'react'
import { Hit, StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-native'
import { FlatList, ScrollView, TouchableOpacity, View, ViewStyle } from 'react-native'

import { globalStyles } from '../../../constants/Styles'
import ConnectedDateRange from './ConnectedDateRange'
import ConnectedPagination from './ConnectedPagination'
import ConnectedSearchBox from './ConnectedSearchBox'
import { customSortFun, SortRecord } from './helpers/adminTableHelper'

import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { PartialExcept } from '@helpers/typescript'
import { RefinementDropdownExternalProps } from './ConnectedRefinementDropdown'

type ColumnData<T> = {
  key: keyof T
  title: string
  size?: number
  process?: (values: T) => string | ReactElement
  itemPressUrl?: (item: T) => string | undefined
}

type SortableColumn<T> = ColumnData<T> & {
  sortDir?: 'desc' | 'asc'
  sortFunc: (a: T, b: T) => number
}

type PropTypes<T> = {
  type: AlgoliaDocType
  style?: ViewStyle
  columns: (ColumnData<T> | SortableColumn<T>)[]
  minWidth?: number
  /** Algolia filters applied to table data */
  refinements?: PartialExcept<
    RefinementDropdownExternalProps,
    'attribute' | 'placeholder' | 'limit' | 'showMoreLimit' | 'setShowMore' | 'showMore'
  >[]
  expandRefinementsInitial?: boolean
  /** Will filter the hits so they must satisfy this condition or will be excluded */
  hitsFilter?: (hit: Hit<T & AlgoliaAdminDoc>) => boolean
  onRowPress?: (item: T) => void
  onRowPressUrl?: (item: T) => string
  searchPlaceholder?: string
  defaultSearch?: string
  dateRange?: {
    attribute: keyof T
    timezone: string
  }
  /** Only column keys that are inside sortOptionKeys will be able to sort  */
  sortOptionKeys?: (keyof T)[]
  /** If disableFutureDates is true, the DatePicker for current table won't allow any date to be chosen after today. By default it is true. */
  disableFutureDates?: boolean
}

/** AdminTable is a reusable component and is bound to Algolia use, so it is currently used in Admin Invoice List, Admin Order List and Admin Customer List screens */
export function AdminTable<T extends AlgoliaAdminDoc>({
  type,
  style,
  columns,
  minWidth = 800,
  refinements = [],
  expandRefinementsInitial,
  hitsFilter,
  onRowPress,
  onRowPressUrl,
  searchPlaceholder,
  defaultSearch,
  dateRange,
  sortOptionKeys,
  disableFutureDates = true,
}: PropTypes<T>) {
  const styles = useStyles()
  const [updatedHits, setUpdatedHits] = useState<any>(null)
  const [sortRecord, setSortRecord] = useState<SortRecord>({ asc: true })
  const { isSmallDevice } = useDeviceSize()

  // connectedResults callback component
  const ConnectedResults = connectStateResults(
    ({ isSearchStalled, searchResults, searchState: { configure }, searching }: StateResultsProvided<T>) => {
      const defaultHits =
        searchResults?.hits.filter((hit) => hit.docType === type && (hitsFilter ? hitsFilter(hit) : true)) || []

      const isLoading = Boolean(
        searching || isSearchStalled || (configure?.filters && !configure?.filters.includes(type)),
      )
      const query = searchResults?.query || ''

      return (
        <FlatList
          /** By default the scrollEnable is false, so the mobile app can scroll on the list without problem. 
           * The reason to set scrollEnabled
           - 'isWeb' => For mobile web, we have to set scrollEnable true to make table scrollable and then trigger entire screen can be scrollable as well. And for normal big screen web, the behavior stays same.
           - '!isSmallDevice', for bigger touch screen like IPad, we have to make the table scrollable and since we set breakpoint as isSmallDevice, we set !isSmallDevice for all bigger touch screen to be able to scroll on app, for web version, it is covered by setting 'isWeb' */
          scrollEnabled={isWeb || !isSmallDevice}
          bounces={false}
          style={{ minWidth: 600 }}
          keyExtractor={(itm) => itm.objectID}
          data={updatedHits ?? defaultHits}
          ListHeaderComponent={() => (
            <View style={[styles.header, styles.tableRow]}>
              {columns.map((col) => (
                <View
                  style={{
                    flex: col.size || 1,
                    marginHorizontal: 7,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  key={col.title}
                >
                  <Text type="medium">{col.title}</Text>
                  {sortOptionKeys?.includes(col.key) && (
                    <TouchableOpacity
                      style={{ paddingLeft: 5 }}
                      onPress={() => customSortFun(defaultHits, col.key, sortRecord, setSortRecord, setUpdatedHits)}
                    >
                      {!!col.key && <FontAwesome name="sort" size={15} color="black" />}
                    </TouchableOpacity>
                  )}
                </View>
              ))}
            </View>
          )}
          renderItem={({ item }) => {
            const cols = columns.map(({ process, itemPressUrl, size = 1, key }, index) => {
              const render = process ? process : (value: T) => value[key]
              if (itemPressUrl?.(item))
                return (
                  <View key={`${item.objectID}_${key.toString()}`} style={{ flex: size, marginHorizontal: 10 }}>
                    <Touchable url={itemPressUrl(item)}>
                      <Text style={styles.link}>{render(item) as any}</Text>
                    </Touchable>
                  </View>
                )
              return (
                <View key={`${item.id}${index}`} style={{ flex: size, marginHorizontal: 10 }}>
                  {typeof render(item) === 'string' ? <Text>{render(item) as any}</Text> : <>{render(item)}</>}
                </View>
              )
            })

            return onRowPress || onRowPressUrl ? (
              <Touchable
                key={item.objectID}
                style={[styles.tableRow, { minWidth }]}
                onPress={() => onRowPress && onRowPress(item)}
                url={onRowPressUrl && onRowPressUrl(item)}
              >
                {cols}
              </Touchable>
            ) : (
              <View key={item.objectID} style={[styles.tableRow, { minWidth }]}>
                {cols}
              </View>
            )
          }}
          ListEmptyComponent={() => (
            <SkeletonContent
              containerStyle={globalStyles.flex1}
              isLoading={isLoading}
              layout={new Array(10).fill({
                width: '95%',
                height: 20,
                marginHorizontal: '2.5%',
                marginVertical: 15,
              })}
            >
              <LoaderWithMessage
                icon={type === AlgoliaDocType.CUSTOMER ? 'user-alt-slash' : 'box-open'}
                title={`No ${capitalize(type)}s`}
                loading={false}
              >
                {query ? (
                  <Text>Did not find any matching {type}s.</Text>
                ) : (
                  <Text>Once your farm has {type}s they will show up here.</Text>
                )}
              </LoaderWithMessage>
            </SkeletonContent>
          )}
        />
      )
    },
  )

  return (
    <View style={[styles.container, style]}>
      <View style={styles.searchContainer}>
        <ConnectedSearchBox
          placeholder={searchPlaceholder}
          defaultRefinement={defaultSearch}
          refinements={refinements}
          expandRefinementsInitial={expandRefinementsInitial}
        />
        {dateRange && (
          <ConnectedDateRange
            attribute={dateRange.attribute}
            timezone={dateRange.timezone}
            setUpdatedHits={setUpdatedHits}
            disableFutureDates={disableFutureDates}
          />
        )}
      </View>
      <ScrollView horizontal scrollEnabled contentContainerStyle={{ flex: 1, minWidth }}>
        <ConnectedResults />
      </ScrollView>

      <View style={styles.paginationContainer}>
        <ConnectedPagination onPress={() => setUpdatedHits(null)} />
      </View>
    </View>
  )
}

const useStyles = () =>
  useLayoutFnStyles(({ isSmallDevice, height }) => {
    return {
      container: {
        flex: 1,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: Colors.shades['100'],
        /** If it is not smallDevice or not extraSmallDevice, it means AdminTable is enabled to have nested scroll, so the maxHeight should be set to height * 0.71 (depend on the the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.*/
        maxHeight: !isSmallDevice ? height * 0.71 : 'auto',
      },
      searchContainer: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: Colors.lightGreen,
        paddingHorizontal: isSmallDevice ? 5 : 20,
        paddingVertical: 10,

        borderBottomWidth: 1,
        borderBottomColor: Colors.shades['100'],
      },
      paginationContainer: {
        backgroundColor: Colors.secondaryGreen3,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        padding: isSmallDevice ? 5 : 20,
      },
      header: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: Colors.lightGreen,
      },
      tableRow: {
        flexDirection: 'row',
        paddingVertical: 15,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        borderBottomColor: Colors.shades['100'],
      },
      rowItem: {
        flex: 1,
      },
      link: {
        color: Colors.blue,
      },
    }
  })
