import { withAuth } from '../../../hooks/withAuth'
import { CheckoutUi } from './CheckoutUi'
import { CheckoutContext, useCheckoutData } from './useCheckoutData'

function Checkout() {
  const checkoutData = useCheckoutData()

  return (
    <CheckoutContext.Provider value={checkoutData}>
      <CheckoutUi />
    </CheckoutContext.Provider>
  )
}

export default withAuth(Checkout, { noSafeAreaInsets: true })
