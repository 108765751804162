import { useCreateSearchParams } from '@components'
import { Text, Touchable } from '@elements'
import { Ionicons } from '@expo/vector-icons'
import { AlgoliaGeoDoc, AlgoliaGeoFarm } from '@models/Algolia'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { AutocompleteProvided } from 'react-instantsearch-core'
import { connectAutoComplete } from 'react-instantsearch-native'
import { ListRenderItem, TextInput, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '../../constants/Colors'
import { FarmsAndCities } from '../../navigation/types'
import { setSearchLocation } from '../../redux/actions/appState'
import { userLocationSelector } from '../../redux/selectors'

import { isMobile, isWeb } from '@/constants/Layout'
import { AutoCompleteItem, useAutoComplete } from '@/hooks/useAutoComplete'
import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import { useLayout } from '@/hooks/useLayout'
import { useDebouncedValue } from '../../hooks/useDebounce'

function HomeSearchBarComp({ refine, hits }: AutocompleteProvided<AlgoliaGeoDoc<AlgoliaGeoFarm>>) {
  const [farmSearchTerm, setSearchTerm] = useState<string>('')
  const navigation = useNavigation<StackNavigationProp<FarmsAndCities>>()
  const userLoc = useSelector(userLocationSelector)
  const farmsInputRef = useRef<TextInput>(null)
  const dispatch = useDispatch()
  const debouncedRefinement = useDebouncedValue(farmSearchTerm, 300)
  const styles = useStyles()
  const layout = useLayout()
  const {
    state: acProps,
    autoCompleteOverlay,
    hideAutocomplete,
    showAutocomplete,
    updateAutocomplete,
  } = useAutoComplete()

  const items: AutoCompleteItem<AlgoliaGeoDoc<AlgoliaGeoFarm>>[] = useMemo(
    () => hits.map((hit) => ({ text: hit.name, data: hit })),
    [hits],
  )

  /** Updates the autocomplete list on change */
  useDeepCompareFocusFx(() => {
    if (farmsInputRef.current?.isFocused()) updateAutocomplete(farmsInputRef, items)
  }, [items, updateAutocomplete])

  /** Performs farm search refinement on debounced change */
  useFocusFx(() => {
    refine(debouncedRefinement)
  }, [debouncedRefinement, refine])

  const createSearchParams = useCreateSearchParams()

  const searchByCurrLocation = useCallback(() => {
    hideAutocomplete()
    if (userLoc) dispatch(setSearchLocation({ ...userLoc }))
    //Navigate to Explore either directly or with the full nested navigator path
    if (navigation.getState().routeNames.includes('ExploreScreen')) {
      navigation.navigate(
        'ExploreScreen',
        createSearchParams({ coords: userLoc?.coordinate, refinement: farmSearchTerm }),
      )
    } else {
      navigation.navigate('Consumer', {
        screen: 'Home',
        params: {
          screen: 'ExploreScreen',
          params: createSearchParams({ coords: userLoc?.coordinate, refinement: farmSearchTerm }),
        },
      })
    }
  }, [createSearchParams, dispatch, farmSearchTerm, navigation, userLoc, hideAutocomplete])

  const onItemPress = useCallback(
    (item: AutoCompleteItem<AlgoliaGeoDoc<AlgoliaGeoFarm>>) => {
      /** This is only for mobile because the Touchable will navigate with url in web */
      if (isMobile) {
        navigation.navigate('Consumer', {
          screen: 'Shopping',
          params: { screen: 'FarmDetailScreen', params: { farmSlug: item.data.farm.id, goBack: 'home' } },
        })
      }
      hideAutocomplete()
    },
    [hideAutocomplete, navigation],
  )

  const farmRenderItem: ListRenderItem<AutoCompleteItem<AlgoliaGeoDoc<AlgoliaGeoFarm>>> = useCallback(
    ({ item }) => {
      return (
        <Touchable
          /** On web, it uses url for navigation. On mobile, it uses navigate(), in an equivalent way */

          onPress={() => onItemPress(item)}
          style={styles.valueCont}
          url={isWeb ? `/farms/${item.data.id}?goBack=home` : undefined}
          key={item.text}
        >
          <Text size={14}>{item.data.name}</Text>
        </Touchable>
      )
    },
    [onItemPress, styles.valueCont],
  )

  const showFarmResults = useCallback(() => {
    showAutocomplete('farmSearch', farmsInputRef, items, undefined, {
      matchWidth: true,
      renderItem: farmRenderItem,
    })
  }, [showAutocomplete, farmRenderItem, items])

  /** Updates the autocomplete list on change */
  useDeepCompareFocusFx(() => {
    if (farmsInputRef.current?.isFocused())
      acProps?.value
        ? updateAutocomplete(farmsInputRef, items)
        : showAutocomplete('farmSearch', farmsInputRef, items, undefined, {
            matchWidth: true,
            renderItem: farmRenderItem,
          })
    // Only intended to run when items change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  /** This is helpful when the autocomplete has hidden and then the user presses the textInput area. Will make the dropdown reappear */
  const onTouchStart = useCallback(() => {
    /** This should display the results on mobile. However this has a bad behavior on web, so if not mobile it should return */
    if (!isMobile) return
    if (acProps && !acProps.value)
      showAutocomplete('farmSearch', farmsInputRef, items, undefined, {
        matchWidth: true,
        renderItem: farmRenderItem,
      })
  }, [acProps, items, farmRenderItem, showAutocomplete])

  const onChangeText = useCallback(
    (text: string) => {
      setSearchTerm(text)
      if (acProps && !acProps.value)
        showAutocomplete('farmSearch', farmsInputRef, items, undefined, {
          matchWidth: true,
          renderItem: farmRenderItem,
        })
    },
    [setSearchTerm, acProps, farmsInputRef, items, farmRenderItem, showAutocomplete],
  )

  return (
    <View style={styles.container}>
      {autoCompleteOverlay}
      <Ionicons name="search" size={layout.isSmallDevice ? 20 : 30} color={Colors.shades[200]} />
      <View style={styles.spacing} />
      <TextInput
        placeholderTextColor={Colors.shades[300]}
        placeholder="What farm are you looking for?"
        style={styles.input}
        onSubmitEditing={searchByCurrLocation}
        ref={farmsInputRef}
        onChangeText={onChangeText}
        value={farmSearchTerm}
        onFocus={showFarmResults}
        onTouchStart={onTouchStart}
      />
    </View>
  )
}

export const HomeSearchBar = connectAutoComplete(memo(HomeSearchBarComp))

const useStyles = CreateResponsiveStyle(
  {
    container: {
      maxWidth: 700,
      minWidth: '50%',
      borderRadius: 10,
      borderWidth: 1,
      borderColor: Colors.shades['200'],
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.white,
      padding: 10,
      flexDirection: 'row',
    },
    icon: {
      width: 70,
      color: Colors.shades['500'],
      alignSelf: 'center',
    },
    input: {
      flex: 1,
      height: 38,
      fontSize: 18,

      paddingRight: 20,
    },

    spacing: {
      width: 30,
    },
    valueCont: {
      paddingHorizontal: 10,
      paddingVertical: 15,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      container: {
        width: '100%',
        padding: 5,
      },
      spacing: {
        width: 10,
      },
      input: {
        fontSize: 14,
        paddingRight: 10,
      },
    },
  },
)
