import { Button, hideModal, Text } from '@elements'
import { ProdErrorsMap, ValidationErrObj } from '@helpers/distributionEditing'
import { DistributionConstraint } from '@models/Distribution'
import { Product } from '@models/Product'
import { ScrollView, StyleSheet, View } from 'react-native'

import { ReturnStates } from './helpers/types'

import Colors from '@/constants/Colors'

export default function ScheduleEditConfirmation({
  fixableErrors,
  updatedConstraints,
  linkedProducts,
  resolve,
}: {
  fixableErrors: ProdErrorsMap<ValidationErrObj>
  updatedConstraints: Map<string, DistributionConstraint>
  linkedProducts: Product[]
  resolve: (returnState: ReturnStates.DO_NOTHING | ReturnStates.PROCEED) => void
}) {
  const prodsToUnlink: Product[] = [...fixableErrors].map(([_, obj]) => obj.product)
  const updatedProdIds = [...updatedConstraints].map(([k]) => k)
  const prodsToUpdate: Product[] = linkedProducts.filter((p) => updatedProdIds.includes(p.id))

  return (
    <ScrollView style={{ margin: 15 }}>
      {!!prodsToUpdate.length && (
        <View style={{ marginVertical: 10 }}>
          <Text style={{ marginTop: 15 }}>
            Your edits will adjust the availability of the following products to match your updated schedule:
          </Text>
          {prodsToUpdate.map((product) => (
            <View key={product.id} style={{ marginVertical: 5 }}>
              <Text size={14} type="medium">
                {product.name}
              </Text>
            </View>
          ))}
        </View>
      )}

      {!!prodsToUnlink.length && (
        <View style={{ marginVertical: 15 }}>
          <Text>The following products are incompatible with your edits and will be removed from this schedule:</Text>
          {prodsToUnlink.map((product) => (
            <View key={product.id} style={{ marginVertical: 5 }}>
              <Text size={14} type="medium">
                {product.name}
              </Text>
            </View>
          ))}
        </View>
      )}

      <Text style={{ marginVertical: 10 }}>
        If you choose "Cancel edit", no changes will be made to either the schedule or these products.
      </Text>

      <View style={styles.flexContainer}>
        <Button
          color={Colors.red}
          outline
          title="Proceed"
          onPress={() => {
            resolve(ReturnStates.PROCEED)
            hideModal()
          }}
        />
        <Button
          title="Cancel edit"
          onPress={() => {
            resolve(ReturnStates.DO_NOTHING)
            hideModal()
          }}
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  flexContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
})
