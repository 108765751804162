import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native'

type Style = ViewStyle | TextStyle | ImageStyle
type StyleObjVal = Style | (() => Style)
type StyleObj = Record<string, StyleObjVal>
type ResolvedTypeObj<Obj extends StyleObj> = {
  [K in keyof Obj]: Obj[K] extends () => infer S ? S : Obj[K]
}

const merge = (...objs: (Style | (() => Style))[]): Style =>
  objs.map((obj) => (typeof obj === 'function' ? obj() : obj)).reduce((prev, curr) => ({ ...prev, ...curr }), {})

type StyleHelpers = {
  merge: typeof merge
}
const styleHelpers: StyleHelpers = { merge }

/** Wrapper for StyleSheet.create, which allows the style object to combine styles by referencing other properties of the same object, through the JS "this" operator.
 * - This has no impact on StyleSheet performance because all the styles functions are resolved before passing into StyleSheet, and the final result is a regular stylesheet object
 */
export const createObjectStyles = <T extends StyleObj>(styleFn: (helpers: StyleHelpers) => T) => {
  const styleObj = styleFn(styleHelpers)
  const resolved = Object.entries(styleObj).map(([k, v]) => {
    let style = {} as Style
    if (typeof v === 'function') {
      const fn: typeof v = v.bind(styleObj)
      style = fn()
    } else style = v
    return [k, style]
  })
  const res = Object.fromEntries(resolved)
  return StyleSheet.create(res) as ResolvedTypeObj<T>
}
