import {
  cartAdd as cartApiAdd,
  cartAddDiscount,
  cartInit,
  cartRemoveDiscount,
  cartUpdateItem,
  cartUpdatePaymentSchedule,
  cartUpdateQuantity,
} from '@api/Cart'
import { logAddToCart } from '@api/FBAnalytics'
import { Toast } from '@elements'
import { buildCartItem } from '@helpers/builders/buildCartItem'
import { canUpdateQuantity, throwItemQuantityError } from '@helpers/canUpdateQuantity'
import mergeDeep from '@helpers/mergeDeep'
import uuid from '@helpers/uuid'
import { Cart } from '@models/Cart'
import { PromoCode } from '@models/Coupon'
import { CartItem } from '@models/Order'
import { PaymentSchedule } from '@models/Product'
import { ConflictingFarmError, InsufficientStockError, OutOfStockError } from '@shared/errors/cart'
import { UpdateCartItemFields } from '@shared/types/v2/cart'
import { dequal } from 'dequal'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { conflictingCartAlert, insufficientStockAlert, outOfStockAlert } from '../../hooks/useCart'
import { useCartInfo, useSelectorRoot } from '../../redux/selectors'
import { AddToCartServiceOpts, CartService } from '../types/cartService'

import { Logger } from '@/config/logger'
import { useCancelableFx } from '@/hooks/useCancelablePromise'
import { setConsumerCartFarm, updateCartInfo } from '@/redux/actions/appPersist'
import { setCartService, setNavProps } from '@/redux/actions/appState'
import { cartsCollection } from '@api/framework/ClientCollections'
import { formatMoney } from '@helpers/display'
import { extendErr, retry } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { calculatePayments } from '@helpers/order'
import { sortCartItems } from '@helpers/sorting'

/** Provides helpers via redux, which facilitate interacting with the shopping cart service.
 * - It sets the cart service to redux for both admin and consumer cart.
 *
 * @param isAdmin this controls the behavior to be either consumer side or admin side (order creator). This value is different from isAdminOpen, because this is intended to remain constant, while isAdminOpen will become true or false depending on the navigation state.
 */
export function useSetCartService(isAdmin = false): CartService {
  const dispatch = useDispatch()
  /** Selects the user based on the admin mode */
  const user = useSelectorRoot((rs) => (isAdmin ? rs.adminState.orderCreatorCustomer : rs.user), dequal)
  const { cartFarmId, cartId, sessionId } = useCartInfo(isAdmin)
  const [discount, setDiscount] = useState<Cart['discount']>()
  const [items, setItems] = useState<CartItem[]>([])
  const [loading, setLoading] = useState(true)

  /** Initialize cart session, and update it whenever user id changes, or if sessionId is cleared (On checkout, or certain errors). */
  useCancelableFx(
    async (isCurrent) => {
      const newSessionId = sessionId || uuid()
      const userId = user?.id || undefined //Prevent an empty string from being sent as user id, given that the user redux state might be initialized as a dummy user object
      const newCartId = await cartInit(newSessionId, userId, isAdmin)
      if (!isCurrent) return

      if (newSessionId !== sessionId || newCartId !== cartId) {
        dispatch(updateCartInfo(newSessionId, newCartId, isAdmin))
      }
    },
    [user?.id, sessionId, cartId, dispatch, isAdmin],
    () => Toast(cartId ? "Couldn't update the cart" : "Couldn't initialize the cart"),
  )

  /** Listens to the cart data and sets it */
  useEffect(() => {
    if (!cartId) return
    return cartsCollection.snapshotDoc(cartId, (snap) => {
      if (!snap) {
        setItems([])
        setDiscount(undefined)
        setLoading(false)
        return
      }
      setItems(Object.values(snap.items).sort(sortCartItems))
      setDiscount(snap.discount)
      setLoading(false)
      /** Only for the consumer cart, update the cart farm in context. This is not necessary for the admin cart because the cartFarm never changes */
      if (!isAdmin) dispatch(setConsumerCartFarm(snap.farm?.id))
    })
  }, [cartId, dispatch, isAdmin])

  /** `addToCart` is the helper for components to add an item to remote cart */
  const addToCart = useCallback(
    async ({
      product,
      distribution,
      unit,
      price,
      pickups,
      csa,
      paymentSchedule,
      replace,
    }: AddToCartServiceOpts): Promise<CartItem | void> => {
      setLoading(true)
      try {
        if (!cartId) throw new Error('cart must be initialized')
        if (cartFarmId && product.farm.id !== cartFarmId && !replace) throw new ConflictingFarmError() //here we're checking client side whether the cartFarmId conflicts, to reduce wait time. However, server should be able to handle this by itself, so as to be independent from our own client
        let newItem = buildCartItem({
          product,
          distribution,
          unit,
          price,
          pickups,
          csa,
          paymentSchedule,
          isAdmin,
        })
        // Check the item quantities don't exceed the stock
        if (!canUpdateQuantity({ cartItem: newItem, cart: items })) {
          throwItemQuantityError(newItem.product)
        }

        newItem = await cartApiAdd({ cartId, ...newItem, isAdmin, replace })

        //If we did not replace the cart, we can update our local cart
        if (!replace)
          setItems((items) => {
            if (items.find((itm) => itm.id === newItem.id))
              return items.map((item) => (item.id === newItem.id ? newItem : item)).sort(sortCartItems)
            else return items.concat(newItem).sort(sortCartItems)
          })
        else setItems([newItem]) //if we replaced the cart, set the new item as the only item in cart

        logAddToCart(product.id, distribution?.id || 'digital_product', product.farm.id)
        setLoading(false)
        return newItem
      } catch (err) {
        setLoading(false)
        if (err instanceof ConflictingFarmError) {
          const replaceCart = await conflictingCartAlert()
          if (replaceCart) {
            return addToCart({
              product,
              distribution,
              unit,
              price,
              pickups,
              csa,
              paymentSchedule,
              replace: true, //try calling itself again with replace `true`
            })
          }
          return // If user chose not to replace cart, return void instead of throwing the error
        } else if (err instanceof OutOfStockError) {
          dispatch(setNavProps())
          return outOfStockAlert()
        } else if (err instanceof InsufficientStockError) {
          dispatch(setNavProps())
          return insufficientStockAlert()
        }
        // Any other errors should be thrown so they can get handled by whoever called addToCart
        throw err
      }
    },
    [cartId, cartFarmId, items, dispatch, isAdmin],
  )

  /** removeDiscount removes the cart's discount */
  const removeDiscount = useCallback(async () => {
    if (!cartId) {
      Logger.error('Cart was not initialized when removeCartDiscount was called', cartId)
      return
    }
    try {
      setLoading(true)
      await cartRemoveDiscount(cartId)
      setDiscount(undefined)
      setLoading(false)
      return undefined
    } catch (e) {
      setLoading(false)
      throw e
    }
  }, [cartId])

  /** `updateQuantity` changes the quantity of an item in the cart */
  const updateQuantity = useCallback(
    async (itemId: string, newQuantity: number): Promise<CartItem | void> => {
      if (!cartId) {
        return Logger.error(new Error('Cart was not initialized when updateQuantity was called. itemId:' + itemId))
      }
      try {
        setLoading(true)

        const cartItem = items.find((itm) => itm.id === itemId)
        if (!cartItem) throw new Error('This item id is not in the cart')

        if (!canUpdateQuantity({ cart: items, cartItem, delta: newQuantity - cartItem.quantity })) {
          throwItemQuantityError(cartItem.product)
        }

        const newItem = await cartUpdateQuantity(cartId, itemId, newQuantity)

        let newItems: CartItem[]
        if (newItem.quantity === 0) {
          newItems = items.filter((item) => item.id !== newItem.id)
        } else {
          newItems = items.map((item) => (item.id === newItem.id ? newItem : item))
        }

        setItems(newItems)
        setLoading(false)
        return newItem
      } catch (err) {
        setLoading(false)

        if (err instanceof OutOfStockError) {
          dispatch(setNavProps())
          return outOfStockAlert()
        } else if (err instanceof InsufficientStockError) {
          dispatch(setNavProps())
          return insufficientStockAlert()
        }
        // Any other errors should be thrown so they can get handled by whoever called addToCart
        throw err
      }
    },
    [cartId, items, dispatch],
  )

  /** If there is a discount on the cart, check if the cart subtotal is above the minimum on items change */
  useCancelableFx(
    async (isCurrent) => {
      if (!discount) return

      // Get the cart subtotal by calculating the payments and getting the one due first which the discount is being applied too
      const cartSubtotal = calculatePayments({ items })?.[0].subtotal
      const orderMinimum = discount.promo?.orderMinimum
      if (cartSubtotal && orderMinimum && MoneyCalc.isLessThan(cartSubtotal, orderMinimum)) {
        try {
          await retry(removeDiscount)
          if (!isCurrent) return

          Toast(
            `Your promo code was removed because your order does not meet the minimum of ${formatMoney(orderMinimum)}`,
          )
        } catch (error) {
          if (!isCurrent) return

          Logger.error(extendErr(error, "A discount couldn't be removed when it was no longer valid in the cart."))
          Toast(
            'Your cart no longer supports the current discount and there was a problem updating it. Please check your internet connection and reload.',
          )
        }
      }
    },
    [items, discount, removeDiscount],
  )

  /** `updatePaySchedule` changes the payment schedule of an item in the cart */
  const updatePaySchedule = useCallback(
    async (itemId: string, paymentSchedule: PaymentSchedule): Promise<CartItem | void> => {
      if (!cartId) {
        return Logger.error(new Error('Cart was not initialized when updatePaySchedule was called. itemId:' + itemId))
      }
      try {
        setLoading(true)
        const newItem = await cartUpdatePaymentSchedule(cartId, itemId, paymentSchedule)
        setItems((items) => items.map((item) => (item.id === newItem.id ? newItem : item)))
        setLoading(false)
        return newItem
      } catch {
        setLoading(false)
      }
    },
    [cartId],
  )

  /** addDiscount adds a promo discount to the cart to be applied to the order */
  const addDiscount = useCallback(
    async (promoId: PromoCode['id'], hasEbtPayment: boolean): Promise<Cart['discount']> => {
      if (!cartId) {
        Logger.error('Cart was not initialized when addCartDiscount was called', cartId)
        return
      }
      try {
        setLoading(true)
        const discount = await cartAddDiscount({ cartId, id: promoId, type: 'promo' })

        // If this is an EBT only coupon then make sure that there is an EBT payment added
        if (discount?.coupon.ebtOnly && !hasEbtPayment) {
          removeDiscount()
          throw new Error('You must select an EBT payment method in order to apply this discount')
        }
        setDiscount(discount)
        setLoading(false)
        return discount
      } catch (e) {
        setLoading(false)
        throw e
      }
    },
    [cartId, removeDiscount],
  )

  /** updateCartItem updates the data of an item in cart, in a freestyle way, though with cartItem validation */
  const updateCartItem = useCallback(
    async (itemId: string, update: UpdateCartItemFields): Promise<CartItem | void> => {
      if (!cartId) {
        return Logger.error(new Error('Cart was not initialized when updatePaySchedule was called. itemId:' + itemId))
      }
      try {
        setLoading(true)
        const oldItm = items.find((itm) => itm.id === itemId)
        if (!oldItm) throw new Error("This cart item wasn't found in the cart")
        let updatedItem = mergeDeep(oldItm, update as Partial<CartItem>)
        buildCartItem({ ...updatedItem, isAdmin }) // only validate, but don't re-create, because building initializes quantity to 1

        // This service is not validating for quantity at this layer because it's best to also refresh the item with the latest product data, which is more suitable if done server side because here we only have access to the product copy currently in the cart

        updatedItem = await cartUpdateItem(cartId, itemId, update, isAdmin)
        setItems((items) => items.map((item) => (item.id === updatedItem.id ? updatedItem : item)))
        setLoading(false)
        return updatedItem
      } catch (err) {
        setLoading(false)
        if (err instanceof OutOfStockError) {
          dispatch(setNavProps())
          return outOfStockAlert()
        } else if (err instanceof InsufficientStockError) {
          dispatch(setNavProps())
          return insufficientStockAlert()
        }
        // Any other errors should be thrown so they can get handled by whoever called addToCart
        throw err
      }
    },
    [cartId, items, isAdmin, dispatch],
  )

  const service = useMemo(
    (): CartService => ({
      isCartInit: !!cartId,
      cart: items,
      discount,
      addToCart,
      loadingCart: loading,
      updateQuantity,
      addDiscount,
      removeDiscount,
      updatePaySchedule,
      updateCartItem,
    }),
    [
      items,
      discount,
      addToCart,
      addDiscount,
      removeDiscount,
      loading,
      updateQuantity,
      updatePaySchedule,
      updateCartItem,
      cartId,
    ],
  )

  /** Set the Cart Service helpers to redux, for either the admin or consumer cart */
  useEffect(() => {
    dispatch(setCartService(service, isAdmin))
  }, [service, isAdmin, dispatch])

  return service
}

export const initialCartService: CartService = {
  cart: [],
  discount: undefined,
  loadingCart: true,
  isCartInit: false,
  addToCart: async () => undefined,
  addDiscount: async () => undefined,
  removeDiscount: async () => undefined,
  updateQuantity: async () => undefined,
  updatePaySchedule: async () => undefined,
  updateCartItem: async () => undefined,
}
