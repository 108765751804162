/** A cancelable promise */
export interface Cancelable<T = any> extends Promise<T> {
  /** will reject the Promise wrapper*/
  cancel(reason?: string): void
}

/**
 * A Cancelation-aware reject handler can distinguish a failure from cancelation by looking at the error type (With instanceof)
 * The setPrototypeOf call is needed so instanceof can be used to detect an instance of this error type.
 */
export class CanceledError extends Error {
  constructor(reason = '') {
    super(reason)
    this.name = 'CanceledError'
    Object.setPrototypeOf(this, CanceledError.prototype)
  }
}

/**
 * @param promise a normal Promise
 * @param onErr an error handler for the promise. It will only be called if the inner promise is rejected. Wont be called when the cancelable promise is rejected/canceled
 * @returns a cancelable promise
 */
export function cancelable<T>(promise: Promise<T>, onErr?: (err: unknown) => void): Cancelable<T> {
  let cancel: Cancelable['cancel']

  const promiseWrapper = new Promise((resolve, reject) => {
    // We assign 'cancel' to a fn that calls reject
    cancel = (reason = 'Promise was canceled') => {
      reject(reason)
    }
    // Lets the inner promise run its course, and handle rejection
    promise.then(resolve).catch((err) => {
      onErr?.(err)
      reject(err) // If the inner promise fails, the outer promise will be rejected too
    })
  }) as Cancelable<T>
  promiseWrapper.cancel = cancel!

  promiseWrapper.catch(() => {
    /** No need to do anything because this just means either 1) the promise wrapper got cancelled, or 2) the inner promise was rejected and had a trickle up effect, rejecting this one too. But in case #2 we also don't need to handle it here because the inner rejection would already have been handled inside the inner catch() */
  })

  return promiseWrapper
}
