import { isArray, isTruthy, removeUndefined } from '@helpers/helpers'
import { isEncodedTime } from '@helpers/time'
import { Cart } from '@models/Cart'
import { CartItem } from '@models/Order'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalCSA, unmarshalCSA } from './CSA'
import { marshalPromoCode, unmarshalCoupon, unmarshalPromoCode } from './Coupons'
import { marshalDistribution, unmarshalDistribution } from './Distribution'
import { marshalPaymentSchedule, marshalProduct, unmarshalPaymentSchedule, unmarshalProduct } from './Product'
import { marshalDate, Time, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** A marshaled cart item */
export type MarshaledCartItem = Omit<CartItem, 'pickups' | 'paymentSchedule' | 'product' | 'csa' | 'distribution'> & {
  pickups?: Time[]
  paymentSchedule?: DocumentData
  product?: DocumentData
  csa?: DocumentData
  distribution?: DocumentData
}

/** Marshaled cart */
type MarshaledCart = Omit<Cart, 'items'> & {
  /** Items are encoded as an object where the key is the cartitem id. this makes database updates easier than with an array, as is done in the regular Cart model. */
  items: Record<string, MarshaledCartItem>
}

/** marshalCart returns an encoded value. */
export function marshalCart(cart: Partial<Cart>): Partial<MarshaledCart> {
  const data = prepareMarshal(cart) as DocumentData

  if (cart.items) {
    const items: Record<string, MarshaledCartItem> = {}
    for (const id in cart.items) {
      items[id] = marshalCartItem(cart.items[id])
    }
    data.items = items
  }

  if (cart.discount?.promo) {
    data.discount.promo = marshalPromoCode(cart.discount.promo)
  }

  return data
}

/** unmarshalCart returns a decoded value. */
export function unmarshalCart(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Cart {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)

  const cart = { ...data, id } as Cart

  const unmarshaledItems: Cart['items'] = {}
  for (const item of Object.values(cart.items)) {
    unmarshaledItems[item.id] = unmarshalCartItem(item)
  }

  if (data.discount) {
    cart.discount = {
      promo: data.discount.promo ? unmarshalPromoCode(data.discount.promo) : undefined,
      coupon: unmarshalCoupon(data.discount.coupon),
    }
  }

  return { ...cart, id, items: unmarshaledItems }
}

export function marshalCartItem(item: Partial<CartItem>): MarshaledCartItem {
  const data = { ...item } as MarshaledCartItem
  if (item.pickups) data.pickups = item.pickups.map((date) => marshalDate(date))
  if (item.paymentSchedule) data.paymentSchedule = marshalPaymentSchedule(item.paymentSchedule)
  if (item.product) data.product = marshalProduct(item.product, true)
  if (item.csa) data.csa = marshalCSA(item.csa, true)
  if (item.distribution) data.distribution = marshalDistribution(item.distribution, true)
  return removeUndefined(data)
}

export function unmarshalCartItem(data: DocumentData): CartItem {
  const item = { ...data } as CartItem
  if (data.pickups && isArray(data.pickups))
    item.pickups = data.pickups.map((date) => isEncodedTime(date) && unmarshalDate(date)).filter(isTruthy)
  if (data.paymentSchedule) item.paymentSchedule = unmarshalPaymentSchedule(data.paymentSchedule)
  if (data.product) item.product = unmarshalProduct(item.product.id as string, data.product)
  if (data.csa) item.csa = unmarshalCSA(data.csa.id as string, data.csa)
  if (data.distribution) item.distribution = unmarshalDistribution(data.distribution.id as string, data.distribution)
  return item
}
