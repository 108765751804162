import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { ButtonClear, DropdownMenu, Text } from '@elements'
import { Ionicons } from '@expo/vector-icons'
import { plural } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Zero } from '@models/Money'
import { PickupItemStatus } from '@models/Order'
import { formatPaymentStatus } from '@models/Summary'
import { userName } from '@models/User'
import React, { memo } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { ListItem } from 'react-native-elements'
import { GroupedItemType, getUserStatus, isDistroSummaryItemSkipped, isReceived } from '../helpers'
import { SignInSummaryOrder } from './SignInSummaryOrder'
import { StatusIcon } from './StatusIcon'

type Props = {
  item: GroupedItemType
  isExpanded?: boolean
  onToggleExpanded?: (userId: string) => void
  onOrderDetailsPress?: (userId: string) => void
  onUpdateStatus?: (userId: string, status: PickupItemStatus) => void
}

/** Multi-use component that represents an user and his items. This component is:
 * - swipeable, and swipe action will affect all the user's items
 * - expandable, when the user items can be collapsed or expanded
 */
export const SignInUserRow = memo(function SignInUserRow(props: Props) {
  const { item, isExpanded = false, onToggleExpanded, onOrderDetailsPress, onUpdateStatus } = props
  const isItemReceived = isReceived(item)

  /** Wrapper that activates mobile swiping */
  function Wrapper({ children }: { children: JSX.Element }) {
    if (isWeb) return <>{children}</>
    return (
      <ListItem.Swipeable
        containerStyle={styles.padding0}
        leftContent={!isExpanded && isItemReceived && <LeftContent {...props} />}
        rightContent={!isExpanded && !isItemReceived && <RightContent {...props} />}
        key={item.user.id}
      >
        {children}
      </ListItem.Swipeable>
    )
  }

  return (
    <>
      <Wrapper>
        <View style={styles.customerCard}>
          <ListItem.Accordion
            style={styles.accordion}
            isExpanded={isExpanded}
            onPress={() => onToggleExpanded?.(item.user.id)}
            content={<Content onUpdateStatus={onUpdateStatus} item={item} />}
          >
            {item.orders.map((orderData) => (
              <SignInSummaryOrder key={orderData.orderInfo.id} orderData={orderData} />
            ))}
            <View style={globalStyles.margin10} />
            <ButtonClear
              style={styles.linkBtn}
              textStyle={styles.linkText}
              title="Contact Customer and Order Details"
              onPress={() => onOrderDetailsPress?.(item.user.id)}
            />
          </ListItem.Accordion>
        </View>
      </Wrapper>
      <View style={globalStyles.margin10} />
    </>
  )
})

/** User data that is always visible */
const Content = memo(function Content(props: Props) {
  const { item } = props
  const totalItems = item.orders.flatMap((ord) => ord.items)
  const isUnpaid = MoneyCalc.isGTZero(item.order_due_amounts ?? Zero)
  // Determine if there is at least 1 item that is not cancelled or on vacation, which is the only time we should show mark all as button
  const hasEditableItem = !item.orders.flatMap((ords) => ords.items).every(isDistroSummaryItemSkipped)

  return (
    <View style={styles.content}>
      <View style={styles.nameAndIconCont}>
        <StatusIcon status={getUserStatus(item)} />
        <View style={styles.margin5} />
        <View style={globalStyles.flex1}>
          <Text numberOfLines={2} type="medium" size={16}>
            {userName(item.user)}
          </Text>
          <Text>{`${totalItems.length} ${plural(totalItems.length, 'item')}`}</Text>
        </View>
      </View>
      <View style={globalStyles.flexRow}>
        {isWeb && hasEditableItem && <ActionButtons {...props} />}
        <View style={styles.margin5} />
        <View>
          <Text color={isUnpaid ? Colors.red : undefined}>
            {isUnpaid ? formatPaymentStatus(item.paymentStatus) : 'PAID'}
          </Text>
          <Text style={styles.orderText}>{`${item.orders.length} ${plural(item.orders.length, 'Order')}`}</Text>
        </View>
      </View>
    </View>
  )
})

/** (Right Swipe) Action for  the User component. Can be used if the component is not expanded
 */
const RightContent = memo(function RightContent(props: Props): React.ReactNode {
  const { item, onUpdateStatus } = props

  return (
    <View style={styles.actionBtnsCont}>
      <Pressable
        onPress={() => onUpdateStatus?.(item.user.id, PickupItemStatus.Donated)}
        style={styles.donationBtnCont}
      >
        <Ionicons name="heart" size={16} color={Colors.white} />
        <Text color={Colors.white} type="medium" size={12}>
          Donation
        </Text>
      </Pressable>

      <Pressable
        onPress={() => onUpdateStatus?.(item.user.id, PickupItemStatus.Received)}
        style={styles.receivedBtnCont}
      >
        <Ionicons name="checkmark-circle" size={16} color={Colors.white} />
        <Text color={Colors.white} type="medium" size={12}>
          Received
        </Text>
      </Pressable>
    </View>
  )
})

/** (Left Swipe) Undo for the User component. Can be used if the component is not expanded and the User has no Active state
 */
const LeftContent = memo(function LeftContent(props: Props) {
  const { item, onUpdateStatus } = props

  return (
    <Pressable onPress={() => onUpdateStatus?.(item.user.id, PickupItemStatus.Active)} style={styles.undoCont}>
      <Text color={Colors.white} type="medium" size={14}>
        Undo
      </Text>
    </Pressable>
  )
})

/** Dropdown action buttons used in web, for updating a user status */
function ActionButtons(props: Props) {
  const { item, onUpdateStatus } = props

  const buttons = [
    { title: 'Received', onPress: () => onUpdateStatus?.(item.user.id, PickupItemStatus.Received) },
    { title: 'Donated', onPress: () => onUpdateStatus?.(item.user.id, PickupItemStatus.Donated) },
    { title: 'Not received', onPress: () => onUpdateStatus?.(item.user.id, PickupItemStatus.Active) },
  ]

  return (
    <DropdownMenu buttons={buttons} data={undefined}>
      <Text color={Colors.green} type="medium">
        Mark all as
      </Text>
    </DropdownMenu>
  )
}

const styles = StyleSheet.create({
  actionBtnsCont: {
    flex: 1,
    flexDirection: 'row',
  },

  receivedBtnCont: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.green,
  },
  donationBtnCont: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.darkGreen,
  },
  undoCont: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.shades['200'],
  },
  linkBtn: {
    alignSelf: 'center',
  },

  accordion: {
    borderBottomWidth: 1,
    borderColor: Colors.shades['100'],
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    borderColor: Colors.shades['100'],
  },

  customerCard: {
    borderRadius: 8,
    borderColor: Colors.shades[100],
    borderWidth: 1,
    width: '100%',
    overflow: 'hidden',
  },
  margin5: {
    margin: 5,
  },
  orderText: {
    alignSelf: 'flex-end',
  },
  linkText: {
    fontWeight: '400',
    fontSize: 13,
    textDecorationLine: 'underline',
  },
  padding0: {
    padding: 0,
  },
  nameAndIconCont: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
})
