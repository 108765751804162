import { sendUserMessage } from '@api/Messaging'
import { TextInputBox } from '@components'
import { Button, hideModal, KeyboardAvoidingScrollView, Text, Toast } from '@elements'
import { Farm } from '@models/Farm'
import { User } from '@models/User'
import { useState } from 'react'
import { StyleSheet } from 'react-native'

type SendMessageProps = {
  to: User
  from: Farm
  farmerId: string
  defaultMessage?: boolean | string
}

/** SendMessage renders a component which accepts a message to be sent to a user. */
export function SendMessage({ to, farmerId, from, defaultMessage }: SendMessageProps): JSX.Element {
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState(defaultMessageContent(defaultMessage, to, from))
  const [error, setError] = useState<string | false>(false)
  const [sending, setSending] = useState(false)

  async function handleSend(): Promise<void> {
    if (!message) {
      setError('A message is required')
      return
    }

    setError(false)
    setSending(true)

    // TODO: Allow send to farm message.
    await sendUserMessage(to, from, farmerId, subject, message)
    setSending(false)
    Toast('Message sent successfully!')
    hideModal()
  }

  return (
    <KeyboardAvoidingScrollView>
      {!!error && <Text>{error}</Text>}
      <TextInputBox placeholder="Subject" value={subject} onChangeText={setSubject} disabled={sending} />
      <TextInputBox
        placeholder="Message (We will include greeting and farm name, so no need to include those.)"
        value={message}
        onChangeText={setMessage}
        inputStyle={styles.textArea}
        disabled={sending}
        multiline
      />
      <Button title="Send" loading={sending} onPress={handleSend} />
    </KeyboardAvoidingScrollView>
  )
}

// defaultMessageContent returns a default message to be shown for the component's configuration.
function defaultMessageContent(defaultMessage: boolean | string | undefined, to: User, from: Farm): string {
  if (!defaultMessage) {
    return ''
  }
  if (typeof defaultMessage === 'string') {
    return defaultMessage
  }
  return `Dear ${to.name.firstName},



  Warmly,
  ${from.name}
  `
}

const styles = StyleSheet.create({
  textArea: {
    padding: 10,
    height: 150,
  },
})
