import { loadProductsByDistribution } from '@api/Products'
import { validateLinkedProducts } from '@helpers/distributionEditing'
import { removeUndefined } from '@helpers/helpers'
import { PartialPick, pick } from '@helpers/typescript'
import { Distribution, editableFieldsSchedule } from '@models/Distribution'
import { dequal } from 'dequal'

import { ReturnStates, ReturnTypes } from './types'
import { blockEdit, confirmationPrompt, locationChangePrompt } from './validationPrompts'

/**
 * Will check whether anything changed, and whether it is important
 * @param oldDist the old distribution to compare against
 * @param newDist the new distribution to compare to
 * @return value a promise containing the data that was updated
 */
export function validateDistributionEdit(
  oldDist: Distribution,
  newDist: Distribution,
): Promise<ReturnTypes<PartialPick<Distribution, 'id'>>> {
  return new Promise(async (resolve) => {
    // Select the values that could've been updated to compare with
    const newData = removeUndefined(pick(newDist, ...editableFieldsSchedule))

    // If we are editing get the old values to compare with
    const oldData = removeUndefined(pick(oldDist, ...editableFieldsSchedule))

    // Check that we actually made a change
    if (dequal(newData, oldData)) {
      resolve({ status: ReturnStates.GO_BACK })
    }

    /** Note: Here, it is expected that we're loading all products related to the distribution regardless of
     * their status: I.e. include inactive, and hidden. Make sure the api `loadProductsByDistribution()` doesn't
     * do any filtering. */
    const linkedProducts = await loadProductsByDistribution(oldDist.id, newDist.farm.id)

    // If we are updating the schedule we must do additional checks
    if (!dequal(newData.schedule, oldData.schedule)) {
      const { blockingErrors, fixableErrors, updatedConstraints } = await validateLinkedProducts(
        oldDist,
        newDist,
        linkedProducts,
      )

      // If we have any affected products with errors that aren't fixable, open a modal to display details. Update will be blocked
      if (blockingErrors.size > 0) {
        const res = await blockEdit(blockingErrors)
        resolve(res)
      } else if (fixableErrors.size > 0 || updatedConstraints.size > 0) {
        // Standard products with distro errors can be fixed by unlinking the newDist from the product, so ask for approval
        const status = await confirmationPrompt(fixableErrors, updatedConstraints, linkedProducts)
        if (status !== ReturnStates.PROCEED) resolve({ status })
      }
    }

    const data = { ...newData, id: oldDist.id }

    if (!dequal(newData.location, oldData.location)) {
      // If we update the location warn the farmer to contact customers
      locationChangePrompt({ before: oldData.location, after: newData.location }).then((status) =>
        resolve(status === ReturnStates.PROCEED ? { status: ReturnStates.SAVE, data, linkedProducts } : { status }),
      )
    } else {
      resolve({ status: ReturnStates.SAVE, data, linkedProducts })
    }
  })
}
