import { memo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import IconWithBadge from './elements/Icon/IconWithBadge'

import { useCartService } from '@/hooks/useCart'

export default memo(function ShoppingCart({
  color,
  style,
  testID,
}: {
  color?: string
  style?: StyleProp<ViewStyle>
  testID?: string
}) {
  const { cart } = useCartService()

  return (
    <IconWithBadge
      style={style}
      size={22}
      name="shopping-cart"
      badgeValue={cart.length}
      url="/farms/shop/my-cart"
      color={color}
      testID={testID}
    />
  )
})
