import { Text, Icon } from '@elements'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import Colors from '../../../constants/Colors'
import { SHADOW_2 } from '../../../constants/Styles'
import { useDeviceSize } from '../../../hooks/useLayout'
import { FilterMenu } from '../Filters'

export default function MapControls({ showRedo, redoSearch }: { showRedo: boolean; redoSearch: () => void }) {
  const { isLargeDevice } = useDeviceSize()

  return (
    <View style={styles.mainContainer}>
      {isLargeDevice && <FilterMenu />}
      {showRedo && (
        <View style={styles.redoContainer}>
          <TouchableOpacity style={styles.redoButton} onPress={redoSearch}>
            <Icon name="redo" color="white" size={14} />
            <Text style={styles.redoText} size={12}>
              Search this area
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: { position: 'absolute', top: 0, left: 0, flexDirection: 'row', flexWrap: 'wrap', width: '100%' },
  redoText: {
    marginLeft: 5,
    color: Colors.white,
  },
  redoButton: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderRadius: 10,
    backgroundColor: Colors.green,
    marginVertical: 10,
  },
  redoContainer: { flex: 1, flexDirection: 'row', justifyContent: 'center', minWidth: 100, paddingHorizontal: 25 },
  control: {
    ...SHADOW_2,
    position: 'absolute',
    top: 10,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
})
