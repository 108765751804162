import { TimestampView } from '@/admin/components/TimestampView.'
import { AdminDrawerParamList } from '@/admin/navigation/types'
import { useDeviceSize } from '@/hooks/useLayout'
import { Button, HeaderText } from '@elements'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleSheet, View } from 'react-native'

type Props = {
  hasAccessProductSetup: boolean
  hasAccessCreateOrder: boolean
  /** Callback to handle - use and fetch live data instead of cache data. */
  onUseLiveDataClick: () => void
  /** Flag to determine if the data is being fetched from cache table. */
  useCache: boolean
}

/** Header component for Dashboard screen */
export function AdminDashboardHeader({
  hasAccessCreateOrder,
  hasAccessProductSetup,
  onUseLiveDataClick,
  useCache,
}: Props) {
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()
  const { isExtraSmallDevice } = useDeviceSize()

  return (
    <>
      <View>
        <HeaderText size={24}>Dashboard</HeaderText>
        <TimestampView isUsingCache={useCache} onRefreshPressed={onUseLiveDataClick} />
      </View>
      <View style={styles.titleWrapper}>
        <View>
          <HeaderText size={20}>Quick Stats</HeaderText>
        </View>
        <View style={styles.btnsCont}>
          {hasAccessProductSetup && (
            <Button
              outline
              title="View Products"
              small={isExtraSmallDevice}
              onPress={() =>
                navigation.navigate('Products', {
                  screen: 'ProductList',
                  initial: false,
                })
              }
            />
          )}
          {hasAccessCreateOrder && (
            <Button
              title="New Order"
              size={isExtraSmallDevice ? 12 : 14}
              small={isExtraSmallDevice}
              onPress={() => navigation.navigate('Orders', { screen: 'CreateOrder' })}
            />
          )}
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  btnsCont: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
})
