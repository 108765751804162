import { CartButtons, CartButtonsHelpers, EbtIcon, Image, PayScheduleExt, ResizedSuffix } from '@components'
import { Divider, Text, TextH2, TextH3, lineHeight } from '@elements'
import { InvalidAmount, formatMoney, formatShortPickupDate, plural } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { getProratedAmount } from '@helpers/order'
import { LocationTypes, formatDistributionType, isLocalPickup, isNonPickup } from '@models/Location'
import { Money, Zero, makeMoney } from '@models/Money'
import { CartItem, Pickup, isCartPhysical, isCartShare } from '@models/Order'
import { PaymentSchedule, hasUnits, isPayInFull, isPayPerPickup } from '@models/Product'
import { DateTime } from 'luxon'
import { memo, useCallback, useContext, useMemo } from 'react'
import { FlatList, ListRenderItem, View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES as SIZE_NAMES, maxSize } from 'rn-responsive-styles'

import { CheckoutContext } from './useCheckoutData'

import { globalStyles } from '@/constants/Styles'
import { useCartService } from '@/hooks/useCart'
import { useDeviceSize } from '@/hooks/useLayout'
import { useFlatStyle } from '@/hooks/useMergeStyle'
import { useToolTip } from '@/hooks/useToolTip'
import { paramsSelector } from '@/redux/selectors'
import { isNonNullish } from '@helpers/helpers'

export const CartItemsCheckout = memo(function CartItemsCheckout() {
  const { itemGroups } = useContext(CheckoutContext)
  const separatorComp = useCallback(() => <Divider clear large />, [])

  const renderItem = useCallback<ListRenderItem<CartItemGroupType>>(
    ({ item: groupData, index }) => (
      <CartItemGroup groupData={groupData} groupCounter={itemGroups.length > 1 ? index + 1 : undefined} />
    ),
    [itemGroups.length],
  )

  return (
    <FlatList
      style={globalStyles.flex1}
      data={itemGroups}
      renderItem={renderItem}
      ItemSeparatorComponent={separatorComp}
    />
  )
})

/** Provides additional delivery details for the user */
export function getDeliveryDetails({
  nDates,
  locationFee,
  combinedDeliveryDates,
  deliveryTotal,
  locType,
}: {
  nDates: number
  locationFee: Money | undefined
  combinedDeliveryDates: DateTime[]
  combinedDeliveryPickups: Pickup[]
  deliveryTotal: Money
  locType: LocationTypes | undefined
}) {
  if (!locationFee || !locType) return ''
  const hasCombinedDeliveries = combinedDeliveryDates.length > 0
  const combinedDeliveryDiscount =
    locationFee && hasCombinedDeliveries ? MoneyCalc.multiply(locationFee, combinedDeliveryDates.length) : null

  // Different ways to format the text for shipping and delivery locations
  const feeTypeText = formatDistributionType({ type: locType }, { action: true })
  const feeTypeTextPluralBox = formatDistributionType({ type: locType }, { plural: true })
  const feeTypeTextCapital = formatDistributionType({ type: locType }, { action: true, capitalize: true })

  const deliveryPluralEnd = nDates === 1 ? '' : ` for each of the ${nDates} ${feeTypeTextPluralBox}`
  const infoBillingOnDeliveryDate = `${nDates === 1 ? 'A' : 'Each'} ${feeTypeText} fee of ${formatMoney(
    locationFee,
  )} will be billed on the ${feeTypeText} date${deliveryPluralEnd}.`
  const genericDeliveryTipMsg = `\nYou will see an invoice in the Orders screen for each ${feeTypeText} fee. ${feeTypeTextCapital} fees will be combined with past orders if they are not cancelled and have a matching schedule, date and address.`
  const combinedDeliveryDetails =
    combinedDeliveryDates.length > 0
      ? `\n\nThere were ${
          combinedDeliveryDates.length
        } combined dates for the items at this location: ${combinedDeliveryDates
          .map((d) => formatShortPickupDate(d))
          .join(', ')}.`
      : ''
  const combinedDiscountDetails =
    combinedDeliveryDiscount && MoneyCalc.isGTZero(combinedDeliveryDiscount)
      ? ` You saved ${formatMoney(combinedDeliveryDiscount)} from combined ${feeTypeText} fees.`
      : ''
  const totalMsg = ` Your total new ${feeTypeText} fees at this location will be ${formatMoney(deliveryTotal)}`

  return (
    infoBillingOnDeliveryDate + genericDeliveryTipMsg + combinedDeliveryDetails + combinedDiscountDetails + totalMsg
  )
}
const indentSpace = '    '

/** Generates text with useful delivery information */
export function getDeliveryDescr(
  locationFee: Money,
  locType: LocationTypes,
  combinedDates: DateTime[],
  nDates: number,
  indent = true,
) {
  const feeTypeText = formatDistributionType({ type: locType }, { action: true })
  const mainSummary = `${indent ? indentSpace : ''}You will be billed ${formatMoney(
    locationFee,
  )} per ${feeTypeText} fee for ${nDates} ${formatDistributionType({ type: locType }, { plural: nDates !== 1 })}`

  const combinedDatesDistributionMsg = formatDistributionType({ type: locType }, { plural: true })
  const discountMsg =
    combinedDates.length > 0
      ? `, minus ${combinedDates.length} ${combinedDatesDistributionMsg} that are combined with other orders.`
      : '.'

  const theseDatesPlural = nDates === 1 ? 'this date' : 'these dates'

  const fullyCoveredMsg = `${
    indent ? indentSpace : ''
  }Your ${feeTypeText} fees from other orders covers ${theseDatesPlural}. No additional fees will be added unless your other order is cancelled.`

  if (combinedDates.length === nDates) {
    return fullyCoveredMsg
  } else return mainSummary + discountMsg
}

type GroupHeaderProps = {
  groupCounter: number | undefined
  /** title used for address or location name */
  title: string
  /** locationFee is the base location fee per delivery at this group's location. will be undefined for non delivery groups */
  locationFee?: Money
  /** total delivery fees due now for this group. will appear in the line item column. If null, means there's nothing due now for this group */
  deliveryTotalDueNow?: Money
  /** total delivery fees regardless of due date */
  deliveryTotal: Money
  /** The location type for this location group. It will be undefined for a group of digital products */
  locType?: LocationTypes
  uniqueDates: DateTime[]
  combinedDeliveryDates: DateTime[]
  combinedDeliveryPickups: Pickup[]
}

export const GroupHeader = memo(function GroupHeader({
  groupCounter,
  title,
  locationFee,
  deliveryTotal,
  deliveryTotalDueNow,
  locType,
  uniqueDates,
  combinedDeliveryDates,
  combinedDeliveryPickups, // For use later
}: GroupHeaderProps) {
  const styles = useStyles()
  const nDates = uniqueDates.length
  const hasCombinedDeliveries = combinedDeliveryDates.length > 0

  const line1 = locationFee
    ? `${formatMoney(locationFee)}/${
        locType ? formatDistributionType({ type: locType }) : 'distribution'
      } x${nDates}(${plural(nDates, 'date', 'dates')})`
    : ''

  const headerTitle = groupCounter
    ? groupCounter +
      '. ' +
      (locType === LocationTypes.Delivery
        ? 'Delivery to'
        : locType === LocationTypes.Shipping
        ? 'Shipping to'
        : isNonNullish(locType) && isLocalPickup(locType)
        ? 'Pickup at'
        : '') +
      ': ' +
      title
    : ''

  const combinedDeliveryDiscount =
    locationFee && hasCombinedDeliveries ? MoneyCalc.multiply(locationFee, combinedDeliveryDates.length) : null

  const line2 =
    hasCombinedDeliveries && combinedDeliveryDiscount
      ? `-${formatMoney(combinedDeliveryDiscount)} (combined deliveries)`
      : ''

  // locType will always be defined for products that have location fee as they can't be digital
  const deliveryDescr = locationFee ? getDeliveryDescr(locationFee, locType!, combinedDeliveryDates, nDates) : ''

  const tipInfo = getDeliveryDetails({
    locationFee,
    combinedDeliveryDates,
    nDates,
    combinedDeliveryPickups,
    deliveryTotal,
    locType,
  })

  const {
    TipButton,
    TipOverlay,
    overlayProps: { overlayStyle },
  } = useToolTip(tipInfo)

  return (
    <View>
      <TipOverlay overlayStyle={useFlatStyle([overlayStyle, styles.deliveryOverlay])} />
      <View style={styles.groupHeader}>
        <TextH2 style={styles.groupHeaderTitle}>{headerTitle}</TextH2>
        {nDates > 0 && locType && isNonPickup(locType) ? (
          <LineItem
            style={styles.lineItem}
            lines={[line1, line2]}
            lineItemAmt={deliveryTotalDueNow ? formatMoney(deliveryTotalDueNow) : ''}
          />
        ) : null}
      </View>
      {locType && isNonPickup(locType) && nDates > 0 ? (
        <TextH3 style={styles.marginLeft} children={[deliveryDescr, <TipButton key="tipButton" />]} />
      ) : null}
    </View>
  )
})

export type CartItemGroupType = {
  items: CartItem[]
  address?: string
  /** location fee for the items in the group. will be the same for all items because they're grouped by location. will be undefined for groups that are non physical or are local pickup */
  locationFee?: Money
  /** the delivery total amount for the group of items, regardless of when it's due */
  groupDeliveryTotal: Money
  groupDeliveryTotalDueNow?: Money
  /** Unique pickup dates on the physical items in a given group */
  uniqueDates: DateTime[]
  /** the location type for all the items in this group */
  locType?: LocationTypes
  /** any dates from the group which were combined with past orders */
  combinedDeliveryDates: DateTime[]
  /** any active pickups that were combined with any pickup date */
  combinedDeliveryPickups: Pickup[]
}

type CartItemGroupProps = {
  groupCounter: number | undefined
  groupData: CartItemGroupType
}

/** Renders a group of items with the same location */
export const CartItemGroup = memo(function CartItemGroup({
  groupCounter,
  groupData: { items, address = 'Online', groupDeliveryTotal, groupDeliveryTotalDueNow, ...groupProps },
}: CartItemGroupProps) {
  const styles = useStyles()
  return (
    <View style={styles.group}>
      <GroupHeader
        groupCounter={groupCounter}
        title={address}
        deliveryTotalDueNow={groupDeliveryTotalDueNow}
        deliveryTotal={groupDeliveryTotal}
        {...groupProps}
      />
      <Divider large />
      <FlatList
        style={styles.groupList}
        data={items}
        renderItem={({ item, index }) => <CartItemCheckout item={item} index={index} />}
        ItemSeparatorComponent={() => <Divider clear large />}
      />
    </View>
  )
})

function NameAndBuyingOption({ item }: { item: CartItem }) {
  const styles = useStyles()
  return (
    <View style={styles.itemNameAndUnit}>
      <Text size={15} numberOfLines={3}>
        <EbtIcon product={item.product} />
        {item.product.name}
      </Text>
      {hasUnits(item.product) ? <Text>{` - Option: ${item.unit!.name}`}</Text> : null}
    </View>
  )
}

const getQtyText = (item: CartItem) => ` x${item.quantity}(qty)`

export const getBreakdownTextLine1 = ({
  item,
  itemAmount,
  isProrated,
  baseInstallmentAmtDueToday,
}: {
  item: CartItem
  itemAmount: number | null
  isProrated: boolean
  baseInstallmentAmtDueToday: Money
}) => {
  const qty = getQtyText(item)

  if (isCartPhysical(item) && isPayInFull(item.paymentSchedule)) {
    const noun = formatDistributionType(item.distribution.location, {
      plural: item.pickups.length > 1,
    })
    const pickupMulti = item.pickups.length > 1 ? ` x${item.pickups.length}(${noun})` : ''
    if (isCartShare(item)) {
      if (itemAmount === null) {
        return InvalidAmount
      }
      const pickupPrice = MoneyCalc.divide(makeMoney(itemAmount), item.pickups.length)
      const proratedText = isProrated ? ' (prorated)' : ''
      return `${formatMoney(pickupPrice)}${qty}${pickupMulti}${proratedText}`
    } else {
      return `${formatMoney(itemAmount)}${qty}${pickupMulti}`
    }
  } else if (isCartShare(item) || isPayPerPickup(item.paymentSchedule)) {
    if (MoneyCalc.isGTZero(baseInstallmentAmtDueToday)) {
      return `${formatMoney(baseInstallmentAmtDueToday)}/installment${qty}`
    } else if (item.paymentSchedule.deposit?.value > 0) {
      return `${formatMoney(item.paymentSchedule.deposit)}/deposit${qty}`
    } else return `No payment due today`
  } else {
    return `${formatMoney(itemAmount)}/ea${qty}`
  }
}

const getBreakdownTextLine2 = (item: CartItem, baseInstallmentAmtDueToday: Money) => {
  if (MoneyCalc.isGTZero(baseInstallmentAmtDueToday) && (item.paymentSchedule.deposit?.value ?? 0) > 0) {
    const qty = getQtyText(item)
    return `+${formatMoney(item.paymentSchedule.deposit)}/deposit${qty}`
  } else if (MoneyCalc.isZero(baseInstallmentAmtDueToday)) return 'No installments due today'
  else return ''
}

type ProductLineItemProps = {
  item: CartItem
  itemAmount: ReturnType<typeof getProratedAmount>['itemAmount']
  isProrated: ReturnType<typeof getProratedAmount>['isProrated']
  baseInstallmentAmtDueToday: Money
  amtDueToday: Money
}
function ProductLineItem({
  item,
  itemAmount,
  isProrated,
  baseInstallmentAmtDueToday,
  amtDueToday,
}: ProductLineItemProps) {
  return (
    <LineItem
      lines={[
        getBreakdownTextLine1({ item, itemAmount, isProrated, baseInstallmentAmtDueToday }),
        getBreakdownTextLine2(item, baseInstallmentAmtDueToday),
      ]}
      lineItemAmt={formatMoney(amtDueToday)}
    />
  )
}

type LineItemProps = BreakdownProps & {
  /** the line item amount should be used for amounts which are due on checkout, any amount here should add up to the grand total */
  lineItemAmt: string
  style?: ViewStyle
}
function LineItem({ lineItemAmt, style, ...breakdownProps }: LineItemProps) {
  const styles = useStyles()
  return (
    <View style={[styles.itemizedTextContainer, style]}>
      <Breakdown {...breakdownProps} />
      {lineItemAmt ? (
        <Text size={16} style={styles.marginLeft}>
          {lineItemAmt}
        </Text>
      ) : null}
    </View>
  )
}

type BreakdownProps = {
  lines: string[]
}
function Breakdown({ lines }: BreakdownProps) {
  const styles = useStyles()
  return (
    <View style={styles.itemizedContainerDetails}>
      {lines.map((line) => (
        <Text key={line} style={styles.itemizedTextLine}>
          {line}
        </Text>
      ))}
    </View>
  )
}

type CartItemCheckoutProps = {
  item: CartItem
  index: number
}

function CartItemCheckout({ item }: CartItemCheckoutProps) {
  const { farm } = useSelector(paramsSelector)
  const { updatePaySchedule: updatePayScheduleService } = useCartService()
  const { errors, touched, set } = useContext(CheckoutContext)
  const { isProrated, itemAmount } = useMemo(
    () =>
      getProratedAmount(item, {
        excludeHiddenDistros: true,
        excludeClosedDistros: true,
        ignoreOrderCutoffWindow: false,
      }),
    [item],
  )
  const showErrors = errors?.includes(item.id) ?? false
  const isTouched = touched[item.id]

  const updatePaySchedule = useCallback(
    async (paySchedule: PaymentSchedule) => {
      if (!isTouched || paySchedule.frequency !== item.paymentSchedule.frequency) {
        await updatePayScheduleService(item.id, paySchedule)
      }
      if (!isTouched) set('touched', { ...touched, [item.id]: true })
      set(
        'errors',
        errors?.filter((eid) => eid !== item.id),
      )
    },
    [errors, touched, set, updatePayScheduleService, isTouched, item.id, item.paymentSchedule.frequency],
  )

  /** PayScheduleExt contains computed data related to the item.product.paymentSchedules */
  const paySchedules: PayScheduleExt[] = useMemo(() => CartButtonsHelpers.processPaySchedules(item), [item])

  const itemSubtotalDueToday = useMemo(() => {
    const ps = paySchedules.find((ps) => ps.frequency === item.paymentSchedule.frequency)!
    if (!ps.interval?.length || !farm?.timezone) return Zero
    if (!ps.interval) return Zero
    return ps.interval[0].subtotal
  }, [item, paySchedules, farm?.timezone])

  const baseInstallmentAmtDueToday = useMemo(() => {
    /** IMPROVE ME: This is a workaround to get the base installment amount without including the deposit. In a better interface, each installment object would have a subtotal that doesn't include the deposit. */
    const ps = paySchedules.find((ps) => ps.frequency === item.paymentSchedule.frequency)!
    if (!ps.interval?.length) return Zero
    const subtotal = ps.interval[0].subtotal
    /** IMPROVE ME: This is a hacky way to get the base installment withouth including the quantity multiplier and deposit. In a better interface each installment object would have a subtotal that doesn't include these extras */
    return MoneyCalc.divide(MoneyCalc.subtract(subtotal, MoneyCalc.multiply(ps.deposit, item.quantity)), item.quantity)
  }, [item, paySchedules])

  const styles = useStyles()
  const { isSmallDevice } = useDeviceSize()

  return (
    <View key={item.id} testID={`checkout-item-${item.id}`} style={styles.cartItem}>
      <View style={styles.imageContainer}>
        <Image source={{ uri: item.product.images[0] }} resizeSuffix={ResizedSuffix.THUMB} style={styles.cartItemImg} />
        {!isSmallDevice ? null : <NameAndBuyingOption item={item} />}
      </View>
      <View style={styles.cartItemMainContent}>
        <View style={styles.cartItemMainContentTopContainer}>
          {!isSmallDevice ? <NameAndBuyingOption item={item} /> : null}
          <ProductLineItem
            item={item}
            itemAmount={itemAmount}
            baseInstallmentAmtDueToday={baseInstallmentAmtDueToday}
            isProrated={isProrated}
            amtDueToday={itemSubtotalDueToday}
          />
        </View>
        <CartButtons
          item={item}
          showErrors={showErrors}
          paySchedules={paySchedules}
          updatePaySchedule={updatePaySchedule}
          touched={isTouched}
        />
      </View>
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    deliveryOverlay: { maxWidth: 550 },
    lineItem: {
      marginBottom: 10,
      /** maxHeight here is necessary to prevent some text from cutting out when device size changes. The lineHeight x2 is because we want two lines of text, plus 20 for the total vertical margin */
      minHeight: lineHeight() * 2 + 20,
    },
    itemNameAndUnit: {
      flex: 1.5,
      paddingRight: 10,
    },
    groupHeader: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      paddingHorizontal: 10,
    },
    groupHeaderTitle: {
      flex: 1.5,
      marginRight: 10,
      alignSelf: 'flex-start',
    },
    groupHeaderRightBlock: {
      flexDirection: 'row',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
    },
    groupHeaderRightBlockText: {
      marginLeft: 10,
      marginTop: 2,
    },
    marginLeft: {
      marginLeft: 10,
    },
    group: {
      width: '100%',
      marginVertical: 15,
    },
    groupList: {
      paddingHorizontal: 20,
    },
    cartItem: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
    cartItemMainContent: {
      flex: 1,
    },
    cartItemMainContentTopContainer: {
      flexDirection: 'row',
      width: '100%',
    },
    itemizedTextContainer: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    itemizedContainerDetails: {
      alignItems: 'flex-end',
      marginTop: 2,
    },
    itemizedTextLine: {
      marginBottom: 10,
    },
    imageContainer: {
      flexDirection: 'row',
    },
    cartItemImg: {
      height: 120,
      width: 120,
      borderRadius: 10,
      marginRight: 10,
    },
    cartItemDescr: {
      marginBottom: 10,
    },
  },
  {
    [maxSize(SIZE_NAMES.MEDIUM_DEVICE)]: {
      cartItemMainContentTopContainer: {},
      groupHeader: {
        flexDirection: 'column',
        alignItems: 'flex-end',
      },
      cartItemImg: {
        width: 90,
        height: 90,
        marginBottom: 10,
      },
    },
    [maxSize(SIZE_NAMES.SMALL_DEVICE)]: {
      cartItemMainContent: {
        width: '100%',
      },
      itemNameAndUnit: {
        minHeight: 75,
      },
      cartItem: {
        flexDirection: 'column',
      },
      cartItemImg: {
        width: 75,
        height: 75,
        marginBottom: 10,
      },
    },
    [maxSize(SIZE_NAMES.EXTRA_SMALL_DEVICE)]: {
      cartItemImg: {
        width: 60,
        height: 60,
        marginBottom: 10,
      },
    },
  },
)
