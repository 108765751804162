import { geoLocateIPAddr, loadExactCoords, reverseGeocode } from '@api/Addresses'
import { validCoords } from '@helpers/coordinate'
import { Coordinate } from '@models/Coordinate'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDisplayName } from '@helpers/client'
import { objToStr } from '@helpers/log'
import { Logger } from '../config/logger'
import { CurrentLocation } from '../constants/types'
import { setCurrLocation } from '../redux/actions/appPersist'
import { userLocationSelector } from '../redux/selectors'
import { useFocusFx } from './useFocusFx'

const getLocation = async (cachedLoc?: CurrentLocation): Promise<{ coords: Coordinate; city?: string } | undefined> => {
  // Only run if we haven't checked in the last hour, OR if there's no cached location yet
  if (
    !cachedLoc ||
    cachedLoc.timestamp < Date.now() - 60 * 60 * 1000 ||
    !validCoords(cachedLoc.coordinate) ||
    !cachedLoc.city ||
    cachedLoc.city === 'Not found'
  ) {
    const [ipLoc, exactCoords] = await Promise.all([geoLocateIPAddr(), loadExactCoords()])
    // prioritize exact coords over ip coords
    let city = ipLoc.city
    const coords = exactCoords ?? ipLoc.coordinate
    // Load the city from Google Api if ipLoc did not return the city
    if (!city && validCoords(coords)) {
      const res = await reverseGeocode(coords)
      //get city from res
      const newCity = res[0]?.address_components.find((comp) => comp.types.includes('locality'))?.long_name
      if (newCity) city = newCity
    }
    //if city is still not returned, we throw a warning
    if (!validCoords(coords) || !city) {
      Logger.warn(new Error('IP location service is returning invalid or missing location data:' + objToStr(ipLoc)))
    }
    return { coords, city }
  }
}

const withLocation = (Component: React.ComponentType<any>) => {
  const NewComponent = function (props: any): JSX.Element {
    const dispatch = useDispatch()
    const cachedLoc = useSelector(userLocationSelector)

    useFocusFx(() => {
      getLocation(cachedLoc).then((data) => {
        if (data) {
          dispatch(setCurrLocation({ city: data.city, coordinate: data.coords, timestamp: Date.now() }))
        }
      })
      // This function updates cached location, so we do not want the effect to run in a loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <Component {...props} />
  }
  NewComponent.displayName = `withLocation(${getDisplayName(Component)})`
  return NewComponent
}

export default withLocation
