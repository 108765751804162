import { loadProduct } from '@api/Products'
import { MessageWithIcon, ProductCardSquare, ToolTips } from '@components'
import { ErrorText, HeaderText, RowWrapper, SafeAreaView, Text, TextH3, Toast } from '@elements'
import { findItemInCart } from '@helpers/canUpdateQuantity'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import {
  InvalidAmount,
  capitalize,
  formatMoney,
  formatUnitPriceRange,
  getDepositRange,
  getPickupPriceRangeForPaySchedule,
  getSharePriceShortText,
  pickupPriceSimple,
  readableCancellation,
  toMoneyRange,
} from '@helpers/display'
import { hyperlinkOpenUrl } from '@helpers/links'
import { getPaymentSchedules, getPickups } from '@helpers/order'
import { getProductAvailability, getStock, getUnitPrice, getUnits, shouldShow } from '@helpers/products'
import { sortByAmount, sortByIncluded, sortByName } from '@helpers/sorting'
import { formatDateRange, isAfter } from '@helpers/time'
import { Farm } from '@models/Farm'
import { formatDistributionType, isDelivery, isLocalPickup, isShipping } from '@models/Location'
import { CartItem, isCartStandard } from '@models/Order'
import {
  PaymentSchedule,
  Product,
  Share,
  Standard,
  Unit,
  hasUnits,
  isAddon,
  isDigital,
  isPayInFull,
  isPhysical,
  isPrimary,
  isShare,
  isStandard,
} from '@models/Product'
import { Media } from '@models/shared/Media'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import HyperLink from 'react-native-hyperlink'
import { useDispatch, useSelector } from 'react-redux'

import { AddCartBtn } from '../../components/AddCartBtn'
import { Certifications } from '../../components/Certifications'
import { FooterBtn } from '../../components/FooterBtn'
import ParallaxHeader, { ParallaxButtons } from '../../components/ParallaxHeader/index'
import { ButtonClear } from '../../components/elements/ButtonClear'
import { Divider } from '../../components/elements/Divider'
import ReadMore from '../../components/elements/ReadMore'
import { Tooltip } from '../../components/elements/Tooltip'
import { Touchable } from '../../components/elements/Touchable'
import Colors from '../../constants/Colors'
import { useProductHitTrack } from '../../hooks/useHitTrack'
import { ShoppingStackParamList } from '../../navigation/types'
import { addNavProp } from '../../redux/actions/appState'
import { paramsSelector } from '../../redux/selectors'
import FarmSnippet from './FarmSnippet'
import { PickupLocation } from './PickupLocation'

import { globalStyles } from '@/constants/Styles'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useCartService } from '@/hooks/useCart'
import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useFarmData } from '@/hooks/useFarmData'
import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { useDeviceSize, useLayout } from '@/hooks/useLayout'
import { defaultAddonNotAvailableText } from '@helpers/addons'
import { getIdFromSlug } from '@helpers/urlSafeSlug'
import { DateTime } from 'luxon'
import { getReadableEbtEligibility } from '@helpers/products-display'

interface Props {
  route: RouteProp<ShoppingStackParamList, 'ProductDetails'>
  navigation: StackNavigationProp<ShoppingStackParamList, 'ProductDetails'>
}

/** Shopping screen dedicated to a specific product */
export function ProductDetails({
  route: {
    params: { farmSlug, productId, csaId, goBack },
  },
  navigation,
}: Props) {
  const {
    farm: { data: farm },
    csas: { data: farmCsas, loading: loadingCsas, err: errorCsas },
    prods: { data: farmProds, addons: farmAddons, loading: loadingProds },
  } = useFarmData(farmSlug, { fetchCsas: true })

  /** farmId will be an empty string until the data matches the slug */
  const farmId = useMemo(() => getIdFromSlug(farmSlug, farm), [farm, farmSlug])

  const { product, csa } = useSelector(paramsSelector)
  const [loading, setLoading] = useKeyedState({ prod: true, csa: true })
  const [invalid, setInvalid] = useKeyedState({ prod: false, csa: false })

  const [addons, setAddons] = useState<Share[]>([]) //the addons to show below the main product
  const { availAddons } = useAvailAddons(farmId) //availAddons in this screen are only used to determine which addons get the green addCartBtn, but all of them get shown

  const [unitId, setUnitId] = useState<string>('')
  const { isSmallDevice, isExtraSmallDevice } = useDeviceSize()
  const dispatch = useDispatch()

  // Provide analytics data for the product view action.
  useProductHitTrack(farmId, productId)

  /** Get the product from farmProds data, or load it directly if not yet available in the cache */
  useCancelableFocusFx(
    async (isActive) => {
      if (productId === product?.id) {
        setLoading('prod', false)
        return
      }
      setLoading('prod', true)
      let prod = farmProds?.find(({ id }) => id === productId) //If already match, not need to set

      if (!prod) {
        // If it's not in the products list on the first try, we may as well fetch it, since progressive loading might take a few pages of data to get the product, in the worst case scenario
        prod = await loadProduct(productId)
        if (!isActive) return
      }

      dispatch(addNavProp({ product: prod }))
      setLoading('prod', false)
    },
    [farmProds, productId, product, dispatch, setLoading],
  )

  /** Get the csa from farmCsas data. Also checks for invalid parameters */
  useFocusFx(() => {
    if (loadingCsas || !farmCsas) {
      return setLoading('csa', true) //wait
    }
    setLoading('csa', true)

    // get the csa from either the current nav prop or the farmCsas list
    let csaProp = csa
    if (csaId) {
      if (csaId !== csaProp?.id) {
        csaProp = farmCsas.find(({ id }) => id === csaId) // get csa from farm csas list
        dispatch(addNavProp({ csa: csaProp }))
        return
      }
    } else if (csaProp) {
      // Should clear the csa nav prop if the csaId is not defined. This prevents trying to add a product to the cart with a csa unrelated to the product
      dispatch(addNavProp({ csa: undefined }))
      return
    }

    // check data is correct
    const needsCsaId = !!product && isShare(product) && !csaId
    const notBelongCsa = !!product && !!csaId && !product.csa?.includes(csaId)
    const notFoundCsa = !!product && !!csaId && !csaProp && !errorCsas && !notBelongCsa
    const invalidProduct = !!product && !shouldShow(product)

    if (needsCsaId || notBelongCsa || notFoundCsa) {
      // If it's not a share, just remove the invalid csa id
      if (!isShare(product)) {
        navigation.setParams({ csaId: undefined })
      } else {
        // If the share has any other useable csa, set it. Otherwise accept this as being invalid
        const newCsaId = product.csa.find((id) => farmCsas.find((csa) => csa.id === id))
        if (newCsaId) {
          navigation.setParams({ csaId: newCsaId })
        } else {
          setInvalid('csa', true)
          Toast('The specified CSA is not available')
        }
      }
    } else if (invalidProduct) {
      setInvalid('prod', true)
      Toast('This product is not being sold at this time')
    }

    setLoading('csa', false)
  }, [csaId, csa, loadingCsas, farmCsas, dispatch, product, errorCsas, setInvalid, setLoading, navigation])

  /** Gets related addons for product, based on the csaId */
  useFocusFx(() => {
    if (loadingProds || loading.csa || !csaId || !farmAddons) return

    // Get the farm addons for the current csa.
    const csaAddons = farmAddons.filter((p) => p.csa.includes(csaId))

    if (csaAddons.length) {
      /// Sort by name before setting the state. Available for addToCart should go first
      const availAddonIds = availAddons.map(({ id }) => id)
      csaAddons.sort(sortByName).sort(sortByIncluded(availAddonIds))
      setAddons(csaAddons)
    }
  }, [csaId, loading.csa, loadingProds, farmAddons, availAddons])

  const onGoBack = useCallback(() => {
    if (goBack === 'home') {
      return navigation.navigate('Consumer', { screen: 'Home' })
    }
    navigation.goBack()
  }, [goBack, navigation])

  const buttons: ParallaxButtons = useMemo(
    () => ({
      left: {
        icon: 'arrow-left',
        onPress: onGoBack,
      },
    }),
    [onGoBack],
  )
  const webElements = useDeepCompareMemo(
    () => ({
      content: <Details product={product} farm={farm} />,
      sticky: <Sticky product={product} unitId={unitId} setUnitId={setUnitId} />,
    }),
    [product, unitId, farm],
  )

  const parallaxMedia: Media[] = useMemo(
    () =>
      product?.images
        ? product.images.map((image: string) => ({
            storageUrl: image,
            type: 'image',
          }))
        : [],
    [product?.images],
  )

  const headerText = product ? getPickupOptsHeaderText(product) : ''

  return (
    <SafeAreaView style={globalStyles.flex1}>
      <ParallaxHeader
        title={product?.name ?? 'Product Details'}
        media={parallaxMedia}
        webElements={webElements}
        buttons={buttons}
        back={onGoBack}
        loading={Object.values(loading).some((v) => !!v)} // children won't be shown while loading
      >
        {/* This part doesn't need to check for loading state because the loading state is passed into the parallax header, and while loading is true, these "children" won't be shown yet. */}
        {product ? (
          <>
            {isPhysical(product) && (
              <>
                <Certifications certs={product.certification} />
                {!!headerText && (
                  <View style={styles.title}>
                    <HeaderText>{headerText}</HeaderText>
                    <Tooltip title="Delivery/Pickup Options" id={ToolTips.PICKUP_OPTIONS} style={styles.marginLeft10} />
                  </View>
                )}
                <View style={styles.alignContCenter}>
                  <PickupLocation product={product} />
                </View>
              </>
            )}
            {!!csaId && !!addons.length && (
              <View style={styles.marginV15}>
                <HeaderText>Add-ons</HeaderText>
                <RowWrapper horizontalSpacing={20} verticalSpacing={20}>
                  {addons.map((p) => (
                    <ProductCardSquare
                      key={p.id}
                      product={p}
                      csa={csa}
                      small={isExtraSmallDevice}
                      cardAction="addcart"
                      onPressMobileNavigate={(p) =>
                        navigation.navigate('ProductDetails', { farmSlug, productId: p.id, csaId })
                      }
                    />
                  ))}
                </RowWrapper>
              </View>
            )}
          </>
        ) : (
          <>
            <MessageWithIcon
              icon="exclamation-triangle"
              title={!invalid.csa && !invalid.prod ? 'Load error' : 'Invalid Link!'}
            >
              <Text>
                {!invalid.csa && !invalid.prod
                  ? 'There was an error loading this product.'
                  : 'This Product does not exist, please check your link and try again.'}
              </Text>
            </MessageWithIcon>
          </>
        )}
      </ParallaxHeader>
      {isSmallDevice && product ? (
        <FooterBtn withTabs style={styles.footerBtn}>
          <AddCartBtn
            /**Disable stepper if the product has multiple buying options */
            disableStepper={(getUnits(product)?.length ?? 0) > 1}
            product={product}
            csa={csa}
          />
        </FooterBtn>
      ) : null}
    </SafeAreaView>
  )
}

const estimateNPickups = (product: Product) => {
  if (isPhysical(product)) {
    const nPickupsPerDistro = product.distributions.map(
      (d) => getPickups(d, product, { excludeHiddenDistros: true, excludeClosedDistros: true }).length,
    )
    const min = Math.min(...nPickupsPerDistro)
    const max = Math.max(...nPickupsPerDistro)
    if (min === max) return min.toString()
    return `${min} to ${max}`
  }
  if (isDigital(product)) return 'No pickups'
}

function DetailItem({ name, children }: { name: string; children: any }) {
  return (
    <View style={styles.detailItm}>
      <Text size={12} color={Colors.shades['300']} style={styles.width150}>
        {name}
      </Text>
      <Text size={12}>{children}</Text>
    </View>
  )
}

const availabilitySection = (product: Product) => {
  if (!isPhysical(product)) return null
  const avail = getProductAvailability(product, undefined, { excludeHiddenDistros: true, excludeClosedDistros: true })
  if (!avail) return null
  return <DetailItem name="Available">{formatDateRange(avail, 'MMM dd, yyyy')}</DetailItem>
}

const Details = memo(function Details({ product, farm }: { product?: Product; farm?: Farm }) {
  if (!product) return null
  return (
    <View>
      <HeaderText>Description</HeaderText>
      <HyperLink linkStyle={styles.blue} onPress={hyperlinkOpenUrl}>
        <ReadMore>{product.longDescription}</ReadMore>
      </HyperLink>
      <Divider large clear />
      <HeaderText>Details</HeaderText>
      {availabilitySection(product)}
      <DetailItem name="Category">{product.category || 'None'}</DetailItem>
      {isStandard(product) && !!product.producer && <DetailItem name="Producer">{product.producer}</DetailItem>}
      {!!product.productionMethod?.length && (
        <DetailItem name="Production method">{product.productionMethod}</DetailItem>
      )}
      {isStandard(product) && <DetailItem name="Cancellation Policy">{getMinCutoffWindowText(product)}</DetailItem>}
      {isStandard(product) && (
        <DetailItem name="SNAP Eligibility">{getReadableEbtEligibility(product.ebtEligibility)}</DetailItem>
      )}
      {isPrimary(product) && (
        <>
          {!!estimateNPickups(product) && <DetailItem name="Number of shares">{estimateNPickups(product)}</DetailItem>}
          {!!product.shareInfo.suggestedFamilySize && (
            <DetailItem name="Family size">{product.shareInfo.suggestedFamilySize}</DetailItem>
          )}
          <DetailItem name="Payment options">
            {product.paymentSchedules
              .map((payment) => payment.frequency.toLowerCase())
              .join(', ')
              .replace('once', 'Pay in full')}
          </DetailItem>
          {product.shareInfo.minimumNumberOfItems !== undefined &&
            product.shareInfo.maximumNumberOfItems !== undefined && (
              <DetailItem name="Number of items">{`${product.shareInfo.minimumNumberOfItems} - ${product.shareInfo.maximumNumberOfItems}`}</DetailItem>
            )}
          {product.vacationWeeks !== undefined && (
            <DetailItem name="Vacation weeks">{product.vacationWeeks}</DetailItem>
          )}
          <DetailItem name="Cancellation policy">{readableCancellation(product.cancellationPolicy)}</DetailItem>
          {getDepositRange(product) !== '$0.00' && <DetailItem name="Deposit">{getDepositRange(product)}</DetailItem>}
        </>
      )}
      <Divider clear large />
      <FarmSnippet farm={farm} />
    </View>
  )
}, propsAreDeepEqual)

function makeTitle(paySchedule: PaymentSchedule) {
  let title: string
  if (paySchedule.frequency === 'ONCE') title = `Pay-in-full`
  else title = `${paySchedule.frequency.charAt(0) + paySchedule.frequency.substring(1).toLowerCase()} Payments`
  return title
}

const PriceContainer = memo(function PriceContainer({
  product,
  unitId,
  setUnitId,
}: {
  product: Product
  unitId: string
  setUnitId: (unitId: string) => void
}) {
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'ProductDetails'>>()
  const {
    params: { farmSlug },
  } = useRoute<RouteProp<ShoppingStackParamList, 'ProductDetails'>>()
  const { csa } = useSelector(paramsSelector)
  const { cart } = useCartService()
  const { modifyDates } = useAddToCartFlow()
  const unit = useMemo(() => (!unitId ? undefined : getUnits(product)?.find((u) => u.id === unitId)), [product, unitId])

  /** If the product is in cart, `itemInCart` should be defined as the cart item. Should consider the unit id for finding the product in the cart */
  const itemInCart: CartItem | undefined = useMemo(() => {
    return findItemInCart({ product, unit }, cart)
  }, [cart, product, unit])

  if (hasUnits(product)) {
    const stock = getStock(product, unitId)

    /*Unit prod & small screen */
    return isSmallDevice ? (
      <>
        {getUnits(product).map((unit: Unit) => {
          const stock = getStock(product, unit.id)
          return (
            <View key={unit.id}>
              <View style={styles.unit}>
                <View>
                  <Text>{unit.name}</Text>
                  {stock === 0 ? (
                    <Text style={stock < 5 && { color: Colors.red }} type="medium">
                      Out of stock
                    </Text>
                  ) : (
                    <Text style={stock < 5 && { color: Colors.red }}>{stock} left in stock</Text>
                  )}
                </View>
                <View style={styles.unitPrice}>
                  <HeaderText>{`${formatUnitPriceRange(product.units, unit.id)}`}</HeaderText>
                  <Text>{`(${formatMoney(getUnitPrice(product, unit.id))}/${product.baseUnit})`}</Text>
                </View>
              </View>
            </View>
          )
        })}
      </>
    ) : (
      /*Unit prod & non-small screen */
      <>
        <View style={styles.marginV40}>
          <Text numberOfLines={1} type="bold" size={14} style={styles.marginBtm5}>
            {`${formatUnitPriceRange(product.units, unitId)}`}
          </Text>
          <Text>{unitId ? `(${formatMoney(getUnitPrice(product, unitId))}/${product.baseUnit})` : ''}</Text>
        </View>
        <Text>{unitId ? `Size: ${unit?.name}` : ''}</Text>
        {stock === 0 ? (
          <Text style={stock < 5 && { color: Colors.red }} type="medium">
            Out of stock
          </Text>
        ) : (
          <Text style={stock < 5 && { color: Colors.red }}>{stock} left in stock</Text>
        )}
        <View style={styles.unitContainer}>
          {getUnits(product).map((unit: Unit) => {
            const hasStock = getStock(product, unit.id) > 0

            return (
              <Touchable
                key={unit.id}
                style={[
                  styles.unitItem,
                  unit.id === unitId && styles.unitItemSelected,
                  !hasStock && styles.unitItemDisabled,
                ]}
                onPress={() => setUnitId(unit.id)}
              >
                <Text color={unit.id === unitId ? Colors.green : undefined} size={10}>
                  {unit.name}
                </Text>
              </Touchable>
            )
          })}
        </View>
        <AddCartBtn product={product} unit={unitId ? unit : undefined} csa={csa} />
        {itemInCart && isCartStandard(itemInCart) && (
          <>
            <View style={styles.cartItemInfo}>
              <TextH3 style={styles.marginRight}>
                {itemInCart.pickups.length === 1
                  ? `${formatDistributionType(itemInCart.distribution.location, {
                      action: true,
                    })}: ${itemInCart.pickups[0].toISODate()}`
                  : `No. ${formatDistributionType(itemInCart.distribution.location, { plural: true })}: ${
                      itemInCart.pickups.length
                    }`}
              </TextH3>
              {itemInCart.pickups.length > 1 && <TextH3>Qty. per pickup: {itemInCart.quantity}</TextH3>}
            </View>
            {!itemInCart.product.disableBuyInFuture && (
              <ButtonClear
                title={itemInCart.pickups?.length === 1 ? 'Add More Dates' : 'Modify dates'}
                style={styles.btnBelowAddCart}
                onPress={() => modifyDates(itemInCart.id)}
              />
            )}
          </>
        )}
        <ButtonClear
          title="Continue Shopping"
          style={styles.btnBelowAddCart}
          onPress={() => (navigation.canGoBack() ? navigation.goBack() : navigation.navigate('FarmShop', { farmSlug }))}
        />
      </>
    )
  } else {
    // Shares
    return (
      <View style={styles.marginV20}>
        <SharePriceDetails product={product} />
        {!isSmallDevice && (
          <>
            <AddCartBtn product={product} csa={csa} />
            <ButtonClear
              title="Continue Shopping"
              style={styles.btnBelowAddCart}
              onPress={() =>
                navigation.canGoBack() ? navigation.goBack() : navigation.navigate('FarmShop', { farmSlug })
              }
            />
          </>
        )}
      </View>
    )
  }
},
propsAreDeepEqual)

const SharePriceDetails = memo(function SharePriceDetails({ product }: { product: Share }) {
  const { isLargeDevice } = useLayout()
  const [paySchedules, setPaySchedules] = useState<PaymentSchedule[]>([])
  const hasSamePrice = paySchedules.every((ps) => ps.amount.value === paySchedules[0].amount.value)

  useFocusFx(() => {
    if (isShare(product)) {
      const schedules = getPaymentSchedules({ product })
      schedules.sort(sortByAmount((sch: PaymentSchedule) => sch.amount.value))
      setPaySchedules(schedules)
    }
  }, [product])

  const getPayMethodsDescription = () => {
    const { paymentSchedules } = product
    if (!paymentSchedules.length) {
      return 'No payment schedules available'
    }
    if (paymentSchedules.every(isPayInFull)) return ''

    const frequencies = paymentSchedules.map((pmt) =>
      pmt.frequency === 'ONCE' ? 'pay-in-full' : pmt.frequency.toLowerCase(),
    )

    let result = capitalize(frequencies.join(', '), true)

    if (!paymentSchedules.some(isPayInFull) || paymentSchedules.length > 1) {
      result += ' installments available'
    }
    return result
  }

  const pickupPrice = useMemo(() => pickupPriceSimple(product), [product])

  return (
    <>
      <Text numberOfLines={1} type="bold" size={16} style={styles.sharePriceText}>
        {getSharePriceShortText(product)}
      </Text>
      <Text>{pickupPrice !== InvalidAmount ? `${pickupPrice}/share` : 'Price not available'}</Text>
      <Text style={{ marginBottom: isLargeDevice ? 40 : 20 }}>{getPayMethodsDescription()}</Text>
      {
        !!paySchedules.length && !hasSamePrice ? (
          <>
            <View style={{ marginBottom: isLargeDevice ? 40 : 20, marginTop: 15 }}>
              {paySchedules.map((paySchedule) => {
                return (
                  <View style={styles.row} key={paySchedule.frequency}>
                    <Text numberOfLines={1} type="regular" style={styles.width180}>
                      {makeTitle(paySchedule) + ':'}
                    </Text>
                    <Text numberOfLines={1} type="bold">
                      {toMoneyRange(getPickupPriceRangeForPaySchedule(product, paySchedule)) + '/pickup'}
                    </Text>
                  </View>
                )
              })}
            </View>
          </>
        ) : null //don't show details by payschedule if they're all the same, since we're already providing the simple pickup price above
      }
    </>
  )
}, propsAreDeepEqual)

function Sticky({
  product,
  unitId,
  setUnitId,
}: {
  product?: Product
  unitId: string
  setUnitId: (unitId: string) => void
}) {
  const { availAddonsResults } = useAvailAddons(product?.farm.id)

  if (!product) return null

  const availAddonResult = isAddon(product) ? availAddonsResults.find((res) => res.id === product.id) : undefined

  return (
    <View>
      <HeaderText size={24}>{product.name}</HeaderText>
      <Text size={18}>{product.description}</Text>
      <PriceContainer product={product} unitId={unitId} setUnitId={setUnitId} />
      {isAddon(product) && !availAddonResult?.isAvail && (
        <ErrorText>{availAddonResult?.unavailReason || defaultAddonNotAvailableText}</ErrorText> // addon is not available and should not be added to cart - also show the correct message from why isn't available
      )}
    </View>
  )
}
const getMinCutoffWindowText = (product: Standard) => {
  const cutoffDays = product.distributions
    .filter((d) => !d.isHidden && !d.closed)
    .map((distro) => distro.orderCutoffWindow)

  const value = Math.min(...cutoffDays)
  if (value === 0) return 'Same day'
  if (value === 1) return '1 day in advance'
  return `${value} days in advance`
}

const getPickupOptsHeaderText = (prod: Product) => {
  const schedules = prod.distributions
    ?.filter((d) => !d.isHidden && !d.closed)
    .filter((d) => {
      const distAvail = getProductAvailability(prod, d, { excludeHiddenDistros: true, excludeClosedDistros: true })
      if (!distAvail) return false
      return isAfter(distAvail.endDate, DateTime.now())
    })
  if (!schedules?.length) return ''

  const hasLocal = schedules.some((d) => isLocalPickup(d.location))
  const hasDelivery = schedules.some((d) => isDelivery(d.location))
  const hasShipping = schedules.some((d) => isShipping(d.location))

  if (hasLocal && !hasDelivery && !hasShipping) return 'Pickup'
  if (!hasLocal && hasDelivery && !hasShipping) return 'Delivery'
  if (!hasLocal && !hasDelivery && hasShipping) return 'Shipping'
  if (hasLocal && hasDelivery && !hasShipping) return 'Pickup and Delivery'
  if (hasLocal && !hasDelivery && hasShipping) return 'Pickup and Shipping'
  if (!hasLocal && hasDelivery && hasShipping) return 'Delivery and Shipping'
  if (hasLocal && hasDelivery && hasShipping) return 'Pickup, Delivery and Shipping'
  return ''
}

const styles = StyleSheet.create({
  flex1: { flex: 1 },
  unitContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  unitItem: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.shades['200'],
    marginVertical: 10,
    marginRight: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  unitItemSelected: {
    borderColor: Colors.green,
    backgroundColor: Colors.lightGreen,
  },
  unitItemDisabled: {
    borderColor: Colors.shades['200'],
    backgroundColor: Colors.shades['200'],
    color: Colors.shades['300'],
    opacity: 0.4,
  },
  about: {
    paddingTop: 10,
  },
  unitPrice: {
    flex: 1,
    alignItems: 'flex-end',
  },
  arrows: {
    top: 0,
    bottom: 0,
    position: 'absolute',
  },
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginVertical: 32,
  },
  detailItm: {
    flexDirection: 'row',
    marginVertical: 5,
  },
  btnBelowAddCart: { alignSelf: 'center', padding: 5 },
  footerBtn: { position: 'absolute', bottom: 0, paddingBottom: 5 },
  cartItemInfo: { marginVertical: 10, flexDirection: 'row', justifyContent: 'center' },
  marginRight: { marginRight: 15 },
  title: { flexDirection: 'row', alignItems: 'center' },
  marginLeft10: { marginLeft: 10 },
  alignContCenter: { alignContent: 'center' },
  marginV15: { marginVertical: 15 },
  width150: { width: 150 },
  unit: { flexDirection: 'row', alignItems: 'center', marginVertical: 10 },
  marginV40: { marginVertical: 40 },
  marginBtm5: { marginBottom: 5 },
  marginV20: { marginVertical: 20 },
  sharePriceText: { marginVertical: 20 },
  row: { flexDirection: 'row' },
  width180: { width: 180 },
  blue: { color: Colors.blueLink },
})
