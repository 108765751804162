import { Icon, IconProps, Text } from '@elements'
import { RefObject, memo, useCallback, useMemo } from 'react'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { Overlay, OverlayProps } from 'react-native-elements'

import { useDeepCompareLayoutFnStyles } from './useFnStyles'

import Colors from '@/constants/Colors'
import useMeasure, { Measure } from '@/hooks/useMeasure'
import { AntDesign } from '@expo/vector-icons'
import { autocompleteContainerBaseStyle } from './useAutoComplete'
import { useControlledState } from './useControlledState'

const btnHitSlop = { top: 5, left: 5, right: 5, bottom: 5 }

const useTipBtn = (ref: RefObject<View>, showTip: () => void) => {
  return memo(function TipButton({ ...overrides }: Partial<Omit<IconProps, 'name'>>) {
    return (
      // We only use the ref for positioning, so it is fine to cast the type like this since TouchableOpacity extends View
      <TouchableOpacity ref={ref as RefObject<TouchableOpacity>} hitSlop={btnHitSlop} onPress={showTip}>
        <AntDesign
          name="questioncircleo"
          size={10}
          color={Colors.shades['300']}
          style={styles.marHor5}
          {...overrides}
        />
      </TouchableOpacity>
    )
  })
}

/** Provides an all-in-one solution for implementing a tool tip.
 * Return type: Includes customizable components for the tip button and overlay. You're free to use these, or implement your own UI elements using the other helpers and props.
 * - For example, you can use the TipOverlay with custom overlay props, same goes for the TipButton.
 * - You can use the overlayProps in a new overlay element
 * - You can use the tip ref, position, state and setters in your other components.
 */
export function useToolTip(info: string | JSX.Element) {
  const [showingTip, [showTip, hideTip]] = useControlledState(false, [true, false])
  const [tipPos, tipRef] = useMeasure()

  const TipButton = useTipBtn(tipRef, showTip)

  const { overlayStyle, backdropStyle } = useStyles(tipPos)

  const overlayProps: OverlayProps = useMemo(() => {
    return {
      isVisible: showingTip,
      backdropStyle,
      onBackdropPress: hideTip,
      overlayStyle,
    }
  }, [overlayStyle, showingTip, hideTip, backdropStyle])

  const TipOverlay = useCallback(
    function TipOverlay({
      overlayContentStyle,
      ...overrides
    }: Partial<OverlayProps> & { overlayContentStyle?: ViewStyle }) {
      return (
        <Overlay
          {...overlayProps}
          {...overrides}
          children={
            <TouchableOpacity onPress={hideTip} hitSlop={btnHitSlop}>
              <View style={[styles.allWidth, overlayContentStyle]}>
                <Icon iconSet="EvilIcons" name="close" style={styles.alignEnd} size={20} solid />
                <View style={styles.marRight5}>{typeof info === 'string' ? <Text>{info}</Text> : info}</View>
              </View>
            </TouchableOpacity>
          }
        />
      )
    },
    [overlayProps, hideTip, info],
  )

  return useMemo(
    () => ({
      tipPos,
      tipRef,
      overlayProps,
      TipButton,
      TipOverlay,
    }),
    [tipRef, tipPos, overlayProps, TipButton, TipOverlay],
  )
}

const styles = StyleSheet.create({
  allWidth: { width: '100%' },
  alignEnd: { alignSelf: 'flex-end' },
  marHor5: { marginHorizontal: 5 },
  marRight5: { marginRight: 5 },
})

// Style for positioning the backdrop
const useStyles = (pos: Measure | undefined) =>
  useDeepCompareLayoutFnStyles(
    ({ isSmallDevice, height, screenWidth }, pos) => ({
      overlayStyle: pos
        ? {
            ...autocompleteContainerBaseStyle,
            position: 'absolute',
            left: !isSmallDevice && pos.pageX < screenWidth / 2 ? pos.pageX : 'auto',
            // This will handle positioning tooltips for Tips that are on the right side of the screen
            right: !isSmallDevice && pos.pageX > screenWidth / 2 ? screenWidth - pos.pageX - pos.width : 'auto',
            top: pos.pageY > 0 ? pos.pageY + pos.height + 3 : height / 2,
            width: isSmallDevice ? 'auto' : '40%',
          }
        : {},
      backdropStyle: isSmallDevice
        ? {
            /** Will be semi-transparent by default */
          }
        : { backgroundColor: Colors.transparent },
    }),
    pos,
  )
