import * as yup from 'yup'
import { isAddressRequired } from '@/admin/screens/Customer/Yup/yupHelper'
import { mainNumPatternForms } from '@helpers/display'
import { nonEmptyString } from '@helpers/helpers'
import { getState } from '@/assets/data/states'

/*
 * Yup validation for address fields
 * If any of the address fields are filled, all of them must be filled
 */
yup.addMethod<yup.StringSchema>(yup.string, 'isAddressRequired', isAddressRequired)
export interface CustomerDetailFormType {
  firstName: string
  lastName: string
  email: string
  email2?: string
  phoneNumber?: string
  pronouns?: string
  street1?: string
  street2?: string
  city?: string
  state?: string
  zipcode?: string
  notes?: string
}

export const customerDetailSchema = yup.object<CustomerDetailFormType>().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  email2: yup.string().email('Email2 is invalid'),
  phoneNumber: yup.string().matches(mainNumPatternForms, 'Please enter a valid phone number.').label('Phone Number'),
  pronouns: yup.string(),
  street1: yup.string().isAddressRequired('street1'),
  street2: yup.string(),
  city: yup.string().isAddressRequired('city'),
  state: yup
    .string()
    .trim()
    .test('State code', 'Must enter a valid state', function (value) {
      // Only check for valid state if the field is not empty
      return !nonEmptyString(value) || !!getState(value)
    })
    .isAddressRequired('state'),
  zipcode: yup.string().isAddressRequired('zipcode'),
  notes: yup.string(),
})
