import { snapshotInvoicesByOrder } from '@api/Invoices'
import { loadOrder } from '@api/Orders'
import { Divider, LoadingView } from '@elements'
import { sortByEarliest } from '@helpers/sorting'
import { OrderItem as OrderItemType } from '@models/Order'
import { dateTimeInZone } from '@models/Timezone'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import { BackTo } from '../../components/BackTo'
import { ItemBreakDown } from './components/ItemBreakdown'
import { OrderOverview } from './components/OrderOverview'
import PaymentsStructure from './components/PaymentsStructure'
import { goBack, goBackString } from './helpers/AdminOrderDetails.helper'

import { AdminView } from '@/admin/components/AdminView'
import { AdminOrdersParamList, CustomerParamList } from '@/admin/navigation/types'
import { useApiFx, useSnapshot } from '@/hooks/useApiFx'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmIdSelector } from '@/redux/selectors'
import { Permission } from '@helpers/Permission'
import { useSelector } from 'react-redux'

function AdminOrderDetails() {
  const [cancelledItems, setCancelledItems] = useState<OrderItemType[]>([])
  const farmId = useSelector(adminFarmIdSelector)

  const {
    params: { orderId },
  } = useRoute<RouteProp<AdminOrdersParamList, 'AdminOrderDetails'>>()
  const navigation = useNavigation<StackNavigationProp<AdminOrdersParamList | CustomerParamList>>()

  const { data: order, loading: loadingOrder, err: errorOrder, refresh: ordRefresh } = useApiFx(loadOrder, [orderId])
  const {
    data: invoices,
    loading: loadingInvoices,
    error: errorInvoices,
  } = useSnapshot(snapshotInvoicesByOrder, [orderId, farmId!], !!farmId)

  const reset = useCallback(() => {
    setCancelledItems([])
    ordRefresh()
  }, [ordRefresh])

  const firstInvoice = useMemo(() => {
    if (!order || !invoices) return undefined
    const inv = [...invoices].sort(sortByEarliest('dueDate'))[0]
    if (!inv) return undefined
    // If there is no payment paid today don't list it as paid in this receipt
    if (inv.dueDate > dateTimeInZone(order.farm.timezone)) return undefined
    return inv
  }, [invoices, order])

  return (
    <AdminView>
      <BackTo
        style={styles.divWrapper}
        title={goBackString(navigation, order?.user?.id)}
        onPress={() => goBack(navigation, order?.user?.id)}
      />
      <LoadingView
        style={styles.divWrapper}
        loading={loadingOrder}
        error={errorOrder}
        spinnerProps={{ size: 'large' }}
        success={order}
      >
        {(order) => (
          <>
            <OrderOverview firstInvoice={firstInvoice} currentOrder={order} callback={reset} />
            <Divider large top={10} bottom={10} clear />
            <LoadingView loading={loadingInvoices} success={invoices} error={errorInvoices}>
              {(invoices) => (
                <>
                  <ItemBreakDown
                    currentOrder={order}
                    invoices={invoices}
                    cancelledItems={cancelledItems}
                    setCancelledItems={setCancelledItems}
                    callback={reset}
                  />
                  <Divider large top={10} bottom={10} clear />
                  <PaymentsStructure currentOrder={order} invoices={invoices} />
                </>
              )}
            </LoadingView>
          </>
        )}
      </LoadingView>
    </AdminView>
  )
}

export default withAdminAuth(AdminOrderDetails, Permission.Orders)

const styles = StyleSheet.create({
  divWrapper: {
    margin: 25,
    marginRight: 20,
  },
})
