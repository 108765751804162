import { Distribution, DistributionConstraint } from '@models/Distribution'
import { Location } from '@models/Location'
import {
  DigitalProduct,
  EbtEligibility,
  GlobalStandard,
  PhysicalProduct,
  Product,
  Share,
  Standard,
  StockType,
  Unit,
  UnitBase,
  UnitPrice,
  UnitStandard,
  hasUnitStock,
  hasUnits,
  isDigital,
  isGlobalStandard,
  isPhysical,
  isShare,
  isStandard,
} from '@models/Product'
import {
  DateRange,
  Frequency,
  Schedule,
  getScheduleAvailability,
  isSeasonalSchedule,
  isYearRoundSchedule,
  makeDateRange,
} from '@models/Schedule'
import { dateTimeInZone } from '@models/Timezone'
import { ProductError } from '@shared/errors/product'
import { DateTime, Zone } from 'luxon'
import { DistroOpts, getOrderDeadlineFromPickup, getPickups } from './order'

import { AlgoliaGeoDoc, AlgoliaGeoProduct, isGeoDoc } from '@models/Algolia'
import { CSA } from '@models/CSA'
import { validateFreqConstraint } from './builders/buildProduct'
import { CachedCompute } from './cachedCompute'
import DecimalCalc from './decimal'
import { getPriceRange, getSharePriceShortText } from './display'
import { isNonNullish, makeHandle } from './helpers'
import { sortUnits } from './sorting'
import { isAfter, isBefore, isValidDateRange } from './time'
import { PartialPick } from './typescript'

/**
 * Returns the correct frequency for a product, applying its own distributionConstraints for a given distribution or distribution id
 * @param prod the product to get the frequency for
 * @param dist the distribution or distribution Id to get frequency for
 * @param opts custom options that determine how the frequency should be determined
 @returns the resulting Frequency, or throws an error if the distributionConstraint is invalid
 */
export function getProductFrequency(
  prod: PartialPick<PhysicalProduct, 'distributions' | 'distributionConstraints'>,
  dist: Distribution | Distribution['id'],
  opts?: { strict?: boolean } & Pick<DistroOpts, 'excludeClosedDistros' | 'excludeHiddenDistros'>,
): Frequency | null {
  const { strict = false, excludeClosedDistros = false, excludeHiddenDistros = false } = opts ?? {}
  try {
    let prodDistro: Distribution
    let constraint: DistributionConstraint | undefined
    if (typeof dist === 'string') [prodDistro, constraint] = getDistroNConstraint(prod, dist)
    else {
      prodDistro = dist
      constraint = prod.distributionConstraints?.find((constraint) => dist.id === constraint.id)
    }

    if (!constraint || !constraint.frequency) return prodDistro.schedule.frequency
    if ((excludeHiddenDistros && prodDistro.isHidden) || (excludeClosedDistros && prodDistro.closed)) return null

    validateFreqConstraint(constraint.frequency, prodDistro.schedule.frequency, strict)
    return constraint.frequency
  } catch (e) {
    // log(e)
    return null
  }
}

/**
 * Will get the availability window for the entire product, or for a specific distribution, if a distId is provided.
 *
 * @param prod The product to get availability for
 * @param distro if undefined, will return the availability based on all the distributions in the product, and their constraints. if provided, the resulting availability will reflect only a single distribution.
 * @param opts additional options for getting product availability.
 *   - excludeHiddenDistros and excludeClosedDistros will exclude those from the calculation
 *   - zone will set the resulting availability to the provided timezone. By default, it will use the timezone of each distro. If no distro specified, `makeDateRange` will equalize the endDate to the startDate's distro timezone.
 * @returns The resulting availability DateRange. Will throw errors if data is invalid for distro, schedule or date-constraint.
 */
export function getProductAvailability(
  prod: PartialPick<PhysicalProduct, 'distributions' | 'distributionConstraints'>,
  distro?: Distribution | Distribution['id'],
  opts?: Pick<DistroOpts, 'excludeClosedDistros' | 'excludeHiddenDistros'> & { zone?: Location['timezone'] | Zone },
): DateRange | null {
  const { excludeClosedDistros = false, excludeHiddenDistros = false, zone } = opts ?? {}
  try {
    if (distro) {
      return getProdDistAvailability(prod, distro, { excludeClosedDistros, excludeHiddenDistros, zone })
    } else {
      const dateRanges = prod.distributions
        .filter((d) => (excludeHiddenDistros ? !d.isHidden : true) && (excludeClosedDistros ? !d.closed : true))
        .map((dist) => {
          try {
            return getProdDistAvailability(prod, dist, {
              zone /** Not necessary to pass DistroOpts here because we already filtered them */,
            })
          } catch {
            return undefined //If a distro has incompatible data, will not count toward the overall availability and the operation will continue
          }
        })
        .filter(isNonNullish)

      if (!dateRanges.length)
        throw new Error(
          `Error while computing product availability. No distributions had a valid date range. Product: "${prod.id}"`,
        )
      return makeDateRange(
        DateTime.min(...dateRanges.map((dr) => dr.startDate)),
        DateTime.max(...dateRanges.map((dr) => dr.endDate)),
        zone,
      )
    }
  } catch (err) {
    // This means a distro is incompatible so product has bad data
    //FIXME: We can't use the sentry logger on helpers. Therefore if we don't log here it will be buried. This will automatically do nothing in production. This may have a better solution later
    // log(err)
    return null
  }
}

/**
 * Gets the product availability for one of its distributions. Only intended for use in other helpers. Not to be exported.
 * - The zone option can override the distro timezone. The final result will be in this timezone
 */
const getProdDistAvailability = (
  prod: PartialPick<PhysicalProduct, 'distributions' | 'distributionConstraints'>,
  distroArg: Distribution | Distribution['id'],
  opts?: Pick<DistroOpts, 'excludeClosedDistros' | 'excludeHiddenDistros'> & { zone?: Location['timezone'] | Zone },
): DateRange => {
  const { excludeClosedDistros = false, excludeHiddenDistros = false, zone: zoneArg } = opts ?? {}

  let distro: Distribution
  let constraint: DistributionConstraint | undefined
  if (typeof distroArg == 'string') [distro, constraint] = getDistroNConstraint(prod, distroArg)
  else {
    distro = distroArg
    constraint = prod.distributionConstraints?.find((constraint) => distroArg.id === constraint.id)
  }
  const zone = zoneArg ?? distro.location.timezone
  if (excludeHiddenDistros && distro.isHidden) throw new Error("Can't get product availability at a hidden distro")
  if (excludeClosedDistros && distro.closed) throw new Error("Can't get product availability at a closed distro")
  const constrainedDates = constrainScheduleDates(distro.schedule, constraint?.dateRange, zone)
  return constrainedDates

  /**
   * FIXME: Since forever, this helper has returned an end date which is based on the schedules end date only. This is OK for some purposes, but people have continued to use it around the app without knowing the difference between this endDate, and the endDate for ordering. The endDate for ordering purposes would need to be the order deadline of the last pickup, which is the same concept as the last avail timestamp.
   *
   * However, if you enable this change, the admin products list screen becomes extremely slow because this calculation involves getPickups.
   *
   * This is one more reason why we should include new fields in the product model, which pre-compute important data like this, so it doesn't need to be computed client-side
   */

  // const pickups = getPickups(distro, prod, {
  //   excludeHiddenDistros,
  //   excludeClosedDistros,
  //   ignoreDisableBuyInFuture: true,
  // })

  // if (!pickups.length) return constrainedDates

  // // Get the last possible pickup for this product at this schedule
  // const lastPickup = DateTime.max(...pickups)
  // // Get the order deadline for the last pickup
  // const lastOrderDeadline = getOrderDeadline({ distro, pickupDate: lastPickup })

  // return { startDate: constrainedDates.startDate, endDate: lastOrderDeadline }
}

/**
 * Returns the distro from product.distributions and constraint for a product and distribution.id
 */
export const getDistroNConstraint = (
  prod: PartialPick<PhysicalProduct, 'distributions' | 'distributionConstraints'>,
  distId: string,
): [Distribution, DistributionConstraint | undefined] => {
  const distro = prod.distributions?.find((dist) => distId === dist.id)
  if (!distro) throw new Error(`Distribution ${distId} does not exist on this product ${prod.id}`)

  const constraint = prod.distributionConstraints?.find((constraint) => distId === constraint.id)
  return [distro, constraint]
}

/**
 * Reasons why a schedule and dateRange distributionConstraint might be invalid or incompatible
 */
export enum ScheduleNDateConstrErrorType {
  needsEndDate = 'needsEndDate', // Yearround schedules have no ending date, so there must be an endDate in dateRange constraint for yearRound schedules
  needsValidDateConstr = 'needsValidDateConstr', // The DateRange from the constraint is invalid
  needsValidSeason = 'needsValidSeason', // The DateRange from the season is invalid
  needsValidStartDateConstraint = 'needsValidStartDateConstraint', //The start date of the constraint should happen on or after the start date of the schedule
  needsValidEndDateConstraint = 'needsValidEndDateConstraint', //The end date of the constraint should happen on or before the end date of the schedule. This may only happen with a seasonal schedule because year round schedules have no inherent end date
}
/**
 * Checks whether a schedule and dateRange constraint are compatible and valid. Can be used in UI for form validation, and inside other helpers
 * @param dateConstr the date constraint from the product, for this distribution schedule
 * @param schedule the schedule being constrained
 * @returns If the combination is valid, returns undefined. If invalid, returns an enum that represents the reason why the combination is invalid. This enum can be used to choose an error message for the UI.
 */
export const isValidScheduleNDateConstr = (
  dateConstr: DistributionConstraint['dateRange'],
  schedule: Schedule,
): ScheduleNDateConstrErrorType | undefined => {
  const {
    needsEndDate,
    needsValidDateConstr,
    needsValidSeason,
    needsValidEndDateConstraint,
    needsValidStartDateConstraint,
  } = ScheduleNDateConstrErrorType
  //check that year round schedules have a end date constraint
  if (isYearRoundSchedule(schedule) && !dateConstr?.endDate) return needsEndDate
  if (isSeasonalSchedule(schedule) && !isValidDateRange(schedule.season)) return needsValidSeason

  if (dateConstr) {
    //check constraints and seasons are valid date range in themselves
    if (!isValidDateRange(dateConstr)) return needsValidDateConstr

    // Ensure constraint start date is not before schedule start date
    const scheduleStart = getScheduleAvailability(schedule).startDate
    const constrStart = dateConstr.startDate
    if (isBefore(constrStart, scheduleStart, { granularity: 'day', zone: scheduleStart.zone }))
      return needsValidStartDateConstraint

    if (isSeasonalSchedule(schedule)) {
      //for seasonal only, check end date is before season end. for yearround schedules, will allow end date anywhere in the future
      const scheduleEnd = getScheduleAvailability(schedule).endDate
      const constrEnd = dateConstr.endDate
      if (isAfter(constrEnd, scheduleEnd, { granularity: 'day', zone: scheduleEnd.zone }))
        return needsValidEndDateConstraint
    }
  }
  //if no errors, undefined
  return undefined
}

/** Validator for the combination between a schedule and a date constraint.
 * - Throws product errors if the combination isn't compatible
 */
export const validateScheduleNDateConstr = (dateConstr: DistributionConstraint['dateRange'], schedule: Schedule) => {
  //Validate date constraint
  const res = isValidScheduleNDateConstr(dateConstr, schedule)
  switch (res) {
    case ScheduleNDateConstrErrorType.needsEndDate:
      throw new ProductError({ code: 'NeedsEndDate' })
    case ScheduleNDateConstrErrorType.needsValidDateConstr:
      throw new ProductError({ code: 'InvalidDateRangeConstraint' })
    case ScheduleNDateConstrErrorType.needsValidSeason:
      throw new ProductError({ code: 'InvalidSeason' })
    case ScheduleNDateConstrErrorType.needsValidStartDateConstraint:
      throw new ProductError({ code: 'InvalidConstraintStartDate' })
    case ScheduleNDateConstrErrorType.needsValidEndDateConstraint:
      throw new ProductError({ code: 'InvalidConstraintEndDate' })
  }
}

/**
 * Checks whether a frequency constraint can be applied to a distribution frequency. Can be used in UI for validation, or in other helpers.
 * @param freqConstrain the frequency constraint to be applied. It must be narrower than the distribution frequency.
 * @param distroFreq the normal frequency of the distribution
 * @param strict if true, constraint must be narrower than distro freq. Else, constraint can be equal.
 * @returns boolean, whether this `freqConstraint` can be applied to this `distroFreq`
 */
export const isValidFreqConstraint = (freqConstrain: Frequency, distroFreq: Frequency, strict = true): boolean => {
  try {
    return validateFreqConstraint(freqConstrain, distroFreq, strict) === undefined
  } catch (err) {
    return false
  }
}

/**
 * Constrains a schedule's dates to the dateRange from a product's distribution constraint.
 * - Handles yearRound or seasonal schedules accordingly.
 * - Will throw an error if combo is invalid. (I.e. expects a an availability date range for year round schedules)
 * - Timezone can be specified. Will set the date range to that timezone. By default will use the start date's zone.
 * - Will set start date to startOf('day') and endDate to endOf('day')
 *
 * @param schedule the schedule to constrain
 * @param availability the availability date range that constrains the schedule
 * @param zone the resulting date range will be set to this zone. By default it will use the zone of the start date or pickupStart.
 */
export const constrainScheduleDates = (
  schedule: Schedule,
  availability: DistributionConstraint['dateRange'],
  zone: Location['timezone'] | Zone = isSeasonalSchedule(schedule)
    ? schedule.season.startDate.zone
    : schedule.pickupStart.zone,
): DateRange => {
  validateScheduleNDateConstr(availability, schedule)

  if (isSeasonalSchedule(schedule)) {
    return availability
      ? {
          startDate: DateTime.max(schedule.season.startDate, availability.startDate).setZone(zone).startOf('day'),
          endDate: DateTime.min(schedule.season.endDate, availability.endDate).setZone(zone).endOf('day'),
        }
      : {
          startDate: schedule.season.startDate.setZone(zone).startOf('day'),
          endDate: schedule.season.endDate.setZone(zone).endOf('day'),
        }
  } else if (isYearRoundSchedule(schedule)) {
    if (!availability)
      throw new Error('A year-round schedule requires an availability date range to determine its end date.')
    return {
      startDate: DateTime.max(schedule.pickupStart, availability.startDate).setZone(zone).startOf('day'),
      endDate: availability.endDate.setZone(zone).endOf('day'),
    }
  } else {
    throw new Error("Bad data: Schedule doesn't match either seasonal or year-round types.")
  }
}

export function getUnits(prod: Share): undefined
export function getUnits(prod: UnitStandard): UnitBase<StockType.Unit>[]
export function getUnits(prod: DigitalProduct | GlobalStandard): UnitBase<StockType.Global>[]
export function getUnits(prod: DigitalProduct | Standard): Unit[]
export function getUnits(prod: Product): Unit[] | undefined
/** Will return a product's sorted units, or undefined if the product type has no units */
export function getUnits(prod: Product): Unit[] | undefined {
  return hasUnits(prod) ? [...prod.units].sort(sortUnits) : undefined
}

export function getStock(p: Share, unitId?: undefined): number
export function getStock(p: Standard | DigitalProduct, unitId?: string): number
export function getStock(p: Product, unitId?: string): number
/**
 * Gets the stock in relation to an optional unit multiplier.
 * - If the product is a share, it returns the quantity of the share.
 * - If the product is a global stock (global standard and digital products):
 *   - If unitId is provided, it returns the stock available for the specified unit based on the global stock.
 *   - If unitId is not provided, it returns the global quantity of the product.
 * - If the product has unit stock:
 *   - If unitId is provided, it returns the quantity of the specified unit.
 *   - If unitId is not provided, it returns the sum of all unit quantities (considered as a conversion to global quantity).
 * - Otherwise, it returns 0.
 *
 * @param p The product to get the stock from.
 * @param unitId The ID of the unit to get the stock for (optional).
 * @returns The stock quantity.
 */
export function getStock(p: Product, unitId?: Unit['id']): number {
  if (isShare(p)) {
    return p.quantity
  }
  if (isGlobalStandard(p) || isDigital(p)) {
    if (unitId) {
      const unit = p.units.find((unit) => unit.id === unitId)
      if (!unit) {
        throw new Error(`Unit ${unitId} not found in product ${p.id}`)
      }

      return Math.floor(DecimalCalc.divide(p.quantity, unit.multiplier))
    }
    return p.quantity
  }
  if (hasUnitStock(p)) {
    if (unitId) {
      const unit = p.units.find((unit) => unit.id === unitId)
      if (!unit) {
        throw new Error(`Unit ${unitId} not found in product ${p.id}`)
      }
      return unit.quantity
    }
    return p.units.reduce((total, u) => total + u.quantity, 0)
  }
  return 0
}

/** Checks whether a product has stock viable for adding to cart */
export const isInStock = (p: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>): boolean => {
  if (isGeoDoc(p)) return p.isInStock
  if (isShare(p)) return getStock(p) > 0
  else if (hasUnits(p)) {
    let isInStock = false
    getUnits(p).forEach((u) => {
      if (isStandard(p)) {
        if (getStock(p, u.id) >= (p.minPickups ?? 1)) isInStock = true
      } else {
        if (getStock(p, u.id) > 0) isInStock = true
      }
    })
    return isInStock
  } else throw new Error('Cant check the stock of this product')
}

/** Gets the unit price. */
export function getUnitPrice(p: Standard | DigitalProduct, unitId: Unit['id'], priceId?: UnitPrice['id']) {
  if (!unitId) throw new Error('No unit id provided')

  const unit = p.units.find((u) => u.id === unitId)
  if (!unit) {
    throw new Error(`Unit ${unitId} not found in product ${p.id}`)
  }

  // If no priceId provided, should find the first one
  const price = unit.prices.find((pr) => (priceId ? pr.id === priceId : true))

  if (!price) throw new Error('Price not found for the buying option')

  return DecimalCalc.divide(price.amount.value, unit.multiplier)
}

/** findProductUnit returns the unit contained in the supplied product matching the given unit ID. It will return undefined if no unit matching the ID is found. If the product type does not contain units undefined will also be returned.*/
export const getProductUnit = (product: Product, unitId: string): Unit | undefined =>
  getUnits(product)?.find((u) => u.id === unitId)

/** This holds a session cache for the hasPickups helper */
export const [getHasPickupsCachedFn, setHasPickupsCachedFn, isSetHasPickupsCachedFn] =
  makeHandle<typeof hasPickups>('hasPickupsCache')

/** Check if any of the (physical) product's distros has future pickups that can still be purchased.
 * - Will return false for nonPhysical products.
 * - By default it excludes hidden and closed distros ("consumer" mode). */
export const hasPickups = (
  prod: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>,
  opts: DistroOpts & {
    /** Whether the result should be from the current session's cache */
    useCache?: boolean
  } = {},
): boolean => {
  opts.excludeClosedDistros ??= true
  opts.excludeHiddenDistros ??= true
  opts.ignoreOrderCutoffWindow ??= false
  opts.useCache ??= false

  if (opts.useCache) {
    if (!isSetHasPickupsCachedFn()) {
      setHasPickupsCachedFn(CachedCompute(hasPickups).cachedFn)
    }
    return getHasPickupsCachedFn()(prod, { ...opts, useCache: false })
  }

  if (isGeoDoc(prod)) {
    return isPhysical(prod) && prod.lastAvailStamp >= DateTime.now().plus({ minutes: 5 }).toMillis()
  }

  if (!isPhysical(prod)) return false

  const distros = prod.distributions.filter(
    (d) => (opts.excludeClosedDistros ? !d.closed : true) && (opts.excludeHiddenDistros ? !d.isHidden : true),
  )
  if (!distros.length) return false

  for (const dist of distros) {
    try {
      const pickups = getPickups(dist, prod, opts)
      if (pickups.length > 0) return true
      else continue
    } catch {
      //If a distro is bad, catching should allow the overall hasPickups to still check if other distros are good
      continue
    }
  }
  return false
}

/** Checks whether a physical product has no pickups left.
 * - Will return false for nonPhysical products */
export const isOutofPickups = (
  p: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>,
  opts?: DistroOpts & {
    /** Whether the internal call to hasPickups will use the session cache  */
    useCache?: boolean
  },
): boolean => {
  if (!isPhysical(p)) return false
  return !hasPickups(p, opts)
}

/** Checks whether a product's end date is after today's date in the farm timezone.
 * - Will return false for nonPhysical products
 * - This helper is based on availability dates only. (Doesn't check for the prescense/ absence of pickups left)
 * - By default it excludes closed and hidden distros when determining availability dates
 **/
export const isPast = (
  p: Product,
  opts: Pick<DistroOpts, 'excludeClosedDistros' | 'excludeHiddenDistros'> & { now?: DateTime } = {},
): boolean => {
  if (!isPhysical(p)) return false

  opts.now ??= dateTimeInZone(p.farm.timezone)
  opts.excludeClosedDistros ??= true
  opts.excludeHiddenDistros ??= true

  const { now, excludeClosedDistros, excludeHiddenDistros } = opts

  const avail = getProductAvailability(p, undefined, { excludeClosedDistros, excludeHiddenDistros })
  if (!avail) return true

  return isAfter(now, avail.endDate, { granularity: 'day', zone: now.zone })
}

/**
 * Determines if a product shoud be shown or hidden for shopping purposes, based on criteria:
 * - Not hidden
 * - Not draft
 * - If physical, must have schedules assigned.
 * - If "opts" are passed, they can control whether the distros must be also hidden or closed. By default acts in 'consumer' mode, which expects distros must also be non-hidden and non-closed. For admin behavior these can be customized.
 * - WARNING: For algolia geo products, this can't know if the product has distros. That's OK it's not a big deal.
 * - Shares must be associated with at least 1 csa
 *  */
export const shouldShow = (
  product: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>,
  opts?: Pick<DistroOpts, 'excludeClosedDistros' | 'excludeHiddenDistros'>,
): boolean => {
  if (isGeoDoc(product)) {
    return !product.isHidden && (isShare(product) ? product.csa.length > 0 : true)
  }
  const { excludeHiddenDistros = true, excludeClosedDistros = true } = opts ?? {}
  return (
    !product.isHidden &&
    !product.isDraft &&
    (isShare(product) ? product.csa.length > 0 : true) &&
    (isPhysical(product)
      ? !!product.distributions.length &&
        product.distributions.some(
          (d) => (excludeHiddenDistros ? !d.isHidden : true) && (excludeClosedDistros ? !d.closed : true),
        )
      : true)
  )
}

/** Tells whether the product meets all the basic requirements for being considered active and selling now:
 * 1. Non hidden, 2. Has pickups left, 3. Not out of stock
 * - By default the distro options are 'consumer' mode (exclude hidden and closed distros and obey order cutoff window)
 */
export const isActive = (
  p: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>,
  opts: DistroOpts & { useCache?: boolean } = {},
) => {
  const {
    excludeClosedDistros = true,
    excludeHiddenDistros = true,
    ignoreOrderCutoffWindow = false,
    useCache = false,
  } = opts
  return (
    shouldShow(p, { excludeClosedDistros, excludeHiddenDistros }) &&
    !isOutofPickups(p, { excludeClosedDistros, excludeHiddenDistros, ignoreOrderCutoffWindow, useCache }) &&
    isInStock(p)
  )
}

/**Gets the last available time at which a product's last pickup can still be ordered on the consumer side, meaning it excludes closed schedules and applies orderCutoffWindow for order deadline.
 *
 * - Non-physical products will get null, as well as physical prods with no schedules.
 * - If this returns null, it means the value either can't be calculated or doesn't apply.
 * - The result of this should be considered technically accurate. It will not try to give an approximation for incompatible, or bad data.
 */
export const getLastAvailTimestamp = (prod: Product, useGetpickupsCache?: boolean): DateTime | null => {
  if (!isPhysical(prod)) {
    return null
  }

  if (!prod.distributions.length) return null

  const distroPickups = prod.distributions
    .map((d) => ({
      distro: d,
      pickups: getPickups(d, prod, {
        excludeClosedDistros: true,
        excludeHiddenDistros: true,
        ignoreDisableBuyInFuture: true, // This is important because we want to get the last pickup potentially available.
        ignoreNumberPickups: true, // This is important to get the last possible pickup in a rolling share
        useCache: useGetpickupsCache,
      }),
    }))
    .filter((d) => d.pickups.length > 0)
    .map((d) => ({ ...d, lastPickup: DateTime.max(...d.pickups) }))
    .map((d) => ({
      ...d,
      lastAvailableStamp: getOrderDeadlineFromPickup(
        d.lastPickup,
        d.distro.orderCutoffWindow,
        d.distro.schedule.hours.endTime,
        d.distro.location.timezone,
      ),
    }))

  if (!distroPickups.length) return null

  return DateTime.max(...distroPickups.map((d) => d.lastAvailableStamp))
}

/** Indicates whether a product is private based on its csa settings */
export const isPrivate = (prod: Product, csas: CSA[]): boolean => {
  if (!prod.csa?.length) return false

  const prodCsas = csas.filter((csa) => prod.csa?.includes(csa.id))
  if (prodCsas.length !== prod.csa.length) {
    throw new Error('isPrivate field calculation is missing CSA data')
  }
  if (prodCsas.every((csa) => csa.isPrivate)) {
    if (isShare(prod)) return true
    return !!prod.hideFromShop
  }
  return false
}
/** Generates the price string to be shown inside the product card */
export const getCardPrice = (product: Product | AlgoliaGeoDoc<AlgoliaGeoProduct> | undefined) => {
  if (!product) return ''
  if (isGeoDoc(product)) return product.priceInCard
  if (isShare(product)) return getSharePriceShortText(product)
  return `${getPriceRange(product)}${product.units.length === 1 ? ` per ${getUnits(product)[0].name}` : ''}`
}

/**
 * Checks if a product is eligible for EBT (if its either EBT eligible or EBT only).
 *
 * @param product - The product to check.
 * @returns `true` if the product is EBT eligible, `false` otherwise.
 */
export const isProductEbtEligible = (product: Product): boolean => {
  if (!isStandard(product)) return false
  return product.ebtEligibility === EbtEligibility.EbtEligible || product.ebtEligibility === EbtEligibility.EbtOnly
}

/**
 * Checks if a product is only for EBT. This means that any order with this product must include an EBT payment method.
 *
 * @param product - The product to check.
 * @returns `true` if the product is for EBT only, `false` otherwise.
 */
export const isProductEbtOnly = (product: Product): boolean => {
  if (!isStandard(product)) return false
  return product.ebtEligibility === EbtEligibility.EbtOnly
}
