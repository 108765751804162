/* eslint-disable no-console */
import { isClient } from '@helpers/Platform'
import { errorToString, isObject } from '@helpers/helpers'
import { makeDatesReadable, objToStr } from '@helpers/log'
import { format } from 'date-fns'
import { logger as defaultLogger } from 'react-native-logs'

import type { Sentry as SentryClass } from './Sentry'

/** This type was derived from, and extends TransportFunctionType in react-native-logs */
type TransportFunctionTypeWithOpts<T> = (props: {
  /** msg should be a string, but the custom transport will handle any type correctly */
  msg: unknown
  /** rawMsg not currently used */
  rawMsg: string
  level: {
    severity: number
    text: string
  }
  extension?: string | null
  options?: T
}) => void

type TransportOptions = {
  /**Control for date formatting */
  dateFormat?: 'utc' | 'iso'
  /** Will hide the date */
  hideDate?: boolean
}

const customTransport: TransportFunctionTypeWithOpts<TransportOptions> = ({ msg, level, options }) => {
  let rawMessage: string
  if (typeof msg === 'string') {
    rawMessage = msg
  } else if (typeof msg === 'function') {
    rawMessage = '[function]'
  } else if (msg instanceof Error) {
    rawMessage = errorToString(msg)
  } else if (isObject(msg)) {
    rawMessage = objToStr(makeDatesReadable(msg))
  } else {
    rawMessage = '[unknown object type]'
  }

  let dateTxt
  if (options && options.dateFormat === 'utc') {
    dateTxt = `${new Date().toUTCString()} | `
  } else if (options && options.dateFormat === 'iso') {
    dateTxt = `${new Date().toISOString()} | `
  } else {
    dateTxt = `${format(new Date(), 'hh:mm:ss a')} | `
  }

  if (options && options.hideDate) dateTxt = ''

  if (level.severity === 0) console.log(dateTxt, msg)
  if (level.severity === 1) console.info(dateTxt, msg)
  if (level.severity === 2) console.warn(dateTxt, msg)
  if (level.severity === 3) console.error(dateTxt, msg)

  //This was originally meant to import sentry only on client, but dynamic imports break cypress. https://github.com/cypress-io/cypress/issues/18435. require() is OK. As a consequence now the Logger can only be used in client
  const Sentry = require('./Sentry').default as SentryClass

  if (level.severity === 3) {
    if (msg instanceof Error) {
      Sentry.captureException(msg)
    } else {
      Sentry.captureError(rawMessage)
    }
  }
  if (level.severity === 2) {
    Sentry.captureError(rawMessage, Sentry.ErrorSeverity.Warning)
  }
}

const loggerConfig: Parameters<typeof defaultLogger.createLogger>[0] = {
  severity: 'debug',
  transport: isClient ? customTransport : undefined,
  transportOptions: null,
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
}

/** Logger will send report app logs to sentry.
 * - Client only. Not for use in `@helpers` files */
export const Logger = defaultLogger.createLogger<'debug' | 'info' | 'warn' | 'error'>(loggerConfig)
