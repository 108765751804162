import { snapshotOrdersByFarm } from '@api/Orders'
import { ToolTips } from '@components'
import {
  Button,
  CheckBox,
  DropdownButton,
  HeaderText,
  Icon,
  RowWrapper,
  Text,
  TextLink,
  Toast,
  ToggleButton,
  Tooltip,
} from '@elements'
import { capitalize } from '@helpers/display'
import { openProductsCSV } from '@helpers/links'
import { AlgoliaAdminProduct, AlgoliaDocType, checkNumericFilter } from '@models/Algolia'
import { ProductType } from '@models/Product'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useContext, useMemo, useState } from 'react'
import {
  useClearRefinements,
  useConfigure,
  useHits,
  useInstantSearch,
  useMenu,
  usePagination,
  useSearchBox,
} from 'react-instantsearch'
import { TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { AdminView } from '../../../components/AdminView'
import { AdminProductsParamList } from '../../../navigation/types'
import { ProductRow } from './components/ProductRow'
import { addProductModal } from './helpers/addProductModal'
import { convertOrdersToSaleStats } from './helpers/convertOrdersToSaleStats'
import useHandleRefine from './helpers/handleRefine'
import { useClearGhostRefinements } from './helpers/useClearGhostRefinements'

import Export from '@/admin/components/Export'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { dropdownMenuFields } from '@/admin/components/bulkEdit/products/product'
import { searchClient } from '@/config/Algolia'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { useAlgoliaState } from '@/hooks/useAlgoliaState'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState, { withKeyedStateProvider } from '@/hooks/useKeyedState'
import { useDeviceSize } from '@/hooks/useLayout'
import { useAlgoliaHooksRefresh } from '@/hooks/useSearchIndexRefresh'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { withAdminIndexHooks } from '@/hooks/withAlgoliaIndex'
import { removeQueueAlgoliaProduct } from '@/redux/actions/adminState'
import { adminFarmIdSelector, adminFarmSelector, queueAlgoliaProducts } from '@/redux/selectors'
import { Permission } from '@helpers/Permission'
import { isPartialEqual } from '@helpers/equalityCheck'
import { isTruthy } from '@helpers/helpers'
import { isOfType } from '@helpers/typescript'
import { dateTimeInZone } from '@models/Timezone'

const ProdTypeRefinementDefault = 'All Types'
const CategoryRefinementDefault = 'All Categories'
const CsaRefinementDefault = 'All CSAs'
const LocRefinementDefault = 'All Locations'
const DistroRefinementDefault = 'All Schedules'

/** State for the screen */
type State = {
  prodTypeRefinement: string
  categoryRefinement: string
  csaRefinement: string
  locRefinement: string
  distroRefinement: string
  isPaginationEnabled: boolean
}

const initialScreenState: State = {
  prodTypeRefinement: ProdTypeRefinementDefault,
  categoryRefinement: CategoryRefinementDefault,
  csaRefinement: CsaRefinementDefault,
  locRefinement: LocRefinementDefault,
  distroRefinement: DistroRefinementDefault,
  isPaginationEnabled: true,
}

function AdminProductListScreenComp({ navigation }: StackScreenProps<AdminProductsParamList, 'ProductList'>) {
  const [{ selection }, setCtx, , settersCtx] = useContext(AdminProductListContext)
  const farmId = useSelector(adminFarmIdSelector)
  const { timezone } = useSelector(adminFarmSelector)
  const { isSmallDevice } = useDeviceSize()
  const productQueue = useSelector(queueAlgoliaProducts) //Any products here should take precedence over algolia results
  const dispatch = useDispatch()

  const [hiddenFilter, setHiddenFilter] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>()
  const [showAvailableOnly, setShowAvailableOnly] = useState<boolean>(false)
  /** These product ids will be passed to the table data */
  const [prodTableIds, setprodTableIds] = useState<string[]>([])
  const [
    { prodTypeRefinement, categoryRefinement, csaRefinement, distroRefinement, locRefinement, isPaginationEnabled },
    set,
  ] = useKeyedState<State>(initialScreenState)

  const lastAvailTimeStampReferenceNow = useMemo(
    () => dateTimeInZone(timezone).plus({ minutes: 5 }).toMillis(),
    [timezone],
  )

  const paginationProps = usePagination({ padding: 2 })

  /** Configures the search filter string */
  useConfigure({
    filters: !farmId
      ? ''
      : `farmId:${farmId} AND docType:${AlgoliaDocType.PRODUCT}${
          hiddenFilter || showAvailableOnly ? ` AND isHidden:false` : ''
        }`,
    numericFilters: showAvailableOnly
      ? [checkNumericFilter(`lastAvailStamp > ${lastAvailTimeStampReferenceNow}`)]
      : undefined,
    hitsPerPage: isPaginationEnabled ? undefined : paginationProps.nbHits,
  })

  /** This makes sure algolia results will refresh automatically when there is a change in algolia data, which happens after a product is edited */
  useAlgoliaHooksRefresh(farmId || '', AlgoliaDocType.PRODUCT)

  /** Loads the farm orders for the given duration (months), and calculates sale statistics from the orders */
  useFocusFx(() => {
    if (!farmId) return
    return snapshotOrdersByFarm(farmId, 3, (orders) => {
      const stats = convertOrdersToSaleStats(orders)

      settersCtx.saleStats(stats)
    })
  }, [farmId, settersCtx])

  const { refine: refineSearch, clear: clearSearch } = useSearchBox({})

  /** Product search: In response to changes in search string, calls algolia refineSearch, or clears the refinement */
  useFocusFx(() => {
    if (!searchTerm) return clearSearch()
    refineSearch(searchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  /** These hits are the product search results */
  const { hits } = useHits<AlgoliaAdminProduct>()
  useAlgoliaHooksRefresh(farmId || '', AlgoliaDocType.PRODUCT)

  /** Integrate the product queue with the algolia results; and remove any processed data from the queue */
  useFocusFx(() => {
    if (!hits?.length) return

    let hitIds = hits.map((h) => h.id as string)

    Object.entries(productQueue).forEach(([queueId, { action, data: queueData }]) => {
      if (action === 'removed') {
        //if a product is in the 'remove' queue, and has not yet been removed from algolia, hide it
        if (hitIds.includes(queueId)) {
          hitIds = hitIds.filter((id) => id !== queueId)
        } else {
          //if a product is no longer in the hitIds, it is ready to be removed from the queue
          dispatch(removeQueueAlgoliaProduct(queueId))
        }
      } else if (action === 'added') {
        //if a product in the 'added' queue is already in the search hits, it is ready to be removed from the queue
        if (hitIds.includes(queueId)) {
          dispatch(removeQueueAlgoliaProduct(queueId))
        } else hitIds.unshift(queueId) //else it means the queued product is not yet in algolia, so add it to the table ids
      } else if (action === 'edited' && isOfType<AlgoliaAdminProduct>(queueData, !!queueData)) {
        const hit = hits.find((h) => h.id === queueId)!
        if (hit && isPartialEqual(queueData, hit)) {
          dispatch(removeQueueAlgoliaProduct(queueId))
        }
      }
    })

    setprodTableIds(hitIds)
  }, [dispatch, hits, productQueue])

  /** Apply pagination refinement when screen focuses, to help ProductList keep page number state when navigating back */
  useFocusFx(
    ({ isRefocus }) => {
      if (!paginationProps.currentRefinement || !isRefocus || !isPaginationEnabled) return

      paginationProps.refine(paginationProps.currentRefinement)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationProps.currentRefinement],
  )

  const { refresh: refreshAlgolia } = useInstantSearch()
  const { loadingSearch, initSearch } = useAlgoliaState()

  const { items: prodTypeFilterOpts, refine: refineProdType } = useMenu({ attribute: 'type', limit: 30 })
  const { refine: clearProdTypeFilter } = useClearRefinements({ includedAttributes: ['type'] })

  const { items: categoryFilterOpts, refine: refineCategory } = useMenu({ attribute: 'category', limit: 30 })
  const { refine: clearCategoryFilter } = useClearRefinements({ includedAttributes: ['category'] })

  const { items: csaFilterOpts, refine: refineCsa } = useMenu({ attribute: 'csas.name', limit: 30 })
  const { refine: clearCsaFilter } = useClearRefinements({ includedAttributes: ['csas.name'] })

  const { items: locFilterOpts, refine: refineLoc } = useMenu({ attribute: 'locations.name', limit: 30 })
  const { refine: clearLocFilter } = useClearRefinements({ includedAttributes: ['locations.name'] })

  const { items: distroFilterOpts, refine: refineDistro } = useMenu({ attribute: 'distributions.name', limit: 30 })
  const { refine: clearDistroFilter } = useClearRefinements({ includedAttributes: ['distributions.name'] })

  /** If a current refinement is no longer in the refinement options, should clear it */
  useClearGhostRefinements([
    {
      rfmt: distroRefinement,
      defaultVal: DistroRefinementDefault,
      currOpts: distroFilterOpts,
      clear: () => {
        set('distroRefinement', DistroRefinementDefault)
        clearDistroFilter()
      },
    },
    {
      rfmt: locRefinement,
      defaultVal: LocRefinementDefault,
      currOpts: locFilterOpts,
      clear: () => {
        set('locRefinement', LocRefinementDefault)
        clearLocFilter()
      },
    },
    {
      rfmt: csaRefinement,
      defaultVal: CsaRefinementDefault,
      currOpts: csaFilterOpts,
      clear: () => {
        set('csaRefinement', CsaRefinementDefault)
        clearCsaFilter()
      },
    },
    {
      rfmt: categoryRefinement,
      defaultVal: CategoryRefinementDefault,
      currOpts: categoryFilterOpts,
      clear: () => {
        set('categoryRefinement', CategoryRefinementDefault)
        clearCategoryFilter()
      },
    },
  ])

  /** loadingInitial here is meant to be only an initial loading indicator because we don't want to
   * hide the results whenever algolia is loading, for example when we refine search, or refine filters.
   * For that, we must make sure algolia's loading state and results are no longer part of its own
   *  initialization process (no longer artificial)
   */
  const [loadingInitial, setLoadingInitial] = useState(true)

  /** Turns off initial loading when search is ready. This approach prevents getting the "No products" message before products load */
  useFocusFx(() => {
    if (loadingInitial && !loadingSearch) setLoadingInitial(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSearch])

  const renderItem = useCallback<NonNullable<OfflineTableProps<string>['renderItem']>>(({ item: id, index }) => {
    return <ProductRow item={id} index={index} key={`prodList_${id}`} />
  }, [])

  /** This should disable pagination and show all results with no pages */
  const showAll = useCallback(() => set('isPaginationEnabled', (v) => !v), [set])

  /** Checks whether all the hits are selected */
  const isAllSelected = useMemo(() => {
    return hits.every((hit) => selection[hit.id] === true)
  }, [hits, selection])

  /** Sets all of the current hits as selected */
  const selectAllToggle = useCallback(() => {
    setCtx('selection', () =>
      hits.map((hit) => [hit.id, !isAllSelected]).reduce((prev, [k, v]) => ({ ...prev, [k as string]: v }), {}),
    )
  }, [setCtx, hits, isAllSelected])

  const styles = useStyles()

  const nSelected = Object.values(selection).filter(isTruthy).length

  return (
    <AdminView>
      <View style={styles.container}>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={30}>Products</HeaderText>
          <Tooltip title="Products" size={15} id={ToolTips.PRODUCTS} />
        </View>

        <RowWrapper>
          <Export
            textStyle={styles.exportTextStyle}
            title="Export Standard Products"
            onPress={() => farmId && openProductsCSV(farmId, ProductType.Standard)}
          />
          <Export
            title="Export Shares"
            textStyle={styles.exportTextStyle}
            onPress={() => farmId && openProductsCSV(farmId, 'share')}
          />
          <Export
            textStyle={styles.exportTextStyle}
            title="Export Digital Products"
            onPress={() => farmId && openProductsCSV(farmId, ProductType.Digital)}
          />
          <Button
            size={isSmallDevice ? 12 : 14}
            small
            title="Add new product"
            onPress={() => addProductModal(navigation)}
          />
        </RowWrapper>

        <RowWrapper style={styles.tableOuterHeader}>
          <View style={globalStyles.flexRowCenter}>
            <Text>{`${hits.length}/${paginationProps.nbHits} products`}</Text>
            <TextLink type="regular" style={styles.showAllText} onPress={showAll}>
              {isPaginationEnabled ? 'Show All' : 'See results in pages'}
            </TextLink>
          </View>

          {nSelected > 1 ? (
            <View style={globalStyles.flexRowCenter}>
              <DropdownButton
                title="Edit Multiple"
                outline
                buttons={dropdownMenuFields}
                data={{
                  selection,
                  onSuccessEdit: () => {
                    Toast('The edits were successful')
                  },
                  onSuccessDelete: (ids) => {
                    setCtx('selection', (prev) => {
                      for (const id of ids) {
                        delete prev[id]
                      }
                      return prev
                    })
                    Toast('Deletion was successful')
                  },
                  farmId: farmId || '',
                }}
                bottomSheetProps={{ ListHeaderComponent: () => <Text>Select a field for bulk editing:</Text> }}
              />
              <View style={styles.direction}>
                <Text style={styles.nSelectedText}>{nSelected} items selected</Text>
                <Text onPress={() => settersCtx.selection({})} style={styles.clearSelectionText}>
                  Clear selection
                </Text>
              </View>
            </View>
          ) : null}

          <View>
            <ToggleButton
              title="Show available only"
              value={showAvailableOnly}
              style={styles.showAvailableOnlyStyle}
              onChange={() => setShowAvailableOnly((prev) => !prev)}
            />
            <View style={styles.showHiddenStyle}>
              <ToggleButton
                title="Show hidden"
                value={!hiddenFilter}
                style={styles.showHiddenBtn}
                onChange={() => {
                  // clean cache when toggling hidden filter
                  searchClient.clearCache().then(() => {
                    setHiddenFilter((prev) => !prev)
                  })
                }}
              />
              <TouchableOpacity
                disabled={!initSearch}
                style={styles.refreshFiltersIcon}
                onPress={() =>
                  searchClient.clearCache().then(() => {
                    refreshAlgolia()
                    Toast('Search filters were refreshed')
                  })
                }
              >
                <Icon color={!initSearch ? Colors.shades['300'] : undefined} iconSet="FontAwesome" name="refresh" />
              </TouchableOpacity>
            </View>
          </View>
        </RowWrapper>

        <OfflineTable<string>
          data={prodTableIds}
          filters={[
            {
              value: prodTypeRefinement,
              items: prodTypeFilterOpts.map((itm) => ({
                label: capitalize(itm.label === ProductType.FarmBalance ? 'Farm Credit' : itm.label),
                value: itm.value,
                key: itm.value,
              })),
              placeholder: ProdTypeRefinementDefault,
              onValueChange: useHandleRefine(
                prodTypeRefinement,
                (v) => set('prodTypeRefinement', v),
                clearProdTypeFilter,
                ProdTypeRefinementDefault,
                refineProdType,
              ),
            },
            {
              value: categoryRefinement,
              items: categoryFilterOpts.map((itm) => ({ label: itm.label, value: itm.value, key: itm.value })),
              placeholder: CategoryRefinementDefault,
              onValueChange: useHandleRefine(
                categoryRefinement,
                (v) => set('categoryRefinement', v),
                clearCategoryFilter,
                CategoryRefinementDefault,
                refineCategory,
              ),
            },
            {
              value: csaRefinement,
              items: csaFilterOpts.map((itm) => ({ label: itm.label, value: itm.value, key: itm.value })),
              placeholder: CsaRefinementDefault,
              onValueChange: useHandleRefine(
                csaRefinement,
                (v) => set('csaRefinement', v),
                clearCsaFilter,
                CsaRefinementDefault,
                refineCsa,
              ),
            },
            {
              value: locRefinement,
              items: locFilterOpts.map((itm) => ({ label: itm.label, value: itm.value, key: itm.value })),
              placeholder: LocRefinementDefault,
              onValueChange: useHandleRefine(
                locRefinement,
                (v) => set('locRefinement', v),
                clearLocFilter,
                LocRefinementDefault,
                refineLoc,
              ),
            },
            {
              value: distroRefinement,
              items: distroFilterOpts.map((itm) => ({ label: itm.label, value: itm.value, key: itm.value })),
              placeholder: DistroRefinementDefault,
              onValueChange: useHandleRefine(
                distroRefinement,
                (v) => set('distroRefinement', v),
                clearDistroFilter,
                DistroRefinementDefault,
                refineDistro,
              ),
            },
          ]}
          search={setSearchTerm}
          searchTerm={searchTerm}
          placeholder="Product Name"
          headerColumns={[
            {
              process: () => <CheckBox checked={isAllSelected} onChange={selectAllToggle} />,
              widthFlex: 0.5,
            },
            { widthFlex: 0.7 /** Accounts for the image */ },
            { title: 'Name', widthFlex: 2 },
            { title: 'SKU', widthFlex: 1.5 },
            { title: 'Type', widthFlex: 1.5 },
            { title: 'Base Unit' },
            { title: 'Price', widthFlex: 1.5 },
            { title: 'In Stock' },
            { title: 'Schedule Availability', widthFlex: 1.5 },
            { title: 'Actions', widthFlex: 0.5 },
          ]}
          needsHeaderSpacer
          renderItem={renderItem}
          containerStyle={styles.offlineTableContainer}
          minWidth={1500}
          paginationProps={paginationProps}
          isPaginationEnabled={isPaginationEnabled}
          isLoading={loadingInitial}
          /** By default the scrollEnable is false, so the mobile app can scroll on the list without problem.
           * The reason to set scrollEnabled
           - 'isWeb' => For mobile web, we have to set scrollEnable true to make table scrollable and then trigger entire screen can be scrollable as well. And for normal big screen web, the behavior stays same.
           - '!isSmallDevice', for bigger touch screen like IPad, we have to make the table scrollable and since we set breakpoint as isSmallDevice, we set !isSmallDevice for all bigger touch screen to be able to scroll on app, for web version, it is covered by setting 'isWeb' */
          scrollEnabled={isWeb || !isSmallDevice}
        />
      </View>
    </AdminView>
  )
}
const useStyles = () =>
  useLayoutFnStyles(({ isSmallDevice, isExtraSmallDevice, height }) => {
    return {
      container: {
        alignContent: 'center',
        flex: 1,
        paddingHorizontal: isSmallDevice ? 10 : 30,
        paddingTop: isSmallDevice ? 10 : 30,
      },
      offlineTableContainer: {
        /** If it is not smallDevice or not extraSmallDevice, it means productList table is enabled to have nested scroll, so the maxHeight should be set to height * 0.65 (depend on the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.
         * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
         */
        maxHeight: !isSmallDevice ? height * 0.65 : '100%',
        borderWidth: 1,
      },
      exportTextStyle: {
        fontSize: isExtraSmallDevice ? 12 : 14,
      },
      tableOuterHeader: {
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
      },
      direction: {
        flexDirection: isExtraSmallDevice ? 'column' : 'row',
      },
      showHiddenStyle: { flexDirection: 'row', alignItems: 'center' },
      showAvailableOnlyStyle: { alignSelf: 'flex-start', paddingVertical: 0 },
      options: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
      },
      showHiddenBtn: { marginRight: 15 },
      refreshFiltersIcon: { marginRight: 5 },
      viewButton: {
        borderColor: Colors.semiTransparent,
        padding: 10,
      },
      showAllText: { textDecorationLine: 'underline', marginHorizontal: 10 },
      nSelectedText: { marginHorizontal: isExtraSmallDevice ? 0 : 10 },
      clearSelectionText: { textDecorationLine: 'underline' },
    }
  })

/** This type should hold any state that needs to be accessed through contexts by sub components of this screen */
export type AdminProductListContextType = {
  /** Tracks products selected by id */
  selection: { [id: string]: boolean | undefined }
  /** Sale stats by product id */
  saleStats: ReturnType<typeof convertOrdersToSaleStats>
}
export const initialContextState: AdminProductListContextType = {
  selection: {},
  saleStats: new Map(),
}

const { ComponentWithProvider, Context: AdminProductListContext } = withKeyedStateProvider(
  AdminProductListScreenComp,
  initialContextState,
)

export { AdminProductListContext }

export const AdminProductListScreen = withAdminAuth(withAdminIndexHooks(ComponentWithProvider), Permission.ProductSetup)
